import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { PlaybackStatisticVm } from '../../../models/order';

type PlaybackStatisticGridProps = {
    rows: PlaybackStatisticVm[];
};

export enum NameField {
    OrderNumber = 'orderNumber',
    Date = 'date',
    Time = 'time',
    MediaSpaceName = 'mediaSpaceName',
}

export default function PlaybackStatisticColumnsGrid(props: PlaybackStatisticGridProps): GridColDef[] {
    const { rows } = props;
    const { t } = useTranslation();

    const getColumns = useCallback(() => {
        return [
            {
                field: NameField.OrderNumber,
                headerName: '№',
                renderCell: function render(params: GridCellParams) {
                    return <Typography variant="body1">{params.row.orderNumber}</Typography>;
                },
                width: 100,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: NameField.Date,
                headerName: t('playbackStatistic.date'),
                renderCell: function render(params: GridCellParams) {
                    const dateString = moment(String(params.row.playDate)).format('DD.MM.YYYY');
                    return <Typography variant="body1">{dateString}</Typography>;
                },
                width: 200,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: NameField.Time,
                headerName: t('playbackStatistic.time'),
                renderCell: function render(params: GridCellParams) {
                    const dateString = moment(String(params.row.playDate)).format('HH:mm');
                    return <Typography variant="body1">{dateString}</Typography>;
                },
                width: 200,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: NameField.MediaSpaceName,
                headerName: t('playbackStatistic.media-space'),
                renderCell: function render(params: GridCellParams) {
                    return <Typography variant="body1">{params.row.mediaSpace.name}</Typography>;
                },
                flex: 0.5,
                sortable: false,
                align: 'left',
                headerAlign: 'left',
            },
        ] as GridColDef[];
    }, [rows]);

    return getColumns();
}
