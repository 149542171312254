import { FC, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { Box } from '@material-ui/core';

import ProgressBar from 'components/atoms/ProgressBar';
import OrderCost from 'components/molecules/Order/OrderCost';
import ResponsiveFlexBox from 'components/atoms/ResponsiveFlexBox';
import OrderContentPreview from 'components/atoms/Order/OrderContentPreview';

import useWindowDimensions from 'hooks/WindowDimensions';

import { OrderCount, OrderVm } from 'models/order';
import { OrderStatusType, UserRoles } from 'shared/enums';

export type OrderInformationProps = {
    children?: ReactNode;
    order?: OrderVm;
    userRole?: string;
    label: string;
    description?: string;
    cost: number;
    currencyCode?: string;
    count?: OrderCount;
};

const OrderInformation: FC<OrderInformationProps> = ({
    order,
    children,
    label,
    description,
    cost,
    count,
    currencyCode,
}) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const mobileVersionMaxWidth = 767;

    return (
        <ResponsiveFlexBox width="100%" alignItems="stretch" paddingTop={2}>
            <Box height="335px">
                <OrderContentPreview height="335px" order={order} data-id="content-preview" />
                {(order?.status == OrderStatusType.CurrentlyPlaying || order?.status == OrderStatusType.Completed) && (
                    <ProgressBar order={order} count={count} />
                )}
            </Box>
            <ResponsiveFlexBox
                justifyContent="start"
                flexDirection="column"
                gridRowGap={17}
                margin={width > mobileVersionMaxWidth ? '0 0 0 1.25rem' : '2.5rem 1rem 0 1rem'}
            >
                {order?.status == OrderStatusType.PaymentRefunded ||
                (order?.status == OrderStatusType.Nullified && order.userRole === UserRoles.User) ? (
                    <ResponsiveFlexBox width="100%" justifyContent="space-between">
                        <Box>
                            <OrderCost
                                label={label}
                                description={description}
                                cost={cost}
                                currencyCode={currencyCode}
                            />
                        </Box>
                        <Box className="refund-cost">
                            <OrderCost
                                label={t('order-form.refund-the-payment-in-part-lable')}
                                cost={order?.refundAmount}
                                currencyCode={currencyCode}
                            />
                        </Box>
                    </ResponsiveFlexBox>
                ) : (
                    <Box>
                        <OrderCost label={label} description={description} cost={cost} currencyCode={currencyCode} />
                    </Box>
                )}
                {children}
            </ResponsiveFlexBox>
        </ResponsiveFlexBox>
    );
};

export default OrderInformation;
