import React from 'react';
import { OrderStatusType } from '../../../../shared/enums';
import ResponsiveFlexBox from '../../ResponsiveFlexBox';
import OrderStatusSelect from '../OrderStatusSelect';
import DateFilter, { DateFilterData } from '../DateFilter';
import { MediaSpaceOrderFormControlTextInputStyle } from './style';

// TODO: Refactor
export type MediaSpaseOrderFilterProps = {
    valFilterStatus: OrderStatusType | undefined;
    setFilterStatus: React.Dispatch<React.SetStateAction<OrderStatusType | undefined>>;
    setStartDate: React.Dispatch<React.SetStateAction<Date | null>>;
    setEndDate: React.Dispatch<React.SetStateAction<Date | null>>;
};

export default function MediaSpaseOrderFilter(props: MediaSpaseOrderFilterProps): JSX.Element {
    const { setFilterStatus, setStartDate, setEndDate } = props;

    return (
        <ResponsiveFlexBox width="100%" height="2.938rem" marginBottom="1.25rem" justifyContent="flex-end">
            <DateFilter
                onFilterChanged={(data: DateFilterData) => {
                    setStartDate(data.startDate);
                    setEndDate(data.endDate);
                }}
            />

            <MediaSpaceOrderFormControlTextInputStyle>
                <OrderStatusSelect
                    onStatusChanged={(value: OrderStatusType) => {
                        setFilterStatus(value);
                    }}
                />
            </MediaSpaceOrderFormControlTextInputStyle>
        </ResponsiveFlexBox>
    );
}
