import { AxiosResponse } from 'axios';
import axios from './axios';

export const setLanguage = (locale: string): Promise<AxiosResponse<boolean>> => {
    return axios.patch(`profile/locale/${locale}`);
};

export const setCurrency = (currencyId: string): Promise<AxiosResponse<boolean>> => {
    return axios.patch(`profile/currency/${currencyId}`);
};
