export const SET_MEDIA_SPACES_FORM = 'SET_MEDIA_SPACES_FORM';
export const DELETE_MEDIA_SPACES_FORM = 'DELETE_MEDIA_SPACES_FORM';
export const SET_ALL_MEDIA_SPACES = 'SET_ALL_MEDIA_SPACES';
export const SET_VIDEO_ID = 'SET_VIDEO_ID';
export const UPLOAD_VIDEO = 'UPLOAD_VIDEO';
export const NOT_UPLOAD_VIDEO = 'NOT_UPLOAD_VIDEO';

export interface NotUploadVideo {
    type: typeof NOT_UPLOAD_VIDEO;
}

export interface UploadVideo {
    type: typeof UPLOAD_VIDEO;
}

export interface SetMediaSpacesForm {
    type: typeof SET_MEDIA_SPACES_FORM;
    data: string[];
}

export interface DeleteMediaSpacesForm {
    type: typeof DELETE_MEDIA_SPACES_FORM;
}

export interface SetAllMediaSpaces {
    type: typeof SET_ALL_MEDIA_SPACES;
    data: boolean;
}

export interface SetVideoId {
    type: typeof SET_VIDEO_ID;
    data: string;
}

export interface FormState {
    mediaSpaces: string[];
    allMediaSpaces: boolean;
    videoId: string;
    isUploadVideo: boolean;
}

export type FormActionTypes =
    | SetMediaSpacesForm
    | DeleteMediaSpacesForm
    | SetAllMediaSpaces
    | SetVideoId
    | UploadVideo
    | NotUploadVideo;
