import TextField, { InputProps } from 'components/atoms/controls/TextField';

export type FormControlProps = InputProps & {
    onChangeValue?: (value: string) => void;
};

export default function FormTextInput(props: FormControlProps): JSX.Element {
    const { onChangeValue, ...otherProps } = props;

    const onFieldInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChangeValue && onChangeValue(e.target.value);
    };

    return <TextField {...otherProps} onChange={onFieldInputChange}></TextField>;
}
