import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const StyledCalendar = styled.div(({}) => ({
    marginBottom: '1.25rem',
    '.mediaspaces-title': {
        fontWeight: 500,
    },
    '.fc': {
        fontStyle: 'normal',
        fontWeight: 'normal',
        '.fc-toolbar': {
            border: `2px solid ${variables.colours.primary}`,
            padding: '1.25rem',
            marginBottom: 0,
            display: 'flex',
            justifyContent: 'space-between',
            '.fc-toolbar-chunk': {
                display: 'flex',
                justifyContent: 'space-between',
                h2: { paddingTop: '0.375rem' },
            },
            '@media only screen and (min-width : 320px) and (max-width : 375px)': {
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
            },
        },
    },
    '.fc-theme-standard .fc-scrollgrid': {
        border: 'none',
    },
    '.fc .fc-button-primary': {
        fontSize: '0.938rem',
        lineHeight: '1.125rem',
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            fontSize: '0.938rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            fontSize: '0.625rem',
        },
        color: variables.colours.primary,
        '&.fc-today-button': {
            width: '4.938rem',
            height: '1.813rem',
            background: variables.backgoroundColours.light,

            border: `1.5px solid ${variables.colours.primary}`,
            boxSizing: 'border-box',
            borderRadius: '2px',
            '@media only screen and (min-width : 320px) and (max-width : 375px)': {
                marginBottom: '0.5rem',
            },
        },
        '&.fc-prev-button': {
            background: variables.backgoroundColours.light,
            border: 0,
        },
        '&.fc-next-button': {
            background: variables.backgoroundColours.light,
            border: 0,
        },
    },
    '.fc-toolbar-title': {
        fontSize: '1.25rem',
        lineHeight: '137.01%',
        ':first-letter': {
            textTransform: 'uppercase',
        },
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            fontSize: '1rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            fontSize: '0.938rem',
        },
    },
    thead: {
        tbody: {
            'tr:first-child': {
                th: {
                    padding: '1.125rem 0',
                    fontWeight: 500,
                    fontSize: '0.938rem',
                    lineHeight: '1.125rem',
                    '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                        fontSize: '0.938rem',
                    },
                    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                        fontSize: '0.625rem',
                    },
                },
            },
        },
    },
    '.fc-button-group': {
        boxSizing: 'border-box',
        borderRadius: '2px',
        button: {
            background: variables.backgoroundColours.light,
            border: `1.5px solid ${variables.colours.primary}`,
            '&:hover': {
                border: `1.5px solid ${variables.colours.primary}`,
                background: variables.backgoroundColours.light,
            },
        },
        '@media only screen and (min-width : 320px) and (max-width : 375px)': {
            marginTop: '0.625rem',
        },
    },
    '.fc .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active': {
        background: variables.colours.primary,
        border: `1.5px solid ${variables.colours.primary}`,
    },
    '.fc-createEvent-button': {
        background: variables.backgoroundColours.light,
        border: `1.5px solid ${variables.colours.primary}`,
        '&:hover': {
            border: `1.5px solid ${variables.colours.primary}`,
            background: variables.backgoroundColours.light,
        },
    },
    '.fc-timeline-event': {
        background: variables.colours.main,
        border: 0,
        height: '1.875rem',
        cursor: 'pointer',
        ':hover': {
            background: variables.colours.primary,
        },
    },
    '.fc-resource-timeline-divider': {
        border: `2px solid ${variables.colours.primary}`,
        borderRight: 0,
    },
    '.fc-scrollgrid-section td:nth-child(1)': {
        border: `2px solid ${variables.colours.primary}`,
        borderRight: 'none',
        borderLeft: `2px solid ${variables.colours.primary}`,
        boxSizing: 'border-box',
        '&.fc-datagrid-cell-frame': {
            borderBottom: `1px solid ${variables.colours.primary}`,
        },
        '&.fc-timeline-lane': {
            borderColor: variables.colours.light,
        },
    },
    '.fc-scrollgrid-section-header td:nth-child(1)': {
        border: `2px solid ${variables.colours.primary}`,
        borderTop: 0,
        borderBottom: `0.6px solid ${variables.colours.primary}`,
        borderRight: 0,
    },
    '.fc .fc-scroller': {
        '::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
        },
        '::-webkit-scrollbar-thumb': {
            background: variables.colours.main,
            borderRadius: '29px',
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: variables.backgoroundColours.primary,
        },
        '::-webkit-scrollbar-track': {
            borderRadius: '0px',
        },
    },
    '.MuiTypography-colorInherit': {
        width: '100%',
        paddingTop: '8px',
        textOverflow: 'ellipsis',
        height: '30px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}));
