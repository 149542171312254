import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Link as MuiLink } from '@material-ui/core';

import Button from 'components/atoms/controls/Button';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import FormControlTextInput from 'components/molecules/forms/FormControlTextInput';

import allRoutes from 'router';
import { AxiosResponse } from 'axios';

import { passwordRecovery } from 'api/auth';
import { ForgetPasswordModel } from 'models/forget-password-model';

import { StyledForgetPassword } from 'components/organisms/forms/ForgetPassword/style';

export default function ForgetPasswordForm(): JSX.Element {
    const { t } = useTranslation();

    const [formData, setFormData] = useState<ForgetPasswordModel>({
        email: '',
        culture: '',
    });
    const [showReSendConfirmEmail, setShowReSendConfirmEmail] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(true);

    const restorePassword = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
        e.preventDefault();
        setIsLoaded(false);
        let isSuccess = false;
        try {
            let body = formData;
            const locale = localStorage.getItem('locale');
            if (locale) {
                body = { email: formData.email, culture: locale } as ForgetPasswordModel;
                setFormData({ ...formData, culture: locale });
            }
            const response: AxiosResponse<boolean> = await passwordRecovery(body);
            isSuccess = response.data;
            // eslint-disable-next-line
        } catch (error: any) {
            const isEmailNotConfirmed =
                error.response?.data?.userMessage.toString() === 'server-messages.email-not-confirmed';
            setShowReSendConfirmEmail(isEmailNotConfirmed);
            setIsLoaded(true);
        } finally {
            if (isSuccess) {
                toast.success(t('forget-password-form.success-message'));
                setFormData({ ...formData, email: '' });
            }
            setIsLoaded(true);
        }
    };

    return (
        <StyledForgetPassword>
            <div>
                <FormControlTextInput
                    name="email"
                    value={formData.email}
                    onChangeValue={(value: string) => {
                        setFormData({ ...formData, email: value });
                    }}
                    label={t('forget-password-form.email')}
                />
            </div>
            <MuiLink component={Link} to={allRoutes.signin.path} className="button-handler" data-id="go-back">
                <Typography variant="body1" className="go-back-text">
                    {t('forget-password-form.go-back')}
                </Typography>
            </MuiLink>
            {showReSendConfirmEmail && (
                <>
                    <LoadingIndicator isLoaded={isLoaded} />
                    {isLoaded && (
                        <MuiLink
                            component={Link}
                            to={allRoutes.confirmEmail.path}
                            className="confirmation-button-handler"
                            data-id="resend-confirmation"
                        >
                            <Typography variant="body1" color="secondary">
                                {t('navigation.resend-confirmation')}
                            </Typography>
                        </MuiLink>
                    )}
                </>
            )}
            <Box display="flex" justifyContent="flex-end" marginTop="0.938rem">
                <LoadingIndicator isLoaded={isLoaded} />
                {isLoaded && (
                    <Button
                        onClick={restorePassword}
                        type="submit"
                        color="primary"
                        variant="outlined"
                        name="send-email"
                    >
                        <Typography variant="h5" className="send-email">
                            {t('forget-password-form.submit')}
                        </Typography>
                    </Button>
                )}
            </Box>
        </StyledForgetPassword>
    );
}
