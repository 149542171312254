import CompletePasswordRestorePage from 'components/pages/CompletePasswordRestorePage';
import ExternalAuthCallback from 'components/pages/ExternalAuthCallback';
import HomePage from 'components/pages/HomeNew';
import LoginPage from 'components/pages/Login';
import RegistrationPage from 'components/pages/Registration';
import MediaSpaceCreateEditPage from 'components/pages/MediaSpaceCreateEdit';
import MediaSpaceProfile from 'components/pages/MediaSpaceProfile';
import MediaSpaces from 'components/pages/MediaSpaces';
import UsersPage from 'components/pages/Users';
import RestorePasswordPage from 'components/pages/RestorePassword';
import UserProfile from 'components/pages/UserProfile';
import UserCreateEditPage from 'components/pages/UserCreateEdit';
import PricesPage from 'components/pages/Prices';
import OrderCreatePage from 'components/pages/OrderCreate';
import OrdersPage from 'components/pages/Orders';
import OrderProfile from 'components/pages/OrderProfile';
import PricesCreateEditPage from 'components/pages/PriceCreateEdit';
import PayResult from 'components/pages/PayResult';
import TermsOfServicePage from 'components/pages/TermsOfService';
import PrivacyPage from 'components/pages/Privacy';
import ChatPage from 'components/pages/Chat';
import ConfirmEmailPage from 'components/pages/ConfirmEmail';
import PlayListsPages from 'components/pages/Playlists';
import PlayListsEditCreatePage from 'components/pages/PlaylistsCreateEdit';
import DevicesPage from 'components/pages/Devices';
import DeviceEditPage from 'components/pages/DeviceEdit';
import { UserRoles } from 'shared/enums';
import ContactsPage from './components/pages/Contacts';
import PlaybackStatisticPage from './components/pages/PlaybackStatistic';
import CurrencyCreatePage from 'components/pages/CurrencyCreate';
import CurrenciesPage from 'components/pages/Currency';

export enum AccessMode {
    Anonymous = 'anonymous',
    Authorized = 'authorized',
    Both = 'both',
}

export type FlashRouterProps = {
    availableForRolesOnly: Array<string>;
    component: () => JSX.Element;
    access: AccessMode;
    isFullWidth?: boolean;
    path: string;
};

export type FlashRoutes = {
    signin: FlashRouterProps;
    signup: FlashRouterProps;
    restorePassword: FlashRouterProps;
    confirmEmail: FlashRouterProps;
    completePasswordRestore: FlashRouterProps;
    externalLoginCallback: FlashRouterProps;
    termsOfService: FlashRouterProps;
    privacyPolicy: FlashRouterProps;
    home: FlashRouterProps;
    mediaSpaces: FlashRouterProps;
    createMediaSpace: FlashRouterProps;
    editMediaSpace: FlashRouterProps;
    mediaSpaceProfile: FlashRouterProps;
    users: FlashRouterProps;
    createUser: FlashRouterProps;
    editUser: FlashRouterProps;
    userProfile: FlashRouterProps;
    currentUserProfile: FlashRouterProps;
    price: FlashRouterProps;
    createPrice: FlashRouterProps;
    priceProfile: FlashRouterProps;
    editPrice: FlashRouterProps;
    messagesPage: FlashRouterProps;
    createOrder: FlashRouterProps;
    createOrderReload: FlashRouterProps;
    orders: FlashRouterProps;
    adminOrders: FlashRouterProps;
    orderProfile: FlashRouterProps;
    orderPayReport: FlashRouterProps;
    playlists: FlashRouterProps;
    createPlaylist: FlashRouterProps;
    playlistProfile: FlashRouterProps;
    editPlaylist: FlashRouterProps;
    devices: FlashRouterProps;
    deviceProfile: FlashRouterProps;
    contacts: FlashRouterProps;
    playbackStatistic: FlashRouterProps;
    currency: FlashRouterProps;
    createCurrency: FlashRouterProps;
    editCurrency: FlashRouterProps;
    currencyProfile: FlashRouterProps;
};

const allRoutes: FlashRoutes = {
    signin: {
        availableForRolesOnly: [],
        component: LoginPage,
        access: AccessMode.Anonymous,
        path: '/auth/signin',
    },
    signup: {
        availableForRolesOnly: [],
        component: RegistrationPage,
        access: AccessMode.Anonymous,
        path: '/auth/signup',
    },
    restorePassword: {
        availableForRolesOnly: [],
        component: RestorePasswordPage,
        access: AccessMode.Anonymous,
        path: '/auth/restore-password',
    },
    confirmEmail: {
        availableForRolesOnly: [],
        component: ConfirmEmailPage,
        access: AccessMode.Anonymous,
        path: '/auth/confirm-email',
    },
    completePasswordRestore: {
        availableForRolesOnly: [],
        component: CompletePasswordRestorePage,
        access: AccessMode.Both,
        path: '/auth/forgot-password/:id/:token/',
    },
    externalLoginCallback: {
        availableForRolesOnly: [],
        component: ExternalAuthCallback,
        access: AccessMode.Anonymous,
        path: '/auth/external-auth-callback',
    },
    termsOfService: {
        availableForRolesOnly: [],
        component: TermsOfServicePage,
        access: AccessMode.Both,
        path: '/terms',
    },
    privacyPolicy: {
        availableForRolesOnly: [],
        component: PrivacyPage,
        access: AccessMode.Both,
        path: '/privacy',
    },
    mediaSpaces: {
        path: '/media-spaces/page:id',
        component: MediaSpaces,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    createMediaSpace: {
        path: '/media-spaces/create',
        component: MediaSpaceCreateEditPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin],
    },
    editMediaSpace: {
        path: '/media-spaces/edit/:id',
        component: MediaSpaceCreateEditPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin],
    },
    mediaSpaceProfile: {
        path: '/media-spaces/:id/page:pageId',
        component: MediaSpaceProfile,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    users: {
        path: '/users/page:id',
        component: UsersPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    createUser: {
        path: '/users/create',
        component: UserCreateEditPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin],
    },
    editUser: {
        path: '/users/edit/:id',
        component: UserCreateEditPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [],
    },
    userProfile: {
        path: '/users/:id',
        component: UserProfile,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    currentUserProfile: {
        path: '/profile',
        component: UserProfile,
        access: AccessMode.Authorized,
        availableForRolesOnly: [],
    },
    price: {
        path: '/prices/page:id',
        component: PricesPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    createPrice: {
        path: '/prices/create',
        component: PricesCreateEditPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin],
    },
    priceProfile: {
        path: '/prices/:id',
        component: PricesCreateEditPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    editPrice: {
        path: '/prices/edit/:id',
        component: PricesCreateEditPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin],
    },
    messagesPage: {
        path: '/chat/:id',
        component: ChatPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [],
    },
    createOrder: {
        path: '/orders/create',
        component: OrderCreatePage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator, UserRoles.User],
    },
    createOrderReload: {
        path: '/orders/create/reload',
        component: OrderCreatePage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator, UserRoles.User],
    },
    orders: {
        path: '/orders/page:pageId',
        component: OrdersPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator, UserRoles.User],
    },
    adminOrders: {
        path: '/orders/admin/page:pageId',
        component: OrdersPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator, UserRoles.User],
    },
    orderProfile: {
        path: '/orders/:id',
        component: OrderProfile,
        access: AccessMode.Authorized,
        availableForRolesOnly: [],
    },
    orderPayReport: {
        path: '/orders/pay/:id',
        component: PayResult,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    playlists: {
        path: '/playlists/page:id',
        component: PlayListsPages,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    createPlaylist: {
        path: '/playlists/create',
        component: PlayListsEditCreatePage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    playlistProfile: {
        path: '/playlists/:id',
        component: PlayListsEditCreatePage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    editPlaylist: {
        path: '/playlists/edit/:id',
        component: PlayListsEditCreatePage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    devices: {
        path: '/devices/page:id',
        component: DevicesPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    deviceProfile: {
        path: '/devices/:id/page:pageId',
        component: DeviceEditPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin, UserRoles.Operator],
    },
    contacts: {
        path: '/contacts',
        component: ContactsPage,
        access: AccessMode.Both,
        availableForRolesOnly: [],
    },
    home: {
        path: '/',
        component: HomePage,
        isFullWidth: true,
        access: AccessMode.Both,
        availableForRolesOnly: [],
    },
    playbackStatistic: {
        path: '/playbackStatistic/:orderId/page:pageId',
        component: PlaybackStatisticPage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [],
    },
    currency: {
        component: CurrenciesPage,
        access: AccessMode.Authorized,
        path: '/currencies/page:pageId',
        availableForRolesOnly: [UserRoles.Admin],
    },
    createCurrency: {
        path: '/currencies/create',
        component: CurrencyCreatePage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin],
    },
    editCurrency: {
        path: '/currencies/edit/:id',
        component: CurrencyCreatePage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin],
    },
    currencyProfile: {
        path: '/currencies/:id',
        component: CurrencyCreatePage,
        access: AccessMode.Authorized,
        availableForRolesOnly: [UserRoles.Admin],
    },
};
export default allRoutes;
