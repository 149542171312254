import { ReactComponent as BurgerMenu } from 'assets/icons/BurgerMenu.svg';
import { BurgerIconStyle } from 'components/atoms/Icon/style';

export default function BurgerIcon(): JSX.Element {
    return (
        <BurgerIconStyle>
            <BurgerMenu className="burger" />
        </BurgerIconStyle>
    );
}
