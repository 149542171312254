import styled from 'styled-components';

export const ChatTemplateStyle = styled.div(() => ({
    width: '100%',
    display: 'flex',
    height: '39.375rem',
    marginBottom: '0.5rem',
    boxShadow: '0px 4px 13px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
}));
