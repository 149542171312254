import styled from 'styled-components';

import { Link, LinkProps } from 'react-router-dom';

import { Link as MuiLink, Typography } from '@material-ui/core';

import Button from 'components/atoms/controls/Button';

import { variables } from 'theme/variables';

export const ProfileActionsStyle = styled.div(({}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        marginBottom: '1.1rem',
    },
}));

export const ButtonText = styled(Typography).attrs({
    variant: 'h6',
})({
    fontSize: '1.5rem',
    color: variables.backgoroundColours.border,
    '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
        fontSize: '1.3rem',
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        fontSize: '1.3rem',
    },
});

export const EditButtonLink = styled(MuiLink).attrs({
    component: Link,
    replace: true,
})<LinkProps>({
    display: 'inline-flex',
    padding: '5px 8px',
    verticalAlign: 'bottom',
    alignItems: 'center',

    svg: {
        fill: variables.backgoroundColours.border,
        marginRight: 5,

        '@media only screen and (min-width: 1440px)': {
            width: 25,
        },
        '@media only screen and (min-width : 320px) and (max-width : 374px)': {
            width: 25,
        },
    },

    ':hover': {
        textDecoration: 'none',

        [ButtonText]: {
            color: variables.colours.primary,
        },

        svg: {
            fill: variables.colours.primary,
        },
    },

    '@media only screen and (min-width: 1440px)': {
        padding: '3px 8px',
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        padding: 0,
        paddingRight: '8px',
    },
});

export const DeleteButton = styled(Button)({
    color: variables.backgoroundColours.border,

    '.MuiButton-startIcon': {
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '20px',
        },
    },

    '.MuiButton-iconSizeMedium > *:first-child': {
        fontSize: '1.875rem',
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            fontSize: '1.563rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            fontSize: '1.25rem',
        },
    },

    svg: {
        fill: variables.backgoroundColours.border,
    },

    '& :hover': {
        [ButtonText]: {
            color: variables.colours.primary,
        },

        svg: {
            fill: variables.colours.primary,
        },
    },
});
