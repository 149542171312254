import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const StyledTemplate = styled.div(({}) => ({
    h1: {
        '@media only screen and (min-width : 320px) and (max-width : 374px)': {
            width: '60%',
        },
        color: variables.colours.primary,
        marginBottom: '30px',
    },
    '.contacts_title': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.25rem',
        lineHeight: '1.813rem',
        color: '#C4C4C4',
        marginBottom: '0.875rem',
        '@media only screen and (max-width : 767px)': {
            marginBottom: '0.1rem',
        },
    },
    '.contacts_text': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '1.688rem',
        color: '#000000',
        textDecoration: 'none',
    },
    '.contacts': {
        '@media only screen and (min-width : 767px)': {
            display: 'flex',
        },
    },
    '.contacts_item': {
        marginRight: '1.875rem',
        paddingRight: '1.875rem',
        width: '26.313rem',
        marginBottom: '2rem',
        '@media only screen and (min-width : 767px)': {
            ':not(:last-child)': {
                borderRight: '1px solid #C4C4C4',
            },
        },
        '@media only screen and (max-width : 767px)': {
            marginBottom: '1rem',
            fontSize: '0.938rem',
        },
        a: {
            borderBottom: `2px solid ${variables.colours.dark}`,
            '&:hover': {
                color: `${variables.colours.primary}`,
                borderBottom: `2px solid ${variables.colours.primary}`,
            },
        },
    },
    '.map-container': {
        '@media only screen and (max-width : 767px)': {
            width: '300px',
            height: '400px',
            margin: '0 auto',
        },
    },
}));

export const StyledBackground = styled.div(({}) => ({
    position: 'fixed',
    zIndex: -1,
    top: '34rem',
    img: {
        minWidth: '100%',
        minHeight: '100%',
    },
    '@media only screen and (max-width: 1439px)': {
        top: '32rem',
    },
    '@media only screen and (min-width : 1025px) and (max-width : 1366px)': {
        top: '28rem',
        right: '0rem',
        img: {
            minWidth: '110%',
            minHeight: '110%',
        },
    },
    '@media only screen and (min-width : 768px) and (max-width : 1025px)': {
        top: '26rem',
        right: '0rem',
        img: {
            minWidth: '110%',
            minHeight: '110%',
        },
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        top: '24rem',
        right: '0rem',
        img: {
            minWidth: '110%',
            minHeight: '110%',
        },
    },
}));
