import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const OrdersPagedDateGridStyles = styled.div(({}) => ({
    '.MuiDataGrid-cell': {
        ':nth-child(3)': {
            a: {
                display: 'flex',
                justifyContent: 'flex-start',
            },
            '.MuiBox-root': {
                minWidth: '47px',
            },
            p: {
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        ':nth-child(4)': {
            a: {
                display: 'flex',
                justifyContent: 'flex-start',
            },
            p: {
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    },
    '.user-name': {
        color: variables.colours.light,
    },
}));
