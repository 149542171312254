import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const RegisterStyle = styled.div(() => ({
    '.registration': {
        color: variables.colours.light,
    },
    '.MuiButton-outlinedPrimary': {
        padding: '0.625rem',
        borderRadius: '0.688rem',
        ':hover': {
            '.registration': {
                color: variables.colours.primary,
            },
        },
    },
    p: {
        color: variables.colours.light,
    },
    '.name-field': {
        ':first-child': {
            width: '47%',
        },
        ':last-child': {
            width: '46%',
        },
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
            width: '100%!important',
        },
    },
}));

export const PhoneInputWrapper = styled.div({
    '.MuiFormControl-root': {
        width: '100%',
        margin: '10px 0',
    },
});
