import { FC } from 'react';
import { StyledTemplate } from 'components/templates/FilteredTemplate/style';
import { FlashRouterProps } from 'router';

export interface FilteredTemplateProps {
    filter: JSX.Element;
    content: JSX.Element;
    header?: string;
    subheader?: string;
    createItemPageRoute?: FlashRouterProps;
    isMediaspaces?: boolean;
    isDevices?: boolean;
    isForOrder?: boolean;
}

const MediaSpacesListContent: FC<FilteredTemplateProps> = ({ filter, content }) => {
    return (
        <StyledTemplate>
            {filter}
            {content}
        </StyledTemplate>
    );
};

export default MediaSpacesListContent;
