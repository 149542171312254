import { useRef, RefObject } from 'react';

interface UseElementSizeValues {
    width: number;
    height: number;
}

type UseElementSizeResult = [UseElementSizeValues, RefObject<HTMLElement | null>];

type UseElementSize = (existingRef?: RefObject<HTMLElement | null>) => UseElementSizeResult;

const defaultValues = {
    width: 0,
    height: 0,
};

const useElementSize: UseElementSize = (existingRef) => {
    const elementRef = useRef<HTMLElement | null>(null);

    const getSize = (): UseElementSizeValues => {
        if (existingRef) {
            return existingRef.current?.getBoundingClientRect() ?? defaultValues;
        }

        return elementRef.current?.getBoundingClientRect() ?? defaultValues;
    };

    const { height, width } = getSize();

    return [{ height, width }, elementRef];
};

export default useElementSize;
