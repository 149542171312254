import { AxiosResponse } from 'axios';
import axios from './axios';

export const getPhotoSrc = (name: string): string | undefined => {
    if (name) {
        return name;
    } else if (window.ENV.config.backendUrl && name) {
        return `${window.ENV.config.backendUrl}/${name}`;
    }
    return undefined;
};

export const create = async (image: File, userId: string): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append(`photo`, image);

    const response = await axios.post(`photos/users`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        params: {
            userId,
        },
    });

    return response;
};
