import { FC, useEffect, useState } from 'react';

import { FlashButtonProps } from 'components/atoms/controls/Button';

import { OptionButton, OptionButtonText } from 'components/atoms/controls/SubmitButton/style';

export type SubmitButtonProps = FlashButtonProps & {
    onSubmit: () => void;
    onSubmiting: (isSubmiting: boolean) => void;
};

const SubmitButton: FC<SubmitButtonProps> = ({ onSubmit, onSubmiting, children, ...buttonProps }) => {
    const [isSubmiting, setIsSubmiting] = useState(false);

    useEffect(() => {
        onSubmiting(isSubmiting);
    }, [isSubmiting]);

    return (
        <OptionButton
            {...buttonProps}
            disabled={buttonProps.disabled || isSubmiting}
            onClick={async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault();
                setIsSubmiting(true);
                onSubmiting(true);
                await onSubmit();
                setIsSubmiting(false);
            }}
        >
            <OptionButtonText>{children}</OptionButtonText>
        </OptionButton>
    );
};

export default SubmitButton;
