import styled from 'styled-components';

import { IconButton, Typography } from '@material-ui/core';

import { variables } from 'theme/variables';

export const StyledMediaDurationInput = styled.div({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${variables.colours.primary}`,
    borderRadius: 8,
    minWidth: 120,
    padding: '2px 5px',
});

export const MediaDurationLabel = styled(Typography).attrs({ variant: 'body1', component: 'span' })({
    fontSize: 16,
    width: 40,
    textAlign: 'center',
});

export const MediaDurationControl = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 5px',
});

export const MediaDurationButton = styled(IconButton)({
    padding: 0,
});

export const MediaDurationIcon = styled.svg({
    height: 18,
    width: 18,
    color: variables.colours.primary,
});
