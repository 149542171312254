import { Price, UPDATE_PRICE_FILTER, REMOVE_FILTER, FilterActionTypes } from 'store/priceFilter/types';

export const updatePriceFilter = (data: Price): FilterActionTypes => ({
    type: UPDATE_PRICE_FILTER,
    data,
});

export const removeFilter = (): FilterActionTypes => ({
    type: REMOVE_FILTER,
});
