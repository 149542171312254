import { OrderStatusType } from '../../shared/enums';

export type PagingVmBaseResponse<T> = {
    total: number;
    pageNumber: number;
    pageSize: number;
    totalReturned: number;
    data: T[];
};

export type PagingModel = SortModel & {
    page: number;
    pageSize: number;
    filterString: string;
};

export type SortModel = {
    property: string;
    direction: SortDirections;
};

export enum SortDirections {
    Asc = 0,
    Desc = 1,
}

export enum SortDirectionsString {
    Asc = 'asc',
    Desc = 'desc',
}

export enum SortField {
    CreatedDate = 'CreatedDate',
    // -----
    DeviceName = 'Name',
    DeviceMediaSpace = 'MediaSpaceName',
    DeviceLastConnectionDate = 'LastConnectionDate',
    DeviceMemory = 'UsedMemory',
    DeviceStatus = 'Status',
    // -----
    CurrencyName = 'Name',
    CurrencyCode = 'Code',
    // -----
    UserId = 'Id',
    UserName = 'UserName',
    UserEmail = 'Email',
    UserRole = 'UserRole',
    UserPhoneNumber = 'PhoneNumber',
    // -----
    PlaylistId = 'Id',
    PlaylistName = 'Name',
    PlaylistStartDate = 'StartDate',
    PlaylistEndDate = 'EndDate',
    PlaylistMediaSpaces = 'MediaSpaces',
    // -----
    MediaSpaceName = 'Name',
    MediaSpaceAddress = 'Address',
    MediaSpaceDevicesCount = 'DevicesCount',
    // -----
    PriceId = 'Id',
    PriceName = 'Name',
    PriceStartDate = 'StartDate',
    PriceEndDate = 'EndDate',
    PriceMediaSpaces = 'MediaSpaces',
    // -----
    OrderNumber = 'Number',
    OrderUserName = 'UserName',
    OrderDateReply = 'DateReply',
    OrderStatus = 'Status',
    OrderCost = 'Cost',
}

export type OrdersPagingModel = PagingModel & {
    userId?: string | null;
    mediaSpaceId?: string | null;
    status?: OrderStatusType;
    startedAt?: string;
    finishedAt?: string;
};
