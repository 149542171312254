import { AxiosResponse } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string'); // CommonJS Module

import { PagedPriceVmResponse, PriceInputModel, PricesPagedRequest, PricesVm } from 'models/price.model';
import axios, { arrayToQuery, toValidGetDate } from './axios';

export const create = (data: PriceInputModel): Promise<AxiosResponse<PricesVm>> => {
    return axios.post(`priceGroup`, data);
};

export const update = (id: string, data: PriceInputModel): Promise<AxiosResponse<PricesVm>> => {
    return axios.put(`priceGroup/${id}`, data);
};

export const get = (mediaSpacesIds: string[], status?: number): Promise<AxiosResponse<Array<PricesVm>>> => {
    const data = queryString.stringify({ mediaSpacesIds });

    return axios.get(`priceGroup/${status}/?${data}`);
};

export const getById = (id: string): Promise<AxiosResponse<PricesVm>> => {
    return axios.get(`priceGroup/${id}`);
};

export const getDefault = (): Promise<AxiosResponse<PricesVm>> => {
    return axios.get(`priceGroup/default`);
};

export const deleteById = (id: string): Promise<AxiosResponse<boolean>> => {
    return axios.delete(`priceGroup/${id}`);
};
export const deletePrices = (ids: string[]): Promise<AxiosResponse<boolean>> => {
    return axios.delete(`priceGroup?${arrayToQuery('ids', ids)}`);
};

export const getPaged = (request: PricesPagedRequest): Promise<PagedPriceVmResponse> => {
    const startDate = toValidGetDate(request.startDate);
    const endDate = toValidGetDate(request.endDate);

    const query = {
        startDate,
        endDate,
        property: request.property,
        direction: request.direction,
        page: request.page,
        pageSize: request.pageSize,
        filterString: request.filterString,
        mediaSpaceIds: request.mediaSpaceIds,
        status: request.status,
    };

    return axios.get(`priceGroup/?${queryString.stringify(query)}`);
};
