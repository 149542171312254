import styled from 'styled-components';

import { Box } from '@material-ui/core';

import { variables } from 'theme/variables';

export const StyledAuth = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    background: variables.backgoroundColours.dark,
    maxWidth: 660,
    padding: '1.875rem 2.313rem',
    margin: '0 auto',
    '@media only screen and (min-width: 768px) and (max-width : 1023px)': {
        padding: theme.spacing(3),
    },
    '@media only screen and (min-width : 320px) and (max-width: 767px)': {
        padding: theme.spacing(2),
    },
    h1: {
        marginTop: '1.5rem',
    },
    a: {
        ':hover': {
            textDecoration: 'none',
        },
    },
    '.protection-handler': {
        marginTop: '2.5rem',
    },
    '.protection-message-box': {
        textAlign: 'center',
        '@media only screen and (min-width : 1440px)': {
            maxWidth: '400px',
        },
    },
    '.protection-message': {
        color: variables.colours.primary,
    },
    '.button-handler': {
        padding: 0,
        ':nth-child(4)': {
            '@media only screen and (min-width : 1440px)': {
                paddingTop: 0,
            },
            '@media only screen and (min-width : 320px) and (max-width : 1023px)': {
                paddingTop: 0,
            },
        },
    },
    '.protection-and': {
        marginRight: '0.5rem',
        marginLeft: '0.5rem',
    },
    '.confirmation-button-handler': {
        display: 'inline-flex',
        paddingTop: '0.513rem',
    },
    '.confirm-text': {
        paddingTop: '0.613rem',
    },
}));

export const AuthLogo = styled.img({
    height: 70,
});
