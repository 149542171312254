import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Typography } from '@material-ui/core';
import { StyledHeaderyTypography } from 'components/molecules/ProfileHeader/style';
import { ReactComponent as PaymentAttention } from 'assets/icons/PaymentAttention.svg';
import { TariffsValidityReasons } from 'shared/enums';
import { variables } from 'theme/variables';
import { getMinimumDateOfTariffCoverage } from '../../../hooks/getMinimumDateOfTariffCoverage';

export interface ProfileHeaderProps {
    header: string;
    subheader?: string;
    valid?: boolean;
    reason?: number;
    isCreate?: boolean;
    isNotDevices?: boolean;
    hasUnavailableDevices?: boolean;
}

const ProfileHeader: FC<ProfileHeaderProps> = ({
    header,
    subheader,
    valid,
    reason,
    isCreate,
    isNotDevices,
    hasUnavailableDevices,
}) => {
    const { t } = useTranslation();

    const messageData = {
        date: getMinimumDateOfTariffCoverage(),
    };
    let errorText = t('media-space.not-full', messageData);

    if (reason == TariffsValidityReasons.NoTariffs) {
        errorText = t('media-space.no-prices');
    } else if (reason == TariffsValidityReasons.TariffNotValid) {
        errorText = t('media-space.price-invalid');
    }

    return (
        <StyledHeaderyTypography>
            <Typography variant="h1" className="header">
                {header}
                {!valid && !isCreate && (
                    <Tooltip
                        title={
                            <Typography variant="body1" style={{ color: variables.colours.light }}>
                                {errorText}
                            </Typography>
                        }
                        placement="top-start"
                        arrow
                    >
                        <PaymentAttention className="attention" />
                    </Tooltip>
                )}
                {isNotDevices && (
                    <Tooltip
                        title={
                            <Typography variant="body1" style={{ color: variables.colours.light }}>
                                {t('media-space.no-devices')}
                            </Typography>
                        }
                        placement="top-start"
                        arrow
                    >
                        <PaymentAttention className="attention" />
                    </Tooltip>
                )}
                {hasUnavailableDevices && (
                    <Tooltip
                        title={
                            <Typography variant="body1" style={{ color: variables.colours.light }}>
                                {t('media-space.no-available-devices')}
                            </Typography>
                        }
                        placement="top-start"
                        arrow
                    >
                        <PaymentAttention className="attention" />
                    </Tooltip>
                )}
            </Typography>
            {subheader ? (
                <Typography variant="body1" className="subheader">
                    {subheader}
                </Typography>
            ) : null}
        </StyledHeaderyTypography>
    );
};

export default ProfileHeader;
