import GradientFont from 'components/atoms/GradientFont';
import { NumericalCardStyled, NumericalCardTextStyled, NumericalCardTitleStyled } from './style';

type NumericalCardProps = {
    number: string;
    title: string;
    text: string;
};

export default function NumericalCard({ number, title, text }: NumericalCardProps): JSX.Element {
    return (
        <NumericalCardStyled>
            <div className="heading">
                <GradientFont className="gradient_font" fontSize={'90px'} text={number}></GradientFont>
                <NumericalCardTitleStyled>{title}</NumericalCardTitleStyled>
            </div>
            <NumericalCardTextStyled>{text}</NumericalCardTextStyled>
        </NumericalCardStyled>
    );
}
