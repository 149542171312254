import styled from 'styled-components';

import { Typography, CircularProgress } from '@material-ui/core';

import { variables } from 'theme/variables';

interface StyledOrderStatusProps {
    $color: string;
}

export const StyledOrderStatus = styled.div<StyledOrderStatusProps>(({ $color }) => ({
    width: 170,
    height: 32,
    background: $color,
    borderRadius: 4,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const StatusMessage = styled(Typography).attrs({
    variant: 'subtitle1',
})({
    color: variables.colours.light,
    textAlign: 'center',
});

export const Spinner = styled(CircularProgress).attrs({
    size: 14,
})({
    marginRight: 6,
    color: '#fff',
});
