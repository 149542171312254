import { variables } from '../../../../theme/variables';
import styled from 'styled-components';

export const StyledMain = styled.main(({}) => ({
    flexGrow: 1,
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    marginTop: variables.constants.appBarHeight,
    // minHeight: `calc(100vh - ${variables.constants.appBarHeight + variables.constants.footerHeight}px)`,
    // '@media only screen and (width: 1024px)': {
    //     marginTop: variables.constants.appBarHeight - 50,
    // },
    minHeight: '100vh',
    '@media only screen and (min-width: 320px) and (max-width: 767px)': {
        marginTop: variables.constants.appBarHeight + 30,
        marginBottom: 0,
        marginRight: 0,
        marginLeft: 0,
    },
    '.filter-handler': {
        marginBottom: '1.563rem',
        flexWrap: 'wrap',
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            marginBottom: '0.938rem',
        },
    },
}));
