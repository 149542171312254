import styled from 'styled-components';

import { Typography } from '@material-ui/core';

import ResponsiveFlexBox from 'components/atoms/ResponsiveFlexBox';

import { variables } from 'theme/variables';

export const StyledInfo = styled(ResponsiveFlexBox)(({}) => ({
    width: '100%',
    margin: '2.5rem 0 2.5rem 0',
    padding: '1.25rem 1.75rem 0 1.75rem',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    border: `2px solid ${variables.colours.default}`,
    borderRadius: '0.25rem',
    background: variables.backgoroundColours.light,
    boxShadow: '0px 0px 8px 3px rgba(0, 0, 0, 0.05)',
}));

export const Characteristic = styled.div(({}) => ({
    maxWidth: '18.125rem',
    marginRight: '1.875rem',
    marginBottom: '1.25rem',
}));

export const Title = styled(Typography).attrs({
    variant: 'h6',
})({
    marginBottom: '1.25rem',
    color: variables.colours.default,
});
