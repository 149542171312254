import React from 'react';
import { useTranslation } from 'react-i18next';
import { RoleAdmin, RoleUser, RoleOperator } from './style';
import { UserRoles } from '../../../shared/enums';
import ResponsiveFlexBox from '../ResponsiveFlexBox';
import { Typography } from '@material-ui/core';

export default function OrderStatus(props: { userRole: string | undefined; symbol?: JSX.Element }): JSX.Element {
    const { t } = useTranslation();
    const { userRole, symbol } = props;

    function status() {
        switch (userRole) {
            case UserRoles.Admin:
                return (
                    <RoleAdmin>
                        <ResponsiveFlexBox justifyContent="center" width="100%">
                            <Typography variant="subtitle1">
                                {t('roles.admin')} {symbol}
                            </Typography>
                        </ResponsiveFlexBox>
                    </RoleAdmin>
                );
            case UserRoles.User:
                return (
                    <RoleUser>
                        <ResponsiveFlexBox justifyContent="center" width="100%">
                            <Typography variant="subtitle1">
                                {t('roles.user')} {symbol}
                            </Typography>
                        </ResponsiveFlexBox>
                    </RoleUser>
                );
            case UserRoles.Operator:
                return (
                    <RoleOperator>
                        <ResponsiveFlexBox justifyContent="center" width="100%">
                            <Typography variant="subtitle1">
                                {t('roles.operator')} {symbol}
                            </Typography>
                        </ResponsiveFlexBox>
                    </RoleOperator>
                );
        }
    }

    return <>{status()}</>;
}
