import React from 'react';

export const screenshotInfoStyle = {
    position: 'fixed',
    top: '10px',
    left: '0px',
    zIndex: 999999,
    background: 'white',
    padding: '3px',
    borderRadius: '3px',
    border: '3px solid #FFB78B',
    width: '100%',
    display: 'flex',
} as React.CSSProperties;
