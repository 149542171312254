import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { variables } from '../../../../theme/variables';

export const StyledBox = styled.div({
    '.MuiButton-outlinedPrimary': {
        p: {
            color: variables.colours.light,
        },
        ':hover': {
            p: {
                color: variables.colours.primary,
            },
        },
    },
});

export const StyledButton = styled(Button)({
    height: '2.063rem',
    width: '3.563rem',
    borderRadius: '0px',
    padding: '0.625rem',
    p: {
        color: variables.colours.default,
    },
    ':hover': {
        p: {
            color: variables.colours.primary,
        },
    },
    ':first-child': {
        borderRadius: '4px 0px 0px 4px',
    },
    ':last-child': {
        borderRadius: '0px 4px 4px 0px',
    },
});
