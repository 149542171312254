import styled from 'styled-components';
import ResponsiveFlexBox from '../../atoms/ResponsiveFlexBox';

export const StyledFormContainer = styled(ResponsiveFlexBox)(({ theme }) => ({
    '.map-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: 300,
        padding: theme.spacing(3),
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            padding: theme.spacing(1),
            marginLeft: '-0.938rem',
            height: '24.688rem',
        },
    },
    '@media (max-width: 768px)': {
        padding: '1.25rem 0.625rem',
        flexDirection: 'column',
        height: 'auto',
    },
}));
