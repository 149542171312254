import styled from 'styled-components';

import { Button, Typography } from '@material-ui/core';

import { CancelButtonProps } from 'components/atoms/controls/CancelButton';

import { variables } from 'theme/variables';

export const OptionButtonText = styled(Typography).attrs({
    variant: 'h5',
})({});

export const OptionButton = styled(Button).attrs<CancelButtonProps>({
    color: 'secondary',
    variant: 'outlined',
    type: 'submit',
})({
    marginTop: '3.125rem',
    marginLeft: '3.313rem',
    marginBottom: '1.25rem',
    border: `2px solid ${variables.colours.primary}`,

    [OptionButtonText]: {
        color: variables.colours.primary,
    },

    ':hover': {
        border: `2px solid ${variables.colours.primary}`,
    },

    '@media only screen and (min-width: 375px) and (max-width: 425px)': {
        width: 'fit-content',
        marginLeft: '1.313rem',
    },

    '@media only screen and (min-width: 320px) and (max-width: 374px)': {
        width: 'fit-content',
        marginLeft: '26.5%',
        marginTop: '1.25rem',
    },
});
