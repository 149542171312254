import styled from 'styled-components';

import ResponsiveFlexBox from 'components/atoms/ResponsiveFlexBox';

import { variables } from 'theme/variables';

export const StyledFormControl = styled.div(({}) => ({
    display: 'flex',
    '.MuiFormControl-root:not(:first-child)': {
        marginLeft: '1.25rem',
    },
    '.MuiFormControl-root': {
        '@media only screen and (min-width : 320px) and (max-width : 769px)': {
            width: '100%',
        },
    },
    '.MuiInputBase-root': {
        background: variables.backgoroundColours.light,
        width: '15.563rem',
        '@media only screen and (min-width : 768px) and (max-width : 1366px)': {
            width: '14.563rem',
        },
        '@media only screen and (min-width : 1025px) and (max-width : 1280px)': {
            width: '12.563rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '100%',
        },
        height: '2.688rem',
        boxSizing: 'border-box',
        borderRadius: '4px',
    },
    '.MuiOutlinedInput-input': {
        color: variables.colours.default,
        textAlign: 'center',
        padding: '0.625rem',
    },
    '.MuiSelect-selectMenu': {
        display: 'flex',
    },
    '&.last-filter': {
        '.filter-option': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
}));

export const StyledFilter = styled(ResponsiveFlexBox)({
    width: '100%',
    minHeight: '2.813rem',
    justifyContent: 'space-between',
});
