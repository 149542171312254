import { AxiosResponse } from 'axios';
import axios from './axios';
import { CurrencyInputModel, CurrencyPage, CurrencyPageVm, CurrencyVm } from 'models/currency.model';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string'); // CommonJS Module

export const currenciesAPI = {
    getCurrencies: (): Promise<AxiosResponse<CurrencyVm[]>> => {
        return axios.get('currency');
    },
    getById: (id: string): Promise<AxiosResponse<CurrencyVm>> => {
        return axios.get(`currency/${id}`);
    },
    update: (id: string, data: CurrencyInputModel): Promise<AxiosResponse<CurrencyVm>> => {
        return axios.put(`currency/${id}`, data);
    },
    deleteById: (id: string): Promise<AxiosResponse<boolean>> => {
        return axios.delete(`currency/${id}`);
    },
    create: (data: CurrencyInputModel): Promise<AxiosResponse<CurrencyVm>> => {
        return axios.post('currency', data);
    },
    getPaged: (data: CurrencyPage): Promise<CurrencyPageVm> => {
        return axios.get(`currency/page/?${queryString.stringify(data)}`);
    },
};
