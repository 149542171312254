import { FC } from 'react';

import moment from 'moment';
import { capitalize } from 'lodash';

import { NavbarElementProps } from 'react-day-picker';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import {
    StyledDatePickerNavbar,
    NavbarButton,
    NavbarButtonIcon,
    NavbarMonth,
} from 'components/atoms/DatePickerNavbar/style';

const DatePickerNavbar: FC<NavbarElementProps> = ({ onPreviousClick, onNextClick, month }) => {
    return (
        <StyledDatePickerNavbar>
            <NavbarButton onClick={() => onPreviousClick()}>
                <NavbarButtonIcon as={ChevronLeftIcon} />
            </NavbarButton>
            <NavbarMonth>{capitalize(moment(month).format('MMMM YYYY'))}</NavbarMonth>
            <NavbarButton onClick={() => onNextClick()}>
                <NavbarButtonIcon as={ChevronRightIcon} />
            </NavbarButton>
        </StyledDatePickerNavbar>
    );
};

export default DatePickerNavbar;
