import {
    StatisticsCardStyled,
    StatisticsCardImageStyled,
    StatisticsCardTitleStyled,
    StatisticsCardTextStyled,
} from './style';

type StatisticsCardProps = {
    image: string;
    title: string;
    text: string;
};

export default function StatisticsCard({ image, title, text }: StatisticsCardProps): JSX.Element {
    return (
        <StatisticsCardStyled>
            <StatisticsCardImageStyled>
                <img srcSet={image} alt={title} />
            </StatisticsCardImageStyled>
            <StatisticsCardTitleStyled>{title}</StatisticsCardTitleStyled>
            <StatisticsCardTextStyled>{text}</StatisticsCardTextStyled>
        </StatisticsCardStyled>
    );
}
