import { FC, ComponentProps } from 'react';

import { StyledAuthProvider, AuthProviderIcon, AuthProviderText } from 'components/atoms/AuthProvider/style';

export interface AuthProviderProps {
    name: string;
    text: string;
    color: string;
    url: string;
    backgroundColor: string;
    icon: FC<ComponentProps<'svg'>>;
    id?: string;
    enable: boolean;
}

const AuthProvider: FC<AuthProviderProps> = ({ text, color, backgroundColor, icon, url, id }) => {
    return (
        <StyledAuthProvider color={color} backgroundcolor={backgroundColor} href={url} id={id}>
            <AuthProviderIcon as={icon} />
            <AuthProviderText>{text}</AuthProviderText>
        </StyledAuthProvider>
    );
};

export default AuthProvider;
