import { FC } from 'react';

import Status from 'components/atoms/Order/Status';

import { OrderStatusMap } from 'shared/constants';

import { OrderStatusType } from 'shared/enums';
import { useTranslation } from 'react-i18next';

interface OrderStatusProps {
    status?: OrderStatusType;
    loading?: boolean;
}

const OrderStatus: FC<OrderStatusProps> = ({ status, loading }) => {
    const { color, message } = OrderStatusMap[status ?? OrderStatusType.All];
    const { t } = useTranslation();

    return <Status color={color} message={t(message)} loading={loading} />;
};

export default OrderStatus;
