import styled from 'styled-components';

import { Slider, Button } from '@material-ui/core';

export const StyledVolumeMixer = styled.div(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: theme.spacing(2),
}));

export const VolumeMixerIcon = styled.svg(({}) => ({
    objectFit: 'cover',
    minWidth: 50,
    minHeight: 50,
}));

export const VolumeSlider = styled(Slider)({
    width: 164,
});

export const SubmitSection = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
}));

export const Submit = styled(Button).attrs({
    color: 'primary',
})({
    fontSize: 16,
});
