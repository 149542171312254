import styled from 'styled-components';
import { variables } from '../../../../theme/variables';
import ResponsiveFlexBox from '../../ResponsiveFlexBox';

export const FormControlTextInputStyle = styled.div(({}) => ({
    display: '-webkit-box',
    marginRight: '5px',
    '.responsive-with-margin': {
        '> div': {
            marginLeft: '1.25rem',
        },
    },
    '.MuiFormControl-root:not(:first-child)': {
        marginLeft: '1.25rem',
    },
    '.MuiFormControl-root': {
        '@media only screen and (min-width : 320px) and (max-width : 769px)': {
            width: '100%',
        },
    },
    '.MuiInputBase-root': {
        background: variables.backgoroundColours.light,
        width: '15.563rem',
        '@media only screen and (min-width : 768px) and (max-width : 1366px)': {
            width: '14.563rem',
        },
        '@media only screen and (min-width : 1025px) and (max-width : 1280px)': {
            width: '12.563rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '100%',
        },
        height: '2.688rem!important',
        boxSizing: 'border-box',
        borderRadius: '4px',
    },
    '.MuiOutlinedInput-input': {
        color: variables.colours.default,
        textAlign: 'center',
        padding: '0.625rem',
    },
    '.MuiSelect-selectMenu': {
        display: 'flex',
    },
}));

export const FilterFlexBox = styled(ResponsiveFlexBox)(({}) => ({
    gap: '1.25rem',
    alignItems: 'flex-start',

    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        alignItems: 'stretch',
    },

    '> div:not(:first-child)': {
        alignItems: 'center',
    },
    '@media only screen and (min-width : 320px) and (max-width : 1220px)': {
        flexWrap: 'wrap',

        '> div:last-child': {
            marginLeft: 0,
            li: {
                paddingLeft: '3px',
            },
        },
    },
}));
