import { ReactComponent as Calendar } from 'assets/icons/CalendarStage.svg';
import { Icon } from 'components/atoms/Icon/style';

export default function SpaceIcon(): JSX.Element {
    return (
        <Icon>
            <Calendar />
        </Icon>
    );
}
