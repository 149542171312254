import { FC, useState, useMemo, Fragment, useEffect, useCallback } from 'react';

import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import classnames from 'classnames';

import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Breadcrumbs, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';

import MediaSpacesMultiSelect from 'components/molecules/forms/MediaSpacesMultiSelect';
import FilterInput from 'components/atoms/FilterInput';
import FlashDatePicker from 'components/atoms/FlashDatePicker';
import FormikAutoSubmit from 'components/atoms/FormikAutoSubmit';
import FilterOption from 'components/atoms/FilterOption';

import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/BurgerMenu.svg';

import { variables } from 'theme/variables';
import { updatePriceFilter } from 'store/priceFilter/actions';
import allRoutes from 'router';

import { getMediaSpaces } from 'api/media-space';

import useDispatch from 'hooks/useAppDispatch';
import useSelector from 'hooks/useAppSelector';

import { ReactComponent as CalendarFilter } from 'assets/icons/CalendarFilter.svg';

import { Price } from 'store/priceFilter/types';
import { MediaSpaceVm } from 'models/media-space.model';
import { PriceSearchStatus } from 'models/price.model';

import {
    DataFilterIcin,
    NotifMenuStyled,
    NotifStyled,
    StyledPriceFilter,
} from 'components/atoms/gridFilters/PriceFilter/style';

export interface PriceFilterProps {
    allMediaSpaces: MediaSpaceVm[];
    tableCalendar: boolean;
    resourceTimeline?: string;
}

export type MediaSpaceChangeData = {
    isAllSelected: boolean;
    selectedMediaSpaces: string[];
};

const PriceFilter: FC<PriceFilterProps> = ({ resourceTimeline, allMediaSpaces, tableCalendar }) => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.userReducer.user);
    const history = useHistory();
    const dispatch = useDispatch();

    const moment = extendMoment(Moment);

    const filters = useSelector((state) => state.priceFilterReducer.price);

    const [filter, setFilter] = useState<Price>(filters);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mediaSpaces, setMediaSpaces] = useState<MediaSpaceVm[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useMemo(() => {
        if (allMediaSpaces.length) {
            setFilter((prev) =>
                prev.isAllSelected ? { ...prev, MediaSpacesIds: allMediaSpaces.map((space) => space.id) } : prev,
            );
        }
    }, [allMediaSpaces]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => setAnchorEl(null);

    const fetchData = () => {
        void getMediaSpaces()
            .then(({ data }) => {
                dispatch(
                    updatePriceFilter({
                        ...filters,
                        MediaSpacesIds: data.map(({ id }) => id),
                    }),
                );
                setMediaSpaces(data);
            })
            .catch((err) => console.error(err))
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setTimeout(() => {
            fetchData();
        }, 300);
    }, [user.lc]);

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            fetchData();
        }, 300);
    }, []);

    useEffect(() => {
        dispatch(updatePriceFilter(filter));
    }, [dispatch, filter]);

    const handleSubmit = useCallback(
        (values: Price) => {
            setFilter({ ...filter, ...values });
        },
        [filter],
    );

    if (isLoading) return null;

    return (
        <StyledPriceFilter>
            <Formik initialValues={filters} onSubmit={handleSubmit} enableReinitialize>
                {({ values, setFieldValue }: FormikProps<Price>) => (
                    <Fragment>
                        <FormikAutoSubmit />
                        <FilterInput
                            value={values.filterString}
                            onChange={(value: string) => {
                                history.push(allRoutes.price.path, { id: 1 });
                                setFieldValue('filterString', value);
                            }}
                        />
                        <MediaSpacesMultiSelect
                            displayField="name"
                            controlsDirection="row"
                            variant="outlined"
                            onChange={(value: string[]) => {
                                const isAllSelected = value.length > 0 && value.length === mediaSpaces.length;

                                history.push(allRoutes.price.path, { id: 1 });

                                setFieldValue('MediaSpacesIds', value);
                                setFieldValue('isAllSelected', isAllSelected);
                            }}
                            value={values.isAllSelected ? mediaSpaces.map(({ id }) => id) : values.MediaSpacesIds}
                            options={mediaSpaces}
                        />
                        <div className="filter-options">
                            <FilterOption
                                checked={values.isAllSelected}
                                onClick={() => {
                                    history.push(allRoutes.price.path, { id: 1 });

                                    if (values.isAllSelected) {
                                        setFieldValue('MediaSpacesIds', []);
                                        setFieldValue('isAllSelected', false);
                                        return;
                                    }

                                    setFieldValue(
                                        'MediaSpacesIds',
                                        mediaSpaces.map(({ id }) => id),
                                    );
                                    setFieldValue('isAllSelected', true);
                                }}
                                text={t('order-form.button-text.select-all')}
                            />
                            {!tableCalendar && (
                                <FilterOption
                                    onClick={() => {
                                        history.push(allRoutes.price.path, { id: 1 });
                                        setFieldValue(
                                            'status',
                                            values.status === PriceSearchStatus.Any
                                                ? PriceSearchStatus.ActiveOnly
                                                : PriceSearchStatus.Any,
                                        );
                                        history.replace({ pathname: generatePath(allRoutes.price.path, { id: 1 }) });
                                    }}
                                    checked={values.status === PriceSearchStatus.Any}
                                    text={t('order-form.button-text.include-inactive')}
                                />
                            )}
                        </div>
                        <div className="date-options">
                            <Box display="flex">
                                <DataFilterIcin>
                                    <Tooltip
                                        title={
                                            <Typography variant="body1" style={{ color: variables.colours.light }}>
                                                {t('price.calendar-title')}
                                            </Typography>
                                        }
                                        placement="top-start"
                                        arrow
                                    >
                                        <IconButton
                                            aria-label="show more"
                                            onClick={handleClick}
                                            aria-haspopup="true"
                                            color="primary"
                                            name="calendar-icon"
                                        >
                                            <CalendarFilter />
                                        </IconButton>
                                    </Tooltip>
                                </DataFilterIcin>
                                <NotifMenuStyled
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <NotifStyled onClick={handleClose}>
                                        <CalendarFilter />
                                    </NotifStyled>
                                    <NotifStyled>
                                        <FlashDatePicker
                                            name="date-picker"
                                            selected={values.startDate}
                                            onChange={(date: Date | [Date, Date]) => {
                                                history.push(allRoutes.price.path, { id: 1 });
                                                if (values.tableCalendar) {
                                                    setFieldValue('startDate', date);
                                                } else {
                                                    const [startDate, endDate] = date as [Date, Date];

                                                    setFieldValue('startDate', startDate);
                                                    setFieldValue('endDate', endDate);
                                                }
                                            }}
                                            isWeek={values.tableCalendar && resourceTimeline == 'week'}
                                            onYearChange={(date) => setFieldValue('startDate', date)}
                                            startDate={
                                                resourceTimeline == 'week' && values.tableCalendar
                                                    ? moment(moment(values.startDate).startOf('week').toDate())
                                                          .subtract('days')
                                                          .toDate()
                                                    : !values.tableCalendar
                                                    ? values.startDate
                                                    : undefined
                                            }
                                            endDate={
                                                resourceTimeline == 'week' && values.tableCalendar
                                                    ? moment(moment(values.startDate).endOf('week').toDate())
                                                          .subtract('days')
                                                          .toDate()
                                                    : !values.tableCalendar
                                                    ? values.endDate
                                                    : undefined
                                            }
                                            inline
                                            showMonthYearPicker={
                                                values.tableCalendar && resourceTimeline == 'month' ? true : false
                                            }
                                            showFullMonthYearPicker={
                                                values.tableCalendar && resourceTimeline == 'month' ? true : false
                                            }
                                            selectsRange={!values.tableCalendar}
                                        />
                                    </NotifStyled>
                                </NotifMenuStyled>
                            </Box>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Tooltip
                                    title={
                                        <Typography variant="body1" style={{ color: variables.colours.light }}>
                                            {t('prices.list-of-tariffs')}
                                        </Typography>
                                    }
                                    placement="top-start"
                                    arrow
                                >
                                    <IconButton
                                        color="inherit"
                                        onClick={() => setFieldValue('tableCalendar', false)}
                                        className={classnames('icon-button', { active: !values.tableCalendar })}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title={
                                        <Typography variant="body1" style={{ color: variables.colours.light }}>
                                            {t('prices.calendar')}
                                        </Typography>
                                    }
                                    placement="top-start"
                                    arrow
                                >
                                    <IconButton
                                        color="inherit"
                                        onClick={() => setFieldValue('tableCalendar', true)}
                                        className={classnames('icon-button', { active: values.tableCalendar })}
                                    >
                                        <CalendarIcon />
                                    </IconButton>
                                </Tooltip>
                            </Breadcrumbs>
                        </div>
                    </Fragment>
                )}
            </Formik>
        </StyledPriceFilter>
    );
};

export default PriceFilter;
