import * as React from 'react';
import Button from '../../../atoms/controls/Button';
import { useTranslation } from 'react-i18next';
import { completePasswordRestore } from '../../../../api/auth';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import { CompletePasswordRestoreModel } from '../../../../models/complete-password-restore';
import { toast } from 'react-toastify';
import { useState } from 'react';
import RedirectPage from '../../../pages/Redirect';
import allRoutes from '../../../../router';
import { Box, Typography } from '@material-ui/core';
import AuthTemplate from '../../../templates/Auth';
import Password from '../../../atoms/PasswordInput';
import { CompleteRestoreStyle } from './style';

const defaultObject = {
    password: '',
    passwordConfirm: '',
    userId: '',
    token: '',
};

export default function CompletePasswordRestoreForm(): JSX.Element {
    const { t } = useTranslation();

    const [formData, setFormData] = useState<CompletePasswordRestoreModel>(defaultObject);
    const [isTokenSet, setToken] = useState<boolean>(false);
    const [isNeedToRedirectToLogin, setIsNeedToRedirectToLogin] = useState<boolean>(false);
    const [showPassword, setShowPassword] = React.useState<boolean>(false);

    if (!isTokenSet) {
        const { id, token } = useParams<{ id: string; token: string }>();
        setToken(true);
        setFormData({ ...formData, userId: id, token: token });
    }

    const completePasswordRestoreEvent = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const doCompletePassword = async () => {
            let isSuccess = false;
            try {
                const response: AxiosResponse<boolean> = await completePasswordRestore(formData);
                isSuccess = response.data;
            } finally {
                if (isSuccess) {
                    toast.success(t('complete-password-restore-form.success-message'));
                    setFormData(defaultObject);
                    setIsNeedToRedirectToLogin(true);
                }
            }
        };
        doCompletePassword();
    };

    return (
        <CompleteRestoreStyle>
            <AuthTemplate>
                {isNeedToRedirectToLogin ? <RedirectPage to={allRoutes.signin.path} /> : ''}
                <Typography variant="h1" color="secondary">
                    {t('complete-password-restore-form.password-restore-title')}
                </Typography>
                <Typography variant="body1" color="secondary" className="restore-subtitle">
                    {t('complete-password-restore-form.password-restore-text')}
                </Typography>
                <div>
                    <Password
                        name="password"
                        label={t('complete-password-restore-form.password')}
                        value={formData.password}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        iconColor="primary"
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setFormData({ ...formData, password: value.target.value });
                        }}
                    />
                </div>
                <div>
                    <Password
                        name="confirm-password"
                        label={t('complete-password-restore-form.password-confirm')}
                        value={formData.passwordConfirm}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        iconColor="primary"
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setFormData({ ...formData, passwordConfirm: value.target.value });
                        }}
                    />
                </div>
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        onClick={completePasswordRestoreEvent}
                        type="submit"
                        variant="outlined"
                        color="primary"
                        className="send-button"
                        name="submit"
                    >
                        <Typography variant="h5">{t('forget-password-form.submit')}</Typography>
                    </Button>
                </Box>
            </AuthTemplate>
        </CompleteRestoreStyle>
    );
}
