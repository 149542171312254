import { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';

import OrderStatus from 'components/atoms/Order/OrderStatus';

import { OrderRow } from 'components/pages/Orders/types';

enum NameField {
    OrderId = 'id',
    Number = 'number',
    CreatedDate = 'createdDate',
    UserName = 'userName',
    DateReply = 'startDate',
    Status = 'status',
    Cost = 'cost',
}

type MediaSpaceOrdersGridProps = {
    rows: OrderRow[];
};

export default function MediaSpaceOrdersColumnsGrid(props: MediaSpaceOrdersGridProps): GridColDef[] {
    const { rows } = props;
    const { t } = useTranslation();

    const getColumns = useCallback(() => {
        return [
            {
                field: NameField.Number,
                headerName: t('orders.number'),
                renderCell: function renderRoles(params: GridCellParams) {
                    return <div>#{params.value}</div>;
                },
                flex: 0.3,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: NameField.CreatedDate,
                headerName: t('order.created-date'),
                renderCell: function renderCreatedDate(params: GridCellParams) {
                    const dateString = String(params.value).split(', ');
                    return (
                        <div style={{ display: 'block', textAlign: 'left' }}>
                            <Typography variant="body1">{dateString[0]}</Typography>
                            <Typography variant="body1">{dateString[1]}</Typography>
                        </div>
                    );
                },
                width: 200,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: NameField.UserName,
                headerName: t('order.user-name'),
                renderCell: function renderRoles(params: GridCellParams) {
                    return <div className="media_spaces_order-userName_cell">{params.row.userName}</div>;
                },
                flex: 1,
                align: 'left',
                headerAlign: 'center',
            },
            {
                field: NameField.DateReply,
                headerName: t('order.date-reply'),
                renderCell: function renderRoles(params: GridCellParams) {
                    return <div>{params.row.dateReply}</div>;
                },
                width: 150,
                headerAlign: 'center',
            },
            {
                field: NameField.Status,
                headerName: t('order.status.name'),
                renderCell: function renderStatus(params: GridCellParams) {
                    return <OrderStatus status={params.row.status} />;
                },
                width: 260,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: NameField.Cost,
                headerName: t('order.cost'),
                width: 160,
                headerAlign: 'center',
                renderCell: function renderCost(params: GridCellParams) {
                    return <div>{params.row.cost}</div>;
                },
            },
        ] as GridColDef[];
    }, [rows]);

    return getColumns();
}
