import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';

import GridCellLink from 'components/atoms/GridCellLink';

import allRoutes from 'router';

import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteButton.svg';
import { ActionButton } from 'components/templates/Devices/style';
import { ActionButtonIcon } from 'components/organisms/forms/Playlist/style';
import { CurrencyBaseVm } from 'models/currency.model';
import FlashGridHeader from '../../atoms/FlashGridHeader';
import { SortField } from '../../../models/paging/paging.model';

type CurrencyGridProps = {
    currencies: CurrencyBaseVm[];
    setDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setCurrencyId: React.Dispatch<React.SetStateAction<string>>;
};

export default function CurrencyColumnsGrid(props: CurrencyGridProps): GridColDef[] {
    const { currencies, setDeleteDialogOpen, setCurrencyId } = props;
    const { t } = useTranslation();
    const history = useHistory();

    async function deleteCurrency(id: string) {
        if (id) {
            setDeleteDialogOpen(true);
        }
    }

    const getColumns = useCallback(() => {
        return [
            {
                field: SortField.CurrencyName,
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'currency.code'} withPadding={false} />;
                },
                flex: 0.5,
                align: 'left',
                headerAlign: 'left',
                renderCell: function renderRoles(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.currencyProfile.path, { id: params.row.id }),
                                state: history.location.pathname,
                            }}
                        >
                            {params.row.code}
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.CurrencyCode,
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'currency.name'} withPadding={false} />;
                },
                renderCell: function renderRoles(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.currencyProfile.path, { id: params.row.id }),
                                state: history.location.pathname,
                            }}
                        >
                            <Typography variant="body1">
                                {params.row.name}&nbsp;
                                {params.row.isDefault && <span>({t('currency.default')})</span>}
                            </Typography>
                        </GridCellLink>
                    );
                },
                flex: 1,
                align: 'left',
                headerAlign: 'left',
            },
            {
                field: 'actions',
                headerName: t('currencies.actions'),
                width: 90,
                headerAlign: 'center',
                renderCell: function renderRowActions(params: GridCellParams) {
                    return (
                        <>
                            {!params.row.isDefault && (
                                <ActionButton
                                    data-id="delete-file"
                                    onClick={() => {
                                        setCurrencyId(params.id as string);
                                        deleteCurrency(params.id as string);
                                    }}
                                >
                                    <ActionButtonIcon as={DeleteIcon} />
                                </ActionButton>
                            )}
                        </>
                    );
                },
                sortable: false,
                disableClickEventBubbling: true,
            },
        ] as GridColDef[];
    }, [currencies]);

    return getColumns();
}
