import { FC } from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';

import { LoadingIndicatorStyle, LoadingStatus, LoaderLogo } from 'components/atoms/LoadingIndicatorPopup/style';

import logoImage from 'assets/images/logo.png';

export type LoadingIndicatorPopupProps = {
    isLoaded: boolean;
};

const LoadingIndicatorPopup: FC<LoadingIndicatorPopupProps> = ({ isLoaded }) => (
    <>
        {!isLoaded && (
            <>
                <LoadingIndicatorStyle />
                <LoadingStatus>
                    <LoaderLogo src={logoImage} />
                    <LinearProgress />
                </LoadingStatus>
            </>
        )}
    </>
);

export default LoadingIndicatorPopup;
