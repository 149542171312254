import * as React from 'react';
import { useState } from 'react';
import OrderProfile from '../DataGridUser';
import ResponsiveFlexBox from '../../atoms/ResponsiveFlexBox';
import { PaginationStyle } from './style';
import { OrderVm } from '../../../models/order';
import { useHistory } from 'react-router-dom';

export type PagedDataGridUserProps = {
    totalItems: number;
    defaultPage: number;
    orders: OrderVm[];
    summaryElement?: JSX.Element;
};

export default function PagedDataGridUser(props: PagedDataGridUserProps): JSX.Element {
    const { totalItems, defaultPage, orders, summaryElement } = props;
    const [page, setPage] = useState(defaultPage);
    const history = useHistory();

    const handleChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        history.push(`page${page}`);
        setPage(page);
    };

    return (
        <>
            {orders.map((order: OrderVm) => (
                <OrderProfile key={order.id} order={order} />
            ))}
            <ResponsiveFlexBox width="100%" justifyContent="space-between">
                {summaryElement}
                <PaginationStyle
                    count={totalItems}
                    page={page}
                    onChange={handleChange}
                    shape="rounded"
                    data-id="pagination"
                />
            </ResponsiveFlexBox>
        </>
    );
}
