import { FC, useState, useCallback, MutableRefObject } from 'react';

import { useTranslation } from 'react-i18next';

import { isEqual } from 'lodash';

import FlashDialog from 'components/atoms/dialog/FlashDialog';
import TextContent from 'components/atoms/dialog/TextContent';
import YesNoButtons from 'components/atoms/dialog/YesNoButtons';

import { IBaseFormRef } from 'models/interface/i-form-data';
import { FlashButtonProps } from 'components/atoms/controls/Button';

import { OptionButton, OptionButtonText } from 'components/atoms/controls/CancelButton/style';

export type CancelButtonProps = FlashButtonProps & {
    onClick: () => void;
    originalData: unknown;
    formData: unknown;
    baseRef: MutableRefObject<IBaseFormRef | undefined>;
};

const CancelButton: FC<CancelButtonProps> = ({
    onClick: onSubmit,
    baseRef,
    children,
    originalData,
    formData,
    ...buttonProps
}) => {
    const { t } = useTranslation();

    const [isSubmiting, setIsSubmiting] = useState(false);

    const handleClose = () => setIsSubmiting(false);

    const handleConfirm = () => {
        baseRef.current?.release();
        setTimeout(onSubmit, 0);
    };

    const handleClick = useCallback(() => {
        if (!isEqual(originalData, formData)) {
            setIsSubmiting(true);
        } else {
            onSubmit();
        }
    }, [originalData, formData]);

    return (
        <>
            <FlashDialog
                open
                title={t('general.cancel-changes-title')}
                isOpen={isSubmiting}
                onClose={handleClose}
                content={<TextContent text={t('general.cancel-changes-text')} />}
                controls={<YesNoButtons onConfirm={handleConfirm} onReject={handleClose} />}
            />
            <OptionButton onClick={handleClick} {...buttonProps}>
                <OptionButtonText>{children}</OptionButtonText>
            </OptionButton>
        </>
    );
};

export default CancelButton;
