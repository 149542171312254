import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { variables } from 'theme/variables';

export const NumericalCardStyled = styled.div({
    width: '100%',
    maxWidth: '440px',
    padding: '12px 30px',
    backgroundColor: variables.colours.light,
    border: `1px solid ${variables.colours.light}`,
    borderRadius: '20px',
    boxShadow: '0px 0 0 rgba(255, 255, 255, 0)',
    transition: 'box-shadow .2s',
    cursor: 'default',

    '@media only screen and (max-width: 414px)': {
        padding: '15px',

        '.gradient_font': {
            lineHeight: '1',
            marginBottom: '10px',
        },
    },

    ':hover': {
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
    },

    '.heading': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

        '@media only screen and (max-width: 414px)': {
            flexDirection: 'column',
            marginBottom: '10px',
        },
    },
});

export const NumericalCardTitleStyled = styled(Typography)({
    paddingTop: '14px',
    marginLeft: '36px',
    'font-weight': '600',
    fontSize: '25px',
    lineHeight: '137.01%',
    letterSpacing: '0.05em',
    color: variables.colours.dark,

    '@media only screen and (max-width: 414px)': {
        paddingTop: '0',
        marginLeft: '0',
    },
});

export const NumericalCardTextStyled = styled(Typography)({
    'font-weight': '400',
    fontSize: '20px',
    lineHeight: '137.01%',
    letterSpacing: '0.05em',
    color: variables.colours.dark,
});
