/* eslint-disable prettier/prettier */

import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { without } from 'lodash';

import { Formik, useField, useFormikContext } from 'formik';
import { MenuItem, Select, Slider, Typography } from '@material-ui/core';
import { BeforeAfterModifier, FunctionModifier } from 'react-day-picker';

import DatePicker from 'components/organisms/DatePicker';
import FilterOption from 'components/atoms/FilterOption';
import Spacer from 'components/atoms/Spacer';

import useSelector from 'hooks/useAppSelector';

import { getFilledDates } from 'api/orders';
import { orderReplaysOptions } from 'components/organisms/DateSubForm/constants';
import { generateRange } from 'shared/utils';

import { UserRoles } from 'shared/enums';
import { OrderFormValues } from 'components/organisms/forms/Order';
import { OrderScheduleModel } from 'models/order';

import {
    CalendarSection,
    FieldRow,
    InputsSection,
    RemoveActionButton,
    ScheduleForm,
} from 'components/organisms/ScheduleMicroForm/style';

import { ReactComponent as RemoveIcon } from 'assets/icons/RemoveCircle.svg';
import { getConfig } from '../../../config';

interface ScheduleMicroFormProps {
    name: string;
    onRemove: () => void;
    outerValues: OrderFormValues;
    updateSchedules: (updateSchedulesCallback: (values: OrderScheduleModel[]) => OrderScheduleModel[]) => void;
    scheduleIndex: number;
    minStart?: Date;
    maxDays?: number;
    removable?: boolean;
    disabledDays?: (FunctionModifier | Date | BeforeAfterModifier)[];
}

const ScheduleMicroForm: FC<ScheduleMicroFormProps> = ({
    onRemove,
    removable,
    outerValues,
    disabledDays = [],
    updateSchedules,
    scheduleIndex,
}) => {
    const { t } = useTranslation();

    const user = useSelector((state) => state.userReducer.user);

    const { values, setFieldValue, submitForm } = useFormikContext<OrderScheduleModel>();

    const [filledDates, setFilledDates] = useState<Date[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [allDatesUnavailable, setAllDatesUnavailable] = useState(false);
    const [isFictitiousMode, setIsFictitiousMode] = useState<boolean>(false);

    const [userStartDateRangeInDays, setUserStartDateRangeInDays] = useState<number>(3);
    const [userEndDateRangeInMonths, setUserEndDateRangeInMonths] = useState<number>(12);

    const [limitMonthsForStartDate, setLimitMonthsForStartDate] = useState<number>(3);
    const [limitMonthsForEndDate, setLimitMonthsForEndDate] = useState<number>(12);

    const maxHoursValue = 24;
    const actualMinHours = (moment().hours() + 2) % maxHoursValue;

    const isLateToday = (day: Date) => {
        const isToday = moment().isSame(day, 'day');
        const isLate = moment().hours() > 22;

        return isToday && isLate;
    };

    const [sliderValue, setSliderValue] = useState([actualMinHours, actualMinHours + 1]);

    const formattedStartDate = moment(values.startDate).format('L');
    const formattedEndDate = moment(values.endDate).format('L');

    const isAdmin = user.role === UserRoles.Admin || user.role === UserRoles.Operator;

    const defaultStartDate = getDefaultUserCalendarMinStartDate();
    const defaultEndDate = getDefaultUserCalendarMinEndDate(defaultStartDate);

    function getDefaultUserCalendarMinStartDate() {
        const date = new Date();
        if (!isAdmin && !isFictitiousMode) date.setDate(date.getDate() + userStartDateRangeInDays);
        return date;
    }

    function getDefaultUserCalendarMinEndDate(defaultStartDate: Date) {
        return moment(defaultStartDate).add(userEndDateRangeInMonths, 'months').startOf('day').toDate();
    }

    const unexcludePrevious = (values: OrderScheduleModel) => {
        updateSchedules((schedules) => {
            const { endDate, startDate } = schedules[scheduleIndex];

            const overlappingSchedules = schedules
                .map((schedule, index) => ({ ...schedule, index }))
                .filter(({ endDate, endTime, excludeDates, index, isShowAllDay, startDate, startTime }) => {
                    if (scheduleIndex === index) return false;
                    if (!(startDate && endDate && endDate && startDate)) return false;
                    if (!(startTime && endTime && endTime && startTime)) return false;
                    if (!excludeDates?.length) return false;

                    if (startTime >= endTime || endTime <= startTime) return false;
                    if (isShowAllDay) return true;
                    if (
                        (startDate >= startDate && startDate <= endDate) ||
                        (endDate <= endDate && endDate >= startDate)
                    )
                        return true;

                    return false;
                });

            const overridenSchedules = schedules.map((schedule, index) => {
                if (overlappingSchedules.length === 1) {
                    const datesToUnExclude = generateRange(startDate, endDate, true);

                    return {
                        ...(scheduleIndex === index ? values : schedule),
                        excludeDates: without(
                            (scheduleIndex === index ? values : schedule).excludeDates,
                            ...datesToUnExclude,
                        ) as string[],
                    };
                }

                return scheduleIndex === index ? values : schedule;
            });

            return overridenSchedules;
        });
    };

    const transformOfHoursToStr = (hours: number, withoutSeconds = false) => {
        let seconds = ':00';
        if (withoutSeconds) seconds = '';
        if (values.isShowAllDay) return '00:00' + seconds;
        if (hours < 10) return '0' + hours.toString() + ':00' + seconds;
        if (hours === 24) return '00:00' + seconds;
        return hours.toString() + ':00' + seconds;
    };

    const handleSliderChange = (event: ChangeEvent<unknown>, newValue: number | number[]) => {
        if ((newValue as number[])[0] !== (newValue as number[])[1]) setSliderValue(newValue as number[]);
        unexcludePrevious({
            ...values,
            startTime: transformOfHoursToStr(sliderValue[0]),
            endTime: transformOfHoursToStr(sliderValue[1]),
            isShowAllDay: transformOfHoursToStr(sliderValue[1]) === '00:00:00' && values.startTime === '00:00:00',
        });
    };

    useEffect(() => {
        setIsLoading(true);
        setFieldValue('startTime', transformOfHoursToStr(sliderValue[0]));
        setFieldValue('endTime', transformOfHoursToStr(sliderValue[1]));

        getFilledDates({
            startTime: transformOfHoursToStr(sliderValue[0]),
            endTime: transformOfHoursToStr(sliderValue[1]),
            duration: outerValues.contentDuration,
            replays: values.replays,
            isShowAllDay: values.isShowAllDay,
            mediaSpaceIds: outerValues.mediaSpaces,
        })
            .then(({ data }) => {
                const filledDates = data.map((date) => moment(date).toDate());
                setFilledDates(filledDates);
                validationFilledDates(filledDates);
            })
            .finally(() => setIsLoading(false));
    }, [sliderValue, values.isShowAllDay, values.replays, values.startDate, values.endDate]);

    useEffect(() => {
        if (
            values.startDate !== undefined &&
            values.startDate !== null &&
            values.endDate !== undefined &&
            values.endDate !== null &&
            values.startTime !== undefined &&
            values.startTime !== null &&
            values.endTime !== undefined &&
            values.endTime !== null
        ) {
            const initialStartTime = parseInt(values.startTime.split(':')[0]);
            const initialEndTime = parseInt(values.endTime.split(':')[0]);
            setSliderValue([initialStartTime, initialEndTime]);
        }
        const loadConfig = async () => {
            const data = await getConfig();
            setIsFictitiousMode(data.fictitiousMode);
            setLimitMonthsForStartDate(data.limitMonthsForStartDate);
            setLimitMonthsForEndDate(data.limitMonthsForEndDate);
            setUserStartDateRangeInDays(data.userStartDateRangeInDays);
            setUserEndDateRangeInMonths(data.userEndDateRangeInMonths);
        };

        loadConfig();
    }, []);

    const validationFilledDates = (filledDates: Date[]) => {
        if (values.startDate && values.endDate) {
            const startDate = new Date(values.startDate);
            const endDate = new Date(values.endDate);
            while (startDate <= endDate) {
                const hasFilledDates = filledDates.filter(
                    (x) => x.setHours(0, 0, 0, 0) === startDate.setHours(0, 0, 0, 0),
                );
                if (hasFilledDates.length > 0) {
                    setFieldValue('startDate', undefined);
                    setFieldValue('endDate', undefined);
                    submitForm();
                    break;
                }
                startDate.setDate(startDate.getDate() + 1);
            }
        }

        let freeDatesCount = 0;
        const currentDate = new Date(defaultStartDate);
        while (currentDate <= defaultEndDate) {
            const hasFilledDates = filledDates.filter(
                (x) => x.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0),
            );
            if (hasFilledDates.length === 0) freeDatesCount++;
            currentDate.setDate(currentDate.getDate() + 1);
        }
        setAllDatesUnavailable(freeDatesCount === 0);
    };

    return (
        <ScheduleForm>
            <InputsSection>
                <FieldRow>
                    {transformOfHoursToStr(sliderValue[0], true)} : {transformOfHoursToStr(sliderValue[1], true)}
                </FieldRow>
                <FieldRow>
                    <Slider
                        value={sliderValue}
                        min={0}
                        max={24}
                        onChange={handleSliderChange}
                        onChangeCommitted={handleSliderChange}
                        disabled={values.isShowAllDay}
                    />
                </FieldRow>
                <FieldRow>
                    <FilterOption
                        checked={values.isShowAllDay}
                        onClick={() => {
                            if (values.isShowAllDay) {
                                unexcludePrevious({
                                    ...values,
                                    isShowAllDay: false,
                                });
                                setSliderValue([actualMinHours, actualMinHours + 1]);
                            } else {
                                unexcludePrevious({
                                    ...values,
                                    startTime: transformOfHoursToStr(0),
                                    endTime: transformOfHoursToStr(24),
                                    isShowAllDay: true,
                                });
                            }
                        }}
                        text={t('order-form.show-all-day')}
                    />
                </FieldRow>
                <FieldRow>
                    <Select
                        value={values.replays}
                        onChange={({ target: { value } }) => {
                            setFieldValue('replays', value);
                            submitForm();
                        }}
                    >
                        {orderReplaysOptions().map(({ label, value }) => (
                            <MenuItem key={`${label}-${value}`} value={value}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FieldRow>
                <p>{t('order-form.time-warning')}</p>
                <Spacer />
                <FieldRow>
                    <Typography>
                        {values.startDate && values.endDate
                            ? `${t('order-form.selected-dates')} ${
                                  formattedStartDate === formattedEndDate
                                      ? formattedStartDate
                                      : `${formattedStartDate} – ${formattedEndDate}`
                              }`
                            : t('order-form.dates-not-selected')}
                    </Typography>
                    {removable && (
                        <RemoveActionButton onClick={onRemove}>
                            <Typography color="inherit" component="span">
                                {t('general.button-text.delete')}
                            </Typography>
                            <RemoveIcon />
                        </RemoveActionButton>
                    )}
                </FieldRow>
            </InputsSection>
            <CalendarSection>
                <DatePicker
                    value={[values.startDate, values.endDate] as [string, string]}
                    onChange={({ start, end, exclude }) => {
                        const datesToExclude = exclude;

                        const isToday = start && moment().isSame(start, 'day');

                        const newTime = {
                            startTime: values.startTime,
                            endTime: values.endTime,
                        };

                        unexcludePrevious({
                            ...values,
                            startDate: start,
                            endDate: end,
                            excludeDates: [...datesToExclude, ...filledDates.map((date) => date.toISOString())],
                            ...(isToday && !values.isShowAllDay ? newTime : {}),
                        });
                    }}
                    disableModifiers={[...filledDates, isLateToday, ...disabledDays]}
                    isLoading={isLoading}
                    minStartDays={!isAdmin && !isFictitiousMode ? 3 : 0}
                    maxStartMonths={limitMonthsForStartDate}
                    minEndMonths={limitMonthsForEndDate}
                />
                {allDatesUnavailable && (
                    <div>
                        <Typography color="error">{t('order.errors.error-calendar-length')}</Typography>
                    </div>
                )}
            </CalendarSection>
        </ScheduleForm>
    );
};

const ScheduleMicroFormContainer: FC<ScheduleMicroFormProps> = (props) => {
    const [{ value: outerValues }, , { setValue }] = useField<OrderScheduleModel>(props.name);

    const handleSubmit = useCallback(
        (values) => {
            setValue(values);
        },
        [setValue],
    );

    return (
        <Formik initialValues={outerValues || {}} enableReinitialize onSubmit={handleSubmit}>
            <ScheduleMicroForm {...props} />
        </Formik>
    );
};

export default ScheduleMicroFormContainer;
