import { FC, useState, useEffect, useMemo, useCallback } from 'react';

import * as yup from 'yup';
import { pick } from 'lodash';

import { useTranslation } from 'react-i18next';

import { Formik, useFormikContext } from 'formik';
import { Button, Typography } from '@material-ui/core';

import { getMediaSpaces, getMediaSpacesHasTariffs } from 'api/media-space';

import useSelector from 'hooks/useAppSelector';

import Spacer from 'components/atoms/Spacer';

import { MediaSpaceVm } from 'models/media-space.model';
import { UserRoles } from 'shared/enums';
import { FormStepComponent } from 'components/organisms/MultiStepForm/types';

import { StyledMediaSpaceSubForm, MediaSpaceBlock } from 'components/organisms/MediaSpaceSubForm/style';
import { useDispatch } from 'react-redux';
import { deleteMediaSpacesForm, setAllMediaSpaces, setMediasSpacesForm } from '../../../store/form/actions';
import MediaSpacesList from '../MediaSpacesList';

export interface MediaSpaceFormValues {
    mediaSpaces: string[];
}

const validationSchema = yup.object().shape({
    mediaSpaces: yup.array().min(1),
});

//TODO: refactor (optimize useEffects and useState(also related to Redux))
const MediaSpaceSubForm: FC = () => {
    const { t } = useTranslation();

    const { isValid, values, setFieldValue, submitForm, setFieldTouched } = useFormikContext<MediaSpaceFormValues>();

    const user = useSelector((state) => state.userReducer.user);

    const [mediaSpaces, setMediaSpaces] = useState<MediaSpaceVm[]>([]);
    const [alert, setAlert] = useState<boolean>(false);
    const storeMediaSpaces = useSelector((store) => store.formReducer.mediaSpaces);
    const allMediaSpaces = useSelector((store) => store.formReducer.allMediaSpaces);
    const dispatch = useDispatch();

    const [checked, setChecked] = useState<string[]>(storeMediaSpaces);

    //Change to 0 if there is no valid mediaSpaces (for tests)
    const devicesFilterQueryParam = 1;
    // const devicesFilterQueryParam = 0;

    useMemo(() => {
        return mediaSpaces
            .filter(({ id }) => values.mediaSpaces.includes(id))
            .map(({ id, latitude, longitude, name, address }) => ({
                id,
                isHighlighted: values.mediaSpaces.includes(id),
                lat: latitude,
                long: longitude,
                address,
                name,
            }));
    }, [mediaSpaces, values.mediaSpaces]);

    const allSelected = useMemo(() => {
        return values.mediaSpaces.length > 0 && values.mediaSpaces.length === mediaSpaces.length;
    }, [values.mediaSpaces, mediaSpaces]);

    const [amountOfMediaSpaces, setAmountOfMediaSpaces] = useState<number>(0);

    const onChangeMediaSpaces = (value: string[]) => {
        dispatch(setMediasSpacesForm(value));
        setFieldValue('mediaSpaces', value);
    };

    const handleSelectAll = useCallback(() => {
        const mediaSpacesIds = mediaSpaces.map(({ id }) => id);
        if (allMediaSpaces) {
            dispatch(deleteMediaSpacesForm());
            dispatch(setAllMediaSpaces(false));
            onChangeMediaSpaces([]);
            setChecked([]);
            setAmountOfMediaSpaces(0);
            return setFieldValue('mediaSpaces', []);
        }
        dispatch(setMediasSpacesForm(mediaSpacesIds));
        setChecked(mediaSpacesIds);
        setAmountOfMediaSpaces(mediaSpacesIds.length);
        setFieldValue('mediaSpaces', mediaSpacesIds);
        dispatch(setAllMediaSpaces(true));
        onChangeMediaSpaces(mediaSpacesIds);
    }, [allSelected, mediaSpaces, allMediaSpaces]);

    useEffect(() => {
        onChangeMediaSpaces(checked);
        setAmountOfMediaSpaces(checked.length);
    }, [checked]);

    useEffect(() => {
        if (user.role === UserRoles.Admin) {
            setTimeout(() => {
                void getMediaSpaces({ devicesFilterRule: devicesFilterQueryParam })
                    .then(({ data }) => setMediaSpaces(data))
                    .catch((err) => console.error(err));
            }, 300);
        } else {
            setTimeout(() => {
                void getMediaSpacesHasTariffs()
                    .then(({ data }) => setMediaSpaces(data))
                    .catch((err) => console.error(err));
            }, 300);
        }
    }, [user.role, user.lc]);

    useEffect(() => {
        if (
            storeMediaSpaces.length !== values.mediaSpaces.length &&
            !storeMediaSpaces.some((mediaSpace) => values.mediaSpaces.includes(mediaSpace))
        ) {
            onChangeMediaSpaces(storeMediaSpaces);
            setTimeout(() => setFieldTouched('mediaSpaces', true));
        }
    }, []);

    useEffect(() => {
        setAlert(
            values.mediaSpaces.length !== 0 &&
                values.mediaSpaces.some((valueMediaSpace) => {
                    return mediaSpaces.every((responseMediaSpace) => valueMediaSpace !== responseMediaSpace.id);
                }),
        );
    }, [mediaSpaces, alert, values.mediaSpaces]);

    useEffect(() => {
        if (mediaSpaces.length > storeMediaSpaces.length) {
            dispatch(setAllMediaSpaces(false));
        }
        if (mediaSpaces.length === storeMediaSpaces.length && storeMediaSpaces.length) {
            dispatch(setAllMediaSpaces(true));
        }
    }, [storeMediaSpaces.length]);

    return (
        <StyledMediaSpaceSubForm>
            <MediaSpacesList
                allSelected={allSelected}
                setAllSelected={handleSelectAll}
                amountOfMediaSpaces={amountOfMediaSpaces}
                checked={checked}
                setChecked={setChecked}
            ></MediaSpacesList>
            <MediaSpaceBlock>
                {alert && <Typography color="error">{t('order-form.invalid-mediaspace-appeared')}</Typography>}
                <Spacer />
                {isValid && (
                    <Button
                        variant="outlined"
                        color="primary"
                        component="label"
                        className="continue-button"
                        onClick={submitForm}
                        id="SEO-createorder-in-orders-page-step-3"
                    >
                        <Typography variant="h5">{t('order-form.save-and-continue')}</Typography>
                    </Button>
                )}
            </MediaSpaceBlock>
        </StyledMediaSpaceSubForm>
    );
};

const MediaSpaceSubFormContainer: FormStepComponent = ({ onSubmit }) => {
    const { values: outerValues } = useFormikContext();

    const handleSubmit = useCallback(
        (values) => {
            onSubmit(values);
        },
        [onSubmit],
    );

    const initialValues = useMemo(() => pick(outerValues, 'mediaSpaces'), [outerValues]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            validateOnMount
            enableReinitialize
        >
            <MediaSpaceSubForm />
        </Formik>
    );
};

export default MediaSpaceSubFormContainer;
