import * as React from 'react';
import ForgetPasswordForm from '../../organisms/forms/ForgetPassword';
import AuthTemplate from '../Auth';
import { useTranslation } from 'react-i18next';
import { StyledRestore } from './style';
import { Typography } from '@material-ui/core';

export default function RestorePasswordTemplate(): JSX.Element {
    const { t } = useTranslation();

    return (
        <StyledRestore>
            <AuthTemplate>
                <Typography variant="h1" color="secondary">
                    {t('forget-password-form.header')}
                </Typography>
                <div className="restore-text">
                    <Typography variant="body1" color="secondary">
                        {t('forget-password-form.subheader')}
                    </Typography>
                    <Typography variant="body1" color="secondary">
                        {t('forget-password-form.subheader-second')}
                    </Typography>
                </div>
                <ForgetPasswordForm />
            </AuthTemplate>
        </StyledRestore>
    );
}
