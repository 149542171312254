import { variables } from '../../../../theme/variables';
import styled from 'styled-components';
import { MenuItem, Menu } from '@material-ui/core';

export const NotifStyled = styled(MenuItem)(({}) => ({
    whiteSpace: 'normal',
    '.notifications-text': {
        color: variables.colours.light,
    },
    '.reset-text': {
        color: variables.colours.default,
        ':hover': {
            color: variables.colours.primary,
        },
    },
    '.MuiLink-underlineHover': {
        width: '100%',
        textDecoration: 'none',
    },
    '&:hover': {
        background: variables.colours.secondary,
    },
}));

export const NotifMenuStyled = styled(Menu)(({}) => ({
    '.MuiPaper-root': {
        backgroundColor: variables.backgoroundColours.dark,
    },
    'ul.MuiList-padding': {
        width: '25.625rem',
        backgroundColor: variables.backgoroundColours.dark,
        '@media only screen and (min-width : 375px) and (max-width : 767px)': {
            width: '18.625rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 374px)': {
            width: '17.625rem',
        },
        '@media only screen and (min-width : 1440px)': {
            width: '27.625rem',
        },
    },
}));
