import React from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import GoToOrderChatButton from 'components/atoms/controls/GoToOrderChatButton';
import NotificationLink from 'components/atoms/controls/notifications/NotificationLink';

import { ReactComponent as OrderNotification } from 'assets/icons/OrderNotification.svg';
import { OrderStatusType } from '../../../../../shared/enums';

export type NotificationItemProps = {
    onClick: (e: React.MouseEvent<unknown, MouseEvent>) => void;
    orderId?: string;
    orderNumber?: number;
    orderStatus?: OrderStatusType;
    chatId: string;
};

export default function OrderCanceledNotificationLink(props: NotificationItemProps): JSX.Element {
    const { onClick, orderId, orderNumber, orderStatus, chatId } = props;

    const { t } = useTranslation();

    return (
        <NotificationLink
            icon={<OrderNotification />}
            onHeaderClick={onClick}
            orderId={orderId}
            header={t('order.status-lable.cancelled')}
            body={
                <>
                    <div onClick={onClick}>
                        {t('order.order')}
                        {orderNumber}
                        {t('order.status-lable.cancelled-coment-start')}
                    </div>
                    <div>
                        <GoToOrderChatButton
                            className="notification"
                            orderId={orderId}
                            orderNumber={orderNumber}
                            orderStatus={orderStatus}
                            chatId={chatId}
                            color="primary"
                            size="large"
                        >
                            <Typography variant="subtitle1" className="chat-button">
                                {t('order.status-lable.cancelled-coment-button')}
                            </Typography>
                        </GoToOrderChatButton>
                        {t('order.status-lable.cancelled-coment-end')}
                    </div>
                </>
            }
        />
    );
}
