import React, { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';

import { PaginationItem } from '@material-ui/lab';

import Spacer from 'components/atoms/Spacer';
import ItemsCount from 'components/atoms/ItemsCount';
import DataGrid, { GridProps } from 'components/organisms/DataGrid';

import { PaginationStyle, StyledDataGridFooter } from 'components/organisms/PagedDataGrid/style';

export type PagedDataGridProps<T> = GridProps<T> & {
    noDataMessage: JSX.Element;
    totalPages: number;
    totalItems?: number;
    defaultPage: number;
    customPagination?: boolean;
    summaryElement?: JSX.Element;
    onChangePage?: (page: number) => void;
};

export default function PagedDataGrid<T>(props: PagedDataGridProps<T>): JSX.Element {
    const { totalItems, totalPages, defaultPage, summaryElement, rows, onChangePage } = props;
    const [page, setPage] = useState(defaultPage);

    const history = useHistory();

    const handleChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        setPage(page);
        if (onChangePage) onChangePage(page);
    };

    useEffect(() => {
        setPage(defaultPage);
    }, [defaultPage]);

    return (
        <>
            <DataGrid {...props} name-id="data-grid" />
            {rows.length > 0 && (
                <StyledDataGridFooter>
                    {summaryElement}
                    {totalItems !== undefined && <ItemsCount value={totalItems} />}
                    <Spacer />
                    <PaginationStyle
                        count={totalPages}
                        page={page}
                        onChange={handleChange}
                        shape="rounded"
                        name-id="pagination"
                        renderItem={(item) => {
                            return onChangePage ? (
                                // for order step2 page
                                <PaginationItem {...item} />
                            ) : (
                                // other pages with pagination
                                <PaginationItem
                                    component={Link}
                                    to={{ pathname: `page${item.page}`, state: history.location.pathname }}
                                    {...item}
                                />
                            );
                        }}
                    />
                </StyledDataGridFooter>
            )}
        </>
    );
}
