import React, { Dispatch, SetStateAction } from 'react';
import ResponsiveFlexBox from '../../ResponsiveFlexBox';
import { Breadcrumbs, IconButton, Tooltip, Typography } from '@material-ui/core';
import { LinkStyle, MediaSpacesAmount, LeftSideContainer } from './style';
import { ReactComponent as Map } from '../../../../assets/icons/Map.svg';
import { ReactComponent as TableIcon } from '../../../../assets/icons/TableIcon.svg';
import { useTranslation } from 'react-i18next';
import { variables } from '../../../../theme/variables';
import FilterOption from 'components/atoms/FilterOption';

export type MediaSpacesFilterProps = {
    setTableMap: Dispatch<SetStateAction<boolean>>;
    allSelected: boolean;
    setAllSelected: () => void;
    amountOfMediaSpaces: number;
    tableMap: boolean;
};

export default function MediaSpacesSubFormFilter(props: MediaSpacesFilterProps): JSX.Element {
    const { tableMap, setTableMap, amountOfMediaSpaces, allSelected, setAllSelected } = props;
    const { t } = useTranslation();

    return (
        <ResponsiveFlexBox width="100%" justifyContent="space-between" marginBottom="1.25rem" alignItems="center">
            <LeftSideContainer>
                <LinkStyle>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Tooltip
                            title={
                                <Typography variant="body1" style={{ color: variables.colours.light }}>
                                    {t('media-spaces.map')}
                                </Typography>
                            }
                            placement="top-start"
                            arrow
                        >
                            <IconButton
                                color="inherit"
                                onClick={() => {
                                    setTableMap(false);
                                }}
                                name="map-button"
                            >
                                <div className={tableMap ? 'map-button-false' : 'map-button-true'}>
                                    <Map className="map-icon" />
                                </div>
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={
                                <Typography variant="body1" style={{ color: variables.colours.light }}>
                                    {t('media-spaces.list-of-media-spaces')}
                                </Typography>
                            }
                            placement="top-start"
                            arrow
                        >
                            <IconButton
                                color="inherit"
                                onClick={() => {
                                    setTableMap(true);
                                }}
                                name="list-button"
                            >
                                <TableIcon className={tableMap ? 'list-icon list-active' : 'list-icon'} />
                            </IconButton>
                        </Tooltip>
                    </Breadcrumbs>
                </LinkStyle>
                <FilterOption
                    text={t('order-form.button-text.select-all')}
                    checked={allSelected}
                    onClick={setAllSelected}
                />
            </LeftSideContainer>
            <MediaSpacesAmount>
                {t('order-form.media-spaces-list.amount')} {amountOfMediaSpaces}
            </MediaSpacesAmount>
        </ResponsiveFlexBox>
    );
}
