import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const StyledTemplate = styled.div(({}) => ({
    '.container': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            alignItems: 'normal',
        },
    },
    h1: {
        '@media only screen and (min-width : 320px) and (max-width : 374px)': {
            width: '60%',
        },
        color: variables.colours.primary,
    },
    '.map-handler': {
        height: '43.75rem',
        marginBottom: '1.25rem',

        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            height: '25rem',
            marginBottom: '1.25rem',
        },
    },
    '.price-filter-buttons': {
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '100%',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    '.attention': {
        marginLeft: '1.25rem',
        marginBottom: '-0.188rem',
        '@media only screen and (min-width : 375px) and (max-width : 1366px)': {
            marginBottom: '-0.288rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 374px)': {
            marginLeft: 0,
        },
    },
    '.action': {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        height: '1.813rem',
        h6: {
            fontSize: '1.7rem',
            color: variables.colours.primary,
            '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                fontSize: '1.5rem',
            },
            '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                fontSize: '1.25rem',
            },
        },
        width: 'auto',
        ':hover': {
            textDecoration: 'none',
        },
        svg: {
            fontSize: '1.375rem',
            marginBottom: '-0.188rem',
            marginLeft: '0.35rem',
            '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                fontSize: '1.25rem',
                marginBottom: '-0.188rem',
                marginLeft: '0.313rem',
            },
        },
    },
}));

export const StyledHeaderyTypography = styled.div(({}) => ({
    h1: {
        color: variables.colours.primary,
    },
    p: {
        color: variables.colours.default,
    },
    marginBottom: '0.625rem',
    paddingBottom: '0.313rem',
}));
