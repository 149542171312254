import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { generatePath, useHistory } from 'react-router-dom';
import { FC, useCallback } from 'react';
import { MomentFormats, OrderStatusType } from '../../../shared/enums';
import OrderStatus from '../../atoms/Order/OrderStatus';
import { ReactComponent as PaymentAttention } from '../../../assets/icons/PaymentAttention.svg';
import { Avatar, Box, Tooltip, Typography } from '@material-ui/core';
import { variables } from '../../../theme/variables';
import allRoutes from 'router';
import GridCellLink from 'components/atoms/GridCellLink';
import FlashGridHeader from '../../atoms/FlashGridHeader';
import { SortField } from '../../../models/paging/paging.model';
import { OrderDataGridShortVm } from '../../../models/order';
import { getPhotoSrc } from '../../../api/photo';
import moment from 'moment/moment';

type OrdersGridCellLinkProps = {
    orderId: string;
    children: React.ReactNode | JSX.Element;
};
const OrdersGridCellLink: FC<OrdersGridCellLinkProps> = (props) => {
    const { orderId, children } = props;
    const history = useHistory();

    return (
        <GridCellLink
            to={{
                pathname: generatePath(allRoutes.orderProfile.path, { id: orderId }),
                state: history.location.pathname,
            }}
        >
            {children}
        </GridCellLink>
    );
};

type OrdersGridProps = {
    rows: OrderDataGridShortVm[];
    sortable?: boolean;
};
export default function OrdersColumnsGrid(props: OrdersGridProps): GridColDef[] {
    const { rows, sortable = true } = props;
    const { t } = useTranslation();

    const getColumns = useCallback(() => {
        return [
            {
                field: SortField.OrderNumber,
                width: 90,
                sortable: sortable,
                align: 'center',
                headerAlign: 'center',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'orders.number'} withPadding={true} paddingLeft={24} />;
                },
                renderCell: (params: GridCellParams) => {
                    return renderCellOrderNumber(params);
                },
            },
            {
                field: SortField.CreatedDate,
                flex: 1,
                minWidth: 130,
                sortable: sortable,
                align: 'center',
                headerAlign: 'center',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'order.created-date'} withPadding={true} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    const createdDate = new Date(Date.parse(params.row.createdDate)).toLocaleString('ru', {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    });
                    const dateString = String(createdDate).split(', ');
                    return (
                        <OrdersGridCellLink orderId={params.row.id}>
                            <Typography variant="body1">{dateString[0]}</Typography>
                            <Typography variant="body1">{dateString[1]}</Typography>
                        </OrdersGridCellLink>
                    );
                },
            },
            {
                field: SortField.OrderUserName,
                flex: 1,
                minWidth: 130,
                align: 'left',
                sortable: sortable,
                headerAlign: 'center',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'order.user-name'} withPadding={false} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <OrdersGridCellLink orderId={params.row.id}>
                            <Box marginRight="0.813rem">
                                <Avatar
                                    style={{ width: '47px', height: '47px' }}
                                    alt={params.row.userFullName && params.row.userFullName}
                                    src={getPhotoSrc(params.row.userPhotoUrl)}
                                >
                                    <Typography variant="body1" className="user-name">
                                        {params.row.userFullName && params.row.userFullName[0]}
                                    </Typography>
                                </Avatar>
                            </Box>
                            <Tooltip
                                title={
                                    <Typography
                                        variant="body1"
                                        style={{ color: variables.colours.light, lineHeight: '137.01%' }}
                                    >
                                        {params.row.userFullName}
                                    </Typography>
                                }
                                placement="top-start"
                                arrow
                            >
                                <Typography variant="body1">{params.row.userFullName}</Typography>
                            </Tooltip>
                        </OrdersGridCellLink>
                    );
                },
            },
            {
                field: SortField.OrderDateReply,
                flex: 1,
                minWidth: 130,
                sortable: sortable,
                align: 'center',
                headerAlign: 'center',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'order.date-reply'} withPadding={false} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <OrdersGridCellLink orderId={params.row.id}>
                            <Box width="100%">
                                {params.row.startDate == params.row.endDate ? (
                                    <Typography variant="body1">
                                        {params.row.startDate &&
                                            moment(params.row.startDate).format(MomentFormats.Short)}
                                    </Typography>
                                ) : (
                                    <Typography variant="body1">
                                        {params.row.startDate &&
                                            moment(params.row.startDate).format(MomentFormats.Short)}
                                        -{params.row.endDate && moment(params.row.endDate).format(MomentFormats.Short)}
                                    </Typography>
                                )}
                            </Box>
                        </OrdersGridCellLink>
                    );
                },
            },
            {
                field: SortField.OrderStatus,
                flex: 1,
                minWidth: 130,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'order.status.name'} withPadding={false} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <OrdersGridCellLink orderId={params.row.id}>
                            <OrderStatus status={params.row.status} />
                        </OrdersGridCellLink>
                    );
                },
            },
            {
                field: SortField.OrderCost,
                flex: 1,
                minWidth: 130,
                align: 'center',
                sortable: sortable,
                headerAlign: 'center',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'order.cost'} withPadding={true} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <OrdersGridCellLink orderId={params.row.id}>
                            <Typography variant="body1">
                                {params.row.cost} {params.row.currencyCode}
                            </Typography>
                        </OrdersGridCellLink>
                    );
                },
            },
        ] as GridColDef[];
    }, [rows]);

    function renderCellOrderNumber(params: GridCellParams) {
        if (params.row.status === OrderStatusType.PaymentRefundFailed) {
            return (
                <OrdersGridCellLink orderId={params.row.id}>
                    <Typography variant="body1" className="order-number">
                        #{params.row.number}
                        <Tooltip
                            title={
                                <Typography variant="body1" style={{ color: variables.colours.light }}>
                                    {t('server-messages.payment-not-refunded')}
                                </Typography>
                            }
                            placement="top-start"
                            arrow
                        >
                            <PaymentAttention className="payment-attention" />
                        </Tooltip>
                    </Typography>
                </OrdersGridCellLink>
            );
        }
        return (
            <OrdersGridCellLink orderId={params.row.id}>
                <Typography variant="body1" className="order-number">
                    #{params.row.number}
                </Typography>
            </OrdersGridCellLink>
        );
    }

    return getColumns();
}
