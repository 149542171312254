import { ReactComponent as Price } from 'assets/icons/Price.svg';
import { Icon } from 'components/atoms/Icon/style';

export default function PricesIcon(): JSX.Element {
    return (
        <Icon style={{ margin: 'auto 0 auto 5px' }}>
            <Price />
        </Icon>
    );
}
