import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { ButtonBase, ButtonBaseProps, lighten } from '@material-ui/core';
import { variables } from 'theme/variables';

export const StyledUnregisteredUserHedaer = styled.div(({}) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    padding: '6px 0',
    'z-index': '500',
    backgroundColor: variables.backgoroundColours.dark,
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',

    '.content': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        minHeight: '104px',

        '.buttons': {
            '.desktop': {
                display: 'flex',
                gap: '24px',

                '@media only screen and (max-width: 1024px)': {
                    display: 'none',
                },
            },

            '.mobile': {
                display: 'none',

                '@media only screen and (max-width: 1024px)': {
                    display: 'flex',
                },
            },
        },
    },
}));

export const SignInButtonStyled = styled(ButtonBase).attrs({
    component: Link,
})<ButtonBaseProps & LinkProps>({
    padding: '0.5rem 1.5rem',
    border: `2px solid ${variables.colours.primary}`,
    borderRadius: 8,
    backgroundColor: variables.backgoroundColours.dark,
    transition: '.2s ease',
    color: variables.colours.light,

    ':hover': {
        backgroundColor: lighten(variables.colours.primary, 0.15),
    },

    '.button-text': {
        color: variables.colours.light,
    },
});

export const RegistrationButtonStyled = styled(ButtonBase).attrs({
    component: Link,
})<ButtonBaseProps & LinkProps>({
    padding: '0.5rem 1.2rem',
    borderRadius: 8,
    backgroundColor: variables.buttonColours.primary,
    transition: '.2s ease',
    color: variables.colours.light,

    ':hover': {
        backgroundColor: lighten(variables.colours.primary, 0.15),
    },

    '.button-text': {
        color: variables.colours.light,
    },
});
