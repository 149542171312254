import styled from 'styled-components';
import { Accordion } from '@material-ui/core';
import { variables } from 'theme/variables';
import AccordionChevron from 'assets/icons/accordion-chevron.svg';

export const FlashAccordionStyled = styled(Accordion)({
    width: '100%',
    marginBottom: '20px!important',
    boxShadow: '0px 4px 10px 0 rgba(0, 0, 0, 0.25)',
    borderRadius: '20px!important',
    backgroundColor: variables.colours.light,
    overflow: 'hidden',

    ':last-child': {
        marginBottom: '0px!important',
    },

    '.accordion-header': {
        padding: '35px 38px',

        '.MuiAccordionSummary-content': {
            margin: '0',

            '.accordion-header-text': {
                'font-weight': '500',
                lineHeight: '137.01%',
                fontSize: '25px',
                letterSpacing: '0.05em',
            },
        },

        '.accordion-header-icon': {
            fontSize: '60px',
        },
    },

    '.accordion-content': {
        padding: '0 38px 15px',

        '.accordion-content-text': {
            'font-weight': '400',
            lineHeight: '137.01%',
            fontSize: '20px',
            letterSpacing: '0.05em',
        },
    },
});

export const FlashAccordionIcon = styled.div({
    width: '30px',
    height: '15px',
    backgroundImage: `url('${AccordionChevron}')`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
});
