import React, { useEffect, useState } from 'react';
import { DaysOfWeek } from '../../../../shared/enums';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { StyledButton, StyledBox } from './style';
import { Typography } from '@material-ui/core';

export type WeekDayPickerProps = {
    startFromSunday: boolean;
    selectedByDefault: Array<DaysOfWeek>;
    disabled?: boolean;
    onChange?: (selected: Array<DaysOfWeek>) => void;
};

export default function WeekDayPicker(props: WeekDayPickerProps): JSX.Element {
    const { disabled, startFromSunday, selectedByDefault, onChange } = props;

    const [selected, setSelected] = useState<Array<DaysOfWeek>>([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (selected != selectedByDefault) {
            setSelected(selectedByDefault);
        }
    }, [selectedByDefault]);

    const daysOfWeek = [
        DaysOfWeek.Monday,
        DaysOfWeek.Tuesday,
        DaysOfWeek.Wednesday,
        DaysOfWeek.Thursday,
        DaysOfWeek.Friday,
        DaysOfWeek.Satturday,
    ];

    if (startFromSunday) {
        daysOfWeek.unshift(DaysOfWeek.Sunday);
    } else {
        daysOfWeek.push(DaysOfWeek.Sunday);
    }

    const handleClick = (day: DaysOfWeek) => {
        const clone = cloneDeep(selected);
        const indexOfDay = clone.indexOf(day);
        if (indexOfDay >= 0) {
            if (clone.length > 1) {
                clone.splice(indexOfDay, 1);
            }
        } else {
            clone.push(day);
        }
        setSelected(
            clone.sort((a, b) => {
                const indexA = daysOfWeek.indexOf(a);
                const indexB = daysOfWeek.indexOf(b);
                return indexA - indexB;
            }),
        );
        onChange && onChange(clone);
    };

    return (
        <StyledBox>
            {daysOfWeek.map((d, i) => (
                <StyledButton
                    disabled={disabled}
                    key={i}
                    variant="outlined"
                    color={selected.indexOf(d) >= 0 ? 'primary' : undefined}
                    onClick={() => handleClick(d)}
                    name="day-of-week"
                >
                    <Typography variant="body1">{t(`weekdays-short.${d}`)}</Typography>
                </StyledButton>
            ))}
        </StyledBox>
    );
}
