import * as React from 'react';
import { FAQStyled } from './style';
import FlashAccordion from 'components/molecules/Accordion';
import { Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type AccordionObject = {
    title: string;
    text: string;
    hasLink?: boolean;
    linkText?: string;
    link?: string;
};

export default function FAQ(): JSX.Element {
    const { t } = useTranslation();
    const accordions = t('home-page.faq.accordions', { returnObjects: true }) as AccordionObject[];

    function parseText(accordion: AccordionObject) {
        let parsedText: React.ReactNode;
        if (accordion.hasLink) {
            const splittedString = accordion.text.split('#thisLink#');
            parsedText = (
                <>
                    {splittedString[0]}
                    <Link href={accordion.link}>{accordion.linkText}</Link>
                    {splittedString[1]}
                </>
            );
        } else {
            parsedText = <>{accordion.text}</>;
        }
        return parsedText;
    }

    return (
        <FAQStyled>
            <div className="accordions" itemScope itemType="https://schema.org/FAQPage">
                {accordions.map((accordion, i) => (
                    <FlashAccordion key={i} id={`home-${i}`} title={accordion.title}>
                        {parseText(accordion)}
                    </FlashAccordion>
                ))}
            </div>
            <Typography className="tip">
                {t('home-page.faq.tip-text')}
                <Link href="/contacts" id="SEO-contactme-home-faq">
                    {t('home-page.faq.tip-text-in-link')}
                </Link>
            </Typography>
        </FAQStyled>
    );
}
