import styled from 'styled-components';

import { Box, IconButton as Icon } from '@material-ui/core';

import Password from 'components/atoms/PasswordInput';
import ResponsiveFlexBox from 'components/atoms/ResponsiveFlexBox';
import FlashDatePicker from 'components/molecules/forms/FlashDatePicker';
import FormControlTextInput from 'components/molecules/forms/FormControlTextInput';

import { variables } from 'theme/variables';

interface StyledUserInfoProps {
    $disabled: boolean | undefined;
}

export const StyledUserInfo = styled.div<StyledUserInfoProps>(({ $disabled }) => ({
    '.MuiInput-underline': {
        '::before': {
            borderBottom: !$disabled ? `3px solid ${variables.colours.main}` : 'none',
        },
    },
}));

export const PasswordHolder = styled.div(({}) => ({
    position: 'relative',

    '.eye-icon': {
        marginRight: '2.5rem',
    },
}));

export const IconButton = styled(Icon).attrs({
    color: 'default',
})({
    position: 'absolute',
    top: '1.303rem',
    right: 0,
    '@media only screen and (min-width: 320px) and (max-width: 1440px)': {
        top: '1rem',
    },
});

export const UserNameBox = styled(ResponsiveFlexBox)(({}) => ({
    justifyContent: 'space-between',
}));

export const StyledTextInput = styled(FormControlTextInput).attrs({
    color: 'secondary',
})({});

export const BirthdayPicker = styled(FlashDatePicker).attrs({
    color: 'secondary',
    variant: 'body1',
})({});

export const StyledBox = styled(Box)(({ width }) => ({
    width: width > 767 ? '45%' : '100%',
}));

interface PasswordInputProps {
    disabled?: boolean;
}

export const PasswordInput = styled(Password).attrs({
    iconColor: 'default',
    color: 'secondary',
})<PasswordInputProps>(({ disabled }) => ({
    '&.MuiInput-underline': {
        '::before': {
            borderBottom: !disabled ? `3px solid ${variables.colours.main}` : '',
        },
    },
}));

export const PhoneWrapper = styled.div({
    margin: '10px 0',

    '.MuiFormControl-root': {
        width: '100%',
    },
});

export const StyledProfileSelect = styled.div({
    position: 'relative',
    paddingTop: '26px',

    '.label': {
        content: '""',
        position: 'absolute',
        top: '7px',
        left: 0,
        fontSize: '13px',
        color: variables.colours.dark,
    },
});
