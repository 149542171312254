import { variables } from '../../../theme/variables';
import styled from 'styled-components';

export const StyledHeaderyTypography = styled.div(({}) => ({
    '.header': {
        color: variables.colours.primary,
        marginBottom: '0.625rem',
        paddingBottom: '0.313rem',
        borderBottom: `1.5px solid ${variables.colours.default}`,
    },
    '.subheader': {
        color: variables.colours.default,
        marginBottom: '1.25rem',
    },
    '.attention': {
        marginLeft: '1.25rem',
        marginBottom: '-0.125rem',
        '@media only screen and (min-width : 375px) and (max-width : 1366px)': {
            marginBottom: '-0.288rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 374px)': {
            marginLeft: '0.25rem',
            marginBottom: '-0.388rem',
        },
    },
}));
