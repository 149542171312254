import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const DatePickerStyle = styled.div(({}) => ({
    width: '100%',
    '.MuiTextField-root': {
        width: '100%',
    },
    '.react-datepicker__header': {
        background: variables.backgoroundColours.light,
        border: 0,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.438rem',
    },
    '.react-datepicker': {
        border: 0,
        width: '100%',
    },
    '.react-datepicker__day-names': {
        width: '16.875rem',
        justifyContent: 'space-between',
        display: 'flex',
        '.react-datepicker__day-name': {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '1.25rem',
            lineHeight: '1.688rem',
            color: variables.colours.primary,
        },
    },
    '.react-datepicker__navigation--previous': {
        borderRightColor: variables.colours.primary,
    },
    '.react-datepicker__navigation--next': {
        borderLeftColor: variables.colours.primary,
    },
    '.react-datepicker__navigation': {
        width: '1rem',
        height: '1rem',
    },
    '.react-datepicker__current-month': {
        ':first-letter': {
            textTransform: 'uppercase',
        },
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.25rem',
        lineHeight: '1.438rem',
        color: variables.colours.dark,
    },
    '.react-datepicker__day': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.938rem',
        lineHeight: '1.719rem',
        margin: '0.166rem 0.166rem',
        padding: '0 0.166rem',
        width: '2.063rem',
    },
    '.react-datepicker__day--selected': {
        background: variables.backgoroundColours.primary,
        borderRadius: '50%',
        color: variables.colours.light,
    },
    'div.react-datepicker__day--disabled': {
        color: variables.colours.default,
    },
    '.react-datepicker__day--keyboard-selected': {
        background: variables.backgoroundColours.light,
        color: variables.colours.dark,
    },
    '.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range': {
        background: variables.colours.main,
        margin: '0.166rem 0',
        width: '2.375rem',
        borderRadius: 0,
        '&.react-datepicker__day--range-end': {
            background: variables.backgoroundColours.primary,
            width: '2.375rem',
            borderRadius: '0 50% 50% 0',
            color: variables.colours.light,
            borderLeft: `2px solid ${variables.colours.main}`,
        },
        '&.react-datepicker__day--range-start': {
            background: variables.backgoroundColours.primary,
            width: '2.375rem',
            borderRadius: '50% 0 0 50%',
            color: variables.colours.light,
            '&.react-datepicker__day--range-end': {
                borderRadius: '50%',
            },
        },
        '&:first-of-type': {
            width: '2.063rem',
            borderRadius: '50% 0 0 50%',
        },
        '&:last-of-type': {
            width: '2.063rem',
            borderRadius: '0 50% 50% 0',
        },
    },
    '.react-datepicker__month-text--keyboard-selected': {
        background: variables.colours.main,
    },
    '.react-datepicker__month-text.react-datepicker__month--selected:hover': {
        background: variables.backgoroundColours.primary,
    },
    '.react-datepicker__month': {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.438rem',
    },
    '.react-datepicker__month-container': {
        float: 'none',
        width: '16.875rem',
    },
    '.react-datepicker__month-wrapper': {
        justifyContent: 'space-between',
        display: 'flex',
        '.react-datepicker__month-text': {
            width: '5rem',
        },
    },
    '&.isWeek': {
        '.react-datepicker__week:hover': {
            background: variables.colours.default,
            borderRadius: '1rem',
        },
    },
}));
