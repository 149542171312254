import * as React from 'react';
import { useState } from 'react';
import FlashDialog from '../../atoms/dialog/FlashDialog';
import TextContent from '../../atoms/dialog/TextContent';
import YesNoButtons from '../../atoms/dialog/YesNoButtons';

export type ConfirmButtonProps = {
    button: React.ReactElement;
    onClick: () => void;
    title: string;
    content: string;
};

export default function ConfirmButton(props: ConfirmButtonProps): JSX.Element {
    const { button, onClick, title, content } = props;

    const handleClick = () => {
        setIsShownPopup(true);
    };

    const [isShownPopup, setIsShownPopup] = useState(false);

    return (
        <>
            {React.cloneElement(button, { onClick: handleClick })}
            <FlashDialog
                open
                title={title}
                isOpen={isShownPopup}
                onClose={() => setIsShownPopup(false)}
                content={<TextContent text={content} />}
                controls={<YesNoButtons onConfirm={onClick} onReject={() => setIsShownPopup(false)} />}
            />
        </>
    );
}
