import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';

import UserForm from 'components/organisms/forms/User';
import ProfileActions from 'components/molecules/ProfileActions';

import { store } from 'store';
import allRoutes from 'router';
import { logOut } from 'api/auth';
import { deleteById } from 'api/users';
import { removeTokens } from 'store/auth/actions';
import { removeUser } from 'store/user/actions';
import { getPaidUserOrders } from 'api/orders';

import useSelector from 'hooks/useAppSelector';

import { UserRoles } from 'shared/enums';
import OrdersPagedDataGrid from '../../organisms/OrdersPagedDataGrid';

export type UserCreateEditTemplateProps = {
    hasDeleteAction?: boolean;
};

export default function UserCreateEditTemplate({ hasDeleteAction }: UserCreateEditTemplateProps): JSX.Element {
    const { t } = useTranslation();

    const { state: locations, pathname } = useLocation();
    const { push } = useHistory();

    const auth = useSelector((state) => state.authReducer.auth);

    const currentUser = useSelector((state) => state.userReducer.user);
    const { dispatch } = store;
    const [disabled, setDisabled] = useState(false);
    const [isYourProfile, setIsYourProfile] = useState(false);
    const [isPaidUserOrders, setPaidUserOrders] = useState(false);

    const { id } = useParams<{ id: string }>();
    const isCreate = id === undefined && pathname !== allRoutes.currentUserProfile.path;

    const userId = pathname === allRoutes.currentUserProfile.path ? currentUser.nameid : id;

    // prevent another user from accessing someone else's profile editing page
    if (
        pathname !== allRoutes.currentUserProfile.path &&
        id !== currentUser.nameid &&
        currentUser.role === UserRoles.User
    ) {
        push('/orders/page1');
    }

    useEffect(() => {
        setIsYourProfile(id === currentUser.nameid || pathname === allRoutes.currentUserProfile.path);

        if (userId && userId !== ':id') {
            const getPaidOrders = async () => {
                const paidUserOrders = await getPaidUserOrders(userId);
                setPaidUserOrders(paidUserOrders.data);
            };
            setTimeout(() => getPaidOrders(), 300);
        }
    }, [id, currentUser]);

    useEffect(() => {
        if ((id && pathname !== generatePath(allRoutes.userProfile.path, { id })) || isCreate || isYourProfile) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [isCreate]);

    async function deleteUser() {
        try {
            if (id) {
                if (isYourProfile) {
                    await deleteById(currentUser.nameid);
                    try {
                        await logOut({
                            accessToken: auth.accessToken || '',
                            refreshToken: auth.refreshToken || '',
                        });
                    } finally {
                        dispatch(removeTokens());
                        dispatch(removeUser());
                    }
                    push(allRoutes.signin.path);
                    return toast.success(t('user-form.messages.success-delete'));
                } else {
                    await deleteById(id);
                    toast.success(t('user-form.messages.success-delete'));
                    push(generatePath(allRoutes.users.path, { id: 1 }));
                }
            } else {
                toast.error(t('user-form.messages.error-delete-admin'));
            }
        } catch {
            toast.error(t('user-form.messages.error-delete'));
        }
    }

    const onEdit = () => {
        if ((hasDeleteAction || isYourProfile) && !isCreate && disabled) {
            return { pathname: generatePath(allRoutes.editUser.path, { id: userId }), state: locations };
        }
        return;
    };

    const onDeleteFunction = (hasDeleteAction && !isCreate) || isYourProfile ? deleteUser : undefined;

    const hasDeleteButton = () => {
        return currentUser.role !== UserRoles.User;
    };

    return (
        <>
            <ProfileActions
                deleteContent={
                    isYourProfile
                        ? isPaidUserOrders
                            ? `${t('user.delete-profile-contentText')} ${t('user.orders-will-be-ripped')}`
                            : t('user.delete-profile-contentText')
                        : t('user.contentText')
                }
                deleteTitle={isYourProfile ? t('user.delete-your-profile') : t('user.delete-user')}
                hasDeleteButton={hasDeleteButton()}
                onEdit={onEdit()}
                onDelete={onDeleteFunction}
            />
            <UserForm disabled={disabled} isCreate={isCreate} />
            {!isCreate && currentUser.role !== UserRoles.User && (
                <OrdersPagedDataGrid userId={userId} isAvailableFilters={true} />
            )}
        </>
    );
}
