import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import moment from 'moment';

import { Box, Typography } from '@material-ui/core';

import useWindowDimensions from 'hooks/WindowDimensions';

import allRoutes from 'router';

import NavigationLink from 'components/molecules/NavigationLink';

import { OrderVm } from 'models/order';
import { MomentFormats, OrderFrequency, OrderReplays } from 'shared/enums';

import { ScheduleTable, ExternalHolder, TableHolder } from 'components/molecules/Order/OrderInfoTable/style';
import useSelector from '../../../../hooks/useAppSelector';
import { useEffect } from 'react';
import { ScheduleVm } from 'models/schedule';

export type OrderInfoTableProps = {
    order: OrderVm | undefined;
    isOperatorView: boolean;
};

export default function OrderInfoTable(props: OrderInfoTableProps): JSX.Element {
    const { order, isOperatorView } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const { width } = useWindowDimensions();
    const user = useSelector((state) => state.userReducer.user);

    useEffect(() => {
        if (order) {
            order.mediaSpaces.forEach((x) => {
                x.name = x.names.find((n) => n.languageCode === user.lc)?.name || '';
                x.address = x.names.find((n) => n.languageCode === user.lc)?.address || '';
            });
        }
    }, [user.lc]);

    function sortByDate(arr: Array<ScheduleVm>) {
        return arr.sort((objA, objB) => new Date(objB.startDate).getTime() - new Date(objA.startDate).getTime());
    }

    let sortedSchdule;
    if (order) {
        sortedSchdule = sortByDate(order.schedules);
    }

    return (
        <ExternalHolder maxWidth={width}>
            <TableHolder maxWidth={width}>
                {order &&
                    sortedSchdule?.map((val) => (
                        <ScheduleTable key={val.id}>
                            <thead>
                                <tr>
                                    <th className="width">
                                        <Typography variant="body1" className="table-title">
                                            {t('order.date')}
                                        </Typography>
                                    </th>
                                    <th className="width">
                                        <Typography variant="body1" className="table-title">
                                            {t('order.time')}
                                        </Typography>
                                    </th>
                                    <th>
                                        <Typography variant="body1" className="table-title">
                                            {t('order.platform')}
                                        </Typography>
                                    </th>
                                    <th className="width">
                                        <Typography variant="body1" className="table-title">
                                            {t('order.cost')}
                                        </Typography>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div>
                                            <div>{moment(val.startDate).format(MomentFormats.Short)} - </div>
                                            <div>{moment(val.endDate).format(MomentFormats.Short)}</div>
                                            <Box marginTop="20px">
                                                {t(`enums.order-replays.${OrderReplays[val.replays].toLowerCase()}`)}
                                            </Box>
                                        </div>
                                        <div>
                                            <Typography variant="body1" className="normal">
                                                {OrderFrequency[val.frequency]}
                                            </Typography>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {val.startTime === val.endTime ? (
                                                <Typography variant="body1" className="bold">
                                                    {t('order-form.show-all-day')}
                                                </Typography>
                                            ) : (
                                                <Typography variant="body1" className="bold">
                                                    {moment(val.startTime, MomentFormats.LongTime).format(
                                                        MomentFormats.Time,
                                                    )}{' '}
                                                    -{' '}
                                                    {moment(val.endTime, MomentFormats.LongTime).format(
                                                        MomentFormats.Time,
                                                    )}
                                                </Typography>
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="scrollable">
                                            {order.mediaSpaces.map((val) => (
                                                <div key={val.id}>
                                                    {isOperatorView ? (
                                                        <NavigationLink
                                                            onClick={() => {
                                                                history.push(
                                                                    generatePath(allRoutes.mediaSpaceProfile.path, {
                                                                        id: val.id,
                                                                        pageId: 1,
                                                                    }),
                                                                );
                                                            }}
                                                            className="mediaspace"
                                                            name="media-space"
                                                        >
                                                            <Typography variant="body1" className="normal">
                                                                {val.name}
                                                            </Typography>
                                                        </NavigationLink>
                                                    ) : (
                                                        <Typography variant="body1" className="normal">
                                                            {val.address}
                                                        </Typography>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <Typography variant="body1" className="bold">
                                                {val.cost} {order.currencyCode}
                                            </Typography>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </ScheduleTable>
                    ))}
            </TableHolder>
        </ExternalHolder>
    );
}
