import React, { Dispatch, SetStateAction, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { Box, Typography } from '@material-ui/core';
import CustomCheckbox from 'components/atoms/Checkbox';

import { MediaSpaceVm } from 'models/media-space.model';
import { ActionButton } from 'components/templates/Devices/style';
import { ReactComponent as DeviceScreen } from 'assets/icons/DeviceScreen.svg';
import FlashTooltip from 'components/atoms/FlashTooltip';
import { variables } from 'theme/variables';

type MediaSpacesGridProps = {
    mediaSpaces: MediaSpaceVm[];
    setChecked: Dispatch<SetStateAction<string[]>>;
    checked: string[];
    selectMediaSpace?: (value: React.SetStateAction<string[]>) => void;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    setImageSrc: Dispatch<SetStateAction<string | null>>;
};

export default function MediaSpacesColumnsGridOrder(props: MediaSpacesGridProps): GridColDef[] {
    const { mediaSpaces, checked, setChecked, setOpenModal, setImageSrc } = props;
    const { t } = useTranslation();

    const getImage = (mediaSpaceImgUrl: string | undefined | null) => {
        if (mediaSpaceImgUrl) {
            setImageSrc(mediaSpaceImgUrl);
        } else {
            setImageSrc(null);
        }

        setOpenModal(true);
    };

    const getColumns = useCallback(() => {
        return [
            {
                field: '',
                headerName: '',
                renderCell: function renderCheckBox(params: GridCellParams) {
                    return <CustomCheckbox params={params} checked={checked} setChecked={setChecked} />;
                },
                width: 60,
                sortable: false,
                disableClickEventBubbling: true,
                align: 'left',
                headerAlign: 'left',
            },
            {
                field: 'name',
                headerName: t('order-form.media-spaces-list.name'),
                flex: 0.1,
                minWidth: 190,
                headerAlign: 'center',
                renderCell: function renderName(params: GridCellParams) {
                    return (
                        <Box display="flex" className="mediaspace-name">
                            <FlashTooltip
                                disableHighlighting
                                title={
                                    <Typography variant="body1" style={{ color: variables.colours.light }}>
                                        {params.row.name}
                                    </Typography>
                                }
                                placement="top-start"
                                arrow
                            >
                                <Typography variant="body1">{params.row.name}</Typography>
                            </FlashTooltip>
                        </Box>
                    );
                },
            },
            {
                field: 'address',
                headerName: t('order-form.media-spaces-list.address'),
                flex: 0.2,
                headerAlign: 'center',
                renderCell: function renderName(params: GridCellParams) {
                    return <Typography variant="body1">{params.row.address}</Typography>;
                },
            },
            {
                field: 'image',
                headerName: t('order-form.media-spaces-list.review'),
                width: 90,
                headerAlign: 'center',
                renderCell: function renderRowActions(params: GridCellParams) {
                    return (
                        <>
                            <ActionButton name="show-button" onClick={() => getImage(params.row.pictureUrl)}>
                                <DeviceScreen />
                            </ActionButton>
                        </>
                    );
                },
                sortable: false,
                disableClickEventBubbling: true,
            },
        ] as GridColDef[];
    }, [mediaSpaces, checked]);

    return getColumns();
}
