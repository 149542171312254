import { useEffect } from 'react';

import { Redirect } from 'react-router-dom';

import { useLocation } from 'react-router-dom';
import allRoutes from '../../../router';
import i18next from 'i18next';
import { toast } from 'react-toastify';
import useAuth from 'hooks/useAuth';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ExternalAuthCallback = (): JSX.Element => {
    const { authorize } = useAuth();
    const nextPath = useSelector((state: RootState) => state.authReducer.nextPath);

    const query = useQuery();
    const accessToken = query.get('access');
    const refreshToken = query.get('refresh');
    const error = query.get('error');

    useEffect(() => {
        if (error && error.length > 0) {
            let toastText = decodeURI(error || '');
            if (toastText.startsWith('server-messages')) {
                toastText = i18next.t(toastText);
            }
            toast.error(toastText);
        }
    }, [error]);

    let route = nextPath;
    if (accessToken && refreshToken && accessToken.length > 0 && refreshToken.length > 0) {
        authorize({ accessToken, refreshToken });
    } else {
        route = allRoutes.signin.path;
    }

    return <Redirect to={route} />;
};

export default ExternalAuthCallback;
