import { TextFieldProps } from '@material-ui/core';
import * as React from 'react';
import { FlashTextField } from './style';

export type InputProps = TextFieldProps & {
    placeholder?: string;
    type?: string;
    id?: string;
    value?: string | number;
    label?: string;
    helperText?: React.ReactNode;
    disabled?: boolean;
    maskPfone?: JSX.Element;
    multiline?: boolean;
    error?: boolean;
    color?: 'primary' | 'secondary';
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function TextField(props: InputProps): JSX.Element {
    const { type, maskPfone, ...otherProps } = props;
    return (
        <FlashTextField {...otherProps} type={type || 'text'}>
            {maskPfone}
        </FlashTextField>
    );
}
