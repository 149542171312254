import { Modal, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { variables } from 'theme/variables';

export const StyledList = styled.div(({}) => ({
    '.MuiDataGrid-columnHeaderWrapper': {
        paddingLeft: '6px',
    },
    '.MuiDataGrid-columnHeader': {
        paddingRight: '10px',

        '.MuiDataGrid-columnHeaderTitleContainer': {
            justifyContent: 'flex-start!important',
        },

        ':nth-of-type(4)': {
            '.MuiDataGrid-columnHeaderTitleContainer': {
                justifyContent: 'center!important',
            },
        },
    },
    '.MuiDataGrid-cell': {
        p: {
            paddingRight: '10px',
        },
        ':first-child': {
            a: {
                justifyContent: 'start',
            },
            p: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },

        ':nth-child(2)': {
            a: {
                justifyContent: 'start',
            },
            p: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },

        ':nth-child(3)': {
            fill: variables.colours.primary,
            p: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },

        ':nth-child(4)': {
            justifyContent: 'center',
            '.MuiButtonBase-root': {
                minWidth: '50px',
            },
        },

        ':nth-last-child(1)': {
            '.MuiButton-label': {
                justifyContent: 'center',
            },
        },

        '.mediaspace-name': {
            position: 'relative',
            width: '11rem',
            minWidth: '100%',

            '& > div': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },

            '@media only screen and (min-width: 320px) and (max-width: 1439px)': {
                width: '8rem',
            },
        },

        '.payment-attention': {
            position: 'absolute',
            right: '-3rem',
            top: '-0.188rem',
        },
    },
}));

export const RedTypography = styled(Typography).attrs({
    variant: 'body1',
})({
    color: '#e2574c',
});

export const ModalStyled = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const ImageViewStyled = styled.img(({}) => ({
    marginBottom: '1.25rem',
    borderRadius: '10px',
    height: 'auto',
    maxHeight: '50%',
    maxWidth: '80%',
    ':focus-visible': {
        outline: 'none',
    },
}));
