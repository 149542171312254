import * as React from 'react';
import FullWidthTemplate from 'components/templates/FullWidthTemplate';
import HowItWork from 'components/organisms/HowItWork';
import Statistics from 'components/organisms/Statistics';
import ContainerWithGradient from 'components/molecules/containers/ContainerWithGradient';
import Intro from 'components/organisms/Intro';
import ContainerWithTitle from 'components/molecules/containers/ContainerWithTitle';
import FlashCommonGlobal from 'components/atoms/FlashCommonGlobal';
import FAQ from 'components/organisms/FAQ';
import CalculateCost from 'components/organisms/CalculateCost';
import { useTranslation } from 'react-i18next';
import FlashMap, { MapPlacemark } from 'components/organisms/FlashMap';
import { mediaSpacesAPI } from 'api/media-space';
import { useEffect, useState } from 'react';
import LoadingIndicatorPopup from 'components/atoms/LoadingIndicatorPopup';
import { Box } from '@material-ui/core';
import { MediaSpaceMapVm } from 'models/media-space.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';

export default function HomePageNew(): JSX.Element {
    const { t } = useTranslation();
    const [mediaSpaceMapPlacemark, setMediaSpaceMapPlacemark] = useState<MapPlacemark[]>([]);
    const [mediaSpaces, setMediaSpaces] = useState<MediaSpaceMapVm[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isTriggeredLoad, setIsTriggeredLoad] = useState(false);
    const user = useSelector((state: RootState) => state.userReducer.user);

    const getMediaSpacesMap = async () => {
        const localStorageLocale = localStorage.getItem('locale');
        setIsDataLoaded(false);
        setMediaSpaceMapPlacemark([]);
        const data = await mediaSpacesAPI.getMediaSpacesMapPublic(localStorageLocale);

        const mediaSpacesMap: MapPlacemark[] = [];
        data.data.forEach((mediaSpace) => {
            mediaSpacesMap.push({
                id: mediaSpace.id,
                isHighlighted: false,
                lat: mediaSpace.latitude,
                long: mediaSpace.longitude,
                name: mediaSpace.name,
                address: mediaSpace.address,
                pictureUrl: mediaSpace.pictureUrl,
            });
        });
        setMediaSpaces(data.data);
        setMediaSpaceMapPlacemark(mediaSpacesMap);
        setIsDataLoaded(true);
    };

    useEffect(() => {
        if (!isTriggeredLoad) {
            setIsTriggeredLoad(true);
            setTimeout(() => getMediaSpacesMap(), 300);
        }
    }, [isTriggeredLoad, user.lc]);

    useEffect(() => {
        setIsTriggeredLoad(false);
    }, [user.lc]);

    return (
        <FullWidthTemplate>
            <div style={{ backgroundColor: '#FFF' }}>
                <Intro
                    title={
                        <span>
                            {t('home-page.intro.title')}
                            <br />
                            <span className={'orange'}>{t('home-page.intro.title-orange')}</span>
                        </span>
                    }
                ></Intro>
                <HowItWork
                    title={t('home-page.how-it-work.title')}
                    teaser={t('home-page.how-it-work.teaser')}
                ></HowItWork>
                <Statistics title={t('home-page.statistics.title')}></Statistics>
                <ContainerWithGradient>
                    <FlashCommonGlobal>
                        <ContainerWithTitle title={t('home-page.map.title')}>
                            {isDataLoaded ? (
                                <Box width="100%" className="map-handler">
                                    <FlashMap
                                        hasSearchControl={true}
                                        showDetailedPlacemarkContent={true}
                                        placemarks={mediaSpaceMapPlacemark}
                                    />
                                </Box>
                            ) : (
                                <LoadingIndicatorPopup isLoaded={isDataLoaded} />
                            )}
                        </ContainerWithTitle>
                        <ContainerWithTitle title={t('home-page.calculate-cost.title')}>
                            <CalculateCost mediaSpaces={mediaSpaces} />
                        </ContainerWithTitle>
                        <ContainerWithTitle title={t('home-page.faq.title')} marginBottom="0">
                            <FAQ />
                        </ContainerWithTitle>
                    </FlashCommonGlobal>
                </ContainerWithGradient>
            </div>
        </FullWidthTemplate>
    );
}
