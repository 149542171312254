import styled from 'styled-components';

export const FlashTooltipStyled = styled.div({
    '.disable-highlighting': {
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'none',
        '-ms-user-select': 'none',
        'user-select': 'none',
    },
});
