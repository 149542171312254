import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { LinearProgress, Tooltip } from '@material-ui/core';

import { OrderCount, OrderVm } from 'models/order';

import { StyledProgress, Label, LabelText, AttentionText } from 'components/atoms/ProgressBar/style';

import { ReactComponent as PaymentAttention } from 'assets/icons/PaymentAttention.svg';

export interface ProgressBarProps {
    count?: OrderCount;
    order?: OrderVm;
}

const emptyCount = 0;
const moreExpectedCount = 100;

const ProgressBar: FC<ProgressBarProps> = ({ count, order }) => {
    const { t } = useTranslation();
    const [completed, setCompleted] = useState(false);
    const countPercent = () => {
        if (count) {
            if (count.realCount !== count.expectedCount && count.realCount < count.expectedCount) {
                const realPercent = (count.realCount * 100) / count.expectedCount;
                const realPercentValue = Number(realPercent.toFixed(1));

                return realPercentValue;
            }
            return moreExpectedCount;
        }
        return emptyCount;
    };

    useEffect(() => {
        let endDate = order?.schedules[0].endDate;
        order?.schedules.map((item) => {
            const now = new Date();
            const orderEndTime = +item.endTime.slice(0, 2);
            const orderEndDate = new Date(item.endDate);
            orderEndDate.setHours(orderEndTime, 0, 0);

            if (count) {
                if (endDate) {
                    if (endDate < orderEndDate) endDate = orderEndDate;
                    if (endDate > now && count.expectedCount > count.realCount) setCompleted(true);
                }
            }
        });
    }, [count]);

    return (
        <StyledProgress>
            <Label>
                <LabelText>
                    {t('order-form.playing') + ' ' + countPercent() + t('order-form.percent')}{' '}
                    {t('order-form.of') + ' ' + moreExpectedCount + t('order-form.percent')}
                </LabelText>
                {completed && (
                    <Tooltip
                        title={<AttentionText>{t('order-form.attention')}</AttentionText>}
                        placement="top-start"
                        arrow
                    >
                        <PaymentAttention className="attention" />
                    </Tooltip>
                )}
            </Label>
            <LinearProgress variant="determinate" value={countPercent()} />
        </StyledProgress>
    );
};

export default ProgressBar;
