import styled from 'styled-components';

import { Button, Typography } from '@material-ui/core';

import { variables } from 'theme/variables';

export const DevicesGridStyled = styled.div(({}) => ({
    '.MuiDataGrid-columnHeader:first-child': {
        paddingLeft: '1.438rem',
    },
    '.MuiDataGrid-row': {
        paddingLeft: '0 !important',
    },
    '.MuiDataGrid-cell': {
        ':first-child': {
            a: {
                justifyContent: 'start',
                paddingLeft: '0.719rem',
            },
            p: {
                color: variables.colours.main,
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },

        ':nth-child(2)': {
            a: {
                justifyContent: 'start',
            },
            p: {
                textTransform: 'uppercase',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
        },
        ':nth-child(3)': {
            whiteSpace: 'normal',
        },
        ':nth-child(4)': {
            a: {
                justifyContent: 'start',
            },
        },
    },
}));

export const ActionButton = styled(Button).attrs({
    size: 'small',
})({
    path: {
        fill: variables.colours.dark,
    },
    '&.Mui-disabled': {
        path: {
            fill: variables.colours.default,
        },
    },
    ':hover': {
        path: {
            fill: variables.colours.primary,
        },
    },
});

export const GridText = styled(Typography).attrs({
    variant: 'body1',
})({
    paddingLeft: '16px',
    '&.tooltip': {
        color: variables.colours.light,
    },

    '&.memory': {
        position: 'relative',
        width: '9.25rem',
    },

    svg: {
        position: 'absolute',
        right: '-7rem',
        top: '-0.188rem',
        '@media only screen and (min-width : 1367px) and (max-width : 1439px)': {
            right: '-6rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 1366px)': {
            right: '-3.3rem',
        },
    },
});
