import { AxiosResponse } from 'axios';
import axios from './axios';
import { TimeZoneShortVm } from '../models/timeZone.model';

export const timeZoneAPI = {
    get: (): Promise<AxiosResponse<Array<TimeZoneShortVm>>> => {
        return axios.get('timezones');
    },
    getById: (id: string): Promise<TimeZoneShortVm> => {
        return axios.get(`timezones/${id}`);
    },
};
