import { User, UPDATE_USER, REMOVE_USER, UPDATE_LC, UserActionTypes } from 'store/user/types';

export const updateUser = (data: User): UserActionTypes => ({
    type: UPDATE_USER,
    data,
});

export const removeUser = (): UserActionTypes => ({
    type: REMOVE_USER,
});

export const updateLc = (lc: string): UserActionTypes => ({
    type: UPDATE_LC,
    data: lc,
});
