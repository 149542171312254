import * as React from 'react';
import { FlashCommonGlobalStyled } from './style';

type FlashCommonGlobalProps = {
    children: React.ReactNode;
};

export default function FlashCommonGlobal({ children }: FlashCommonGlobalProps): JSX.Element {
    return <FlashCommonGlobalStyled>{children}</FlashCommonGlobalStyled>;
}
