import styled from 'styled-components';

import { ButtonBase, Typography } from '@material-ui/core';

import { StyledFullScreenImage } from 'components/atoms/FullScreenImage/style';

import { variables } from 'theme/variables';

export const StyledMediaPreview = styled.div({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [StyledFullScreenImage]: {
        marginBottom: 20,
    },
});

export const RemoveButtonText = styled(Typography).attrs({
    variant: 'h6',
    component: 'span',
})({
    color: variables.colours.default,
    marginRight: 10,
    transition: '.2s ease',
});

export const RemoveButtonIcon = styled.svg({
    transition: '.2s ease',
    color: variables.colours.default,
});

export const RemoveButton = styled(ButtonBase)({
    textAlign: 'right',
    borderRadius: 5,
    padding: '5px 10px',
    alignSelf: 'flex-end',
    marginBottom: 10,

    ':hover': {
        [RemoveButtonText]: {
            color: variables.colours.primary,
        },

        [RemoveButtonIcon]: {
            color: variables.colours.primary,
        },

        '.MuiTouchRipple-child': {
            backgroundColor: variables.colours.primary,
        },
    },
});

export const TimeLabel = styled(Typography).attrs({
    variant: 'body1',
})({
    color: variables.colours.primary,
});

export const ImageControls = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    margin: '20px 0',
});
