import { MediaSpaceShortVm, MediaSpaceVm } from './media-space.model';
import { PagingVmBaseResponse } from './paging/paging.model';

export interface PlaylistModel {
    name: string;
    startDate: Date;
    endDate?: Date;
    isInfinite: boolean;
    isForAllMediaSpaces: boolean;
    mediaSpaceIds: string[];
    playlistFiles: LocalPlaylistFileModel[];
}

export type PlaylistBaseVm = PlaylistModel & {
    id: string;
};

export type PlaylistDetailsVm = PlaylistBaseVm & {
    mediaSpaces: Array<MediaSpaceVm>;
};

export type PlaylistGridVm = PlaylistBaseVm & {
    mediaSpaces: Array<MediaSpaceShortVm>;
};

export type PagedPlaylistVmResponse = PagingVmBaseResponse<PlaylistGridVm>;

export const DefaultPlaylistInput: PlaylistModel = {
    name: '',
    startDate: new Date(),
    endDate: new Date(),
    isInfinite: false,
    isForAllMediaSpaces: false,
    mediaSpaceIds: [],
    playlistFiles: [],
};

export interface PlaylistFileModel {
    id: string;
    name: string;
    originalName?: string;
    lastModified?: number;
    type: string;
    duration: number;
    indexNumber: number;
    width: number;
    height: number;
}

export type DevicePlaylistFileModel = PlaylistFileModel & {
    playlist: PlaylistModel;
    playlistId: string;
};

export interface LocalPlaylistFileModel extends Omit<PlaylistFileModel, 'name'> {
    name?: string;
    isLoading?: boolean;
}

export type PlaylistsPagedRequest = {
    page: number;
    pageSize: number;
    property?: string;
    direction?: number | null;
    filterString?: string | null;
    startDate: Date | null;
    endDate?: Date | null;
    mediaSpaceIds: string[];
};
