import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { FormikHelpers } from 'formik';

import { MenuItem, Menu } from '@material-ui/core';

import useSelector from 'hooks/useAppSelector';

import UserRole from 'components/atoms/UserRole';

import { UserModel } from 'models/user-model';
import { UserRoles } from 'shared/enums';

import {
    AdminPoint,
    OperatorPoint,
    RoleBox,
    RoleButton,
    RoleName,
    UserPoint,
    UserRoleText,
} from 'components/molecules/forms/UserProfileRolesSelect/style';

export interface RoleSelectProps {
    id: string;
    disabled?: boolean;
    values: UserModel;
    setFieldValue: FormikHelpers<UserModel>['setFieldValue'];
}

const UserRolesSelect: FC<RoleSelectProps> = ({ id, disabled, values, setFieldValue }) => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.userReducer.user);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {user.role === UserRoles.User || user.nameid === id || disabled ? (
                <UserRole userRole={values.role} />
            ) : (
                <>
                    <RoleButton onClick={handleClick} name="user-role">
                        <UserRole userRole={values.role} symbol={<UserRoleText>&#8897;</UserRoleText>} />
                    </RoleButton>
                    <Menu
                        id="simple-menu"
                        keepMounted
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem
                            onClick={() => {
                                setFieldValue('role', UserRoles.User);
                                setAnchorEl(null);
                            }}
                            data-id="user"
                        >
                            <RoleBox>
                                <RoleName>{t('roles.user')}</RoleName>
                                <UserPoint />
                            </RoleBox>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setFieldValue('role', UserRoles.Operator);
                                setAnchorEl(null);
                            }}
                            data-id="operator"
                        >
                            <RoleBox>
                                <RoleName>{t('roles.operator')}</RoleName>
                                <OperatorPoint />
                            </RoleBox>
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setFieldValue('role', UserRoles.Admin);
                                setAnchorEl(null);
                            }}
                            data-id="admin"
                        >
                            <RoleBox>
                                <RoleName>{t('roles.admin')}</RoleName>
                                <AdminPoint />
                            </RoleBox>
                        </MenuItem>
                    </Menu>
                </>
            )}
        </>
    );
};

export default UserRolesSelect;
