import { GridColDef, GridRowParams, GridSortModel } from '@material-ui/data-grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPage } from '../../../api/orders';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import PagedDataGrid from '../../../components/organisms/PagedDataGrid';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { MomentFormats, OrderStatusType, UserRoles } from '../../../shared/enums';
import { PageSize } from '../../../shared/constants';
import MediaSpaseOrderFilter from '../../atoms/gridFilters/MediaSpaceOrderFilter';
import OrderCostSummary from '../../atoms/Order/OrderCostSummary';
import FilteredTemplate from '../../templates/FilteredTemplate';
import { Avatar, Box, Tooltip, Typography } from '@material-ui/core';
import { getPhotoSrc } from '../../../api/photo';
import { StyledMediaOrderList } from './style';
import moment from 'moment';
import { variables } from '../../../theme/variables';
import allRoutes from '../../../router';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect/useDebouncedEffect';
import MediaSpaceOrdersColumnsGrid from './ColumnsGrid';

type OrderRow = {
    id: string;
    number: number;
    createdDate: string;
    userId: string;
    userName: JSX.Element;
    dateReply: JSX.Element;
    status: OrderStatusType;
    cost: string;
};

type MediaSpaceOrdersGridProps = {
    mediaSpaceId: string;
};

export default function MediaSpaceOrdersGrid(props: MediaSpaceOrdersGridProps): JSX.Element {
    const { mediaSpaceId } = props;
    const { t } = useTranslation();

    const user = useSelector((state: RootState) => state.userReducer.user);

    enum NameField {
        OrderId = 'id',
        Number = 'number',
        CreatedDate = 'createdDate',
        UserName = 'userName',
        DateReply = 'startDate',
        Status = 'status',
        Cost = 'cost',
    }
    enum SortField {
        OrderId = 'Id',
        Number = 'Number',
        CreatedDate = 'CreatedDate',
        UserName = 'UserName',
        DateReply = 'DateReply',
        Status = 'Status',
        Cost = 'Cost',
    }

    enum SortDirections {
        Asc = 'asc',
        Desc = 'desc',
    }

    enum SortDirectionsInteger {
        Asc = 1,
        Desc = 2,
    }

    const history = useHistory();
    const [rows, setRows] = useState<OrderRow[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [sortValue, setSortValue] = useState<SortDirectionsInteger>(0);
    const [valField, setField] = useState<string | undefined>(NameField.Number);
    const [valFilterStatus, setFilterStatus] = useState<OrderStatusType>();
    const [valSort, setSort] = useState<number | null | undefined>(SortDirectionsInteger.Desc);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [costSum, setCostSum] = useState(0);
    const { pageId } = useParams<{ pageId: string }>();

    const columns: GridColDef[] = MediaSpaceOrdersColumnsGrid({
        rows,
    });

    useEffect(() => {
        if (pageId) {
            const page = Number(pageId);
            setPage(page);
        } else {
            setPage(1);
        }
    }, [pageId]);

    useDebouncedEffect(() => setOrderData(), 200, [
        page,
        startDate,
        endDate,
        valFilterStatus,
        user.role,
        valField,
        valSort,
        sortValue,
        PageSize,
    ]);

    const setOrderData = React.useCallback(async () => {
        setRows([]);
        const reqVal = {
            userId: user.role === UserRoles.User ? user.nameid : undefined,
            mediaSpaceId: mediaSpaceId,
            page: page,
            status: valFilterStatus,
            SortProperty: valField,
            SortDirection: valSort,
            StartedAt: startDate ? startDate : undefined,
            FinishedAt: endDate ? endDate : undefined,
            PageSize: PageSize,
        };

        const pageContent = await getPage(reqVal);

        setIsDataLoaded(true);
        const totalPageNumber = Math.ceil(pageContent.total / pageContent.pageSize);
        setTotalPage(totalPageNumber);
        setTotalItems(pageContent.total);
        const rows: OrderRow[] = [];
        pageContent.data.forEach((element) => {
            const endDate =
                element.schedules.length > 0 &&
                element.schedules.reduce((prev, current) => (prev.endDate > current.endDate ? prev : current)).endDate;
            const startDate =
                element.schedules.length > 0 &&
                element.schedules.reduce((prev, current) => (prev.startDate < current.startDate ? prev : current))
                    .startDate;
            rows.push({
                id: element.id,
                number: element.number,
                createdDate: new Date(Date.parse(element.createdDate)).toLocaleString('ru', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                }),
                userId: element.userId,
                userName: (
                    <>
                        <Box marginRight="0.813rem">
                            <Avatar
                                style={{ width: '47px', height: '47px' }}
                                alt={element.userFullName && element.userFullName}
                                src={getPhotoSrc(element.photoUrl)}
                            >
                                <Typography variant="body1" className="user-name">
                                    {element.userFullName && element.userFullName[0]}
                                </Typography>
                            </Avatar>
                        </Box>
                        <Tooltip
                            title={
                                <Typography
                                    variant="body1"
                                    style={{ color: variables.colours.light, lineHeight: '137.01%' }}
                                >
                                    {element.userFullName}
                                </Typography>
                            }
                            placement="top-start"
                            arrow
                        >
                            <Typography variant="body1">{element.userFullName}</Typography>
                        </Tooltip>
                    </>
                ),
                dateReply: (
                    <Box width="100%">
                        {startDate && endDate && (
                            <>
                                {startDate == endDate ? (
                                    <>{moment(startDate).format(MomentFormats.Short)}</>
                                ) : (
                                    <>
                                        {moment(startDate).format(MomentFormats.Short)}-
                                        {element.schedules.length > 1 && <>...</>}
                                        <br />
                                        {moment(endDate).format(MomentFormats.Short)}
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                ),
                status: element.status,
                cost: element.schedules.reduce((sum, current) => sum + current.cost, 0) + ' ' + t('order.currency'),
            });
        });
        setCostSum(pageContent.costSum);
        setRows(rows);
    }, [page, startDate, endDate, valFilterStatus, user.role, valField, valSort, sortValue, PageSize]);

    const getSorting = async (model: GridSortModel) => {
        if (model.length == 0) {
            model.push({ sort: SortDirections.Asc, field: NameField.Number });
        }
        const sortModel = model[0];
        let field = sortModel.field;
        const sort = sortModel.sort;

        let sortValue = SortDirectionsInteger.Asc;
        if (sort != SortDirections.Asc) {
            setSort(SortDirectionsInteger.Desc);
            sortValue = SortDirectionsInteger.Desc;
        } else {
            setSort(SortDirectionsInteger.Asc);
        }

        if (field == NameField.UserName) {
            field = SortField.UserName;
        } else if (field == NameField.Number) {
            field = SortField.Number;
        } else if (field == NameField.CreatedDate) {
            field = SortField.CreatedDate;
        }
        setSortValue(sortValue);
        setField(field);
        setPage(1);
    };

    return (
        <StyledMediaOrderList>
            <FilteredTemplate
                filter={
                    <MediaSpaseOrderFilter
                        valFilterStatus={valFilterStatus}
                        setFilterStatus={setFilterStatus}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                    />
                }
                content={
                    <PagedDataGrid
                        isDataLoaded={isDataLoaded}
                        isLoadingIndicator={isDataLoaded}
                        totalPages={totalPage}
                        totalItems={totalItems}
                        noDataMessage={<>{t('media-space.no-data')}</>}
                        defaultPage={page}
                        columns={columns}
                        summaryElement={<OrderCostSummary costs={costSum} />}
                        onRowClick={(param: GridRowParams) => {
                            history.push({
                                pathname: generatePath(allRoutes.orderProfile.path, { id: param.row.id }),
                                state: history.location.pathname,
                            });
                        }}
                        rows={rows}
                        rowsPerPageOptions={[]}
                        filterMode="server"
                        onSortModelChange={(model: GridSortModel) => {
                            getSorting(model);
                        }}
                    />
                }
            />
        </StyledMediaOrderList>
    );
}
