import styled from 'styled-components';

import { Button, Typography } from '@material-ui/core';

import { variables } from 'theme/variables';

export const StyledDateSubForm = styled.div({
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: -10,
});

interface ActionButtonProps {
    $primary?: boolean;
}

export const ActionButton = styled(Button)<ActionButtonProps>(({ $primary, color }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 400,
    padding: 8,
    color: color === 'primary' ? '#fff' : variables.colours[$primary ? 'primary' : 'default'],
    '@media only screen and (min-width : 320px) and (max-width : 575px)': {
        margin: '10px auto !important',
    },

    '.MuiButton-label > *': {
        margin: '0 4px',
        '@media only screen and (min-width : 320px) and (max-width : 575px)': {
            margin: '0 -4px 0 4px',
        },
    },
}));

export const FormActions = styled.div({
    display: 'flex',
    flex: 1,
    maxWidth: '100%',
    padding: -10,
    margin: 0,
    justifyContent: 'space-between',
    flexWrap: 'wrap-reverse',

    [ActionButton]: {
        margin: 10,
        minWidth: 250,
        maxWidth: 300,
        flex: 1,
    },
});

export const AddTimeText = styled(Typography).attrs({
    color: 'inherit',
    component: 'span',
})({
    whiteSpace: 'nowrap',
});
