import React, { useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';

import { Box, Typography, Link as MuiLink } from '@material-ui/core';

import Button from 'components/atoms/controls/Button';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import FormControlTextInput from 'components/molecules/forms/FormControlTextInput';

import allRoutes from 'router';

import { sendConfirmEmail } from 'api/users';
import { ConfirmEmailModel } from 'models/user-model';

import { ConfirmFormStyle } from 'components/organisms/forms/ConfirmEmail/style';

export default function ConfirmEmailForm(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();

    const [formData, setFormData] = useState<ConfirmEmailModel>({
        email: '',
    });
    const [isLoaded, setIsLoaded] = useState<boolean>(true);

    const confirmEmail = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
        e.preventDefault();
        setIsLoaded(false);
        try {
            await sendConfirmEmail(formData);
            toast.success(t('confirm-email-form.subheader'));
            history.push(allRoutes.signin.path);
        } catch {
            history.push(allRoutes.signin.path);
        }
    };

    return (
        <ConfirmFormStyle>
            <div>
                <FormControlTextInput
                    value={formData.email}
                    onChangeValue={(value: string) => {
                        setFormData({ ...formData, email: value });
                    }}
                    label={t('forget-password-form.email')}
                    name="email"
                ></FormControlTextInput>
            </div>
            <MuiLink component={Link} to={allRoutes.signin.path} className="button-handler" data-id="go-back">
                <Typography variant="body1" className="go-back">
                    {t('forget-password-form.go-back')}
                </Typography>
            </MuiLink>
            <Box display="flex" justifyContent="flex-end" marginTop="1rem">
                <LoadingIndicator isLoaded={isLoaded} />
                {isLoaded && (
                    <Button onClick={confirmEmail} type="submit" color="primary" variant="outlined" name="send-email">
                        <Typography variant="h5" className="send-email">
                            {t('forget-password-form.submit')}
                        </Typography>
                    </Button>
                )}
            </Box>
        </ConfirmFormStyle>
    );
}
