import { UserStatesEnum } from '../components/atoms/gridFilters/UserStateSelect';
import { UserRoles } from '../shared/enums';
import { PagingVmBaseResponse } from './paging/paging.model';

export const defaultUserModel = {
    userName: '',
    email: '',
    password: '',
    passwordConfirm: '',
    role: UserRoles.User,
    phoneNumber: '',
    firstName: '',
    lastName: '',
    birthday: new Date(),
    timeZoneId: '',
    locale: 'ru',
    preferredCurrencyId: '',
};

export type UserVm = {
    id: string;
    userName: string;
    email: string;
    phoneNumber: string;
    role: string;
    isLockedOut: boolean;
    birthday: Date;
    timeZoneId: string;
    photoUrl: string;
    firstName: string;
    lastName: string;
    locale: string;
    preferredCurrencyId: string;
};

export interface UserModel {
    userName: string;
    email: string;
    password: string;
    passwordConfirm: string;
    phoneNumber: string;
    role: string;
    firstName: string;
    lastName: string;
    birthday: Date;
    timeZoneId: string;
    locale: string;
    preferredCurrencyId: string;
}

export type PagedUserVmResponse = PagingVmBaseResponse<UserVm>;

export type SortModel = {
    field: string;
    sort: string;
};

export type UserPagedRequest = {
    page: number;
    pageSize: number;
    property?: string;
    direction?: number | null;
    userRole: string | undefined;
    status: UserStatesEnum;
    filterString?: string | null;
};

export type ProfileImageModel = {
    url: string;
    file?: File;
};

export type ConfirmEmailModel = {
    email: string;
};
