import styled from 'styled-components';

import { FormControl } from '@material-ui/core';

import { variables } from 'theme/variables';

export const StyledSelect = styled(FormControl)(({}) => ({
    width: '50%',
    margin: '0.625rem 1.875rem 3.5rem 0',

    label: {
        color: variables.colours.dark,
    },

    '.MuiInputBase-root': {
        display: 'flex',
        width: '100%',
        height: '2.658rem',
    },

    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        width: '100%',
        margin: '0.625rem 0 3.5rem 0',
    },
}));
