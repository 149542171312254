import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { StyledBox } from './style';

export type LoadingIndicatorProps = {
    isLoaded: boolean;
};

export default function LoadingIndicator(props: LoadingIndicatorProps): JSX.Element {
    const { isLoaded } = props;
    return (
        <>
            {!isLoaded && (
                <StyledBox height="100%" justifyContent="center" alignItems="center">
                    <CircularProgress disableShrink />
                </StyledBox>
            )}
        </>
    );
}
