import { FC, useState, MouseEvent, ChangeEvent, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import { Box, Typography } from '@material-ui/core';
import PhoneInput from 'material-ui-phone-number';

import Button from 'components/atoms/controls/Button';
import TextField from 'components/atoms/controls/TextField';
import ResponsiveFlexBox from 'components/atoms/ResponsiveFlexBox';
import FlashDatePicker from 'components/molecules/forms/FlashDatePicker';
import Password from 'components/atoms/PasswordInput';

import { logIn, signUp } from 'api/auth';
import allRoutes from 'router';
import { unmaskPhone } from 'shared/utils';

import { RegistrationModel } from 'models/registration';

import { RegisterStyle, PhoneInputWrapper } from 'components/organisms/forms/Registration/style';
import { getConfig } from '../../../../config';
import { LoginModel } from '../../../../models/login';
import useAuth from '../../../../hooks/useAuth';

const Register: FC = () => {
    const { t } = useTranslation();

    const history = useHistory();

    const [phoneInputTouched, setPhoneInputTouched] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [formData, setFormData] = useState<RegistrationModel>({
        userName: '',
        email: '',
        phoneNumber: '',
        password: '',
        passwordConfirm: '',
        firstName: '',
        lastName: '',
        birthday: null,
    });

    const [config, setConfig] = useState<Config>({
        backendUrl: '/',
        frontendUrl: '/',
        landingUrl: '/',
        id: '',
        mapsApiKey: '',
        sentryDsn: '',
        sentryEnvironment: '',
        sentryTracesSampleRate: 1.0,
        isSentryEnabled: false,
        defaultLocale: '',
        defaultCurrency: '',
        contactsAddressEn: '',
        contactsAddressRu: '',
        contactsPhone: '',
        contactsEmail: '',
        contactsLongitude: 0,
        contactsLatitude: 0,
        contactsCompanyRu: '',
        contactsCompanyEn: '',
        supportTeamPhone: '',
        whatsAppLink: '',
        googleTagManagerId: '',
        facebookIsAvailable: false,
        fictitiousMode: false,
        detectBrowserLanguage: false,
        adminStartDateRangeInHours: 0,
        userStartDateRangeInDays: 0,
        userEndDateRangeInMonths: 0,
        limitMonthsForStartDate: 0,
        limitMonthsForEndDate: 0,
    });
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const { authorize } = useAuth();

    const registration = async () => {
        const errorMessages: Array<string> = [];

        const mail = formData.email.match(
            /[a-zA-Z | 0-9]{1}[a-zA-Z | 0-9 | _ | \- | \.]{1,}[a-zA-Z | 0-9]{1}@[a-zA-Z | 0-9]{1}[a-zA-Z | 0-9 | _ | \- | \.]+\.[a-zA-Z]{2,}\b(?![^a-zA-Z])/,
        ) || [''];
        const phone = formData.phoneNumber.match(/^[+]*[0-9]+$/) || [''];

        if (formData.firstName.length === 0) {
            errorMessages.push('user-form.messages.error-firstName-not-valid');
        }
        if (formData.lastName.length === 0) {
            errorMessages.push('user-form.messages.error-lastName-not-valid');
        }
        if (mail[0].length === 0) {
            errorMessages.push('user-form.messages.error-mail');
        }
        if (formData.phoneNumber == null) {
            errorMessages.push('user-form.messages.error-phone-not-empty');
        } else if (formData.phoneNumber.length > 15 || phone[0].length === 0) {
            errorMessages.push('user-form.messages.error-phone-is-incorrect');
        }
        if (formData.userName.length === 0) {
            errorMessages.push('user-form.messages.error-login-not-valid');
        }
        if (formData.password != formData.passwordConfirm) {
            errorMessages.push('user-form.messages.error-password-dont-match');
        } else if (formData.password.length < 5) {
            errorMessages.push('user-form.messages.error-password-are-incorrect');
        }
        if (formData.birthday == null) {
            errorMessages.push('user-form.messages.error-birthday');
        } else if (
            new Date().getTime() - formData.birthday.getTime() < 18 * 365 * 24 * 60 * 60 * 1000 ||
            formData.birthday.getFullYear() < 1900
        ) {
            errorMessages.push('user-form.messages.error-age-are-incorrect');
        }

        errorMessages.forEach((m) => toast.error(t(m)));
        if (errorMessages.length === 0) {
            await signUp(formData);
            toast.success(t('user-form.messages.success-create'));
            history.push(allRoutes.signin.path);
        }
    };

    const fictitiousRegistration = async () => {
        const errorMessages: Array<string> = [];

        const mail = formData.email.match(
            /[a-zA-Z | 0-9]{1}[a-zA-Z | 0-9 | _ | \- | \.]{1,}[a-zA-Z | 0-9]{1}@[a-zA-Z | 0-9]{1}[a-zA-Z | 0-9 | _ | \- | \.]+\.[a-zA-Z]{2,}\b(?![^a-zA-Z])/,
        ) || [''];
        if (mail[0].length === 0) {
            errorMessages.push('user-form.messages.error-mail');
        }

        errorMessages.forEach((m) => toast.error(t(m)));
        if (errorMessages.length === 0) {
            formData.birthday = new Date();
            const response = await signUp(formData);
            if (response.data) {
                const loginFormData = {
                    userName: formData.email.split('@')[0],
                    password: '',
                    preferredLocalization: localStorage.getItem('locale') ?? '',
                } as LoginModel;

                const {
                    data: { accessToken, refreshToken },
                } = await logIn(loginFormData);

                authorize({ accessToken, refreshToken });
                localStorage.removeItem('currencyCode');
                history.push(generatePath(allRoutes.orders.path, { pageId: 1 }));
            }
        }
    };

    const registrationClick = async (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (!config.fictitiousMode) {
            await registration();
        } else {
            await fictitiousRegistration();
        }
    };

    const onChangeBirthDay = (data: Date) => {
        setFormData({ ...formData, birthday: data });
    };

    useEffect(() => {
        if (!isDataLoaded) {
            const loadConfig = async () => {
                const data = await getConfig();
                setIsDataLoaded(true);
                setConfig(data);
            };

            loadConfig();
        }
    }, [config]);

    return (
        <RegisterStyle>
            {!config.fictitiousMode && (
                <ResponsiveFlexBox justifyContent="space-between">
                    <Box className="first-name">
                        <TextField
                            autoComplete="given-name"
                            name="firstname"
                            value={formData.firstName}
                            onChange={(value: ChangeEvent<HTMLInputElement>) => {
                                setFormData({ ...formData, firstName: value.target.value });
                            }}
                            label={t('registration-form.firstName')}
                        />
                    </Box>
                    <Box className="last-name">
                        <TextField
                            autoComplete="family-name"
                            name="lastname"
                            value={formData.lastName}
                            onChange={(value: ChangeEvent<HTMLInputElement>) => {
                                setFormData({ ...formData, lastName: value.target.value });
                            }}
                            label={t('registration-form.lastName')}
                        />
                    </Box>
                </ResponsiveFlexBox>
            )}
            <div>
                <TextField
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={(value: ChangeEvent<HTMLInputElement>) => {
                        setFormData({ ...formData, email: value.target.value });
                    }}
                    label={t('registration-form.email-label')}
                />
            </div>
            {!config.fictitiousMode && (
                <PhoneInputWrapper>
                    {/* WARNING: Package is patched, to see its behavior, check frontend/patches */}
                    <PhoneInput
                        onChange={(value: string) => setFormData({ ...formData, phoneNumber: unmaskPhone(value) })}
                        value={formData.phoneNumber}
                        defaultCountry="ru"
                        name="phone"
                        autoComplete="tel-national"
                        label={t('registration-form.phonenumber-label')}
                        disableAreaCodes
                        autoFormat={!!formData.phoneNumber}
                        onFocus={() => setPhoneInputTouched(true)}
                        disableDropdown={!phoneInputTouched}
                        placeholder="+7 (999) 888 77 66"
                    />
                </PhoneInputWrapper>
            )}
            {!config.fictitiousMode && (
                <div>
                    <TextField
                        autoComplete="username"
                        name="username"
                        value={formData.userName}
                        onChange={(value: ChangeEvent<HTMLInputElement>) => {
                            setFormData({ ...formData, userName: value.target.value });
                        }}
                        label={t('registration-form.username-label')}
                    />
                </div>
            )}
            {!config.fictitiousMode && (
                <div>
                    <Password
                        autoComplete="new-password"
                        name="password"
                        label={t('registration-form.password-label')}
                        value={formData.password}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        iconColor="primary"
                        onChange={(value: ChangeEvent<HTMLInputElement>) => {
                            setFormData({ ...formData, password: value.target.value });
                        }}
                    />
                </div>
            )}
            {!config.fictitiousMode && (
                <div>
                    <Password
                        autoComplete="new-password"
                        name="confirm-password"
                        label={t('registration-form.passwordconfirm-label')}
                        value={formData.passwordConfirm}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        iconColor="primary"
                        onChange={(value: ChangeEvent<HTMLInputElement>) => {
                            setFormData({ ...formData, passwordConfirm: value.target.value });
                        }}
                    />
                </div>
            )}
            {!config.fictitiousMode && (
                <Box marginBottom="2.5rem">
                    <FlashDatePicker color="primary" onChangeValue={onChangeBirthDay} currentdt={formData.birthday} />
                </Box>
            )}
            <Box display="flex" justifyContent="flex-end">
                <Button
                    onClick={registrationClick}
                    type="submit"
                    color="primary"
                    variant="outlined"
                    name="submit"
                    id="SEO-registration-in-signup-page"
                >
                    <Typography variant="h5" className="registration">
                        {t('registration-form.submit')}
                    </Typography>
                </Button>
            </Box>
        </RegisterStyle>
    );
};

export default Register;
