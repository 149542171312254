import { FC, useEffect } from 'react';

import { useFormikContext } from 'formik';

const FormikAutoSubmit: FC = () => {
    const { submitForm, values } = useFormikContext();

    useEffect(() => {
        submitForm();
    }, [values, submitForm]);

    return null;
};

export default FormikAutoSubmit;
