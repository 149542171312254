import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FlashTooltip from '../FlashTooltip';
import { FlashGridHeaderStyled } from './style';

type Props = {
    header: string;
    withPadding: boolean;
    paddingLeft?: number;
    disableHighlighting?: boolean;
};

const FlashGridHeader: FC<Props> = (props) => {
    const { header, withPadding, paddingLeft: paddingLeftProps = 16, disableHighlighting = false } = props;
    const { t } = useTranslation();

    const text = t(header);
    const paddingLeft = withPadding ? paddingLeftProps : 0;

    return (
        <FlashTooltip disableHighlighting={disableHighlighting} title={text}>
            <FlashGridHeaderStyled style={{ paddingLeft: paddingLeft }}>{text}</FlashGridHeaderStyled>
        </FlashTooltip>
    );
};

export default FlashGridHeader;
