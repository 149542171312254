import React from 'react';
import { styled } from '@material-ui/core/styles';
import { IconButton as MaterialIconButton, IconButtonProps } from '@material-ui/core';

const FlashIconButton = styled(MaterialIconButton)({
    margin: 1,
});

export default function IconButton(props: IconButtonProps): JSX.Element {
    return <FlashIconButton {...props}>{props.children}</FlashIconButton>;
}
