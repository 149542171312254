import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import PricesIcon from '../../../atoms/Icon/PricesIcon';
import { OrderDescription } from './style';

export type OrderCostProps = {
    label: string;
    currencyCode?: string;
    cost?: number;
    description?: string;
};

export default function OrderCost(props: OrderCostProps): JSX.Element {
    const { label, cost, description, currencyCode } = props;

    return (
        <>
            <Box display="flex">
                <Typography variant="h4" className="order-cost">
                    {label}
                </Typography>
                <PricesIcon />
            </Box>
            {description && (
                <OrderDescription>
                    <Typography variant="subtitle1" className="order-user">
                        {description}
                    </Typography>
                </OrderDescription>
            )}
            {cost !== undefined && (
                <Typography variant="h4">
                    {cost.toFixed(2)} {currencyCode}
                </Typography>
            )}
        </>
    );
}
