import React from 'react';
import { FormControl, InputLabel, Select, SelectProps } from '@material-ui/core';

export type FlashDropDownProps = SelectProps & {
    label: string;
    userwidth: string;
};

export default function FlashDropDown(props: FlashDropDownProps): JSX.Element {
    const { label, userwidth, ...selectProps } = props;
    return (
        <FormControl style={{ width: userwidth }}>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select labelId="demo-simple-select-label" id="demo-simple-select" {...selectProps}></Select>
        </FormControl>
    );
}
