import {
    Access,
    AuthActionTypes,
    DELETE_NEXT_PATH,
    DeleteNextPath,
    Refresh,
    REMOVE_TOKENS,
    SET_NEXT_PATH,
    SetNextPath,
    UPDATE_ACCESS,
    UPDATE_REFRESH,
} from 'store/auth/types';

export const updateAccess = (accessData: Access): AuthActionTypes => ({
    type: UPDATE_ACCESS,
    accessData,
});

export const updateRefresh = (refreshData: Refresh): AuthActionTypes => ({
    type: UPDATE_REFRESH,
    refreshData,
});

export const removeTokens = (): AuthActionTypes => ({
    type: REMOVE_TOKENS,
});

export const setNextPath = (path: string): SetNextPath => ({
    type: SET_NEXT_PATH,
    path,
});

export const deleteNextPath = (): DeleteNextPath => ({
    type: DELETE_NEXT_PATH,
});
