import { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';

import { Box, Container } from '@material-ui/core';

import useSelector from 'hooks/useAppSelector';

import allRoutes from 'router';
import { AxiosResponse } from 'axios';

import MediaSpaceForm from 'components/organisms/forms/MediaSpace';
import FlashMap, { MapPlacemark } from 'components/organisms/FlashMap';
import MediaSpaceOrdersGrid from 'components/organisms/MediaSpaceOrdersGrid';
import MediaSpaceTopProfileNavigation from 'components/organisms/MediaSpaceTopProfileNavigation';

import { IBaseFormRef } from '../../../models/interface/i-form-data';

import { UserRoles, ValidationRules } from 'shared/enums';
import { create, getValidityById, sendImageToBackend, update } from 'api/media-space';
import { DefaultMediaSpaceModel, MediaSpaceImageModel, MediaSpaceModel, MediaSpaceVm } from 'models/media-space.model';

import { StyledFormContainer } from 'components/templates/MediaSpace/style';
import { getDateWithLocalTz } from 'hooks/getDateWithLocalTz';
import { getCurrentDate } from 'hooks/getCurrentDate';

import { getLanguages } from '../../../api/language';

export type MediaSpaceCRUDTemplateProps = {
    disabled?: boolean;
    hasEditAction?: boolean;
    uniqueData?: boolean;
};

export default function MediaSpaceCRUDTemplate(props: MediaSpaceCRUDTemplateProps): JSX.Element {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const locations = useLocation().state;
    const history = useHistory();

    const { disabled, hasEditAction } = props;

    const user = useSelector((state) => state.userReducer.user);
    const currentUserRole = useSelector((state) => state.userReducer.user.role);

    const [isDataLoaded, setIsDataLoaded] = useState(!id);

    const [mediaSpace, setMediaSpace] = useState<MediaSpaceModel>(DefaultMediaSpaceModel);
    const [placeMarks, setPlaceMarks] = useState<MapPlacemark[]>([]);
    const [originalData, setOriginalData] = useState<MediaSpaceModel>(DefaultMediaSpaceModel);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isNotDevices, setIsNotDevices] = useState(false);
    const [hasUnavailableDevices, setHasUnavailableDevices] = useState(false);
    const [mapDetectAddressOnChange, setMapDetectAddressOnChange] = useState(false);
    const [imageModel, setImageModel] = useState<MediaSpaceImageModel>();
    const [addresses, setAddresses] = useState<string[]>([]);
    const valid = originalData.tariffsValidityStatus === ValidationRules.Valid;

    const baseRef = useRef<IBaseFormRef>();

    useEffect(() => {
        if (mediaSpace.latitude !== undefined && mediaSpace.longitude !== undefined) {
            const currentPlacemark = placeMarks[0];
            if (
                !currentPlacemark ||
                currentPlacemark.lat != mediaSpace.latitude ||
                currentPlacemark.long !== mediaSpace.longitude ||
                currentPlacemark.name !== mediaSpace.name
            ) {
                setPlaceMarks([
                    {
                        isHighlighted: false,
                        lat: mediaSpace.latitude,
                        long: mediaSpace.longitude,
                        id: id || 'new',
                        address: mediaSpace.address,
                        name: mediaSpace.name,
                    },
                ]);

                if (currentPlacemark === undefined) {
                    setTimeout(() => {
                        setMapDetectAddressOnChange(true);
                    });
                }
            }
        }
    }, [mediaSpace]);

    useEffect(() => {
        setIsDisabled(isSubmiting || (disabled !== undefined && disabled));
    }, [isSubmiting, disabled]);

    const validateMediaSpaceModel = (data: MediaSpaceModel) => {
        const errors: Array<string> = [];

        if (!data.timeZoneId || data.timeZoneId.length === 0) {
            errors.push(t('server-messages.time-zone-is-required'));
        }
        if (data.address.length < 3 || data.address.length > 150) {
            errors.push(t('server-messages.address-not-valid'));
        }
        if (data.latitude && data.latitude < -90 && data.latitude > 90) {
            errors.push(t('server-messages.latitude-not-valid'));
        }
        if (data.longitude && data.longitude < -180 && data.longitude > 180) {
            errors.push(t('server-messages.longitude-not-valid'));
        }
        if (data.comment.length < 3 || data.comment.length > 200) {
            errors.push(t('server-messages.comment-not-valid'));
        }

        return errors;
    };

    async function preOnSubmit(data: MediaSpaceModel) {
        if (
            data &&
            data.names.find((x) => {
                return x.name === '';
            }) === undefined
        ) {
            const errors = validateMediaSpaceModel(data);
            if (errors.length > 0) {
                errors.forEach((x) => {
                    toast.error(x);
                });
            } else {
                const languages = (await getLanguages()).data;
                if (languages)
                    for (const i in data.names)
                        for (const j in languages)
                            if (languages[j].id === data.names[i].languageId) data.names[i].address = addresses[j];
                await onSubmit(data);
            }
        } else {
            toast.error(t('media-space-form.messages.media-space-data-not-valid'));
        }
    }

    async function onSubmit(data: MediaSpaceModel) {
        let redirectId = id;
        if (id) {
            await update(data, id);
            toast.success(t('media-space-form.messages.success-update'));
            //TODO: add check if it's the same image, then there is no need to send data to BE
            if (imageModel !== undefined) {
                imageModel.file !== undefined
                    ? await sendImageToBackend(id, imageModel.file)
                    : toast.warning('file is empty');
            }
        } else {
            const result = await create(data);
            toast.success(t('media-space-form.messages.success-create'));
            if (imageModel !== undefined) {
                imageModel.file !== undefined
                    ? await sendImageToBackend(result.data.id, imageModel.file)
                    : toast.warning('file is empty');
            }
            redirectId = result.data.id;
        }
        history.replace({
            pathname: generatePath(allRoutes.mediaSpaceProfile.path, { id: redirectId, pageId: 1 }),
            state: locations,
        });
    }

    useEffect(() => {
        setIsDataLoaded(false);
    }, [user.lc]);

    useEffect(() => {
        if (id && !isDataLoaded) {
            const getMediaSpace = async () => {
                const data: AxiosResponse<MediaSpaceVm> = await getValidityById(ValidationRules.Valid, id);
                const timeWhenDeviceIsOnline = 10;
                setIsDataLoaded(true);
                setIsNotDevices(data.data.devices.length === 0);
                setHasUnavailableDevices(
                    data.data.devices.some(
                        (device) =>
                            getDateWithLocalTz(device.lastConnectionDate) < getCurrentDate(timeWhenDeviceIsOnline),
                    ),
                );
                setMediaSpace(data.data);
                setOriginalData(data.data);
            };

            setTimeout(() => getMediaSpace(), 300);
        } else {
            setIsDataLoaded(true);
        }
    }, [id, isDataLoaded]);

    const isCreate = id === undefined;

    // Hack to fix maps
    const stateRef = useRef<{ mediaSpace: MediaSpaceModel }>();
    stateRef.current = { mediaSpace: mediaSpace };

    const onMapClick = (coordinates: Array<number>) => {
        setMediaSpace({
            ...(stateRef.current?.mediaSpace || mediaSpace),
            latitude: coordinates[0],
            longitude: coordinates[1],
        });
    };

    const onEdit =
        id && user.role === UserRoles.Admin && hasEditAction && !isCreate
            ? { pathname: generatePath(allRoutes.editMediaSpace.path, { id: id }), state: locations }
            : undefined;

    const hasDeleteAction = currentUserRole === UserRoles.Admin && !isCreate;

    const sendImage = (imageModel: MediaSpaceImageModel) => {
        setImageModel(imageModel);
    };

    return (
        <>
            <MediaSpaceTopProfileNavigation
                hasDeleteAction={hasDeleteAction}
                mediaSpace={mediaSpace}
                id={id}
                isCreate={isCreate}
                onEdit={onEdit}
                valid={valid}
                reason={originalData.tariffsValidityReason}
                isNotDevices={isNotDevices}
                hasUnavailableDevices={hasUnavailableDevices}
            />
            <StyledFormContainer display="flex" justifyContent="space-around">
                <Container disableGutters={true}>
                    <MediaSpaceForm
                        id={id}
                        onSubmiting={(isSubmiting: boolean) => {
                            setIsSubmiting(isSubmiting);
                        }}
                        disabled={isDisabled}
                        hideButtons={disabled}
                        model={mediaSpace}
                        originalData={originalData}
                        onSubmit={preOnSubmit}
                        isCreate={isCreate}
                        onStateChange={setMediaSpace}
                        sendImage={sendImage}
                        isOperator={currentUserRole === UserRoles.Operator}
                        ref={baseRef}
                    />
                </Container>
                <Box height="24.688rem" className="map-container">
                    {isDataLoaded && (
                        <FlashMap
                            hasSearchControl={true}
                            disabled={isDisabled}
                            placemarks={placeMarks}
                            onMapClick={onMapClick}
                            showDetailedPlacemarkContent={false}
                            detectaddressOnChange={mapDetectAddressOnChange}
                            onAddressChanged={(address: string) => {
                                setMediaSpace({
                                    ...(stateRef.current?.mediaSpace || mediaSpace),
                                    address: address,
                                });
                            }}
                            addresses={addresses}
                            setAddresses={setAddresses}
                        />
                    )}
                </Box>
            </StyledFormContainer>
            {!isCreate && isDisabled && <MediaSpaceOrdersGrid mediaSpaceId={id} />}
        </>
    );
}
