import { UPDATE_THEME, ThemeActionType, ThemeState } from 'store/theme/types';

const InitialState: ThemeState = {
    themeSettings: {
        isDarkMode: false,
    },
};

const themeReducer = (state = InitialState, action: ThemeActionType): ThemeState => {
    switch (action.type) {
        case UPDATE_THEME: {
            return {
                ...state,
                themeSettings: {
                    ...state.themeSettings,
                    isDarkMode: action.data.isDarkMode,
                },
            };
        }
        default:
            return state;
    }
};

export default themeReducer;
