import styled from 'styled-components';

export const Titles = styled.div(({}) => ({
    display: 'flex',
    img: {
        '@media only screen and (min-width: 320px) and (max-width: 424px)': {
            width: '30px',
        },
    },
    '.stage-title': {
        paddingTop: '0.4rem',
    },
}));
