import { Menu } from '@material-ui/core';
import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const StyledCheckboxMenu = styled.div({
    '.more-icon': {
        fontSize: '1.875rem',
    },
});

export const NotifMenuStyled = styled(Menu)(({}) => ({
    'ul.MuiList-padding': {
        background: variables.backgoroundColours.light,
        border: `2px solid ${variables.colours.primary}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
    },
}));
