import { variables } from '../../../theme/variables';
import styled from 'styled-components';
import { Button, IconButton } from '@material-ui/core';

export const Order = styled.div(({}) => ({
    position: 'fixed',
    zIndex: -1,
    top: '43rem',
    opacity: 0.2,
    img: {
        minWidth: '100%',
        minHeight: '100%',
    },
    '@media only screen and (max-width: 1439px)': {
        top: '36rem',
    },
    '@media only screen and (min-width : 1025px) and (max-width : 1366px)': {
        top: '36rem',
        right: '-10rem',
        img: {
            minWidth: '110%',
            minHeight: '110%',
        },
    },
    '@media only screen and (min-width : 768px) and (max-width : 1025px)': {
        top: '38rem',
        right: '-10rem',
        img: {
            minWidth: '110%',
            minHeight: '110%',
        },
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        top: '40rem',
        right: '-10rem',
        img: {
            minWidth: '110%',
            minHeight: '110%',
        },
    },
}));

export const ModerationBlock = styled.div(({}) => ({
    a: {
        color: 'black',
        fontWeight: 500,
    },
}));

export const CancelButton = styled(IconButton)({
    position: 'absolute',
    zIndex: 100,
    top: '0.313rem',
    right: '0.313rem',
    '.MuiSvgIcon-colorPrimary': {
        color: variables.colours.light,
    },
});

export const StyledButton = styled(Button)({
    border: `2px solid ${variables.colours.primary}`,
    '.cancel-text': {
        color: variables.colours.primary,
    },
    '.send-text': {
        color: variables.colours.light,
    },
    ':hover': {
        border: `2px solid ${variables.colours.primary}`,
        '.send-text': {
            color: variables.colours.primary,
        },
    },
    ':last-child': {
        marginLeft: '20px',
    },
});

export const OrderFormStyle = styled.div(({}) => ({
    '.filled-btn': {
        '.filled-btn-text': {
            color: variables.colours.light,
        },
        ':hover': {
            border: `2px solid ${variables.colours.primary}`,
            '.filled-btn-text': {
                color: variables.colours.primary,
            },
        },
        ':disabled': {
            '.filled-btn-text': {
                color: variables.colours.default,
            },
        },
    },
    '.outlined-btn': {
        marginLeft: 0,
        border: `2px solid ${variables.colours.primary}`,
        background: variables.colours.light,
        h5: {
            color: variables.colours.primary,
        },
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            width: '220px',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '190px',
            margin: '0 auto',
        },
    },
    '.statistic-btn': {
        border: `2px solid ${variables.colours.primary}`,
        ':disabled': {
            background: variables.colours.light,
            border: `1px solid ${variables.colours.default}`,
        },
    },
    '.next-btn': {
        marginLeft: '1.375rem',
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            marginLeft: 0,
            marginTop: '0.738rem',
            '&.decline-button': {
                margin: '0.738rem auto 0',
            },
        },
        '@media only screen and (min-width : 768px) and (max-width : 1100px)': {
            marginLeft: 0,
            marginTop: '0.738rem',
        },
    },
    '.order-btn': {
        margin: '0 !important',
        width: '190px',
    },
    '.order-action-panel': {
        '@media only screen and (min-width : 768px) and (max-width : 1100px)': {
            flexDirection: 'column',
        },
    },
    '.undo-button': {
        marginLeft: 0,
        border: `2px solid ${variables.colours.primary}`,
        background: variables.colours.light,
        h5: {
            color: variables.colours.primary,
        },
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            width: '220px',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '190px',
            margin: '0 auto',
        },
    },
    '.order-link': {
        p: {
            textDecoration: 'underline',
            ':hover': {
                color: variables.colours.primary,
                cursor: 'pointer',
            },
        },
    },
    '.go-back-button': {
        marginLeft: '0.125rem',
    },
    '.order-number': {
        paddingBottom: '0.625rem',
    },
    '.order-user': {
        color: variables.colours.default,
    },
    '.order-cost': {
        marginRight: '1.25rem',
        '@media only screen and (min-width : 320px) and (max-width : 768px)': {
            margin: 'auto 0 auto 0',
        },
    },
    '.chat-button': {
        h6: {
            marginRight: '0.625rem',
            marginLeft: '0.938rem',
            color: variables.colours.primary,
            textTransform: 'uppercase',
            '@media only screen and (min-width : 320px) and (max-width : 768px)': {
                marginRight: '0.225rem',
                marginLeft: '0.338rem',
            },
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            marginLeft: 0,
            marginTop: 0,
            marginBottom: 0,
        },
    },
    '.accept-button': {
        border: `2px solid ${variables.colours.primary}`,
        width: '230px',
        '.accept-text': {
            color: variables.colours.light,
        },
        ':hover': {
            border: `2px solid ${variables.colours.primary}`,
            '.accept-text': {
                color: variables.colours.primary,
            },
        },
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            width: '220px',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '190px',
            margin: '0 auto',
        },
        '@media only screen and (min-width : 768px) and (max-width : 1100px)': {
            margin: '0',
        },
    },
    '.in-part-button': {
        background: variables.colours.light,
        marginLeft: '1.375rem',
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            marginLeft: 0,
            marginTop: '0.738rem',
            '&.decline-button': {
                margin: '0.738rem auto 0',
            },
        },
        '@media only screen and (min-width : 768px) and (max-width : 1100px)': {
            marginLeft: 0,
            marginTop: '0.738rem',
        },
    },
    '.refund-title': {
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            marginBottom: '0.25rem',
            textAlign: 'center',
        },
    },
    '.refund-cost': {
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            marginTop: '0.625rem',
            marginBottom: '1rem',
        },
    },
    '@media only screen and (min-width : 767px)': {
        position: 'relative',
    },
}));

export const PayToShow = styled.div(({}) => ({
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        position: 'relative',
    },
}));
