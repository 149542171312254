import FlashCommonGlobal from 'components/atoms/FlashCommonGlobal';
import StatisticsCard from 'components/molecules/StatisticsCard';
import ContainerWithTitle from 'components/molecules/containers/ContainerWithTitle';
import { useTranslation } from 'react-i18next';
import { StatisticsStyled } from './style';

import ImageTV from 'assets/images/statistics-tv.png';
import ImagePoint from 'assets/images/statistics-point.png';
import ImageMap from 'assets/images/statistics-map.png';
import ImagePeople from 'assets/images/statistics-people.png';
import ImgMouthpiece from 'assets/images/statistics-mouthpiece.png';

type StatisticsProps = {
    title: string;
    marginBottom?: string;
};

export default function Statistics({ title, marginBottom = '140px' }: StatisticsProps): JSX.Element {
    const { t } = useTranslation();
    const cards = t('home-page.statistics.cards', { returnObjects: true }) as [{ title: string; text: string }];
    const ArrayImages: string[] = [ImageTV, ImagePoint, ImageMap, ImagePeople, ImgMouthpiece];

    return (
        <StatisticsStyled marginBottom={marginBottom}>
            <FlashCommonGlobal>
                <ContainerWithTitle title={title} marginBottom="0">
                    <div className="container">
                        {cards.map((card, i) => (
                            <StatisticsCard
                                key={i}
                                image={ArrayImages[i]}
                                title={card.title}
                                text={card.text}
                            ></StatisticsCard>
                        ))}
                    </div>
                </ContainerWithTitle>
            </FlashCommonGlobal>
        </StatisticsStyled>
    );
}
