import { variables } from '../../../../theme/variables';
import styled from 'styled-components';

export const MediaSpaceFormStyle = styled.div(({}) => ({
    '.MuiAutocomplete-endAdornment': {
        button: {
            margin: 0,
            border: 0,
            ':hover': {
                border: 0,
            },
        },
    },

    '.MuiInputBase-input': {
        color: variables.colours.dark,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },

    '.title-name-text-length': {
        display: 'flex',
        flexDirection: 'row-reverse',
    },

    '.comment': {
        '.MuiInputBase-input': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '0.938rem',
            lineHeight: '1.125rem',
            '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                fontSize: '0.813rem',
            },
            '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                fontSize: '0.625rem',
            },
        },
        '.MuiInput-underline:before': {
            borderBottom: 0,
        },
    },
    button: {
        border: `2px solid ${variables.colours.primary}`,
        marginTop: '1.25rem',
        marginBottom: '1.25rem',
        h5: {
            color: variables.colours.primary,
        },
        '.submit-text': {
            color: variables.colours.light,
        },
        ':hover': {
            border: `2px solid ${variables.colours.primary}`,
            '.submit-text': {
                color: variables.colours.primary,
            },
        },
        '@media only screen and (min-width: 320px) and (max-width: 374px)': {
            width: '8.938rem',
            marginBottom: 0,
            marginLeft: '25%',
        },
    },
    '.MuiButton-outlinedSecondary': {
        marginLeft: '3.313rem',
        '@media only screen and (min-width: 375px) and (max-width: 425px)': {
            width: 'fit-content',
            marginLeft: '1.313rem',
        },
        '@media only screen and (min-width: 320px) and (max-width: 374px)': {
            width: 'fit-content',
            marginLeft: '26.5%',
            marginBottom: '1.25rem',
        },
    },
}));
