import React, { useCallback, Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';

import CustomCheckbox from 'components/atoms/Checkbox';
import CheckboxMenu from 'components/atoms/CheckboxMenu';
import DateRenderer from 'components/atoms/DateRenderer';
import GridCellLink from 'components/atoms/GridCellLink';

import allRoutes from 'router';

import { MomentFormats } from 'shared/enums';
import { PlaylistGridVm } from 'models/playlist.model';
import { MediaSpaceShortVm } from 'models/media-space.model';

import { variables } from 'theme/variables';
import FlashGridHeader from '../../atoms/FlashGridHeader';
import { SortField } from '../../../models/paging/paging.model';
import FlashTooltip from 'components/atoms/FlashTooltip';

type PlaylistsGridProps = {
    playlists: PlaylistGridVm[];
    checked: string[];
    setChecked: Dispatch<SetStateAction<string[]>>;
    setModalToShow: Dispatch<SetStateAction<boolean>>;
};

export default function PlaylistsColumnsGrid(props: PlaylistsGridProps): GridColDef[] {
    const { playlists, checked, setChecked, setModalToShow } = props;
    const { t } = useTranslation();
    const history = useHistory();

    const getColumns = useCallback(() => {
        return [
            {
                field: SortField.PlaylistId,
                headerName: 'Menu',
                width: 70,
                sortable: false,
                disableClickEventBubbling: true,
                align: 'center',
                headerAlign: 'center',
                renderHeader: function renderHeader() {
                    return (
                        <CheckboxMenu
                            actions={[
                                {
                                    action: () => {
                                        setModalToShow(true);
                                    },
                                    label: t('playlist-form.delete-marked'),
                                },
                            ]}
                            setChecked={setChecked}
                            rows={playlists}
                        />
                    );
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return <CustomCheckbox params={params} checked={checked} setChecked={setChecked} />;
                },
            },
            {
                field: SortField.PlaylistName,
                flex: 0.6,
                headerAlign: 'left',
                align: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'playlists.name'} withPadding={false} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.playlistProfile.path, { id: params.row.id }),
                                state: history.location.pathname,
                            }}
                        >
                            <Typography variant="body1">{params.row.name}</Typography>
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.PlaylistStartDate,
                width: 198,
                align: 'left',
                headerAlign: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'playlists.startDate'} withPadding={false} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.playlistProfile.path, { id: params.row.id }),
                                state: history.location.pathname,
                            }}
                        >
                            <DateRenderer date={params.row.startDate} format={MomentFormats.Short} />
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.PlaylistEndDate,
                width: 202,
                headerAlign: 'left',
                align: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'playlists.endDate'} withPadding={false} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    const endDate = params.row.endDate;
                    if (endDate) {
                        return (
                            <GridCellLink
                                to={{
                                    pathname: generatePath(allRoutes.playlistProfile.path, { id: params.row.id }),
                                    state: history.location.pathname,
                                }}
                            >
                                <DateRenderer date={endDate} format={MomentFormats.Short} />
                            </GridCellLink>
                        );
                    } else {
                        return (
                            <GridCellLink
                                to={{
                                    pathname: generatePath(allRoutes.playlistProfile.path, { id: params.row.id }),
                                    state: history.location.pathname,
                                }}
                            >
                                <AllInclusiveIcon className="infinity" />
                            </GridCellLink>
                        );
                    }
                },
            },
            {
                field: SortField.PlaylistMediaSpaces,
                flex: 1.2,
                align: 'left',
                headerAlign: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'playlists.mediaSpaces'} withPadding={false} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    const mediaSpaces = params.row.mediaSpaces;
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.playlistProfile.path, { id: params.row.id }),
                                state: history.location.pathname,
                            }}
                        >
                            {params.row.isForAllMediaSpaces ? (
                                t('playlists.all-mediaSpaces')
                            ) : (
                                <>
                                    {mediaSpaces.length > 0 ? `${mediaSpaces[0].name}` : ''}
                                    {mediaSpaces.length > 1 && (
                                        <FlashTooltip
                                            disableHighlighting
                                            title={
                                                <Typography variant="body1" style={{ color: variables.colours.light }}>
                                                    {mediaSpaces.map((mediaSpace: MediaSpaceShortVm) => (
                                                        <span key={mediaSpace.id}>{mediaSpace.name}</span>
                                                    ))}
                                                </Typography>
                                            }
                                            placement="top-start"
                                            arrow
                                        >
                                            <>+{mediaSpaces.length - 1}</>
                                        </FlashTooltip>
                                    )}
                                </>
                            )}
                        </GridCellLink>
                    );
                },
            },
        ] as GridColDef[];
    }, [playlists, checked]);

    return getColumns();
}
