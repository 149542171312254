import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const OrderStatusLineStyle = styled.div(({}) => ({
    width: `100%`,
    overflowX: 'auto',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'inherit',
    lineHeight: '146.69%',
    h6: {
        color: variables.backgoroundColours.dark,
        fontWeight: 500,
        textAlign: 'center',
    },
    '.status-highlighted': {
        margin: '0 auto',
        color: variables.backgoroundColours.dark,
        hr: {
            background: variables.backgoroundColours.primary,
        },
    },
    '&.under-text': {
        width: '99%',
    },
    hr: {
        background: variables.backgoroundColours.border,
        border: '0',
        height: '2px',
        width: '3.375rem',
        margin: '32px 0.5rem !important',
        '&.status-highlighted-processing': {
            background: variables.orderStatusLine.processing,
        },
        '&.status-highlighted-completed': {
            background: variables.orderStatusLine.completed,
        },
        '@media only screen and (min-width : 320px) and (max-width : 1300px)': {
            display: 'none',
        },
    },
    '@media only screen and (min-width : 320px) and (max-width : 374px)': {
        '&.under-text': {
            width: '275px',
            marginLeft: '-13px',
        },
    },
    '::-webkit-scrollbar': {
        height: '5px',
    },
    '::-webkit-scrollbar-thumb': {
        background: variables.colours.main,
        borderRadius: '29px',
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: variables.backgoroundColours.primary,
    },
    '::-webkit-scrollbar-track': {
        borderRadius: '0px',
    },
    '@media only screen and (max-width : 767px)': {
        // whiteSpace: 'nowrap',
    },
}));

export const ImgStatus = styled.div(({}) => ({
    width: '64px',
    height: '64px',
    background: variables.backgoroundColours.border,
    borderRadius: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    marginBottom: '8px !important',
    '&.status-highlighted-processing': {
        background: variables.orderStatusLine.processing,
    },
    '&.status-highlighted-completed': {
        background: variables.orderStatusLine.completed,
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        width: '48px',
        height: '48px',
    },
}));
