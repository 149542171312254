import { FC, useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { Backdrop, Fade } from '@material-ui/core';
import { GridColDef, GridSortModel } from '@material-ui/data-grid';

import NoDataMessage from 'components/atoms/NoDataMessage';
import FlashDialog from 'components/atoms/dialog/FlashDialog';
import TextContent from 'components/atoms/dialog/TextContent';
import PagedDataGrid from 'components/organisms/PagedDataGrid';
import YesNoButtons from 'components/atoms/dialog/YesNoButtons';
import FilteredTemplate from 'components/templates/FilteredTemplate';
import DevicesColumnsGrid from 'components/templates/Devices/ColumnsGrid';
import DevicesFilter, { DevicesFilterData } from 'components/atoms/gridFilters/DevicesFilter';

import useSelector from 'hooks/useAppSelector';

import { PageSize } from 'shared/constants';
import { devicesAPI } from 'api/devices';
import { DeviceBaseVm, DevicePage } from 'models/device.model';
import { DevicesFilterRuleEnum, DeviceStatusEnum } from 'components/atoms/gridFilters/DeviceStatusSelect';

import { DevicesGridStyled } from 'components/templates/Devices/style';
import { ImageViewStyled, ModalStyled } from 'components/organisms/forms/Playlist/style';
import { screenshotInfoStyle } from 'components/templates/Devices/screenshotInfoStyle';
import { getSortingParams } from '../../../utils/sortingHelper';
import { SortDirections, SortField, SortModel } from '../../../models/paging/paging.model';

const DevicesTemplate: FC = () => {
    const defaultSort: SortModel = {
        property: SortField.CreatedDate,
        direction: SortDirections.Asc,
    };

    const auth = useSelector((state) => state.authReducer.auth);
    const user = useSelector((state) => state.userReducer.user);
    const filters = useSelector((state) => state.deviceFilterReducer.device);

    const [loading, setLoading] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<DeviceBaseVm>();
    const [devices, setDevices] = useState<Array<DeviceBaseVm>>([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [filter, setFilter] = useState<DevicesFilterData>(filters);
    const [onlyInvalid, setOnlyInvalid] = useState<boolean>(false);
    const [sort, setSort] = useState<SortModel>(defaultSort);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);

    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const [deviceIdToDelete, setDeviceIdToDelete] = useState<string>('');
    const [screenshotSrc, setScreenshotSrc] = useState('');
    const [showScreenshotInfo, setScreenshotInfo] = useState<boolean>(false);
    const [playlistFileName, setPlaylistFileName] = useState<string>('');
    const [playlistName, setPlaylistName] = useState<string>('');
    const [fileName, setFileName] = useState<string>('');
    const [orderNumber, setOrderNumber] = useState<number>(0);

    const history = useHistory();

    // eslint-disable-next-line
    const onClickOuterModal = (event: any) => {
        const modal = document.getElementsByClassName('modal');
        if (modal[0] !== event.target && event.target.nodeName !== 'IMG') {
            setScreenshotInfo(false);
        }
    };

    const normalizedName = playlistFileName
        ? `${playlistName}/${playlistFileName.slice(playlistFileName.lastIndexOf('_') + 1)}`
        : `${orderNumber}/${fileName}`;

    const columns: GridColDef[] = DevicesColumnsGrid({
        devices,
        setDeleteDialogOpen,
        setImageModalOpen,
        setScreenshotSrc,
        setDeviceIdToDelete,
        setScreenshotInfo,
        setPlaylistFileName,
        setFileName,
        setOrderNumber,
        setPlaylistName,
    });

    const checkInvalid = async () => {
        if (auth.accessToken) {
            const invalid = await devicesAPI.checkDevices(auth.accessToken);
            setInvalid(invalid.data);
        }
    };

    const getReq = () => {
        const req: DevicePage = {
            page: page,
            pageSize: PageSize,
            property: sort?.property,
            direction: sort?.direction,
            filterString: filter.filterString,
            mediaSpaceIds: filter.selectedMediaSpaces ?? [],
            status: filter.status === DeviceStatusEnum.all ? null : filter.status,
            filterRule: onlyInvalid ? DevicesFilterRuleEnum.onlyInvalid : DevicesFilterRuleEnum.all,
        };
        return req;
    };

    const loadData = useCallback(async () => {
        const req = getReq();
        await getDevices(req);
    }, [
        onlyInvalid,
        page,
        filter.filterString,
        filter.status,
        filter.selectedMediaSpaces,
        sort.property,
        sort.direction,
        PageSize,
    ]);

    const getDevices = async (req: DevicePage) => {
        await checkInvalid();
        const res = await devicesAPI.getPage(req);
        const totalPageNumber = Math.ceil(res.total / res.pageSize);

        setDevices(res.data);
        setTotalPage(totalPageNumber);
        setTotalItems(res.total);
    };

    const deleteDevice = async () => {
        await devicesAPI.deleteById(deviceIdToDelete);
        toast.success(t('devices.success-delete'));
        await loadData();
        setDeleteDialogOpen(false);
    };

    useEffect(() => {
        setLoading(true);
        (async () => {
            await loadData();
            setLoading(false);
        })();
    }, [page, onlyInvalid, user.lc, sort, filter]);

    useEffect(() => {
        if (id) {
            const page = Number(id);
            setPage(page);
        }
    }, [id]);

    useEffect(() => {
        document.addEventListener('click', onClickOuterModal, false);
        return function () {
            document.removeEventListener('click', onClickOuterModal, false);
        };
    }, []);

    const handleFilterChange = (filter: DevicesFilterData) => {
        history.push(history.location.pathname.replace(id, '1'));
        setFilter(filter);
    };

    const handleSorting = (model: GridSortModel) => {
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
            const sort = getSortingParams(model, defaultSort.property, defaultSort.direction);
            setSort(sort);
        }
    };

    return (
        <>
            {showScreenshotInfo && (
                <div className="modal" style={screenshotInfoStyle}>
                    {(playlistFileName || fileName) && <p style={{ margin: '0 auto' }}>{normalizedName}</p>}
                    {!playlistFileName && !fileName && <p style={{ margin: '0 auto' }}>{t('devices.nothing-plays')}</p>}
                </div>
            )}
            <DevicesGridStyled>
                <ModalStyled
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={imageModalOpen}
                    closeAfterTransition
                    onClose={() => {
                        setImageModalOpen(false);
                    }}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    data-id="modal"
                >
                    <Fade in={imageModalOpen}>
                        <ImageViewStyled src={screenshotSrc} />
                    </Fade>
                </ModalStyled>
                <FilteredTemplate
                    header={t('devices.header')}
                    filter={
                        <DevicesFilter
                            onFilterChange={handleFilterChange}
                            checkboxValue={onlyInvalid}
                            setCheckbox={() => {
                                history.push(history.location.pathname.replace(id, '1'));
                                setOnlyInvalid((state) => !state);
                            }}
                        />
                    }
                    isDevices={Boolean(invalid)}
                    content={
                        <PagedDataGrid
                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model: GridSortModel) => {
                                handleSorting(model);
                            }}
                            rows={devices}
                            columns={columns}
                            defaultPage={page}
                            totalPages={totalPage}
                            totalItems={totalItems}
                            isDataLoaded={!loading}
                            noDataMessage={<NoDataMessage />}
                            isLoadingIndicator={!loading}
                            filterMode="server"
                            customPagination={true}
                            disableColumnSelector={true}
                        />
                    }
                />
                <FlashDialog
                    open={deleteDialogOpen}
                    title={t('devices.delete-device')}
                    isOpen={deleteDialogOpen}
                    onClose={() => {
                        setDeleteDialogOpen(false);
                    }}
                    content={<TextContent text={t('devices.contentText')} />}
                    controls={
                        <YesNoButtons
                            onConfirm={deleteDevice}
                            onReject={() => {
                                setDeleteDialogOpen(false);
                            }}
                        />
                    }
                />
            </DevicesGridStyled>
        </>
    );
};

export default DevicesTemplate;
