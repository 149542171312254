import styled from 'styled-components';

import { Box } from '@material-ui/core';

export const StyledBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',

    '.MuiListItemIcon-root': {
        '& > div': {
            position: 'relative',

            '& > svg': {
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateY(-50%)',
            },
        },
    },
});
