import FlashCommonGlobal from 'components/atoms/FlashCommonGlobal';
import NumericalCard from 'components/molecules/NumericalCard';
import ContainerWithTitle from 'components/molecules/containers/ContainerWithTitle';
import { useTranslation } from 'react-i18next';
import { HowItWorkStyled } from './style';

type HowItWorkProps = {
    title: string;
    teaser: string;
    marginBottom?: string;
};

export default function HowItWork({ title, teaser, marginBottom = '140px' }: HowItWorkProps): JSX.Element {
    const { t } = useTranslation();
    const cards = t('home-page.how-it-work.cards', { returnObjects: true }) as [
        { number: string; title: string; text: string },
    ];

    return (
        <HowItWorkStyled marginBottom={marginBottom}>
            <FlashCommonGlobal>
                <ContainerWithTitle title={title} teaser={teaser} marginBottom="0">
                    <div className="container">
                        {cards.map((card, i) => (
                            <NumericalCard
                                key={i}
                                number={card.number}
                                title={card.title}
                                text={card.text}
                            ></NumericalCard>
                        ))}
                    </div>
                </ContainerWithTitle>
            </FlashCommonGlobal>
        </HowItWorkStyled>
    );
}
