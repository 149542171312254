import { createBrowserHistory } from 'history';
import { FlashRouterProps } from '../../router';

const withRefresh = createBrowserHistory({ forceRefresh: true });
const ROOT_PATH = process.env.PUBLIC_URL || '';

const useRedirectWithData = <T>(route: FlashRouterProps, data: T): void => {
    if (data) {
        withRefresh.push({
            pathname: `${ROOT_PATH}${route.path}`,
            state: { ...data },
        });
    }
};

export default useRedirectWithData;
