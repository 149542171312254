import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';

import { GridColDef, GridSortModel } from '@material-ui/data-grid';

import useDebouncedEffect from 'hooks/useDebouncedEffect/useDebouncedEffect';

import NoDataMessage from 'components/atoms/NoDataMessage';
import FlashDialog from 'components/atoms/dialog/FlashDialog';
import TextContent from 'components/atoms/dialog/TextContent';
import PagedDataGrid from 'components/organisms/PagedDataGrid';
import YesNoButtons from 'components/atoms/dialog/YesNoButtons';
import PriceColumnsGrid from 'components/organisms/PricesDataGrid/ColumnsGrid';
import { StyledPricesGrid } from 'components/organisms/PricesDataGrid/style';

import { PageSize } from 'shared/constants';
import { deletePrices, getPaged, getById } from 'api/price';
import { PriceGridVm, PricesPagedRequest } from 'models/price.model';

import useSelector from 'hooks/useAppSelector';
import { getSortingParams } from '../../../utils/sortingHelper';
import { SortDirections, SortField, SortModel } from '../../../models/paging/paging.model';

export default function PricesDataGrid(): JSX.Element {
    const defaultSort: SortModel = {
        property: SortField.CreatedDate,
        direction: SortDirections.Desc,
    };

    const { t } = useTranslation();
    const user = useSelector((state) => state.userReducer.user);

    const filters = useSelector((state) => state.priceFilterReducer.price);
    const { id } = useParams<{ id: string }>();

    const [isModalToShow, setModalToShow] = useState<boolean>(false);

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isLoadingIndicator, setIsLoadingIndicator] = useState(true);
    const [checked, setChecked] = useState<string[]>([]);
    const [sort, setSort] = useState<SortModel>(defaultSort);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);

    const deleteSelectedPrices = async () => {
        for (let i = 0; i < checked.length; i++) {
            const priceGroup = await getById(checked[i]);
            if (priceGroup.data.isDefault) {
                checked.splice(i, 1);
                toast.error(t('price-form.messages.error-cannot-be-deleted'));
                break;
            }
        }
        await deletePrices(checked);
        getPage();
    };

    const closeDeleteModal = () => {
        setModalToShow(false);
    };

    const [prices, setPrices] = useState<Array<PriceGridVm>>([]);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [page, setPage] = useState(Number(id));

    const columns: GridColDef[] = PriceColumnsGrid({
        prices,
        setModalToShow,
        checked,
        setChecked,
    });

    useEffect(() => {
        if (id) {
            if (id != ':id') {
                const page = Number(id);
                setPage(page);
            } else setPage(1);
        } else setPage(1);
    }, [id]);

    const getPage = async () => {
        setIsLoadingIndicator(false);
        const request = {
            page: page,
            pageSize: PageSize,
            property: sort.property,
            direction: sort.direction,
            filterString: filters.filterString,
            mediaSpaceIds: filters.MediaSpacesIds,
            startDate: filters.startDate,
            endDate: filters.endDate,
            status: filters.status,
        } as PricesPagedRequest;
        const response = await getPaged(request);
        setModalToShow(false);
        const totalPageNumber = Math.ceil(response.total / response.pageSize);
        setTotalPage(totalPageNumber);
        setTotalItems(response.total);
        setPrices(response.data);
        setIsDataLoaded(true);
        setIsLoadingIndicator(true);
    };

    useDebouncedEffect(() => getPage(), 500, [filters, page, user.lc, sort]);

    const handleSorting = (model: GridSortModel) => {
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
            const sort = getSortingParams(model, defaultSort.property, defaultSort.direction);
            setSort(sort);
        }
    };

    return (
        <>
            <StyledPricesGrid>
                <PagedDataGrid
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={(model: GridSortModel) => {
                        handleSorting(model);
                    }}
                    isLoadingIndicator={isLoadingIndicator}
                    isDataLoaded={isDataLoaded}
                    totalPages={totalPage}
                    totalItems={totalItems}
                    defaultPage={page}
                    noDataMessage={<NoDataMessage />}
                    columns={columns}
                    disableColumnSelector={true}
                    rows={prices}
                    filterMode="server"
                    customPagination={true}
                />
            </StyledPricesGrid>
            <FlashDialog
                open={isModalToShow}
                isOpen={isModalToShow}
                onClose={closeDeleteModal}
                controls={<YesNoButtons onConfirm={deleteSelectedPrices} onReject={closeDeleteModal} />}
                content={<TextContent text={t('price.delete-bulk-confirmation-content')} />}
                title={t('price.delete-bulk-dialog-title')}
            />
        </>
    );
}
