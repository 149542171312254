import styled from 'styled-components';

import { variables } from 'theme/variables';

export const StyledMediaOrderList = styled.div(({}) => ({
    '.MuiDataGrid-cell': {
        ':first-child': {
            color: variables.colours.main,
            overflow: 'visible',
        },
        ':nth-child(2)': {
            padding: '0 1.25rem',
            a: {
                textAlign: 'start',
            },
        },
        ':nth-child(3)': {
            a: {
                display: 'flex',
                justifyContent: 'flex-start',
            },
            paddingRight: '0.313rem',
            '.MuiBox-root': {
                minWidth: '2.938rem',
            },
            '.MuiTypography-body1': {
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },

        ':nth-child(4)': {
            a: {
                textAlign: 'start',
                color: variables.colours.dark,
            },
            padding: '0 1.25rem',
            lineHeight: '1.713rem!important',
        },
        ':nth-child(6)': {
            a: {
                textAlign: 'start',
                justifyContent: 'start',
            },
            color: variables.colours.main,
        },
        '.order-number': {
            position: 'relative',
        },
        '.payment-attention': {
            position: 'absolute',
            right: '2.5rem',
            top: '0.625rem',
        },
        '.user-name': {
            color: variables.colours.light,
        },
        '.media_spaces_order-userName_cell': {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
        },
    },
}));
