import styled from 'styled-components';

import { Link, LinkProps } from 'react-router-dom';

import { Link as MuiLink } from '@material-ui/core';

export const StyledCellLink = styled(MuiLink).attrs({
    component: Link,
})<LinkProps>({
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',

    ':hover': {
        textDecoration: 'none',
    },
});
