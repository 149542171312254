import styled from 'styled-components';
import { variables } from '../../../theme/variables';
import { Typography } from '@material-ui/core';

export const HeaderTypographyStyle = styled(Typography)(({}) => ({
    color: variables.colours.primary,
    marginBottom: '0.625rem',
    paddingBottom: '0.313rem',
    borderBottom: `1.5px solid ${variables.colours.default}`,
}));
