import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { GridColDef, GridSortModel } from '@material-ui/data-grid';

import NoDataMessage from 'components/atoms/NoDataMessage';
import FlashDialog from 'components/atoms/dialog/FlashDialog';
import TextContent from 'components/atoms/dialog/TextContent';
import PagedDataGrid from 'components/organisms/PagedDataGrid';
import YesNoButtons from 'components/atoms/dialog/YesNoButtons';
import UsersColumnsGrid, { UserRow } from 'components/pages/Users/ColumnsGrid';
import FilteredTemplate from 'components/templates/FilteredTemplate';
import { FieldValue } from 'components/atoms/gridFilters/RolesSelect';
import { UserStatesEnum } from 'components/atoms/gridFilters/UserStateSelect';
import UserFilter, { UserFilterData } from 'components/atoms/gridFilters/UserFilter';

import allRoutes from 'router';

import { UserPagedRequest } from 'models/user-model';
import { PageSize } from 'shared/constants';
import { deleteById, bulkDelete, getUserPage, bulkDisable } from 'api/users';

import { StyledUserList } from 'components/pages/Users/style';

import useSelector from '../../../hooks/useAppSelector';
import { RootState } from '../../../store/rootReducer';
import { UserRoles } from '../../../shared/enums';
import { getSortingParams } from '../../../utils/sortingHelper';
import { SortDirections, SortField, SortModel } from '../../../models/paging/paging.model';

export default function UsersPage(): JSX.Element {
    const defaultSort: SortModel = {
        property: SortField.CreatedDate,
        direction: SortDirections.Desc,
    };

    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const user = useSelector((state: RootState) => state.userReducer.user);

    const [users, setUsers] = useState(Array<UserRow>());
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const [openEnable, setOpenEnable] = useState(false);
    const [openDisable, setOpenDisable] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState('');
    const [isOperator, setIsOperator] = useState<boolean>(false);

    const columns: GridColDef[] = UsersColumnsGrid({
        users,
        setOpen,
        setOpenEnable,
        setOpenDisable,
        checked,
        setChecked,
        setDeleteUserId,
    });

    const [totalPage, setTotalPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState<UserFilterData>({
        filterString: '',
        selectedRole: FieldValue.allRoles.toString(),
        state: UserStatesEnum.unlocked,
    });
    const [sort, setSort] = useState<SortModel>(defaultSort);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);

    const history = useHistory();

    const onFilterChange = (filter: UserFilterData) => {
        history.push(history.location.pathname.replace(id, '1'));
        setFilter(filter);
    };

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const onDeleteFunction = async () => {
        await deleteById(deleteUserId);
        await bulkDelete(checked);
        checked.length
            ? toast.success(t('user-form.messages.success-delete-bulk'))
            : toast.success(t('user-form.messages.success-delete'));
        await loadData();
        handleClose();
        _.debounce(() => (checked.length = 0), 10)();
    };

    const onEnableFunction = async () => {
        if (checked.length > 1) {
            await bulkDisable(checked, false);
            toast.success(t('user-form.messages.success-disable-bulk'));
            checked.length = 0;
        } else if (checked.length === 1) {
            await bulkDisable(checked, false);
            toast.success(t('user-form.messages.success-disable'));
            checked.length = 0;
        } else {
            toast.error(t('user-form.messages.error-bulk'));
        }
        await loadData();
        handleClose();
        _.debounce(() => (checked.length = 0), 10)();
    };

    const onDisableFunction = async () => {
        if (checked.length > 1) {
            await bulkDisable(checked, true);
            toast.success(t('user-form.messages.success-enable-bulk'));
            checked.length = 0;
        } else if (checked.length === 1) {
            await bulkDisable(checked, true);
            toast.success(t('user-form.messages.success-enable'));
            checked.length = 0;
        } else {
            toast.error(t('user-form.messages.error-bulk'));
        }
        await loadData();
        handleClose();
        _.debounce(() => (checked.length = 0), 10)();
    };

    const handleClose = () => {
        setOpen(false);
        setOpenEnable(false);
        setOpenDisable(false);
    };

    const getReq = () => {
        let allRolesVal = filter.selectedRole;
        if (filter.selectedRole == FieldValue.allRoles) {
            allRolesVal = undefined;
        }

        const req: UserPagedRequest = {
            page: page,
            userRole: allRolesVal,
            property: sort?.property,
            direction: sort?.direction,
            filterString: filter.filterString,
            pageSize: PageSize,
            status: filter.state,
        };
        return req;
    };

    const loadData = useCallback(async () => {
        const req = getReq();
        await getUsers(req);
    }, [page, sort.property, sort.direction, PageSize, filter.filterString, filter.selectedRole, filter.state]);

    const getUsers = async (req: UserPagedRequest) => {
        const res = await getUserPage(req);
        const totalPageNumber = Math.ceil(res.total / res.pageSize);
        const usersList: UserRow[] = res.data.map((x) => {
            return {
                id: x.id,
                userName: `${x.firstName + ' ' + x.lastName}`,
                role: x.role,
                isLockedOut: x.isLockedOut,
                email: x.email,
                phoneNumber: x.phoneNumber,
            } as UserRow;
        });

        setUsers(usersList);
        setTotalPage(totalPageNumber);
        setTotalItems(res.total);
    };

    useEffect(() => {
        setLoading(true);
        (async () => {
            await loadData();
            setLoading(false);
        })();
    }, [page, user.lc, sort, filter]);

    useEffect(() => {
        if (id) {
            const page = Number(id);
            setPage(page);
            setIsOperator(user.role === UserRoles.Operator);
        }
    }, [id]);

    const handleSorting = (model: GridSortModel) => {
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
            const sort = getSortingParams(model, defaultSort.property, defaultSort.direction);
            setSort(sort);
        }
    };

    return (
        <>
            <StyledUserList>
                <FilteredTemplate
                    header={t('users-page.header')}
                    createItemPageRoute={isOperator ? undefined : allRoutes.createUser}
                    filter={<UserFilter onFilterChange={onFilterChange} />}
                    content={
                        <PagedDataGrid
                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model: GridSortModel) => {
                                handleSorting(model);
                            }}
                            isLoadingIndicator={!loading}
                            isDataLoaded={!loading}
                            totalPages={totalPage}
                            totalItems={totalItems}
                            defaultPage={page}
                            noDataMessage={<NoDataMessage />}
                            columns={columns}
                            disableColumnSelector={true}
                            rows={users}
                            onRowClick={() => scrollToTop()}
                            filterMode="server"
                            customPagination={true}
                        />
                    }
                />
                <FlashDialog
                    open={open}
                    title={checked.length > 1 ? t('user.delete-users') : t('user.delete-user')}
                    isOpen={open}
                    onClose={handleClose}
                    content={
                        <TextContent text={checked.length > 1 ? t('user.contentTextBulk') : t('user.contentText')} />
                    }
                    controls={<YesNoButtons onConfirm={onDeleteFunction} onReject={handleClose} />}
                />
                <FlashDialog
                    open={openEnable || openDisable}
                    title={
                        checked.length > 1
                            ? openEnable
                                ? t('user.enable-users')
                                : t('user.disable-users')
                            : openEnable
                            ? t('user.enable-user')
                            : t('user.disable-user')
                    }
                    isOpen={openEnable || openDisable}
                    onClose={handleClose}
                    content={
                        <TextContent
                            text={
                                checked.length > 1
                                    ? openEnable
                                        ? t('user.enableContentTextBulk')
                                        : t('user.disableContentTextBulk')
                                    : openEnable
                                    ? t('user.enableContentText')
                                    : t('user.disableContentText')
                            }
                        />
                    }
                    controls={
                        <YesNoButtons
                            onConfirm={openEnable ? onEnableFunction : onDisableFunction}
                            onReject={handleClose}
                        />
                    }
                />
            </StyledUserList>
        </>
    );
}
