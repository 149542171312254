import styled from 'styled-components';
import { Container } from '@material-ui/core';
import { variables } from '../../../theme/variables';

export const FullScreenStyledContainer = styled(Container)(({}) => ({
    padding: 0,
    maxWidth: '100%',
    minHeight: '100%',

    '@media only screen and (min-width : 320px) and (max-width : 1023px)': {
        padding: 0,
    },
    ...ChildrenElements,
}));

export const StyledContainer = styled(Container)(({ theme }) => ({
    padding: theme.spacing(5.3),
    maxWidth: '1280px',
    minHeight: '100%',

    '@media only screen and (min-width : 320px) and (max-width : 1023px)': {
        padding: '0.625rem 0.625rem 2rem 0.625rem',
    },
    ...ChildrenElements,
}));

const ChildrenElements = {
    '.title': {
        h1: {
            color: variables.colours.primary,
            fontSize: '1.25rem',
            width: '6.875rem',
            borderBottom: `4px solid ${variables.colours.primary}`,
            marginBottom: '-0.688rem',
            paddingBottom: '0.625rem',
            '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                fontSize: '1.125rem',
            },
            '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                fontSize: '1.125rem',
            },
        },
        width: '100%',
        borderBottom: `2px solid ${variables.backgoroundColours.border}`,
    },
    '.mediaspaces': {
        marginTop: '1.875rem',
        display: 'flex',
        alignItems: 'center',
    },
    '.cost': {
        marginTop: '0.625rem',
        width: '50%',
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '100%',
        },
    },
};
