import styled from 'styled-components';

export const StyledSection = styled.div(({}) => ({
    display: 'flex',

    '.avatar': {
        width: '3.125rem',
        height: '3.125rem',
        marginRight: '0.625rem',
    },
    '.menu': {
        '@media only screen and (min-width : 320px) and (max-width : 1024px)': {
            display: 'none',
        },
    },
    '.MuiIconButton-root:first-child': {
        padding: '0.875rem',
        paddingTop: '0.625rem',
    },
}));
