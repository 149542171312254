import * as React from 'react';
import { Redirect } from 'react-router-dom';

export type RedirectProps = {
    to?: string;
};

export default function RedirectPage(props: RedirectProps): JSX.Element {
    return <Redirect to={props.to || '/login'} />;
}
