import { TextField, TextFieldProps } from '@material-ui/core';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../Icon/CalendarIcon';
import { DatePickerStyle } from './style';

import { MomentFormats } from '../../../shared/enums';
import { useTranslation } from 'react-i18next';
import useSelector from '../../../hooks/useAppSelector';
export type FlashDatePickerProps = ReactDatePickerProps & {
    label?: string;
    icon?: boolean;
    isWeek?: boolean;
    dateRange?: boolean;
    infinite?: boolean;
};

export default function FlashDatePicker(props: FlashDatePickerProps): JSX.Element {
    const {
        label,
        onChange,
        startDate,
        selected,
        endDate,
        open,
        icon,
        disabled,
        isWeek,
        readOnly,
        dateRange,
        infinite,
        ...datepickerProps
    } = props;

    const [displayValue, setDisplayValue] = useState<string>('');
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const { t } = useTranslation();
    const user = useSelector((state) => state.userReducer.user);
    const [locale, setLocale] = useState<string>(user.lc);

    useEffect(() => {
        setIsOpened(open || isOpened || false);
        if (startDate && endDate) {
            setDisplayValue(
                `${moment(startDate).format(MomentFormats.LongLocalDate)} - ${moment(endDate).format(
                    MomentFormats.LongLocalDate,
                )}`,
            );
        } else if (!selected) {
            setDisplayValue('');
        } else {
            setDisplayValue(moment(selected).lang(user.lc).format(MomentFormats.LongLocalDate));
        }
    }, [startDate, selected, endDate, user.lc]);

    useEffect(() => setLocale(user.lc), [user.lc]);

    const onDateChanged = (
        date: Date | [Date, Date] | /* for selectsRange */ null,
        event: React.SyntheticEvent<unknown> | undefined,
    ) => {
        if (!readOnly) {
            if (date instanceof Array && date[1] === null) {
                event?.preventDefault();
                setIsOpened(true);
            } else {
                setIsOpened(false);
            }
            if (onChange) {
                onChange(date, event);
            }
        }
    };

    const RangeDateCustomTextInput = forwardRef(function CustomeInputFunction(
        {}: TextFieldProps,
        ref: React.Ref<unknown>,
    ) {
        return (
            <TextField
                color="secondary"
                fullWidth
                value={displayValue ? displayValue : t('order-form.order-date')}
                label={label}
                onClick={() => {
                    if (open === undefined && !disabled) {
                        setIsOpened(!isOpened);
                    }
                }}
                disabled={disabled}
                InputProps={{
                    endAdornment: <>{!icon && <CalendarIcon />}</>,
                }}
                ref={ref as React.RefObject<HTMLDivElement>}
            />
        );
    });
    RangeDateCustomTextInput.displayName = 'RangeDateCustomTextInput';

    const OneDateCustomTextInput = forwardRef(function ({ onClick }: TextFieldProps, ref: React.Ref<unknown>) {
        return (
            <TextField
                color="secondary"
                fullWidth
                className="example-custom-input"
                value={displayValue ? displayValue : t('order-form.order-date')}
                onClick={onClick}
                disabled={disabled}
                InputProps={{
                    endAdornment: <>{!icon && <CalendarIcon />}</>,
                }}
                ref={ref as React.RefObject<HTMLDivElement>}
            />
        );
    });
    OneDateCustomTextInput.displayName = 'OneDateCustomTextInput';

    return (
        <DatePickerStyle className={isWeek ? 'isWeek' : ''}>
            <DatePicker
                open={dateRange && !infinite ? isOpened : undefined}
                startDate={startDate}
                endDate={endDate}
                locale={locale}
                selected={selected}
                {...datepickerProps}
                onChange={onDateChanged}
                customInput={dateRange && !infinite ? <RangeDateCustomTextInput /> : <OneDateCustomTextInput />}
                dateFormat="MM/yyyy"
            />
        </DatePickerStyle>
    );
}
