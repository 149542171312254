import styled from 'styled-components';

import { variables } from 'theme/variables';

export const LoginStyle = styled.div({
    '.forget-password-button': {
        display: 'inline-flex',
        marginRight: '0.5rem',
    },
    '.forget-password': {
        color: variables.colours.primary,
        marginTop: '0.313rem',
    },
    '.continue': {
        color: variables.colours.light,
    },
    '.or': {
        color: variables.colours.primary,
    },
    '.resend-confirmation': {
        color: variables.colours.primary,
    },
    '.MuiButton-outlinedPrimary': {
        ':hover': {
            '.continue': {
                color: variables.colours.primary,
            },
        },
    },
    '.MuiInputBase-input': {
        paddingBottom: '0.625rem',
    },
});
