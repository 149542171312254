import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import ConfirmButton from '../ConfirmButton';
import { store } from '../../../store';
import { logOut } from '../../../api/auth';
import { removeUser } from '../../../store/user/actions';
import { deleteNextPath, removeTokens } from '../../../store/auth/actions';
import allRoutes from '../../../router';
import { useTranslation } from 'react-i18next';

export type ConfirmButtonProps = {
    button: React.ReactElement;
};

export default function LogoutButton(props: ConfirmButtonProps): JSX.Element {
    const { button } = props;

    const auth = useSelector((state: RootState) => state.authReducer.auth);
    const { dispatch } = store;

    const logout = async () => {
        try {
            await logOut({
                accessToken: auth.accessToken || '',
                refreshToken: auth.refreshToken || '',
            });
        } finally {
            dispatch(removeTokens());
            dispatch(removeUser());
            dispatch(deleteNextPath());
            setTimeout(() => (location.href = allRoutes.signin.path), 0);
        }
    };
    const { t } = useTranslation();

    return (
        <ConfirmButton
            button={button}
            onClick={logout}
            title={t('navigation.logout')}
            content={t('navigation.logout-text')}
            data-id="log-out"
        />
    );
}
