import { Box } from '@material-ui/core';
import * as React from 'react';

export type OrderInformationProps = {
    children: React.ReactNode;
};

export default function OrderInformation(props: OrderInformationProps): JSX.Element {
    const { children } = props;

    return (
        <Box width="100%" justifyContent="space-between" alignItems="center" display="flex" marginTop="0.2rem">
            {children}
        </Box>
    );
}
