import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ListItemText, MenuItem, MenuProps } from '@material-ui/core';

import FlashCheckbox from 'components/atoms/FlashCheckbox';
import BaseFilterSelect from 'components/atoms/gridFilters/BaseFilterSelect';

import { getMediaSpaces } from 'api/media-space';
import { MediaSpaceVm } from 'models/media-space.model';
import useSelector from '../../../../hooks/useAppSelector';

export type MediaSpacesMultiselect = {
    displayField: 'name' | 'address';
    value?: Array<string>;
    onSelectionChanged: (selectedMediaSpaces: Array<string>, isAllSelected: boolean) => void;
};
const menuProps: Partial<MenuProps> = {
    style: {
        maxHeight: '30.313rem',
    },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
};

const MediaSpacesMultiselect = forwardRef((props: MediaSpacesMultiselect, ref) => {
    const { onSelectionChanged: onStatusChanged, displayField, value } = props;
    const [selectedMediaSpacesIds, setSelectedMediaSpacesIds] = useState<string[]>([]);

    useImperativeHandle(ref, () => ({
        selectAll(isSelected: boolean): string[] {
            let ids = new Array<string>();
            if (isSelected) {
                ids = mediaSpaces.map((x) => x.id);
            }

            setSelectedMediaSpacesIds(ids);
            return ids;
        },
    }));

    const { t } = useTranslation();
    const user = useSelector((state) => state.userReducer.user);

    const [mediaSpaces, setMediaSpaces] = useState<MediaSpaceVm[]>([]);
    const [isLoadingTriggered, setIsLoadingTriggered] = useState(false);

    useEffect(() => {
        setIsLoadingTriggered(false);
    }, [user.lc]);

    useEffect(() => {
        async function fetchData() {
            const response = await getMediaSpaces();
            setMediaSpaces(response.data);
        }

        if (!isLoadingTriggered) {
            setIsLoadingTriggered(true);
            setTimeout(() => fetchData(), 300);
        }
    }, [isLoadingTriggered]);

    return (
        <BaseFilterSelect
            defaultValue={selectedMediaSpacesIds}
            value={value ? value : selectedMediaSpacesIds}
            multiple
            renderValue={(selected): JSX.Element => {
                const arraySelected = selected as string[];
                const messageData = {
                    count: arraySelected.length,
                };
                const multyselectedValue = <>{t('order-form.button-text.selected-mediaspaces', messageData)}</>;
                if (arraySelected.length > 1) {
                    return multyselectedValue;
                } else {
                    const selectedSpace = mediaSpaces.find((ms) => ms.id === (selected as string[])[0]);
                    if (selectedSpace) {
                        return <> {selectedSpace[displayField]}</>;
                    } else {
                        return multyselectedValue;
                    }
                }
            }}
            label={t('playlists.media-space')}
            onSelectionChanged={(value: string[]) => {
                const isAllSelected = mediaSpaces.length == value.length;
                onStatusChanged(value, isAllSelected);
                setSelectedMediaSpacesIds(value);
            }}
            MenuProps={menuProps}
            name="mediaspace-select"
        >
            {mediaSpaces.map((val) => (
                <MenuItem key={val.id} value={val.id} data-id="mediaspace-item">
                    <FlashCheckbox
                        checked={value ? value.includes(val.id) : selectedMediaSpacesIds.includes(val.id)}
                        name="checkbox"
                    />
                    <ListItemText primary={val[displayField]} />
                </MenuItem>
            ))}
        </BaseFilterSelect>
    );
});

MediaSpacesMultiselect.displayName = 'MediaSpacesMultiselect';
export default MediaSpacesMultiselect;
