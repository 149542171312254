import { FC, ComponentProps, useRef, useState, useEffect, useMemo, ChangeEvent } from 'react';

import moment from 'moment';

import { Grow, Slider, Fade, useMediaQuery } from '@material-ui/core';
import {
    Pause as PauseIcon,
    PlayArrow as PlayArrowIcon,
    Fullscreen as FullscreenIcon,
    FullscreenExit as FullscrenExitIcon,
    VolumeUp as VolumeUpIcon,
    VolumeDown as VolumeDownIcon,
    VolumeMute as VolumeMuteIcon,
    VolumeOff as VolumeOffIcon,
} from '@material-ui/icons';

import useElementSize from 'hooks/useElementSize';

import {
    Controls,
    PlayButton,
    PlayIcon,
    StyledVideoPlayer,
    Video,
    ControlButton,
    ControlIcon,
    Timeline,
    TimeValue,
    VolumeWrapper,
    VolumePopup,
    VolumeMenu,
    VideoWrapper,
    Actions,
    SmallVolume,
    Loader,
} from 'components/atoms/VideoPlayer/style';

interface VideoPlayerProps extends ComponentProps<'video'> {
    src?: string;
    preview?: boolean;
    height?: string | undefined;
}

const VideoPlayer: FC<VideoPlayerProps> = ({ autoPlay, controls, preview = false, ...props }) => {
    const isMobile = useMediaQuery('(max-width: 720px)');

    const wrapperRef = useRef<HTMLDivElement | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
    const controlsTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const volumeTimerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const [{ width }] = useElementSize(wrapperRef);
    const isSmall = width <= 300;

    const [isPlaying, setIsPlaying] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [sliderTime, setSliderTime] = useState(0);
    const [volume, setVolume] = useState(50);
    const [volumeActive, setVolumeActive] = useState(true);
    const [volumeOpen, setVolumeOpen] = useState(false);
    const [showControls, setShowControls] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const onVideoLoad = () => {
        setIsLoading(false);

        if (autoPlay) videoRef.current?.play();
    };

    const play = () => setIsPlaying(!preview);
    const togglePlay = () => {
        if (preview) {
            setIsPlaying(false);
        } else {
            setIsPlaying((prev) => !prev);
        }
    };

    const toggleFullscreen = () => setIsFullscreen((prev) => !prev);

    const toggleVolume = () => {
        setVolumeActive((prev) => {
            if (!volume && !prev) setVolume(50);
            return !prev;
        });
    };

    const openVolume = () => {
        if (volumeTimerRef.current) {
            clearTimeout(volumeTimerRef.current);
            volumeTimerRef.current = null;
        }

        setVolumeOpen(true);
    };
    const closeVolume = () => {
        volumeTimerRef.current = setTimeout(() => setVolumeOpen(false), 1000);
    };

    const changeTime = (value: number) => {
        if (videoRef.current) {
            videoRef.current.currentTime = value;
            setSliderTime(value);
        }
    };

    // eslint-disable-next-line @typescript-eslint/ban-types
    const handleChangeVolume = (e: ChangeEvent<{}>, value: number | number[]): void => {
        setVolumeActive(true);
        setVolume(Array.isArray(value) ? value[0] : value);
    };

    const handleShowControls = () => {
        if (controlsTimerRef.current) {
            clearTimeout(controlsTimerRef.current);
            controlsTimerRef.current = null;
        }

        setShowControls(true);

        controlsTimerRef.current = setTimeout(() => setShowControls(false), 3000);
    };

    const handleHideControls = () => {
        if (controlsTimerRef.current) {
            clearTimeout(controlsTimerRef.current);
            controlsTimerRef.current = null;
        }

        setShowControls(false);
    };

    const volumeValue = useMemo(() => (volumeActive ? volume : 0), [volume, volumeActive]);
    const volumeIcon = useMemo(() => {
        if (volumeValue === 0) return VolumeOffIcon;
        if (volumeValue <= 25) return VolumeMuteIcon;
        if (volumeValue <= 75) return VolumeDownIcon;
        return VolumeUpIcon;
    }, [volumeValue]);

    const timeValue = useMemo(() => {
        const timeString = moment().startOf('hour').set({ seconds: sliderTime }).format('m:ss');

        return timeString;
    }, [sliderTime]);

    useEffect(() => {
        if (videoRef.current && wrapperRef.current) {
            const handleVideoPlay = () => setIsPlaying(true);
            const handleVideoPause = () => setIsPlaying(false);
            const handleFullscreenChange = () => {
                const fullscreenValue = Boolean(document.fullscreenElement);

                setIsFullscreen(fullscreenValue);
            };

            videoRef.current.addEventListener('playing', handleVideoPlay);
            videoRef.current.addEventListener('pause', handleVideoPause);
            wrapperRef.current.addEventListener('fullscreenchange', handleFullscreenChange);

            return () => {
                videoRef.current?.removeEventListener('playing', handleVideoPlay);
                videoRef.current?.removeEventListener('pause', handleVideoPause);
                wrapperRef.current?.removeEventListener('fullscreenchange', handleFullscreenChange);
            };
        }
    }, []);

    useEffect(() => {
        if (isPlaying) {
            videoRef.current?.play();
            if (videoRef.current) {
                intervalRef.current = setInterval(() => setSliderTime(videoRef.current?.currentTime ?? 0), 10);
            }
        } else {
            videoRef.current?.pause();

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        }
    }, [isPlaying]);

    useEffect(() => {
        if (isFullscreen) {
            wrapperRef.current?.requestFullscreen();
        } else if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    }, [isFullscreen]);

    useEffect(() => {
        if (videoRef.current) videoRef.current.volume = volumeValue / 100;
        setVolumeActive(volumeValue > 0);
    }, [volumeValue]);

    return (
        <StyledVideoPlayer
            ref={wrapperRef}
            onMouseMove={handleShowControls}
            onMouseLeave={handleHideControls}
            $showControls={showControls}
            height={props.height ?? 'auto'}
        >
            <VideoWrapper>
                <Video {...props} ref={videoRef} onClick={togglePlay} onLoadedData={onVideoLoad} />
                <Fade in={!isPlaying}>
                    <PlayButton onClick={play}>
                        <PlayIcon />
                    </PlayButton>
                </Fade>
                <Fade in={isPlaying && isLoading}>
                    <Loader />
                </Fade>
            </VideoWrapper>
            {controls && (
                <Fade in={isSmall ? showControls && isPlaying : showControls || isMobile}>
                    <Controls $small={isSmall}>
                        <ControlButton onClick={togglePlay}>
                            <ControlIcon as={isPlaying ? PauseIcon : PlayArrowIcon} />
                        </ControlButton>
                        <Timeline
                            onChange={(e, value) => changeTime(Array.isArray(value) ? value[0] : value)}
                            valueLabelFormat={(value) =>
                                moment().startOf('hour').set({ seconds: value }).format('m:ss')
                            }
                            step={0.1}
                            value={sliderTime}
                            valueLabelDisplay="auto"
                            max={videoRef.current?.duration ?? 0}
                        />
                        <TimeValue>{timeValue}</TimeValue>
                        <Actions>
                            <VolumeWrapper onMouseEnter={openVolume} onMouseLeave={closeVolume}>
                                {isSmall && (
                                    <Fade in={volumeOpen}>
                                        <SmallVolume
                                            valueLabelFormat={(value) => `${value}%`}
                                            value={volumeValue}
                                            onChange={handleChangeVolume}
                                        />
                                    </Fade>
                                )}
                                <ControlButton onClick={toggleVolume}>
                                    <ControlIcon as={volumeIcon} />
                                </ControlButton>
                                {!isSmall && (
                                    <Grow in={volumeOpen}>
                                        <VolumePopup>
                                            <VolumeMenu>
                                                <Slider
                                                    valueLabelFormat={(value) => `${value}%`}
                                                    value={volumeValue}
                                                    orientation="vertical"
                                                    max={100}
                                                    valueLabelDisplay="auto"
                                                    onChange={handleChangeVolume}
                                                />
                                            </VolumeMenu>
                                        </VolumePopup>
                                    </Grow>
                                )}
                            </VolumeWrapper>
                            <ControlButton onClick={toggleFullscreen}>
                                <ControlIcon as={isFullscreen ? FullscrenExitIcon : FullscreenIcon} />
                            </ControlButton>
                        </Actions>
                    </Controls>
                </Fade>
            )}
        </StyledVideoPlayer>
    );
};

export default VideoPlayer;
