import { ButtonBase, ButtonBaseProps, Drawer, lighten } from '@material-ui/core';
import { Link, LinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const StyledDrawer = styled(Drawer)(({}) => ({
    '.MuiPaper-root': {
        width: '18.75rem',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: `${variables.colours.primary} transparent`,
        scrollbarFaceColor: variables.colours.primary,
        scrollbarShadowColor: 'transparent',

        '&::-ms-expand': {
            display: 'none',
        },

        '&::-webkit-scrollbar': {
            width: '6px',
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: variables.colours.primary,
            borderRadius: '30px',
        },

        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },

        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '16.25rem',
        },
    },
    '.cancel-button': {
        width: '36px',
        color: variables.colours.main,
        marginRight: '1.875rem',
        '.MuiSvgIcon-root': {
            fontSize: '1.875rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            marginRight: '1.438rem',
        },
    },
    '.avatar': {
        marginLeft: '1.125rem',
    },
    '.avatar-name': {
        color: variables.colours.light,
        '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
            fontSize: '1rem',
        },
    },
    '.user-name': {
        marginLeft: '0.625rem',
        color: variables.colours.light,
        fontWeight: 'bold',
        '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
            fontSize: '1rem',
        },
    },
    '.MuiDivider-root': {
        backgroundColor: variables.colours.main,
    },
    '.log-out': {
        marginTop: '1.875rem',
        marginLeft: '0.813rem',
        padding: 0,
        h6: {
            color: variables.colours.main,
            '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
                fontSize: '1rem',
            },
        },
    },
    '.order-menu-item': {
        marginTop: '1.813rem',
        marginLeft: '1.553rem',
        padding: 0,
        h6: {
            color: variables.colours.main,
            ':first-letter': {
                textTransform: 'uppercase',
            },
            '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
                fontSize: '1rem',
            },
        },
    },
    '.chat-button': {
        p: {
            '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
                fontSize: '1rem',
            },
        },
        '@media only screen and (min-width: 320px) and (max-width: 1024px)': {
            p: {
                marginTop: '1.813rem',
                marginLeft: '1.553rem',
                fontWeight: 'normal',
                padding: 0,
                color: variables.colours.main,
            },
        },
        '&:hover': {
            textDecoration: 'none',
        },
    },
}));

export const CreateOrderLink = styled(ButtonBase).attrs({
    component: Link,
})<ButtonBaseProps & LinkProps>({
    padding: '10px 20px',
    borderRadius: 8,
    backgroundColor: variables.colours.primary,
    transition: '.2s ease',
    display: 'inline-flex',
    margin: '20px',
    h5: {
        color: 'white',
    },
    ':hover': {
        backgroundColor: lighten(variables.colours.primary, 0.15),
    },
    '@media only screen and (min-width: 768px)': {
        display: 'none',
    },
});

export const StyledMenuButton = styled.div(({}) => ({
    display: 'block',
    width: '100%',
    height: '3.75rem',
    ':hover': {
        transition: 'background-color 1s',
        backgroundColor: variables.colours.dark,
        borderLeft: `5px solid ${variables.colours.primary}`,
    },
}));
