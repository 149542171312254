import { DeviceVideoFileModel } from 'models/video';
import { MediaSpaceVm } from 'models/media-space.model';
import { DevicePlaylistFileModel } from 'models/playlist.model';
import { PagingVmBaseResponse } from './paging/paging.model';

export interface DeviceVm {
    name: string;
    lastConnectionDate: Date;
    createdDate: Date;
    status: string;
    macAddress: string;
    ipAddress: string;
    operationSystemName: string;
    osBuild: string;
    mediaSpace: MediaSpaceVm;
    availableMemory: number;
    usedMemory: number;
    applicationVersion: string;
    volumeLevel: number;
    maxVolumeLevel: number;
    cpuTemp: number;
    storageType: StorageType;
}

export enum StorageType {
    Internal,
    External,
}

export type DeviceBaseVm = DeviceVm & {
    id: string;
};

export type DeviceShortVm = {
    id: string;
};

export interface UpdateDeviceNameData {
    name: string;
    mediaSpaceId: string | null;
}

export interface UpdateDeviceVolumeData {
    volumeLevel: DeviceVm['volumeLevel'];
}

export type PagedDeviceVmResponse = PagingVmBaseResponse<DeviceBaseVm>;
export type PagedDeviceFilesVmResponse = PagingVmBaseResponse<DeviceFile>;

export const DefaultDeviceInput: DeviceVm = {
    name: '',
    lastConnectionDate: new Date(),
    createdDate: new Date(),
    status: '',
    macAddress: '',
    ipAddress: '',
    operationSystemName: '',
    osBuild: '',
    mediaSpace: {} as MediaSpaceVm,
    availableMemory: 0,
    usedMemory: 0,
    applicationVersion: '',
    volumeLevel: 5,
    maxVolumeLevel: 10,
    cpuTemp: 36.6,
    storageType: StorageType.External,
};

export type DevicePage = {
    mediaSpaceIds: string[];
    page: number;
    pageSize: number;
    property?: string;
    direction?: number | null;
    filterString?: string | null;
    status: number | null;
    filterRule?: number | null;
};

export type DeviceProfilePage = {
    page: number;
    pageSize: number;
    sortProperty?: string;
    sortDirection?: number | null;
};

export type DeviceScreenshotVm = {
    screenshotBase64: string;
    connectionId: string;
    file: {
        video: {
            name: string;
            order: {
                number: number;
            };
        };
        playlistFile: {
            name: string;
            playlist: {
                name: string;
            };
        };
    };
};

export type DeviceFile = {
    id: string;
    size: number;
    downloadDate: Date;
    playlistFile: DevicePlaylistFileModel;
    video: DeviceVideoFileModel;
};
