import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const StyledPlaylisGrid = styled.div(({}) => ({
    '.MuiDataGrid-cell': {
        ':first-child': {
            paddingLeft: '0.719rem',
            justifyContent: 'start',
        },
        ':nth-child(2)': {
            a: {
                justifyContent: 'start',
            },
            p: {
                color: variables.colours.main,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
        },
        ':nth-child(3)': {
            a: {
                justifyContent: 'start',
            },
        },
        ':nth-child(4)': {
            a: {
                justifyContent: 'start',
            },
            '.infinity': {
                color: variables.colours.main,
                fontSize: '1.5rem',
                '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                    fontSize: '1.25rem',
                },
                '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                    fontSize: '0.938rem',
                },
            },
        },
        ':nth-child(5)': {
            a: {
                color: variables.colours.dark,
                justifyContent: 'start',
            },
            textTransform: 'uppercase',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    },
}));
