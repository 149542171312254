import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core';

import { StyledStatusChip } from 'components/atoms/controls/DeviceStatusChip/style';
import { DeviceStatusEnum } from 'components/atoms/gridFilters/DeviceStatusSelect';

export interface DeviceStatusProps {
    status: string;
}

export default function DeviceStatusChip(props: DeviceStatusProps): JSX.Element {
    const { status } = props;
    const [state, setState] = useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setState(status);
    }, [status]);

    return (
        <StyledStatusChip
            size="small"
            className={DeviceStatusEnum[+state]}
            label={
                <Typography variant="subtitle1" className="status">
                    {t(`device-status.${DeviceStatusEnum[+state]}`)}
                </Typography>
            }
        />
    );
}
