import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';

import * as serviceWorker from './serviceWorker';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from 'App';
import axios from 'api/axios';
import { getConfig } from 'config';
import { store, persistor } from 'store';
import { setDefaultLocale } from 'i18n/config';

import 'index.css';
import 'assets/fonts/MagistralCTT.ttf';
import AuthProvider from 'providers/AuthProvider';

import TagManager from 'react-gtm-module';

import { setUpInterceptor } from './api/axios';

declare global {
    interface Config {
        backendUrl: string;
        frontendUrl: string;
        landingUrl: string;
        id: string;
        mapsApiKey: string;
        isSentryEnabled: boolean;
        sentryDsn: string;
        sentryTracesSampleRate: number;
        sentryEnvironment: string;
        defaultLocale: string;
        defaultCurrency: string;
        contactsAddressRu: string;
        contactsAddressEn: string;
        contactsPhone: string;
        contactsEmail: string;
        contactsLatitude: number;
        contactsLongitude: number;
        contactsCompanyRu: string;
        contactsCompanyEn: string;
        supportTeamPhone: string;
        whatsAppLink: string;
        googleTagManagerId: string;
        facebookIsAvailable: boolean;
        fictitiousMode: boolean;
        detectBrowserLanguage: boolean;
        adminStartDateRangeInHours: number;
        userStartDateRangeInDays: number;
        userEndDateRangeInMonths: number;
        limitMonthsForStartDate: number;
        limitMonthsForEndDate: number;
    }
    interface Env {
        config: Config;
    }

    interface Window {
        ENV: Env;
    }
}

async function init() {
    try {
        const config = await getConfig();
        axios.defaults.baseURL = `${config.backendUrl}/api`;
        await setDefaultLocale();

        if (config.googleTagManagerId) {
            const tagManagerArgs = {
                gtmId: config.googleTagManagerId,
            };
            TagManager.initialize(tagManagerArgs);
        }

        if (config.isSentryEnabled) {
            Sentry.init({
                dsn: config.sentryDsn,
                integrations: [new Integrations.BrowserTracing()],
                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: config.sentryTracesSampleRate,
                environment: config.sentryEnvironment,
            });
        }
    } catch (err) {
        console.log(err);
    } finally {
        ReactDOM.render(
            <React.StrictMode>
                <Provider store={store}>
                    <BrowserRouter
                        getUserConfirmation={(message, callback) => {
                            // this is the default behavior
                            const allowTransition = window.confirm(message);
                            callback(allowTransition);
                        }}
                    >
                        <PersistGate persistor={persistor}>
                            <AuthProvider>
                                <App />
                            </AuthProvider>
                        </PersistGate>
                    </BrowserRouter>
                </Provider>
            </React.StrictMode>,
            document.getElementById('root'),
        );
        setUpInterceptor(store);
    }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

init().then(() => {
    console.log('Application start');
});
