import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Menu, MenuItem, Typography } from '@material-ui/core';

import MenuItemLink from 'components/atoms/MenuItemLink';
import LogoutButton from 'components/molecules/LogoutButton';

import allRoutes from 'router';
import FormChangeLanguage from 'components/organisms/forms/FormChangeLanguage';
import FormChangeCurrency from 'components/organisms/forms/FormChangeCurrency';
import { currenciesAPI } from '../../../../api/currency';
import { getLanguages } from '../../../../api/language';

export type FlashMenuProps = {
    id: string;
    anchorEl: HTMLElement | null;
    handleMenuClose: () => void;
};

const FlashMenu: FC<FlashMenuProps> = ({ id, anchorEl, handleMenuClose }) => {
    const isMenuOpen = Boolean(anchorEl);
    const { t } = useTranslation();

    const [thereAreCurrencies, setThereAreCurrencies] = useState<boolean>(false);
    const [thereAreLanguages, setThereAreLanguages] = useState<boolean>(false);

    useEffect(() => {
        async function fetchData() {
            const response = await currenciesAPI.getCurrencies();
            const languagesResponse = await getLanguages();
            setThereAreLanguages(languagesResponse.data.length > 1);
            setThereAreCurrencies(response.data.length > 1);
        }
        fetchData();
    }, []);

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={id}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            onBlur={handleMenuClose}
        >
            <div>
                <MenuItemLink to={allRoutes.currentUserProfile.path} onClick={handleMenuClose} data-id="user-profile">
                    <Typography variant="body1">{t('navigation.profile')}</Typography>
                </MenuItemLink>
            </div>
            {thereAreLanguages && (
                <FormChangeLanguage
                    title={t('navigation.language-title')}
                    button={
                        <MenuItem data-id="change-language">
                            <Typography variant="body1">{t('navigation.language')}</Typography>
                        </MenuItem>
                    }
                />
            )}
            {thereAreCurrencies && (
                <FormChangeCurrency
                    title={t('navigation.currency-title')}
                    onClick={() => null}
                    button={
                        <MenuItem data-id="change-language">
                            <Typography variant="body1">{t('navigation.currency')}</Typography>
                        </MenuItem>
                    }
                />
            )}
            <LogoutButton
                button={
                    <MenuItem data-id="log-out">
                        <Typography variant="body1">{t('navigation.logout')}</Typography>
                    </MenuItem>
                }
            />
        </Menu>
    );
};

export default FlashMenu;
