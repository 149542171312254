import { AxiosResponse } from 'axios';
import jwt_decode from 'jwt-decode';
import { AccessToken } from '../interface/accessToken';
import { LoginModel } from '../models/login';
import { RegistrationModel } from '../models/registration';
import { TokensPairModel } from '../models/tokens-pair';
import axios from './axios';
import { store } from '../store';
import { removeTokens, updateAccess } from '../store/auth/actions';
import { removeUser, updateUser } from '../store/user/actions';
import { ForgetPasswordModel } from '../models/forget-password-model';
import { CompletePasswordRestoreModel } from '../models/complete-password-restore';
import i18n from 'i18n/config';
import { Languages } from '../shared/enums';

const { dispatch } = store;

export const logIn = (data: LoginModel): Promise<AxiosResponse<TokensPairModel>> => {
    return axios.post(`session`, data);
};

export const logOut = (data: TokensPairModel): Promise<AxiosResponse<boolean>> => {
    return axios.post(`session/logout`, data);
};

export const signUp = (data: RegistrationModel): Promise<AxiosResponse<TokensPairModel>> => {
    return axios.post(`account`, data);
};

export const refresh = async (data: TokensPairModel): Promise<AxiosResponse<TokensPairModel>> => {
    try {
        const response = await axios.post<TokensPairModel>(`session/refresh`, data);
        return response;
    } catch (e) {
        dispatch(removeTokens());
        dispatch(removeUser());
        throw e;
    }
};

export const passwordRecovery = (data: ForgetPasswordModel): Promise<AxiosResponse<boolean>> => {
    return axios.post(`recovery`, data);
};

export const completePasswordRestore = (data: CompletePasswordRestoreModel): Promise<AxiosResponse<boolean>> => {
    return axios.post(`recovery/change`, data);
};

export const updateUserFromAccessToken = (access: string | null): void => {
    if (access) {
        const decodeAT = jwt_decode(access) as AccessToken;
        // TODO: add currency, locale here
        const {
            nameid,
            email,
            role,
            given_name,
            family_name,
            pic,
            exp,
            hasSignedInOnce,
            lc,
            preferred_currency_id,
        } = decodeAT;

        dispatch(
            updateAccess({
                accessToken: access,
                accessTokenTTL: exp,
            }),
        );

        const hasUserEverSignedIn = hasSignedInOnce.toLowerCase() === 'true';

        dispatch(
            updateUser({
                email,
                nameid,
                role,
                given_name,
                family_name,
                pic,
                lc,
                hasSignedInOnce: hasUserEverSignedIn,
                preferred_currency_id,
            }),
        );

        i18n.changeLanguage(lc || Languages.English);
        localStorage.setItem('locale', lc);
    }
};

export default {
    logIn,
    signUp,
    refresh,
    logOut,
    passwordRecovery,
    completePasswordRestore,
};
