import { variables } from '../../../theme/variables';
import styled from 'styled-components';

export const Order = styled.div(({}) => ({
    position: 'absolute',
    zIndex: -1,
    width: '75%',
    bottom: '0.625rem',
    opacity: 0.3,
    img: {
        width: '100%',
    },
}));

export const OrderNumber = styled.div(({}) => ({
    margin: '1.25rem 0 1.25rem 0',
    '.order-title': {
        padding: 0,
    },
}));

export const OrderCost = styled.div(({}) => ({
    width: '58%',
    height: '4.563rem',
    background: `linear-gradient(181.64deg,  ${variables.backgoroundColours.primary} 1.39%, rgba(241, 67, 54, 0.53) 123.52%)`,
    borderRadius: '0.25rem',
    '.order-cost': {
        paddingTop: '0.338rem',
        fontWeight: 'bold',
        color: variables.colours.light,
        paddingLeft: '1.8rem',
        '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
            paddingTop: '0.538rem',
        },
    },
    '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
        height: '3.963rem',
    },
}));

export const ScheduleTable = styled.table(({}) => ({
    border: `2px solid ${variables.colours.default}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 8px 3px rgba(0, 0, 0, 0.05)',
    marginBottom: '0.175rem',
    width: '100%',
    borderCollapse: 'inherit',
    tr: {
        textAlign: 'left',
    },
    tbody: {
        td: {
            verticalAlign: 'top',
            padding: '0.625rem 1.75rem 1.25rem  1.75rem',
            ':first-child': {
                padding: '0.625rem 0 1.25rem  1.75rem',
            },
            '.bold': {
                fontWeight: 'bold',
            },
            '.normal': {
                textTransform: 'uppercase',
            },
        },
    },
    th: {
        '&.table-title': {
            width: '15%',
        },
        '&.order-status': {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        padding: '0 1.875rem',
    },
    '.scrollable': {
        overflowY: 'scroll',
        maxHeight: '9.063rem',
        '::-webkit-scrollbar': {
            width: '5px',
            paddingRight: '10px',
        },
        '::-webkit-scrollbar-thumb': {
            background: variables.colours.primary,
            borderRadius: '29px',
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: variables.backgoroundColours.primary,
        },
        '::-webkit-scrollbar-track': {
            borderRadius: '0px',
        },
        div: {
            marginBottom: '0.625rem',
        },
    },
    '.order-cost': {
        verticalAlign: 'bottom',
    },
    '.chat-button-cell': {
        textAlign: 'right',
    },
    '.chat-button': {
        margin: '0 2.2rem 0 0',
        minHeight: '40px',

        h6: {
            textTransform: 'uppercase',
            marginLeft: '0.938rem',
            color: variables.colours.primary,
        },
    },
}));

export const TableHolder = styled.div((props: { maxWidth: number }) => ({
    maxWidth: props.maxWidth,
    'overflow-x': 'auto',
    'overflow-y': 'hidden',
    '::-webkit-scrollbar': {
        height: '5px',
    },
    '::-webkit-scrollbar-thumb': {
        background: variables.colours.main,
        borderRadius: '29px',
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: variables.backgoroundColours.primary,
    },
    '::-webkit-scrollbar-track': {
        borderRadius: '0px',
    },
}));

export const ExternalHolder = styled.div((props: { maxWidth: number }) => ({
    'overflow-x': 'hidden',
    width: '100%',
    maxWidth: props.maxWidth,
    margin: '1.875rem 0 1.875rem 0',
    '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
        margin: '0 0 1.875rem 0',
    },
}));
