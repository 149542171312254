import { FC } from 'react';

import StyledSpacer from 'components/atoms/Spacer/style';

export interface SpacerProps {
    width?: string | number;
    height?: string | number;
    grow?: number;
    shrink?: number;
}

const Spacer: FC<SpacerProps> = (props) => <StyledSpacer className="spacer" {...props} />;

export default Spacer;
