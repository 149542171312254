import { DeviceStatusEnum } from 'components/atoms/gridFilters/DeviceStatusSelect';

export const REMOVE_FILTER = `REMOVE_FILTER`;
export const UPDATE_DEVICE_FILTER = `UPDATE_PRICE_FILTER`;

export interface Device {
    filterString: string;
    selectedMediaSpaces: string[];
    status: DeviceStatusEnum | null;
}

export interface FilterState {
    device: Device;
}

interface UpdateFilterAction {
    type: typeof UPDATE_DEVICE_FILTER;
    data: Device;
}
interface RemoveFilterAction {
    type: typeof REMOVE_FILTER;
}

export type FilterActionTypes = UpdateFilterAction | RemoveFilterAction | UpdateFilterAction; // | other actions
