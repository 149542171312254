import { Container } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import CompletePasswordRestoreForm from '../../organisms/forms/CompletePasswordRestore';

export default function CompletePasswordRestoreTemplate(): JSX.Element {
    return (
        <Page>
            <Container maxWidth="sm">
                <CompletePasswordRestoreForm />
            </Container>
        </Page>
    );
}

const Page = styled.div`
    width: 100%;
    max-width: 77.5rem;
    padding: 0 1.25rem;
    margin: 0 auto;
`;
