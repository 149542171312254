import { FC, useEffect, useRef, useState, ChangeEvent, RefCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import { cloneDeep, omit } from 'lodash';
import { toast } from 'react-toastify';

import {
    Box,
    FormControlLabel,
    Switch,
    Backdrop,
    Fade,
    ListSubheader,
    InputAdornment,
    Typography,
    ListItem,
    ListItemText,
    CircularProgress,
} from '@material-ui/core';
import { AddBoxRounded as AddBoxRoundedIcon, Visibility as VisibilityIcon } from '@material-ui/icons';

import FormHistoryBlocker from 'components/organisms/forms/FormHistoryBlocker';
import { MediaSpaceChangeData } from 'components/molecules/forms/MediaSpacesMultiSelect';
import FormControlTextInput from 'components/molecules/forms/FormControlTextInput';
import OrderTableOfContents from 'components/molecules/forms/OrderTableOfContents';
import ProfileActions from 'components/molecules/ProfileActions';
import SubmitButton from 'components/atoms/controls/SubmitButton';
import CancelButton from 'components/atoms/controls/CancelButton';
import TransferList from 'components/atoms/TransferList';
import FlashDatePicker from 'components/atoms/FlashDatePicker';
import FlashContainer from 'components/atoms/FlashContainer';
import CalendarIcon from 'components/atoms/Icon/CalendarIcon';
import LoadingIndicator from 'components/atoms/LoadingIndicator';

import { playlistsAPI } from 'api/playlists';
import allRoutes from 'router';

import useWindowDimensions from 'hooks/WindowDimensions';

import { IBaseFormRef } from 'models/interface/i-form-data';
import { DefaultPlaylistInput, LocalPlaylistFileModel, PlaylistModel } from 'models/playlist.model';

import {
    Calendar,
    Duration,
    PlaylistListStyled,
    UploadInput,
    PlaylistItemStyled,
    Quality,
    ModalStyled,
    VideoViewStyled,
    ImageViewStyled,
    OptionButtonHolder,
    TableHolder,
    ExternalHolder,
    LoaderWrapper,
    ActionButton,
    ActionButtonIcon,
    ListNumber,
} from 'components/organisms/forms/Playlist/style';

import VideoIcon from 'components/atoms/Icon/VideoIcon';
import SpaceIcon from 'components/atoms/Icon/SpaceIcon';
import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteButton.svg';
import { HeaderTypography } from '../../../atoms/HeaderTypography';

type ValidationResult = {
    isNameValid: false;
    isFilesValid: false;
    isMediaSpacesValid: false;
    isDateValid: false;
};

export type PlaylistFromProps = {
    event: PlaylistModel;
    id?: string;
};

const reorder = (list: LocalPlaylistFileModel[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const PlaylistCreateEditForm: FC<PlaylistFromProps> = ({ event, id }) => {
    const { t } = useTranslation();

    const listRef = useRef<HTMLElement | null>(null);

    const history = useHistory();
    const location = history.location;
    const locations = useLocation().state;

    const [originalData, setOriginalData] = useState(DefaultPlaylistInput);
    const [formModel, setFormModel] = useState(DefaultPlaylistInput);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [disabled, setDisabled] = useState(id !== undefined);
    const [fileUrl, setFileUrl] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [contentType, setContentType] = useState<string>();
    const [isDisabled, setIsDisabled] = useState(false);
    const [isSubmiting, setIsSubmiting] = useState(false);

    const supportedMediaTypes = 'video/mp4,image/jpeg,image/png';
    const supportedVideoMediaTypes = '.mp4';
    const supportedImageMediaTypes = 'image/jpeg,image/png';

    const [isValidated, setIsValidated] = useState(false);
    const [formValidation, setFormValidation] = useState<ValidationResult>({
        isNameValid: false,
        isFilesValid: false,
        isMediaSpacesValid: false,
        isDateValid: false,
    });
    const { width } = useWindowDimensions();

    const handleValidation = (): boolean => {
        setIsValidated(true);

        const validationResult = {
            isNameValid: formModel.name !== '',
            isFilesValid: formModel.playlistFiles?.length > 0,
            isMediaSpacesValid:
                (formModel.isForAllMediaSpaces && formModel.mediaSpaceIds?.length === 0) ||
                formModel.mediaSpaceIds?.length > 0,
            isDateValid: formModel.startDate !== undefined,
        } as ValidationResult;

        if (!validationResult.isNameValid) {
            toast.error(t('playlist-form.messages.error-empty-name'));
        }

        if (!validationResult.isFilesValid) {
            toast.error(t('playlist-form.messages.error-files'));
        }

        if (!validationResult.isMediaSpacesValid) {
            toast.error(t('playlist-form.messages.error-empty-media-space'));
        }

        if (!validationResult.isDateValid) {
            toast.error(t('playlist-form.messages.error-empty-date'));
        }

        setFormValidation(validationResult);

        let isValid = true;
        Object.keys(validationResult).map(
            (key) => (isValid = isValid && validationResult[key as keyof ValidationResult]),
        );

        return isValid;
    };

    function onDragEnd(e: DropResult) {
        // dropped outside the list
        if (!e.destination) {
            return;
        }

        const items = reorder(formModel.playlistFiles, e.source.index, e.destination.index);

        setFormModel({
            ...formModel,
            playlistFiles: items,
        });
    }

    useEffect(() => {
        setIsDisabled(isSubmiting || disabled);
    }, [isSubmiting, disabled]);

    useEffect(() => {
        if (location.pathname === `/playlists/edit/${id}`) {
            setDisabled(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        const clone = cloneDeep(event);
        if (clone) {
            setStartDate(clone.startDate || null);
            setEndDate(clone.endDate || null);
            setFormModel(clone);
            setOriginalData(clone);
        }
    }, [event]);

    const handleDateRange = (date: Date | [Date, Date] | null) => {
        if (date instanceof Date) {
            setStartDate(date);
            setEndDate(null);

            const scheduleStartDate = convertToUtc(date);

            setFormModel({
                ...formModel,
                startDate: scheduleStartDate,
                endDate: undefined,
            });
        } else if (date instanceof Array) {
            const [start, end] = date;
            setStartDate(start);
            setEndDate(end);

            if (start !== null && end !== null) {
                const scheduleStartDate = convertToUtc(start);
                const scheduleEndDate = convertToUtc(end);

                setFormModel({
                    ...formModel,
                    startDate: scheduleStartDate,
                    endDate: scheduleEndDate,
                });
            }
        }
    };

    function convertToUtc(date: Date) {
        const hoursDiff = date.getHours() - date.getTimezoneOffset() / 60;
        const minutesDiff = (date.getHours() - date.getTimezoneOffset()) % 60;
        date.setHours(hoursDiff);
        date.setMinutes(minutesDiff);
        return date;
    }

    const sendEvent = async () => {
        const isValid = handleValidation();

        if (isValid) {
            if (formModel.playlistFiles.length > 0) {
                for (let i = 0; i < formModel.playlistFiles.length; i++) {
                    formModel.playlistFiles[i].indexNumber = i;
                }
            }

            let isSuccess: boolean;
            const model = {
                name: formModel.name,
                startDate: formModel.startDate,
                endDate: formModel.endDate,
                isForAllMediaSpaces: formModel.isForAllMediaSpaces,
                isInfinite: formModel.isInfinite,
                mediaSpaceIds: formModel.mediaSpaceIds,
                playlistFiles: formModel.playlistFiles || [],
            } as PlaylistModel;

            let textToast: string;

            let redirectId = id;

            if (id) {
                const response = await playlistsAPI.update(id, model);
                isSuccess = response.data?.id !== '';
                textToast = 'playlist-form.success-update-playlist';
            } else {
                const response = await playlistsAPI.create(model);
                redirectId = response.data.id;
                isSuccess = response.data?.id !== '';
                textToast = 'playlist-form.success-create-playlist';
            }

            if (isSuccess) {
                toast.success(t(textToast));
                baseRef.current?.release();
                history.replace({
                    pathname: generatePath(allRoutes.playlistProfile.path, { id: redirectId }),
                    state: locations,
                });
                setIsDisabled(true);
            }
        }
    };

    async function handleUpload(e: ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;
        if (!fileList) return;
        const file = fileList[0];

        e.target.value = '';

        const alreadyExists = formModel.playlistFiles.some((el) => {
            const sameName = el.originalName === file.name;
            const sameLastModified = el.lastModified === file.lastModified;

            return sameName && sameLastModified;
        });

        if (alreadyExists) return;

        let duration = 10;

        setTimeout(() => {
            if (listRef.current) {
                listRef.current.scrollTop = listRef.current.scrollHeight;
            }
        }, 100);

        if (file.type === 'video/mp4') {
            const video = document.createElement('video');
            video.preload = 'metadata';

            video.src = URL.createObjectURL(file);

            await new Promise((resolve) => {
                video.onloadedmetadata = function () {
                    window.URL.revokeObjectURL(video.src);
                    if (video.duration) {
                        duration = Math.ceil(video.duration);
                        resolve(duration);
                        setFormModel((prev) => ({
                            ...prev,
                            playlistFiles: [
                                ...prev.playlistFiles,
                                {
                                    indexNumber: prev.playlistFiles.length,
                                    id: `temp-${file.name}-${file.lastModified}`,
                                    width: video.videoWidth,
                                    height: video.videoHeight,
                                    originalName: file.name,
                                    type: file.type,
                                    lastModified: file.lastModified,
                                    isLoading: true,
                                    duration,
                                },
                            ],
                        }));
                    }
                };
            });
        } else if (supportedImageMediaTypes.includes(file.type)) {
            const image = new Image();

            image.src = URL.createObjectURL(file);

            image.onload = function () {
                setFormModel((prev) => ({
                    ...prev,
                    playlistFiles: [
                        ...prev.playlistFiles,
                        {
                            indexNumber: prev.playlistFiles.length,
                            id: 'temp',
                            width: image.width,
                            height: image.height,
                            originalName: file.name,
                            type: file.type,
                            lastModified: file.lastModified,
                            isLoading: true,
                            duration,
                        },
                    ],
                }));
            };
        }

        try {
            const { data: fileData } = await playlistsAPI.uploadFile(file, duration);
            setFormModel((prev) => ({
                ...prev,
                playlistFiles: [
                    ...prev.playlistFiles.map((playlistFile) => {
                        const sameName = playlistFile.originalName === file.name;
                        const sameDateModified = playlistFile.lastModified === file.lastModified;

                        if (sameName && sameDateModified) {
                            return {
                                ...omit(playlistFile, 'isLoading'),
                                ...omit(fileData, 'width', 'height'),
                            };
                        }

                        return playlistFile;
                    }),
                ],
            }));
        } catch {
            setFormModel((prev) => ({
                ...prev,
                playlistFiles: prev.playlistFiles.filter(({ originalName, lastModified }) => {
                    return originalName === file.name && lastModified === file.lastModified;
                }),
            }));
        }
    }

    const handleDeleteFile = (id: string) => {
        const playlistFiles = formModel.playlistFiles.filter((x) => x.id !== id);
        setFormModel({ ...formModel, playlistFiles: playlistFiles });
    };

    const handleDuration = (id: string, value: string) => {
        let val = parseInt(value, 10);

        if (val > 60) val = 60;

        const playlistFiles = formModel.playlistFiles.map((file) => {
            file.duration = file.id === id ? parseInt(val.toString()) : file.duration;
            return file;
        });
        setFormModel({ ...formModel, playlistFiles: playlistFiles });
    };

    const handleMinValue = () => {
        const playlistFiles = formModel.playlistFiles.map((file) => {
            return { ...file, duration: file.duration < 10 ? 10 : file.duration };
        });
        setFormModel({ ...formModel, playlistFiles: playlistFiles });
    };

    async function deletePlaylist() {
        if (id) {
            await playlistsAPI.deleteById(id);
        }
        history.push(generatePath(allRoutes.playlists.path, { id: 1 }));
    }

    const onEdit = () => {
        if (id && disabled)
            return { pathname: generatePath(allRoutes.editPlaylist.path, { id: id }), state: locations };
        return;
    };

    async function handleContent(file: LocalPlaylistFileModel) {
        const loadContent = async () => {
            const metadata = await playlistsAPI.getContentMetadata(file.id);
            setContentType(metadata.data.contentType);
            const src = playlistsAPI.getFileSrc(file.id);
            setFileUrl(src);
            setOpenModal(true);
        };
        loadContent();
    }

    const isVideo = (mediaType: string | undefined): boolean => {
        return !!mediaType && mediaType.indexOf('video/') == 0;
    };

    const baseRef = useRef<IBaseFormRef>();

    return (
        <FlashContainer>
            <FormHistoryBlocker formData={formModel} ref={baseRef} originalData={originalData} />
            <ProfileActions
                hasDeleteButton={!!id}
                deleteTitle={t('playlist-form.delete-playlist')}
                deleteContent={t('playlist-form.delete-playlist-content-text')}
                onDelete={deletePlaylist}
                onEdit={onEdit()}
            />
            <HeaderTypography>{t('playlist-form.header')}</HeaderTypography>
            <PlaylistItemStyled className="view-handler">
                <ModalStyled
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModal}
                    onClose={() => {
                        setOpenModal(false);
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    data-id="modal"
                >
                    <Fade in={openModal}>
                        {isVideo(contentType) ? (
                            <VideoViewStyled controls src={fileUrl} />
                        ) : (
                            <ImageViewStyled src={fileUrl} />
                        )}
                    </Fade>
                </ModalStyled>
            </PlaylistItemStyled>
            <PlaylistItemStyled>
                <FormControlTextInput
                    value={formModel.name}
                    error={isValidated && formValidation.isNameValid}
                    color="secondary"
                    onChangeValue={(value: string) => {
                        setFormModel({ ...formModel, name: value });
                    }}
                    label={t('playlist-form.name')}
                    disabled={disabled}
                    name="name"
                />
            </PlaylistItemStyled>
            <PlaylistItemStyled>
                <OrderTableOfContents titles={t('order-form.video-description')} icon={<VideoIcon />} />
                {formModel.playlistFiles?.length > 0 && (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => {
                                const multipleRefs: RefCallback<HTMLElement> = (element) => {
                                    listRef.current = element;
                                    provided.innerRef(element);
                                };

                                return (
                                    <ExternalHolder maxWidth={width}>
                                        <TableHolder maxWidth={width}>
                                            <Typography variant="subtitle1" className="view-title">
                                                {t('playlist-form.view-title')}
                                            </Typography>
                                            <PlaylistListStyled
                                                dense
                                                role="list"
                                                {...provided.droppableProps}
                                                ref={multipleRefs}
                                                data-id="files-list"
                                            >
                                                <ListSubheader component="div" className="list-header">
                                                    <Typography variant="h6">{t('playlist-form.file')}</Typography>
                                                    <div className="list-handler">
                                                        <Typography variant="h6">
                                                            {t('playlist-form.resolution')}
                                                        </Typography>
                                                        <Typography variant="h6">
                                                            {t('playlist-form.duration')}
                                                        </Typography>
                                                    </div>
                                                </ListSubheader>
                                                {formModel.playlistFiles.map((value, index) => {
                                                    const labelId = `transfer-list-item-${value}-label`;
                                                    return (
                                                        <Draggable
                                                            key={value.id}
                                                            draggableId={value.id}
                                                            index={index}
                                                            isDragDisabled={disabled}
                                                            data-id="draggable"
                                                        >
                                                            {(provided) => (
                                                                <ListItem
                                                                    key={value.id}
                                                                    role="listitem"
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    disabled={disabled}
                                                                    data-id="file-item"
                                                                >
                                                                    <ListNumber>{index + 1}.</ListNumber>
                                                                    {value.isLoading && (
                                                                        <LoaderWrapper>
                                                                            <CircularProgress size={24} />
                                                                        </LoaderWrapper>
                                                                    )}
                                                                    <ListItemText
                                                                        id={labelId}
                                                                        primary={
                                                                            value.name?.substring(37) ??
                                                                            value.originalName
                                                                        }
                                                                        data-id="file-name"
                                                                        className="file-name"
                                                                    />
                                                                    {value.width && value.height && (
                                                                        <Quality>
                                                                            <Typography variant="body1">
                                                                                {value.width}X{value.height}
                                                                            </Typography>
                                                                        </Quality>
                                                                    )}
                                                                    <Duration>
                                                                        <FormControlTextInput
                                                                            type="number"
                                                                            InputProps={{
                                                                                inputProps: { min: 10, max: 60 },
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <Typography variant="body1">
                                                                                            {t('playlist-form.second')}
                                                                                        </Typography>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            value={value.duration.toString()}
                                                                            size="small"
                                                                            disabled={
                                                                                value.type ===
                                                                                    supportedVideoMediaTypes || disabled
                                                                            }
                                                                            onBlur={handleMinValue}
                                                                            onChangeValue={(duration: string) => {
                                                                                handleDuration(value.id, duration);
                                                                            }}
                                                                            name="duration"
                                                                        />
                                                                    </Duration>
                                                                    <ActionButton
                                                                        disabled={value.isLoading}
                                                                        onClick={() => handleContent(value)}
                                                                    >
                                                                        <ActionButtonIcon as={VisibilityIcon} />
                                                                    </ActionButton>
                                                                    {!disabled && (
                                                                        <ActionButton
                                                                            data-id="delete-file"
                                                                            onClick={() => handleDeleteFile(value.id)}
                                                                        >
                                                                            <ActionButtonIcon as={DeleteIcon} />
                                                                        </ActionButton>
                                                                        // <DeleteButton
                                                                        //     width="21"
                                                                        //     height="27.5"
                                                                        //     onClick={() => handleDeleteFile(value.id)}
                                                                        //     className="delete-button"
                                                                        //     data-id="delete-file"
                                                                        // />
                                                                    )}
                                                                </ListItem>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </PlaylistListStyled>
                                        </TableHolder>
                                    </ExternalHolder>
                                );
                            }}
                        </Droppable>
                    </DragDropContext>
                )}
                {!disabled && (
                    <Box textAlign="left" marginLeft={width > 425 ? '3.438rem' : 0}>
                        <label htmlFor="file">
                            <AddBoxRoundedIcon fontSize="large" color="primary" />
                        </label>
                        <UploadInput
                            id="file"
                            accept={supportedMediaTypes}
                            onChange={handleUpload}
                            type="file"
                            disabled={disabled}
                            name="upload-input"
                        />
                    </Box>
                )}
            </PlaylistItemStyled>
            <PlaylistItemStyled>
                <OrderTableOfContents titles={t('playlists.media-space')} icon={<SpaceIcon />} />
                <Box margin={width > 424 ? '1.25rem' : 0} flex="1 1 0">
                    <TransferList
                        disabled={disabled}
                        mediaSpaceUids={formModel.mediaSpaceIds}
                        isAllSelected={formModel.isForAllMediaSpaces}
                        id={id ? id : 'new'}
                        onChangeValue={(data: MediaSpaceChangeData) => {
                            setFormModel({
                                ...formModel,
                                isForAllMediaSpaces: data.isAllSelected,
                                mediaSpaceIds: !data.isAllSelected ? data.selectedMediaSpaces : [],
                            });
                        }}
                    />
                </Box>
            </PlaylistItemStyled>
            <PlaylistItemStyled>
                <OrderTableOfContents titles={t('playlist-form.calendar')} icon={<CalendarIcon />} />
                <Box flex="1 1 0">
                    <Calendar>
                        <FlashDatePicker
                            dateRange={true}
                            infinite={formModel.isInfinite}
                            onChange={handleDateRange}
                            selected={startDate}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange={!formModel.isInfinite}
                            disabled={disabled}
                            name="calendar"
                        />
                    </Calendar>
                </Box>
                <FormControlLabel
                    control={
                        <Switch
                            checked={formModel.isInfinite}
                            onChange={(event) => {
                                setFormModel({ ...formModel, isInfinite: event.target.checked, endDate: undefined });
                                setEndDate(formModel.isInfinite ? formModel.startDate : null);
                            }}
                            name="switch"
                        />
                    }
                    label={t('playlist-form.infinite')}
                    labelPlacement="start"
                    disabled={disabled}
                    name="date-of-end"
                />
            </PlaylistItemStyled>
            {!disabled && (
                <OptionButtonHolder textAlign="center" marginBottom="1.25rem">
                    <SubmitButton
                        onSubmit={sendEvent}
                        type="submit"
                        variant="outlined"
                        disabled={isDisabled}
                        onSubmiting={(isSubmiting: boolean) => {
                            setIsSubmiting(isSubmiting);
                        }}
                        color={isDisabled ? 'default' : 'primary'}
                        name="save-button"
                    >
                        {isDisabled ? (
                            <LoadingIndicator isLoaded={false} />
                        ) : (
                            <Typography variant="h5" className="save-text">
                                {t('playlist-form.save')}
                            </Typography>
                        )}
                    </SubmitButton>
                    <CancelButton
                        baseRef={baseRef}
                        originalData={originalData}
                        formData={formModel}
                        onClick={() => {
                            if (id) {
                                history.replace({
                                    pathname: generatePath(allRoutes.playlistProfile.path, { id: id }),
                                    state: locations,
                                });
                            } else {
                                history.replace(generatePath(allRoutes.playlists.path, { id: 1 }));
                            }
                        }}
                        disabled={isDisabled}
                        name="cancel-button"
                    >
                        {t('playlist-form.cancel')}
                    </CancelButton>
                </OptionButtonHolder>
            )}
        </FlashContainer>
    );
};

export default PlaylistCreateEditForm;
