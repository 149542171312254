import { Menu, MenuItem } from '@material-ui/core';
import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const FormControlTextInputStyle = styled.div(({}) => ({
    width: '16.563rem',
    display: '-webkit-box',
    '.MuiFormControl-root:last-child': {
        marginLeft: '1.25rem',
    },
    '.MuiInputBase-root': {
        background: variables.backgoroundColours.light,
        width: '15.563rem',
        height: '2.688rem',
        boxSizing: 'border-box',
        borderRadius: '4px',
    },
    '.MuiOutlinedInput-input': {
        color: variables.colours.default,
        textAlign: 'center',
        padding: '0.625rem',
    },
    '.MuiSelect-selectMenu': {
        display: 'flex',
    },
}));

export const MediaSpacesMultiSelectStyle = styled.div(({}) => ({
    '.checked-all': {
        margin: 0,
    },
    h6: {
        marginBottom: '0.688rem',
    },
    '.MuiInputBase-root': {
        width: '15.875rem',
        minWidth: '14.563rem',
        '@media only screen and (min-width: 768px) and (max-width: 1023px)': {
            width: '14.563rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '100%',
        },
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        width: '100%',
    },
}));

export const DataFilterIcin = styled.div(({}) => ({
    background: variables.backgoroundColours.primary,
    width: '53px',
    height: '47px',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '4px',
    marginTop: '0.4rem',
    marginRight: '0.625rem',
    padding: 0,
}));

export const NotifMenuStyled = styled(Menu)(({}) => ({
    'ul.MuiList-padding': {
        background: variables.backgoroundColours.light,
        boxSizing: 'border-box',
        borderRadius: '4px',
        width: '16.875rem',
        padding: 0,
    },
}));

export const NotifStyled = styled(MenuItem)(({}) => ({
    padding: 0,
    ':first-child': {
        width: '16.875rem',
        height: '2.938rem',
        padding: '0.625rem',
        background: variables.backgoroundColours.primary,
        img: {
            position: 'absolute',
            right: '0.313rem',
        },
        '&:hover': {
            background: variables.backgoroundColours.primary,
        },
    },
}));
export const LinkStyle = styled.div(({}) => ({
    width: 'auto',
    minWidth: '8.188rem',
    '.list-icon': {
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            width: '30px',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '30px',
        },
    },
    '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

export const StyledPriceFilter = styled.div({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: -5,
    marginBottom: 20,
    justifyContent: 'stretch',

    '> *': {
        margin: 5,
    },

    '.form-control-text-input': {
        width: '100%',
    },

    '.MuiInputBase-root': {
        height: '2.688rem',
    },

    '.filter-options': {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        minWidth: 'fit-content',

        '.filter-option': {
            minWidth: 'fit-content',
            flex: 1,
        },
    },

    '.date-options': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
        minWidth: 'fit-content',

        '.MuiBreadcrumbs-ol': {
            justifyContent: 'flex-end',
            alignItems: 'center',

            '.MuiBreadcrumbs-li': {
                display: 'inline-flex',
                alignItems: 'center',
                width: 60,
                height: 60,

                '.icon-button': {
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                    padding: 8,

                    '.MuiIconButton-label': {
                        border: `2px solid ${variables.backgoroundColours.border}`,
                        padding: 4,
                        borderRadius: '50%',
                        minHeight: 35,
                        minWidth: 35,
                    },

                    svg: {
                        height: 20,
                        width: 20,
                        fill: variables.backgoroundColours.border,
                    },

                    '&.active': {
                        '.MuiIconButton-label': {
                            borderColor: variables.colours.primary,
                        },

                        svg: {
                            path: {
                                fill: variables.colours.primary,
                                stroke: variables.colours.primary,
                            },
                        },
                    },
                },
            },
        },
    },

    '.media-space-multiselect': {
        flex: 1,
        display: 'flex',

        '@media (max-width: 960px)': {
            flex: 10,
        },

        '.MuiInputBase-root': {
            flex: 1,
        },
    },
});
