import { AxiosResponse } from 'axios';
import axios, { arrayToQuery, toValidGetDate } from './axios';
import {
    PagedPlaylistVmResponse,
    PlaylistDetailsVm,
    PlaylistModel,
    PlaylistsPagedRequest,
    PlaylistFileModel,
} from '../models/playlist.model';
import { ContentMetadataVm } from '../models/video';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string'); // CommonJS Module

export const playlistsAPI = {
    getById: (id: string): Promise<AxiosResponse<PlaylistDetailsVm>> => {
        return axios.get(`playlists/${id}`);
    },
    getPaged: (req: PlaylistsPagedRequest): Promise<PagedPlaylistVmResponse> => {
        const startDate = toValidGetDate(req.startDate);
        const endDate = toValidGetDate(req.endDate);

        const data = {
            page: req.page,
            pageSize: req.pageSize,
            property: req.property,
            direction: req.direction,
            filterString: req.filterString,
            startDate: startDate,
            endDate: endDate,
            mediaSpaceIds: req.mediaSpaceIds,
        };

        return axios.get(`playlists/?${queryString.stringify(data)}`);
    },
    create: (data: PlaylistModel): Promise<AxiosResponse<PlaylistDetailsVm>> => {
        return axios.post(`playlists`, data);
    },
    update: (id: string, data: PlaylistModel): Promise<AxiosResponse<PlaylistDetailsVm>> => {
        return axios.put(`playlists/${id}`, data);
    },
    deleteById: (id: string): Promise<AxiosResponse> => {
        return axios.delete(`playlists/${id}`);
    },
    deletePlaylists: (ids: string[]): Promise<AxiosResponse<boolean>> => {
        return axios.delete(`playlists?${arrayToQuery('ids', ids)}`);
    },
    uploadFile: async (file: File, duration: number): Promise<AxiosResponse<PlaylistFileModel>> => {
        const formData = new FormData();
        formData.append('file', file);

        const response = (await axios.post(`videos/playlist`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                duration,
            },
        })) as AxiosResponse<PlaylistFileModel>;

        return response;
    },
    getContentMetadata: (fileId: string): Promise<AxiosResponse<ContentMetadataVm>> => {
        return axios.get(`videos/${fileId}/playlist/metadata`);
    },
    getFileSrc: (fileId: string): string => {
        let token = '';
        const root = window.localStorage['persist:root'];
        if (root) {
            const tokens = JSON.parse(JSON.parse(root).authReducer);
            if (tokens?.auth?.accessToken) {
                token = tokens.auth.accessToken;
            }
        }
        return `${window.ENV.config.backendUrl}/api/videos/${fileId}/playlist?access_token=${token}`;
    },
};
