import { GridSortModel } from '@material-ui/data-grid';
import { SortDirections, SortDirectionsString, SortModel } from '../models/paging/paging.model';

export const getSortingParams = (
    model: GridSortModel,
    defaultProperty: string,
    defaultDirection: number,
): SortModel => {
    let property = defaultProperty;
    let direction = defaultDirection;

    if (model.length > 0) {
        property = model[0].field;
        direction = model[0].sort == SortDirectionsString.Asc ? SortDirections.Asc : SortDirections.Desc;
    }

    return { property, direction };
};
