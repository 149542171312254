import styled from 'styled-components';

export const LoadingIndicatorStyle = styled.div(({}) => ({
    background: 'rgba(35, 35, 35, 0.52)',
    backdropFilter: 'blur(11px)',
    position: 'fixed',
    right: 0,
    bottom: 0,
    height: `100vh`,
    width: `100vw`,
    zIndex: 10000,
}));

export const LoadingStatus = styled.div(({}) => ({
    position: 'fixed',
    zIndex: 10001,
    opacity: 1,
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '11.813rem',
}));

export const LoaderLogo = styled.img({
    height: 70,
});
