import React, { Dispatch, SetStateAction } from 'react';
import ResponsiveFlexBox from '../../ResponsiveFlexBox';
import FilterInput from '../../FilterInput';
import { Breadcrumbs, IconButton, Tooltip, Typography } from '@material-ui/core';
import { LinkStyle, FormControlTextInputStyle } from './style';
import { ReactComponent as Map } from '../../../../assets/icons/Map.svg';
import { ReactComponent as TableIcon } from '../../../../assets/icons/TableIcon.svg';
import { useTranslation } from 'react-i18next';
import { variables } from '../../../../theme/variables';

import FilterOption from 'components/atoms/FilterOption';

export type MediaSpacesFilterProps = {
    onFilterChange: Dispatch<SetStateAction<string>>;
    setTableMap: Dispatch<SetStateAction<boolean>>;
    tableMap: boolean;
    warningsOnly: boolean;
    setWarningsOnly: Dispatch<SetStateAction<boolean>>;
};

export default function MediaSpacesFilter(props: MediaSpacesFilterProps): JSX.Element {
    const { onFilterChange, tableMap, setTableMap, warningsOnly, setWarningsOnly } = props;
    const { t } = useTranslation();

    return (
        <ResponsiveFlexBox width="100%" justifyContent="space-between" marginBottom="1.25rem">
            <FormControlTextInputStyle>
                <FilterInput onChange={onFilterChange} />
                <FilterOption
                    onClick={() => setWarningsOnly((prev) => !prev)}
                    checked={warningsOnly}
                    text={t('mediaspaces.warning-only')}
                />
            </FormControlTextInputStyle>
            <LinkStyle>
                <Breadcrumbs aria-label="breadcrumb">
                    <Tooltip
                        title={
                            <Typography variant="body1" style={{ color: variables.colours.light }}>
                                {t('media-spaces.list-of-media-spaces')}
                            </Typography>
                        }
                        placement="top-start"
                        arrow
                    >
                        <IconButton
                            color="inherit"
                            onClick={() => {
                                setTableMap(true);
                            }}
                            name="list-button"
                        >
                            <TableIcon className={tableMap ? 'list-icon list-active' : 'list-icon'} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={
                            <Typography variant="body1" style={{ color: variables.colours.light }}>
                                {t('media-spaces.map')}
                            </Typography>
                        }
                        placement="top-start"
                        arrow
                    >
                        <IconButton
                            color="inherit"
                            onClick={() => {
                                setTableMap(false);
                            }}
                            name="map-button"
                        >
                            <div className={tableMap ? 'map-button-false' : 'map-button-true'}>
                                <Map className="map-icon" />
                            </div>
                        </IconButton>
                    </Tooltip>
                </Breadcrumbs>
            </LinkStyle>
        </ResponsiveFlexBox>
    );
}
