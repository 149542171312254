import { variables } from 'theme/variables';
import styled from 'styled-components';

import { Modal as MuiModal, Paper } from '@material-ui/core';

export const OrderVideo = styled.div((props: { height: string }) => ({
    borderRadius: '10px',
    display: 'flex',
    maxWidth: 300,
    minWidth: 300,
    height: `calc(${props.height} - 25px)`,
    '@media only screen and (max-width : 767px)': {
        maxWidth: 'none',
    },
    '@media only screen and (min-width : 1366px) and (max-width : 1440px)': {
        maxWidth: 'none',
        height: `${props.height}`,
    },
}));

export const ImageView = styled.img(({}) => ({
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: '10px',
    height: 'auto',
    maxHeight: '50vh',
    maxWidth: '100%',
    objectFit: 'contain',
    '&.fullScreanImg': {
        position: 'fixed',
        maxHeight: `calc(100vh - 20px)`,
        maxWidth: `calc(100vw - 20px)`,
        zIndex: 10001,
        top: '50%',
        left: '50%',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
}));

export const Modal = styled(MuiModal)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const ModalContent = styled.div({
    maxWidth: 1000,
    overflow: 'hidden',
    outline: 'none',
    border: 'none',
    width: 'fit-content',
});

export const PreviewBlock = styled.div({
    background: variables.backgoroundColours.dark,
    borderRadius: '10px',
    'img, video': {
        borderRadius: '0px !important',
    },
});

export const MediaPlaceholder = styled(Paper)({
    height: 200,
    width: 300,
    borderRadius: 8,
    backgroundColor: '#ccc',
});
