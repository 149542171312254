import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Link as MuiLink } from '@material-ui/core';

import AuthTemplate from 'components/templates/Auth';
import RegistrationForm from 'components/organisms/forms/Registration';

import allRoutes from 'router';

import { StyledRegistration } from 'components/templates/Registration/style';

export default function RegistrationTemplate(): JSX.Element {
    const { t } = useTranslation();

    return (
        <StyledRegistration>
            <AuthTemplate>
                <Typography variant="h1" color="secondary">
                    {t('login-form.create-new-account')}
                </Typography>
                <Box display="flex" marginTop="0.625rem">
                    <Typography variant="h3" color="secondary">
                        {t('registration-form.subheader')}
                    </Typography>
                    <MuiLink component={Link} to={allRoutes.signin.path} className="login" data-id="login">
                        <Typography variant="h3">{t('navigation.login')}</Typography>
                    </MuiLink>
                </Box>
                <RegistrationForm />
            </AuthTemplate>
        </StyledRegistration>
    );
}
