import { FC, useRef, useCallback, ChangeEvent } from 'react';

import { useFormikContext } from 'formik';

import { StyledMediaInput, MediaInputHeading, MediaInputHint, MediaInputIcon } from 'components/atoms/MediaInput/style';

import { MediaFormValues } from 'components/organisms/MediaSubForm';
import { useDispatch } from 'react-redux';
import { uploadVideo } from '../../../store/form/actions';

interface MediaInputProps {
    heading: string;
    hint: string;
    accept: string;
}

const MediaInput: FC<MediaInputProps> = ({ heading, hint, accept }) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const dispatch = useDispatch();

    const { values, setValues } = useFormikContext<MediaFormValues>();

    const handleClick = useCallback(() => inputRef.current?.click(), []);

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            dispatch(uploadVideo());
            const fileList = e.target.files;
            if (!fileList) return;

            const file = fileList[0];

            const isVideo = file.type?.includes('video/');

            setValues({
                ...values,
                contentType: file.type,
                content: file,
                contentUrl: URL.createObjectURL(file),
                ...(isVideo
                    ? {
                          contentDuration: 0,
                      }
                    : {
                          contentDuration: 10,
                      }),
            });
        },
        [setValues],
    );

    return (
        <StyledMediaInput onClick={handleClick}>
            <MediaInputIcon />
            <input ref={inputRef} hidden accept={accept} type="file" onChange={handleChange} />
            <MediaInputHeading>{heading}</MediaInputHeading>
            <MediaInputHint>{hint}</MediaInputHint>
        </StyledMediaInput>
    );
};

export default MediaInput;
