import React from 'react';
import { ToastContainer, ToastContainerProps } from 'react-toastify';
import styled from 'styled-components';

export const WrappedToastContainer = ({
    className,
    ...rest
}: ToastContainerProps & { className?: string }): JSX.Element => (
    <div className={className}>
        <ToastContainer {...rest} />
    </div>
);

export default styled(WrappedToastContainer).attrs({
    // custom props
})`
    .Toastify__toast-container {
        max-width: 100%;
    }
    .Toastify__toast {
    }
    .Toastify__toast--error {
    }
    .Toastify__toast--warning {
    }
    .Toastify__toast--success {
    }
    .Toastify__toast-body {
    }
    .Toastify__progress-bar {
    }
`;
