import { FC, ChangeEvent, Dispatch, SetStateAction } from 'react';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import useWindowDimensions from 'hooks/WindowDimensions';

import Spacer from 'components/atoms/Spacer';
import ItemsCount from 'components/atoms/ItemsCount';

import { MomentFormats } from 'shared/enums';
import { DeviceFile } from 'models/device.model';

import {
    DataFooter,
    ExternalHolder,
    FileDate,
    FileName,
    FileSize,
    ScheduleTable,
    Subheader,
    SubheaderText,
    TableHolder,
} from './style';
import { PaginationStyle } from 'components/organisms/PagedDataGrid/style';

export interface FileListProps {
    page: number;
    totalPage: number;
    totalItems: number;
    files: DeviceFile[];
    setPage: Dispatch<SetStateAction<number>>;
}

const FileList: FC<FileListProps> = ({ page, totalPage, totalItems, files, setPage }) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const history = useHistory();

    const handleChange = async (event: ChangeEvent<unknown>, page: number) => {
        history.push(`page${page}`);
        setPage(page);
    };

    return (
        <>
            <ExternalHolder maxWidth={width}>
                <TableHolder maxWidth={width}>
                    <ScheduleTable>
                        <thead>
                            <Subheader>
                                <th>
                                    <SubheaderText>{t('device-profile.file')}</SubheaderText>
                                </th>
                                <th>
                                    <SubheaderText>{t('device-profile.download-date')}</SubheaderText>
                                </th>
                                <th>
                                    <SubheaderText className="size">{t('device-profile.file-size')}</SubheaderText>
                                </th>
                            </Subheader>
                        </thead>
                        {files.map((value) => {
                            const labelId = `transfer-list-item-${value}-label`;
                            const playlistFileName = value.playlistFile?.name;
                            const videoName = value.video?.name;

                            const normalizedName = value.playlistFile
                                ? playlistFileName.slice(playlistFileName.indexOf('_') + 1)
                                : videoName.slice(videoName.indexOf('_') + 1);
                            return (
                                <>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <FileName
                                                    id={labelId}
                                                    primary={
                                                        value.playlistFile
                                                            ? `${value.playlistFile?.playlist?.name}/${normalizedName}`
                                                            : `${value.video?.order?.number}/${normalizedName}`
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <FileDate
                                                    primary={moment(value.downloadDate).format(MomentFormats.Long)}
                                                />
                                            </td>
                                            <td>
                                                <FileSize
                                                    primary={
                                                        +value.size.toFixed(1) + ' ' + t('device-profile.megabyte')
                                                    }
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </>
                            );
                        })}
                    </ScheduleTable>
                </TableHolder>
            </ExternalHolder>
            <DataFooter>
                <ItemsCount value={totalItems} />
                <Spacer />
                <PaginationStyle
                    count={totalPage ? totalPage : 1}
                    page={page}
                    onChange={handleChange}
                    shape="rounded"
                    name-id="pagination"
                />
            </DataFooter>
        </>
    );
};

export default FileList;
