import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const Icon = styled.div(({}) => ({
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    background: variables.backgoroundColours.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0.5rem',
    '@media only screen and (min-width : 425px) and (max-width : 767px)': {
        marginBottom: '3px',
    },
    '@media only screen and (min-width : 320px) and (max-width : 424px)': {
        width: '38px',
        height: '38px',
        marginBottom: '3px',
        svg: {
            width: '28px',
        },
    },
    '@media only screen and (min-width : 1440px)': {
        width: '38px',
        height: '38px',
        svg: {
            width: '28px',
        },
    },
}));
