import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const StyledDateFilter = styled.div(({}) => ({
    position: 'relative',
    borderRadius: '4px',
    '.imgHeader': {
        background: variables.backgoroundColours.light,
    },
    '.date-handler': {
        width: '16.875rem',
        background: variables.backgoroundColours.light,
    },
    '.days-handler': {
        marginLeft: '1.25rem',
        h6: {
            color: variables.colours.light,
        },
    },
    '.MuiIconButton-label': {
        background: variables.backgoroundColours.primary,
        borderRadius: '4px',
        width: '2.688rem',
        height: '2.688rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '.MuiIconButton-root': {
        padding: '0',
    },
    '.filter-icon': {
        path: {
            fill: variables.colours.primary,
        },
    },
    '.staticDayButton': {
        background: variables.backgoroundColours.border,
        borderRadius: '4px',
        border: 0,
        height: '1.75rem',
        margin: '0.25rem',
        ':hover': {
            h6: {
                color: variables.backgoroundColours.border,
            },
        },
    },
    '.option-button': {
        ':last-child': {
            marginLeft: '0.625rem',
            h6: {
                color: variables.backgoroundColours.border,
            },
        },
        h6: {
            textTransform: 'uppercase',
            color: variables.colours.primary,
        },
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        marginBottom: '0.375rem',
    },
}));

export const LinkStyle = styled.div(({}) => ({
    width: 'auto',
}));

export const HeaderCalendar = styled.div<{ width: number }>((props) => ({
    background: variables.backgoroundColours.primary,
    borderRadius: '4px 4px 0px 0px',
    width: '37.625rem',
    height: '2.938rem',
    paddingTop: '0.5rem',
    '.filter-header': {
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            flexDirection: 'row',
        },
    },
    '.header-title': {
        color: variables.colours.light,
        marginLeft: '-10rem',
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            marginLeft: 0,
        },
        '@media only screen and (width : 320px)': {
            paddingTop: 0,
        },
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        width: props.width - 34,
    },
}));

export const CalendarFilter = styled.div<{ width: number }>((props) => ({
    position: 'absolute',
    zIndex: 1,
    background: variables.backgoroundColours.light,
    border: `2px solid ${variables.colours.main}`,
    boxSizing: 'border-box',
    boxShadow: '-1px 3px 7px 1px rgba(0, 0, 0, 0.15)',
    borderRadius: '4px',
    width: '37.875rem',
    top: '-0.188rem',
    right: '-0.188rem',
    minHeight: '29.5rem',
    '.MuiOutlinedInput-input': {
        color: variables.colours.secondary,
        textAlign: 'center',
    },
    '.calendar': {
        '.react-datepicker__month-container': {
            float: 'left',
        },
        display: 'flex',
        justifyContent: 'space-between',
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        width: props.width - 30,
        left: -3,
    },
}));

export const DataFilterIcon = styled.div(({}) => ({
    background: variables.backgoroundColours.primary,
    width: '43px',
    height: '43px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '4px',
    padding: 0,
    '&.wide': {
        width: '300px',
        paddingTop: 0,
        height: '43px',
        '.date-handler': {
            width: '16.875rem',
            background: variables.backgoroundColours.light,
            '@media only screen and (min-width : 768px) and (max-width : 805px)': {
                width: '100%',
            },
        },
        '.MuiInputBase-input': {
            minHeight: '26px',
            padding: '3px',
        },
        '@media only screen and (min-width : 768px) and (max-width : 805px)': {
            width: '200px',
            height: '103px',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '100%',
            height: '103px',
        },
    },
}));
