import React from 'react';
import { useTranslation } from 'react-i18next';
import { variables } from '../../../../theme/variables';
import FlashTextarea from '../../controls/FlashTextarea';

export type RejectOrderConfirmationProps = {
    onChangeMessage?: (value: string) => void;
    maxLength?: number;
};

export default function RejectOrderConfirmation(props: RejectOrderConfirmationProps): JSX.Element {
    const { t } = useTranslation();

    const { onChangeMessage: handleMessageText, maxLength } = props;
    return (
        <FlashTextarea
            inputProps={maxLength !== undefined ? { maxLength: maxLength } : {}}
            userwidth="100%"
            rows={6}
            placeholder={t('media-space-form.comment-label')}
            borderColor={variables.colours.primary}
            backgroundColor={variables.colours.light}
            disabled={false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.value;
                handleMessageText && handleMessageText(value);
            }}
            name="reject-textarea"
        ></FlashTextarea>
    );
}
