import React from 'react';
import { ContainerProps } from '@material-ui/core';
import { StyledContainer, FullScreenStyledContainer } from './style';

export type MainContainerProps = ContainerProps;

export default function FlashContainer(props: MainContainerProps & { isFullWidth?: boolean }): JSX.Element {
    return (
        <>
            {props.isFullWidth ? (
                <FullScreenStyledContainer>{props.children}</FullScreenStyledContainer>
            ) : (
                <StyledContainer>{props.children}</StyledContainer>
            )}
        </>
    );
}
