import styled from 'styled-components';

import { Paper, PaperProps, IconButton, Slider, Typography, CircularProgress } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { variables } from 'theme/variables';

export const Video = styled.video(({}) => ({
    width: '100%',
    maxHeight: '50vh',
    minWidth: 285,
    objectFit: 'contain',
}));

export const VideoWrapper = styled.div({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
});

export const PlayButton = styled(IconButton)(({}) => ({
    position: 'absolute',
    zIndex: 10,
    width: 'calc(100px - 2vw)',
    height: 'calc(100px - 2vw)',
}));

export const PlayIcon = styled(PlayArrowIcon)(({}) => ({
    position: 'relative',
    zIndex: 15,
    width: '100%',
    height: '100%',
    color: variables.colours.light,
    filter: 'drop-shadow(0 0 2px rgb(0 0 0 / 0.4))',
}));

interface ControlsProps {
    $small: boolean;
}

export const Controls = styled(Paper).attrs({ elevation: 2 })<ControlsProps>(({ $small }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    background: '#fff',
    borderRadius: 0,
    padding: '6px 8px',
    gap: '0 10px',
    flexWrap: 'wrap',

    ...($small
        ? {
              padding: '2px 4px',
              [Timeline]: {
                  order: 3,
                  minWidth: 'calc(100% - 20px)',
                  padding: '5px 0',
                  margin: '0 10px',
                  marginBottom: 5,
              },

              [Actions]: {
                  flex: 1,
              },

              [ControlButton]: {
                  height: 30,
                  width: 30,

                  [ControlIcon]: {
                      height: 18,
                      width: 18,
                  },
              },
          }
        : {}),
}));

export const ControlButton = styled(IconButton)({
    height: 40,
    width: 40,
});

export const ControlIcon = styled.svg({
    pointerEvents: 'none',
    height: 20,
    width: 20,
});

export const Timeline = styled(Slider)({
    flex: 1,
});

export const TimeValue = styled(Typography)({});

export const VolumeWrapper = styled.div({
    display: 'inline-flex',
    position: 'relative',
    justifyContent: 'center',
});

export const VolumePopup = styled.div({
    position: 'absolute',
    bottom: '100%',
    paddingBottom: 10,
    display: 'inline-flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
});

export const VolumeMenu = styled(Paper)({
    display: 'inline-flex',
    justifyContent: 'center',
    padding: 10,
    height: 150,
    width: 40,
});

export const Actions = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    [ControlButton]: {
        marginLeft: 10,
    },
});

interface StyledVideoPlayerProps extends PaperProps {
    $showControls: boolean;
    height: string;
}

export const StyledVideoPlayer = styled(Paper)<StyledVideoPlayerProps>(({ $showControls, height }) => ({
    height: `${height}`,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    overflow: 'hidden',
    marginBottom: 20,
    width: '100%',
    backgroundColor: '#111',
    flexDirection: 'column',

    [Video]: {
        cursor: $showControls ? 'pointer' : 'none',
    },

    '@media screen and (max-width: 720px)': {
        [Controls]: {
            position: 'static',
            width: '100%',
            borderRadius: 0,
        },
    },
}));

export const SmallVolume = styled(Slider).attrs({
    max: 100,
    valueLabelDisplay: 'auto',
})({
    width: 50,
});

export const Loader = styled(CircularProgress).attrs({
    size: 40,
})({
    color: '#fff',
    position: 'absolute',
});
