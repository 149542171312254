import * as React from 'react';
import Button from '../../../atoms/controls/Button';
import { useTranslation } from 'react-i18next';
import { ChatMessage, ChatMessageVm } from '../../../../models/chat-message-model';
import FormControlTextInput from '../../../molecules/forms/FormControlTextInput';
import { Box, Paper, Typography } from '@material-ui/core';
import { useHistory, useParams, useLocation, generatePath } from 'react-router-dom';
import unionBy from 'lodash.unionby';
import { useNotification } from '../../NotificationProvider';
import { useEffect, useRef, useState } from 'react';
import { getMessages } from '../../../../hub/chat';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { Chat, OrderButton, MessagesStile, NewMessages, MessageLength } from './style';
import { ReactComponent as Submit } from '../../../../assets/icons/Submit.svg';
import moment from 'moment';
import { MomentFormats } from '../../../../shared/enums';
import _ from 'lodash';
import LoadingIndicator from '../../../atoms/LoadingIndicator';
import { readNotifications, sendMessage } from '../../../../api/chat';
import allRoutes from '../../../../router';
import { toast } from 'react-toastify';
import { getDateWithLocalTz } from '../../../../hooks/getDateWithLocalTz';

interface IState {
    orderNumber?: number;
    orderUid?: string;
}

export enum HubEvents {
    onNewMessage = 'OnNewMessage',
    onNotification = 'OnNewNotification',
    onNotificationsUpdate = 'OnNotificationsUpdate',
    onUserAddedToChat = 'OnUserAddedToChat',
    onNewScreenshot = 'OnNewScreenshot',
    onUserDisabled = 'OnUserDisabled',
    onNewOrderCompletedEvent = 'OnNewOrderCompletedEvent',
}

export type ChartFormProps = {
    onNewMessage?: (message: ChatMessageVm) => void;
};

export default function ChatForm(props: ChartFormProps): JSX.Element {
    const { onNewMessage } = props;
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.userReducer.user);
    const location = useLocation();

    const [formData, setFormData] = React.useState<ChatMessage>({
        messageText: '',
    });

    const { hub } = useNotification();
    const history = useHistory();

    const { id } = useParams<{ id: string }>();

    const stateRef = useRef<{ id: string }>();
    stateRef.current = { id: id };

    const [isLoadTriggered, setIsLoadTriggered] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [messages, setMessages] = useState(Array<ChatMessageVm>());
    const [page, setCount] = useState(1);
    const [orderNumber, setOrderNumber] = useState<number>();
    const [orderUid, setOrderUid] = useState<string>();
    const messagesEndRef = useRef<HTMLInputElement>(null);
    const [scrollMassage, setScrollMassage] = useState(0);
    const [hasConnection, setHasConnection] = useState(false);
    const [messageLength, setMessageLength] = useState(0);

    const messageMaxLength = 500;

    const sendChatMessage = async () => {
        const messageToSend = formData.messageText.trim();
        if (messageToSend.length > 0) {
            setFormData({ ...formData, messageText: '' });
            setMessageLength(0);
            await sendMessage(id, messageToSend);
            goEnd();
        }
    };

    useEffect(() => {
        if (page <= 2) goEnd();
    }, [page]);

    useEffect(() => {
        if (location.state as IState) {
            if ((location.state as IState).orderUid) {
                setOrderNumber(location.state ? (location.state as IState).orderNumber : 0);
                setOrderUid(location.state ? (location.state as IState).orderUid : '0');
            }
        }
    }, [location]);

    useEffect(() => {
        setIsLoadTriggered(false);
    }, [id]);

    useEffect(() => {
        if (!isLoadTriggered && user.nameid) {
            setIsLoadTriggered(true);
            loadChatData();
        }
    }, [isLoadTriggered, user]);

    const readNotificationsForChat = async (chatId: string) => {
        await readNotifications(chatId);
    };

    const onNewMessageFromHub = (data: ChatMessageVm) => {
        const isSameChat = data.chatId === stateRef.current?.id;
        const messageClone = _.clone(data);
        messageClone.isViewed = isSameChat;

        if (isSameChat) {
            readNotificationsForChat(stateRef.current?.id || '');
            setMessages((messages) => [...messages, messageClone]);
        }
        onNewMessage && onNewMessage(messageClone);
    };

    const onNewToastNotificationFromHub = (data: string) => {
        toast.success(t('order.status.completedMessage', data));
    };

    useEffect(() => {
        hub?.on(HubEvents.onNewMessage, onNewMessageFromHub);
        hub?.on(HubEvents.onNewOrderCompletedEvent, onNewToastNotificationFromHub);
        setHasConnection(hub?.state.toString() === 'Connected');
        return () => {
            hub?.off(HubEvents.onNewMessage);
            hub?.off(HubEvents.onNewOrderCompletedEvent);
        };
    }, [hub?.state]);

    useEffect(() => {
        setCount(1);
        setMessages([]);
    }, [id]);

    function goEnd() {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }

    const loadChatData = async () => {
        if (id && id != ':id') {
            setIsLoaded(false);
            const response = await getMessages(id, page);
            response.data.forEach((message) => {
                message.createdDate = getDateWithLocalTz(new Date(message.createdDate)).toString();
            });
            const newMessages = response.data.reverse();
            const messagesCopy = [...messages];
            messagesCopy.concat(newMessages);
            messagesCopy.splice(0, messages.length, ...unionBy(newMessages, messagesCopy, (x) => x.id));
            readNotificationsForChat(id);

            if (onNewMessage) {
                const cloneLastMessage = newMessages[newMessages.length - 1];
                if (cloneLastMessage) {
                    cloneLastMessage.isViewed = true;
                    onNewMessage(cloneLastMessage);
                }
            }
            setMessages(messagesCopy);
            setCount(page + 1);
            setIsLoaded(true);
        }
    };

    // eslint-disable-next-line
    function onScrollList(event: any) {
        if (event.target.scrollTop === 0 && isLoadTriggered) {
            loadChatData();
            event.target.scrollTop = event.target.scrollHeight - scrollMassage;
            setScrollMassage(event.target.scrollHeight);
        }
    }

    return (
        <Chat>
            <OrderButton>
                <Button
                    onClick={() => {
                        history.push(generatePath(allRoutes.orderProfile.path, { id: orderUid }));
                    }}
                    type="submit"
                    className="buttonText"
                    name="order"
                >
                    <Typography variant="h2" className="chat-title">
                        {t('general.order')} {orderNumber}
                    </Typography>
                </Button>
            </OrderButton>
            <Box
                className="dialogBox"
                onScroll={(event) => onScrollList(event)}
                maxHeight="40vh"
                overflow="hidden"
                padding="0 1.563rem 0 1.563rem"
                data-id="messages-list"
            >
                <LoadingIndicator isLoaded={isLoaded} />
                {messages.map((item, index) => (
                    <MessagesStile key={index}>
                        <Box display="flex" justifyContent={user.nameid === item.userId ? 'flex-end' : 'flex-start'}>
                            <Box className={item.isSystemMessage ? 'system' : ''}>
                                {user.nameid !== item.userId && (
                                    <Typography variant="subtitle1">
                                        <strong>{item.userFullName}</strong>
                                    </Typography>
                                )}
                                <Paper className={user.nameid === item.userId ? 'message-end' : 'message-start'}>
                                    <Typography variant="subtitle1" className="chat-message">
                                        {item.textMessage}
                                    </Typography>
                                </Paper>
                                <div>
                                    <Typography
                                        variant="subtitle1"
                                        className={user.nameid === item.userId ? 'end-time-message' : 'time-message'}
                                    >
                                        {moment(item.createdDate).format(MomentFormats.Long)}
                                    </Typography>
                                </div>
                            </Box>
                        </Box>
                    </MessagesStile>
                ))}
                <div ref={messagesEndRef} />
            </Box>
            <NewMessages>
                <Box marginLeft="5%" width="77%">
                    <FormControlTextInput
                        value={formData.messageText}
                        variant="outlined"
                        color="primary"
                        disabled={false}
                        inputProps={{
                            maxLength: messageMaxLength,
                        }}
                        onKeyPress={(e) => {
                            if (hasConnection && e.key === 'Enter') {
                                sendChatMessage();
                            }
                        }}
                        onChangeValue={(value: string) => {
                            setFormData({ ...formData, messageText: value });
                            setMessageLength(value.length);
                        }}
                        label=""
                        name="chat-input"
                    ></FormControlTextInput>
                </Box>
                <Box margin="0.938rem 0% 0% 5%">
                    <LoadingIndicator isLoaded={hasConnection} />
                    {hasConnection && (
                        <Button disabled={!hasConnection} onClick={sendChatMessage} type="submit" name="send-button">
                            <Submit />
                        </Button>
                    )}
                    <MessageLength>{`${messageLength}/${messageMaxLength}`}</MessageLength>
                </Box>
            </NewMessages>
        </Chat>
    );
}
