import { Box, Button, Typography } from '@material-ui/core';
import { currenciesAPI } from 'api/currency';
import CancelButton from 'components/atoms/controls/CancelButton';
import FlashContainer from 'components/atoms/FlashContainer';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import ProfileActions from 'components/molecules/ProfileActions';
import {
    FormControlTextInputStyle,
    PriceEditFormStyle,
    StyledTitleBlock,
} from 'components/organisms/forms/PriceEditForm/style';
import useWindowDimensions from 'hooks/WindowDimensions';
import { IBaseFormRef } from 'models/interface/i-form-data';
import { CurrencyInputModel, DefaultCurrencyInput } from 'models/currency.model';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import allRoutes from 'router';
import { UserRoles } from 'shared/enums';
import { RootState } from 'store/rootReducer';
import TextContent from '../../../atoms/dialog/TextContent';
import FlashDialog from '../../../atoms/dialog/FlashDialog';

export type CurrencyFromProps = {
    event: CurrencyInputModel;
    id?: string;
};

type ValidationResult = {
    isNameValid: false;
    isCodeValid: false;
};

export default function CurrencyCreatePage(props: CurrencyFromProps): JSX.Element {
    const { event, id } = props;

    const { t } = useTranslation();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.userReducer.user);
    const { width } = useWindowDimensions();
    const locations = useLocation().state;

    const [disabled, setDisabled] = useState(id !== undefined);
    const [isDisabled, setIsDisabled] = useState(false);
    const [originalData, setOriginalData] = useState(DefaultCurrencyInput);
    const [formModel, setFormModel] = useState(DefaultCurrencyInput);
    const baseRef = useRef<IBaseFormRef>();
    const [isValidated, setIsValidated] = useState(false);
    const [formValidation, setFormValidation] = useState<ValidationResult>({
        isNameValid: false,
        isCodeValid: false,
    });
    const [isShowPreventionMessage, setIsShowPreventionMessage] = useState(false);
    const [newId, setNewId] = useState<string>('');

    const onEdit = () => {
        if (id && disabled && user.role === UserRoles.Admin)
            return { pathname: generatePath(allRoutes.editCurrency.path, { id }), state: locations };
        return;
    };

    async function deleteCurrency() {
        if (id) {
            currenciesAPI.deleteById(id);
        }
        history.push(generatePath(allRoutes.currency.path, { pageId: 1 }));
    }

    const handleValidation = (): boolean => {
        setIsValidated(true);
        const validationResult = {
            isNameValid: formModel.name !== '',
            isCodeValid: formModel.code !== '',
        } as ValidationResult;
        if (!validationResult.isNameValid) {
            toast.error(t('currency-form.messages.error-empty-name-currency'));
        }
        if (!validationResult.isCodeValid) {
            toast.error(t('currency-form.messages.error-empty-code-currency'));
        }
        setFormValidation(validationResult);

        let isValid = true;
        Object.keys(validationResult).map(
            (key) => (isValid = isValid && validationResult[key as keyof ValidationResult]),
        );

        return isValid;
    };

    const handlePreventionMessage = () => {
        setIsShowPreventionMessage(!isShowPreventionMessage);
        if (isShowPreventionMessage && newId !== '') {
            history.replace({
                pathname: generatePath(allRoutes.currencyProfile.path, { id: newId }),
                state: locations,
            });
        }
    };

    const sendEvent = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const isValid = handleValidation();

        if (isValid) {
            baseRef.current?.release();
            let isSuccess = false;
            const model = {
                name: formModel.name,
                code: formModel.code,
            } as CurrencyInputModel;

            let textToast = '';

            try {
                if (id) {
                    const response = await currenciesAPI.update(id, model);
                    isSuccess = response.data?.id !== '';
                    textToast = 'currency-form.messages.success-update-currency';
                    history.replace({
                        pathname: generatePath(allRoutes.currencyProfile.path, { id: response.data.id }),
                        state: locations,
                    });
                } else {
                    const response = await currenciesAPI.create(model);
                    isSuccess = response.data?.id !== '';
                    textToast = 'currency-form.messages.success-create-currency';
                    setNewId(response.data.id);
                    handlePreventionMessage();
                }
            } catch {
                isSuccess = false;
            }

            if (isSuccess) {
                setIsDisabled(true);
                toast.success(t(textToast));
            } else {
                baseRef.current?.block();
            }
        }

        return false;
    };

    useEffect(() => {
        setFormModel(event);
        setOriginalData(event);
    }, [event]);

    useEffect(() => {
        if (location.pathname === `/currencies/edit/${id}`) {
            setDisabled(false);
        }
    }, [location.pathname]);

    return (
        <PriceEditFormStyle>
            <FlashContainer>
                <ProfileActions
                    deleteTitle={t('currency-form.delete-currency')}
                    deleteContent={t('currency-form.contentText')}
                    hasDeleteButton={(id && user.role === UserRoles.Admin && !formModel.isDefault) as boolean}
                    onDelete={deleteCurrency}
                    onEdit={onEdit()}
                />
                <Box margin={width > 424 ? '1.25rem' : 0}>
                    <Box width="100%">
                        <Typography variant="h1" className="header">
                            {t('currency-form.header')}
                        </Typography>
                        <StyledTitleBlock className="tariff-title">
                            <FormControlTextInputStyle
                                disabled={disabled}
                                inputProps={{
                                    maxLength: 500,
                                }}
                                value={formModel.name}
                                error={isValidated && formValidation.isNameValid}
                                color="secondary"
                                onChangeValue={(value: string) => {
                                    setFormModel({ ...formModel, name: value });
                                }}
                                placeholder={t('currency.name')}
                                name="name"
                            />
                            <FormControlTextInputStyle
                                disabled={disabled}
                                inputProps={{
                                    maxLength: 500,
                                }}
                                value={formModel.code}
                                error={isValidated && formValidation.isCodeValid}
                                color="secondary"
                                onChangeValue={(value: string) => {
                                    setFormModel({ ...formModel, code: value });
                                }}
                                placeholder={t('currency.code')}
                                name="code"
                            />
                        </StyledTitleBlock>
                    </Box>
                </Box>
                <FlashDialog
                    open={isShowPreventionMessage}
                    isOpen={isShowPreventionMessage}
                    title={t('currency-form.attention-message')}
                    content={<TextContent text={t('currency-form.prevention-message')} />}
                    controls={
                        <Button
                            onClick={() => handlePreventionMessage()}
                            type="submit"
                            variant="outlined"
                            color="primary"
                            className="option-button"
                        >
                            <Typography variant="h5" color="inherit">
                                {t('currency-form.confirm')}
                            </Typography>
                        </Button>
                    }
                    onClose={() => handlePreventionMessage()}
                />
                {!disabled && (
                    <>
                        <Box textAlign="center" marginBottom="1.25rem">
                            <Button
                                onClick={sendEvent}
                                type="submit"
                                variant="outlined"
                                color={isDisabled ? 'default' : 'primary'}
                                className="option-button"
                                name="save-button"
                            >
                                {isDisabled ? (
                                    <LoadingIndicator isLoaded={false} />
                                ) : (
                                    <Typography variant="h5" className="save-text">
                                        {t('price.button-text.save')}
                                    </Typography>
                                )}
                            </Button>
                            <CancelButton
                                baseRef={baseRef}
                                originalData={originalData}
                                formData={formModel}
                                onClick={() => {
                                    if (id) {
                                        history.replace({
                                            pathname: generatePath(allRoutes.currencyProfile.path, { id: id }),
                                            state: locations,
                                        });
                                    } else {
                                        history.goBack();
                                    }
                                }}
                                disabled={isDisabled}
                                className="option-button"
                                name="cancel-button"
                            >
                                {t('price.button-text.cancel')}
                            </CancelButton>
                        </Box>
                    </>
                )}
            </FlashContainer>
        </PriceEditFormStyle>
    );
}
