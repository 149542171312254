import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Box, Tooltip, Typography, Link as MuiLink } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { StyledHeaderyTypography, StyledTemplate } from 'components/templates/FilteredTemplate/style';

import { FlashRouterProps } from 'router';

import { ReactComponent as PaymentAttention } from 'assets/icons/PaymentAttention.svg';

import { variables } from 'theme/variables';
import { useDispatch } from 'react-redux';
import { deleteMediaSpacesForm, setAllMediaSpaces } from '../../../store/form/actions';

export interface FilteredTemplateProps {
    filter: JSX.Element;
    content: JSX.Element;
    header?: string;
    subheader?: string;
    createItemPageRoute?: FlashRouterProps;
    isMediaspaces?: boolean;
    isDevices?: boolean;
    isForOrder?: boolean;
}

const FilteredTemplate: FC<FilteredTemplateProps> = ({
    filter,
    content,
    header,
    createItemPageRoute,
    subheader,
    isMediaspaces,
    isDevices,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const clearFormReducer = () => {
        dispatch(deleteMediaSpacesForm());
        dispatch(setAllMediaSpaces(false));
    };

    return (
        <StyledTemplate>
            <Box className="container" marginBottom={3.5}>
                <Typography variant="h1">
                    {header}
                    {(isMediaspaces || isDevices) && (
                        <Tooltip
                            title={
                                <Typography variant="body1" style={{ color: variables.colours.light }}>
                                    {isMediaspaces && t('media-spaces.attention')}
                                    {isDevices && t('devices.attention')}
                                </Typography>
                            }
                            placement="top-start"
                            arrow
                        >
                            <PaymentAttention className="attention" />
                        </Tooltip>
                    )}
                </Typography>
                {createItemPageRoute && (
                    <MuiLink
                        component={Link}
                        className="action"
                        to={createItemPageRoute.path}
                        data-id="create"
                        onClick={clearFormReducer}
                        id="SEO-createorder-in-orders-page-step-1"
                    >
                        <Typography variant="h6">{t('navigation.create-media-user')}</Typography>
                        <AddCircleOutlineIcon />
                    </MuiLink>
                )}
            </Box>
            {subheader && (
                <StyledHeaderyTypography>
                    <Typography variant="body1">{t('cost.subheader')}</Typography>
                </StyledHeaderyTypography>
            )}
            {filter}
            {content}
        </StyledTemplate>
    );
};

export default FilteredTemplate;
