import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const GradientFontStyled = styled(Typography)({
    fontFamily: 'MagistralCTT',
    fontStyle: 'normal',
    'font-weight': '700',
    fontSize: '90px',
    lineHeight: '137.01%',
    background: 'linear-gradient(180deg, #FF6000 0%, rgba(241, 67, 54, 0.27) 119.17%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
});
