import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const StyledPlaybackStatistic = styled.div(({}) => ({
    '.MuiDataGrid-row': {
        paddingLeft: '0 !important',
        cursor: 'auto',
    },
    '.MuiDataGrid-cell': {
        ':first-child': {
            paddingLeft: '0 !important',
            justifyContent: 'center !important',
        },
    },
    '.chat-button': {
        marginTop: '0 !important',
        marginBottom: '0 !important',
        marginRight: '5px',

        h6: {
            marginRight: '0.625rem',
            marginLeft: '0.938rem',
            color: variables.colours.primary,
            textTransform: 'uppercase',
            '@media only screen and (min-width : 320px) and (max-width : 768px)': {
                marginRight: '0.225rem',
                marginLeft: '0.338rem',
            },
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            marginLeft: 0,
            marginTop: 0,
            marginBottom: 0,
        },
    },
}));
