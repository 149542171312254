import styled from 'styled-components';

import { variables } from 'theme/variables';

export const StyledRegistration = styled.div(({}) => ({
    '.login': {
        marginLeft: '0.625rem',
        h3: {
            color: variables.colours.primary,
            marginBottom: '0.125rem',
        },
    },
    '@media only screen and (min-width: 320px) and (max-width: 767px)': {
        marginTop: 0,
    },
}));
