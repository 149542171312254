import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { variables } from 'theme/variables';

export const StatisticsCardStyled = styled.div({
    display: 'inline-flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    flex: '1 1 32%',
    minWidth: '375px',
    maxWidth: '540px',
    padding: '15px 25px 25px',
    backgroundColor: variables.colours.light,
    border: `1px solid ${variables.colours.light}`,
    borderRadius: '20px',
    boxShadow: '0px 0 0 rgba(255, 255, 255, 0)',
    transition: 'box-shadow .2s',
    cursor: 'default',

    '@media only screen and (max-width: 414px)': {
        minWidth: 'auto',
        width: '100%',
    },

    ':hover': {
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
    },

    '.heading': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
});

export const StatisticsCardImageStyled = styled.div({
    position: 'relative',
    width: '240px',
    height: '240px',

    img: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        margin: 'auto',
        objectFit: 'cover',
        maxWidth: '100%',
        transform: 'translate(-50%, -50%)',
    },
});

export const StatisticsCardTitleStyled = styled(Typography)({
    marginBottom: '15px',
    fontFamily: 'MagistralCTT',
    'font-weight': '700',
    fontSize: '100px',
    lineHeight: '100%',
    textAlign: 'center',
    color: variables.colours.primary,
});

export const StatisticsCardTextStyled = styled(Typography)({
    'font-weight': '500',
    fontSize: '40px',
    lineHeight: '100%',
    letterSpacing: '0.05em',
    textAlign: 'center',
    color: variables.colours.dark,
});
