import React from 'react';
import { FormControl, TextFieldProps } from '@material-ui/core';
import { FlashTextarea } from './style';

export type FlashTextareaProps = TextFieldProps & {
    label?: string;
    userwidth: string;
    borderColor?: string;
    backgroundColor?: string;
};

export default function Textarea(props: FlashTextareaProps): JSX.Element {
    const { userwidth } = props;
    return (
        <FormControl style={{ width: userwidth }}>
            <FlashTextarea {...props} multiline />
        </FormControl>
    );
}
