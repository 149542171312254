import React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { MenuItem, Typography } from '@material-ui/core';

import { MediaSpaceDevicesFilterRule, MediaSpaceVm } from '../../../models/media-space.model';
import { getMediaSpaces } from '../../../api/media-space';
import FlashCheckbox from '../FlashCheckbox';
import { TransferListStyle } from './style';
import useSelector from '../../../hooks/useAppSelector';

function not(a: MediaSpaceVm[], b: MediaSpaceVm[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: MediaSpaceVm[], b: MediaSpaceVm[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export type MediaSpaceChangeData = {
    isAllSelected: boolean;
    selectedMediaSpaces: Array<string>;
};

export type TransferListProps = {
    mediaSpaceUids?: string[];
    isAllSelected?: boolean;
    onChangeValue?: (value: MediaSpaceChangeData) => void;
    id?: string | 'new';
    disabled: boolean;
    isForTariffs?: boolean;
};

export default function TransferList(props: TransferListProps): JSX.Element {
    const { mediaSpaceUids, isAllSelected, onChangeValue, id, disabled, isForTariffs } = props;
    const [checked, setChecked] = React.useState<MediaSpaceVm[]>([]);
    const [left, setLeft] = React.useState<MediaSpaceVm[]>([]);
    const [right, setRight] = React.useState<MediaSpaceVm[]>([]);
    const [flag, setFlag] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const user = useSelector((state) => state.userReducer.user);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    useEffect(() => {
        left.forEach((x) => {
            x.name = x.names.find((n) => n.languageCode === user.lc)?.name || '';
        });
        setLeft(left);
        right.forEach((x) => {
            x.name = x.names.find((n) => n.languageCode === user.lc)?.name || '';
        });
        setRight(right);
    }, [user.lc]);

    useEffect(() => {
        async function fetchData() {
            const response = await getMediaSpaces({
                devicesFilterRule: isForTariffs
                    ? MediaSpaceDevicesFilterRule.NoFilter
                    : MediaSpaceDevicesFilterRule.AllDevicesValid,
            });
            if (isAllSelected) {
                setRight(response.data);
            } else {
                response.data.map((element) => {
                    if (mediaSpaceUids?.indexOf(element.id) === -1) {
                        setLeft((prev) => [...prev, element]);
                    } else {
                        setRight((prev) => [...prev, element]);
                    }
                });
            }
            setFlag(true);
        }
        if ((mediaSpaceUids?.length || isAllSelected) && !flag) setTimeout(() => fetchData(), 300);
    }, [mediaSpaceUids]);

    useEffect(() => {
        async function fetchData() {
            const response = await getMediaSpaces({
                devicesFilterRule: isForTariffs
                    ? MediaSpaceDevicesFilterRule.NoFilter
                    : MediaSpaceDevicesFilterRule.AllDevicesValid,
            });
            setLeft(response.data);
            setFlag(true);
        }
        if (id === 'new') setTimeout(() => fetchData(), 300);
    }, [id]);

    useEffect(() => {
        if (flag) {
            const itemId: string[] = [];
            right.map((item) => {
                itemId.push(item.id);
            });
            const data = {
                selectedMediaSpaces: itemId,
                isAllSelected: left.length ? false : isAllSelected ? true : false,
            } as MediaSpaceChangeData;
            onChangeValue && onChangeValue(data);
        }
    }, [right]);

    const handleToggle = (value: MediaSpaceVm) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };
    const checkedAllMadiaSpaces = () => {
        const itemId: string[] = [];
        right.map((item) => {
            itemId.push(item.id);
        });
        if (isAllSelected === false) handleAllRight();
        onChangeValue &&
            onChangeValue({
                selectedMediaSpaces: itemId,
                isAllSelected: !isAllSelected,
            } as MediaSpaceChangeData);
    };

    const customList = (items: MediaSpaceVm[]) => (
        <Paper>
            <List dense component="div" role="list">
                {items.map((value: MediaSpaceVm) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                            disabled={disabled}
                            data-id="list-item"
                        >
                            <ListItemIcon>
                                <FlashCheckbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                    name="item-checkbox"
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={
                                    <Typography color={props.disabled ? 'textSecondary' : 'textPrimary'}>
                                        {value.name}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <TransferListStyle disabled={disabled}>
            <Grid container spacing={4} justify="center" alignItems="center" className="grid-container">
                <Grid className="list" item>
                    <Typography color={props.disabled ? 'textSecondary' : 'textPrimary'}>
                        {t('transfer-list.available-for-selection')}
                    </Typography>
                    <br />
                    {customList(left)}
                </Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            className="arrow"
                            variant="outlined"
                            size="small"
                            onClick={handleAllRight}
                            disabled={left.length === 0 || disabled}
                            aria-label="move all right"
                            name="double-arrow-rigth"
                        >
                            ≫
                        </Button>
                        <Button
                            className="arrow"
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0 || disabled}
                            aria-label="move selected right"
                            name="arrow-right"
                        >
                            &gt;
                        </Button>
                        <Button
                            className="arrow"
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0 || disabled}
                            aria-label="move selected left"
                            name="arrow-left"
                        >
                            &lt;
                        </Button>
                        <Button
                            className="arrow"
                            variant="outlined"
                            size="small"
                            onClick={handleAllLeft}
                            disabled={right.length === 0 || disabled}
                            aria-label="move all left"
                            name="double-arrow-left"
                        >
                            ≪
                        </Button>
                    </Grid>
                </Grid>
                <Grid className="list" item>
                    <Typography color={props.disabled ? 'textSecondary' : 'textPrimary'}>
                        {t('transfer-list.selected')}
                    </Typography>
                    <br />
                    {customList(right)}
                </Grid>
            </Grid>
            <MenuItem
                disabled={disabled}
                className="all-mediaspaces"
                data-id="all-items"
                onClick={checkedAllMadiaSpaces}
            >
                <FlashCheckbox checked={isAllSelected || false} name="all-checkbox" />
                <ListItemText primary={t('price.button-text.select-all')} />
            </MenuItem>
        </TransferListStyle>
    );
}
