import allRoutes from 'router';
import { IntroButtonStyled, IntroStyled, IntroTitleStyled } from './style';
import FlashCommonGlobal from 'components/atoms/FlashCommonGlobal';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import IntroBackground from 'assets/images/intro-background.png';
import IntroImage from 'assets/images/home-page.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';

type IntroProps = {
    title: React.ReactNode;
    backgroundUrl?: string;
    imageRightUrl?: string;
    marginBottom?: string;
};

export default function Intro({
    title,
    backgroundUrl = IntroBackground,
    imageRightUrl = IntroImage,
    marginBottom = '100px',
}: IntroProps): JSX.Element {
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.userReducer.user);

    return (
        <IntroStyled marginBottom={marginBottom}>
            <img srcSet={backgroundUrl} />
            <FlashCommonGlobal>
                <div className="content">
                    <div className="left">
                        <IntroTitleStyled>{title}</IntroTitleStyled>
                        <IntroButtonStyled
                            to={!user.role ? allRoutes.signup.path : allRoutes.createOrder.path}
                            id={
                                !user.role
                                    ? 'SEO-registration-in-home-page-intro'
                                    : 'SEO-create-order-in-home-page-intro'
                            }
                        >
                            {!user.role ? (
                                <Typography variant="h5" className="button-text">
                                    {t('home-page.intro.button')}
                                </Typography>
                            ) : (
                                <Typography variant="h5" className="button-text">
                                    {t('home-page.intro.create-order')}
                                </Typography>
                            )}
                        </IntroButtonStyled>
                    </div>
                    <div className="right">
                        <img srcSet={imageRightUrl} />
                    </div>
                </div>
            </FlashCommonGlobal>
        </IntroStyled>
    );
}
