import { FC } from 'react';

import { FormikHelpers } from 'formik';

import useWindowDimensions from 'hooks/WindowDimensions';

import PhotoUploader from 'components/organisms/PhotoUploader';
import UserRolesSelect from 'components/molecules/forms/UserProfileRolesSelect';

import { ProfileImageModel, UserModel } from 'models/user-model';

import { StyledBox, UserHeader, UserName } from 'components/molecules/User/Header/style';

export interface UserProfileHeaderProps {
    id: string;
    disabled?: boolean;
    values: UserModel;
    setFieldValue: FormikHelpers<UserModel>['setFieldValue'];
    imageUrl: string | undefined;
    sendImageToStorage: (value: ProfileImageModel) => void;
}

const UserProfileHeader: FC<UserProfileHeaderProps> = ({
    id,
    disabled,
    sendImageToStorage,
    values,
    setFieldValue,
    imageUrl,
}) => {
    const { width } = useWindowDimensions();

    return (
        <UserHeader maxWidth={width}>
            <PhotoUploader
                disabled={disabled}
                handleBase64image={sendImageToStorage}
                userImgSrc={imageUrl}
                abbreviation={values.firstName}
            />
            <StyledBox>
                <UserName>
                    {values.firstName} {values.lastName}
                </UserName>
                <UserRolesSelect id={id} disabled={disabled} values={values} setFieldValue={setFieldValue} />
            </StyledBox>
        </UserHeader>
    );
};

export default UserProfileHeader;
