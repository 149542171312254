import React from 'react';
import { CheckboxStyle } from './style';
import { ReactComponent as ReactCustomCheckbox } from '../../../assets/icons/Checkbox.svg';
import { Checkbox } from '@material-ui/core';
import { GridCellParams } from '@material-ui/data-grid';

export type CustomCheckboxProps = {
    checked: string[];
    setChecked: (value: React.SetStateAction<string[]>) => void;
    params: GridCellParams;
    disabled?: boolean;
    className?: string;
};

export default function CustomCheckbox(props: CustomCheckboxProps): JSX.Element {
    const { params, checked, disabled, setChecked, className } = props;
    return (
        <Checkbox
            checked={checked.indexOf(params.id.toString()) >= 0}
            disabled={disabled}
            onClick={() => {
                const index = checked.indexOf(params.id.toString());
                if (index === -1) {
                    setChecked((prev) => {
                        return [...prev, params.id.toString()];
                    });
                } else {
                    setChecked(checked.slice(0, index).concat(checked.slice(index + 1, checked.length)));
                }
            }}
            icon={<CheckboxStyle />}
            checkedIcon={<ReactCustomCheckbox />}
            className={className}
        />
    );
}
