import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, useHistory, useParams } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import Button from 'components/atoms/controls/Button';
import UserInfo from 'components/molecules/Order/UserInfo';
import OrderHeader from 'components/molecules/Order/Header';
import FlashDialog from 'components/atoms/dialog/FlashDialog';
import PaymentStatusBox from 'components/atoms/PaymentStatus';
import TextContent from 'components/atoms/dialog/TextContent';
import YesNoButtons from 'components/atoms/dialog/YesNoButtons';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import SubmitButton from 'components/atoms/controls/SubmitButton';
import ResponsiveFlexBox from 'components/atoms/ResponsiveFlexBox';
import OrderStatusLine from 'components/atoms/Order/OrderStatusLine';
import OrderInfoTable from 'components/molecules/Order/OrderInfoTable';
import LoadingIndicatorPopup from 'components/atoms/LoadingIndicatorPopup';
import OrderInformation from 'components/molecules/Order/OrderInformation';
import OrderHeaderTitle from 'components/molecules/Order/OrderHeaderTitle';
import FormControlTextInput from 'components/molecules/forms/FormControlTextInput';
import RejectOrderConfirmation from 'components/atoms/dialog/RejectOrderConfirmation';

import useDebouncedEffect from 'hooks/useDebouncedEffect/useDebouncedEffect';

import allRoutes from 'router';
import { getConfig } from 'config';
import { AxiosResponse } from 'axios';

import {
    getById as getOrderById,
    getByIdWithCount,
    getPaymentStatus,
    hardDeleteById,
    onStatusToPay,
    toCancelPay,
    toChangeStatus,
    toChangeStatusByUser,
    toPay,
} from 'api/orders';
import { RootState } from 'store/rootReducer';
import { MomentFormats, OrderStatusType, PaymentStatus, UserRoles } from 'shared/enums';
import { CancelPaymentResponse, OrderCount, OrderPayVm, OrderVm } from 'models/order';

import orderBackground from 'assets/images/order-background.svg';

import {
    CancelButton,
    ModerationBlock,
    Order,
    OrderFormStyle,
    StyledButton,
} from 'components/pages/OrderProfile/style';
import { notUploadVideo } from '../../../store/form/actions';
import moment from 'moment';
import OrderHistoryTable from 'components/molecules/Order/OrderHistoryTable';
import { getDateWithLocalTz } from 'hooks/getDateWithLocalTz';

export default function OrderProfile(): JSX.Element {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.userReducer.user);
    const dispatch = useDispatch();

    const [isOperatorView, setIsOperatorView] = useState<boolean>(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isPayToShow, setIsPayToShow] = useState(false);
    const [orderStatusIsCreated, setOrderStatusIsCreated] = useState(false);
    const [isDeclineOrderReasonDialogShown, setOpen] = useState(false);
    const [isUndoOrderReasonDialogShown, setOpenUndo] = useState(false);
    const [isResetFormDialogShown, setModalToShow] = useState<boolean>(false);
    const [count, setCount] = useState<OrderCount>();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isLoadedUndo, setIsLoadedUndo] = useState(true);
    const [isStatusLoading, setIsStatusLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [order, setOrder] = useState<OrderVm>();
    const [costOrder, setCostOrder] = useState<number>();
    const [rejectOrderMessage, setRejectOrderMessage] = useState<string>();
    const [undoOrderMessage, setUndoOrderMessage] = useState<string>();
    const [inputCancelPayPartData, setInputCancelPayPartData] = useState<string>('');

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const emptyCostOrder = 0;
    const emptyPayResponseStatus = '0';

    const handleClose = () => {
        setOpen(false);
        setOpenUndo(false);
        setIsLoadedUndo(true);
    };

    const handleFormSubmitting = (isSubmitting: boolean) => {
        setIsSubmitting(isSubmitting);
    };

    const rejectOrder = async () => {
        if (rejectOrderMessage) {
            setIsLoadedUndo(false);
            await onChangeStatus(OrderStatusType.Cancelled, rejectOrderMessage);
            setOpen(false);
        } else {
            toast.error(t('order.declineReasonstatus'));
        }
    };

    const undoOrder = async () => {
        if (undoOrderMessage) {
            await onChangeStatus(OrderStatusType.Nullified, undoOrderMessage);
            setOpenUndo(false);
        } else {
            toast.error(t('order.undoReasonstatus'));
        }
    };

    const getDataOrder = async () => {
        setIsLoaded(false);
        const order: AxiosResponse<OrderVm> = await getOrderById(id);
        setIsDataLoaded(true);
        setOrder(order.data);
        const payToShow = user.role === UserRoles.User && order?.data.status === OrderStatusType.onPayWaiting;
        setIsPayToShow(payToShow);
        const orderStatusIsCreated = user.role === UserRoles.User && order?.data.status === OrderStatusType.Created;
        setOrderStatusIsCreated(orderStatusIsCreated);
        setOrder(order.data);
        setIsDataLoaded(true);
        setIsLoaded(true);
        if (order?.data.status !== OrderStatusType.onPayWaiting || !order.data.externalOrderId) {
            setIsLoaded(true);
        }
        if (order?.data.videos.length === 0) toast.error(t('order.errors.error-videos-length'));
    };

    const handleNegative = () => {
        setModalToShow(false);
    };

    async function onToPay() {
        if (costOrder !== emptyCostOrder && costOrder !== undefined) {
            const config = await getConfig();
            const redirectUrl = `${config.frontendUrl}/orders/${id}`;
            const reqValue = {
                orderId: id,
                returnUrl: redirectUrl,
            };
            const payResponse: AxiosResponse<OrderPayVm> = await toPay(reqValue);
            if (payResponse.data.formUrl != null) {
                return (window.location.href = payResponse.data.formUrl);
            }
            toast.success(payResponse.data.errorMessage);
        } else {
            toast.error(t('order-form.messages.error-cost-value'));
        }
    }

    async function onCancelPayment() {
        if (costOrder !== emptyCostOrder && costOrder !== undefined) {
            const reqValue = {
                orderId: id,
                amount: costOrder,
            };
            try {
                const payResponse: AxiosResponse<CancelPaymentResponse> = await toCancelPay(reqValue);
                if (payResponse.data.errorCode === emptyPayResponseStatus) {
                    toast.success(t('order-form.messages.sucess-cancel-pay'));
                    return true;
                } else {
                    toast.error(t('order-form.messages.error-cancel-pay'));
                    return false;
                }
            } catch {
                return false;
            }
        }
    }

    const handelCancelPayment = async () => {
        setIsLoadedUndo(false);
        const result = await onCancelPayment();
        if (result) {
            await onChangeStatus(OrderStatusType.PaymentRefunded, '');
            location.reload();
            setOpen(false);
        }
    };

    const handelPartialCancelPayment = async () => {
        if (inputCancelPayPartData == '') {
            return toast.error(t('order-form.messages.error-empty-field-cancel-pay'));
        }
        if (costOrder) {
            if (Number(inputCancelPayPartData) >= costOrder) {
                return toast.error(t('order-form.messages.error-coast-field-cancel-pay'));
            }
        }
        setIsLoadedUndo(false);
        const result = await onPartialCancelPayment();
        if (result) {
            await onChangeStatus(OrderStatusType.PaymentRefunded, '');
            location.reload();
            setOpen(false);
        }
    };

    const validateValue = () => {
        const value = parseInt(inputCancelPayPartData, 10);

        if (value < 0) setInputCancelPayPartData(emptyPayResponseStatus);
        if (costOrder) {
            if (value > costOrder) setInputCancelPayPartData(costOrder.toString());
        }
    };

    async function onPartialCancelPayment() {
        if (costOrder !== emptyCostOrder && costOrder !== undefined) {
            const reqValue = {
                orderId: id,
                amount: Number(inputCancelPayPartData),
            };
            const payResponse: AxiosResponse<CancelPaymentResponse> = await toCancelPay(reqValue);
            if (payResponse.data.errorCode === emptyPayResponseStatus) {
                toast.success(t('order-form.messages.sucess-cancel-pay'));
                setInputCancelPayPartData('');
                return true;
            } else {
                toast.error(t('order-form.messages.error-cancel-pay'));
                return false;
            }
        }
    }

    async function onChangeStatus(orderType: OrderStatusType, message: string) {
        const reqValue = {
            status: orderType,
            reasonMessage: message,
        };

        const acceptResponse: AxiosResponse<OrderVm> = isOperatorView
            ? await toChangeStatus(id, reqValue)
            : await toChangeStatusByUser(id, reqValue);

        if (acceptResponse.data != null) {
            setOrder(acceptResponse.data);
            handleFormSubmitting(false);
        }
    }

    useEffect(() => {
        const getCount = async () => {
            if (
                order?.status === OrderStatusType.CurrentlyPlaying ||
                order?.status === OrderStatusType.Completed ||
                order?.status === OrderStatusType.Nullified ||
                order?.status === OrderStatusType.PaymentRefunded ||
                order?.status === OrderStatusType.PaymentRefundFailed ||
                order?.status === OrderStatusType.Cancelled
            ) {
                const { data } = await getByIdWithCount(order.id);
                setCount(data);
            }
            if (order?.id && costOrder && OrderStatusType.Approved) {
                const { data } = await getByIdWithCount(order.id);
                setInputCancelPayPartData(`${costOrder - (costOrder / data.expectedCount) * data.realCount}`);
            }
            const orderStatusIsCreated = user.role === UserRoles.User && order?.status === OrderStatusType.Created;
            setOrderStatusIsCreated(orderStatusIsCreated);
        };
        getCount();
    }, [order?.status]);

    useMemo(() => {
        setIsLoaded(true);
    }, [isPayToShow]);

    useMemo(() => {
        setIsDisabled(isSubmitting);
    }, [isSubmitting]);

    useMemo(() => {
        setIsLoadedUndo(true);
        setIsLoaded(false);
        if (order?.status === OrderStatusType.onPayWaiting) {
            const getData = async () => {
                setIsStatusLoading(true);
                const status = await onStatusToPay(id);
                setIsStatusLoading(false);
                if (status.data == PaymentStatus.Paid) {
                    await getDataOrder();
                }
            };
            setTimeout(() => getData(), 300);
        }
        const cost = order?.schedules.reduce((sum, current) => sum + current.cost, 0);
        setCostOrder(cost);
        if (order?.status !== OrderStatusType.onPayWaiting || !order.externalOrderId) {
            setIsLoaded(true);
        }
    }, [order]);

    useDebouncedEffect(
        () => {
            if ((!isDataLoaded || id !== order?.id) && user.nameid) {
                getDataOrder();
                const isAdmin = user.role === UserRoles.Admin || user.role === UserRoles.Operator;
                setIsOperatorView(isAdmin);
            }
        },
        200,
        [id, user],
    );

    const paymentStatus = useMemo(() => {
        return getPaymentStatus(order?.userRole, order?.status);
    }, [order]);

    const isCancellable =
        typeof order?.status === 'number' &&
        [OrderStatusType.Approved, OrderStatusType.CurrentlyPlaying].includes(order?.status);

    const handleGoToPlaybackStatistic = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        history.push({
            pathname: generatePath(allRoutes.playbackStatistic.path, { orderId: order?.id, pageId: 1 }),
            state: { orderNumber: order?.number, orderUid: order?.id },
        });
    };

    return (
        <>
            {(!isLoaded || isStatusLoading) && <LoadingIndicatorPopup isLoaded={isLoaded && !isStatusLoading} />}
            <OrderFormStyle>
                <OrderHeader order={order} isStatusLoading={isStatusLoading} />
                <OrderHeaderTitle>
                    <UserInfo order={order} />
                    {Boolean(paymentStatus) && <PaymentStatusBox status={paymentStatus} />}
                </OrderHeaderTitle>
                <OrderInformation
                    count={count}
                    order={order}
                    userRole={order?.userRole}
                    cost={order ? order?.schedules.reduce((sum, current) => sum + current.cost, 0) : 0}
                    currencyCode={order?.currencyCode}
                    label={
                        !isOperatorView ||
                        (order?.status == OrderStatusType.Nullified && order.userRole === UserRoles.User) ||
                        order?.status == OrderStatusType.PaymentRefunded
                            ? t('order-form.order-cost')
                            : t('order-form.order-cost-admin')
                    }
                >
                    <>
                        <h6>
                            {t('order-form.order-duration')}&nbsp;
                            {order?.videos.length === 0 ? '' : order?.videos[0]?.duration}
                        </h6>
                        {!isOperatorView && isPayToShow && (
                            <Typography variant="subtitle1" className="order-user">
                                {t('order-form.order-cost-description')}
                            </Typography>
                        )}
                        {!isOperatorView && orderStatusIsCreated && (
                            <Typography variant="subtitle1" className="order-user">
                                {t('order-form.order-to-moderation-description')}
                            </Typography>
                        )}
                        <OrderStatusLine orderStatus={order?.status} />
                        {isOperatorView &&
                            (order?.status === OrderStatusType.Approved ||
                                order?.status === OrderStatusType.onPayWaiting ||
                                order?.status === OrderStatusType.CurrentlyPlaying ||
                                order?.status === OrderStatusType.Completed ||
                                order?.status === OrderStatusType.Cancelled ||
                                order?.status === OrderStatusType.Nullified) && (
                                <ModerationBlock>
                                    <Typography>
                                        {(order?.status === OrderStatusType.Approved ||
                                            order?.status === OrderStatusType.onPayWaiting ||
                                            order?.status === OrderStatusType.CurrentlyPlaying ||
                                            order?.status === OrderStatusType.Completed) &&
                                            t('order-form.messages.orderApproved')}
                                        {order?.status === OrderStatusType.Cancelled &&
                                            t('order-form.messages.orderCanceled')}
                                        {order?.status === OrderStatusType.Nullified &&
                                            t('order-form.messages.orderNullified')}
                                        {t('order-form.messages.by-user')}
                                        <Link
                                            to={generatePath(allRoutes.userProfile.path, {
                                                id: order?.moderatorId,
                                            })}
                                        >
                                            {order?.moderatorLogin}
                                        </Link>
                                    </Typography>
                                    <Typography>
                                        {t('order-form.messages.date') +
                                            `${moment(getDateWithLocalTz(new Date(order?.updatedDate || ''))).format(
                                                MomentFormats.Long,
                                            )} (${timeZone})`}
                                    </Typography>
                                </ModerationBlock>
                            )}

                        {/* User view */}
                        {!isOperatorView && (
                            <>
                                {/* Start: User action buttons before moderation */}
                                {order?.status === OrderStatusType.Created && (
                                    <ResponsiveFlexBox
                                        gridRowGap={5}
                                        gridColumnGap={5}
                                        alignItems="center"
                                        justifyContent="start"
                                        flexWrap="wrap"
                                    >
                                        <LoadingIndicator isLoaded={isLoadedUndo} />
                                        {isLoadedUndo && (
                                            <SubmitButton
                                                onSubmiting={handleFormSubmitting}
                                                onSubmit={async () => {
                                                    setIsLoadedUndo(false);
                                                    await onChangeStatus(OrderStatusType.onModeration, '');
                                                }}
                                                disabled={isDisabled}
                                                variant="outlined"
                                                color="primary"
                                                className="order-btn filled-btn"
                                                name="send-to-moderation-btn"
                                                id="SEO-sendorder-in-orders-page-step-beforemoderation"
                                            >
                                                {t('order-form.button-text.sendToModeration')}
                                            </SubmitButton>
                                        )}
                                        <Button
                                            onClick={() => setModalToShow(true)}
                                            variant="outlined"
                                            color="primary"
                                            className="order-btn outlined-btn next-btn statistic-btn"
                                            id="SEO-cancelorder-in-orders-page-step-beforemoderation"
                                        >
                                            <Typography variant="h5">{t('order-form.button-text.cancel')}</Typography>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                history.push({
                                                    pathname: allRoutes.createOrder.path,
                                                    state: order?.id,
                                                });
                                                dispatch(notUploadVideo());
                                            }}
                                            variant="outlined"
                                            color="primary"
                                            className="order-btn outlined-btn next-btn statistic-btn"
                                            id="SEO-editorder-in-orders-page-step-beforemoderation"
                                        >
                                            <Typography variant="h5">{t('order-form.button-text.edit')}</Typography>
                                        </Button>
                                        <FlashDialog
                                            open={isResetFormDialogShown}
                                            isOpen={isResetFormDialogShown}
                                            title={t('order-form.rejectOrder')}
                                            content={<TextContent text={t('order-form.dialogToRejectOrderShort')} />}
                                            controls={
                                                <YesNoButtons
                                                    onConfirm={async () => {
                                                        if (order) await hardDeleteById(order.id);
                                                        history.push('/orders/page1');
                                                        toast.success(t('order-form.messages.success-delete'));
                                                    }}
                                                    onReject={handleNegative}
                                                />
                                            }
                                            onClose={handleNegative}
                                        />
                                    </ResponsiveFlexBox>
                                )}
                                {/* End: User action buttons before moderation */}

                                {/* Start: User action buttons after moderation */}
                                {isPayToShow && (
                                    <ResponsiveFlexBox
                                        gridRowGap={5}
                                        gridColumnGap={5}
                                        alignItems="center"
                                        justifyContent="start"
                                        flexWrap="wrap"
                                    >
                                        <SubmitButton
                                            onSubmiting={handleFormSubmitting}
                                            onSubmit={onToPay}
                                            disabled={isDisabled}
                                            variant="outlined"
                                            color="primary"
                                            className="order-btn filled-btn"
                                            name="pay-btn"
                                            id="SEO-payorder-in-orders-page-step-aftermoderation"
                                        >
                                            <Typography variant="h5" className="filled-btn-text">
                                                {t('order-form.button-text.pay')}
                                            </Typography>
                                        </SubmitButton>
                                        <SubmitButton
                                            onSubmiting={handleFormSubmitting}
                                            onSubmit={() => {
                                                setModalToShow(true);
                                            }}
                                            variant="outlined"
                                            color="primary"
                                            className="order-btn outlined-btn"
                                            name="undo-btn"
                                            id="SEO-cancelorder-in-orders-page-step-aftermoderation"
                                        >
                                            {t('order-form.button-text.cancel')}
                                        </SubmitButton>
                                        <FlashDialog
                                            open={isResetFormDialogShown}
                                            isOpen={isResetFormDialogShown}
                                            title={t('order-form.rejectOrder')}
                                            content={<TextContent text={t('order-form.dialogToRejectOrderShort')} />}
                                            controls={
                                                <YesNoButtons
                                                    onConfirm={async () => {
                                                        await onChangeStatus(OrderStatusType.Cancelled, '');
                                                        setModalToShow(false);
                                                        handleFormSubmitting(false);
                                                        handleClose();
                                                        setIsPayToShow(false);
                                                    }}
                                                    onReject={handleNegative}
                                                />
                                            }
                                            onClose={handleNegative}
                                        />
                                    </ResponsiveFlexBox>
                                )}
                                {/* End: User action buttons after moderation */}

                                {/* Start: User payment action buttons */}
                                {(order?.status == OrderStatusType.Nullified ||
                                    order?.status == OrderStatusType.PaymentRefunded ||
                                    order?.status == OrderStatusType.PaymentRefundFailed) && (
                                    <ResponsiveFlexBox
                                        gridRowGap={5}
                                        gridColumnGap={5}
                                        alignItems="center"
                                        justifyContent="start"
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={handleGoToPlaybackStatistic}
                                            color="primary"
                                            className="order-btn filled-btn statistic-btn"
                                            disabled={!(count !== undefined && count.realCount > 0)}
                                        >
                                            <Typography variant="h5" className="filled-btn-text">
                                                {t('order.playbackStatistic')}
                                            </Typography>
                                        </Button>
                                    </ResponsiveFlexBox>
                                )}
                                {/* End: User payment action buttons */}
                            </>
                        )}

                        {/* Operator view */}
                        {isOperatorView && (
                            <>
                                {/* Start: Admin action buttons on moderation */}
                                {order?.status === OrderStatusType.onModeration && (
                                    <ResponsiveFlexBox
                                        gridRowGap={5}
                                        gridColumnGap={5}
                                        alignItems="center"
                                        justifyContent="start"
                                        flexWrap="wrap"
                                    >
                                        <SubmitButton
                                            onSubmiting={handleFormSubmitting}
                                            onSubmit={async () => {
                                                setIsLoadedUndo(false);
                                                await onChangeStatus(OrderStatusType.onPayWaiting, '');
                                            }}
                                            disabled={isDisabled}
                                            variant="outlined"
                                            color="primary"
                                            className="order-btn filled-btn"
                                            name="accept-btn"
                                        >
                                            <Typography variant="h5" className="filled-btn-text">
                                                {t('order-form.button-text.accept')}
                                            </Typography>
                                        </SubmitButton>
                                        <SubmitButton
                                            onSubmiting={handleFormSubmitting}
                                            onSubmit={() => setOpen(true)}
                                            disabled={isDisabled}
                                            variant="outlined"
                                            color="primary"
                                            className="order-btn outlined-btn next-btn"
                                            name="decline-btn"
                                        >
                                            {t('order-form.button-text.decline')}
                                        </SubmitButton>
                                        <FlashDialog
                                            position="relative"
                                            open={isDeclineOrderReasonDialogShown}
                                            isOpen={isDeclineOrderReasonDialogShown}
                                            onClose={handleClose}
                                            content={
                                                <>
                                                    <CancelButton
                                                        className="cancel-button"
                                                        onClick={handleClose}
                                                        name="cross-button"
                                                    >
                                                        <ClearIcon fontSize="large" color="primary" />
                                                    </CancelButton>
                                                    <RejectOrderConfirmation
                                                        maxLength={500}
                                                        onChangeMessage={(message: string) => {
                                                            setRejectOrderMessage(message);
                                                        }}
                                                    />
                                                    <ResponsiveFlexBox justifyContent="flex-end">
                                                        <LoadingIndicator isLoaded={isLoadedUndo} />
                                                        {isLoadedUndo && (
                                                            <StyledButton
                                                                onClick={rejectOrder}
                                                                color="primary"
                                                                variant="outlined"
                                                                name="send-button"
                                                            >
                                                                <Typography variant="h5" className="send-text">
                                                                    {t('order.send')}
                                                                </Typography>
                                                            </StyledButton>
                                                        )}
                                                    </ResponsiveFlexBox>
                                                </>
                                            }
                                            title={t('order.declineReasonstatus')}
                                        />
                                    </ResponsiveFlexBox>
                                )}
                                {/* End: Admin action buttons on moderation */}

                                {/* Start: Admin payment action buttons */}
                                {order?.status == OrderStatusType.Nullified && order.userRole === UserRoles.User && (
                                    <ResponsiveFlexBox
                                        gridRowGap={5}
                                        gridColumnGap={5}
                                        alignItems="center"
                                        justifyContent="start"
                                    >
                                        <Box mr="1.375rem">
                                            <Typography variant="body1" className="refund-title">
                                                {t('order-form.button-text.cancel-payment')}:
                                            </Typography>
                                        </Box>
                                        <SubmitButton
                                            variant="outlined"
                                            onSubmiting={handleFormSubmitting}
                                            onSubmit={handelCancelPayment}
                                            className="order-btn outlined-btn"
                                            name="in-full-btn"
                                        >
                                            {t('order-form.button-text.refund-the-payment-in-full')}
                                        </SubmitButton>
                                        <SubmitButton
                                            variant="outlined"
                                            onSubmiting={handleFormSubmitting}
                                            onSubmit={() => {
                                                setOpenUndo(true);
                                            }}
                                            className="order-btn outlined-btn next-btn"
                                            name="in-part-btn"
                                        >
                                            {t('order-form.button-text.refund-the-payment-in-part')}
                                        </SubmitButton>
                                        <Button
                                            variant="outlined"
                                            onClick={handleGoToPlaybackStatistic}
                                            color="primary"
                                            className="order-btn filled-btn statistic-btn"
                                            disabled={!(count !== undefined && count.realCount > 0)}
                                        >
                                            <Typography variant="h5" className="filled-btn-text">
                                                {t('order.playbackStatistic')}
                                            </Typography>
                                        </Button>
                                        <FlashDialog
                                            open={isUndoOrderReasonDialogShown}
                                            isOpen={isUndoOrderReasonDialogShown}
                                            title={t('order-form.button-text.cancel-payment')}
                                            content={
                                                <>
                                                    <FormControlTextInput
                                                        disabled={isDisabled}
                                                        value={inputCancelPayPartData.toString()}
                                                        color="secondary"
                                                        type="number"
                                                        onBlur={validateValue}
                                                        onChangeValue={(value: string) => {
                                                            setInputCancelPayPartData(value);
                                                        }}
                                                        label={t('order-form.refund-the-payment-in-part-lable')}
                                                        inputProps={{ min: 0, max: costOrder }}
                                                        name="in-part-input"
                                                    />
                                                    <Box display="flex" justifyContent="center" marginTop="1.25rem">
                                                        <StyledButton
                                                            onClick={handelPartialCancelPayment}
                                                            color="primary"
                                                            variant="outlined"
                                                            name="refund-button"
                                                        >
                                                            <Typography variant="h5" className="send-text">
                                                                {t('order-form.button-text.refund')}
                                                            </Typography>
                                                        </StyledButton>
                                                        <StyledButton
                                                            onClick={handleClose}
                                                            variant="outlined"
                                                            name="cancel-button"
                                                        >
                                                            <Typography variant="h5" className="cancel-text">
                                                                {t('order-form.button-text.cancel')}
                                                            </Typography>
                                                        </StyledButton>
                                                    </Box>
                                                </>
                                            }
                                            onClose={handleClose}
                                        />
                                    </ResponsiveFlexBox>
                                )}
                                {order?.status == OrderStatusType.Nullified && order.userRole !== UserRoles.User && (
                                    <ResponsiveFlexBox
                                        gridRowGap={5}
                                        gridColumnGap={5}
                                        alignItems="center"
                                        justifyContent="start"
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={handleGoToPlaybackStatistic}
                                            color="primary"
                                            className="order-btn filled-btn statistic-btn"
                                            disabled={!(count !== undefined && count.realCount > 0)}
                                        >
                                            <Typography variant="h5" className="filled-btn-text">
                                                {t('order.playbackStatistic')}
                                            </Typography>
                                        </Button>
                                    </ResponsiveFlexBox>
                                )}
                                {order?.status == OrderStatusType.PaymentRefunded && (
                                    <ResponsiveFlexBox
                                        gridRowGap={5}
                                        gridColumnGap={5}
                                        alignItems="center"
                                        justifyContent="start"
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={handleGoToPlaybackStatistic}
                                            color="primary"
                                            className="order-btn filled-btn statistic-btn"
                                            disabled={!(count !== undefined && count.realCount > 0)}
                                        >
                                            <Typography variant="h5" className="filled-btn-text">
                                                {t('order.playbackStatistic')}
                                            </Typography>
                                        </Button>
                                    </ResponsiveFlexBox>
                                )}
                                {order?.status == OrderStatusType.PaymentRefundFailed && (
                                    <>
                                        <ResponsiveFlexBox
                                            gridRowGap={5}
                                            gridColumnGap={5}
                                            alignItems="center"
                                            justifyContent="start"
                                        >
                                            <Button
                                                variant="outlined"
                                                onClick={handleGoToPlaybackStatistic}
                                                color="primary"
                                                className="order-btn filled-btn statistic-btn"
                                                disabled={!(count !== undefined && count.realCount > 0)}
                                            >
                                                <Typography variant="h5" className="filled-btn-text">
                                                    {t('order.playbackStatistic')}
                                                </Typography>
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                onClick={handelCancelPayment}
                                                className="order-btn outlined-btn"
                                                name="cancel-btn"
                                            >
                                                <Typography variant="h5">
                                                    {t('order-form.button-text.cancel-payment')}
                                                </Typography>
                                            </Button>
                                        </ResponsiveFlexBox>
                                    </>
                                )}
                                {/* End: Admin payment action buttons */}
                            </>
                        )}

                        {/* Start: Action buttons after paid */}
                        {(isCancellable || order?.status === OrderStatusType.Completed) && (
                            <ResponsiveFlexBox
                                gridRowGap={5}
                                gridColumnGap={5}
                                alignItems="center"
                                justifyContent="start"
                            >
                                <Button
                                    variant="outlined"
                                    onClick={handleGoToPlaybackStatistic}
                                    color="primary"
                                    className="order-btn filled-btn statistic-btn"
                                    disabled={!(count !== undefined && count.realCount > 0)}
                                >
                                    <Typography variant="h5" className="filled-btn-text">
                                        {t('order.playbackStatistic')}
                                    </Typography>
                                </Button>
                                {isCancellable && isOperatorView && (
                                    <>
                                        {!isLoadedUndo && (
                                            <Box ml="1.375rem">
                                                <LoadingIndicator isLoaded={isLoadedUndo} />
                                            </Box>
                                        )}
                                        {isLoadedUndo && (
                                            <SubmitButton
                                                onSubmiting={handleFormSubmitting}
                                                onSubmit={() => {
                                                    setOpenUndo(true);
                                                }}
                                                variant="outlined"
                                                color="primary"
                                                className="order-btn outlined-btn"
                                                name="undo-btn"
                                            >
                                                {t('order-form.button-text.cancel')}
                                            </SubmitButton>
                                        )}
                                        <FlashDialog
                                            position="relative"
                                            width="46.563rem"
                                            open={isUndoOrderReasonDialogShown}
                                            isOpen={isUndoOrderReasonDialogShown}
                                            onClose={handleClose}
                                            content={
                                                <>
                                                    <CancelButton
                                                        className="cancel-button"
                                                        onClick={handleClose}
                                                        name="cross-button"
                                                    >
                                                        <ClearIcon fontSize="large" color="primary" />
                                                    </CancelButton>
                                                    <RejectOrderConfirmation
                                                        maxLength={500}
                                                        onChangeMessage={(message: string) => {
                                                            setUndoOrderMessage(message);
                                                        }}
                                                    />
                                                    <ResponsiveFlexBox justifyContent="flex-end">
                                                        <StyledButton
                                                            onClick={undoOrder}
                                                            color="primary"
                                                            variant="outlined"
                                                            name="send-button"
                                                        >
                                                            <Typography variant="h5" className="send-text">
                                                                {t('order.send')}
                                                            </Typography>
                                                        </StyledButton>
                                                    </ResponsiveFlexBox>
                                                </>
                                            }
                                            title={t('order.undoReasonstatus')}
                                        />
                                    </>
                                )}
                                {isCancellable && !isOperatorView && (
                                    <>
                                        <SubmitButton
                                            onSubmiting={handleFormSubmitting}
                                            onSubmit={() => {
                                                setModalToShow(true);
                                            }}
                                            variant="outlined"
                                            color="primary"
                                            className="order-btn outlined-btn"
                                            name="undo-btn"
                                        >
                                            {t('order-form.button-text.cancel')}
                                        </SubmitButton>
                                        <FlashDialog
                                            open={isResetFormDialogShown}
                                            isOpen={isResetFormDialogShown}
                                            title={t('order-form.rejectOrder')}
                                            content={<TextContent text={t('order-form.dialogToRejectOrderShort')} />}
                                            controls={
                                                <YesNoButtons
                                                    onConfirm={async () => {
                                                        await onChangeStatus(OrderStatusType.Nullified, '');
                                                        setModalToShow(false);
                                                    }}
                                                    onReject={handleNegative}
                                                />
                                            }
                                            onClose={handleNegative}
                                        />
                                    </>
                                )}
                            </ResponsiveFlexBox>
                        )}
                        {/* End: Action buttons after paid */}
                    </>
                </OrderInformation>
                {isOperatorView && order?.orderHistory.length && <OrderHistoryTable order={order}></OrderHistoryTable>}
                <OrderInfoTable order={order} isOperatorView={isOperatorView} />
                {!isOperatorView && (
                    <Order>
                        <img src={orderBackground} alt="" />
                    </Order>
                )}
            </OrderFormStyle>
        </>
    );
}
