import axios, { AxiosInstance } from 'axios';
import i18next from 'i18next';
import moment from 'moment';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface IException {
    ErrorMessage: string;
}

const instance: AxiosInstance = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
    },
});

export const setUpInterceptor = (store: any) => {
    instance.interceptors.request.use(async (req) => {
        const state = store.getState();
        const auth = state.authReducer.auth;
        if (auth?.accessToken) {
            req.headers.Authorization = `Bearer ${auth.accessToken}`;
        }
        return req;
    });

    instance.interceptors.response.use(
        async ({ data: response }) => {
            return response;
        },
        (error) => {
            const exceptions = error.response?.data?.userMessage;
            if (exceptions) {
                try {
                    const exceptionsJson = JSON.parse(exceptions);
                    exceptionsJson.forEach((e: IException) => {
                        const translationIsExist = i18next.t(e.ErrorMessage) !== e.ErrorMessage;
                        toast.error(translationIsExist ? i18next.t(e.ErrorMessage) : e.ErrorMessage);
                    });
                } catch (e) {
                    const translationIsExist = i18next.t(exceptions) !== exceptions;
                    toast.error(translationIsExist ? i18next.t(exceptions) : exceptions);
                }
            } else if (error.response) {
                toast.error(error.response.data?.userMessage);
            } else {
                toast.error(i18next.t('errors.general'));
            }
            return Promise.reject(error);
        },
    );
};

export const toValidGetDate = (date?: Date | string | null): string | null => {
    let result: string | null = null;
    if (date) {
        result = moment(date).format('YYYY-MM-DDTHH:mm');
    }
    return result;
};

export const arrayToQuery = (key: string, array: string[]): string => {
    const keyArray = array.map((val) => `${key}=${val}`);
    return keyArray.join('&');
};

export default instance;
