import { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import PricesOrganism from 'components/organisms/Prices';
import PricesDataGrid from 'components/organisms/PricesDataGrid';
import PriceFilter from 'components/atoms/gridFilters/PriceFilter';
import FilteredTemplate from 'components/templates/FilteredTemplate';

import useSelector from 'hooks/useAppSelector';

import allRoutes from 'router';

import { getMediaSpaces } from 'api/media-space';
import { MediaSpaceVm } from 'models/media-space.model';
import { RootState } from '../../../store/rootReducer';
import { UserRoles } from '../../../shared/enums';

export default function PricesTemplate(): JSX.Element {
    const { t } = useTranslation();

    const filters = useSelector((state) => state.priceFilterReducer.price);

    const [allMediaSpaces, setAllMediaSpaces] = useState(Array<MediaSpaceVm>());
    const [resourceTimeline, setResourceTimeline] = useState<string | undefined>(undefined);
    const user = useSelector((state: RootState) => state.userReducer.user);
    const [isOperator, setIsOperator] = useState<boolean>(false);

    const fetchData = async () => {
        const response = await getMediaSpaces();
        setAllMediaSpaces(response.data);
    };

    useEffect(() => {
        setTimeout(() => fetchData(), 600);
    }, [user.lc]);

    useEffect(() => {
        setIsOperator(user.role === UserRoles.Operator);
        setTimeout(() => fetchData(), 600);
    }, []);

    return (
        <>
            <FilteredTemplate
                header={t('cost.header')}
                createItemPageRoute={isOperator ? undefined : allRoutes.createPrice}
                filter={
                    <PriceFilter
                        allMediaSpaces={allMediaSpaces}
                        resourceTimeline={resourceTimeline}
                        tableCalendar={filters.tableCalendar}
                    />
                }
                content={
                    <>
                        {filters.tableCalendar ? (
                            <PricesOrganism allMediaSpaces={allMediaSpaces} setResourceTimeline={setResourceTimeline} />
                        ) : (
                            <PricesDataGrid />
                        )}
                    </>
                }
            />
        </>
    );
}
