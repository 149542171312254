import * as React from 'react';
import classNames from 'classnames';
import Header from '../../templates/Header';
import { Switch, Link as LinkRouter, useLocation } from 'react-router-dom';
import allRoutes, { FlashRoutes, AccessMode } from '../../../router';
import FlashMediaRoute from '../../organisms/routes/FlashMediaRoute';
import { CssBaseline, Link, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SideMenu from '../../organisms/menu/SideMenu';
import { RootState } from '../../../store/rootReducer';
import { StyledPage, WhatsAppButtonContainer } from './style';
import { NotificationProvider } from '../../organisms/NotificationProvider';
import Footer from '../../../components/templates/Footer';
import { UserRoles } from '../../../shared/enums';
import IconButton from 'components/atoms/controls/IconButton';
import { ReactComponent as WhatsAppIcon } from 'assets/icons/whatsapp-icon.svg';
import { getConfig } from '../../../config';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';

const showSideMenuForRoles = [UserRoles.Admin.toString(), UserRoles.Operator.toString()];

export default function SignedInPage(): JSX.Element {
    const [isConfigLoaded, setConfigLoaded] = React.useState(false);
    const [whatsAppLink, setWhatsAppLink] = React.useState<string>('#');

    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        if (!isConfigLoaded) {
            const loadConfig = async () => {
                const configData = await getConfig();
                setConfigLoaded(true);
                setWhatsAppLink(`${configData.whatsAppLink}${configData.supportTeamPhone}`);
            };

            loadConfig();
        }
    });

    const user = useSelector((state: RootState) => state.userReducer.user);

    const location = useLocation();
    const routeInfo =
        allRoutes[
            Object.keys(allRoutes).find(
                (x) => allRoutes[x as keyof FlashRoutes]?.path == location.pathname,
            ) as keyof FlashRoutes
        ] ?? {};

    const className = classNames({
        login: routeInfo.access === AccessMode.Anonymous,
        protection: !user.nameid && (location.pathname == '/privacy' || location.pathname == '/terms'),
        home: routeInfo.access === AccessMode.Both,
        auth: location.pathname.startsWith('/auth/forgot-password/'),
    });

    const renderSideMenu = (): JSX.Element | null => {
        if (showSideMenuForRoles.indexOf(user.role) >= 0) {
            return <SideMenu />;
        } else {
            return null;
        }
    };

    return (
        <NotificationProvider user={user}>
            <div>
                <StyledPage className={className}>
                    <CssBaseline />
                    <Header />
                    {renderSideMenu()}

                    <Switch>
                        {Object.keys(allRoutes).map((key) => (
                            <FlashMediaRoute
                                key={allRoutes[key as keyof FlashRoutes].path}
                                exact
                                route={allRoutes[key as keyof FlashRoutes]}
                                path={allRoutes[key as keyof FlashRoutes].path}
                                component={allRoutes[key as keyof FlashRoutes].component}
                            />
                        ))}
                    </Switch>
                    <CookieConsent
                        disableStyles={true}
                        location="bottom"
                        buttonClasses="cookie-accept-btn"
                        containerClasses="cookie-container"
                        contentClasses="cookie-content"
                        buttonText={t('general.cookie-accept').toUpperCase()}
                    >
                        <Typography>{t('general.cookies-notification')}</Typography>
                        <Typography>
                            {t('general.cookies-notification-with-policy')}&nbsp;
                            <LinkRouter
                                to={`/documents/privacy_policy_${i18n.language}.html`}
                                target="_blank"
                                className="privacy-policy"
                                data-id="privacy-policy"
                            >
                                {t('general.cookies-privacy-policy').toLowerCase()}
                            </LinkRouter>
                        </Typography>
                    </CookieConsent>
                    {whatsAppLink && (
                        <WhatsAppButtonContainer>
                            <Link href={whatsAppLink} target="_blank" id="SEO-whatsapp-overlay">
                                <IconButton color="primary">
                                    <WhatsAppIcon width="48" height="48"></WhatsAppIcon>
                                </IconButton>
                            </Link>
                        </WhatsAppButtonContainer>
                    )}
                </StyledPage>
                <Footer />
            </div>
        </NotificationProvider>
    );
}
