import styled from 'styled-components';

import { Button, Typography } from '@material-ui/core';

import { variables } from 'theme/variables';

export const StyledMultiStepForm = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    paddingTop: 40,
    marginBottom: 40,
});

export const MultiStepContent = styled.div({
    flexDirection: 'column',
    flex: 1,
    maxWidth: '85%',

    '@media(max-width: 767px)': {
        maxWidth: '100%',
        padding: '20px',
    },
});

export const MultiStepHeader = styled.div({
    display: 'flex',
    margin: 10,
    marginBottom: 28,
});

export const MultiStepIcon = styled.svg({
    height: 40,
    width: 40,
    minWidth: 40,
    minHeight: 40,
    marginRight: 20,
});

export const MultiStepTitle = styled(Typography).attrs({
    component: 'h4',
    variant: 'h4',
})({
    fontSize: 18,
    color: variables.colours.dark,
    marginTop: 10,
});

export const MultiStepBackButton = styled(Button)({
    position: 'absolute',
    left: 0,
    top: -10,
    margin: '-5px -10px',
    display: 'inline-flex',
    padding: '5px 10px',
    color: variables.backgoroundColours.border,
    textTransform: 'uppercase',
    transition: '.2s ease',

    '.MuiTouchRipple-child': {
        backgroundColor: variables.colours.primary,
    },
});

export const MultiStepBackIcon = styled.svg({
    marginRight: 20,
});
