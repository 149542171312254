import styled from 'styled-components';

import { Form } from 'formik';

import { StyledMapContainer } from 'components/organisms/FlashMap/style';

import { variables } from 'theme/variables';

export const StyledMediaSpaceSubForm = styled(Form)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',

    [StyledMapContainer]: {
        height: '100%',
        width: '100%',
        alignSelf: 'center',
        '@media(max-width: 767px)': {
            height: 400,
            // width: 200,
        },
    },

    '& > div:first-child': {
        width: '100%',
    },
});

export const MediaSpaceBlock = styled.div({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '0 auto',
    minWidth: 200,
    justifyContent: 'center',

    '.continue-button': {
        width: 'fit-content',
        border: `2px solid ${variables.colours.primary}`,
        h5: {
            color: variables.colours.light,
        },
        ':hover': {
            h5: {
                color: variables.colours.primary,
            },
        },
    },

    '@media(max-width: 768px)': {
        minWidth: '100%',
        '.continue-button': {
            margin: '1.25rem auto 0',
        },
    },
});
