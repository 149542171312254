import * as React from 'react';
import UserCreateEditTemplate from '../../../components/templates/UserCreateEditTemplate';
import { RootState } from '../../../store/rootReducer';
import { useSelector } from 'react-redux';
import { UserRoles } from '../../../shared/enums';

export default function UserProfile(): JSX.Element {
    const currentUser = useSelector((state: RootState) => state.userReducer.user);
    return (
        <>
            <UserCreateEditTemplate hasDeleteAction={currentUser.role === UserRoles.Admin} />
        </>
    );
}
