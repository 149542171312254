/* eslint-disable prettier/prettier */
import { FC, Fragment, useMemo, useCallback, useState, useEffect } from 'react';

import { pick, without, cloneDeep, uniq } from 'lodash';
import moment from 'moment';
import { ValidationError } from 'yup';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';

import { Formik, useFormikContext, FieldArray } from 'formik';

import ScheduleMicroForm from 'components/organisms/ScheduleMicroForm';
import { FormStepComponent } from 'components/organisms/MultiStepForm/types';

import { SCHEDULES_LIMIT, scheduleInitialValues, validationSchema } from 'components/organisms/DateSubForm/constants';
import { generateRange } from 'shared/utils';

import { DateFormValues } from 'components/organisms/DateSubForm/types';
import { OrderFormValues } from 'components/organisms/forms/Order';

import { StyledDateSubForm, FormActions, ActionButton, AddTimeText } from 'components/organisms/DateSubForm/style';

import { ReactComponent as AddIcon } from 'assets/icons/AddCircle.svg';
import { OrderScheduleModel } from 'models/order';
import { useDispatch } from 'react-redux';
import { deleteMediaSpacesForm, setAllMediaSpaces } from '../../../store/form/actions';
import { getConfig } from '../../../config';

interface DateSubFormProps {
    outerValues: OrderFormValues;
}

const DateSubForm: FC<DateSubFormProps> = ({ outerValues }) => {
    const { t } = useTranslation();

    const { values, submitForm, setValues } = useFormikContext<DateFormValues>();
    const [isFictitiousMode, setIsFictitiousMode] = useState<boolean>(false);
    const [adminStartDateRangeInHours, setAdminStartDateRangeInHours] = useState<number>(2);

    const dispatch = useDispatch();

    const addSchedule = useCallback(() => {
        setValues({
            ...values,
            schedules: [...values.schedules, scheduleInitialValues],
        });
    }, [values, setValues]);

    useEffect(() => {
        const loadConfig = async () => {
            const data = await getConfig();
            setIsFictitiousMode(data.fictitiousMode);
            setAdminStartDateRangeInHours(data.adminStartDateRangeInHours);
        };

        loadConfig();
    }, []);

    const submitFormHandler = useCallback(() => {
        const isValidSheduleModelsTimeStart = values.schedules.every((model) => {
            if (!moment().isSame(model.startDate, 'day')) return true;
            if (model.startTime === undefined || model.endDate === undefined) return true;
            return parseInt(model.startTime.slice(0, 2)) >= parseInt(moment().format('HH')) + (isFictitiousMode ? 0 : adminStartDateRangeInHours);
        });

        if (isValidSheduleModelsTimeStart) {
            dispatch(deleteMediaSpacesForm());
            dispatch(setAllMediaSpaces(false));
            return submitForm();
        }
        toast.error(t('order-form.messages.error-wrong-time'));
    }, [values]);

    const removeSchedule = useCallback(
        (removeIndex: number) => {
            setValues({
                ...values,
                schedules: [...values.schedules].filter((_, index) => index !== removeIndex),
            });
        },
        [values, setValues],
    );

    const updateSchedules = (updateSchedulesCallback: (values: OrderScheduleModel[]) => OrderScheduleModel[]) => {
        const newSchedules = updateSchedulesCallback(cloneDeep(values.schedules));

        setValues({
            ...values,
            schedules: newSchedules,
        });
    };

    return (
        <StyledDateSubForm>
            <FieldArray name="schedules">
                {() => (
                    <div>
                        {values.schedules?.map((currentSchedule, index) => {
                            const filledDates: Date[] = values.schedules.reduce((dates, schedule, scheduleIndex) => {
                                if (scheduleIndex === index) return dates;

                                const currentStartTime = values.schedules[index].startTime;
                                const currentEndTime = values.schedules[index].endTime;

                                const scheduleStartTime = schedule.startTime;
                                const scheduleEndTime = schedule.endTime;

                                if (!(currentStartTime && currentEndTime && scheduleStartTime && scheduleEndTime))
                                    return dates;

                                const isOverlapping =
                                    currentStartTime < scheduleEndTime && currentEndTime > scheduleStartTime;

                                if (!isOverlapping) return [];

                                const scheduleRangedDates = generateRange(schedule.startDate, schedule.endDate, true);

                                const rangedDates = without(scheduleRangedDates, ...(schedule.excludeDates ?? [])).map(
                                    (date) => {
                                        return typeof date === 'string' ? moment(date).toDate() : date;
                                    },
                                );

                                return [...dates, ...rangedDates];
                            }, [] as Date[]);

                            return (
                                <Fragment key={index}>
                                    <ScheduleMicroForm
                                        name={`schedules[${index}]`}
                                        onRemove={() => removeSchedule(index)}
                                        removable={values.schedules.length > 1}
                                        outerValues={outerValues}
                                        updateSchedules={updateSchedules}
                                        disabledDays={filledDates}
                                        scheduleIndex={index}
                                    />
                                    {index === values.schedules.length - 1 && (
                                        <FormActions>
                                            <ActionButton
                                                variant="outlined"
                                                onClick={submitFormHandler}
                                                color="primary"
                                                id="SEO-createorder-in-orders-page-step-beforemoderation"
                                            >
                                                {t('order-form.button-text.calculate-the-cost')}
                                            </ActionButton>
                                            {values.schedules.length < SCHEDULES_LIMIT && (
                                                <ActionButton $primary onClick={addSchedule}>
                                                    <AddTimeText>{t('order-form.button-text.add-time')}</AddTimeText>
                                                    <AddIcon />
                                                </ActionButton>
                                            )}
                                        </FormActions>
                                    )}
                                </Fragment>
                            );
                        })}
                    </div>
                )}
            </FieldArray>
        </StyledDateSubForm>
    );
};

const DateSubFormContainer: FormStepComponent = ({ onSubmit }) => {
    const { values: outerValues } = useFormikContext<OrderFormValues>();

    const initialValues = useMemo(() => pick(outerValues, 'schedules'), [outerValues]);

    const handleSubmit = (values: DateFormValues) => {
        validationSchema()
            .validate(values, { abortEarly: false })
            .then(() => onSubmit(values))
            .catch((error: ValidationError) => {
                console.log(error);
                const errors: string[] = uniq(error.inner?.map(({ errors }) => errors).flat());

                errors.forEach((err: string) => toast.error(err));
            });
    };

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <DateSubForm outerValues={outerValues} />
        </Formik>
    );
};

export default DateSubFormContainer;
