import styled from 'styled-components';

import { variables } from 'theme/variables';

export const StyledMenuButton = styled.div(({}) => ({
    display: 'block',
    width: '100%',
    height: '3.75rem',
    paddingLeft: 5,

    ':hover': {
        transition: 'background-color 1s',
        backgroundColor: '#000',
        borderLeft: `5px solid ${variables.colours.primary}`,
        paddingLeft: '0',
    },

    '.MuiListItemText-root': {
        margin: 0,
    },

    '.MuiListItem-root': {
        paddingLeft: 15,
        height: '100%',
    },

    '.MuiButton-textPrimary.MuiButton-fullWidth': {
        width: '100%',
        borderRadius: 0,
        justifyContent: 'flex-start',
        height: '2.5rem',
        padding: '0 0.938rem',
    },

    '.item-label': {
        color: variables.colours.main,

        '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
            fontSize: '1rem',
        },
    },

    '.attention': {
        width: '1.25rem',
        height: '1.25rem',
        position: 'absolute',
        right: '10px',
    },

    '.menu-item': {
        position: 'relative',
    },
    '.reduced-item': {
        width: '16px',
        height: '16px',
        bottom: '1px',
        left: '5px',
        '@media (max-width: 1431px)': {
            bottom: '10px',
        },
    },
}));
