import React, { useEffect } from 'react';
import ResponsiveFlexBox from '../../../components/atoms/ResponsiveFlexBox';
import ChatForm from '../../organisms/forms/ChatForm';
import UserChats from '../../../components/organisms/UserChats';
import { ChatTemplateStyle } from './style';
import { ChatMessageVm } from '../../../models/chat-message-model';
import { useState } from 'react';
import FilterInput from '../../atoms/FilterInput';
import { ArrowBackIos } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import NavigationLink from '../../molecules/NavigationLink';
import { Box } from '@material-ui/core';
import useWindowDimensions from '../../../hooks/WindowDimensions';

export default function ChatTemplate(): JSX.Element | null {
    const [lastMessage, setLastMessage] = useState<ChatMessageVm>();
    const [isFilter, setFilter] = useState<string>('');
    const [mobileChat, setMobileChat] = useState(false);
    const history = useHistory();

    const mobileChatMaxWidth = 767;

    const onNewMessage = (message: ChatMessageVm) => {
        setLastMessage(message);
    };

    const { width } = useWindowDimensions();

    useEffect(() => {
        if (width < mobileChatMaxWidth) {
            setMobileChat(true);
        }
    }, []);

    useEffect(() => {
        if (width > mobileChatMaxWidth) {
            setMobileChat(false);
        }
    }, [width]);

    return (
        <>
            <ResponsiveFlexBox marginBottom="0.625rem">
                <NavigationLink
                    startIcon={<ArrowBackIos color="disabled" />}
                    onClick={() => {
                        mobileChat ? setMobileChat(false) : history.goBack();
                    }}
                    className="chat-go-back"
                    name="go-back"
                />
                <FilterInput onChange={setFilter} />
            </ResponsiveFlexBox>
            <ChatTemplateStyle>
                {width > mobileChatMaxWidth && (
                    <Box display="flex" width="100%">
                        <UserChats
                            isFilter={isFilter}
                            lastMessage={lastMessage}
                            onNewMessage={onNewMessage}
                            setMobileChat={setMobileChat}
                            mobileChat={mobileChat}
                        />
                        <ChatForm onNewMessage={onNewMessage} />
                    </Box>
                )}
                {width < mobileChatMaxWidth && (
                    <Box display="flex" width="100%">
                        <UserChats
                            isFilter={isFilter}
                            lastMessage={lastMessage}
                            onNewMessage={onNewMessage}
                            setMobileChat={setMobileChat}
                            mobileChat={mobileChat}
                        />
                    </Box>
                )}
            </ChatTemplateStyle>
        </>
    );
}
