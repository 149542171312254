import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilteredTemplate from 'components/templates/FilteredTemplate';
import PagedDataGrid from 'components/organisms/PagedDataGrid';
import { CurrencyBaseVm } from 'models/currency.model';
import { GridColDef, GridSortModel } from '@material-ui/data-grid';
import CurrencyColumnsGrid from './ColumnsGrid';
import { currenciesAPI } from 'api/currency';
import FlashDialog from 'components/atoms/dialog/FlashDialog';
import TextContent from 'components/atoms/dialog/TextContent';
import YesNoButtons from 'components/atoms/dialog/YesNoButtons';
import { toast } from 'react-toastify';
import allRoutes from 'router';
import { PageSize } from 'shared/constants';
import { CurrencyPage } from 'models/currency.model';
import { useParams } from 'react-router-dom';
import NoDataMessage from 'components/atoms/NoDataMessage';
import { CurrencyGridStyled } from './style';
import useSelector from '../../../hooks/useAppSelector';
import { getSortingParams } from '../../../utils/sortingHelper';
import { SortDirections, SortField, SortModel } from '../../../models/paging/paging.model';

const CurrenciesGrid: FC = () => {
    const defaultSort: SortModel = {
        property: SortField.CreatedDate,
        direction: SortDirections.Asc,
    };

    const { t } = useTranslation();
    const { pageId } = useParams<{ pageId: string }>();
    const user = useSelector((state) => state.userReducer.user);

    const [loading, setLoading] = useState(false);
    const [currencies, setCurrencies] = useState<Array<CurrencyBaseVm>>([]);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [currencyId, setCurrencyId] = useState<string>('');
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [sort, setSort] = useState<SortModel>(defaultSort);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);

    const columns: GridColDef[] = CurrencyColumnsGrid({
        currencies,
        setDeleteDialogOpen,
        setCurrencyId,
    });

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };

    const deleteCurrency = async () => {
        await currenciesAPI.deleteById(currencyId);
        toast.success(t('currency-form.messages.success-delete'));
        await loadData();
        handleCloseDeleteDialog();
    };

    const getReq = () => {
        const req: CurrencyPage = {
            page: page,
            pageSize: PageSize,
            property: sort?.property,
            direction: sort?.direction,
        };
        return req;
    };

    const loadData = useCallback(async () => {
        const req = getReq();
        await getCurrencies(req);
    }, [page, sort.property, sort.direction, PageSize]);

    const getCurrencies = async (req: CurrencyPage) => {
        const res = await currenciesAPI.getPaged(req);
        const totalPageNumber = Math.ceil(res.total / res.pageSize);

        setCurrencies(res.data);
        setTotalPage(totalPageNumber);
        setTotalItems(res.total);
    };

    useEffect(() => {
        setLoading(true);
        (async () => {
            await loadData();
            setLoading(false);
        })();
    }, [page, user.lc, sort]);

    useEffect(() => {
        if (pageId) {
            const page = Number(pageId);
            setPage(page);
        }
    }, [pageId]);

    const handleSorting = (model: GridSortModel) => {
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
            const sort = getSortingParams(model, defaultSort.property, defaultSort.direction);
            setSort(sort);
        }
    };

    return (
        <CurrencyGridStyled>
            <FilteredTemplate
                header={t('currencies.header')}
                createItemPageRoute={allRoutes.createCurrency}
                filter={<></>}
                content={
                    <PagedDataGrid
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={(model: GridSortModel) => {
                            handleSorting(model);
                        }}
                        rows={currencies}
                        columns={columns}
                        defaultPage={page}
                        totalPages={totalPage}
                        totalItems={totalItems}
                        isDataLoaded={!loading}
                        noDataMessage={<NoDataMessage />}
                        isLoadingIndicator={!loading}
                        filterMode="server"
                        customPagination={true}
                        disableColumnSelector={true}
                    />
                }
            />
            <FlashDialog
                open={deleteDialogOpen}
                title={t('currency-form.delete-currency')}
                isOpen={deleteDialogOpen}
                onClose={handleCloseDeleteDialog}
                content={<TextContent text={t('currency-form.contentText')} />}
                controls={<YesNoButtons onConfirm={deleteCurrency} onReject={handleCloseDeleteDialog} />}
            />
        </CurrencyGridStyled>
    );
};

export default function CurrenciesPage(): JSX.Element {
    return <CurrenciesGrid />;
}
