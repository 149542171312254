import { DeviceStatusEnum } from 'components/atoms/gridFilters/DeviceStatusSelect';
import { REMOVE_FILTER, UPDATE_DEVICE_FILTER, FilterActionTypes, FilterState } from 'store/deviceFilter/types';

const InitialState: FilterState = {
    device: {
        filterString: '',
        status: DeviceStatusEnum.all,
        selectedMediaSpaces: [],
    },
};

const deviceFilterReducer = (state = InitialState, action: FilterActionTypes): FilterState => {
    switch (action.type) {
        case UPDATE_DEVICE_FILTER: {
            return {
                ...state,
                device: {
                    ...state.device,
                    filterString: action.data.filterString,
                    selectedMediaSpaces: action.data.selectedMediaSpaces,
                    status: action.data.status,
                },
            };
        }
        case REMOVE_FILTER: {
            return {
                ...state,
                device: {
                    ...state.device,
                    ...InitialState.device,
                },
            };
        }
        default:
            return state;
    }
};

export default deviceFilterReducer;
