import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import PhoneInput from 'material-ui-phone-number';
import CreateIcon from '@material-ui/icons/Create';

import useWindowDimensions from 'hooks/WindowDimensions';
import { unmaskPhone } from 'shared/utils';

import { UserModel } from 'models/user-model';

import {
    StyledBox,
    IconButton,
    UserNameBox,
    PasswordInput,
    StyledUserInfo,
    BirthdayPicker,
    PasswordHolder,
    StyledTextInput,
    PhoneWrapper,
    StyledProfileSelect,
} from 'components/molecules/User/UserInfo/style';
import { MenuItem, Select, Typography } from '@material-ui/core';
import { CurrencyVm } from 'models/currency.model';
import { LanguageVm } from 'models/language.model';
import { currenciesAPI } from 'api/currency';
import { getLanguages } from 'api/language';

export interface UserInfoProps {
    disabled?: boolean;
    isDisabled: boolean;
    values: UserModel;
    setFieldValue: FormikHelpers<UserModel>['setFieldValue'];
    errors: FormikErrors<UserModel>;
    touched: FormikTouched<UserModel>;
}

const UserInfo: FC<UserInfoProps> = ({ disabled, isDisabled, values, setFieldValue, errors, touched }) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState<boolean>(false);
    const [currencyList, setCurrencyList] = useState<CurrencyVm[]>([]);
    const [languages, setLanguages] = useState<LanguageVm[]>([]);

    useEffect(() => {
        async function fetchData() {
            const response = await currenciesAPI.getCurrencies();
            setCurrencyList(response.data);
        }
        fetchData();
    }, []);

    useEffect(() => {
        let isMounted = true;
        const fetchData = async () => {
            const response = await getLanguages();
            if (isMounted) setLanguages(response.data);
        };
        fetchData();
        return () => {
            isMounted = false;
        };
    }, []);

    const handleChangePassword = () => {
        setIsConfirmPasswordShown(!isConfirmPasswordShown);
    };

    return (
        <>
            <StyledUserInfo $disabled={disabled}>
                <UserNameBox>
                    <StyledBox width={width}>
                        <StyledTextInput
                            name="first-name"
                            label={t('user-form.firstName')}
                            disabled={isDisabled}
                            value={values.firstName}
                            error={touched.firstName && Boolean(errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                            onChangeValue={(value: string) => {
                                setFieldValue('firstName', value);
                            }}
                        />
                    </StyledBox>
                    <StyledBox width={width}>
                        <StyledTextInput
                            name="last-name"
                            label={t('user-form.lastName')}
                            disabled={isDisabled}
                            value={values.lastName}
                            error={touched.lastName && Boolean(errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                            onChangeValue={(value: string) => {
                                setFieldValue('lastName', value);
                            }}
                        />
                    </StyledBox>
                </UserNameBox>
                <StyledTextInput
                    name="user-name"
                    label={t('user-form.name')}
                    disabled={isDisabled}
                    value={values.userName}
                    error={touched.userName && Boolean(errors.userName)}
                    helperText={touched.userName && errors.userName}
                    onChangeValue={(value: string) => {
                        setFieldValue('userName', value);
                    }}
                />
                <StyledTextInput
                    name="email"
                    label={t('user-form.email')}
                    disabled={isDisabled}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    onChangeValue={(value: string) => {
                        setFieldValue('email', value);
                    }}
                />
                <PhoneWrapper>
                    {/* WARNING: Package is patched, to see its behavior, check frontend/patches */}
                    <PhoneInput
                        color="secondary"
                        onChange={(value: string) => setFieldValue('phoneNumber', unmaskPhone(value))}
                        value={values.phoneNumber}
                        defaultCountry="ru"
                        name="phone"
                        autoComplete="tel-national"
                        label={t('registration-form.phonenumber-label')}
                        disableAreaCodes
                        autoFormat={!!values.phoneNumber}
                        placeholder="+7 (999) 888 77 66"
                        disabled={isDisabled}
                        error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                        helperText={touched.phoneNumber && errors.phoneNumber}
                        disableDropdown={isDisabled}
                    />
                </PhoneWrapper>
                <UserNameBox>
                    {languages.length > 1 && (
                        <StyledBox width={width}>
                            <StyledProfileSelect>
                                <Typography variant="body1" className="label">
                                    {t('registration-form.language')}
                                </Typography>
                                <Select
                                    disabled={disabled}
                                    color="secondary"
                                    fullWidth
                                    value={values.locale}
                                    onChange={(event) => {
                                        setFieldValue('locale', event.target.value);
                                    }}
                                    name="select-language"
                                >
                                    {languages.map((item, index) => (
                                        <MenuItem key={index} value={item.code} data-id={item.code}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </StyledProfileSelect>
                        </StyledBox>
                    )}
                    {currencyList.length > 1 && (
                        <StyledBox width={width}>
                            <StyledProfileSelect>
                                <Typography variant="body1" className="label">
                                    {t('registration-form.currency')}
                                </Typography>
                                <Select
                                    disabled={disabled}
                                    color="secondary"
                                    fullWidth
                                    value={values.preferredCurrencyId}
                                    onChange={(event) => {
                                        setFieldValue('preferredCurrencyId', event.target.value);
                                    }}
                                    name="select-currency"
                                >
                                    {currencyList.map((x) => (
                                        <MenuItem
                                            value={x.id}
                                            data-id={x.id}
                                            key={x.id}
                                        >{`${x.name} (${x.code})`}</MenuItem>
                                    ))}
                                </Select>
                            </StyledProfileSelect>
                        </StyledBox>
                    )}
                </UserNameBox>
                <BirthdayPicker
                    label={t('user-form.dateOfBirth')}
                    disabled={isDisabled}
                    currentdt={values.birthday}
                    onChangeValue={(value) => setFieldValue('birthday', value)}
                    error={touched.birthday && Boolean(errors.birthday)}
                    helperText={touched.birthday && errors.birthday}
                    majority={true}
                />
            </StyledUserInfo>
            {!window.ENV.config.fictitiousMode && (
                <>
                    {!disabled && (
                        <PasswordHolder>
                            <PasswordInput
                                name="password"
                                classNameIcon="eye-icon"
                                disabled={!isConfirmPasswordShown}
                                label={isConfirmPasswordShown ? t('user-form.changePassword') : t('user-form.password')}
                                value={values.password}
                                showPassword={showPassword}
                                setShowPassword={setShowPassword}
                                isEdit={isConfirmPasswordShown}
                                error={touched.password && Boolean(errors.password)}
                                helperText={touched.password && errors.password}
                                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue('password', value.target.value);
                                }}
                            />
                            <IconButton name="password-eye" onClick={handleChangePassword}>
                                <CreateIcon />
                            </IconButton>
                        </PasswordHolder>
                    )}
                    {isConfirmPasswordShown && (
                        <PasswordInput
                            name="confirm-password"
                            label={t('user-form.passwordConfirm')}
                            value={values.passwordConfirm}
                            showPassword={showPassword}
                            setShowPassword={setShowPassword}
                            error={touched.passwordConfirm && Boolean(errors.passwordConfirm)}
                            helperText={touched.passwordConfirm && errors.passwordConfirm}
                            onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                                setFieldValue('passwordConfirm', value.target.value);
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default UserInfo;
