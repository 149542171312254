import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import FlashDialog from 'components/atoms/dialog/FlashDialog';
import TextContent from 'components/atoms/dialog/TextContent';
import YesNoButtons from 'components/atoms/dialog/YesNoButtons';
import GoBackButton from 'components/atoms/controls/GoBackButton';

import { ReactComponent as EditIcon } from 'assets/icons/EditButton.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteButton.svg';

import {
    ProfileActionsStyle,
    EditButtonLink,
    DeleteButton,
    ButtonText,
} from 'components/molecules/ProfileActions/style';

export type ProfileActionsProps = {
    onDelete?: () => void;
    deleteTitle: string;
    deleteContent: string;
    onEdit?: { pathname: string; state: unknown };
    hasDeleteButton?: boolean;
};

const ProfileActions: FC<ProfileActionsProps> = ({ onDelete, onEdit, hasDeleteButton, deleteTitle, deleteContent }) => {
    const { t } = useTranslation();

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const renderEditButton = () => {
        if (onEdit) {
            return (
                <EditButtonLink to={onEdit} data-id="edit">
                    <EditIcon />
                    <ButtonText>{t('general.button-text.edit')}</ButtonText>
                </EditButtonLink>
            );
        } else {
            return null;
        }
    };
    const renderDeleteButton = () => {
        if (onDelete) {
            return (
                <>
                    <FlashDialog
                        open
                        title={deleteTitle}
                        isOpen={isDeleteDialogOpen}
                        onClose={() => {
                            setIsDeleteDialogOpen(false);
                        }}
                        content={<TextContent text={deleteContent} />}
                        controls={
                            <YesNoButtons
                                onConfirm={onDelete}
                                onReject={() => {
                                    setIsDeleteDialogOpen(false);
                                }}
                            />
                        }
                    />
                    <DeleteButton
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                            setIsDeleteDialogOpen(true);
                        }}
                        name="delete"
                    >
                        <ButtonText>{t('general.button-text.delete')}</ButtonText>
                    </DeleteButton>
                </>
            );
        } else {
            return null;
        }
    };

    return (
        <ProfileActionsStyle>
            <GoBackButton />
            <div className="button-container">
                {renderEditButton()}
                {hasDeleteButton && renderDeleteButton()}
            </div>
        </ProfileActionsStyle>
    );
};

export default ProfileActions;
