import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ResponsiveFlexBox from '../../ResponsiveFlexBox';
import { ReactComponent as Calendar } from '../../../../assets/icons/CalendarFilter.svg';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import FlashDatePicker from '../../FlashDatePicker';
import moment from 'moment';
import TextField from '../../controls/TextField';
import Button from '../../controls/Button';
import { CalendarFilter, DataFilterIcon, HeaderCalendar, LinkStyle, StyledDateFilter } from './style';
import { variables } from '../../../../theme/variables';
import useWindowDimensions from '../../../../hooks/WindowDimensions';

export type DateFilterProps = {
    onFilterChanged: (data: DateFilterData) => void;
    startDate?: Date | null;
    endDate?: Date | null;
};

export type DateFilterData = {
    startDate: Date | null;
    endDate: Date | null;
};

export default function DateFilter(props: DateFilterProps): JSX.Element {
    const { onFilterChanged, startDate, endDate } = props;

    const { t } = useTranslation();
    const [startDateLocal, setStartDateLocal] = useState<Date | null>(startDate ? startDate : null);
    const [endDateLocal, setEndDateLocal] = useState<Date | null>(endDate ? endDate : null);
    const [filter, setFilter] = useState<DateFilterData>({
        startDate: startDate ? startDate : null,
        endDate: endDate ? endDate : null,
    });
    const [anchorEl, setAnchorEl] = useState<boolean>(false);
    const { width } = useWindowDimensions();

    useEffect(() => {
        onFilterChanged && onFilterChanged(filter);
    }, [filter]);

    const now = moment(new Date());

    const staticDays = [
        { name: t('orders.filterDate.Today'), startDate: new Date(), endDate: new Date() },
        {
            name: t('orders.filterDate.Yesterday'),
            startDate: moment(now).subtract(1, 'days').toDate(),
            endDate: moment(now).subtract(1, 'days').toDate(),
        },
        {
            name: t('orders.filterDate.this-week'),
            startDate: moment(now).startOf('week').toDate(),
            endDate: moment(now).endOf('week').toDate(),
        },
        {
            name: t('orders.filterDate.last-week'),
            startDate: moment(moment(now).subtract(1, 'week')).startOf('week').toDate(),
            endDate: moment(moment(now).subtract(1, 'week')).endOf('week').toDate(),
        },
        {
            name: t('orders.filterDate.this-month'),
            startDate: moment(now).startOf('months').toDate(),
            endDate: moment(now).endOf('months').toDate(),
        },
        {
            name: t('orders.filterDate.last-month'),
            startDate: moment(moment(now).subtract(1, 'months')).startOf('months').toDate(),
            endDate: moment(moment(now).subtract(1, 'months')).endOf('months').toDate(),
        },
        {
            name: t('orders.filterDate.this-year'),
            startDate: moment(now).startOf('year').toDate(),
            endDate: moment(now).endOf('year').toDate(),
        },
    ];

    function dataInput(startDate: Date | null, endDate: Date | null) {
        if (startDate && endDate) {
            return (
                <ResponsiveFlexBox
                    width="100%"
                    alignItems="center"
                    paddingLeft={width > 374 ? '1.25rem' : 0}
                    paddingRight={width > 374 ? '1.25rem' : 0}
                    justifyContent="space-between"
                    flexDirection={width > 767 && width < 806 ? 'column' : ''}
                >
                    <TextField
                        className="date-handler"
                        size="small"
                        variant="outlined"
                        color="secondary"
                        value={`${startDate.toLocaleDateString()}`}
                        InputProps={{
                            startAdornment: <Calendar className="filter-icon" />,
                        }}
                        disabled
                    />
                    <TextField
                        className="date-handler"
                        size="small"
                        variant="outlined"
                        color="secondary"
                        value={`${endDate.toLocaleDateString()}`}
                        InputProps={{
                            startAdornment: <Calendar className="filter-icon" />,
                        }}
                        disabled
                    />
                </ResponsiveFlexBox>
            );
        } else {
            return (
                <IconButton
                    aria-label="show more"
                    onClick={() => {
                        setAnchorEl(!anchorEl);
                    }}
                    aria-haspopup="true"
                    color="primary"
                >
                    <Calendar />
                </IconButton>
            );
        }
    }

    const handleDateRange = (dates: [Date, Date]) => {
        const [start, end] = dates;

        setStartDateLocal(start);
        setEndDateLocal(end);
    };

    const applyAndSet = (startDate: Date | null, endDate: Date | null) => {
        setStartDateLocal(startDate);
        setEndDateLocal(endDate);
        setAnchorEl(false);
        setFilter({
            startDate: startDate,
            endDate: endDate,
        });
    };

    return (
        <LinkStyle>
            <StyledDateFilter>
                <Tooltip
                    title={
                        <Typography variant="body1" style={{ color: variables.colours.light }}>
                            {t('orders.calendar-title')}
                        </Typography>
                    }
                    placement="top-start"
                    arrow
                >
                    <DataFilterIcon
                        className={startDateLocal && startDateLocal ? 'wide' : ''}
                        onClick={() => {
                            setAnchorEl(!anchorEl);
                        }}
                        data-id="filter-icon"
                    >
                        {dataInput(startDateLocal, endDateLocal)}
                    </DataFilterIcon>
                </Tooltip>
                {anchorEl && (
                    <CalendarFilter width={width}>
                        <HeaderCalendar width={width}>
                            <ResponsiveFlexBox
                                width="98%"
                                height="1.875rem"
                                justifyContent="space-between"
                                alignItems="center"
                                marginLeft={width > 767 ? '0.313rem' : ''}
                                className="filter-header"
                            >
                                <>
                                    <IconButton
                                        onClick={() => {
                                            setStartDateLocal(startDateLocal);
                                            setEndDateLocal(startDateLocal);
                                            setAnchorEl(false);
                                        }}
                                        color="primary"
                                        name="cross-button"
                                    >
                                        <ClearIcon fontSize="large" />
                                    </IconButton>
                                    <Typography variant="subtitle1" className="header-title">
                                        {t('orders.calendar-title')}
                                    </Typography>
                                </>
                                <Calendar />
                            </ResponsiveFlexBox>
                        </HeaderCalendar>
                        {dataInput(startDateLocal || new Date(), endDateLocal || new Date())}
                        <ResponsiveFlexBox
                            width="100%"
                            paddingLeft="1.25rem"
                            paddingRight="1.25rem"
                            justifyContent="space-between"
                        >
                            <FlashDatePicker
                                disabledKeyboardNavigation
                                selected={startDateLocal}
                                onChange={handleDateRange}
                                startDate={startDateLocal}
                                endDate={endDateLocal}
                                selectsRange
                                monthsShown={2}
                                calendarClassName="calendar"
                                inline
                                name="date-picker"
                            />
                        </ResponsiveFlexBox>
                        <div className="days-handler">
                            {staticDays.map((staticDay, index) => {
                                return (
                                    <Button
                                        key={index}
                                        className="staticDayButton"
                                        color="primary"
                                        variant="outlined"
                                        onClick={() => {
                                            setStartDateLocal(staticDay.startDate);
                                            setEndDateLocal(staticDay.endDate);
                                        }}
                                        name="period-button"
                                    >
                                        <Typography variant="subtitle1">{staticDay.name}</Typography>
                                    </Button>
                                );
                            })}
                        </div>
                        <Box paddingRight="1.25rem" paddingTop="0.313rem" justifyContent="flex-end" display="flex">
                            <Button
                                className="option-button"
                                color="primary"
                                onClick={() => {
                                    applyAndSet(startDateLocal, endDateLocal);
                                }}
                                name="use-button"
                            >
                                <Typography variant="subtitle1">{t('orders.use')}</Typography>
                            </Button>
                            <Button
                                className="option-button"
                                onClick={() => {
                                    applyAndSet(null, null);
                                }}
                                name="clear-button"
                            >
                                <Typography variant="subtitle1">{t('orders.clear')}</Typography>
                            </Button>
                        </Box>
                    </CalendarFilter>
                )}
            </StyledDateFilter>
        </LinkStyle>
    );
}
