import { FC, useRef, createContext, useCallback } from 'react';

import jwt_decode from 'jwt-decode';

import useDispatch from 'hooks/useAppDispatch';

import { updateRefresh } from 'store/auth/actions';
import { refresh, updateUserFromAccessToken } from 'api/auth';

import { AccessToken } from 'interface/accessToken';
import { pick } from 'lodash';

interface AuthorizeParams {
    accessToken: string;
    refreshToken: string;
}

type StartTimerParams = Parameters<typeof setTimeout>;

type Authorize = (args: AuthorizeParams) => void;
type StartTimer = (...args: StartTimerParams) => void;

export interface AuthContext {
    authorize: Authorize;
}

export const AuthContext = createContext<AuthContext>({
    authorize: () => null,
});

const AuthProvider: FC = ({ children }) => {
    const dispatch = useDispatch();

    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const stopTimer = useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    }, []);

    const startTimer = useCallback<StartTimer>((callback, time, callbackParams) => {
        timerRef.current = setTimeout(callback, time, callbackParams);
    }, []);

    const authorize = useCallback<Authorize>(
        async ({ accessToken, refreshToken }) => {
            const tokens = { accessToken: accessToken, refreshToken: refreshToken };
            const tokenString = localStorage.getItem('persist:root');
            if (tokenString !== null) {
                const localTokens = JSON.parse(JSON.parse(tokenString)?.authReducer)?.auth;
                if (localTokens.accessToken && localTokens.refreshToken) {
                    tokens.accessToken = localTokens.accessToken;
                    tokens.refreshToken = localTokens.refreshToken;
                }
            }
            stopTimer();

            if (tokens.refreshToken && tokens.accessToken) {
                if (Date.now() >= (jwt_decode(tokens.accessToken) as AccessToken).exp * 1000) {
                    const { data } = await refresh({
                        accessToken: tokens.accessToken,
                        refreshToken: tokens.refreshToken,
                    });
                    const newTokens = pick(data, 'accessToken', 'refreshToken');

                    tokens.accessToken = newTokens.accessToken;
                    tokens.refreshToken = newTokens.refreshToken;
                }

                const { exp: expRT } = jwt_decode(tokens.refreshToken) as AccessToken;
                const { exp: expAT } = jwt_decode(tokens.accessToken) as AccessToken;

                const timeAccess = expAT * 1000 - Date.now() - 1000;

                dispatch(
                    updateRefresh({
                        refreshToken: tokens.refreshToken,
                        refreshTokenTTL: expRT,
                    }),
                );

                updateUserFromAccessToken(tokens.accessToken);

                startTimer(authorize, timeAccess, {
                    accessToken: tokens.accessToken,
                    refreshToken: tokens.refreshToken,
                });
            }
        },
        [dispatch],
    );

    return <AuthContext.Provider value={{ authorize }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
