import styled from 'styled-components';

import { Box, Typography } from '@material-ui/core';

import ResponsiveFlexBox from 'components/atoms/ResponsiveFlexBox';

export const OptionButtonText = styled(Typography).attrs({
    variant: 'h5',
})({});

export const StyledBox = styled(Box)({
    padding: '1.938rem 2.625rem',

    '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
        padding: '0.538rem 1.625rem',
    },

    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        padding: '0.538rem 1.625rem',
    },

    'h4 + div': {
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            margin: '0 auto',
        },
    },
});

export const UserName = styled(Typography).attrs({
    variant: 'h4',
})({ marginBottom: '1.25rem', overflowWrap: 'anywhere' });

export const UserHeader = styled(ResponsiveFlexBox)(({ maxWidth }) => ({
    paddingBottom: maxWidth > 767 ? '2.5rem' : 0,
    marginBottom: '1.25rem',

    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        textAlign: 'center',
    },
}));
