import { Chip } from '@material-ui/core';
import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const StyledRolesChip = styled(Chip)({
    borderRadius: 4,
    fontWeight: 'bold',
    width: '10.813rem',
    height: '2.438rem',
    '&.lockout-enabled': {
        backgroundColor: variables.colours.default,
    },
    '&.admin': {
        backgroundColor: variables.rolesColours.admin,
    },
    '&.operator': {
        backgroundColor: variables.rolesColours.operator,
    },
    '&.user': {
        backgroundColor: variables.rolesColours.user,
    },
    '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
        width: '6.375rem',
        height: '1.563rem',
        span: { padding: 0 },
    },
    '.role': {
        color: variables.colours.light,
    },
});
