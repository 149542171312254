import React from 'react';
import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';
import { CheckboxStyle } from './style';
import { ReactComponent as CustomCheckbox } from '../../../assets/icons/Checkbox.svg';

export type FlashDropDownProps = CheckboxProps & {
    label?: string;
    children?: React.ReactNode;
};

export default function FlashCheckbox(props: FlashDropDownProps): JSX.Element {
    const { label, ...checkboxProps } = props;
    return (
        <FormControlLabel
            control={<Checkbox icon={<CheckboxStyle />} checkedIcon={<CustomCheckbox />} {...checkboxProps} />}
            label={label}
        />
    );
}
