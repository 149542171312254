import styled from 'styled-components';

import { variables } from 'theme/variables';

export const CostSumStyle = styled.div(({}) => ({
    display: 'flex',
    width: 'auto',
    justifyContent: 'space-between',
    border: `1.5px solid ${variables.backgoroundColours.border}`,
    boxSizing: 'border-box',
    borderRadius: '4px',

    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        marginBottom: '0rem',
    },
}));

export const Cost = styled.div(({}) => ({
    padding: '0.688rem',
    lineHeight: '1.438rem',
    letterSpacing: '0.095em',
    fontVariant: 'small-caps',
}));

export const Total = styled.div(({}) => ({
    padding: '0.688rem',
    lineHeight: '1.438rem',
    letterSpacing: '0.095em',
    p: {
        color: variables.colours.default,
    },
}));
