import { RefObject, useEffect, useRef, createRef } from 'react';
import { generatePath, Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import moment from 'moment';
import PopperJs from 'popper.js';

import { Tooltip, Typography, Link as MuiLink } from '@material-ui/core';
import FullCalendar, { DateSelectArg, DatesSetArg, EventClickArg, LocaleSingularArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelineDay from '@fullcalendar/resource-timeline';

import { CalendarEvent } from 'components/organisms/Prices/events';
import { StyledCalendar } from 'components/organisms/calendar/Calendar/style';

import { MomentFormats } from 'shared/enums';

import { variables } from 'theme/variables';
import allRoutes from '../../../../router';

export type CalendarResource = {
    id: string;
    title: string;
};

export type CalendarProps = {
    locale: LocaleSingularArg;
    initialDate: Date;
    initialEvents: Array<CalendarEvent>;
    hasAllDateEvents: boolean;
    resourceAreaHeaderContent?: JSX.Element | string;
    resources?: Array<CalendarResource>;
    handleDateSelect?: (selectInfo: DateSelectArg) => void;
    handleEventClick?: (clickInfo: EventClickArg) => void;
    setResourceTimeline: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const calendarRef: RefObject<FullCalendar> = createRef();

export default function FlashCalendar(props: CalendarProps): JSX.Element {
    const { t } = useTranslation();
    const {
        locale,
        initialDate,
        initialEvents: events,
        hasAllDateEvents,
        handleDateSelect,
        handleEventClick,
        resources,
        resourceAreaHeaderContent,
        setResourceTimeline,
    } = props;
    const positionRef = useRef<{ x: number; y: number }>({
        x: 0,
        y: 0,
    });
    const popperRef = useRef<PopperJs>(null);

    useEffect(() => {
        calendarRef.current?.getApi().scrollToTime({ day: new Date().getDate() - 2 });
    }, []);

    useEffect(() => {
        calendarRef.current?.getApi().gotoDate(initialDate);
    }, [initialDate]);

    const refreshTimeline = (view: string) => {
        const indicators = Array.from(
            // eslint-disable-next-line
            document.getElementsByClassName('fc-timeline-now-indicator-container') as HTMLCollectionOf<any>,
        );
        // eslint-disable-next-line
        const line = document.querySelector('.fc-timeline-now-indicator-line') as any;
        // eslint-disable-next-line
        const arrow = document.querySelector('.fc-timeline-now-indicator-arrow') as any;
        const timeline = document.querySelector(
            '.fc-scroller[style="overflow: scroll; margin-bottom: -5px;"], .fc-scroller[style="overflow: scroll hidden; margin-bottom: -5px;"]',
            // eslint-disable-next-line
        ) as any;

        const refreshNowMark = (position: number) => {
            if (!indicators || !line || !arrow) return;
            indicators.forEach((indicator) => {
                indicator.style = `left: ${0}px;`;
            });
            line.style = `left: ${position}px !important`;
            arrow.style = `left: ${position}px !important`;
        };

        const scrollToNow = (position: number) => {
            if (!timeline) return;

            timeline.scrollTo({ left: position, behavior: 'smooth' });
        };

        let indicatorPosition = 0;
        let viewPosition = 0;

        switch (view) {
            case 'resourceTimelineDay':
                indicatorPosition = moment().hours() * 60;
                viewPosition = indicatorPosition - 2 * 60;
                break;
            case 'resourceTimelineWeek':
                indicatorPosition = moment().day() * 30 + moment().hours() * 30;
                viewPosition = indicatorPosition - 6 * 30;
                break;
            case 'resourceTimelineMonth':
                indicatorPosition = (moment().date() - 1) * 47;
                viewPosition = indicatorPosition - 2 * 47;
                break;
        }

        scrollToNow(viewPosition);
        refreshNowMark(indicatorPosition);
    };

    const handleDateSet = (arg: DatesSetArg) => {
        const viewType = arg.view.type;

        setTimeout(() => refreshTimeline(viewType), 0);

        switch (viewType) {
            case 'resourceTimelineDay':
                return setResourceTimeline('day');
            case 'resourceTimelineWeek':
                return setResourceTimeline('week');
            case 'resourceTimelineMonth':
                return setResourceTimeline('month');
        }
    };

    const handleMouseMove = (event: React.MouseEvent) => {
        positionRef.current = { x: event.clientX, y: event.clientY };

        if (popperRef.current != null) {
            popperRef.current.scheduleUpdate();
        }
    };

    return (
        <StyledCalendar>
            <FullCalendar
                height="60vh"
                data-id="full-calendar"
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, resourceTimelineDay, interactionPlugin]}
                locale={locale}
                resourceAreaHeaderContent={
                    <Typography className="mediaspaces-title"> {resourceAreaHeaderContent}</Typography>
                }
                firstDay={1}
                headerToolbar={{
                    left: 'today',
                    center: 'prev title next',
                    right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth',
                }}
                buttonText={{
                    today: t('cost.calendar.today'),
                    month: t('cost.calendar.month'),
                    week: t('cost.calendar.week'),
                    day: t('cost.calendar.day'),
                }}
                initialView="resourceTimelineMonth"
                datesSet={handleDateSet}
                views={{
                    timeGridWeek: {
                        selectable: true,
                        selectMirror: true,
                        select: handleDateSelect,
                    },
                    timeGridDay: {
                        selectable: true,
                        selectMirror: true,
                        select: handleDateSelect,
                    },
                    resourceTimelineDay: {
                        selectable: true,
                        selectMirror: true,
                        select: handleDateSelect,
                    },
                }}
                stickyHeaderDates={true}
                slotEventOverlap={false}
                allDaySlot={hasAllDateEvents}
                nowIndicator={true}
                weekText={t('price.week')}
                weekNumbers={true}
                navLinks={true}
                dayMaxEventRows={true}
                eventClick={handleEventClick}
                resources={resources}
                eventDisplay="block"
                eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false }}
                displayEventTime={false}
                displayEventEnd={true}
                schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                resourceOrder="title"
                events={events}
                resourceLabelContent={(arg) => {
                    return (
                        <MuiLink
                            component={Link}
                            to={generatePath(allRoutes.mediaSpaceProfile.path, { id: arg.resource.id, pageId: 1 })}
                        >
                            {arg.resource.title}
                        </MuiLink>
                    );
                }}
                eventContent={(arg) => {
                    return (
                        <Tooltip
                            title={
                                <Typography className="test" variant="body1" style={{ color: variables.colours.light }}>
                                    <>
                                        {arg.event.title} {arg.event.extendedProps?.cost} {t('price.currency')}{' '}
                                        {moment(arg.event.start).format(MomentFormats.Time)}-
                                        {arg.event.end === null
                                            ? '00:00'
                                            : moment(arg.event.end).format(MomentFormats.Time)}
                                    </>
                                </Typography>
                            }
                            placement="top"
                            PopperProps={{
                                popperRef,
                                anchorEl: {
                                    clientHeight: 0,
                                    clientWidth: 0,
                                    getBoundingClientRect: () => ({
                                        top: positionRef.current.y,
                                        left: positionRef.current.x,
                                        right: positionRef.current.x,
                                        bottom: positionRef.current.y,
                                        width: 0,
                                        height: 0,
                                    }),
                                },
                            }}
                        >
                            <Typography onMouseMove={handleMouseMove} color="inherit" variant="subtitle2">
                                {arg.event.title}
                            </Typography>
                        </Tooltip>
                    );
                }}
            />
        </StyledCalendar>
    );
}
