import React, { FC, useEffect, useState } from 'react';

import FilterInput from 'components/atoms/FilterInput';
import RolesSelect, { FieldValue } from 'components/atoms/gridFilters/RolesSelect';
import UserStateSelect, { UserStatesEnum } from 'components/atoms/gridFilters/UserStateSelect';

import { FormControlTextInputStyle } from 'components/atoms/gridFilters/FiltersHolder/style';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getUsersReportSrc } from '../../../../api/users';
import ResponsiveFlexBox from '../../ResponsiveFlexBox';

export interface UserFilterData {
    selectedRole: string | undefined;
    filterString: string;
    state: UserStatesEnum;
}

export interface UserFilterProps {
    onFilterChange: (filter: UserFilterData) => void;
}

const UserFilter: FC<UserFilterProps> = ({ onFilterChange }) => {
    const [filter, setFilter] = useState<UserFilterData>({
        selectedRole: FieldValue.allRoles,
        filterString: '',
        state: UserStatesEnum.unlocked,
    });
    const { t } = useTranslation();

    useEffect(() => {
        onFilterChange && onFilterChange(filter);
    }, [filter]);

    const onRoleChanged = (selectedRole: string) => {
        setFilter({ ...filter, selectedRole: selectedRole });
    };

    const onFilterStringChange = (filterString: string) => {
        setFilter({ ...filter, filterString: filterString });
    };

    const onStateChange = (state: UserStatesEnum) => {
        setFilter({ ...filter, state: state });
    };

    const handleGenerateReport = () => {
        window.open(getUsersReportSrc(false));
    };

    return (
        <ResponsiveFlexBox justifyContent="space-between" marginBottom={2} gridGap={10}>
            <ResponsiveFlexBox gridGap={10}>
                <FormControlTextInputStyle>
                    <FilterInput onChange={onFilterStringChange} />
                </FormControlTextInputStyle>
                <FormControlTextInputStyle>
                    <RolesSelect onRoleChanged={onRoleChanged} value={filter.selectedRole} />
                </FormControlTextInputStyle>
                <FormControlTextInputStyle className="last-filter">
                    <UserStateSelect onChange={onStateChange} value={filter.state} />
                </FormControlTextInputStyle>
            </ResponsiveFlexBox>
            <ResponsiveFlexBox>
                <Button variant="outlined" color="primary" onClick={handleGenerateReport}>
                    <Typography variant="h5" color="inherit">
                        {t('orders.create-report')}
                    </Typography>
                </Button>
            </ResponsiveFlexBox>
        </ResponsiveFlexBox>
    );
};

export default UserFilter;
