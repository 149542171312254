import styled from 'styled-components';

export const StyledLogo = styled.img({
    cursor: 'pointer',
    width: '270px!important',
    height: 'auto!important',

    '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
        width: '161px!important',
    },

    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        width: '125px!important',
    },
});
