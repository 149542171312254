import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const CompleteRestoreStyle = styled.div(({}) => ({
    '.restore-subtitle': {
        marginTop: '1.25rem',
    },
    '.send-button': {
        textAlign: 'right',
        marginTop: '1.875rem',
        ':hover': {
            h5: {
                color: variables.colours.primary,
            },
        },
    },
    h5: {
        color: variables.colours.light,
    },
}));
