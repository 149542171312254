import { FC, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { Typography, Link as MuiLink } from '@material-ui/core';

import { HubEvents } from 'components/organisms/forms/ChatForm';
import { useNotification } from 'components/organisms/NotificationProvider';

import allRoutes from 'router';
import { userHasAnyChat } from 'api/chat';

const GoToOrdersChatLink: FC = () => {
    const { t } = useTranslation();
    const { hub } = useNotification();
    const location = useLocation();

    const [userHasAnyChats, setUserHasAnyChats] = useState(false);

    const checkIfUserHasChatsTimeOut = async () => {
        const response = await userHasAnyChat();
        if (response.data) setUserHasAnyChats(response.data);
    };

    const checkIfUserHasChats = async () => {
        await checkIfUserHasChatsTimeOut();
        setTimeout(checkIfUserHasChatsTimeOut, 500);
    };

    useEffect(() => {
        hub?.on(HubEvents.onUserAddedToChat, () => {
            (async () => await checkIfUserHasChats())();
        });
        return () => {
            hub?.off(HubEvents.onUserAddedToChat);
        };
    }, [hub]);

    useEffect(() => {
        setTimeout(() => {
            checkIfUserHasChats();
        }, 1000);
    }, []);

    return (
        <>
            {userHasAnyChats && (
                <MuiLink
                    component={Link}
                    to={{ pathname: allRoutes.messagesPage.path }}
                    className="button-handler chat-button"
                    data-id="chat"
                >
                    <Typography
                        variant="body2"
                        className={location.pathname.split('/')[1] === 'chat' ? 'link-now' : 'orders-button'}
                    >
                        {t('navigation.orders-chat')}
                    </Typography>
                </MuiLink>
            )}
        </>
    );
};

export default GoToOrdersChatLink;
