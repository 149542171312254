import axios from 'api/axios';
import { AxiosResponse } from 'axios';

import {
    CancelPaymentResponse,
    OrderCount,
    OrderFilledDatesModel,
    OrderModel,
    OrderNotifiedVm,
    OrderPage,
    OrderPayVm,
    OrderStatus,
    OrderVm,
    PagedOrderDataGridShortVm,
    PagedPlaybackStatisticVm,
    PageOrderVm,
} from 'models/order';
import { OrderStatusType, PaymentStatus, UserRoles } from 'shared/enums';
import { CancelPayModel, PayModel } from 'models/pay-model';
import { OrdersPagingModel } from '../models/paging/paging.model';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string'); // CommonJS Module

export const ordersAPI = {
    getPaged: (data: OrdersPagingModel): Promise<PagedOrderDataGridShortVm> => {
        return axios.get(`orders/paged/?${queryString.stringify(data)}`);
    },
};

export const getOrderActSrc = (id: string, orderActId: string, displayInline: boolean): string => {
    return `${window.ENV.config.backendUrl}/api/acts/${orderActId}/orders/${id}?displayInline=${displayInline}`;
};

export const getOrderReportSrc = (startDate: string, endDate: string, displayInline: boolean): string => {
    let token = '';
    const root = window.localStorage['persist:root'];
    if (root) {
        const tokens = JSON.parse(JSON.parse(root).authReducer);
        if (tokens?.auth?.accessToken) {
            token = tokens.auth.accessToken;
        }
    }
    return `${window.ENV.config.backendUrl}/api/orders/report?startDate=${startDate}&endDate=${endDate}&displayInline=${displayInline}&access_token=${token}`;
};

export const get = (): Promise<AxiosResponse<Array<OrderVm>>> => {
    return axios.get(`orders`);
};

export const getById = (id: string): Promise<AxiosResponse<OrderVm>> => {
    return axios.get(`orders/${id}`);
};

export const getByIdWithCount = (id: string): Promise<AxiosResponse<OrderCount>> => {
    return axios.get(`orders/${id}/summary`);
};

export const getPagedPlaybackStatisticById = (
    id: string,
    pageNumber: number,
    pageSize: number,
): Promise<PagedPlaybackStatisticVm> => {
    return axios.get(`orders/${id}/playbackStatistic?pageNumber=${pageNumber}&pageSize=${pageSize}`);
};

export const create = (data: OrderModel): Promise<AxiosResponse<OrderVm>> => {
    return axios.post(`orders`, data);
};

export const deleteById = (id: string): Promise<AxiosResponse<boolean>> => {
    return axios.delete(`orders/${id}`);
};

export const deletePage = (data: string[]): Promise<AxiosResponse<PageOrderVm>> => {
    return axios.delete(`orders?${arrayToQuery('ids', data)}`);
};

export const declineManyOrders = (data: string[]): Promise<AxiosResponse<PageOrderVm>> => {
    return axios.post(`orders/declineMany?${arrayToQuery('ids', data)}`);
};

export const getPage = (data: OrderPage): Promise<PageOrderVm> => {
    return axios.post(`orders/page`, data);
};

export const toPay = (data: PayModel): Promise<AxiosResponse<OrderPayVm>> => {
    return axios.post(`orders/pay`, data);
};

export const toCancelPay = (data: CancelPayModel): Promise<AxiosResponse<CancelPaymentResponse>> => {
    return axios.post(`orders/cancelPayment`, data);
};

export const getNotified = (id: string): Promise<AxiosResponse<OrderNotifiedVm>> => {
    return axios.post(`orders/pay/${id}`);
};

export const toChangeStatusByUser = (id: string, data: OrderStatus): Promise<AxiosResponse<OrderVm>> => {
    return axios.put(`orders/${id}/statusByUser`, data);
};

export const toChangeStatus = (id: string, data: OrderStatus): Promise<AxiosResponse<OrderVm>> => {
    return axios.put(`orders/${id}/status`, data);
};

export const calculationOrderPrice = (data: OrderModel): Promise<AxiosResponse<OrderVm>> => {
    return axios.post(`orders/price?orderId=${data.orderId}`, data);
};

export const getFilledDates = (data: OrderFilledDatesModel): Promise<AxiosResponse<string[]>> => {
    return axios.post(`orders/filledDates`, data);
};

export const onStatusToPay = (id: string): Promise<AxiosResponse<PaymentStatus>> => {
    return axios.post(`orders/${id}/statusPay`);
};

export const hardDeleteById = (id: string): Promise<AxiosResponse<boolean>> => {
    return axios.delete(`orders/hard/${id}`);
};

export const getPaidUserOrders = (id: string): Promise<AxiosResponse<boolean>> => {
    return axios.get(`orders/${id}/paidUserOrders`);
};

const arrayToQuery = (key: string, array: string[]) => {
    const keyArray = array.map((val) => `${key}=${val}`);
    return keyArray.join('&');
};

export const getPaymentStatus = (
    userRole: string | undefined,
    orderStatus: OrderStatusType | undefined,
): PaymentStatus | undefined => {
    if (userRole === UserRoles.Admin || userRole === UserRoles.Operator) {
        return PaymentStatus.WithoutPayment;
    }
    if (
        orderStatus !== OrderStatusType.Cancelled &&
        orderStatus !== OrderStatusType.PaymentRefunded &&
        orderStatus !== OrderStatusType.onPayWaiting &&
        orderStatus !== OrderStatusType.onModeration &&
        orderStatus !== OrderStatusType.Created
    ) {
        return PaymentStatus.Paid;
    }
    if (orderStatus === OrderStatusType.PaymentRefunded) {
        return PaymentStatus.PaymentReturned;
    }
    if (
        orderStatus === OrderStatusType.onPayWaiting ||
        orderStatus === OrderStatusType.onModeration ||
        orderStatus === OrderStatusType.Cancelled ||
        orderStatus === OrderStatusType.Created
    ) {
        return PaymentStatus.NonPaid;
    }

    return undefined;
};
