const prefix = 'THEME_STORE';

export const UPDATE_THEME = `${prefix}UPDATE_THEME`;

export interface ThemeSettings {
    isDarkMode: boolean;
}

export interface ThemeState {
    themeSettings: ThemeSettings;
}

interface UpdateUserAction {
    type: typeof UPDATE_THEME;
    data: ThemeSettings;
}

export type ThemeActionType = UpdateUserAction; // | other actions
