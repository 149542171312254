import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import Button from 'components/atoms/controls/Button';
import GoBackButton from 'components/atoms/controls/GoBackButton';

import allRoutes from 'router';
import { AxiosResponse } from 'axios';

import { getNotified } from 'api/orders';
import { OrderNotifiedVm } from 'models/order';

export default function OrderProfile(): JSX.Element {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    enum Lable {
        Ok = 'order-form.button-text.ok',
        CheckPay = 'order-form.button-text.check-payment',
    }

    enum Method {
        Redirect = 1,
        Check = 2,
    }

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [lableButton, setIsLableButton] = useState<string>(Lable.Ok);
    const [lableText, setIsLableText] = useState<string>();
    const [clickMethod, setIsClickMethod] = useState<number>();

    useEffect(() => {
        if (!isDataLoaded) {
            setIsDataLoaded(true);
            const getData = async () => {
                const response = await onNotified();
                if (response.data.errorCode == null || response.data.errorCode != '0') {
                    setIsLableButton(Lable.CheckPay);
                    setIsLableText(response.data.errorMessage);
                    setIsClickMethod(Method.Check);
                }
                if (response.data.errorCode == '0') {
                    setIsLableButton(Lable.Ok);
                    setIsLableText(response.data.errorMessage);
                    toast.success(response.data.errorMessage);
                    setIsClickMethod(Method.Redirect);
                }
            };
            setTimeout(() => getData(), 300);
        }
    }, []);
    async function onNotified() {
        const payResponse: AxiosResponse<OrderNotifiedVm> = await getNotified(id);
        return payResponse;
    }
    function onRedirect() {
        return history.push(generatePath(allRoutes.orderProfile.path, { id: id }));
    }
    function clickFunc() {
        if (clickMethod == Method.Check) {
            onNotified();
        } else if (clickMethod == Method.Redirect) {
            onRedirect();
        }
    }
    return (
        <>
            <GoBackButton data-id="go-back" />
            <div>
                <span>{lableText}</span>
                <Button onClick={clickFunc} name="pay-result">
                    {t(lableButton)}
                </Button>
            </div>
        </>
    );
}
