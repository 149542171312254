import { MenuItem } from '@material-ui/core';
import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const MediaSpaceChangeStyle = styled.div(({}) => ({
    '.MuiInputBase-root': {
        height: '2.658rem',
        minWidth: 160,
        display: 'flex',
        maxWidth: '100%',
    },
    '.MuiMenuItem-root': {
        paddingTop: 0,
    },
    '.MuiInputBase-input': {
        color: variables.colours.dark,
    },
    '.checked-all': {
        marginLeft: '-0.938rem',
        marginTop: '0.625rem',
    },
    '.MuiCheckbox-root': {
        padding: '0.563rem',
    },
}));

export const MenuItemStyle = styled(MenuItem)(({}) => ({
    '.MuiTypography-root': {
        color: variables.colours.dark,
    },
    '&.MuiMenuItem-root': {
        overflow: 'visible',
        whiteSpace: 'normal',
    },
}));
