import { FC } from 'react';

import { variables } from 'theme/variables';
import { useTranslation } from 'react-i18next';
import { StatusMessage, StyledOrderStatus, Spinner } from 'components/atoms/Order/Status/style';

interface StatusProps {
    color: string;
    message: string;
    loading?: boolean;
}

const Status: FC<StatusProps> = ({ color, message, loading }) => {
    const { t } = useTranslation();
    return (
        <StyledOrderStatus $color={loading ? variables.colours.default : color}>
            {loading && <Spinner />}
            <StatusMessage>{loading ? t('loading') : message}</StatusMessage>
        </StyledOrderStatus>
    );
};

export default Status;
