import { FC } from 'react';

import { InputLabel, Select, SelectProps } from '@material-ui/core';

import { SelectFormControl } from 'components/atoms/gridFilters/BaseFilterSelect/style';

export type RoleSelectProps<T> = SelectProps & {
    onSelectionChanged: (value: T) => void;
    defaultValue: T | null;
    children: React.ReactNode;
    value: T | null;
};

// eslint-disable-next-line
const BaseFilterSelect: FC<RoleSelectProps<any>> = ({
    onSelectionChanged,
    defaultValue,
    children,
    value,
    ...selectProps
}) => {
    return (
        <>
            <SelectFormControl variant="outlined" color={selectProps.color}>
                {selectProps.label && <InputLabel id={`select-${selectProps.label}`}>{selectProps.label}</InputLabel>}
                <Select
                    {...selectProps}
                    defaultValue={defaultValue}
                    value={value}
                    labelId={selectProps.label ? `select-${selectProps.label}` : undefined}
                    MenuProps={{
                        anchorOrigin: {
                            horizontal: 'center',
                            vertical: 'top',
                        },
                        transformOrigin: {
                            horizontal: 'center',
                            vertical: 'top',
                        },
                    }}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        const selected = event.target.value;
                        onSelectionChanged(selected);
                    }}
                >
                    {children}
                </Select>
            </SelectFormControl>
        </>
    );
};

export default BaseFilterSelect;
