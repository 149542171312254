import { FC, useCallback, useState, useMemo } from 'react';

import MomentLocaleUtils from 'react-day-picker/moment';
import { compact, isDate, isFunction } from 'lodash';
import moment, { locale } from 'moment';

import { Box, CircularProgress, ClickAwayListener, Typography } from '@material-ui/core';
import { BeforeAfterModifier, FunctionModifier } from 'react-day-picker';

import DatePickerNavbar from 'components/atoms/DatePickerNavbar';

import { StyledDatePicker, DatePickerWrapper, LoadingOverlay } from 'components/organisms/DatePicker/style';

import 'moment/locale/ru';
import { useTranslation } from 'react-i18next';
import { Languages } from '../../../shared/enums';

type DatePickerValue = string | undefined;

interface DatePickerOnChangeValue {
    start: string | undefined;
    end: string | undefined;
    exclude: string[];
}

interface DatePickerProps {
    value: [DatePickerValue, DatePickerValue];
    onChange: (value: DatePickerOnChangeValue) => void;
    disableModifiers: (BeforeAfterModifier | Date | FunctionModifier)[];
    isLoading?: boolean;
    minStartDays?: number;
    maxStartDays?: number;
    minEndDays?: number;
    minStartMonths?: number;
    maxStartMonths?: number;
    minEndMonths?: number;
}

const DatePicker: FC<DatePickerProps> = ({
    value,
    isLoading,
    onChange,
    disableModifiers,
    minStartDays = 0,
    maxStartDays = 0,
    minEndDays = 0,
    minStartMonths = 0,
    maxStartMonths = 0,
    minEndMonths = 0,
}) => {
    const { t } = useTranslation();

    const [isSelecting, setIsSelecting] = useState<boolean>(false);

    const start = value[0] ? moment(value[0]).toDate() : null;
    const end = value[1] ? moment(value[1]).toDate() : null;

    const [selectStart, setSelectStart] = useState<Date | null>(null);
    const [selectEnd, setSelectEnd] = useState<Date | null>(null);

    const currentStart = isSelecting ? selectStart || start : start;
    const currentEnd = isSelecting ? selectEnd || end : end;

    // set locale params for correct month updating in datepicker
    const localStorageLanguage = (localStorage.getItem('locale') || Languages.English) as Languages;
    MomentLocaleUtils.getMonths(locale(localStorageLanguage));

    const reset = useCallback(() => {
        setSelectStart(null);
        setSelectEnd(null);
        setIsSelecting(false);
    }, []);

    const displayWarning = () => {
        if (currentStart && isSelecting) return t('date-picker.just-the-beginning');
        if (!currentEnd) return t('date-picker.nothing-selected');
    };

    const handleDayClick = useCallback(
        (day, { disabled, selected }) => {
            if (isSelecting && disabled) return reset();

            if (!isSelecting && selected) {
                return onChange({
                    start: undefined,
                    end: undefined,
                    exclude: [],
                });
            }

            if (!disabled) {
                if (isSelecting) {
                    if (selectStart && !selectEnd && selectStart.getTime() === day.getTime()) {
                        setSelectEnd(day);
                        handleSelectedAllDates(selectStart, day);
                    } else if (selectStart && selectEnd) {
                        handleSelectedAllDates(selectStart, selectEnd);
                    }
                } else {
                    setSelectStart(day);
                    setIsSelecting(true);
                }
            }
        },
        [isSelecting, selectStart, selectEnd],
    );

    const handleSelectedAllDates = (start: Date | null, end: Date | null) => {
        if (start && end) {
            if (start.getTime() > end.getTime()) return reset();

            const rangeLength = Math.abs(moment(start).diff(end, 'days', false)) + 1;

            const days: string[] = new Array(rangeLength)
                .fill(moment(start))
                .map((date, index) => moment(date).add(index, 'days').toISOString());

            const exclude = days.filter((day) =>
                disableModifiers.some((filledDate) => {
                    if (isDate(filledDate)) return moment(filledDate).isSame(day);

                    if (isFunction(filledDate)) return filledDate(new Date(day));

                    return false;
                }),
            );

            onChange({
                start: days[0],
                end: days[days.length - 1],
                exclude,
            });
            reset();
        }
    };

    const handleDayMouseEnter = useCallback((day) => {
        setSelectEnd(day);
    }, []);

    const handleKeyDown = useCallback(({ key }) => {
        if (key === 'Escape') reset();
    }, []);

    const modifiers = {
        start: currentStart || undefined,
        end: currentEnd || undefined,
    };

    const selectedDays = useMemo(() => {
        return compact([
            currentStart,
            {
                from: currentStart,
                to: moment.max(moment(currentEnd), moment(currentStart)).toDate(),
            },
        ]);
    }, [currentStart, currentEnd]);

    const minimalEndDate = moment()
        .add(minStartMonths + maxStartMonths, 'months')
        .add(minStartDays + maxStartDays, 'days')
        .startOf('day')
        .toDate();

    const disabledDays = isSelecting
        ? [
              ...disableModifiers,
              {
                  before: selectStart,
                  after: moment(selectStart).add(minEndMonths, 'months').add(minEndDays, 'days').toDate(),
              },
          ]
        : [
              ...disableModifiers,
              {
                  before: moment().add(minStartMonths, 'months').add(minStartDays, 'days').startOf('day').toDate(),
                  after: moment(end).isAfter(minimalEndDate) ? moment(end).toDate() : minimalEndDate,
              },
          ];

    return (
        <Box flexDirection="column">
            <ClickAwayListener onClickAway={reset}>
                <DatePickerWrapper>
                    <StyledDatePicker
                        localeUtils={MomentLocaleUtils}
                        locale={localStorageLanguage}
                        showOutsideDays
                        fromMonth={new Date()}
                        navbarElement={DatePickerNavbar}
                        captionElement={() => null}
                        modifiers={modifiers}
                        disabledDays={disabledDays}
                        selectedDays={selectedDays}
                        onDayClick={handleDayClick}
                        onDayMouseEnter={handleDayMouseEnter}
                        onKeyDown={handleKeyDown}
                    />
                    {isLoading && (
                        <LoadingOverlay>
                            <CircularProgress />
                        </LoadingOverlay>
                    )}
                </DatePickerWrapper>
            </ClickAwayListener>
            <Box m={1}>
                <Typography color="textPrimary">{displayWarning()}</Typography>
            </Box>
        </Box>
    );
};

export default DatePicker;
