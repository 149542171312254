import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const CheckboxStyle = styled.div(({}) => ({
    background: variables.backgoroundColours.light,
    border: `1px solid ${variables.backgoroundColours.border}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '25px',
    height: '25px',
}));
