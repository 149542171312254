import { FormControl } from '@material-ui/core';
import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const RoleColor = styled.div((props: { color: string }) => ({
    width: '1.25rem',
    height: '1.25rem',
    marginRight: '0.563rem',
    background: props.color,
    borderRadius: '4px',
}));

export const SelectFormControl = styled(FormControl)(({}) => ({
    display: 'flex',

    '.MuiInputLabel-formControl': {
        color: variables.colours.default,
        paddingTop: '0.125rem',
    },
}));
