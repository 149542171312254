import { FC } from 'react';

import { StyledStepIndicator, StepIndicatorValue } from 'components/atoms/StepIndicator/style';

export interface StepIndicatorProps {
    value: number;
    max: number;
}

const StepIndicator: FC<StepIndicatorProps> = ({ value, max }) => (
    <StyledStepIndicator value={value} max={max}>
        <StepIndicatorValue>{`0${value}`.slice(-2)}</StepIndicatorValue>
    </StyledStepIndicator>
);

export default StepIndicator;
