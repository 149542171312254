import axios from 'api/axios';
import { AxiosResponse } from 'axios';

import {
    DeviceVm,
    DevicePage,
    DeviceBaseVm,
    UpdateDeviceNameData,
    UpdateDeviceVolumeData,
    DeviceProfilePage,
    PagedDeviceVmResponse,
    PagedDeviceFilesVmResponse,
} from 'models/device.model';

export const devicesAPI = {
    getById: (id: string): Promise<AxiosResponse<DeviceBaseVm>> => {
        return axios.get(`devices/${id}`);
    },
    getPage: (data: DevicePage): Promise<PagedDeviceVmResponse> => {
        return axios.post(`devices/page`, data);
    },
    update: (id: string, data: UpdateDeviceNameData): Promise<AxiosResponse<DeviceVm>> => {
        return axios.put(`devices/${id}`, data);
    },
    updateVolume: (id: string, data: UpdateDeviceVolumeData): Promise<AxiosResponse<DeviceVm>> => {
        return axios.post(`devices/${id}/volume`, data);
    },
    deleteById: (id: string): Promise<AxiosResponse<boolean>> => {
        return axios.delete(`devices/${id}`);
    },
    getDeviceScreenshot: (id: string): Promise<AxiosResponse> => {
        return axios.get(`devices/${id}/screenshot/request`);
    },
    getDeviceFiles: (id: string, data: DeviceProfilePage): Promise<PagedDeviceFilesVmResponse> => {
        return axios.post(`playedfiles/page/${id}`, data);
    },
    checkDevices: (token: string): Promise<AxiosResponse<DeviceBaseVm>> => {
        return axios.get(`devices/checkstatus`, { headers: { Authorization: `Bearer ${token}` } });
    },
};
