import { AxiosResponse } from 'axios';
import queryString from 'query-string';
import { mapValues, toString } from 'lodash';

import axios from 'api/axios';

import {
    MediaSpaceModel,
    MediaSpacePage,
    MediaSpacePageVm,
    MediaSpaceVm,
    MediaSpaceTariffsValidationRule,
    MediaSpaceDevicesFilterRule,
    MediaSpaceMapVm,
} from 'models/media-space.model';

interface GetMediaSpacesQuery {
    validationRule?: MediaSpaceTariffsValidationRule;
    devicesFilterRule?: MediaSpaceDevicesFilterRule;
}

export const mediaSpacesAPI = {
    getMediaSpacesMap: (status: number): Promise<AxiosResponse<Array<MediaSpaceMapVm>>> => {
        return axios.get(`mediaspace/map?mediaSpaceFilterRule=${status}`);
    },
    getMediaSpacesMapPublic: (locale: string | null): Promise<AxiosResponse<Array<MediaSpaceMapVm>>> => {
        return axios.get(`mediaspace/map/public?culture=${locale}`);
    },
    getMediaSpacesMapOrderPage: (): Promise<AxiosResponse<Array<MediaSpaceMapVm>>> => {
        return axios.get(`mediaspace/map/orderPage`);
    },
    getValidityPage: (status: number, req: MediaSpacePage): Promise<MediaSpacePageVm> => {
        return axios.post(`mediaspace/page?validationRule=${status}`, req);
    },
};

export const getMediaSpaces = (query?: GetMediaSpacesQuery): Promise<AxiosResponse<Array<MediaSpaceVm>>> => {
    return axios.get(queryString.stringifyUrl({ url: 'mediaspace', query: mapValues(query, toString) }));
};

export const getMediaSpacesHasTariffs = (): Promise<AxiosResponse<Array<MediaSpaceVm>>> => {
    return axios.get(`mediaspace/hastariffs`);
};

export const getPage = (data: MediaSpacePage): Promise<MediaSpacePageVm> => {
    return axios.post(`mediaspace/page`, data);
};

export const getById = (id: string): Promise<AxiosResponse<MediaSpaceVm>> => {
    return axios.get(`mediaspace/${id}`);
};

export const create = (data: MediaSpaceModel): Promise<AxiosResponse<MediaSpaceVm>> => {
    return axios.post(`mediaspace`, data);
};

export const update = (data: MediaSpaceModel, id: string): Promise<AxiosResponse<MediaSpaceVm>> => {
    return axios.put(`mediaspace/${id}`, data);
};

export const deleteById = (id: string): Promise<AxiosResponse<boolean>> => {
    return axios.delete(`mediaspace/${id}`);
};

export const getValidityMediaSpaces = (status: number): Promise<AxiosResponse<Array<MediaSpaceVm>>> => {
    return axios.get(`mediaspace?validationRule=${status}`);
};

export const getValidityById = (status: number, id: string): Promise<AxiosResponse<MediaSpaceVm>> => {
    return axios.get(`mediaspace/${id}?validationRule=${status}`);
};

export const checkTariffs = (token: string): Promise<AxiosResponse<MediaSpaceVm>> => {
    return axios.get(`mediaspace/checktariffs`, { headers: { Authorization: `Bearer ${token}` } });
};

export const sendImageToBackend = (id: string, image: File): Promise<AxiosResponse<MediaSpaceVm>> => {
    const formData = new FormData();
    formData.append(`file`, image);

    return axios.post(`mediaspace/${id}/picture`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
};

export default {
    get: getMediaSpaces,
    getById,
};
