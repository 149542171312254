import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const CheckboxStyle = styled.div(({}) => ({
    '.enabled-checkbox': {
        div: {
            border: `1px solid ${variables.colours.main}`,
        },
    },
}));

export const StyledOrderList = styled.div(({}) => ({
    '.MuiDataGrid-cell': {
        ':first-child': {
            justifyContent: 'start',
        },
        ':nth-child(2)': {
            overflow: 'visible',
            p: {
                color: variables.colours.main,
            },
        },
        ':nth-child(3)': {
            textAlign: 'start',
        },
        ':nth-child(4)': {
            a: {
                display: 'flex',
                justifyContent: 'flex-start',
            },

            '.MuiBox-root': {
                minWidth: '47px',
            },
            p: {
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        ':nth-child(5)': {
            textAlign: 'center',
        },
        ':nth-child(7)': {
            a: {
                justifyContent: 'center',
                textAlign: 'start',
            },
            p: {
                color: variables.colours.main,
                minWidth: '80px',
            },
        },
        '.order-number': {
            position: 'relative',
            width: '100%',
            paddingRight: '30px',
        },
        '.payment-attention': {
            position: 'absolute',
            right: '0',
            top: '50%',
            transform: 'translateY(-50%)',
        },
    },
    '.user-name': {
        color: variables.colours.light,
    },
}));
