import styled from 'styled-components';

export const HowItWorkStyled = styled.div((props: { marginBottom: string }) => ({
    width: '100%',
    marginBottom: props.marginBottom,

    '.container': {
        display: 'flex',
        'flex-wrap': 'wrap',
        justifyContent: 'center',
        rowGap: '20px',
        columnGap: '15px',
    },
}));
