import { FC, useEffect, useState, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import moment from 'moment';
import { FormikErrors } from 'formik';

import { FormHelperText, MenuItem } from '@material-ui/core';

import { Months } from 'shared/constants';

import {
    BirthdayTitle,
    DayInput,
    InputDate,
    MonthSelect,
    StyledFormControl,
    YearInput,
} from 'components/molecules/forms/FlashDatePicker/style';
import { ChangeEvent } from 'react';

export interface FlashDatePickerProps {
    label?: string;
    currentdt: Date | null;
    color: 'primary' | 'secondary';
    onChangeValue?: (value: Date) => void;
    disabled?: boolean;
    error?: boolean | undefined;
    helperText?: FormikErrors<Date> | undefined;
    majority?: boolean;
}

const FlashDatePicker: FC<FlashDatePickerProps> = ({
    currentdt,
    onChangeValue,
    color,
    disabled,
    error,
    helperText,
}) => {
    const { t } = useTranslation();
    const now = new Date();

    const [selectedDate, setSelectedDate] = useState<boolean>(false);
    const [selectedDay, setSelectedDay] = useState<number | null>();
    const [selectedMonts, setSelectedMonth] = useState<number | null>();
    const [selectedYear, setSelectedYear] = useState<number | null>();

    useEffect(() => {
        const currentMoment = moment(currentdt);
        if (currentdt == null) {
            setSelectedDay(null);
            setSelectedMonth(null);
            setSelectedYear(null);
            setSelectedDate(true);
        } else {
            const date = currentMoment.toDate();
            setSelectedDay(date.getDate());
            setSelectedMonth(date.getMonth());
            setSelectedYear(
                date.getFullYear() === parseInt(moment().format('YYYY'))
                    ? parseInt(moment().format('YYYY')) - 18
                    : date.getFullYear(),
            );
            setSelectedDate(true);
        }
    }, [currentdt]);

    useMemo(() => {
        if (selectedDate) {
            if (!!selectedDay && (!!selectedMonts || selectedMonts === 0) && !!selectedYear) {
                const date = moment(currentdt).toDate();
                date.setUTCHours(0);
                date.setFullYear(selectedYear);
                date.setMonth(selectedMonts);
                date.setDate(selectedDay);
                onChangeValue && onChangeValue(date);
            }
        }
    }, [selectedDay, selectedMonts, selectedYear]);

    const daysInMonth = (month?: number | null, year?: number | null) => {
        if (year && month) return new Date(year, month + 1, 0).getDate();
        return 31;
    };

    const renderValue = () => {
        if (selectedMonts || selectedMonts === 0) return <> {t(`months.${Months[selectedMonts]?.number}`)}</>;
    };

    const handleDayChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const maxDay = daysInMonth(selectedMonts, selectedYear);
        const eventDayNumber = event.target.value as number;

        if (eventDayNumber > maxDay || eventDayNumber < 0) {
            setSelectedDay(eventDayNumber > 0 ? maxDay : 1);
        } else {
            setSelectedDay(eventDayNumber);
        }
    };

    const handleMonthChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const month = event.target.value as number;
        const maxDaysInMonth = daysInMonth(month, selectedYear || now.getFullYear());

        if (selectedDay && selectedDay > maxDaysInMonth) {
            setSelectedDay(maxDaysInMonth);
        }
        setTimeout(() => {
            setSelectedMonth(month);
        }, 0);
    };

    const handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const nowYear = new Date().getFullYear();
        const eventYearNumber = event.target.value as number;

        if (eventYearNumber > nowYear - 18) {
            setSelectedYear(nowYear - 18);
        } else {
            setSelectedYear(eventYearNumber);
        }
    };

    const inputFixing = (event: React.KeyboardEvent) => {
        if (['+', '-', 'e', '.', ','].includes(event.key)) event.preventDefault();
    };

    return (
        <StyledFormControl error={!!helperText}>
            <BirthdayTitle>{t('user-form.dateOfBirth')}</BirthdayTitle>
            <InputDate>
                <DayInput
                    autoComplete="bday-day"
                    name="day"
                    color={color}
                    error={error}
                    disabled={disabled}
                    placeholder={t('user.day-of-birth')}
                    value={selectedDay || ''}
                    onChange={(event: ChangeEvent<{ value: unknown }>) => handleDayChange(event)}
                    onKeyDown={(event: React.KeyboardEvent) => inputFixing(event)}
                />
                <MonthSelect
                    autoComplete="bday-month"
                    name="month"
                    color={color}
                    error={error}
                    disabled={disabled}
                    placeholder={t('user.month-of-birth')}
                    renderValue={renderValue}
                    value={selectedMonts === 0 ? selectedMonts : selectedMonts || ''}
                    onChange={(event: ChangeEvent<{ value: unknown }>) => handleMonthChange(event)}
                >
                    {Months.map((month) => (
                        <MenuItem key={month.number} value={month.number}>
                            {t(`months.${month.number}`)}
                        </MenuItem>
                    ))}
                </MonthSelect>
                <YearInput
                    autoComplete="bday-year"
                    name="year"
                    $now={now}
                    color={color}
                    error={error}
                    disabled={disabled}
                    placeholder={t('user.year-of-birth')}
                    value={selectedYear || ''}
                    onChange={(event: ChangeEvent<{ value: unknown }>) => handleYearChange(event)}
                    onKeyDown={(event: React.KeyboardEvent) => inputFixing(event)}
                />
            </InputDate>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </StyledFormControl>
    );
};

export default FlashDatePicker;
