import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const FormControlTextInputStyle = styled.div(({}) => ({
    maxWidth: '16.563rem',
    minWidth: '6.25rem',
    width: '100%',
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',

    '.MuiInputLabel-root.MuiFormLabel-colorSecondary': {
        color: variables.colours.default,
    },
    '.MuiFormControl-root': {
        margin: '0',
    },
    '.MuiInputLabel-outlined': {
        color: variables.colours.default,
        background: variables.colours.light,
        padding: '0.125rem',
    },
    '.MuiOutlinedInput-input': {
        padding: '0.75rem 3rem 0.75rem 0.875rem',
    },
    '.search-icon': {
        position: 'absolute',
        // top: '0.625rem',
        right: '0.625rem',
    },
    '.MuiButtonBase-root.MuiButton-label.MuiBadge-root img': {
        background: variables.colours.default,
    },
    '.MuiInputBase-root': {
        width: '100%',
        height: '100%',
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        maxWidth: '100%',
    },
}));
