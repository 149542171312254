import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const ScheduleTable = styled.table(({}) => ({
    border: `2px solid ${variables.colours.default}`,
    borderRadius: '0.25rem',
    background: variables.backgoroundColours.light,
    boxSizing: 'border-box',
    boxShadow: '0px 0px 8px 3px rgba(0, 0, 0, 0.05)',
    margin: '5rem 0 0.188rem 0',
    width: '100%',
    padding: '1.25rem 1.75rem',
    tr: {
        textAlign: 'left',
    },
    td: {
        padding: '0 1.75rem 1.25rem 1.75rem',
        '.bold': {
            fontWeight: 'bold',
        },
        '.normal': {
            textTransform: 'uppercase',
        },
    },
    th: {
        '&.width': {
            width: '20%',
        },
        '.table-title': {
            textTransform: 'uppercase',
            color: variables.colours.default,
        },
        padding: '1.25rem 1.75rem',
    },
    '.scrollable': {
        overflowY: 'scroll',
        maxHeight: '9.063rem',
        '::-webkit-scrollbar': {
            width: '5px',
            paddingRight: '0.625rem',
        },
        '::-webkit-scrollbar-thumb': {
            background: variables.colours.primary,
            borderRadius: '1.813rem',
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: variables.backgoroundColours.primary,
        },
        '::-webkit-scrollbar-track': {
            borderRadius: 0,
        },
        div: {
            marginBottom: '0.625rem',
        },
    },
    '.mediaspace': {
        paddingLeft: 0,
        justifyContent: 'normal',
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        margin: '2rem 0 0.188rem 0',
    },
}));

export const TableHolder = styled.div((props: { maxWidth: number }) => ({
    maxWidth: props.maxWidth,
    'overflow-x': 'auto',
    'overflow-y': 'hidden',
    '::-webkit-scrollbar': {
        height: '5px',
    },
    '::-webkit-scrollbar-thumb': {
        background: variables.colours.main,
        borderRadius: '29px',
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: variables.backgoroundColours.primary,
    },
    '::-webkit-scrollbar-track': {
        borderRadius: '0px',
    },
}));

export const ExternalHolder = styled.div((props: { maxWidth: number }) => ({
    'overflow-x': 'hidden',
    width: '100%',
    maxWidth: props.maxWidth,
    marginBottom: '1.25rem',
}));
