import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { currenciesAPI } from 'api/currency';
import { CurrencyInputModel, DefaultCurrencyInput } from 'models/currency.model';
import CurrencyCreatePage from '../CurrencyEditForm';

export default function PricesCreateEditPage(): JSX.Element {
    const { id } = useParams<{ id: string }>();

    const [event, setEvent] = useState<CurrencyInputModel>(DefaultCurrencyInput);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!id) {
            setEvent(DefaultCurrencyInput);
        } else {
            const loadEvent = async () => {
                const response = await currenciesAPI.getById(id);
                const event = response.data;
                const inputModel = {
                    name: event.name,
                    code: event.code,
                    isDefault: event.isDefault,
                } as CurrencyInputModel;
                setEvent(inputModel);
            };
            setTimeout(() => loadEvent(), 300);
        }
    }, [id]);

    return <CurrencyCreatePage event={event} id={id} />;
}
