import React from 'react';
import { RoleColor } from './style';

export type MenuItemProps = {
    color: string;
};

export default function MenuItemChip(props: MenuItemProps): JSX.Element {
    const { color } = props;
    return <RoleColor color={color} />;
}
