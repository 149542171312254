import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import PriceEditForm from 'components/organisms/forms/PriceEditForm';

import { getById } from 'api/price';
import { DefaultPriceInput, PriceInputModel } from 'models/price.model';

export default function PricesCreateEditPage(): JSX.Element {
    const { id } = useParams<{ id: string }>();

    const [event, setEvent] = useState<PriceInputModel>(DefaultPriceInput);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!id) {
            setEvent(DefaultPriceInput);
        } else {
            const loadEvent = async () => {
                const response = await getById(id);
                const event = response.data;
                const inputModel = {
                    names: event.names,
                    description: event.description,
                    cost: event.cost,
                    endDate: new Date(event.endDate),
                    startDate: new Date(event.startDate),
                    endsOn: event.endsOn === null || event.endsOn === undefined ? null : new Date(event.endsOn),
                    timeStart: event.timeStart.substring(0, event.timeStart.length - 3),
                    timeEnd: event.timeEnd.substring(0, event.timeEnd.length - 3),
                    isForAllMediaSpaces: event.isForAllMediaSpaces,
                    isAllDay: event.isAllDay,
                    isDefault: event.isDefault,
                    color: event.color,
                    frequency: event.frequency,
                    daysOfWeek: event.daysOfWeek,
                    mediaSpaceUids: event.mediaSpaces.map((x) => x.id),
                    priceCost: event.priceCost,
                } as PriceInputModel;
                setEvent(inputModel);
            };
            setTimeout(() => loadEvent(), 300);
        }
    }, [id]);

    return <PriceEditForm event={event} id={id} />;
}
