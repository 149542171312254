import React, { ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Link as MuiLink, Typography } from '@material-ui/core';

import ResponsiveFlexBox from 'components/atoms/ResponsiveFlexBox';
import NotificationsIcon from 'components/atoms/NotificationImg';

import allRoutes from 'router';

import { StyledNotification } from 'components/atoms/controls/notifications/NotificationLink/style';

export type NotificationLinkProps = {
    header: React.ReactNode;
    body: React.ReactNode;
    icon: ReactElement | null;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    onHeaderClick?: (e: React.MouseEvent<HTMLHeadElement, MouseEvent>) => void;
    chatId?: string;
    orderId?: string;
};

export default function NotificationLink(props: NotificationLinkProps): JSX.Element {
    const { header, body, onClick, onHeaderClick, icon, chatId, orderId } = props;

    return (
        <StyledNotification>
            <MuiLink
                component={Link}
                onClick={onClick}
                to={{ pathname: allRoutes.messagesPage.path, state: { id: orderId ? orderId : chatId } }}
            >
                <ResponsiveFlexBox width="100%">
                    <NotificationsIcon iconMessage={icon} />
                    <div>
                        <Typography variant="subtitle1" onClick={onHeaderClick} className="notification-header">
                            {header}
                        </Typography>
                        <Typography variant="subtitle1" onClick={onHeaderClick} className="notification-text">
                            {body}
                        </Typography>
                    </div>
                </ResponsiveFlexBox>
            </MuiLink>
        </StyledNotification>
    );
}
