import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const StyledPricesGrid = styled.div(({}) => ({
    '.MuiDataGrid-cell': {
        ':first-child': {
            paddingLeft: '0.719rem',
            justifyContent: 'start',
        },
        ':nth-child(2)': {
            a: {
                justifyContent: 'start',
            },
            p: {
                color: variables.colours.main,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        ':nth-child(3)': {
            a: {
                justifyContent: 'start',
            },
        },
        ':nth-child(4)': {
            a: {
                justifyContent: 'start',
            },
            '.infinity': {
                color: variables.colours.main,
            },
        },
        ':nth-child(5)': {
            a: {
                justifyContent: 'start',
                color: variables.colours.dark,
            },
            textTransform: 'uppercase',
        },
    },
    '& .disableColor': {
        color: `${variables.colours.default}!important`,
    },
}));
