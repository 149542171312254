import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Tooltip, Typography } from '@material-ui/core';

import ListItemLink from 'components/atoms/ListItemLink';
import { StyledMenuButton } from 'components/atoms/controls/MenuButton/style';

import { ReactComponent as PaymentAttention } from 'assets/icons/PaymentAttention.svg';

import { variables } from 'theme/variables';
import useSelector from 'hooks/useMenuSellector';

export interface MenuButtonProps {
    label: string;
    icon: JSX.Element;
    path: string;
    isMediaspaces?: boolean;
    isDevices?: boolean;
    click: () => void;
}

const MenuButton: FC<MenuButtonProps> = ({ label, icon, path, isMediaspaces = false, isDevices = false, click }) => {
    const { t } = useTranslation();
    const isReduced = useSelector((state) => state.menuReducer.menu.isCollapsed);

    return (
        <StyledMenuButton onClick={click} className="menu-item">
            <ListItemLink to={path} icon={icon}>
                <Typography variant="subtitle1" className="item-label">
                    {label}
                    {(isMediaspaces || isDevices) && (
                        <Tooltip
                            title={
                                <Typography variant="body1" style={{ color: variables.colours.light }}>
                                    {isMediaspaces && t('media-spaces.attention')}
                                    {isDevices && t('devices.attention')}
                                </Typography>
                            }
                            placement="top-start"
                            arrow
                        >
                            <PaymentAttention className={'attention ' + (isReduced ? 'reduced-item' : '')} />
                        </Tooltip>
                    )}
                </Typography>
            </ListItemLink>
        </StyledMenuButton>
    );
};

export default MenuButton;
