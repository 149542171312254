import { variables } from '../../../theme/variables';
import styled from 'styled-components';

export const GridHolder = styled.div((props: { rowsCount: number; maxWidth: number }) => ({
    height: props.rowsCount * 75 + 8 + 66,
    '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
        height: props.rowsCount * 55 + 25 + 66,
    },
    '@media only screen and (min-width: 1440px)': {
        height: props.rowsCount * 60 + 5 + 66,
    },
    maxWidth: props.maxWidth,
    'overflow-x': 'auto',
    'overflow-y': 'hidden',
    minHeight: 120,
    '.MuiDataGrid-root': {
        border: 'none',
        minWidth: 800,
        '.MuiDataGrid-window': {
            height: props.rowsCount * 100 + 8,
            maxHeight: `${props.rowsCount * 80 + 8}px !important`,
            '.MuiDataGrid-dataContainer': {
                '.MuiDataGrid-viewport': {
                    maxHeight: `${props.rowsCount * 100 + 8}px !important`,
                    height: props.rowsCount * 100 + 8,
                },
            },
        },
    },
    '::-webkit-scrollbar': {
        height: '5px',
    },
    '::-webkit-scrollbar-thumb': {
        background: variables.colours.main,
        borderRadius: '29px',
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: variables.backgoroundColours.primary,
    },
    '::-webkit-scrollbar-track': {
        borderRadius: '0px',
    },
}));

export const StyledDataGridDiv = styled.div((props: { maxWidth: number }) => ({
    width: '100%',
    'overflow-x': 'hidden',
    maxWidth: props.maxWidth,
    '.MuiDataGrid-columnsContainer': {
        backgroundColor: variables.colours.main,
        borderRadius: '0.25rem',
    },
    '.MuiDataGrid-columnHeaderTitle': {
        color: variables.colours.light,
        fontSize: '0.938rem',
    },
    '.MuiDataGrid-columnSeparator': {
        display: 'none',
    },
    '.MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-root .MuiDataGrid-cell': {
        padding: 0,
    },
    '.MuiDataGrid-selectedRowCount': {
        display: 'none',
    },
    // .MuiDataGrid-colCell deprecated, use MuiDataGrid-columnHeader
    '.MuiDataGrid-columnHeader': {
        '.MuiDataGrid-columnHeaderTitleContainer': {
            padding: '0!important',
        },
        '.MuiIconButton-root': {
            color: variables.colours.light,
            // paddingLeft: '1.375rem',
        },
        padding: '0',
        ':focus': {
            outline: 'none',
        },
        '.MuiDataGrid-columnSeparator': {
            display: 'none',
        },
        '.MuiDataGrid-menuIcon': {
            display: 'none',
        },
    },
    '.MuiDataGrid-window': {
        overflow: 'hidden!important',
    },
    '.MuiDataGrid-cell': {
        height: '4.375rem',
        maxHeight: '100%!important',
        display: 'flex',
        border: 'none',
        padding: '0',
        alignItems: 'center',
        '&.MuiDataGrid-cellCenter': {
            justifyContent: 'center',
        },
        ':first-child': {
            paddingLeft: '0.719rem',
            justifyContent: 'start',
        },
    },
    '.MuiDataGrid-row': {
        '&:first-child ': {
            marginTop: '0.313rem',
        },
        'font-weight': 'normal',
        cursor: 'pointer',
        border: `1px solid ${variables.backgoroundColours.border}`,
        height: '4.375rem',
        maxHeight: '100%!important',
        width: '100%',
        paddingLeft: '6px',
        borderRadius: '4px',
        marginBottom: '0.313rem',
        ':hover': {
            borderLeft: `6px solid ${variables.colours.primary}`,
            paddingLeft: '1px',
            boxShadow: '0px 1px 9px rgba(0, 0, 0, 0.15)',
        },
    },
    '.MuiDataGrid-renderingZone': {
        width: '100%!important',
    },
    '.MuiDataGrid-viewport': {
        minWidth: 'auto!important',
    },
    '.MuiDataGrid-footer': {
        display: 'none',
    },
    '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
        '.MuiDataGrid-row': {
            height: '3.125rem',
        },
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        '.MuiDataGrid-row': {
            height: '3.125rem',
        },
    },
}));
