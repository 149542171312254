import * as React from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import allRoutes from '../../../../router';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';
import { Avatar, Box, MenuItem, Typography } from '@material-ui/core';
import Button from '../../../atoms/controls/Button';
import { CreateOrderLink, StyledDrawer } from './style';
import IconButton from '../../../atoms/controls/IconButton';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';
import { UserRoles } from '../../../../shared/enums';
import GoToOrdersChatLink from '../../../atoms/controls/GoToOrdersChatLink';
import NavigationLink from '../../../molecules/NavigationLink';
import LogoutButton from '../../../molecules/LogoutButton';
import ManagerSideMenuItems from '../ManagerSideMenuItems';
import FormChangeLanguage from '../../forms/FormChangeLanguage';
import FormChangeCurrency from '../../forms/FormChangeCurrency';
import { useEffect, useState } from 'react';
import { currenciesAPI } from '../../../../api/currency';
import MenuItemLink from 'components/atoms/MenuItemLink';

export type SideMenuMobileProps = {
    openMenu: boolean;
    setOpenMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SideMenuMobile(props: SideMenuMobileProps): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.userReducer.user);

    const { openMenu, setOpenMenu } = props;
    const [thereAreCurrencies, setThereAreCurrencies] = useState<boolean>(false);

    useEffect(() => {
        async function fetchData() {
            const response = await currenciesAPI.getCurrencies();
            if (response.data.length > 1) {
                setThereAreCurrencies(true);
            }
        }
        fetchData();
    }, []);

    return (
        <StyledDrawer
            variant="persistent"
            anchor="right"
            open={openMenu}
            onClose={() => {
                setOpenMenu(false);
            }}
        >
            {user.role !== '' ? (
                <>
                    <Box display="flex" justifyContent="flex-end" marginTop="20px">
                        <IconButton className="cancel-button" onClick={() => setOpenMenu(false)} name="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <List onClick={() => setOpenMenu(false)}>
                        <Box
                            display="flex"
                            alignItems="center"
                            marginBottom="20px"
                            onClick={() => {
                                history.push(generatePath(allRoutes.userProfile.path, { id: user.nameid }));
                            }}
                            data-id="profile-user"
                        >
                            <Avatar
                                style={{ width: '35px', height: '35px' }}
                                alt={user.given_name + ' ' + user.family_name}
                                src={user.pic}
                                className="avatar"
                            >
                                <Typography variant="subtitle1" className="avatar-name">
                                    {user.given_name[0]}
                                </Typography>
                            </Avatar>
                            <Typography variant="subtitle1" className="user-name">
                                {user.given_name + ' ' + user.family_name}
                            </Typography>
                        </Box>
                        <FormChangeLanguage
                            title={t('navigation.language-title')}
                            button={
                                <MenuItem data-id="change-language">
                                    <Typography variant="body1" color="primary">
                                        {t('navigation.language')}
                                    </Typography>
                                </MenuItem>
                            }
                        />
                        {thereAreCurrencies && (
                            <FormChangeCurrency
                                title={t('navigation.currency-title')}
                                onClick={() => null}
                                button={
                                    <MenuItem data-id="change-language">
                                        <Typography variant="body1" color="primary">
                                            {t('navigation.currency')}
                                        </Typography>
                                    </MenuItem>
                                }
                            />
                        )}
                        <br />
                        <Divider />
                        {user.role !== UserRoles.User && <ManagerSideMenuItems />}
                        {user.role === UserRoles.User && (
                            <>
                                <NavigationLink
                                    route={allRoutes.orders}
                                    routerState={{ pageId: 1 }}
                                    className="order-menu-item"
                                    name="orders-history"
                                >
                                    <Typography variant="subtitle1">{t('navigation.orders-history')}</Typography>
                                </NavigationLink>
                                <br />
                                <GoToOrdersChatLink data-id="chat-link" />
                            </>
                        )}
                        <br />
                        {user.role === UserRoles.User && (
                            <>
                                <CreateOrderLink to={allRoutes.createOrder.path}>
                                    <Typography variant="h5" className="create-text">
                                        {t('media-space-form.button-text.create')}
                                    </Typography>
                                </CreateOrderLink>
                                <br />
                            </>
                        )}
                        <Divider />
                        <LogoutButton
                            button={
                                <Button className="log-out" name="log-out">
                                    <Typography variant="subtitle1">{t('navigation.logout')}</Typography>
                                </Button>
                            }
                        />
                    </List>
                </>
            ) : (
                <>
                    <Box display="flex" justifyContent="flex-end" marginTop="20px">
                        <IconButton className="cancel-button" onClick={() => setOpenMenu(false)} name="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <List onClick={() => setOpenMenu(false)}>
                        <FormChangeLanguage
                            title={t('navigation.language-title')}
                            button={
                                <MenuItem data-id="change-language">
                                    <Typography variant="body1" color="primary">
                                        {t('navigation.language')}
                                    </Typography>
                                </MenuItem>
                            }
                        />
                        {thereAreCurrencies && (
                            <FormChangeCurrency
                                title={t('navigation.currency-title')}
                                onClick={() => null}
                                button={
                                    <MenuItem data-id="change-language">
                                        <Typography variant="body1" color="primary">
                                            {t('navigation.currency')}
                                        </Typography>
                                    </MenuItem>
                                }
                            />
                        )}
                        <br />
                        <Divider />
                        <br />
                        <MenuItemLink to={allRoutes.signin.path}>
                            <Typography variant="body1" color="primary">
                                {t('navigation.login')}
                            </Typography>
                        </MenuItemLink>
                        <MenuItemLink to={allRoutes.signup.path}>
                            <Typography variant="body1" color="primary">
                                {t('home-page.intro.button')}
                            </Typography>
                        </MenuItemLink>
                    </List>
                </>
            )}
        </StyledDrawer>
    );
}
