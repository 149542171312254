import { FC, useEffect, useState } from 'react';
import { StyledContainer } from './style';
import { Typography, Box, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MediaSpaceImageModel } from 'models/media-space.model';

export interface PhotoUploadProps {
    handleBase64image: (value: MediaSpaceImageModel) => void;
    photoImgSrc: string | null | undefined;
    disabled?: boolean;
}

const ImageUploader: FC<PhotoUploadProps> = ({ handleBase64image, photoImgSrc, disabled }) => {
    const { t } = useTranslation();
    const [filename, setFilename] = useState('');
    const [imageUrl, setImageUrl] = useState<string | null | undefined>(undefined);

    useEffect(() => {
        async function fetchData() {
            setImageUrl(photoImgSrc);
        }

        fetchData();
    }, [photoImgSrc]);

    const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const img = e.target.files[0];
            setImageUrl(URL.createObjectURL(img));
            const profilePhotoModel: MediaSpaceImageModel = {
                file: e.target.files[0],
                url: URL.createObjectURL(img),
            };
            setFilename(img.name);
            handleBase64image(profilePhotoModel);
        }
    };

    return (
        <StyledContainer>
            <Button component="label" variant="contained" color="primary" className="upload-button" disabled={disabled}>
                <Typography variant="h5">{t('media-space-form.image.upload-image')}</Typography>
                <input type="file" disabled={disabled} hidden onChange={onImageChange} />
            </Button>
            <Box component="span">{filename || imageUrl}</Box>
        </StyledContainer>
    );
};

export default ImageUploader;
