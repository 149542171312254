import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { OrderVm } from '../../../models/order';
import { OrderNumber, ScheduleTable, OrderCost, TableHolder, ExternalHolder } from './style';
import { MomentFormats, OrderFrequency } from '../../../shared/enums';
import moment from 'moment';
import OrderStatus from '../../atoms/Order/OrderStatus';
import OrderContentPreview from '../../atoms/Order/OrderContentPreview';
import GoToOrderChatButton from '../../atoms/controls/GoToOrderChatButton';
import NavigationLink from '../../molecules/NavigationLink';
import { Box, Typography } from '@material-ui/core';
import allRoutes from '../../../router';
import useWindowDimensions from '../../../hooks/WindowDimensions';

export type OrderProfileProps = {
    order: OrderVm;
};

export default function OrderProfile(props: OrderProfileProps): JSX.Element {
    const { t } = useTranslation();
    const { order } = props;

    const history = useHistory();
    const { width } = useWindowDimensions();

    return (
        <ExternalHolder maxWidth={width}>
            <TableHolder maxWidth={width}>
                <ScheduleTable>
                    <thead>
                        <tr>
                            <th>
                                <OrderNumber>
                                    <NavigationLink
                                        onClick={() =>
                                            history.push(generatePath(allRoutes.orderProfile.path, { id: order?.id }))
                                        }
                                        className="order-title"
                                        name="order"
                                    >
                                        <Typography variant="h4" className="order-number">
                                            {t('order-form.order-title')} {order?.number}
                                        </Typography>
                                    </NavigationLink>
                                </OrderNumber>
                            </th>
                            <th />
                            <th />
                            <th className="order-status">
                                <OrderStatus status={order.status} />
                            </th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th className="table-title">
                                <Typography variant="h6">{t('order.file')}</Typography>
                            </th>
                            <th className="table-title">
                                <Typography variant="h6">{t('order.date')}</Typography>
                            </th>
                            <th className="table-title">
                                <Typography variant="h6">{t('order.time')}</Typography>
                            </th>
                            <th>
                                <Typography variant="h6">{t('order.platform')}</Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Box height="170px">
                                    <OrderContentPreview height="170px" order={order} data-id="content-preview" />
                                </Box>
                            </td>
                            <td>
                                {order?.schedules.map((val) => (
                                    <div key={val.id}>
                                        <div>
                                            <Typography variant="body1" className="bold">
                                                {moment(val.startDate).format(MomentFormats.Short)} -
                                                {moment(val.endDate).format(MomentFormats.Short)}
                                            </Typography>
                                        </div>
                                        <div>
                                            <Typography variant="body1" className="normal">
                                                {OrderFrequency[val.frequency]}
                                            </Typography>
                                        </div>
                                    </div>
                                ))}
                            </td>
                            <td>
                                {order?.schedules.map((val) => (
                                    <div key={val.id}>
                                        <Typography variant="body1" className="bold">
                                            {moment(val.startTime, MomentFormats.LongTime).format(MomentFormats.Time)} -{' '}
                                            {moment(val.endTime, MomentFormats.LongTime).format(MomentFormats.Time)}
                                        </Typography>
                                    </div>
                                ))}
                            </td>
                            <td>
                                <div className="scrollable">
                                    {order?.mediaSpaces?.map((val) => (
                                        <div key={val.id}>
                                            <Typography variant="body1" className="normal">
                                                {val.address}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="order-cost">
                                <OrderCost>
                                    <>
                                        <Typography variant="body1" className="order-cost">
                                            {t('order.cost')}
                                        </Typography>
                                    </>
                                    <>
                                        <Typography variant="body1" className="order-cost">
                                            {order?.schedules.reduce((sum, current) => sum + current.cost, 0)}{' '}
                                            {order.currencyCode}
                                        </Typography>
                                    </>
                                </OrderCost>
                            </td>
                            <td />
                            <td />
                            <td className="chat-button-cell">
                                <GoToOrderChatButton
                                    orderId={order.id}
                                    orderNumber={order.number}
                                    orderStatus={order.status}
                                    chatId={order.currentChatUid}
                                    className="chat-button"
                                    name="chat-button"
                                />
                                <NavigationLink
                                    className="chat-button"
                                    color="primary"
                                    onClick={() =>
                                        history.push(generatePath(allRoutes.orderProfile.path, { id: order?.id }))
                                    }
                                >
                                    <Typography variant="subtitle1">{t('order.order-details-btn')}</Typography>
                                </NavigationLink>
                            </td>
                        </tr>
                    </tfoot>
                </ScheduleTable>
            </TableHolder>
        </ExternalHolder>
    );
}
