import { AxiosResponse } from 'axios';
import axios from './axios';
import { ContentMetadataVm } from '../models/video';

export const getVideoSrc = (name: string): string => {
    return `${window.ENV.config.backendUrl}/uploads/${name}`;
};

export const getVideoFileSrc = (videoId: string, orderId: string): string => {
    let token = '';
    const root = window.localStorage['persist:root'];
    if (root) {
        const tokens = JSON.parse(JSON.parse(root).authReducer);
        if (tokens?.auth?.accessToken) {
            token = tokens.auth.accessToken;
        }
    }
    return `${window.ENV.config.backendUrl}/api/videos/${videoId}/order/${orderId}?access_token=${token}`;
    //return `${window.ENV.config.backendUrl}/api/videos?orderId=${orderId}&access_token=${token}&videoId=${videoId}`;
};

export const getContentMetadata = (videoId: string, orderId: string): Promise<AxiosResponse<ContentMetadataVm>> => {
    return axios.get(`videos/${videoId}/order/${orderId}/metadata`);
};

export const getAbsoluteFilePath = (videoId: string, orderId: string): Promise<AxiosResponse<BlobPart[]>> => {
    return axios.get(`videos/file/${videoId}/order/${orderId}`);
};

export const updateVideo = (videoId: string, orderId: string, duration: number): Promise<AxiosResponse> => {
    return axios.post(`videos/${videoId}/newOrder/${orderId}?duration=${duration}`);
};

export const create = async (video: File, orderId: string, duration: number): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append('video', video);

    const response = await axios.post(`videos?orderId=${orderId}&duration=${Math.ceil(duration)}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response;
};

export const uploadDetached = async (video: File, duration: number): Promise<AxiosResponse> => {
    const formData = new FormData();
    formData.append('video', video);

    const response = await axios.post(`videos/detached?duration=${Math.ceil(duration)}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return response;
};
