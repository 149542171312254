import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { GridCellParams, GridColDef, GridRowData } from '@material-ui/data-grid';
import { Box, Button, Typography } from '@material-ui/core';

import GridCellLink from 'components/atoms/GridCellLink';

import { RootState } from 'store/rootReducer';

import allRoutes from 'router';

import { MediaSpaceVm } from 'models/media-space.model';
import { TariffsValidityReasons, UserRoles, ValidationRules } from 'shared/enums';

import { ReactComponent as DeleteButton } from 'assets/icons/DeleteButton.svg';
import { ReactComponent as PaymentAttention } from 'assets/icons/PaymentAttention.svg';

import { variables } from 'theme/variables';
import { getDateWithLocalTz } from 'hooks/getDateWithLocalTz';
import { RedTypography } from './style';
import { getCurrentDate } from 'hooks/getCurrentDate';
import { getMinimumDateOfTariffCoverage } from '../../../hooks/getMinimumDateOfTariffCoverage';
import { DeviceVm } from 'models/device.model';
import FlashGridHeader from '../../atoms/FlashGridHeader';
import FlashTooltip from 'components/atoms/FlashTooltip';
import { SortField } from '../../../models/paging/paging.model';

type MediaSpacesGridProps = {
    mediaSpaces: MediaSpaceVm[];
    setMediaSpaceId: React.Dispatch<React.SetStateAction<string>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

type RenderAlertProps = {
    value: boolean;
    message: string;
};

export default function MediaSpacesColumnsGrid(props: MediaSpacesGridProps): GridColDef[] {
    const { mediaSpaces, setMediaSpaceId, setOpen } = props;
    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.userReducer.user);
    const history = useHistory();

    function renderRowActions(params: GridCellParams) {
        return (
            <>
                {user.role === UserRoles.Admin && (
                    <Button
                        size="small"
                        onClick={() => {
                            setMediaSpaceId(params.id as string);
                            deleteMediaSpace(params.id as string);
                        }}
                        name="delete-button"
                    >
                        <DeleteButton />
                    </Button>
                )}
            </>
        );
    }

    async function deleteMediaSpace(id: string) {
        if (id) {
            handleClickOpen();
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const RenderAlert: React.FC<RenderAlertProps> = ({ value, message }) => {
        if (!value) return <div />;
        return (
            <FlashTooltip
                disableHighlighting
                title={
                    <Typography variant="body1" style={{ color: variables.colours.light }}>
                        {message}
                    </Typography>
                }
                placement="top-start"
                arrow
            >
                <PaymentAttention className="payment-attention" />
            </FlashTooltip>
        );
    };

    const getColumns = useCallback(() => {
        return [
            {
                field: SortField.MediaSpaceName,
                width: 200,
                headerAlign: 'left',
                align: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'media-space.name'} withPadding={true} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    const invalidStatus =
                        params.row.tariffsValidityStatus === ValidationRules.Invalid &&
                        !!params.row.tariffsValidityReason;
                    const messageData = {
                        date: getMinimumDateOfTariffCoverage(),
                    };
                    let errorText = t('media-space.no-prices');
                    if (params.row.tariffsValidityReason == TariffsValidityReasons.NotFull) {
                        errorText = t('media-space.not-full', messageData);
                    } else if (params.row.tariffsValidityReason == TariffsValidityReasons.TariffNotValid) {
                        errorText = t('media-space.price-invalid');
                    }
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.mediaSpaceProfile.path, {
                                    id: params.row.id,
                                    pageId: 1,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            <Box display="flex" className="mediaspace-name">
                                <Typography variant="body1">{params.row.name}</Typography>
                                <RenderAlert value={invalidStatus} message={errorText} />
                            </Box>
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.MediaSpaceAddress,
                flex: 1,
                headerAlign: 'left',
                align: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'media-space.address'} withPadding={true} paddingLeft={6} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.mediaSpaceProfile.path, {
                                    id: params.row.id,
                                    pageId: 1,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            <Typography variant="body1">{params.row.address}</Typography>
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.MediaSpaceDevicesCount,
                flex: 1,
                headerAlign: 'center',
                align: 'center',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'media-space.devices-count'} withPadding={false} />;
                },
                valueGetter: (params: GridCellParams) => {
                    let result = 0;
                    if (params.row.devices.length > 0) {
                        const timeWhenDeviceIsOnline = 10;
                        const availableDevices = params.row.devices?.filter((elem: DeviceVm) => {
                            const lastConnectionDate = getDateWithLocalTz(elem.lastConnectionDate);
                            return lastConnectionDate >= getCurrentDate(timeWhenDeviceIsOnline);
                        }).length;
                        result = 1 - availableDevices / params.row.devices.length;
                    }
                    return result;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    const noDevices = (params.row.devices?.length ?? 0) === 0;
                    const devicesTotal = params.row.devices?.length ?? 0;
                    const timeWhenDeviceIsOnline = 10;
                    const availableDevices = params.row.devices?.filter((elem: GridRowData['string']) => {
                        const lastConnectionDate = getDateWithLocalTz(elem.lastConnectionDate);
                        return lastConnectionDate >= getCurrentDate(timeWhenDeviceIsOnline);
                    }).length;
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.mediaSpaceProfile.path, {
                                    id: params.row.id,
                                    pageId: 1,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            <Box display="flex" className="mediaspace-name">
                                {availableDevices < devicesTotal || devicesTotal === 0 ? (
                                    <RedTypography>
                                        {availableDevices} / {devicesTotal}
                                    </RedTypography>
                                ) : (
                                    <Typography variant="body1">
                                        {availableDevices} / {devicesTotal}
                                    </Typography>
                                )}
                                <RenderAlert value={noDevices} message={t('media-space.no-devices')} />
                                <RenderAlert
                                    value={availableDevices < devicesTotal}
                                    message={t('media-space.no-available-devices')}
                                />
                            </Box>
                        </GridCellLink>
                    );
                },
            },
            {
                field: 'isDeleted',
                headerAlign: 'center',
                align: 'center',
                headerName: user.role === UserRoles.Admin ? t('media-space.actions') : ' ',
                width: 100,
                renderCell: (params: GridCellParams) => renderRowActions(params),
                sortable: false,
                disableClickEventBubbling: true,
            },
        ] as GridColDef[];
    }, [mediaSpaces]);

    return getColumns();
}
