import { FormControlLabel, RadioGroup } from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import FlashDialog from 'components/atoms/dialog/FlashDialog';
import YesNoButtons from 'components/atoms/dialog/YesNoButtons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refresh, updateUserFromAccessToken } from 'api/auth';
import { RadioStyled } from './style';
import { updateRefresh } from 'store/auth/actions';
import { RootState } from '../../../../store/rootReducer';
import { setLanguage } from 'api/profile';
import { AccessToken } from 'interface/accessToken';
import i18n from 'i18n/config';
import { Languages } from '../../../../shared/enums';
import { getLanguages } from '../../../../api/language';
import { LanguageVm } from '../../../../models/language.model';
import { updateLc } from 'store/user/actions';

export type ConfirmButtonProps = {
    button: React.ReactElement;
    title: string;
};

export default function FormChangeLanguage({ button, title }: ConfirmButtonProps): JSX.Element {
    const auth = useSelector((state: RootState) => state.authReducer.auth);
    const dispatch = useDispatch();
    const [isShownPopup, setIsShownPopup] = useState(false);
    const [radioValue, setRadioValue] = React.useState(i18n.language);
    const [languages, setLanguages] = useState<LanguageVm[]>([]);

    const radioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
    };

    const handleClick = () => {
        setIsShownPopup(true);
    };

    let btnLock = false;
    const saveLocale = async () => {
        if (btnLock) return;
        btnLock = true;
        if (auth?.accessToken !== '') {
            const response = await setLanguage(radioValue);
            if (response.data) {
                const refreshResponse = await refresh({
                    accessToken: auth?.accessToken ?? '',
                    refreshToken: auth?.refreshToken ?? '',
                });
                updateUserFromAccessToken(refreshResponse.data.accessToken);
                const { exp: expRT } = jwt_decode(refreshResponse.data.refreshToken) as AccessToken;
                dispatch(
                    updateRefresh({
                        refreshToken: refreshResponse.data.refreshToken,
                        refreshTokenTTL: expRT,
                    }),
                );
            }
        } else {
            const lc = localStorage.getItem('locale');
            if (lc !== radioValue) {
                i18n.changeLanguage(radioValue || Languages.Russian);
                localStorage.setItem('locale', radioValue);
                dispatch(updateLc(radioValue));
            }
        }
        setIsShownPopup(false);
        btnLock = false;
    };

    useEffect(() => {
        if (isShownPopup) {
            const fetchData = async () => {
                const response = await getLanguages();
                console.log('responseLang', response);
                setLanguages(response.data);
            };
            fetchData();
        }
    }, [isShownPopup]);

    return (
        <>
            {React.cloneElement(button, { onClick: handleClick })}
            <FlashDialog
                open={isShownPopup}
                title={title}
                isOpen={isShownPopup}
                onClose={() => setIsShownPopup(false)}
                content={
                    <RadioGroup aria-labelledby="langugage" name="langugage" value={radioValue} onChange={radioChange}>
                        {Array.isArray(languages) &&
                            languages.map((item, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={item.code}
                                    control={<RadioStyled />}
                                    label={item.name}
                                />
                            ))}
                    </RadioGroup>
                }
                controls={<YesNoButtons onConfirm={saveLocale} onReject={() => setIsShownPopup(false)} />}
            />
        </>
    );
}
