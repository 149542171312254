import { FC, forwardRef, useMemo, ReactNode } from 'react';

import { Link } from 'react-router-dom';
import { LinkProps } from 'react-router-dom';

import { MenuItem, MenuItemProps } from '@material-ui/core';

type MenuItemLinkProps = MenuItemProps & {
    to: string;
    children: ReactNode;
};

const MenuItemLink: FC<MenuItemLinkProps> = ({ to, children }) => {
    const renderLink = useMemo(
        () =>
            // eslint-disable-next-line react/display-name
            forwardRef<HTMLAnchorElement | null, Omit<LinkProps, 'to'>>((itemProps, ref) => (
                <Link to={to} ref={ref} {...itemProps} />
            )),
        [to],
    );

    return <MenuItem component={renderLink}>{children}</MenuItem>;
};

export default MenuItemLink;
