import { ButtonBase, ButtonBaseProps, lighten, Link, LinkProps, Slider } from '@material-ui/core';
import styled from 'styled-components';
import { variables } from 'theme/variables';
import BackgroundImage from 'assets/images/calculate-background.png';
import ImageChevronDown from 'assets/icons/downButton.png';

export const CalculateCostStyled = styled.div({
    width: '100%',
    padding: '50px 60px 40px',
    backgroundColor: variables.backgoroundColours.light,
    border: `1px solid ${variables.backgoroundColours.light}`,
    borderRadius: '20px',
    boxShadow: '0px 4px 10px 0 rgba(0, 0, 0, 0.5)',

    '@media(max-width: 480px)': {
        padding: '25px 15px',
    },

    '.top': {
        position: 'relative',
        'z-index': '1',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: '40px',

        '@media(max-width: 1024px)': {
            flexDirection: 'column',

            '.column': {
                width: '100%!important',
                marginBottom: '50px',
            },
        },

        '.column': {
            display: 'flex',
            flexDirection: 'column',

            '@media(max-width: 1024px)': {
                width: '100%!important',
                marginBottom: '50px',
            },

            '&.column-1': {
                width: '23%',
            },

            '&.column-2': {
                width: '35%',
            },

            '&.column-3': {
                width: '32%',
            },

            '.top-title': {
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '28px',

                '@media(max-width: 1024px)': {
                    marginBottom: '18px',
                },

                '> p': {
                    'font-weight': '500',
                    fontSize: '30px',
                    lineHeight: '137.01%',
                    textAlign: 'center',
                    color: variables.colours.dark,
                },

                '.quantity-title': {
                    flex: 1,
                    paddingRight: '15px',
                    textAlign: 'left',
                },

                '.quantity': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    maxWidth: '80px',
                    overflow: 'hidden',
                    color: variables.colours.primary,
                },
            },

            '.duration': {
                width: '165px',
                borderWidth: '2px',
                padding: '6px 10px',
            },

            '.select': {
                '.MuiInput-root': {
                    width: '100%',
                    marginBottom: '17px',
                    border: `2px solid ${variables.colours.primary}`,
                    borderRadius: '8px',
                    height: 'auto',

                    '::before, ::after': {
                        content: 'none',
                    },

                    '.MuiInputBase-input': {
                        paddingBottom: '0.4rem',
                    },

                    '.MuiSelect-selectMenu': {
                        minHeight: '32px',
                        padding: '16px 55px 2px 20px',
                        fontSize: '18px',
                        color: variables.colours.dark,
                    },

                    '.MuiSelect-icon': {
                        top: '50%',
                        right: '27px',
                        marginTop: '-5px',
                    },
                },
            },
        },
    },

    '.middle': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        paddingRight: '390px',

        '@media(max-width: 1024px)': {
            paddingRight: '0',
        },

        '::after': {
            content: '""',
            position: 'absolute',
            bottom: '0',
            right: '0',
            'z-index': '0',
            width: '372px',
            height: '399px',
            backgroundImage: `url('${BackgroundImage}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',

            '@media(max-width: 1024px)': {
                content: 'none',
            },
        },

        '.middle-title': {
            fontFamily: 'MagistralCTT',
            'font-weight': '700',
            fontSize: '50px',
            lineHeight: '137.01%',
            color: variables.colours.dark,
        },

        '.total': {
            position: 'relative',
            zIndex: 9,
            display: 'flex',
            flexWrap: 'wrap',
            width: '630px',
            marginBottom: '30px',
            fontFamily: 'MagistralCTT',
            'font-weight': '700',
            fontSize: '100px',
            lineHeight: '137%',
            color: variables.colours.dark,

            '@media(max-width: 768px)': {
                width: '100%',
                fontSize: '13vw',
            },

            '.value': {
                paddingRight: '10px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },

            '.currency': {
                textAlign: 'left',
            },
        },
    },
});

export const CalculateCostSelectIcon = styled.div({
    width: '22px',
    height: '11px',
    backgroundImage: `url('${ImageChevronDown}')`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
});

export const CalculateCostSlider = styled(Slider).attrs({
    defaultValue: 10,
    'aria-label': 'quantity',
})({
    width: '100%',
    height: '8px',

    '.MuiSlider-rail': {
        height: '8px',
    },

    '.MuiSlider-track': {
        height: '8px',
    },

    '.MuiSlider-thumb': {
        width: '20px',
        height: '20px',
    },
});

export const CalculateCostButtoneStyled = styled(ButtonBase).attrs({
    component: Link,
})<ButtonBaseProps & LinkProps>({
    padding: '22px 60px',
    borderRadius: 8,
    backgroundColor: variables.colours.primary,
    transition: '.2s ease',
    color: '#FFF',

    ':hover': {
        backgroundColor: lighten(variables.colours.primary, 0.15),
        textDecoration: 'none',
    },

    '.button-text': {
        'font-weight': '400',
        fontSize: '30px',
        lineHeight: '1',
        color: variables.colours.light,
    },
});
