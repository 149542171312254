import React, { useEffect, useRef } from 'react';
import * as SignalR from '@microsoft/signalr';
import { HubConnection } from '@microsoft/signalr';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { NotificationShortVm, NotificationType } from '../../../models/notification-model';
import { User } from '../../../store/user/types';
import { HubEvents } from '../forms/ChatForm';
import audio from '../../../assets/audio/notification.mp3';
import { DeviceScreenshotVm } from 'models/device.model';
import { logOut } from '../../../api/auth';
import { removeTokens } from '../../../store/auth/actions';
import { removeUser } from '../../../store/user/actions';
import { store } from '../../../store';
import { getNotifications } from '../../../api/chat';

interface NotificationContextInterface {
    totalNotificationsCount: number;
    chatNotificationsCount: number;
    orderNotificationsCount: number;
    notifications: Array<NotificationShortVm>;
    hub: HubConnection;
    updateHub: () => void;
}

export type NotificationProviderProps = {
    children?: JSX.Element;
    user: User;
};

const NotificationContext = React.createContext<Partial<NotificationContextInterface>>({});

const NotificationProvider = (props: NotificationProviderProps): JSX.Element => {
    const { user } = props;
    const { dispatch } = store;

    const token = useSelector((state: RootState) => state.authReducer.auth.accessToken) as string;
    const connection = React.useRef<SignalR.HubConnection | null>(null);
    const auth = useSelector((state: RootState) => state.authReducer.auth);

    const isSignedIn = user.nameid !== '';

    const [notifications, setNotifications] = React.useState(Array<NotificationShortVm>());
    const [screenshot, setScreenshot] = React.useState<DeviceScreenshotVm>();

    const [isTokenRefreshed, setIsTokenRefreshed] = React.useState(false);

    const chatNotificationsCount =
        notifications?.filter((x) => x.actionType == NotificationType.chatMessage).length ?? 0;
    const orderNotificationsCount =
        notifications?.filter((x) => x.actionType == NotificationType.createOrder).length ?? 0;

    const stateRef = useRef<{ notifications: Array<NotificationShortVm> }>();
    stateRef.current = { notifications: notifications };

    const screenshotRef = useRef<{ screenshot: DeviceScreenshotVm }>();
    if (screenshot != null) {
        screenshotRef.current = { screenshot: screenshot };
    }

    const playAudio = () => {
        new Audio(audio).play().catch();
    };

    const makeConnection = () => {
        getNotificationsAsync(token);
        connection.current = new SignalR.HubConnectionBuilder()
            .withAutomaticReconnect()
            .withUrl(`${window.ENV.config.backendUrl}/hub/chat`, {
                skipNegotiation: true,
                transport: SignalR.HttpTransportType.WebSockets,
                accessTokenFactory: () => token,
            })
            .build();
        connection.current.start();
        connection.current?.on(HubEvents.onNotification, (data: NotificationShortVm) => {
            playAudio();
            if (data) {
                const notificationsCopy = [...(stateRef.current?.notifications || [])];
                notificationsCopy.unshift(data);
                setNotifications(sortByDate(notificationsCopy));
            }
        });
        connection.current?.on(HubEvents.onNotificationsUpdate, (data: NotificationShortVm[]) => {
            setNotifications(sortByDate(data));
        });
        connection.current?.on(HubEvents.onNewScreenshot, (data: DeviceScreenshotVm) => {
            if (data) {
                setScreenshot(data);
            }
        });
        connection.current?.on(HubEvents.onUserDisabled, async () => {
            try {
                await logOut({
                    accessToken: auth.accessToken || '',
                    refreshToken: auth.refreshToken || '',
                });
            } finally {
                dispatch(removeTokens());
                dispatch(removeUser());
            }
        });
    };

    function sortByDate(arr: Array<NotificationShortVm>) {
        return [...arr].sort(
            (objA, objB) => new Date(objB.updatedDate).getTime() - new Date(objA.updatedDate).getTime(),
        );
    }

    const getNotificationsAsync = async (token: string) => {
        const res = await getNotifications(token);
        setNotifications(sortByDate(res.data));
    };

    useEffect(() => {
        if (isSignedIn && token !== '') {
            if (!connection.current) {
                makeConnection();
                setIsTokenRefreshed(true);
            } else if (isTokenRefreshed) {
                connection.current?.stop();
                makeConnection();
            }
        } else if (!isSignedIn && connection.current) {
            connection.current?.stop();
            connection.current = null;
        }

        return () => {
            connection.current?.off(HubEvents.onNotification);
            connection.current?.off(HubEvents.onNotificationsUpdate);
            connection.current?.off(HubEvents.onNewScreenshot);
        };
    }, [isSignedIn, token]);

    const updateHub = () => {
        connection.current?.stop();
        makeConnection();
    };

    const totalNotificationsCount = chatNotificationsCount + orderNotificationsCount;
    const hub = connection.current || undefined;

    return (
        <NotificationContext.Provider
            value={{
                totalNotificationsCount,
                orderNotificationsCount,
                chatNotificationsCount,
                notifications,
                hub,
                updateHub,
            }}
            {...props}
        />
    );
};

const useNotification = (): NotificationContextInterface => {
    const context = React.useContext(NotificationContext);
    if (context === undefined) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context as NotificationContextInterface;
};

export { NotificationProvider, useNotification };
