import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import allRoutes, { AccessMode, FlashRoutes } from 'router';
import { RootState } from 'store/rootReducer';

import PrimarySearchAppBar from '../../organisms/menu/PrimarySearchAppBar';

export default function Header(): JSX.Element | null {
    const hasUser = useSelector((state: RootState) => {
        return state.authReducer.auth.accessToken !== '';
    });
    const location = useLocation();
    const routeInfo =
        allRoutes[
            Object.keys(allRoutes).find(
                (x) => allRoutes[x as keyof FlashRoutes]?.path == location.pathname,
            ) as keyof FlashRoutes
        ] ?? {};
    return hasUser || routeInfo.access === AccessMode.Both || location.pathname.startsWith('/auth/forgot-password/') ? (
        <PrimarySearchAppBar />
    ) : null;
}
