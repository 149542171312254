import { FC, Fragment, useEffect, useRef, useState, useMemo } from 'react';

import * as Yup from 'yup';
import * as Moment from 'moment';
import { ValidationError } from 'yup';
import { extendMoment } from 'moment-range';
import { toast } from 'react-toastify';
import jwt_decode from 'jwt-decode';

import i18n from 'i18n/config';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';

import { Formik, FormikErrors, FormikHelpers, FormikProps } from 'formik';

import getUTCDate from 'hooks/GetUTCDate';
import useSelector from 'hooks/useAppSelector';
import useWindowDimensions from 'hooks/WindowDimensions';

import FlashDialog from 'components/atoms/dialog/FlashDialog';
import TextContent from 'components/atoms/dialog/TextContent';
import YesNoButtons from 'components/atoms/dialog/YesNoButtons';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import CancelButton from 'components/atoms/controls/CancelButton';
import UserInfo from 'components/molecules/User/UserInfo';
import UserProfileHeader from 'components/molecules/User/Header';
import DisableUserButton from 'components/molecules/User/DisableButton';
import FormHistoryBlocker from 'components/organisms/forms/FormHistoryBlocker';

import allRoutes from 'router';
import { AxiosResponse } from 'axios';
import { getById, update, create, getCurrent } from 'api/users';
import { create as createPhoto, getPhotoSrc } from 'api/photo';
import { unmaskPhone } from 'shared/utils';

import { IBaseFormRef } from 'models/interface/i-form-data';
import { UserModel, UserVm, ProfileImageModel, defaultUserModel } from 'models/user-model';

import { UserFormStyle, StyledBox, OptionButton } from 'components/organisms/forms/User/style';
import { updateRefresh } from 'store/auth/actions';
import { useDispatch } from 'react-redux';
import { refresh, updateUserFromAccessToken } from 'api/auth';
import { AccessToken } from 'interface/accessToken';

export interface UserFormProps {
    isCreate?: boolean;
    disabled?: boolean;
}

const moment = extendMoment(Moment);

const phoneReg = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/;

const validationSchema = Yup.object({
    firstName: Yup.string()
        .min(1, i18n.t('server-messages.firstName-not-valid'))
        .required(i18n.t('server-messages.error-save')),

    lastName: Yup.string()
        .min(1, i18n.t('server-messages.lastName-not-valid'))
        .required(i18n.t('server-messages.error-save')),

    email: Yup.string().email(i18n.t('server-messages.email-not-valid')).required(i18n.t('server-messages.error-save')),

    userName: Yup.string()
        .min(1, i18n.t('server-messages.login-not-valid'))
        .required(i18n.t('server-messages.error-save')),

    phoneNumber: Yup.string()
        .matches(phoneReg, i18n.t('server-messages.phone-is-incorrect'))
        .required(i18n.t('server-messages.error-save')),

    birthday: Yup.string()
        .test('Is age valid', i18n.t('user-form.messages.error-birthday-year'), (value) => {
            const now = moment();
            const age = now.diff(moment(value), 'years');
            return 18 <= age && age <= 150;
        })
        .required(i18n.t('user-form.messages.error-birthday')),

    password: Yup.string().when({
        is: (password: string) => password.length > 0,
        then: Yup.string()
            .oneOf([Yup.ref('passwordConfirm'), null], i18n.t('server-messages.password-dont-match'))
            .min(5, i18n.t('server-messages.password-are-incorrect')),
    }),

    passwordConfirm: Yup.string().when({
        is: (password: string) => password.length > 0,
        then: Yup.string()
            .oneOf([Yup.ref('password'), null], i18n.t('server-messages.password-dont-match'))
            .min(5, i18n.t('server-messages.password-are-incorrect')),
    }),
});

const UserForm: FC<UserFormProps> = ({ isCreate, disabled }) => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { width } = useWindowDimensions();

    const baseRef = useRef<IBaseFormRef>();
    const history = useHistory();
    const { state, pathname } = useLocation();
    const auth = useSelector((state) => state.authReducer.auth);
    const user = useSelector((state) => state.userReducer.user);

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isUserLockedOut, setIsUserLockedOut] = useState(false);
    const [isSubmiting, setIsSubmiting] = useState(false);
    const [isSelfEditing, setSelfEditing] = useState(false);
    const [isSaveUser, setIsSaveUser] = useState(false);

    const [originalData, setOriginalData] = useState<UserModel>(defaultUserModel);
    const [imageModel, setImageModel] = useState<ProfileImageModel>();
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

    const isDisabled = useMemo(() => disabled || isSubmiting, [isSubmiting, disabled]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isDataLoaded && !isCreate && user.nameid) {
            const getUser = async () => {
                const data: AxiosResponse<UserVm> =
                    id !== undefined && id !== user.nameid && pathname !== allRoutes.currentUserProfile.path
                        ? await getById(id)
                        : await getCurrent();
                setIsDataLoaded(true);
                setIsUserLockedOut(data.data.isLockedOut);

                const birthday = new Date(data.data.birthday);
                const utcBirthday = moment.utc(birthday).toDate();
                const validUserModel = fixNullValues({
                    ...data.data,
                    birthday: utcBirthday,
                    password: '',
                    passwordConfirm: '',
                });
                setOriginalData(validUserModel);
                setSelfEditing(user.nameid === data.data.id);
                const innerUrl = data.data.photoUrl || '';
                const url = getPhotoSrc(innerUrl);
                setImageUrl(url);
            };
            setTimeout(() => getUser(), 300);
        }
    }, [user, isDataLoaded, isCreate, pathname]);

    async function submitForm(values: UserModel) {
        baseRef.current?.release();
        if (id) {
            try {
                await update(
                    {
                        ...values,
                        birthday: getUTCDate(values.birthday),
                        phoneNumber: unmaskPhone(values.phoneNumber),
                    },
                    id,
                );
                if (imageModel !== undefined) {
                    imageModel.file !== undefined
                        ? await createPhoto(imageModel.file, id)
                        : toast.warning('file is empty');
                }
                toast.success(t('user-form.messages.success-update'));
                if (values.email !== user.email) toast.success(t('user-form.messages.email-update'));
                if (!isSelfEditing) {
                    history.replace({
                        pathname: generatePath(allRoutes.userProfile.path, { id }),
                        state,
                    });
                } else {
                    const refreshResponse = await refresh({
                        accessToken: auth?.accessToken ?? '',
                        refreshToken: auth?.refreshToken ?? '',
                    });
                    updateUserFromAccessToken(refreshResponse.data.accessToken);
                    const { exp: expRT } = jwt_decode(refreshResponse.data.refreshToken) as AccessToken;
                    dispatch(
                        updateRefresh({
                            refreshToken: refreshResponse.data.refreshToken,
                            refreshTokenTTL: expRT,
                        }),
                    );
                    history.replace({
                        pathname: allRoutes.currentUserProfile.path,
                        state,
                    });
                }
            } catch {
                setIsSaveUser(false);
                baseRef.current?.block();
            }
        }
        if (id === undefined && !isSelfEditing) {
            try {
                const user = await create(values);
                const userId = user.data.id;
                if (imageModel !== undefined) {
                    imageModel.file !== undefined
                        ? await createPhoto(imageModel.file, userId)
                        : toast.warning('file is empty');
                }
                toast.success(t('user-form.messages.success-create-admin'));
                history.replace({
                    pathname: generatePath(allRoutes.userProfile.path, { id: userId }),
                    state,
                });
            } catch (error) {
                setIsSaveUser(false);
                baseRef.current?.block();
            }
        }
    }

    const fixNullValues = (userModel: UserModel) => {
        userModel.phoneNumber = userModel.phoneNumber || '';
        userModel.firstName = userModel.firstName || '';
        userModel.lastName = userModel.lastName || '';
        userModel.timeZoneId = userModel.timeZoneId || '';

        const birthday = moment(userModel.birthday);
        const now = moment();
        const age = now.diff(birthday, 'years');
        if (age > 150) {
            toast.error(t('user-form.messages.error-birthday'));
        }
        userModel.birthday = birthday.toDate();
        return userModel;
    };

    const sendImageToStorage = (imageModel: ProfileImageModel) => {
        setImageModel(imageModel);
    };

    const handleFormSubmiting = (isSubmiting: boolean) => setIsSubmiting(isSubmiting);

    const handleSubmit = (values: UserModel, helpers: FormikHelpers<UserModel>) => {
        validationSchema
            .validate(values, {
                abortEarly: false,
            })
            .then(() => {
                if (id || (id === undefined && !isSelfEditing)) {
                    setIsSaveUser(true);
                }
            })
            .catch((err: ValidationError) => {
                const errors = err.inner.reduce((acc: FormikErrors<UserModel>, { path, errors }: ValidationError) => {
                    if (!path) return acc;
                    return { ...acc, [path]: errors.join(', ') };
                }, {});
                helpers.setErrors(errors);
            });
    };

    const handleConfirm = (values: UserModel) => {
        try {
            if (values.password === values.passwordConfirm) {
                baseRef.current?.release();
                return submitForm(values);
            }
            toast.error(t('server-messages.password-dont-match'));
        } catch {
            baseRef.current?.block();
        }
    };

    const handleCancel = () => {
        if (id) {
            return history.replace({
                pathname: generatePath(allRoutes.userProfile.path, { id: id }),
                state,
            });
        }
        history.replace(generatePath(allRoutes.users.path, { id: 1 }));
    };

    const handleReject = () => setIsSaveUser(false);

    return (
        <UserFormStyle>
            <Formik initialValues={originalData} enableReinitialize onSubmit={handleSubmit}>
                {({ values, setFieldValue, submitForm, errors, touched }: FormikProps<UserModel>) => (
                    <Fragment>
                        {!disabled && (
                            <FormHistoryBlocker originalData={originalData} ref={baseRef} formData={values} />
                        )}
                        <UserProfileHeader
                            id={id}
                            disabled={disabled}
                            values={values}
                            setFieldValue={setFieldValue}
                            imageUrl={imageUrl}
                            sendImageToStorage={sendImageToStorage}
                        />
                        <UserInfo
                            disabled={disabled}
                            isDisabled={isDisabled}
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                        />
                        <DisableUserButton
                            id={id}
                            values={values}
                            isUserLockedOut={isUserLockedOut}
                            setIsUserLockedOut={setIsUserLockedOut}
                            isSelfEditing={isSelfEditing}
                        />
                        {!disabled && (
                            <StyledBox maxWidth={width}>
                                <OptionButton
                                    onSubmit={submitForm}
                                    onSubmiting={handleFormSubmiting}
                                    disabled={isDisabled}
                                    name="save-button"
                                >
                                    {isDisabled ? (
                                        <LoadingIndicator isLoaded={false} />
                                    ) : (
                                        t('user-form.button-text.save')
                                    )}
                                </OptionButton>
                                <FlashDialog
                                    open
                                    title={t('user-form.save-title')}
                                    isOpen={isSaveUser && !isDisabled}
                                    onClose={handleReject}
                                    content={<TextContent text={t('user-form.save-text')} />}
                                    controls={
                                        <YesNoButtons onConfirm={() => handleConfirm(values)} onReject={handleReject} />
                                    }
                                />
                                {id && (
                                    <CancelButton
                                        baseRef={baseRef}
                                        originalData={originalData}
                                        formData={values}
                                        disabled={isDisabled}
                                        onClick={handleCancel}
                                        name="cancel-button"
                                    >
                                        {t('user-form.button-text.cancel')}
                                    </CancelButton>
                                )}
                            </StyledBox>
                        )}
                    </Fragment>
                )}
            </Formik>
        </UserFormStyle>
    );
};

export default UserForm;
