export enum Languages {
    English = 'en',
    Russian = 'ru',
}

export enum UserRoles {
    Admin = 'admin',
    Operator = 'operator',
    User = 'user',
}

export enum LoginProviders {
    Google = 0,
    Facebook = 1,
    Vkontakte = 2,
}

export enum DaysOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Satturday = 6,
}

export enum Frequency {
    None = 0,
    Daily = 1,
    Weekly = 2,
    Monthly = 3,
    Yearly = 4,
    Custom = 5,
    Workdays = 6,
}

export enum OrderFrequency {
    NotRepeat = 0,
    EveryWorkDay = 1,
    EveryDay = 2,
    EveryWeek = 3,
}

export enum OrderStatusType {
    All = -1,
    Cancelled = 0,
    Approved = 1,
    onModeration = 2,
    onPayWaiting = 4,
    CurrentlyPlaying = 5,
    Completed = 6,
    Nullified = 7,
    PaymentRefunded = 8,
    PaymentRefundFailed = 9,
    Created = 10,
}

export const orderFrequencyOptions = [
    { label: 'enums.order-frequency.not-repeat', value: OrderFrequency.NotRepeat },
    { label: 'enums.order-frequency.every-work-day', value: OrderFrequency.EveryWorkDay },
    { label: 'enums.order-frequency.every-day', value: OrderFrequency.EveryDay },
    { label: 'enums.order-frequency.every-week', value: OrderFrequency.EveryWeek },
];

export enum OrderReplays {
    One = 1,
    Five = 5,
    Ten = 10,
    Fifteen = 15,
    Twenty = 20,
}

export const orderReplaysOptions = [
    { label: 'enums.order-replays.one', value: OrderReplays.One },
    { label: 'enums.order-replays.five', value: OrderReplays.Five },
    { label: 'enums.order-replays.ten', value: OrderReplays.Ten },
    { label: 'enums.order-replays.fifteen', value: OrderReplays.Fifteen },
    { label: 'enums.order-replays.twenty', value: OrderReplays.Twenty },
];

export enum PaymentStatus {
    NonPaid,
    Paid,
    PaymentReturned,
    WithoutPayment,
}

export enum NumberZero {
    Zero = '00000',
}

export enum ValidationRules {
    Valid = 1,
    Invalid = 2,
}

export enum TariffsValidityReasons {
    NoTariffs = 1,
    NotFull = 2,
    TariffNotValid = 3,
}

export enum MomentFormats {
    Short = 'DD.MM.YYYY',
    invertedShort = 'YYYY.MM.DD',
    Long = 'DD.MM.YYYY HH:mm:ss',
    LongLocalDate = 'DD MMMM YYYY',
    Time = 'HH:mm',
    LongTime = 'HH:mm:ss',
}

export enum ShowState {
    Unset,
    NotInWork,
    InWork,
}
