import { ComponentProps } from 'react';

import styled from 'styled-components';

import { ButtonBase, Typography } from '@material-ui/core';

import { variables } from 'theme/variables';

interface StyledAuthProviderProps extends ComponentProps<'a'> {
    color: string;
    backgroundcolor: string;
}

export const AuthProviderIcon = styled.svg({
    marginRight: 8,
    height: 30,
    width: 30,
});

export const AuthProviderText = styled(Typography)({});

export const StyledAuthProvider = styled(ButtonBase).attrs({ component: 'a' })<StyledAuthProviderProps>(
    ({ color, backgroundcolor }) => ({
        borderRadius: '42px',
        maxHeight: 48,
        padding: 10,
        margin: 10,
        justifyContent: 'center',
        color,
        backgroundColor: backgroundcolor,
        flex: 1,

        [AuthProviderText]: {
            color,
        },

        '&.MuiButtonBase-root': {
            border: '3px solid transparent',
            transition: '.2s ease',

            '&:hover': {
                border: `3px solid ${variables.colours.primary}`,
                zIndex: 0,
            },
        },
    }),
);
