import React from 'react';
import { Badge, IconButton } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { StyledSection } from './style';
import { ReactComponent as Notifications } from '../../../../assets/icons/Notifications.svg';
import { ReactComponent as AvatarArrow } from '../../../../assets/icons/DownArrow.svg';
import { useState, useEffect } from 'react';
import { getPhotoSrc } from '../../../../api/photo';

export type MenuSectionDesktopProps = {
    menuId: string;
    dropDownMenuId: string;
    handleNotificationMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
    handleProfileMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
    photoUrl: string;
    fullName: string;
    notificationsCount: number;
};

export default function MenuSectionDesktop(props: MenuSectionDesktopProps): JSX.Element {
    const {
        menuId,
        dropDownMenuId,
        notificationsCount,
        handleNotificationMenuOpen,
        handleProfileMenuOpen,
        photoUrl,
        fullName,
    } = props;
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

    useEffect(() => {
        setImageUrl(getPhotoSrc(photoUrl));
    }, [photoUrl]);

    return (
        <StyledSection>
            <IconButton
                aria-label="show new notifications"
                color="inherit"
                aria-controls={dropDownMenuId}
                aria-haspopup="true"
                onClick={handleNotificationMenuOpen}
                name="notification-button"
            >
                <Badge badgeContent={notificationsCount} color="primary">
                    <Notifications height="29px" width="24.29px" />
                </Badge>
            </IconButton>
            <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                className="menu"
                name="profile-button"
            >
                <Avatar alt={fullName && fullName} src={imageUrl} className="avatar">
                    {fullName && fullName[0]}
                </Avatar>
                <AvatarArrow />
            </IconButton>
        </StyledSection>
    );
}
