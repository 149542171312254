import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { Button, Box, Typography } from '@material-ui/core';

import OrderCreateNotificationLink from 'components/atoms/controls/notifications/OrderCreateNotificationLink';
import ChatMessageNotificationLink from 'components/atoms/controls/notifications/ChatMessageNotificationLink';
import OrderApprovedNotificationLink from 'components/atoms/controls/notifications/OrderApprovedNotificationLink';
import OrderCanceledNotificationLink from 'components/atoms/controls/notifications/OrderCanceledNotificationLink';

import useSelector from 'hooks/useAppSelector';

import { readNotifications } from 'api/chat';
import allRoutes from 'router';

import { ActionTypes, NotificationShortVm } from 'models/notification-model';
import { UserRoles } from 'shared/enums';

import { NotifStyled, NotifMenuStyled } from 'components/organisms/menu/NotificationsArea/styles';

export type DropdownMenuPros = {
    id: string;
    anchorEl: HTMLElement | null;
    handleMenuClose: () => void;
    notifications: NotificationShortVm[];
    onResetNotificationClick: () => void;
};

export default function NotificationsArea(props: DropdownMenuPros): JSX.Element {
    const { id, anchorEl: anchorNotificateEl, handleMenuClose, notifications, onResetNotificationClick } = props;
    const { t } = useTranslation();
    const history = useHistory();

    const user = useSelector((state) => state.userReducer.user);

    const onReadNotify = async (
        chatId: string,
        path: string = generatePath(allRoutes.messagesPage.path, { id: chatId }),
    ) => {
        handleMenuClose();
        await readNotifications(chatId);
        history.push(path);
    };

    const isMenuNotificateOpen = Boolean(anchorNotificateEl);
    return (
        <NotifMenuStyled
            anchorEl={anchorNotificateEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={id}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuNotificateOpen}
            onClose={handleMenuClose}
        >
            <NotifStyled>
                <Box width="100%">
                    <Typography variant="h3" className="notifications-text">
                        {t('chat-form.notifications')}
                    </Typography>
                    <Box textAlign="right">
                        {notifications.length ? (
                            <Button
                                className="selectAll"
                                onClick={onResetNotificationClick}
                                type="submit"
                                name="reset-button"
                            >
                                <Typography variant="subtitle1" className="reset-text">
                                    {t('order-form.button-text.reset')}
                                </Typography>
                            </Button>
                        ) : (
                            <Typography variant="subtitle1" className="notifications-text">
                                {t('chat-form.notifications-are-empty')}
                            </Typography>
                        )}
                    </Box>
                </Box>
            </NotifStyled>
            {notifications.map((item, index) => (
                <NotifStyled key={item.id}>
                    {item.actionType == ActionTypes.createOrder && (
                        <OrderCreateNotificationLink
                            key={index}
                            orderNumber={item.order.number}
                            orderUserId={item.order?.userId}
                            onClick={() => onReadNotify(item.chat.id)}
                            orderId={
                                user.role !== UserRoles.User || item?.order?.userId !== user.nameid
                                    ? item.order.id
                                    : undefined
                            }
                            chatId={item.chat.id}
                            data-id="order-create"
                        />
                    )}
                    {item.actionType == ActionTypes.approvedOrder && (
                        <OrderApprovedNotificationLink
                            key={index}
                            orderNumber={item.order.number}
                            onClick={() => onReadNotify(item.chat.id)}
                            data-id="approved-order"
                            chatId={item.chat.id}
                        />
                    )}
                    {item.actionType == ActionTypes.canceledOrder && (
                        <OrderCanceledNotificationLink
                            key={index}
                            onClick={() => onReadNotify(item?.chat.id)}
                            orderId={item.order.id}
                            orderNumber={item.order.number}
                            orderStatus={item.order.status}
                            chatId={item.chat.id}
                            data-id="cancel-order"
                        />
                    )}
                    {item.actionType == ActionTypes.nullifiedOrder && (
                        <OrderCanceledNotificationLink
                            key={index}
                            onClick={() => onReadNotify(item?.chat.id)}
                            orderId={item.order.id}
                            orderNumber={item.order.number}
                            orderStatus={item.order.status}
                            chatId={item.chat.id}
                            data-id="cancel-order"
                        />
                    )}
                    {item.actionType == ActionTypes.chatMessage && (
                        <ChatMessageNotificationLink
                            key={index}
                            onClick={() => onReadNotify(item?.chat.id)}
                            chatId={item.chat.id}
                            orderNumber={item.order.number}
                            lastMessageDate={item.chat.lastMessageDate}
                            data-id="chat-message"
                        />
                    )}
                </NotifStyled>
            ))}
        </NotifMenuStyled>
    );
}
