import { Avatar, Box, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { OrderVm } from '../../../../models/order';
import allRoutes from '../../../../router';
import { UserRoles } from '../../../../shared/enums';
import { RootState } from '../../../../store/rootReducer';
import NavigationLink from '../../NavigationLink';
import { UserDeleted, UserProfile } from './style';

export type UserInfoProps = {
    order: OrderVm | undefined;
};

export default function UserInfo(props: UserInfoProps): JSX.Element {
    const { order } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.userReducer.user);

    return (
        <UserProfile>
            <NavigationLink
                disabled={order?.userIsDeleted}
                className="user-profile"
                onClick={() => history.push(generatePath(allRoutes.userProfile.path, { id: order?.userId }))}
                name="user-profile"
            >
                {user.role !== UserRoles.User ? (
                    <>
                        <Box marginRight="0.625rem">
                            <Avatar alt={order?.userFullName} src={order?.photoUrl}>
                                {order?.userFullName[0]}
                            </Avatar>
                        </Box>
                        <Typography variant="body2" className="order-user">
                            {order?.userFullName}
                        </Typography>
                    </>
                ) : (
                    <></>
                )}
            </NavigationLink>
            {order?.userIsDeleted && (
                <UserDeleted>
                    <Typography variant="subtitle1" className="deleted-user">
                        {t('order.user-deleted')}
                    </Typography>
                </UserDeleted>
            )}
        </UserProfile>
    );
}
