import styled from 'styled-components';

import { ActionButton } from 'components/organisms/DateSubForm/style';

export const FieldRow = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: -10,
    marginBottom: 5,
    minHeight: 40,

    ':last-child': {
        marginBottom: 0,
    },

    '> *': {
        margin: 10,
        flex: 1,
    },

    '.MuiInputBase-root': {
        flex: 1,
        color: '#000',
    },
});

export const InputsSection = styled.div({
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    flexShrink: 2,
    maxWidth: 700,
    minWidth: 260,
    margin: 10,
});

export const CalendarSection = styled.div({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: 'fit-content',
    margin: 10,
    flex: 1,
});

export const ScheduleForm = styled.div({
    display: 'flex',
    flexWrap: 'wrap-reverse',
    flex: 1,
    marginBottom: 40,
    borderRadius: 12,
    minHeight: 312,
});

export const RemoveActionButton = styled(ActionButton)({
    maxWidth: 'fit-content',
});
