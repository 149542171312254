import * as React from 'react';
import { FC, useCallback, useEffect, useState } from 'react';
import NoDataMessage from '../../atoms/NoDataMessage';
import { GridColDef, GridSortModel } from '@material-ui/data-grid';
import OrderCostSummary from '../../atoms/Order/OrderCostSummary';
import PagedDataGrid from '../PagedDataGrid';
import { getSortingParams } from '../../../utils/sortingHelper';
import { PageSize } from '../../../shared/constants';
import { CostCurrencySum, OrderDataGridShortVm } from '../../../models/order';
import { ordersAPI } from '../../../api/orders';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import OrdersColumnsGrid from './ColumnsGrid';
import { OrdersPagingModel, SortDirections, SortField, SortModel } from '../../../models/paging/paging.model';
import { OrdersPagedDateGridStyles } from './style';
import OrderFilter, { DefaultFilterData, OrderFilterData } from '../../atoms/gridFilters/OrderFilter';
import FilteredTemplate from '../../templates/FilteredTemplate';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

type PropsType = {
    mediaSpaceId?: string;
    userId?: string;
    isAvailableFilters?: boolean;
};

const OrdersPagedDataGrid: FC<PropsType> = (props) => {
    const { mediaSpaceId, userId, isAvailableFilters = false } = props;
    const defaultSort: SortModel = {
        property: SortField.CreatedDate,
        direction: SortDirections.Desc,
    };

    const { t } = useTranslation();
    const user = useSelector((state: RootState) => state.userReducer.user);

    const [loading, setLoading] = useState<boolean>(false);
    const [rows, setRows] = useState<OrderDataGridShortVm[]>([]);
    const [costSummary, setCostSummary] = useState(0);
    const [costCurrencySum, setCostCurrencySum] = useState<CostCurrencySum[]>([]);
    const [page, setPage] = useState(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [sort, setSort] = useState<SortModel>(defaultSort);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [filter, setFilter] = useState<OrderFilterData>(DefaultFilterData);
    const columns: GridColDef[] = OrdersColumnsGrid({ rows });

    const getReq = () => {
        const req: OrdersPagingModel = {
            page: page,
            pageSize: PageSize,
            property: sort.property,
            direction: sort.direction,
            filterString: filter.filterString,
            status: filter.status,
            mediaSpaceId: mediaSpaceId,
            userId: userId,
            startedAt: filter.startDate ? moment(filter.startDate).format('YYYY-MM-DD') : undefined,
            finishedAt: filter.endDate ? moment(filter.endDate).format('YYYY-MM-DD') : undefined,
        };
        return req;
    };

    const loadData = useCallback(async () => {
        const req = getReq();
        await getOrders(req);
    }, [page, sort.property, sort.direction, PageSize, filter]);

    const getOrders = async (req: OrdersPagingModel) => {
        const res = await ordersAPI.getPaged(req);
        const totalPageNumber = Math.ceil(res.total / res.pageSize);

        setTotalPage(totalPageNumber);
        setTotalItems(res.total);
        setCostSummary(res.costSum);
        setCostCurrencySum(res.costCurrencySum);
        setRows(res.data);
    };

    useEffect(() => {
        setLoading(true);
        (async () => {
            await loadData();
            setLoading(false);
        })();
    }, [page, user.lc, sort, filter]);

    const handleSorting = (model: GridSortModel) => {
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
            const sort = getSortingParams(model, defaultSort.property, defaultSort.direction);
            setSort(sort);
        }
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const handleFilterChange = (filter: OrderFilterData) => {
        setPage(1);
        setFilter(filter);
    };

    function renderFilter() {
        return isAvailableFilters ? (
            <OrderFilter
                onChange={handleFilterChange}
                isAvailableGenerateReportButton={false}
                isAvailableFilterByDate={false}
                isAvailableFilterByString={false}
                isAvailableFilterByStatus={true}
            />
        ) : (
            <></>
        );
    }

    return (
        <OrdersPagedDateGridStyles>
            <FilteredTemplate
                header={t('orders.header')}
                filter={renderFilter()}
                content={
                    <PagedDataGrid
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={(model: GridSortModel) => {
                            handleSorting(model);
                        }}
                        isDataLoaded={!loading}
                        isLoadingIndicator={!loading}
                        totalPages={totalPage}
                        totalItems={totalItems}
                        noDataMessage={<NoDataMessage />}
                        defaultPage={page}
                        columns={columns}
                        rows={rows}
                        rowsPerPageOptions={[]}
                        filterMode="server"
                        summaryElement={<OrderCostSummary costs={costSummary} costCurrencySum={costCurrencySum} />}
                        onChangePage={onChangePage}
                    />
                }
            />
        </OrdersPagedDateGridStyles>
    );
};

export default OrdersPagedDataGrid;
