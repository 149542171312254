import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const StyledForgetPassword = styled.div({
    '.MuiInputBase-input': {
        paddingBottom: '0.625rem',
    },
    '.go-back-text': {
        color: variables.colours.primary,
    },
    '.send-email': {
        color: variables.colours.light,
    },
    '.MuiButton-outlinedPrimary': {
        ':hover': {
            '.send-email': {
                color: variables.colours.primary,
            },
        },
    },
});
