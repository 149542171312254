import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import moment from 'moment';
import { cloneDeep } from 'lodash';

import { Box, Button, InputAdornment, MenuItem, Slider, Typography } from '@material-ui/core';

import TransferList from 'components/atoms/TransferList';
import FlashCheckbox from 'components/atoms/FlashCheckbox';
import FlashContainer from 'components/atoms/FlashContainer';
import FlashDatePicker from 'components/atoms/FlashDatePicker';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import ProfileActions from 'components/molecules/ProfileActions';
import CancelButton from 'components/atoms/controls/CancelButton';
import ResponsiveFlexBox from 'components/atoms/ResponsiveFlexBox';
import FlashDropDown from 'components/atoms/controls/FlashDropdown';
import WeekDayPicker from 'components/atoms/controls/WeekDayPicker';
import FlashTextarea from 'components/atoms/controls/FlashTextarea';
import FormHistoryBlocker from 'components/organisms/forms/FormHistoryBlocker';
import FormControlTextInput from 'components/molecules/forms/FormControlTextInput';
import { MediaSpaceChangeData } from 'components/molecules/forms/MediaSpacesMultiSelect';

import allRoutes from 'router';

import { RootState } from 'store/rootReducer';

import { create, deleteById, getById, update } from 'api/price';
import { currenciesAPI } from 'api/currency';
import { IBaseFormRef } from 'models/interface/i-form-data';
import { DefaultPriceInput, PriceInputModel, PriceNamesInputModel } from 'models/price.model';
import { DaysOfWeek, Frequency, MomentFormats, UserRoles } from 'shared/enums';

import getUTCDate from 'hooks/GetUTCDate';
import useWindowDimensions from 'hooks/WindowDimensions';

import { ReactComponent as Prices } from 'assets/icons/Prices.svg';
import { ReactComponent as Mediaspaces } from 'assets/icons/Mediaspaces.svg';
import { ReactComponent as Description } from 'assets/icons/Description.svg';

import {
    CostItem,
    CostList,
    FormControlTextInputStyle,
    PriceEditFormStyle,
    StyledMessage,
    StyledTextLength,
    StyledTitleBlock,
    StyledTypography,
} from 'components/organisms/forms/PriceEditForm/style';
import { getLanguages } from 'api/language';
import { LanguageVm } from 'models/language.model';
import { priceCostVm } from 'models/priceCost.model';

export type PriceFromProps = {
    event: PriceInputModel;
    id?: string;
};

type ValidationResult = {
    isTitleValid: false;
    isCostValid: false;
    isMediaSpacesValid: false;
    isTimeValid: false;
};

export default function PriceEditForm(props: PriceFromProps): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const location = history.location;
    const locations = useLocation().state;
    const tariffMaxLength = 50;
    const subscriptionMaxLength = 500;

    const user = useSelector((state: RootState) => state.userReducer.user);

    const baseRef = useRef<IBaseFormRef>();
    const { event, id } = props;
    const [formModel, setFormModel] = useState(DefaultPriceInput);
    const [originalData, setOriginalData] = useState(DefaultPriceInput);
    const [isValidated, setIsValidated] = useState(false);
    const [isRepeatedEvent, setIsRepeatedEvent] = useState(false);
    const [languages, setLanguages] = useState<LanguageVm[]>();
    const [priceCosts, setPriceCosts] = useState<priceCostVm[]>();

    const [subscriptionTextLength, setSubscriptionTextLength] = useState(0);

    const [hours, setHours] = useState([
        moment(formModel.timeStart, 'HH:mm').hours() ? moment(formModel.timeStart, 'HH:mm').hours() : 0,
        moment(formModel.timeEnd, 'HH:mm').hours() ? moment(formModel.timeEnd, 'HH:mm').hours() : 24,
    ]);
    const [formValidation, setFormValidation] = useState<ValidationResult>({
        isTitleValid: false,
        isCostValid: false,
        isMediaSpacesValid: false,
        isTimeValid: false,
    });
    const [priceField, setPriceField] = useState('');
    const [disabled, setDisabled] = useState(id !== undefined);
    const [isDisabled, setIsDisabled] = useState(false);
    const { width } = useWindowDimensions();

    const inputFixing = (event: React.KeyboardEvent) => {
        const inputRegEx = new RegExp(/[0-9]+\.[0-9]{2}/);
        if (inputRegEx.test(priceField) && event.key !== 'Backspace') event.preventDefault();
    };

    function validateTitle(arr: PriceNamesInputModel[]): boolean {
        let isValid = true;
        for (const item of arr) {
            if (item.title === '') {
                isValid = false;
                break;
            }
        }
        return isValid;
    }

    const handleValidation = (): boolean => {
        setIsValidated(true);
        const validationResult = {
            isTitleValid: validateTitle(formModel.names),
            isCostValid: !!formModel.priceCost.reduce((isCorrect, element) => {
                return element.cost > 0 ? isCorrect : false;
            }, true),
            isMediaSpacesValid:
                (formModel.isForAllMediaSpaces && formModel.mediaSpaceUids?.length === 0) ||
                formModel.mediaSpaceUids?.length > 0,
            isTimeValid:
                formModel.timeStart !== formModel.timeEnd ||
                formModel.timeStart !== '' ||
                formModel.timeEnd !== '' ||
                formModel.isAllDay,
        } as ValidationResult;
        if (!validationResult.isTitleValid) {
            toast.error(t('price-form.messages.error-empty-name-price'));
        }
        if (!validationResult.isCostValid) {
            toast.error(t('price-form.messages.error-empty-field-cost'));
        }
        if (!validationResult.isMediaSpacesValid) {
            toast.error(t('price-form.messages.error-empty-media-space'));
        }
        if (!validationResult.isTimeValid) {
            toast.error(t('price-form.messages.error-empty-time'));
        }
        setFormValidation(validationResult);

        let isValid = true;
        Object.keys(validationResult).map(
            (key) => (isValid = isValid && validationResult[key as keyof ValidationResult]),
        );

        return isValid;
    };

    const handleMinValue = () => {
        if (formModel.cost < 0) {
            setFormModel({ ...formModel, cost: 0 });
        }
    };

    const checkIsSameDay = (event: PriceInputModel) => {
        if (event.isAllDay) {
            setHours([0, 24]);
        }

        const isRepeatedEvent = event.frequency !== Frequency.None;
        if (isRepeatedEvent) {
            event.endDate = event.startDate;
        }
        setFormModel(event);
        setHours([
            parseInt(formModel.timeStart.slice(0, 2)),
            parseInt(formModel.timeEnd.slice(0, 2)) === 0 ? 24 : parseInt(formModel.timeEnd.slice(0, 2)),
        ]);
    };

    useEffect(() => {
        const clone = cloneDeep(event);
        clone.endDate = clone.endDate ? new Date(clone.endDate) : null;
        clone.startDate = new Date(clone.startDate);
        checkIsSameDay(clone);
        setOriginalData(clone);
    }, [event]);

    useEffect(() => {
        checkIsSameDay(formModel);
    }, [formModel.startDate, formModel.endDate, formModel.endsOn]);

    useEffect(() => {
        setSubscriptionTextLength(formModel?.description?.length ?? '');
    }, [formModel.description]);

    useEffect(() => {
        async function fetchData() {
            const currenciesResponse = await currenciesAPI.getCurrencies();
            const languagesResponse = await getLanguages();
            setLanguages(languagesResponse.data);
            if (location.pathname == '/prices/create') {
                const initialCostValue = 0;
                const priceCostList = [];
                for (let i = 0; i < currenciesResponse.data.length; i++) {
                    priceCostList.push({
                        currencyId: currenciesResponse.data[i].id,
                        currencyCode: currenciesResponse.data[i].code,
                        cost: initialCostValue,
                    });
                }
                setPriceCosts(priceCostList);

                const names = languagesResponse.data.map((x) => ({
                    title: '',
                    description: '',
                    languageId: x.id,
                }));
                setFormModel({ ...formModel, names: names, priceCost: priceCostList });
            }
        }
        fetchData();

        if (location.pathname === `/prices/edit/${id}`) {
            setDisabled(false);
        }
    }, [location.pathname]);

    useEffect(() => {
        setIsRepeatedEvent(formModel.frequency !== Frequency.None);
    }, [formModel.frequency]);

    const endsOnParser = (formModel: PriceInputModel) => {
        return formModel.endsOn ? getUTCDate(formModel.endsOn) : formModel.endsOn;
    };

    const sendEvent = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const isValid = handleValidation();

        if (
            (formModel.frequency !== Frequency.None && !formModel.endsOn) ||
            (formModel.frequency === Frequency.None && !formModel.endDate)
        ) {
            return toast.error(t('price-form.messages.error-empty-end-date'));
        }

        if (isValid) {
            baseRef.current?.release();
            let isSuccess = false;
            const model = {
                names: formModel.names,
                description: formModel.description,
                cost: formModel.cost,
                startDate: getUTCDate(formModel.startDate),
                endDate: formModel.endDate ? getUTCDate(formModel.endDate) : endsOnParser(formModel),
                endsOn: endsOnParser(formModel),
                isForAllMediaSpaces: formModel.isForAllMediaSpaces,
                isAllDay: formModel.isAllDay,
                isDefault: formModel.isDefault,
                timeStart: formModel.timeStart + ':00',
                timeEnd: formModel.timeEnd + ':00',
                color: formModel.color,
                frequency: formModel.frequency,
                mediaSpaceUids: formModel.mediaSpaceUids,
                daysOfWeek: formModel.daysOfWeek,
                priceCost: formModel.priceCost,
            } as PriceInputModel;

            let textToast = '';

            try {
                if (id) {
                    const response = await update(id, model);
                    const priceGroup = await getById(id);
                    if (priceGroup.data.isDefault) {
                        isSuccess = true;
                        textToast = 'price-form.messages.success-only-cost-changed';
                    } else {
                        isSuccess = response.data?.id !== '';
                        textToast = 'price-form.messages.success-update-price';
                    }
                    history.replace({
                        pathname: generatePath(allRoutes.priceProfile.path, { id: response.data.id }),
                        state: locations,
                    });
                } else {
                    const response = await create(model);
                    isSuccess = response.data?.id !== '';
                    textToast = 'price-form.messages.success-create-price';
                    history.replace({
                        pathname: generatePath(allRoutes.priceProfile.path, { id: response.data.id }),
                        state: locations,
                    });
                }
            } catch {
                isSuccess = false;
            }

            if (isSuccess) {
                setIsDisabled(true);
                toast.success(t(textToast));
            } else {
                baseRef.current?.block();
            }
        }
        return false;
    };

    const handleSliderChange = (event: ChangeEvent<unknown>, value: number | number[]) => {
        if (!Array.isArray(value)) return;
        if (value[0] !== value[1]) setHours(value);
    };

    useEffect(() => {
        if (!formModel.priceCost.length && priceCosts) {
            setFormModel({ ...formModel, priceCost: priceCosts });
        }
    }, [formModel]);

    useEffect(() => {
        setFormModel({
            ...formModel,
            timeStart: moment().hours(hours[0]).minute(0).format('HH:mm'),
            timeEnd: moment().hours(hours[1]).minute(0).format('HH:mm'),
        });
    }, [hours]);

    useEffect(() => {
        setHours([0, 24]);
    }, [formModel.isAllDay]);

    const renderTimePickers = () => {
        return (
            <>
                <ResponsiveFlexBox className="time-handler">
                    <FormHistoryBlocker originalData={originalData} ref={baseRef} formData={formModel} />
                    {formModel.timeStart} : {formModel.timeEnd}
                </ResponsiveFlexBox>
                <ResponsiveFlexBox className="time-handler">
                    <Slider
                        value={hours}
                        min={0}
                        max={24}
                        onChange={handleSliderChange}
                        disabled={formModel.isAllDay || disabled}
                    />
                </ResponsiveFlexBox>
                <ResponsiveFlexBox className="time-handler">
                    <FlashCheckbox
                        data-id="checkbox"
                        className="checkbox-time"
                        label={t('price.is-all-day-event')}
                        checked={formModel.isAllDay}
                        disabled={disabled}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const isAllDay = event.target.checked;
                            setFormModel({
                                ...formModel,
                                isAllDay: isAllDay,
                                timeEnd: isAllDay ? '00:00' : '23:00',
                                timeStart: '00:00',
                            });
                        }}
                    />
                </ResponsiveFlexBox>
            </>
        );
    };

    const freqsLabels: { [id: number]: string } = {};
    freqsLabels[Frequency.None] = t('price.freq-none');
    freqsLabels[Frequency.Daily] = t('price.freq-daily');
    freqsLabels[Frequency.Workdays] = t('price.freq-workdays');
    freqsLabels[Frequency.Weekly] = t('price.freq-weekly');

    const availableFreqs = Array<Frequency>(Frequency.None, Frequency.Daily, Frequency.Workdays, Frequency.Weekly);

    const renderMessage = () => {
        let weekdaysMessage = '';

        if (formModel.frequency === Frequency.Weekly) {
            const days = formModel.daysOfWeek?.map((day) => {
                return t(`weekdays-short.${day}`);
            });
            weekdaysMessage = `(${days})`;
        }

        const messageData = {
            startDate: moment(formModel.startDate).format(MomentFormats.Short),
            startTime: formModel.timeStart,
            endDate: moment(formModel.endDate).format(MomentFormats.Short),
            endTime: formModel.timeEnd,
            freq: freqsLabels[formModel.frequency].toLowerCase(),
            endsOn: moment(formModel.endsOn).format(MomentFormats.Short),
            weekdaysMessage: weekdaysMessage,
        };
        let message = t('price-form.not-repeated-message', messageData);

        if (formModel.frequency !== Frequency.None) {
            message += t('price-form.repeated-message', messageData);
        }
        return (
            <StyledMessage>{formModel.endDate && <Typography variant="subtitle1">{message}</Typography>}</StyledMessage>
        );
    };

    const renederDaysOfWeek = () => {
        if (formModel.frequency === Frequency.Weekly || formModel.frequency === Frequency.Workdays) {
            return (
                <ResponsiveFlexBox marginTop="1.375rem">
                    <WeekDayPicker
                        disabled={formModel.frequency === Frequency.Workdays || disabled}
                        selectedByDefault={formModel.daysOfWeek || []}
                        startFromSunday={false}
                        onChange={(selected: Array<DaysOfWeek>) => {
                            setFormModel({ ...formModel, daysOfWeek: selected });
                        }}
                    />
                </ResponsiveFlexBox>
            );
        }
    };

    async function deletePrice() {
        if (id) {
            const priceGroup = await getById(id);
            if (priceGroup.data.isDefault) {
                toast.error(t('price-form.messages.error-cannot-be-deleted'));
            } else {
                deleteById(id);
            }
        }
        history.push(generatePath(allRoutes.price.path, { id: 1 }));
    }

    const onEdit = () => {
        if (id && disabled && user.role === UserRoles.Admin)
            return { pathname: generatePath(allRoutes.editPrice.path, { id }), state: locations };
        return;
    };

    return (
        <PriceEditFormStyle>
            <FlashContainer>
                <div className="button-acctions">
                    <ProfileActions
                        deleteTitle={t('price.delete-tariff')}
                        deleteContent={t('price.contentText')}
                        hasDeleteButton={(id && user.role === UserRoles.Admin) as boolean}
                        onDelete={deletePrice}
                        onEdit={onEdit()}
                    />
                </div>
                <Box margin={width > 424 ? '1.25rem' : 0}>
                    <Box width="100%">
                        <Typography variant="h1" className="header">
                            {t('price-form.header')}
                        </Typography>
                        <br />
                        {formModel.names.map((item, index) => (
                            <StyledTitleBlock key={index} className="tariff-title">
                                <Typography variant="body1">
                                    {t('price.title') +
                                        ` (${languages?.find((x) => x.id === item.languageId)?.name ?? ''})`}
                                </Typography>
                                <FormControlTextInputStyle
                                    disabled={disabled}
                                    key={index}
                                    inputProps={{
                                        maxLength: tariffMaxLength,
                                    }}
                                    value={item.title}
                                    error={isValidated && formValidation.isTitleValid}
                                    color="secondary"
                                    onChangeValue={(value: string) => {
                                        const nameIndex = formModel.names.findIndex(
                                            (x) => x.languageId == item.languageId,
                                        );
                                        const arr = [...formModel.names];
                                        arr[nameIndex].title = value;
                                        setFormModel({ ...formModel, names: arr });
                                    }}
                                    placeholder=""
                                    name="title"
                                />
                                <StyledTextLength className="tariff-title-text-length">{`${item.title.length}/${tariffMaxLength}`}</StyledTextLength>
                            </StyledTitleBlock>
                        ))}
                    </Box>
                    <Box>
                        <ResponsiveFlexBox className="date-handler">
                            <FlashDatePicker
                                data-id="start-date"
                                disabled={disabled}
                                maxDate={formModel.endDate}
                                label={t('price-form.start')}
                                selected={formModel.startDate}
                                minDate={new Date()}
                                onChange={(date: Date | [Date, Date] | null) => {
                                    setFormModel({ ...formModel, startDate: date as Date });
                                }}
                                icon
                            />
                            <FlashDatePicker
                                data-id="end-date"
                                disabled={disabled}
                                minDate={formModel.startDate}
                                label={isRepeatedEvent ? t('price-form.ends-on') : t('price-form.end')}
                                selected={isRepeatedEvent ? formModel.endsOn : formModel.endDate}
                                onChange={(date: Date | null) => {
                                    if (isRepeatedEvent) {
                                        setFormModel({ ...formModel, endsOn: date });
                                    } else {
                                        setFormModel({ ...formModel, endDate: date });
                                    }
                                }}
                                icon
                            />
                        </ResponsiveFlexBox>
                        {renderTimePickers()}
                    </Box>
                    <ResponsiveFlexBox>
                        <Box className="dropdown-handler">
                            <FlashDropDown
                                data-id="repeat-period"
                                disabled={disabled}
                                label={''}
                                userwidth={width > 767 ? '96%' : '100%'}
                                color="secondary"
                                value={formModel.frequency}
                                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                                    const frequency = e.target.value as Frequency;
                                    let endDate = formModel.endDate;
                                    let endsOn = formModel.endsOn;
                                    let daysOfWeek = Array<DaysOfWeek>();

                                    if (frequency === Frequency.None) {
                                        if (formModel.endsOn) {
                                            endDate = formModel.endsOn;
                                        }
                                    } else {
                                        if (frequency === Frequency.Workdays) {
                                            daysOfWeek = [
                                                DaysOfWeek.Monday,
                                                DaysOfWeek.Tuesday,
                                                DaysOfWeek.Wednesday,
                                                DaysOfWeek.Thursday,
                                                DaysOfWeek.Friday,
                                            ];
                                        } else if (frequency === Frequency.Weekly) {
                                            daysOfWeek = [moment(formModel.startDate).day()];
                                        }
                                        if (endsOn === null || endsOn === undefined) {
                                            endsOn = endDate;
                                        }
                                    }
                                    setFormModel({
                                        ...formModel,
                                        frequency: frequency,
                                        endsOn: endsOn,
                                        endDate: endDate,
                                        daysOfWeek: daysOfWeek,
                                    });
                                }}
                            >
                                {availableFreqs.map((freq) => (
                                    <MenuItem value={freq} key={freq} data-id="period">
                                        {freqsLabels[freq]}
                                    </MenuItem>
                                ))}
                            </FlashDropDown>
                        </Box>
                        {renederDaysOfWeek()}
                    </ResponsiveFlexBox>
                </Box>
                <Box
                    margin={width > 424 ? '1.25rem' : '1.25rem 0'}
                    marginTop={width > 424 ? 0 : '1.25rem'}
                    flex="1 1 0"
                >
                    <StyledTypography className="title">
                        <Typography variant="h1">{t('price.media-spaces')}</Typography>
                    </StyledTypography>
                    <Box className="mediaspaces" marginBottom="1.375rem">
                        <Mediaspaces className="mediaspace-icon" />
                        <Typography variant="body1">{t('price-form.media-space')}</Typography>
                    </Box>
                    <div className="list-container">
                        <TransferList
                            disabled={disabled}
                            mediaSpaceUids={formModel.mediaSpaceUids}
                            isAllSelected={formModel.isForAllMediaSpaces}
                            isForTariffs={true}
                            id={id ? id : 'new'}
                            onChangeValue={(data: MediaSpaceChangeData) => {
                                setFormModel({
                                    ...formModel,
                                    isForAllMediaSpaces: data.isAllSelected,
                                    mediaSpaceUids: !data.isAllSelected ? data.selectedMediaSpaces : [],
                                });
                            }}
                        />
                    </div>
                </Box>
                <ResponsiveFlexBox margin={width > 424 ? '2.063rem 1.25rem 0 1.25rem' : 0} marginTop="2.063rem">
                    <Box flex="1 1 0">
                        <StyledTypography className="title">
                            <Typography variant="h1">{t('price.cost')}</Typography>
                        </StyledTypography>
                        <CostList className="cost">
                            {formModel.priceCost.map((item, idx) => {
                                return (
                                    <CostItem key={item.currencyId}>
                                        <Prices className="price-icon cost-icon" />
                                        <FormControlTextInput
                                            disabled={disabled}
                                            value={item.cost}
                                            color="secondary"
                                            error={isValidated && formValidation.isCostValid}
                                            onChangeValue={(value: string) => {
                                                const priceCost = [...formModel.priceCost];
                                                priceCost[idx].cost = parseFloat(value);
                                                setFormModel({ ...formModel, priceCost: priceCost });
                                                setPriceField(value);
                                            }}
                                            onBlur={handleMinValue}
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {item.currencyCode.toUpperCase() + t('price.currency')}
                                                    </InputAdornment>
                                                ),
                                            }}
                                            label={t('price.cost')}
                                            type="number"
                                            name="cost"
                                            onKeyDown={(event) => inputFixing(event)}
                                        />
                                    </CostItem>
                                );
                            })}
                        </CostList>
                        <StyledTitleBlock className="tariff-title">
                            <ResponsiveFlexBox className="description">
                                <Description className="price-icon cost-icon" />
                                <FlashTextarea
                                    disabled={disabled}
                                    inputProps={{
                                        maxLength: subscriptionMaxLength,
                                    }}
                                    value={formModel.description}
                                    userwidth="100%"
                                    label={t('price-form.comment-label')}
                                    rows={7}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const value = e.target.value;
                                        setFormModel({ ...formModel, description: value });
                                    }}
                                    name="comment-textarea"
                                />
                            </ResponsiveFlexBox>
                            <StyledTextLength className="tariff-title-text-length">{`${subscriptionTextLength}/${subscriptionMaxLength}`}</StyledTextLength>
                        </StyledTitleBlock>
                    </Box>
                </ResponsiveFlexBox>
                {!disabled && (
                    <>
                        {renderMessage()}
                        <Box textAlign="center" marginBottom="1.25rem">
                            <Button
                                onClick={sendEvent}
                                type="submit"
                                variant="outlined"
                                color={isDisabled ? 'default' : 'primary'}
                                className="option-button"
                                name="save-button"
                            >
                                {isDisabled ? (
                                    <LoadingIndicator isLoaded={false} />
                                ) : (
                                    <Typography variant="h5" className="save-text">
                                        {t('price.button-text.save')}
                                    </Typography>
                                )}
                            </Button>
                            <CancelButton
                                baseRef={baseRef}
                                originalData={originalData}
                                formData={formModel}
                                onClick={() => {
                                    if (id) {
                                        history.replace({
                                            pathname: generatePath(allRoutes.priceProfile.path, { id: id }),
                                            state: locations,
                                        });
                                    } else {
                                        history.replace(generatePath(allRoutes.price.path, { id: 1 }));
                                    }
                                }}
                                disabled={isDisabled}
                                className="option-button"
                                name="cancel-button"
                            >
                                {t('price.button-text.cancel')}
                            </CancelButton>
                        </Box>
                    </>
                )}
            </FlashContainer>
        </PriceEditFormStyle>
    );
}
