import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';
import { DefaultPlaylistInput, PlaylistModel } from '../../../models/playlist.model';
import { playlistsAPI } from '../../../api/playlists';
import PlaylistCreateEditForm from '../../organisms/forms/Playlist';

export default function PlayListsEditCreatePage(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();

    const [event, setEvent] = useState<PlaylistModel>(DefaultPlaylistInput);

    useEffect(() => {
        if (!id) {
            setEvent(location.state as PlaylistModel);
        } else {
            const loadEvent = async () => {
                const response = await playlistsAPI.getById(id);
                const event = response.data;
                const inputModel = {
                    name: event.name,
                    startDate: new Date(event.startDate),
                    endDate: event.endDate,
                    isForAllMediaSpaces: event.isForAllMediaSpaces,
                    isInfinite: event.isInfinite,
                    mediaSpaceIds: event.mediaSpaces.map((x) => x.id),
                    playlistFiles: event.playlistFiles,
                } as PlaylistModel;
                setEvent(inputModel);
            };
            setTimeout(() => loadEvent(), 300);
        }
    }, [id]);

    return <PlaylistCreateEditForm event={event} id={id} />;
}
