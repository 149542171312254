import { ButtonProps } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { StyledNavigationLink } from './style';

export default function GoBackButton(props: ButtonProps): JSX.Element {
    const history = useHistory();
    const location = useLocation().pathname;

    const PaginationGridRe = new RegExp('/page[0-9]+$');
    const PaginationPlaybackGridRe = new RegExp('/playbackStatistic/*');
    const PartOfGridLinkArray: string[] = [
        'orders',
        'prices',
        'media-spaces',
        'playlists',
        'devices',
        'currencies',
        'users',
    ];

    function handleClick() {
        if (history.location.state && !PaginationPlaybackGridRe.test(location)) {
            // back to grid
            if (PaginationGridRe.test(`${history.location.state}`)) {
                history.push(`${history.location.state}`);
            }
            // usual back
            else {
                history.goBack();
            }
        }

        // refund if you came by direct link
        else {
            for (let i = 0; i < PartOfGridLinkArray.length; i++) {
                const GridChildRe = new RegExp(`/${PartOfGridLinkArray[i]}/*`);
                // for child pages of grid
                if (GridChildRe.test(`${location}`)) {
                    history.push(`/${PartOfGridLinkArray[i]}/page1`);
                    break;
                } else {
                    if (i === PartOfGridLinkArray.length - 1) {
                        history.goBack();
                    }
                }
            }
        }
    }

    return <StyledNavigationLink startIcon={<ArrowBackIos color="disabled" />} onClick={handleClick} {...props} />;
}
