import React from 'react';
import { SelectProps, Typography } from '@material-ui/core';
import { Titles } from './style';

export type OrderTableOfContentsProps = SelectProps & {
    titles: string;
    icon: JSX.Element;
};

export default function OrderTableOfContents(props: OrderTableOfContentsProps): JSX.Element {
    const { titles, icon } = props;
    return (
        <Titles>
            {icon}
            <Typography variant="h3" className="stage-title">
                {titles}
            </Typography>
        </Titles>
    );
}
