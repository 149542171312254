import { MenuActionTypes, MenuState, UPDATE_MENU } from 'store/menu/types';

const InitialState: MenuState = {
    menu: {
        isCollapsed: false,
    },
};

const menuReducer = (state = InitialState, action: MenuActionTypes): MenuState => {
    switch (action.type) {
        case UPDATE_MENU: {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    isCollapsed: action.data.isCollapsed,
                },
            };
        }
        default:
            return state;
    }
};

export default menuReducer;
