import * as React from 'react';
import logoImage from '../../../assets/images/logo.png';
import { Box, BoxProps } from '@material-ui/core';
import NavigationLink from '../../molecules/NavigationLink';
import { useHistory } from 'react-router-dom';
import { LogoHandler, StyledTemplate, Logo } from './style';
import { ArrowBackIos } from '@material-ui/icons';

export default function ProtectionTemplate(props: BoxProps): JSX.Element {
    const { children } = props;
    const history = useHistory();

    return (
        <StyledTemplate boxShadow={5} borderRadius={15}>
            <LogoHandler>
                <Logo src={logoImage} />
            </LogoHandler>
            <Box className="content-handler">
                <NavigationLink onClick={history.goBack} className="go-back" name="create-account">
                    <ArrowBackIos />
                </NavigationLink>
                {children}
            </Box>
        </StyledTemplate>
    );
}
