import styled from 'styled-components';

import { variables } from 'theme/variables';

export const StyledNotification = styled.div(({}) => ({
    '.notification-header': {
        color: variables.colours.light,
        fontWeight: 500,
        fontSize: '1rem',
        marginBottom: '0.625rem',
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            fontSize: '0.813rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            fontSize: '0.813rem',
        },
    },
    '.notification-text': {
        color: variables.colours.light,
    },
    '.chat-button': {
        color: variables.colours.primary,
    },
    '.chat-icon': {
        width: 37,
        height: 30,
        path: {
            fill: variables.colours.light,
        },
        '@media only screen and (min-width : 1440px)': {
            width: 30,
            height: 30,
        },
    },
    '.notification-date': {
        color: variables.colours.default,
        fontSize: '0.75rem',
        fontWeight: 500,
    },
}));
