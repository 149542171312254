import React from 'react';
import GetTheme from 'theme';
import i18nObj from 'i18n/config';
import { ThemeProvider } from 'styled-components';

import { connect, ConnectedProps } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { CssBaseline, MuiThemeProvider, PaletteType } from '@material-ui/core';

import SignedInPage from 'components/pages/SignedIn';
import StyledToastContainer from 'components/templates/StyledToastContainer';

import { AuthContext } from 'providers/AuthProvider';

import { RootState } from 'store/rootReducer';
import { updateState } from 'store/theme/actions';
import { ThemeSettings } from 'store/theme/types';
import ScrollToTop from './hooks/ScrollToTop';
import { Languages } from './shared/enums';
import detectBrowserLanguage from 'detect-browser-language';
import { getConfig } from './config';
import { getLanguages } from './api/language';
import { currenciesAPI } from './api/currency';

interface IState {
    isResponseCome: boolean;
    themeMode: PaletteType;
}

const mapState = (state: RootState) => ({
    auth: state.authReducer.auth,
    theme: state.themeReducer.themeSettings,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
    theme: ThemeSettings;
};
const mapDispatch = {
    updateState,
    // updateAuth
};

const connector = connect(mapState, mapDispatch);

class App extends React.Component<Props, IState> {
    public state: IState = {
        isResponseCome: false,
        themeMode: this.props.theme.isDarkMode ? 'dark' : 'light',
    };

    theme = GetTheme(this.state.themeMode);

    static contextType = AuthContext;

    async componentDidMount() {
        const config = await getConfig();
        let lc = localStorage.getItem('locale');
        if (!lc) {
            if (config.detectBrowserLanguage) {
                const browserLanguage = detectBrowserLanguage();
                if (browserLanguage.includes(Languages.Russian)) {
                    lc = Languages.Russian.toString();
                } else if (browserLanguage.includes(Languages.English)) {
                    lc = Languages.English.toString();
                }
            }

            const languagesResponse = await getLanguages();
            const defaultLanguage = languagesResponse.data.find((x) => x.isDefault);
            if (defaultLanguage) {
                i18nObj.changeLanguage(defaultLanguage.code || Languages.English);
                localStorage.setItem('locale', defaultLanguage.code || Languages.English);
            }
        }
        i18nObj.changeLanguage(lc || Languages.English);

        const { accessToken, refreshToken } = this.props.auth;

        if (this.props.auth) {
            try {
                await this.context.authorize({ accessToken, refreshToken });
            } finally {
                this.setState({ isResponseCome: true });
            }
        } else {
            const response = await currenciesAPI.getCurrencies();
            const localCurrencyCode = localStorage.getItem('currencyCode');
            if (!localCurrencyCode) {
                localStorage.setItem('currencyCode', response.data.filter((x) => x.isDefault)[0].code);
            }
        }
    }

    async componentDidUpdate(_prevProps: Props, prevState: IState) {
        if (prevState.themeMode !== this.state.themeMode) {
            const mode = this.props.theme.isDarkMode ? 'dark' : 'light';
            this.setState({ themeMode: mode });
            this.theme = GetTheme(this.state.themeMode);
        }

        const response = await currenciesAPI.getCurrencies();
        const localCurrencyCode = localStorage.getItem('currencyCode');
        if (!localCurrencyCode) {
            localStorage.setItem('currencyCode', response.data.filter((x) => x.isDefault)[0].code);
        }
    }

    public render() {
        return (
            <MuiThemeProvider theme={this.theme}>
                <ThemeProvider theme={this.theme}>
                    <CssBaseline />
                    <StyledToastContainer hideProgressBar />
                    <Router>
                        <ScrollToTop>
                            <Switch>
                                <Route path="/" component={SignedInPage} />
                            </Switch>
                        </ScrollToTop>
                    </Router>
                </ThemeProvider>
            </MuiThemeProvider>
        );
    }
}

export default connector(App);
