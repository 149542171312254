import styled from 'styled-components';

import { variables } from 'theme/variables';

const StyledItemsCount = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    width: 'auto',
    border: `1.5px solid ${variables.backgoroundColours.border}`,
    boxSizing: 'border-box',
    borderRadius: 4,
    fontWeight: 400,
});

export default StyledItemsCount;
