import { AxiosResponse } from 'axios';
import { ConfirmEmailModel, PagedUserVmResponse, UserModel, UserPagedRequest, UserVm } from '../models/user-model';
import axios, { arrayToQuery } from './axios';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string'); // CommonJS Module

export const getUsersReportSrc = (displayInline: boolean): string => {
    let token = '';
    const root = window.localStorage['persist:root'];
    if (root) {
        const tokens = JSON.parse(JSON.parse(root).authReducer);
        if (tokens?.auth?.accessToken) {
            token = tokens.auth.accessToken;
        }
    }
    return `${window.ENV.config.backendUrl}/api/user/report?displayInline=${displayInline}&access_token=${token}`;
};

export const get = (): Promise<AxiosResponse<UserVm[]>> => {
    return axios.get(`user`);
};

export const getCurrent = (): Promise<AxiosResponse<UserVm>> => {
    return axios.get(`profile`);
};

export const getById = (id: string): Promise<AxiosResponse<UserVm>> => {
    return axios.get(`user/${id}`);
};

export const create = (data: UserModel): Promise<AxiosResponse<UserVm>> => {
    return axios.post(`user`, data);
};

export const update = (data: UserModel, id: string): Promise<AxiosResponse<UserVm>> => {
    return axios.put(`user/${id}`, data);
};

export const deleteById = (id: string): Promise<AxiosResponse<boolean>> => {
    return axios.delete(`user/${id}`);
};

export const disable = (enabled: boolean, id: string): Promise<AxiosResponse<boolean>> => {
    return axios.put(`user/disable/${id}?enabled=${enabled}`);
};

export const getUserPage = (data: UserPagedRequest): Promise<PagedUserVmResponse> => {
    return axios.get(`user/page/?${queryString.stringify(data)}`);
};
export const sendConfirmEmail = (data: ConfirmEmailModel): Promise<AxiosResponse<boolean>> => {
    return axios.post(`user/confirmEmail`, data);
};

export const bulkDelete = (ids: string[]): Promise<AxiosResponse<PagedUserVmResponse>> => {
    return axios.delete(`user?${arrayToQuery('ids', ids)}`);
};

export const bulkDisable = (ids: string[], enabled: boolean): Promise<AxiosResponse<PagedUserVmResponse>> => {
    return axios.put(`user/disable?${arrayToQuery('ids', ids)}&enabled=${enabled}`);
};
