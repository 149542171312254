import { OrderStatusType } from '../../../shared/enums';

export type OrderRow = {
    id: string;
    number: number;
    createdDate: string;
    userId: string;
    userName: JSX.Element;
    dateReply: JSX.Element;
    status: OrderStatusType;
    cost: string;
};

export enum NameField {
    OrderId = 'id',
    Number = 'number',
    CreatedDate = 'createdDate',
    UserName = 'userName',
    DateReply = 'dateReply',
    Status = 'status',
    Cost = 'cost',
}

export enum SortField {
    OrderId = 'Id',
    Number = 'Number',
    CreatedDate = 'CreatedDate',
    UserName = 'UserName',
    DateReply = 'DateReply',
    Status = 'Status',
    Cost = 'Cost',
}

export enum SortDirections {
    Asc = 'asc',
    Desc = 'desc',
}

export enum SortDirectionsInteger {
    Asc = 1,
    Desc = 2,
}

export type RequestSortingType = {
    sortProperty: string;
    sortDirection: number;
};
