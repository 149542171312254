import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Backdrop, Box, Fade } from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';

import NoDataMessage from 'components/atoms/NoDataMessage';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import LoadingIndicatorPopup from 'components/atoms/LoadingIndicatorPopup';
import MediaSpacesSubFormFilter from 'components/atoms/gridFilters/MediaSpacesSubForm';
import PagedDataGrid from 'components/organisms/PagedDataGrid';
import FlashMap, { MapPlacemark } from 'components/organisms/FlashMap';
import MediaSpacesColumnsGridOrder from 'components/organisms/MediaSpacesList/ColumnsGrid';

import { mediaSpacesAPI } from 'api/media-space';
import { PageSize } from 'shared/constants';
import { ValidationRules } from 'shared/enums';
import {
    MediaSpaceFilterRule,
    MediaSpacePage,
    MediaSpacePageVm,
    MediaSpaceValidityStatus,
    MediaSpaceVm,
} from 'models/media-space.model';

import { ImageViewStyled, ModalStyled, StyledList } from './style';
import { getDateWithLocalTz } from 'hooks/getDateWithLocalTz';
import { getCurrentDate } from 'hooks/getCurrentDate';
import MediaSpacesListContent from 'components/templates/MediaSpacesList';
import defaultMediaSpaceImg from 'assets/images/logo.png';
import useSelector from '../../../hooks/useAppSelector';

export type MediaSpacesListProps = {
    allSelected: boolean;
    checked: string[];
    setChecked: Dispatch<SetStateAction<string[]>>;
    setAllSelected: () => void;
    amountOfMediaSpaces: number;
    selectMediaSpace?: (value: React.SetStateAction<string[]>) => void;
};

export default function MediaSpacesList(props: MediaSpacesListProps): JSX.Element {
    const { allSelected, setAllSelected, amountOfMediaSpaces, selectMediaSpace, checked, setChecked } = props;

    const { id } = useParams<{ id: string }>();

    const user = useSelector((state) => state.userReducer.user);

    const [mediaSpaces, setMediaSpaces] = useState<MediaSpaceVm[]>([]);
    const [mediaSpaceMapPlacemark, setMediaSpaceMapPlacemark] = useState<MapPlacemark[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isLoadingIndicator, setIsLoadingIndicator] = useState(true);
    const [isTriggeredLoad, setIsTriggeredLoad] = useState(false);
    const [tableMap, setTableMap] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);

    const [openModal, setOpenModal] = useState(false);
    const [imageSrc, setImageSrc] = useState<string | null>('');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [warningsOnly, setWarningsOnly] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [invalid, setInvalid] = useState(false);

    const columns: GridColDef[] = MediaSpacesColumnsGridOrder({
        mediaSpaces,
        checked,
        setChecked,
        selectMediaSpace,
        setOpenModal,
        setImageSrc,
    });

    useEffect(() => {
        const isInvalid = mediaSpaces.filter((item) => {
            const timeWhenDeviceIsOnline = 10;
            if (item.tariffsValidityStatus === ValidationRules.Invalid) return true;
            if (!item.devices.length) return true;
            if (
                item.devices.some(
                    (device) => getDateWithLocalTz(device.lastConnectionDate) < getCurrentDate(timeWhenDeviceIsOnline),
                )
            )
                return true;
        });
        setInvalid(Boolean(isInvalid.length));
    }, [mediaSpaces.length]);

    useEffect(() => {
        setIsTriggeredLoad(false);
    }, [user.lc]);

    useEffect(() => {
        if (!isTriggeredLoad) {
            setIsTriggeredLoad(true);
            if (tableMap) {
                setTimeout(() => getMediaSpacesPage(1), 300);
            } else {
                setTimeout(() => getMediaSpacesMap(), 300);
            }
        }
    }, [isTriggeredLoad]);

    useEffect(() => {
        if (id) {
            const page = Number(id);
            setPage(page);
            setTimeout(() => getMediaSpacesPage(page), 300);
        }
    }, [id]);

    const onChangePage = (page: number) => {
        setTimeout(() => getMediaSpacesPage(page), 300);
    };

    useEffect(() => {
        if (isDataLoaded && mediaSpaces) {
            if (tableMap) {
                setTimeout(() => getMediaSpacesPage(1), 300);
            } else {
                setTimeout(() => getMediaSpacesMap(), 300);
            }
        }
    }, [tableMap]);

    const handleClose = () => setOpenModal(false);

    const getMediaSpacesPage = async (page: number) => {
        setIsLoadingIndicator(false);
        setMediaSpaceMapPlacemark([]);
        setMediaSpaces([]);
        const reqVal: MediaSpacePage = {
            page: page,
            property: '',
            direction: 1,
            filterString: '',
            pageSize: PageSize,
            filterRule: MediaSpaceFilterRule.All,
        };
        const response: MediaSpacePageVm = await mediaSpacesAPI.getValidityPage(MediaSpaceValidityStatus.Valid, reqVal);
        const totalPageNumber = Math.ceil(response.total / PageSize);
        setTotalItems(response.total);
        setTotalPage(totalPageNumber);
        setIsDataLoaded(true);
        const mediaSpaces: MediaSpaceVm[] = response.data.map((element) => ({
            names: element.names,
            id: element.id,
            name: element.name,
            address: element.address,
            longitude: element.longitude,
            latitude: element.latitude,
            comment: element.comment,
            tariffsValidityStatus: element.tariffsValidityStatus,
            tariffsValidityReason: element.tariffsValidityReason,
            devices: element.devices,
            isActive: element.isActive,
            pictureUrl: element.pictureUrl,
            timeZoneId: element.timeZoneId,
        }));
        setMediaSpaces(mediaSpaces);
        setIsLoadingIndicator(true);
    };

    const getMediaSpacesMap = async () => {
        setIsDataLoaded(false);
        setMediaSpaceMapPlacemark([]);
        setMediaSpaces([]);
        const data = await mediaSpacesAPI.getMediaSpacesMapOrderPage();

        const mediaSpacesMap: MapPlacemark[] = [];
        data.data.forEach((mediaSpace) => {
            mediaSpacesMap.push({
                id: mediaSpace.id,
                isHighlighted: false,
                lat: mediaSpace.latitude,
                long: mediaSpace.longitude,
                name: mediaSpace.name,
                address: mediaSpace.address,
                pictureUrl: mediaSpace.pictureUrl,
            });
        });
        setMediaSpaceMapPlacemark(mediaSpacesMap);
        setIsDataLoaded(true);
    };

    useEffect(() => {
        if (tableMap) {
            setTimeout(() => getMediaSpacesPage(1), 300);
        } else {
            setTimeout(() => getMediaSpacesMap(), 300);
        }
    }, [warningsOnly]);

    const getGridContent = (): JSX.Element => {
        {
            return tableMap ? (
                <StyledList>
                    <PagedDataGrid
                        isDataLoaded={isDataLoaded}
                        isLoadingIndicator={isLoadingIndicator}
                        columns={columns}
                        noDataMessage={<NoDataMessage />}
                        rows={mediaSpaces}
                        filterMode="server"
                        totalPages={totalPage}
                        totalItems={totalItems}
                        defaultPage={page}
                        onChangePage={onChangePage}
                    />
                </StyledList>
            ) : (
                <>
                    {isDataLoaded ? (
                        <Box width="100%" className="map-handler">
                            <FlashMap
                                hasSearchControl={true}
                                showDetailedPlacemarkContent={true}
                                placemarks={mediaSpaceMapPlacemark}
                                isMediaSpaceSubForm={true}
                                setChecked={setChecked}
                                checked={checked}
                            />
                        </Box>
                    ) : (
                        <LoadingIndicatorPopup isLoaded={isDataLoaded} />
                    )}
                </>
            );
        }
    };

    return (
        <>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                closeAfterTransition
                onClose={handleClose}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                data-id="modal"
            >
                <Fade in={openModal}>
                    <ImageViewStyled src={imageSrc || defaultMediaSpaceImg} />
                </Fade>
            </ModalStyled>
            <MediaSpacesListContent
                filter={
                    <MediaSpacesSubFormFilter
                        setTableMap={setTableMap}
                        tableMap={tableMap}
                        amountOfMediaSpaces={amountOfMediaSpaces}
                        allSelected={allSelected}
                        setAllSelected={setAllSelected}
                    />
                }
                content={getGridContent()}
            ></MediaSpacesListContent>
            {<LoadingIndicator isLoaded={isDataLoaded} />}
        </>
    );
}
