import { fade } from '@material-ui/core';

import styled from 'styled-components';

import { variables } from 'theme/variables';

export const StyledButtons = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    flexWrap: 'wrap',
    width: 'auto',
    padding: '-5px -10px',

    '.option-button': {
        display: 'inline-flex',
        padding: 5,
        minWidth: 100,
        border: `1px solid ${variables.colours.primary}`,
        borderRadius: 4,
        margin: '5px 10px',

        '&[name="no"]': {
            borderColor: fade(variables.colours.primary, 0.5),

            '.no-text': {
                color: fade(variables.colours.primary, 0.5),
            },
        },

        ':hover': {
            borderColor: variables.colours.primary,

            '.yes-text, .no-text': {
                color: variables.colours.primary,
            },
        },
    },

    '.yes-text, .no-text': {
        transition: '.2s ease',
    },

    '.yes-text': {
        color: variables.colours.light,
    },

    '.no-text': {
        color: variables.colours.primary,
    },
});
