import { AxiosResponse } from 'axios';
import { ChatVm } from '../models/chat';
import { ChatMessageVm } from '../models/chat-message-model';
import axios from './axios';
import { NotificationShortVm } from '../models/notification-model';

export const get = (): Promise<AxiosResponse<Array<ChatVm>>> => {
    return axios.get(`chat`);
};

export const deleteChat = (id: string): Promise<AxiosResponse<string>> => {
    return axios.delete(`chat/${id}`);
};

export const createChat = (id: string): Promise<AxiosResponse<string>> => {
    return axios.post(`chat/${id}`);
};

export const sendMessage = (id: string, message: string): Promise<AxiosResponse<ChatMessageVm>> => {
    return axios.post(`chat/${id}/message`, { textMessage: message });
};

export const isUserInChat = (id: string): Promise<AxiosResponse<boolean>> => {
    return axios.get(`chat/${id}/hasuser`);
};

export const userHasAnyChat = (): Promise<AxiosResponse<boolean>> => {
    return axios.get(`chat/user/hasany`);
};

export const restoreUserInChat = (id: string): Promise<void> => {
    return axios.put(`chat/${id}/restore`);
};

export const readNotifications = (id: string): Promise<void> => {
    return axios.post(`notification/chat/${id}/read`);
};

export const deleteNotifications = (): Promise<void> => {
    return axios.delete(`notification`);
};

export const getNotifications = (token?: string): Promise<AxiosResponse<Array<NotificationShortVm>>> => {
    return axios.get(`notification`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};
