import { FC, forwardRef, useMemo, ReactNode } from 'react';

import { Link } from 'react-router-dom';
import { LinkProps } from 'react-router-dom';

import { ListItem, ListItemIcon, ListItemProps, ListItemText } from '@material-ui/core';
import { StyledBox } from './style';

type ListItemLinkProps = ListItemProps & {
    to: string;
    children: ReactNode;
    icon?: JSX.Element;
};

const ListItemLink: FC<ListItemLinkProps> = ({ to, children, icon }) => {
    const renderLink = useMemo(
        () =>
            // eslint-disable-next-line react/display-name
            forwardRef<HTMLAnchorElement | null, Omit<LinkProps, 'to'>>((itemProps, ref) => (
                <Link to={to} ref={ref} {...itemProps} />
            )),
        [to],
    );

    return (
        <ListItem button disableGutters={true} component={renderLink}>
            <ListItemText
                primary={
                    <StyledBox>
                        {icon && <ListItemIcon>{icon}</ListItemIcon>}
                        {children}
                    </StyledBox>
                }
            />
        </ListItem>
    );
};

export default ListItemLink;
