import React from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';
import { FlashTooltipStyled } from './style';

export type FlashTooltipProps = TooltipProps & {
    disableHighlighting?: boolean;
    children: React.ReactNode;
};

export default function FlashTooltip(props: FlashTooltipProps): JSX.Element {
    const { disableHighlighting = false, children } = props;
    return (
        <FlashTooltipStyled>
            <Tooltip {...props} className={disableHighlighting ? 'disable-highlighting' : ''}>
                {children}
            </Tooltip>
        </FlashTooltipStyled>
    );
}
