import { FormControlLabel, RadioGroup } from '@material-ui/core';
import jwt_decode from 'jwt-decode';
import { refresh, updateUserFromAccessToken } from 'api/auth';
import { currenciesAPI } from 'api/currency';
import { setCurrency } from 'api/profile';
import FlashDialog from 'components/atoms/dialog/FlashDialog';
import YesNoButtons from 'components/atoms/dialog/YesNoButtons';
import { CurrencyVm } from 'models/currency.model';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateRefresh } from 'store/auth/actions';
import { RootState } from '../../../../store/rootReducer';
import { RadioStyled } from './style';
import { AccessToken } from 'interface/accessToken';
import { updateUser } from '../../../../store/user/actions';

export type ConfirmButtonProps = {
    button: React.ReactElement;
    onClick: () => void;
    title: string;
};

export default function FormChangeCurrency({ button, title }: ConfirmButtonProps): JSX.Element {
    const user = useSelector((state: RootState) => state.userReducer.user);
    const auth = useSelector((state: RootState) => state.authReducer.auth);
    const [isShownPopup, setIsShownPopup] = useState(false);
    const [currencyList, setCurrencyList] = useState<CurrencyVm[]>([]);
    const [radioValue, setRadioValue] = React.useState(user.preferred_currency_id ?? '');

    const dispatch = useDispatch();

    const radioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
    };

    const handleClick = () => {
        setIsShownPopup(true);
    };

    useEffect(() => {
        async function fetchData() {
            const response = await currenciesAPI.getCurrencies();
            setCurrencyList(response.data);
            if (auth?.accessToken === '') {
                const localCurrencyCode = localStorage.getItem('currencyCode');
                if (localCurrencyCode) {
                    setRadioValue(response.data.filter((x) => x.code === localCurrencyCode)[0].id);
                } else {
                    localStorage.setItem('currencyCode', response.data.filter((x) => x.isDefault)[0].code);
                }
            } else if (user.preferred_currency_id) {
                setRadioValue(user.preferred_currency_id);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        setRadioValue(user.preferred_currency_id);
    }, [user.preferred_currency_id]);

    let btnLock = false;
    const saveCurrency = async () => {
        if (btnLock) return;
        btnLock = true;
        if (auth?.accessToken !== '') {
            const response = await setCurrency(radioValue);
            if (response.data) {
                const refreshResponse = await refresh({
                    accessToken: auth?.accessToken ?? '',
                    refreshToken: auth?.refreshToken ?? '',
                });
                updateUserFromAccessToken(refreshResponse.data.accessToken);
                const { exp: expRT } = jwt_decode(refreshResponse.data.refreshToken) as AccessToken;
                dispatch(
                    updateRefresh({
                        refreshToken: refreshResponse.data.refreshToken,
                        refreshTokenTTL: expRT,
                    }),
                );
            }
        } else {
            const currency = currencyList.filter((x) => x.id === radioValue)[0];
            if (currency) {
                localStorage.setItem('currencyCode', currency.code);
                dispatch(
                    updateUser({
                        email: '',
                        nameid: '',
                        role: '',
                        given_name: '',
                        family_name: '',
                        pic: '',
                        lc: '',
                        hasSignedInOnce: true,
                        preferred_currency_id: currency.id,
                    }),
                );
            }
        }
        setIsShownPopup(false);
        btnLock = false;
    };

    return (
        <>
            {React.cloneElement(button, { onClick: handleClick })}
            <FlashDialog
                open
                title={title}
                isOpen={isShownPopup}
                onClose={() => setIsShownPopup(false)}
                content={
                    <RadioGroup aria-labelledby="currency" name="currency" value={radioValue} onChange={radioChange}>
                        {currencyList.map((x) => (
                            <FormControlLabel
                                value={x.id}
                                control={<RadioStyled />}
                                label={`${x.name} (${x.code})`}
                                key={x.id}
                            />
                        ))}
                    </RadioGroup>
                }
                controls={<YesNoButtons onConfirm={saveCurrency} onReject={() => setIsShownPopup(false)} />}
            />
        </>
    );
}
