export const variables = {
    constants: {
        appBarHeight: 115,
        footerHeight: 130,
        drawerWidth: 223,
    },
    backgoroundColours: {
        primary: '#ff6000',
        dark: '#232323',
        light: '#ffffff',
        secondary: '#fffff',
        border: '#DDDDEE',
        warning: '#FF0100',
        facebook: '#4267B2',
    },
    buttonColours: {
        primary: '#ff6000',
        secondary: '#ff6000',
        error: '#d50909',
        warning: '#d57209',
        info: '#11cb5f',
    },
    rolesColours: {
        admin: '#FF0100',
        operator: '#913303',
        user: '#FFC01E',
    },
    stateColours: {
        active: '#729f72',
        inactive: '#ff0100',
    },
    deviceStatus: {
        online: '#21AD15',
        offline: '#DDDDEE',
    },
    colours: {
        light: '#ffffff',
        main: '#FFB78B',
        primary: '#ff6000',
        secondary: '#000000',
        error: '#d50909',
        warning: '#d57209',
        info: '#11cb5f',
        default: '#c4c4c4',
        dark: '#232323',
    },
    orderStatus: {
        cancelled: '#EA3C3B',
        approved: '#21AD15',
        onModeration: '#FFC01E',
        currentlyPlaying: '#41479B',
        completed: '#7D96D4',
        nullified: '#AE2B2B',
        onEditing: '#7D96D4',
        onPayWaiting: '#FFC01E',
    },
    paymentStatus: {
        nonPaid: '#21AD15',
        paid: '#21AD15',
        paymentReturned: '#0A5504',
        WithoutPayment: '#21AD15',
    },
    orderStatusLine: {
        processing: '#FFC01E',
        completed: '#ff6000',
    },
};
