import * as React from 'react';
import { ContainerProps } from '@material-ui/core';
import { StyledMain } from './style';
import FlashContainer from '../../../../components/atoms/FlashContainer';
import Grow from '../../../../components/atoms/Grow';

export default function MainContainer(props: ContainerProps & { isFullWidth?: boolean }): JSX.Element {
    return (
        <StyledMain>
            <Grow>
                <FlashContainer isFullWidth={props.isFullWidth}>{props.children}</FlashContainer>
            </Grow>
        </StyledMain>
    );
}
