import { Device, UPDATE_DEVICE_FILTER, REMOVE_FILTER, FilterActionTypes } from 'store/deviceFilter/types';

export const updateDeviceFilter = (data: Device): FilterActionTypes => ({
    type: UPDATE_DEVICE_FILTER,
    data,
});

export const removeFilter = (): FilterActionTypes => ({
    type: REMOVE_FILTER,
});
