import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ImgStatus, OrderStatusLineStyle } from './style';
import { OrderStatusType } from '../../../../shared/enums';
import { ReactComponent as OnModeration } from '../../../../assets/icons/orderStatusLineIcons/onModeration.svg';
import { ReactComponent as OnPayWaiting } from '../../../../assets/icons/orderStatusLineIcons/onPayWaiting.svg';
import { ReactComponent as Approved } from '../../../../assets/icons/orderStatusLineIcons/approved.svg';
import { ReactComponent as CurrentlyPlaying } from '../../../../assets/icons/orderStatusLineIcons/currentlyPlaying.svg';
import { ReactComponent as Completed } from '../../../../assets/icons/orderStatusLineIcons/completed.svg';
import useWindowDimensions from '../../../../hooks/WindowDimensions';

export default function OrderStatusLine(props: { orderStatus: number | undefined }): JSX.Element {
    const { orderStatus } = props;
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const orderStatusLinesItems = [
        OrderStatusType.onModeration,
        OrderStatusType.onPayWaiting,
        OrderStatusType.Approved,
        OrderStatusType.CurrentlyPlaying,
        OrderStatusType.Completed,
    ];

    const mobileVersionMaxWidth = 767;

    function getClassForItem(lineStatus: OrderStatusType): string {
        // if order completed
        if (orderStatus === OrderStatusType.Completed) {
            return 'status-highlighted-completed';
        }

        // if order is currentlyPlaying
        if (orderStatus === OrderStatusType.CurrentlyPlaying) {
            if (lineStatus === OrderStatusType.Completed) {
                return '';
            }

            if (lineStatus === OrderStatusType.CurrentlyPlaying) {
                return 'status-highlighted-processing';
            } else {
                return 'status-highlighted-completed';
            }
        }

        // if order approved
        if (
            orderStatus === OrderStatusType.Approved &&
            lineStatus !== OrderStatusType.Completed &&
            lineStatus !== OrderStatusType.CurrentlyPlaying
        ) {
            return 'status-highlighted-completed';
        }

        // if order on moderation
        if (
            orderStatus === OrderStatusType.onPayWaiting &&
            lineStatus !== OrderStatusType.Completed &&
            lineStatus !== OrderStatusType.CurrentlyPlaying &&
            lineStatus !== OrderStatusType.Approved
        ) {
            return 'status-highlighted-completed';
        }

        // if order on moderation
        if (orderStatus === OrderStatusType.onModeration && lineStatus === OrderStatusType.onModeration) {
            return 'status-highlighted-processing';
        }

        return '';
    }

    const statusIcon = (lineStatus: OrderStatusType) => {
        const svgWidth = width > mobileVersionMaxWidth ? '32px' : '24px';
        const svgHeight = width > mobileVersionMaxWidth ? '32px' : '24px';
        switch (lineStatus) {
            case OrderStatusType.onModeration:
                return <OnModeration width={svgWidth} height={svgHeight} />;
            case OrderStatusType.onPayWaiting:
                return <OnPayWaiting width={svgWidth} height={svgHeight} />;
            case OrderStatusType.Approved:
                return <Approved width={svgWidth} height={svgHeight} />;
            case OrderStatusType.CurrentlyPlaying:
                return <CurrentlyPlaying width={svgWidth} height={svgHeight} />;
            case OrderStatusType.Completed:
                return <Completed width={svgWidth} height={svgHeight} />;
        }
    };

    const statusTypography = (type: number) => {
        switch (type) {
            case OrderStatusType.onModeration:
                return (
                    <Typography className={getClassForItem(OrderStatusType.onModeration)} variant="subtitle1">
                        {t('order.status-line.onModeration')}
                    </Typography>
                );
            case OrderStatusType.onPayWaiting:
                return (
                    <Typography className={getClassForItem(OrderStatusType.onPayWaiting)} variant="subtitle1">
                        {t('order.status-line.onPayWaiting')}
                    </Typography>
                );
            case OrderStatusType.Approved:
                return (
                    <Typography className={getClassForItem(OrderStatusType.Approved)} variant="subtitle1">
                        {t('order.status-line.approved')}
                    </Typography>
                );
            case OrderStatusType.CurrentlyPlaying:
                return (
                    <Typography className={getClassForItem(OrderStatusType.CurrentlyPlaying)} variant="subtitle1">
                        {t('order.status-line.currentlyPlaying')}
                    </Typography>
                );
            case OrderStatusType.Completed:
                return (
                    <Typography className={getClassForItem(OrderStatusType.Completed)} variant="subtitle1">
                        {t('order.status-line.completed')}
                    </Typography>
                );
        }
    };

    return (
        <OrderStatusLineStyle>
            {orderStatusLinesItems.map((status, i) => (
                <Box
                    display="flex"
                    key={i}
                    mr="0.4rem"
                    minWidth={
                        width < mobileVersionMaxWidth
                            ? status === OrderStatusType.onModeration || status === OrderStatusType.CurrentlyPlaying
                                ? '93px'
                                : '83px'
                            : 'auto'
                    }
                    justifyContent="center"
                >
                    <Box>
                        <ImgStatus className={getClassForItem(status)}>{statusIcon(status)}</ImgStatus>
                        {statusTypography(status)}
                    </Box>
                    {i < orderStatusLinesItems.length - 1 && <hr className={getClassForItem(status)} />}
                </Box>
            ))}
        </OrderStatusLineStyle>
    );
}
