import styled from 'styled-components';

import DayPicker from 'react-day-picker';

import { variables } from 'theme/variables';

import 'react-day-picker/lib/style.css';

export const DatePickerWrapper = styled.div({
    display: 'inline-flex',
    position: 'relative',
    height: 'fit-content',
    width: 'fit-content',
});

export const LoadingOverlay = styled.div({
    position: 'absolute',
    top: 0,
    left: 0,
    background: '#fffc',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

export const StyledDatePicker = styled(DayPicker)({
    position: 'relative',

    '.DayPicker-wrapper': {
        padding: 0,
    },

    '.DayPicker-Day, .DayPicker-Weekday': {
        width: 34,
        height: 34,
    },

    '.DayPicker-Weekday': {
        fontSize: 14,
        color: variables.colours.primary,
    },

    '.DayPicker-Month': {
        margin: 0,
    },

    '.DayPicker-Day': {
        fontWeight: 400,
        background: 'none !important',

        '&.DayPicker-Day--outside': {
            color: variables.colours.default,
        },

        '&.DayPicker-Day--today': {
            color: variables.colours.primary,
        },

        '&.DayPicker-Day--selected': {
            position: 'relative',
            color: '#fff',

            '&:before': {
                content: `""`,
                backgroundColor: '#FFB78B',
                position: 'absolute',
                display: 'inline-block',
                zIndex: -1,
                height: 24,
                top: 5,
                bottom: 5,
                left: 0,
                right: 0,
            },

            '&:nth-child(7n):before': {
                borderTopRightRadius: 15,
                borderBottomRightRadius: 15,
            },

            '&:first-child:before': {
                borderTopLeftRadius: 15,
                borderBottomLeftRadius: 15,
            },

            '&:first-child': {
                ':is(.DayPicker-Day--end):after': {
                    borderTopLeftRadius: 15,
                    borderBottomLeftRadius: 15,
                },
            },

            '&.DayPicker-Day--start, &.DayPicker-Day--end': {
                ':before': {
                    backgroundColor: variables.colours.primary,
                    borderRadius: '50%',
                    height: 24,
                    width: 24,
                    left: 5,
                    top: 5,
                    right: 5,
                    bottom: 5,
                },
            },

            '&.DayPicker-Day--end': {
                ':after': {
                    content: `""`,
                    backgroundColor: '#FFB78B',
                    width: 17,
                    height: 24,
                    left: 0,
                    top: 5,
                    bottom: 5,
                    display: 'inline-block',
                    position: 'absolute',
                    zIndex: -2,
                },

                ':is(&.DayPicker-Day--start)': {
                    ':after': {
                        display: 'none',
                    },
                },
            },

            '&.DayPicker-Day--start': {
                '+ .DayPicker-Day--selected': {
                    ':after': {
                        content: `""`,
                        backgroundColor: '#FFB78B',
                        width: 17,
                        height: 24,
                        top: 5,
                        bottom: 5,
                        right: 0,
                        left: -17,
                        display: 'inline-block',
                        position: 'absolute',
                        zIndex: -2,
                    },

                    '&.DayPicker-Day--end:after': {
                        width: 34,
                    },
                },
            },

            '&.DayPicker-Day--disabled': {
                color: '#ccc',

                ':before, :after': {
                    backgroundColor: '#eee !important',
                },
            },
        },
    },
});
