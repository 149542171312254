import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { MenuItem } from '@material-ui/core';

import MenuItemChip from 'components/atoms/gridFilters/MenuItemChip';
import BaseFilterSelect from 'components/atoms/gridFilters/BaseFilterSelect';

import { OrderStatusType } from 'shared/enums';

import { variables } from 'theme/variables';

export interface RoleSelectProps {
    onStatusChanged: (status: OrderStatusType) => void;
    value?: OrderStatusType;
}

const OrderStatusSelect: FC<RoleSelectProps> = ({ onStatusChanged, value }) => {
    const { t } = useTranslation();

    return (
        <BaseFilterSelect
            defaultValue={OrderStatusType.All.toString()}
            onSelectionChanged={(value: OrderStatusType) => {
                onStatusChanged(value);
            }}
            value={value}
            name="order-select"
        >
            <MenuItem value={OrderStatusType.All} data-id="all-statuses">
                {t('order.status.allStatuses')}
            </MenuItem>
            <MenuItem value={OrderStatusType.onModeration} data-id="on-moderation">
                <MenuItemChip color={variables.orderStatus.onModeration} />
                {t('order.status.onModeration')}
            </MenuItem>
            <MenuItem value={OrderStatusType.onPayWaiting} data-id="on-pay-waiting">
                <MenuItemChip color={variables.orderStatus.onPayWaiting} />
                {t('order.status.onPayWaiting')}
            </MenuItem>
            <MenuItem value={OrderStatusType.Approved} data-id="approved">
                <MenuItemChip color={variables.orderStatus.approved} />
                {t('order.status.approved')}
            </MenuItem>
            <MenuItem value={OrderStatusType.Cancelled} data-id="cancelled">
                <MenuItemChip color={variables.orderStatus.cancelled} />
                {t('order.status.cancelled')}
            </MenuItem>
            <MenuItem value={OrderStatusType.Nullified} data-id="nullified">
                <MenuItemChip color={variables.orderStatus.nullified} />
                {t('order.status.nullified')}
            </MenuItem>
            <MenuItem value={OrderStatusType.CurrentlyPlaying} data-id="playing">
                <MenuItemChip color={variables.orderStatus.currentlyPlaying} />
                {t('order.status.currentlyPlaying')}
            </MenuItem>
            <MenuItem value={OrderStatusType.Completed} data-id="completed">
                <MenuItemChip color={variables.orderStatus.completed} />
                {t('order.status.completed')}
            </MenuItem>
        </BaseFilterSelect>
    );
};

export default OrderStatusSelect;
