import { PagingVmBaseResponse } from './paging/paging.model';

export type CurrencyVm = {
    id: string;
    name: string;
    code: string;
    isDefault: boolean;
};

export type CurrencyInputModel = {
    name: string;
    code: string;
    isDefault: boolean;
};

export const DefaultCurrencyInput: CurrencyInputModel = {
    name: '',
    code: '',
    isDefault: false,
};

export type CurrencyBaseVm = CurrencyVm & {
    id: string;
};

export type CurrencyPage = {
    page: number;
    pageSize: number;
    property?: string;
    direction?: number | null;
};

export type CurrencyPageVm = PagingVmBaseResponse<CurrencyVm>;
