import { FC, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, BoxProps, Typography } from '@material-ui/core';

import { AuthLogo, StyledAuth } from 'components/templates/Auth/style';

import logoImage from 'assets/images/logo.png';
import { Languages } from '../../../shared/enums';

export type PrivacyLinks = {
    publicOffer: string;
    privacyPolicy: string;
};

const AuthTemplate: FC<BoxProps> = ({ children }) => {
    const { t } = useTranslation();
    const [privacyLinks, setPrivacyLinks] = useState<PrivacyLinks>();

    const landingLink = window.ENV.config.landingUrl;

    useEffect(() => {
        const locale = localStorage.getItem('locale');

        if (locale === Languages.Russian) {
            const defaultPrivacyLinks = {
                publicOffer: '/documents/public_offer_ru.html',
                privacyPolicy: '/documents/privacy_policy_ru.html',
            } as PrivacyLinks;
            setPrivacyLinks(defaultPrivacyLinks);
        } else if (locale === Languages.English) {
            const defaultPrivacyLinks = {
                publicOffer: '/documents/public_offer_en.html',
                privacyPolicy: '/documents/privacy_policy_en.html',
            } as PrivacyLinks;
            setPrivacyLinks(defaultPrivacyLinks);
        }
    }, []);

    return (
        <StyledAuth boxShadow={5} borderRadius={15}>
            <a href={landingLink}>
                <AuthLogo src={logoImage} />
            </a>
            {children}
            <Box display="flex" justifyContent="center" className="protection-handler">
                <Box className="protection-message-box">
                    <Typography variant="subtitle1" color="secondary">
                        {t('general.protectionMessage')}&nbsp;
                        <Link
                            to={privacyLinks?.publicOffer ?? ''}
                            target="_blank"
                            className="button-handler protection-message"
                            data-id="terms-of-service"
                        >
                            {t('general.terms-of-service').toLowerCase()}
                        </Link>
                        &#44;&nbsp;
                        <Link
                            to={privacyLinks?.privacyPolicy ?? ''}
                            target="_blank"
                            className="button-handler protection-message"
                            data-id="privacy-policy"
                        >
                            {t('general.privacy-policy').toLowerCase()}
                        </Link>
                        &nbsp;{t('general.protectionMessageEnd').toLowerCase()}&#46;
                    </Typography>
                </Box>
            </Box>
        </StyledAuth>
    );
};

export default AuthTemplate;
