import moment from 'moment';

export const generateRange = (start?: Date | string, end?: Date | string, iso?: boolean): (string | Date)[] => {
    if (!(start && end)) return [];

    const rangeLength = Math.abs(moment(start).diff(end, 'days', false)) + 1;

    const rangedDates = new Array(rangeLength).fill(moment(start)).map((date, index) => {
        const newDate = moment(date).add(index, 'days').toDate();
        return iso ? newDate.toISOString() : newDate;
    });

    return rangedDates;
};

const phoneCharactersRegex = /[\D+]+/g;

export const unmaskPhone = (phone: string): string => '+' + phone.replace(phoneCharactersRegex, '');
