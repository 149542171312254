import React, { useEffect } from 'react';
import { StyledRolesChip } from './style';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

export type RolesChipProps = {
    role: string;
    disabled?: boolean;
};

export default function RolesChip(props: RolesChipProps): JSX.Element {
    const { role, disabled } = props;
    const [state, setState] = React.useState('');
    const { t } = useTranslation();

    useEffect(() => {
        setState(role);
    }, [role]);

    return (
        <StyledRolesChip
            className={disabled ? 'lockout-enabled' : state}
            size="small"
            label={
                <Typography variant="subtitle1" className="role">
                    {t(`roles.${state}`)}
                </Typography>
            }
        />
    );
}
