import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getConfig } from '../../../config';
import { Box, Typography } from '@material-ui/core';
import FlashMap, { MapPlacemark } from '../../organisms/FlashMap';
import { StyledBackground, StyledTemplate } from './style';
import BackgroundContacts from '../../../assets/images/background_contacts.svg';
import logoImage from 'assets/images/logo.png';
import useSelector from 'hooks/useAppSelector';

export default function ContactsPage(): JSX.Element {
    const { t } = useTranslation();

    const [address, setAddress] = useState<string | null>(null);
    const [phone, setPhone] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [placeMarks, setPlaceMarks] = useState<MapPlacemark[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
    const userLocale = useSelector((state) => state.userReducer.user.lc);
    const [config, setConfig] = useState<Config | null>(null);
    const [company, setCompany] = useState<string | null>(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        let isMounted = true;

        getConfig().then((response) => {
            if (isMounted) setConfig(response);
        });

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (!config) return;

        if (userLocale) {
            if (userLocale === 'ru') {
                setCompany(config.contactsCompanyRu);
                setAddress(config.contactsAddressRu);
            } else if (userLocale === 'en') {
                setCompany(config.contactsCompanyEn);
                setAddress(config.contactsAddressEn);
            }
        } else {
            if (localStorage.getItem('locale')) {
                if (localStorage.getItem('locale') === 'ru') {
                    setCompany(config.contactsCompanyRu);
                    setAddress(config.contactsAddressRu);
                } else if (localStorage.getItem('locale') === 'en') {
                    setCompany(config.contactsCompanyEn);
                    setAddress(config.contactsAddressEn);
                }
            } else {
                if (config.defaultLocale === 'ru') {
                    setCompany(config.contactsCompanyRu);
                    setAddress(config.contactsAddressRu);
                } else if (config.defaultLocale === 'en') {
                    setCompany(config.contactsCompanyEn);
                    setAddress(config.contactsAddressEn);
                }
            }
        }

        setPhone(config.contactsPhone);
        setEmail(config.contactsEmail);
        if (company) {
            setPlaceMarks([
                {
                    isHighlighted: true,
                    lat: config.contactsLatitude,
                    long: config.contactsLongitude,
                    id: 'address',
                    address: userLocale === 'en' ? config.contactsAddressEn : config.contactsAddressRu,
                    name: company,
                },
            ]);

            setIsDataLoaded(true);
        }
    }, [userLocale, config, company]);

    useEffect(() => {
        setIsDataLoaded(true);
    }, [placeMarks]);

    return (
        <StyledTemplate>
            <Typography variant="h1">{t('navigation.contacts')}</Typography>
            <div className="contacts" itemScope itemType="http://schema.org/Organization">
                <meta itemProp="name" content={t('general.company-name')} />
                <meta itemProp="image" content={logoImage} />
                <div className="contacts_item">
                    <Typography variant="h6" className="contacts_title">
                        {t('contacts.address')}
                    </Typography>
                    <Typography className="contacts_text">
                        <span itemProp="address">{address}</span>
                    </Typography>
                </div>
                <div className="contacts_item">
                    <Typography variant="h6" className="contacts_title">
                        {t('contacts.phone')}
                    </Typography>
                    <a href={`tel:${phone}`} className="contacts_text">
                        <span itemProp="telephone">{phone}</span>
                    </a>
                </div>
                <div className="contacts_item">
                    <Typography variant="h6" className="contacts_title">
                        {t('contacts.email')}
                    </Typography>
                    <a href={`mailto:${email}`} className="contacts_text">
                        <span itemProp="email">{email}</span>
                    </a>
                </div>
            </div>
            <Box height="40rem" className="map-container">
                {isDataLoaded && (
                    <FlashMap hasSearchControl={true} placemarks={placeMarks} showDetailedPlacemarkContent={false} />
                )}
            </Box>
            <StyledBackground>
                <img src={BackgroundContacts} alt="" />
            </StyledBackground>
        </StyledTemplate>
    );
}
