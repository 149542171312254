import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const FlashGridHeaderStyled = styled.p(({}) => ({
    color: variables.colours.light,
    fontSize: '0.938rem',
    overflow: 'hidden',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '.MuiDataGrid-columnHeaderTitleContainer': {
        padding: '0!important',
    },
}));
