import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { CircularProgress, Tooltip } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';

import DateRenderer from 'components/atoms/DateRenderer';
import GridCellLink from 'components/atoms/GridCellLink';
import DeviceStatusChip from 'components/atoms/controls/DeviceStatusChip';

import { useNotification } from 'components/organisms/NotificationProvider';

import allRoutes from 'router';

import { MomentFormats, UserRoles } from 'shared/enums';
import { HubEvents } from 'components/organisms/forms/ChatForm';
import { DeviceBaseVm, DeviceScreenshotVm } from 'models/device.model';
import { DeviceStatusEnum } from 'components/atoms/gridFilters/DeviceStatusSelect';

import useSelector from 'hooks/useAppSelector';

import { ReactComponent as DeviceScreen } from 'assets/icons/DeviceScreen.svg';
import { ReactComponent as DeleteButton } from 'assets/icons/DeleteButton.svg';
import { ReactComponent as PaymentAttention } from 'assets/icons/PaymentAttention.svg';

import { ActionButton, GridText } from 'components/templates/Devices/style';
import { getDateWithLocalTz } from '../../../hooks/getDateWithLocalTz';
import FlashGridHeader from '../../atoms/FlashGridHeader';
import { SortField } from '../../../models/paging/paging.model';

interface DevicesGridProps {
    devices: DeviceBaseVm[];
    setDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
    setImageModalOpen: Dispatch<SetStateAction<boolean>>;
    setScreenshotSrc: Dispatch<SetStateAction<string>>;
    setDeviceIdToDelete: Dispatch<SetStateAction<string>>;
    setScreenshotInfo: Dispatch<SetStateAction<boolean>>;
    setPlaylistFileName: Dispatch<SetStateAction<string>>;
    setFileName: Dispatch<SetStateAction<string>>;
    setOrderNumber: Dispatch<SetStateAction<number>>;
    setPlaylistName: Dispatch<SetStateAction<string>>;
}

export default function DevicesColumnsGrid(props: DevicesGridProps): GridColDef[] {
    const {
        devices,
        setDeleteDialogOpen,
        setImageModalOpen,
        setScreenshotSrc,
        setDeviceIdToDelete,
        setScreenshotInfo,
        setFileName,
        setOrderNumber,
        setPlaylistFileName,
        setPlaylistName,
    } = props;

    const screenTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const { t } = useTranslation();
    const { hub, updateHub } = useNotification();
    const history = useHistory();

    const [screenshotLoading, setScreenshotLoading] = useState<string | null>(null);

    const user = useSelector((state) => state.userReducer.user);

    const renderRowActions = (params: GridCellParams) => {
        const isOffline = +params.row.status === DeviceStatusEnum.offline;

        return (
            <>
                <ActionButton
                    name="show-button"
                    onClick={() => getScreenshot(params)}
                    disabled={isOffline || Boolean(screenshotLoading)}
                >
                    {screenshotLoading === params.row.id ? <CircularProgress size={24} /> : <DeviceScreen />}
                </ActionButton>
                {user.role === UserRoles.Admin && (
                    <ActionButton
                        name="delete-button"
                        onClick={async () => {
                            setDeviceIdToDelete(params.row.id);
                            setDeleteDialogOpen(true);
                        }}
                    >
                        <DeleteButton />
                    </ActionButton>
                )}
            </>
        );
    };

    const getScreenshot = (params: GridCellParams) => {
        setScreenshotLoading(params.row.id);
        hub?.invoke('RequestScreenshot', params.row.id)
            .then(() => {
                console.log('SignalR Connected.');
            })
            .catch(() => {
                updateHub();
            });
        screenTimeoutRef.current = setTimeout(() => {
            hub?.off(HubEvents.onNewScreenshot);
            setScreenshotLoading(null);
        }, 15 * 1000);
    };

    const onNewScreenshotFromHub = (data: DeviceScreenshotVm) => {
        setScreenshotInfo(true);
        setFileName(data.file?.video?.name || '');
        setOrderNumber(data.file?.video?.order?.number || 0);
        setPlaylistFileName(data.file?.playlistFile?.name);
        setPlaylistName(data.file?.playlistFile?.playlist?.name);
        setScreenshotSrc('data:image/jpeg;base64,' + data.screenshotBase64.replace(/\n/g, ''));
        setImageModalOpen(true);
        setScreenshotLoading(null);

        if (screenTimeoutRef.current) {
            clearTimeout(screenTimeoutRef.current);
            screenTimeoutRef.current = null;
        }
    };

    useEffect(() => {
        hub?.on(HubEvents.onNewScreenshot, onNewScreenshotFromHub);
        return () => {
            hub?.off(HubEvents.onNewScreenshot);
        };
    }, [screenshotLoading]);

    const getColumns = useCallback(() => {
        return [
            {
                field: SortField.DeviceName,
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'devices.name'} withPadding={true} disableHighlighting={true} />;
                },
                flex: 0.7,
                headerAlign: 'left',
                align: 'left',
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.deviceProfile.path, {
                                    id: params.row.id,
                                    pageId: 1,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            <GridText>{params.row.name}</GridText>
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.DeviceMediaSpace,
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'devices.mediaSpaces'} withPadding={true} />;
                },
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                valueGetter: (params: GridCellParams) => {
                    return params.row.mediaSpace.name;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    const mediaSpace = params.row.mediaSpace;
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.deviceProfile.path, {
                                    id: params.row.id,
                                    pageId: 1,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            {mediaSpace ? (
                                <Tooltip
                                    title={<GridText className="tooltip">{mediaSpace.name}</GridText>}
                                    placement="top-start"
                                    arrow
                                >
                                    <GridText className="disable-highlighting">{mediaSpace.name}</GridText>
                                </Tooltip>
                            ) : (
                                <Tooltip
                                    title={<GridText className="tooltip">{t('devices.no-mediaspace')}</GridText>}
                                    placement="top-start"
                                    arrow
                                >
                                    <PaymentAttention className="disable-highlighting" />
                                </Tooltip>
                            )}
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.DeviceLastConnectionDate,
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'devices.connection-date'} withPadding={false} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.deviceProfile.path, {
                                    id: params.row.id,
                                    pageId: 1,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            <DateRenderer
                                date={getDateWithLocalTz(new Date(params.row.lastConnectionDate))}
                                format={MomentFormats.Long}
                            />
                        </GridCellLink>
                    );
                },
                align: 'left',
                headerAlign: 'left',
                width: 140,
            },
            {
                field: SortField.DeviceMemory,
                headerName: t('devices.memory'),
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'devices.memory'} withPadding={true} />;
                },
                flex: 1.1,
                align: 'left',
                headerAlign: 'left',
                valueGetter: (params: GridCellParams) => {
                    return params.row.availableMemory > 0 ? 1 - params.row.usedMemory / params.row.availableMemory : 0;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    const usedMemory = +params.row.usedMemory.toFixed(1);
                    const availableMemory = +params.row.availableMemory.toFixed(1);
                    const lowMemory =
                        params.row.usedMemory > params.row.availableMemory - params.row.availableMemory * 0.2;

                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.deviceProfile.path, {
                                    id: params.row.id,
                                    pageId: 1,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            <GridText className="memory">
                                {availableMemory < 1024 &&
                                    usedMemory +
                                        t('device-profile.megabyte') +
                                        ' / ' +
                                        availableMemory +
                                        t('device-profile.megabyte')}
                                {availableMemory >= 1024 &&
                                    (usedMemory / 1024).toFixed(2) +
                                        t('device-profile.gigabyte') +
                                        ' / ' +
                                        (availableMemory / 1024).toFixed(2) +
                                        t('device-profile.gigabyte')}
                                {lowMemory && (
                                    <Tooltip
                                        title={<GridText className="tooltip">{t('devices.low-memory')}</GridText>}
                                        placement="top-start"
                                        arrow
                                    >
                                        <PaymentAttention className="disable-highlighting" />
                                    </Tooltip>
                                )}
                            </GridText>
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.DeviceStatus,
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'devices.status'} withPadding={false} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.deviceProfile.path, {
                                    id: params.row.id,
                                    pageId: 1,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            <DeviceStatusChip status={params.row.status.toLocaleString() || ''} />
                        </GridCellLink>
                    );
                },
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: 'actions',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'devices.actions'} withPadding={false} />;
                },
                width: 130,
                renderCell: (params: GridCellParams) => renderRowActions(params),
                sortable: false,
                disableClickEventBubbling: true,
                align: 'center',
                headerAlign: 'center',
            },
        ] as GridColDef[];
    }, [devices, screenshotLoading]);

    return getColumns();
}
