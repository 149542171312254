import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const CurrencyGridStyled = styled.div(({}) => ({
    '.MuiDataGrid-columnHeader:first-child': {
        paddingLeft: '1.438rem',
    },
    '.MuiDataGrid-row': {
        paddingLeft: '0 !important',
    },
    '.MuiDataGrid-cell': {
        ':first-child': {
            a: {
                justifyContent: 'start',
                paddingLeft: '0.719rem',
            },
            p: {
                color: variables.colours.main,
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },

        ':nth-child(2)': {
            a: {
                justifyContent: 'start',
            },
            p: {
                textTransform: 'uppercase',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
        },
    },
}));
