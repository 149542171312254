import { ChatVm } from './chat';
import { OrderVm } from './order';
import { OrderStatusType } from '../shared/enums';

export type NotificationVm = {
    id: string;
    actionType: number;
    chat: ChatVm;
    userUid: string;
    message: string;
    order: OrderVm;
};

export enum NotificationType {
    chatMessage,
    createOrder,
}

export enum ActionTypes {
    chatMessage,
    createOrder,
    approvedOrder,
    canceledOrder,
    nullifiedOrder,
}

export type NotificationShortVm = {
    id: string;
    updatedDate: Date;
    actionType: number;
    message: string;
    isRead: boolean;
    order: OrderNotificationShortVm;
    chat: ChatNotificationShortVm;
};

type OrderNotificationShortVm = {
    id: string;
    number: number;
    userId: string;
    status: OrderStatusType;
};

type ChatNotificationShortVm = {
    id: string;
    lastMessageDate: string;
};
