import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const StyledTemplate = styled(Box)(({ theme }) => ({
    width: '100%',
    height: '100%',
    maxWidth: 660,
    margin: '0 auto',
    paddingTop: '1.875rem',
    paddingBottom: '1.875rem',
    h1: {
        marginTop: '1.8rem',
        color: variables.colours.primary,
    },
    '.content-handler': {
        svg: {
            color: variables.colours.primary,
            paddingLeft: '2px',
        },
        margin: '1.875rem 2.313rem',
        '@media only screen and (min-width: 768px) and (max-width : 1023px)': {
            margin: theme.spacing(3),
        },
        '@media only screen and (min-width : 320px) and (max-width: 767px)': {
            margin: theme.spacing(2),
        },
    },
    '.go-back': {
        padding: 0,
        justifyContent: 'flex-start',
        h6: {
            color: variables.colours.primary,
        },
    },
}));

export const LogoHandler = styled.div({
    width: '100%',
    height: '80px',
    maxWidth: 660,
    paddingLeft: '1.875rem',
    paddingTop: '0.188rem',
    background: variables.backgoroundColours.dark,
});

export const Logo = styled.img({
    height: 70,
});
