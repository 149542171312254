import { FC, ReactNode } from 'react';

import { LinkProps } from 'react-router-dom';

import { StyledCellLink } from './style';

type GridCellLinkProps = LinkProps & {
    children: ReactNode;
};

const GridCellLink: FC<GridCellLinkProps> = ({ children, to }) => {
    return <StyledCellLink to={to}>{children}</StyledCellLink>;
};

export default GridCellLink;
