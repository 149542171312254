import i18n from 'i18n/config';
import { variables } from 'theme/variables';

import { PaymentStatus, OrderStatusType } from 'shared/enums';
import { LocaleForYMap } from 'shared/types';

export const DaysOfWeek = [
    { name: 'Sunday', number: 0 },
    { name: 'Monday', number: 1 },
    { name: 'Tuesday', number: 2 },
    { name: 'Wednesday', number: 3 },
    { name: 'Thursday', number: 4 },
    { name: 'Friday', number: 5 },
    { name: 'Saturday', number: 6 },
];

export const Months = [
    { name: 'January', number: 0 },
    { name: 'February', number: 1 },
    { name: 'March', number: 2 },
    { name: 'April', number: 3 },
    { name: 'May', number: 4 },
    { name: 'June', number: 5 },
    { name: 'July', number: 6 },
    { name: 'August', number: 7 },
    { name: 'September', number: 8 },
    { name: 'October', number: 9 },
    { name: 'November', number: 10 },
    { name: 'December', number: 11 },
];

export const Hours: number[] = Array.from({ length: 24 }, (_, i) => i + 1);

export const DaysOfMonth: number[] = Array.from({ length: 31 }, (_, i) => i + 1);

export const PageSize = 12;

interface StatusMapValue {
    color: string;
    message: string;
}

export const OrderStatusMap: Record<OrderStatusType, StatusMapValue> = {
    [OrderStatusType.Cancelled]: {
        color: variables.orderStatus.cancelled,
        message: 'order.status.cancelled',
    },
    [OrderStatusType.PaymentRefundFailed]: {
        color: variables.orderStatus.cancelled,
        message: 'order.status.refunded',
    },
    [OrderStatusType.Approved]: {
        color: variables.orderStatus.approved,
        message: 'order.status.approved',
    },
    [OrderStatusType.onModeration]: {
        color: variables.orderStatus.onModeration,
        message: 'order.status.onModeration',
    },
    [OrderStatusType.onPayWaiting]: {
        color: variables.orderStatus.onPayWaiting,
        message: 'order.status.onPayWaiting',
    },
    [OrderStatusType.CurrentlyPlaying]: {
        color: variables.orderStatus.currentlyPlaying,
        message: 'order.status.currentlyPlaying',
    },
    [OrderStatusType.Nullified]: {
        color: variables.orderStatus.nullified,
        message: 'order.status.nullified',
    },
    [OrderStatusType.PaymentRefunded]: {
        color: variables.orderStatus.nullified,
        message: 'order.status.refunded',
    },
    [OrderStatusType.Completed]: {
        color: variables.orderStatus.completed,
        message: 'order.status.completed',
    },
    [OrderStatusType.All]: {
        color: variables.colours.default,
        message: 'order.status.allStatuses',
    },
    [OrderStatusType.Created]: {
        color: variables.colours.default,
        message: 'order.status.created',
    },
};

export const PaymentStatusMap: Record<PaymentStatus, StatusMapValue> = {
    [PaymentStatus.Paid]: {
        color: variables.paymentStatus.paid,
        message: i18n.t('order.payment-status.paid'),
    },
    [PaymentStatus.NonPaid]: {
        color: variables.paymentStatus.nonPaid,
        message: i18n.t('order.payment-status.non-paid'),
    },
    [PaymentStatus.PaymentReturned]: {
        color: variables.paymentStatus.paymentReturned,
        message: i18n.t('order.payment-status.payment-returned'),
    },
    [PaymentStatus.WithoutPayment]: {
        color: variables.paymentStatus.WithoutPayment,
        message: i18n.t('order.payment-status.without-payment'),
    },
};

export const currencies: { [id: string]: string } = {
    RUB: '₽',
    USD: '$',
};

export const localizedСurrencies: { [id: string]: string } = {
    ru: 'RUB',
    en: 'USD',
};

export const langCodesForYMaps: { [id: string]: LocaleForYMap } = {
    en: 'en_US',
    ru: 'ru_RU',
    tr: 'tr_TR',
    uk: 'uk_UA',
};
