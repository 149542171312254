import { FC, useEffect, useState, useMemo, useCallback, ReactNode, ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';

import { FormHelperText, InputLabel, ListItemText, Select, MenuProps } from '@material-ui/core';

import FlashCheckbox from 'components/atoms/FlashCheckbox';

import { MediaSpaceSelectOption } from 'models/media-space.model';

import { MediaSpaceChangeStyle, MenuItemStyle } from 'components/molecules/forms/MediaSpacesMultiSelect/style';

export interface MediaSpaceChangeData {
    isAllSelected: boolean;
    selectedMediaSpaces: string[];
}
export interface MediaSpacesMultiSelectProps {
    controlsDirection: 'column' | 'column-reverse' | 'row' | 'flex-start';
    options: MediaSpaceSelectOption[];
    value: string[];
    onChange: (value: string[]) => void;
    label?: string;
    error?: boolean;
    helperText?: ReactNode;
    displayField: 'name' | 'address';
    variant?: 'outlined' | 'standard';
    iconComponent?: React.ElementType;
}

const menuProps: Partial<MenuProps> = {
    style: {
        maxHeight: '30.313rem',
    },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
};

const MediaSpacesMultiSelect: FC<MediaSpacesMultiSelectProps> = ({
    iconComponent,
    value,
    onChange,
    options,
    error,
    label,
    helperText,
    variant,
    displayField,
}) => {
    const { t } = useTranslation();

    const [hasError, setHasError] = useState(false);

    const handleMediaSpacesChange = useCallback((event: ChangeEvent<{ value: unknown }>) => {
        const selectedMediaSpaces = event.target.value as string[];
        onChange(selectedMediaSpaces);
    }, []);

    const sortedMediaSpaces = useMemo(() => {
        if (displayField !== 'address') return options;
        return options.sort((a, b) => (a.address > b.address ? 1 : -1));
    }, [displayField, options]);

    useEffect(() => {
        setHasError(error || false);
    }, [error]);

    return (
        <MediaSpaceChangeStyle className="media-space-multiselect">
            {label && <InputLabel>{label}</InputLabel>}
            <Select
                IconComponent={iconComponent}
                variant={variant}
                multiple
                error={error}
                color="primary"
                value={value}
                disabled={!options.length}
                renderValue={(selected): JSX.Element => {
                    const arraySelected = selected as string[];
                    const messageData = { count: arraySelected.length };
                    const multyselectedValue = <>{t('order-form.button-text.selected-mediaspaces', messageData)}</>;
                    if (arraySelected.length > 1) return multyselectedValue;

                    const selectedSpace = options.find(({ id }) => id === (selected as string[])[0]);

                    if (selectedSpace) return <>{selectedSpace[displayField]}</>;

                    return multyselectedValue;
                }}
                onChange={handleMediaSpacesChange}
                MenuProps={menuProps}
                name="media-space-select"
            >
                {sortedMediaSpaces.map((val) => (
                    <MenuItemStyle key={val.id} value={val.id} data-id="media-space">
                        <FlashCheckbox checked={value?.includes(val.id)} name="checkbox" />
                        <ListItemText primary={val[displayField]} />
                    </MenuItemStyle>
                ))}
            </Select>
            {hasError && <FormHelperText>{helperText}</FormHelperText>}
        </MediaSpaceChangeStyle>
    );
};

export default MediaSpacesMultiSelect;
