import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { generatePath, useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import CustomCheckbox from '../../atoms/Checkbox';
import { CheckboxStyle } from './style';
import CheckboxMenu from '../../atoms/CheckboxMenu';
import { toast } from 'react-toastify';
import { NameField, OrderRow } from './types';
import { declineManyOrders } from '../../../api/orders';
import { OrderStatusType } from '../../../shared/enums';
import OrderStatus from '../../atoms/Order/OrderStatus';
import { ReactComponent as PaymentAttention } from '../../../assets/icons/PaymentAttention.svg';
import { Typography } from '@material-ui/core';
import { variables } from '../../../theme/variables';
import allRoutes from 'router';
import GridCellLink from 'components/atoms/GridCellLink';
import FlashTooltip from 'components/atoms/FlashTooltip';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';

type OrdersGridProps = {
    rows: OrderRow[];
    checked: string[];
    setChecked: React.Dispatch<React.SetStateAction<string[]>>;
    setIsGridLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    loadOrders: (
        createdDate?: string | undefined,
        userName?: string | undefined,
        orderNumber?: number | undefined,
    ) => Promise<void>;
};

export default function OrdersColumnsGrid(props: OrdersGridProps): GridColDef[] {
    const { rows, checked, setChecked, setIsGridLoaded, loadOrders } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.userReducer.user);

    const getColumns = useCallback(() => {
        return [
            {
                field: NameField.OrderId,
                headerName: 'Menu',
                renderHeader: function renderOrderId() {
                    return (
                        <CheckboxMenu
                            actions={[
                                {
                                    action: async () => {
                                        setIsGridLoaded(false);
                                        await declineManyOrders(checked);
                                        setIsGridLoaded(true);
                                        toast.success(t('order-form.messages.success-decline-admin'));
                                        setChecked([]);
                                        loadOrders();
                                    },
                                    label: t('orders.checkbox.reject-marked'),
                                },
                            ]}
                            setChecked={setChecked}
                            rows={rows}
                        />
                    );
                },
                renderCell: function renderOrderId(params: GridCellParams) {
                    return (
                        <CheckboxStyle>
                            <CustomCheckbox
                                className={params.row.status === OrderStatusType.onModeration ? 'enabled-checkbox' : ''}
                                params={params}
                                disabled={params.row.status !== OrderStatusType.onModeration}
                                checked={checked}
                                setChecked={setChecked}
                            />
                        </CheckboxStyle>
                    );
                },
                width: 60,
                sortable: false,
                disableClickEventBubbling: true,
                align: 'center',
                headerAlign: 'left',
            },
            {
                field: NameField.Number,
                headerName: t('orders.number'),
                renderCell: function renderNumber(params: GridCellParams) {
                    if (OrderStatusType.PaymentRefundFailed == params.row.status) {
                        return (
                            <GridCellLink
                                to={{
                                    pathname: generatePath(allRoutes.orderProfile.path, {
                                        id: params.row.id,
                                    }),
                                    state: history.location.pathname,
                                }}
                            >
                                <Typography variant="body1" className="order-number">
                                    #{params.value}
                                    <FlashTooltip
                                        disableHighlighting
                                        title={
                                            <Typography variant="body1" style={{ color: variables.colours.light }}>
                                                {t('server-messages.payment-not-refunded')}
                                            </Typography>
                                        }
                                        placement="top-start"
                                        arrow
                                    >
                                        <PaymentAttention className="payment-attention" />
                                    </FlashTooltip>
                                </Typography>
                            </GridCellLink>
                        );
                    }
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.orderProfile.path, {
                                    id: params.row.id,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            <Typography variant="body1" className="order-number">
                                #{params.value}
                            </Typography>
                        </GridCellLink>
                    );
                },
                width: 90,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: NameField.CreatedDate,
                headerName: t('order.created-date'),
                renderCell: function renderCreatedDate(params: GridCellParams) {
                    const dateString = String(params.value).split(', ');
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.orderProfile.path, {
                                    id: params.row.id,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            <Typography variant="body1">{dateString[0]}</Typography>
                            <Typography variant="body1">{dateString[1]}</Typography>
                        </GridCellLink>
                    );
                },
                flex: 1,
                minWidth: 130,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: NameField.UserName,
                headerName: t('order.user-name'),
                renderCell: function renderUserName(params: GridCellParams) {
                    const path =
                        params.row.userId !== user.nameid
                            ? generatePath(allRoutes.userProfile.path, { id: params.row.userId })
                            : generatePath(allRoutes.currentUserProfile.path);
                    return (
                        <GridCellLink
                            to={{
                                pathname: path,
                                state: history.location.pathname,
                            }}
                        >
                            {params.row.userName}
                        </GridCellLink>
                    );
                },
                flex: 1,
                minWidth: 130,
                align: 'left',
                sortable: false,
                headerAlign: 'center',
            },
            {
                field: NameField.DateReply,
                headerName: t('order.date-reply'),
                renderCell: function renderRoles(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.orderProfile.path, {
                                    id: params.row.id,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            {params.row.dateReply}
                        </GridCellLink>
                    );
                },
                flex: 1,
                minWidth: 130,
                sortable: false,
                headerAlign: 'center',
            },
            {
                field: NameField.Status,
                headerName: t('order.status.name'),
                renderCell: function renderStatus(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.orderProfile.path, {
                                    id: params.row.id,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            <OrderStatus status={params.row.status} />
                        </GridCellLink>
                    );
                },
                flex: 1,
                minWidth: 130,
                sortable: false,
                align: 'center',
                headerAlign: 'center',
            },
            {
                field: NameField.Cost,
                headerName: t('order.cost'),
                flex: 1,
                minWidth: 130,
                align: 'left',
                sortable: false,
                headerAlign: 'center',
                renderCell: function renderCost(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.orderProfile.path, {
                                    id: params.row.id,
                                }),
                                state: history.location.pathname,
                            }}
                        >
                            <Typography variant="body1">{params.row.cost}</Typography>
                        </GridCellLink>
                    );
                },
            },
        ] as GridColDef[];
    }, [rows, checked]);

    return getColumns();
}
