import { variables } from '../../../theme/variables';
import styled from 'styled-components';

export const StyledChatLinkContainer = styled.div(({}) => ({
    '.order-title': {
        color: variables.colours.dark,
        position: 'absolute',
        top: '0.563rem',
        left: '1.25rem',
    },
    '.chat-order-header': {
        fontFamily: 'Roboto',
        letterSpacing: '0.09rem',
    },
    '.unread-order-title': {
        color: variables.colours.dark,
        fontWeight: 'bold',
        position: 'absolute',
        top: '0.563rem',
        left: '1.25rem',
    },
    Button: {
        height: '4.375rem',
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            height: '3.375rem',
        },
    },
    '.last-message-date': {
        position: 'absolute',
        top: '0.563rem',
        right: 0,
        fontWeight: 300,
    },
    '.unread-message-date': {
        position: 'absolute',
        top: '0.563rem',
        right: 0,
        fontWeight: 'bold',
    },
    '.unread-message': {
        color: variables.colours.default,
        position: 'absolute',
        fontWeight: 'bold',
        top: '2.5rem',
        left: '1.25rem',
        textAlign: 'left',
        maxWidth: '12rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    '.message': {
        color: variables.colours.default,
        position: 'absolute',
        top: '2.5rem',
        left: '1.25rem',
        textAlign: 'left',
        maxWidth: '12rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    '.delete-chat-button': {
        width: '4rem',
        paddingTop: '0.1875rem',
        height: 'fit-content',
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '3rem',
        },
    },
    display: 'flex',
    height: '4.563rem',
    borderRadius: '4px',
    boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.1)',
    '&.current': {
        backgroundColor: variables.colours.main,
        '.MuiTypography-root': {
            color: variables.colours.light,
        },
        p: {
            color: variables.colours.light,
        },
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        height: '3.763rem',
    },
}));

export const DelChat = styled.div(({}) => ({
    Button: {
        color: variables.colours.light,
        height: '0.625rem',
    },
    top: 0,
    right: 0,
}));

export const BadgeChat = styled.div(({}) => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '0.313rem',
    marginBottom: '0.313rem',
    img: {
        background: variables.colours.primary,
        height: '15px',
        width: '15px',
        borderRadius: '9px',
    },
    top: 0,
    right: 0,
}));

export const ChatsStyle = styled.div(({}) => ({
    width: '40%',
    height: '39.375rem',
    minWidth: '20rem',
    overflowX: 'hidden',
    '::-webkit-scrollbar': {
        width: '5px',
    },
    '::-webkit-scrollbar-thumb': {
        background: variables.colours.main,
        borderRadius: '29px',
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: variables.backgoroundColours.primary,
    },
    '::-webkit-scrollbar-track': {
        borderRadius: '0px',
    },
    '@media only screen and (min-width: 768px)': {
        borderRight: '10px solid white',
    },
    '@media only screen and (min-width: 320px) and (max-width: 767px)': {
        width: '100%',
        minWidth: '18rem',
    },
}));
