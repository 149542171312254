import { FC, useState, Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import TextContent from 'components/atoms/dialog/TextContent';
import YesNoButtons from 'components/atoms/dialog/YesNoButtons';
import FlashDialog from 'components/atoms/dialog/FlashDialog';

import { disable as changeEnable } from 'api/users';

import useSelector from 'hooks/useAppSelector';

import { UserRoles } from 'shared/enums';
import { UserModel } from 'models/user-model';

import { DisableButtonText, DisableButton, StyledBox } from 'components/molecules/User/DisableButton/style';

export interface DisableUserButtonProps {
    id: string;
    values: UserModel;
    isSelfEditing: boolean;
    isUserLockedOut: boolean;
    setIsUserLockedOut: Dispatch<SetStateAction<boolean>>;
}

const DisableUserButton: FC<DisableUserButtonProps> = ({
    id,
    values,
    isSelfEditing,
    isUserLockedOut,
    setIsUserLockedOut,
}) => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.userReducer.user);

    const [openEnabled, setOpenEnabled] = useState(false);

    async function setUserEnabled(enabled: boolean) {
        if (id) {
            await changeEnable(enabled, id);
            setIsUserLockedOut(!enabled);

            toast.success(enabled ? t('user-form.messages.success-enable') : t('user-form.messages.success-disable'));
        }
    }

    const handleCloseEnabled = () => setOpenEnabled(false);

    const handleOpenEnabled = () => setOpenEnabled(true);

    const handleConfirm = () => {
        setUserEnabled(isUserLockedOut);
        handleCloseEnabled();
    };

    return (
        <>
            {(user.role === UserRoles.Admin ||
                (user.role === UserRoles.Operator && id && values.role === UserRoles.User)) &&
                id &&
                !isSelfEditing && (
                    <>
                        <StyledBox>
                            <DisableButton onClick={handleOpenEnabled} name="disable-user">
                                <DisableButtonText>
                                    {isUserLockedOut
                                        ? t('user-form.button-text.enable')
                                        : t('user-form.button-text.disable')}
                                </DisableButtonText>
                            </DisableButton>
                        </StyledBox>
                        <FlashDialog
                            open
                            title={`${
                                isUserLockedOut ? t('user-form.button-text.enable') : t('user-form.button-text.disable')
                            } ?`}
                            isOpen={openEnabled}
                            onClose={handleCloseEnabled}
                            content={
                                <TextContent
                                    text={isUserLockedOut ? t('user.disableContentText') : t('user.enableContentText')}
                                />
                            }
                            controls={<YesNoButtons onConfirm={handleConfirm} onReject={handleCloseEnabled} />}
                        />
                    </>
                )}
        </>
    );
};

export default DisableUserButton;
