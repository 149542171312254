import { useState, useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import moment from 'moment';
import { cloneDeep, random } from 'lodash';

import { DateSelectArg, EventClickArg } from '@fullcalendar/react';

import allRoutes from 'router';
import { AxiosResponse } from 'axios';

import { CalendarEvent } from 'components/organisms/Prices/events';
import LoadingIndicatorPopup from 'components/atoms/LoadingIndicatorPopup';
import FlashCalendar, { CalendarResource } from 'components/organisms/calendar/Calendar';

import useSelector from 'hooks/useAppSelector';
import useRedirectWithData from 'hooks/RedirectWithData';

import { get } from 'api/price';
import { Frequency } from 'shared/enums';
import { MediaSpaceVm } from 'models/media-space.model';
import { PriceInputModel, PriceSearchStatus, PricesVm } from 'models/price.model';

const colors = ['#FF7267', '#FAB603', '#F80404', '#41479B', '#2C759E'];

const getBaseEvent = (x: PricesVm) => {
    return {
        id: x.id,
        title: x.title,
        allDay: x.isAllDay,
        editable: false,
        overlap: false,
        classNames: ['event-recurring'],
        extendedProps: {
            mediaSpaces: x.mediaSpaces,
            isRecurring: x.frequency !== Frequency.None,
            isForAllMediaSpaces: x.isForAllMediaSpaces,
            description: x.description,
            cost: x.cost,
        },
    };
};

const getNonRecurring = (x: PricesVm): CalendarEvent => {
    const baseEvent = getBaseEvent(x);
    const normalRules = {
        start: `${moment(x.startDate).format('YYYY-MM-DD')}T${x.timeStart}`,
        end: `${moment(x.endDate).format('YYYY-MM-DD')}T${x.timeEnd}`,
    };

    const event = {
        ...normalRules,
        ...baseEvent,
    } as CalendarEvent;
    return event;
};

const getReccuringObject = (x: PricesVm): CalendarEvent => {
    const baseEvent = getBaseEvent(x);

    const recurRules = {
        daysOfWeek: x.daysOfWeek,
        startTime: x.isAllDay ? null : x.timeStart,
        endTime: x.isAllDay ? null : x.timeEnd,
        startRecur: moment(x.startDate).format('YYYY-MM-DD'),
        endRecur: moment(x.endsOn).format('YYYY-MM-DD'),
    };

    const event = {
        ...recurRules,
        ...baseEvent,
    } as CalendarEvent;

    return event;
};

const getMediaSpacesInfo = (mediaSpacesId: string[], allMediaSpaces: MediaSpaceVm[]): CalendarResource[] => {
    const mediaSpaces = useCallback((): CalendarResource[] => {
        const ediaSpaces = allMediaSpaces.filter((x) => mediaSpacesId.indexOf(x.id) >= 0);
        return ediaSpaces.map((x) => {
            return { id: x.id, title: x.name };
        });
    }, [mediaSpacesId, allMediaSpaces]);

    return mediaSpaces();
};

export type PricesOrganismProps = {
    allMediaSpaces: MediaSpaceVm[];
    setResourceTimeline: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export default function PricesOrganism(props: PricesOrganismProps): JSX.Element {
    const { allMediaSpaces, setResourceTimeline } = props;

    const { t } = useTranslation();
    const history = useHistory();
    const filters = useSelector((state) => state.priceFilterReducer.price);

    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const [calendarEvents, setCalendarEvents] = useState(Array<CalendarEvent>());
    const userLocale = useSelector((state) => state.userReducer.user.lc);

    useMemo(() => {
        setIsDataLoaded(false);
        const getPrices = async () => {
            const selectedIds = filters.MediaSpacesIds;
            const response: AxiosResponse<Array<PricesVm>> = await get(selectedIds, PriceSearchStatus.Any);

            const events = response.data.map((x) => {
                if (x.frequency === Frequency.None) {
                    return getNonRecurring(x);
                } else {
                    return getReccuringObject(x);
                }
            });

            const resourcedEvents = Array<CalendarEvent>();
            filters.MediaSpacesIds.forEach((x) => {
                events.forEach((event) => {
                    const hasEvent = !!event.extendedProps.mediaSpaces.find((ms) => ms.id === x);
                    if (hasEvent || event.extendedProps.isForAllMediaSpaces) {
                        const clone = cloneDeep(event);
                        clone.resourceId = x;
                        clone.backgroundColor = colors[random(0, 4)];
                        if (clone.daysOfWeek?.length === 0) {
                            clone.daysOfWeek = undefined;
                        }
                        resourcedEvents.push(clone);
                    }
                });
            });

            setCalendarEvents(resourcedEvents);
            setIsDataLoaded(true);
        };

        setTimeout(() => getPrices(), 300);
    }, [filters]);

    const handleEventClick = (clickInfo: EventClickArg) => {
        history.push(generatePath(allRoutes.priceProfile.path, { id: clickInfo.event.id }));
    };

    const handleDateSelect = (selectInfo: DateSelectArg) => {
        redirectToCreate(
            new Date(selectInfo.startStr.replace(/T.*$/, '')),
            new Date(selectInfo.endStr.replace(/T.*$/, '')),
            `${selectInfo.startStr.slice(11, 13)}:00`,
            `${selectInfo.endStr.slice(11, 13)}:00`,
            selectInfo.allDay,
        );
    };

    const redirectToCreate = (
        startDate: Date,
        endDate: Date,
        timeStart: string,
        timeEnd: string,
        isAllDay: boolean,
    ) => {
        const data: PriceInputModel = {
            names: [],
            description: '',
            startDate: startDate,
            timeStart: timeStart,
            endDate: endDate,
            timeEnd: timeEnd,
            isAllDay: isAllDay,
            isDefault: false,
            cost: 0,
            frequency: Frequency.None,
            isForAllMediaSpaces: false,
            color: 0,
            mediaSpaceUids: [],
            priceCost: [],
        };
        useRedirectWithData(allRoutes.createPrice, data);
    };

    return (
        <>
            {!isDataLoaded && <LoadingIndicatorPopup isLoaded={isDataLoaded} />}
            <FlashCalendar
                locale={userLocale}
                initialDate={filters.startDate}
                initialEvents={calendarEvents}
                hasAllDateEvents={true}
                handleDateSelect={handleDateSelect}
                handleEventClick={handleEventClick}
                setResourceTimeline={setResourceTimeline}
                resourceAreaHeaderContent={t('price.resource-label')}
                resources={getMediaSpacesInfo(filters.MediaSpacesIds, allMediaSpaces)}
            />
        </>
    );
}
