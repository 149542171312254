import { FC, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import UserIcon from 'components/atoms/SideMenuIcon/Users';
import OrderIcon from 'components/atoms/SideMenuIcon/Orders';
import MenuButton from 'components/atoms/controls/MenuButton';
import PricesIcon from 'components/atoms/SideMenuIcon/Prices';
import DevicesIcon from 'components/atoms/SideMenuIcon/Devices';
import PlaylistsIcon from 'components/atoms/SideMenuIcon/Playlists';
import MediaSpacesIcon from 'components/atoms/SideMenuIcon/MediaSpaces';
import GoToOrdersChatLink from 'components/atoms/controls/GoToOrdersChatLink';
import NavigationLink from 'components/molecules/NavigationLink';

import useSelector from 'hooks/useAppSelector';

import allRoutes from 'router';
import { devicesAPI } from 'api/devices';
import { checkTariffs } from 'api/media-space';

import { MediaSpaceValidityStatus } from 'models/media-space.model';
import CurrencyIcon from 'components/atoms/SideMenuIcon/Currency';
import { UserRoles } from '../../../../shared/enums';

const ManagerSideMenuItems: FC = () => {
    const { t } = useTranslation();

    const user = useSelector((state) => state.userReducer.user);
    const auth = useSelector((state) => state.authReducer.auth);

    const [priceValidityStatus, setPriceValidityStatus] = useState(false);
    const [deviceValidityStatus, setDeviceValidityStatus] = useState(false);

    const checkPricesOnMediaspaces = useCallback(async () => {
        if (!user?.nameid || !auth.accessToken) return;

        const prices = await checkTariffs(auth.accessToken);
        const devices = await devicesAPI.checkDevices(auth.accessToken);

        setPriceValidityStatus(prices.data === MediaSpaceValidityStatus.Invalid);
        setDeviceValidityStatus(Boolean(devices.data));
    }, [user?.nameid, auth.accessToken]);

    useEffect(() => {
        setTimeout(() => checkPricesOnMediaspaces(), 300);
    }, [checkPricesOnMediaspaces]);

    return (
        <>
            <MenuButton
                icon={<OrderIcon />}
                label={t('navigation.orders')}
                path={generatePath(allRoutes.orders.path, { pageId: 1 })}
                data-id="orders"
                click={checkPricesOnMediaspaces}
            />
            <MenuButton
                icon={<PricesIcon />}
                label={t('navigation.price')}
                path={generatePath(allRoutes.price.path, { id: 1 })}
                data-id="prices"
                click={checkPricesOnMediaspaces}
            />
            <MenuButton
                icon={<MediaSpacesIcon />}
                label={t('navigation.media-spaces')}
                path={generatePath(allRoutes.mediaSpaces.path, { id: 1 })}
                data-id="media-spaces"
                isMediaspaces={priceValidityStatus}
                click={checkPricesOnMediaspaces}
            />
            <MenuButton
                icon={<PlaylistsIcon />}
                label={t('navigation.playlists')}
                path={generatePath(allRoutes.playlists.path, { id: 1 })}
                data-id="playlists"
                click={checkPricesOnMediaspaces}
            />
            <MenuButton
                icon={<DevicesIcon />}
                label={t('navigation.devices')}
                path={generatePath(allRoutes.devices.path, { id: 1 })}
                data-id="devices"
                isDevices={deviceValidityStatus}
                click={checkPricesOnMediaspaces}
            />
            {user.role === UserRoles.Admin && (
                <MenuButton
                    icon={<CurrencyIcon />}
                    label={t('navigation.currencies')}
                    path={generatePath(allRoutes.currency.path, { pageId: 1 })}
                    data-id="currencies"
                    click={checkPricesOnMediaspaces}
                />
            )}
            <MenuButton
                icon={<UserIcon />}
                label={t('navigation.users')}
                path={generatePath(allRoutes.users.path, { id: 1 })}
                data-id="users"
                click={checkPricesOnMediaspaces}
            />
            <Divider />
            <NavigationLink
                route={allRoutes.adminOrders}
                routerState={{ pageId: 1 }}
                className="order-menu-item"
                name="orders-history"
            >
                <Typography variant="subtitle1">{t('navigation.orders-history')}</Typography>
            </NavigationLink>
            <br />
            <GoToOrdersChatLink data-id="chat-link" />
        </>
    );
};

export default ManagerSideMenuItems;
