import { PaletteType, Theme, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import { variables } from './variables';

export default function GetTheme(paletteType: PaletteType): Theme {
    const defaultTheme = createMuiTheme({
        overrides: {
            MuiButton: {
                root: {
                    textTransform: 'none',
                    borderRadius: '0.688rem',
                    padding: '0.675rem 1.8rem',
                    '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                        padding: '0.538rem 1.538rem',
                    },
                    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                        padding: '0.338rem 1.338rem',
                    },
                    '&:hover:not($disabled)': {
                        color: variables.colours.primary,
                        background: 'transparent',
                    },
                },
                outlined: {
                    padding: '0.675rem 1.8rem',
                    '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                        padding: '0.538rem 1.538rem',
                    },
                    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                        padding: '0.338rem 1.338rem',
                    },
                },
                outlinedPrimary: {
                    color: variables.colours.light,
                    backgroundColor: variables.backgoroundColours.primary,
                },
                outlinedSecondary: {
                    backgroundColor: variables.backgoroundColours.light,
                    color: variables.colours.primary,
                },
            },
            MuiAppBar: {
                colorPrimary: {
                    backgroundColor: variables.backgoroundColours.dark,
                },
            },
            MuiDrawer: {
                root: {
                    backgroundColor: variables.backgoroundColours.dark,
                    '@media only screen and (min-width : 320px) and (max-width : 1024px)': {
                        backgroundColor: 'transparent',
                    },
                },
                paper: {
                    backgroundColor: variables.backgoroundColours.dark,
                    color: variables.colours.light,
                },
            },
            MuiInputLabel: {
                outlined: {
                    transform: 'translate(0.625rem,0.688rem) scale(1)',
                },
                root: {
                    '&.MuiFormLabel-colorSecondary': {
                        color: variables.colours.dark,
                    },
                },
                formControl: {
                    color: variables.colours.light,
                },
            },

            MuiInput: {
                root: {
                    color: variables.colours.light,
                },
                colorSecondary: {
                    color: variables.colours.dark,
                },
                formControl: {
                    color: variables.colours.light,
                },
                underline: {
                    borderBottomColor: variables.colours.primary,
                    '&:before': {
                        borderBottom: `3px solid ${variables.colours.primary}`,
                    },
                    '&:hover:not($disabled):not($focused):not($error):before': {
                        borderBottom: `2px solid ${variables.colours.light}`,
                    },
                    '&$disabled:before': {
                        borderBottom: `3px solid ${variables.colours.default}`,
                        borderBottomStyle: 'solid!important',
                    },
                },
            },
            MuiIconButton: {
                colorPrimary: {
                    color: variables.colours.light,
                },
                root: {
                    colorPrimary: variables.colours.light,
                },
            },
            MuiTypography: {
                root: {
                    color: variables.colours.dark,
                },
                colorPrimary: {
                    color: variables.colours.main,
                },
                colorSecondary: {
                    color: variables.colours.light,
                },
            },
        },
        typography: {
            fontFamily: 'Roboto',
            h1: {
                fontFamily: 'MagistralCTT',
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '2.5rem',
                lineHeight: '137.01%',
                letterSpacing: '0.05em',
                '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                    fontSize: '1.563rem',
                },
                '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                    fontSize: '1.25rem',
                },
            },
            h2: {
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '2.5rem',
                lineHeight: '137.01%',
                letterSpacing: '0.01em',
                '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                    fontSize: '1.563rem',
                },
                '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                    fontSize: '1rem',
                },
            },
            h3: {
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '1.25rem',
                lineHeight: '137.01%',
                letterSpacing: '0.01em',
                '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                    fontSize: '1.125rem',
                },
                '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                    fontSize: '1rem',
                },
            },
            h4: {
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '1.563rem',
                lineHeight: '137.01%',
                '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                    fontSize: '1.125rem',
                },
                '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                    fontSize: '1rem',
                },
            },
            h5: {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '1.25rem',
                lineHeight: '137.01%',
                textTransform: 'uppercase',
                '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                    fontSize: '1.125rem',
                },
                '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                    fontSize: '1rem',
                },
            }, //ui kit B1
            h6: {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '1.25rem',
                lineHeight: '137.01%',
                fontVariant: 'all-small-caps',
                '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                    fontSize: '1rem',
                },
                '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                    fontSize: '0.938rem',
                },
            }, //ui kit B2
            body1: {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '1.25rem',
                lineHeight: '137.01%',
                '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                    fontSize: '1rem',
                },
                '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                    fontSize: '0.938rem',
                },
            }, //ui kit t1
            body2: {
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '1.25rem',
                lineHeight: '137.01%',
                '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                    fontSize: '1rem',
                },
                '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                    fontSize: '0.813rem',
                },
            }, //ui kit t2
            subtitle1: {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '0.938rem',
                lineHeight: '1.125rem',
                '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                    fontSize: '0.875rem',
                },
                '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                    fontSize: '0.75rem',
                },
            }, //ui kit t3
            subtitle2: {
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '0.625rem',
                lineHeight: '137.01%',
                '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                    fontSize: '0.625rem',
                },
                '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                    fontSize: '0.625rem',
                },
            }, //ui kit t4
        },
        spacing: 8,
        palette: {
            type: paletteType,
            background: {
                default: variables.backgoroundColours.light,
            },
            primary: {
                main: variables.colours.primary,
            },
            secondary: {
                main: variables.colours.secondary,
            },
            error: {
                main: variables.colours.error,
            },
            warning: {
                main: variables.colours.warning,
            },
            info: {
                main: variables.colours.info,
            },
        },
    });

    return defaultTheme;
}
