import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    FullscreenControl,
    GeolocationControl,
    Map,
    Placemark,
    SearchControl,
    YMaps,
    YMapsApi,
    ZoomControl,
    Clusterer,
} from 'react-yandex-maps';

import { getConfig } from 'config';

import useSelector from 'hooks/useAppSelector';
import { useStore } from 'react-redux';

import { StyledMapContainer } from 'components/organisms/FlashMap/style';

import customPlacemark from 'assets/images/custom-placemark.svg';
import pickedPlacemark from 'assets/images/orange-placemark.svg';
import defaultClusterMark from 'assets/icons/cluster-picked-icon.svg';
import defaultMediaPlaceImage from 'assets/images/logo.png';

import { getLanguages } from '../../../api/language';

import { langCodesForYMaps } from 'shared/constants';
import { LocaleForYMap } from 'shared/types';
import { Languages } from '../../../shared/enums';

declare global {
    interface Window {
        hasSubscribed: boolean;
    }
}

type Baloon = Event & {
    detail?: string;
};

export interface MapPlacemark {
    id: string;
    isHighlighted: boolean;
    lat: number;
    long: number;
    address?: string;
    pictureUrl?: string;
    name: string;
}

export interface FlashMapProps {
    placemarks: Array<MapPlacemark>;
    disabled?: boolean;
    hasSearchControl: boolean;
    detectaddressOnChange?: boolean;
    showDetailedPlacemarkContent: boolean;
    onMapClick?: (coordinates: Array<number>) => void;
    onAddressChanged?: (address: string) => void;
    isMediaSpaceSubForm?: boolean;
    setChecked?: Dispatch<SetStateAction<string[]>>;
    checked?: Array<string>;
    addresses?: string[];
    setAddresses?: (addresses: string[]) => void;
}

type MapRef = unknown | null;

const FlashMap: FC<FlashMapProps> = ({
    detectaddressOnChange,
    disabled,
    showDetailedPlacemarkContent,
    placemarks,
    onMapClick,
    onAddressChanged,
    hasSearchControl,
    isMediaSpaceSubForm,
    setChecked,
    checked,
    addresses,
    setAddresses,
}) => {
    const { t } = useTranslation();
    const defaultZoom = 9;

    const setYMapLocale = (lc: string) => {
        let yMapLocale: LocaleForYMap = 'en_US';
        if (lc === Languages.Russian) yMapLocale = 'ru_RU';

        setLocale(yMapLocale);
    };

    const [locale, setLocale] = useState<LocaleForYMap>();
    const [isConfigLoaded, setConfigLoaded] = useState(false);
    const [isClickEventSet, setIsClickEvent] = useState(false);
    const [apiKey, setApiKey] = useState<string | null>(null);
    const [mapCenter, setMapCenter] = useState<Array<number>>([0, 0]);
    const [address, setAddress] = useState('');
    const [myMap, setMyMap] = useState<YMapsApi | null>(null);
    // eslint-disable-next-line
    const [myMapRef, setMapRef] = useState<MapRef>(null);
    const [zoom, setZoom] = useState(defaultZoom);
    // eslint-disable-next-line
    const currentRef = useRef<{ api: YMapsApi | null; ref: any }>();
    currentRef.current = { api: myMap, ref: myMapRef };

    const maximumDefaultZoom = 14;

    const store = useStore();
    const storeMediaSpaces = useSelector((store) => store.formReducer.mediaSpaces);
    const userLocale = useSelector((state) => state.userReducer.user.lc);

    useEffect(() => {
        if (!isConfigLoaded) {
            const loadConfig = async () => {
                const data = await getConfig();
                setConfigLoaded(true);
                setApiKey(data.mapsApiKey);
            };

            loadConfig();
        }
    }, [apiKey]);

    const setMapCenterByLocation = async (map: YMapsApi, provider: 'browser' | 'yandex') => {
        const geolocation = map.geolocation;
        try {
            const result = await geolocation.get({
                provider: provider,
                mapStateAutoApply: true,
            });
            setMapCenter(result.geoObjects.position);
        } catch (ex) {}
    };

    useEffect(() => {
        placemarks?.forEach((element) => {
            const addButton = document.getElementById(`addMediaSpace-${element.id}`);
            const removeButton = document.getElementById(`removeMediaSpace-${element.id}`);
            removeButton?.setAttribute('disabled', 'true');
            addButton?.removeAttribute('disabled');
        });
        checked?.forEach((element) => {
            const addButton = document.getElementById(`addMediaSpace-${element}`);
            const removeButton = document.getElementById(`removeMediaSpace-${element}`);
            removeButton?.removeAttribute('disabled');
            addButton?.setAttribute('disabled', 'true');
            console.log(addButton);
        });
    }, [checked]);

    useEffect(() => {
        if (currentRef.current?.ref) {
            setTimeout(setCenterForGeoObjects, 0);
        }
    }, [placemarks, myMapRef]);

    async function getLocalizedAddresses(lat: number, long: number) {
        const result = await currentRef.current?.api?.geocode([lat, long]);
        if (result) {
            const firstGeoObject = result.geoObjects.get(0);
            const firstAddr = firstGeoObject.getAddressLine();
            const resultLang = await getLanguages();
            const _addresses = addresses?.slice() ?? [];

            for (const i in resultLang.data)
                if (
                    langCodesForYMaps[resultLang.data[i].code] === locale ||
                    (!langCodesForYMaps[resultLang.data[i].code] && locale === 'en_US')
                )
                    _addresses[i] = firstAddr;

            if (setAddresses) setAddresses(_addresses);
            setIsClickEvent(false);

            const nextIndex = _addresses.indexOf('');
            if (nextIndex !== -1) setLocale(langCodesForYMaps[resultLang.data[nextIndex].code]);
        }
    }

    useEffect(() => {
        if (
            placemarks[0] &&
            addresses &&
            addresses.find((x) => {
                return x !== '';
            }) === undefined
        )
            getLocalizedAddresses(placemarks[0].lat, placemarks[0].long);
    }, [addresses]);

    useEffect(() => {
        if (
            placemarks[0] &&
            addresses &&
            addresses.find((x) => {
                return x !== '';
            }) !== undefined
        )
            getLocalizedAddresses(placemarks[0].lat, placemarks[0].long);
    }, [myMap]);

    useEffect(() => {
        if (detectaddressOnChange && placemarks.length === 1) {
            const placemark = placemarks[0];
            const getAddressByPlaceMark = async () => {
                await getAddress([placemark.lat, placemark.long]);
            };
            getAddressByPlaceMark();
        }
    }, [placemarks]);

    useEffect(() => {
        if (myMap?.geolocation) {
            const setCenter = async () => {
                await setMapCenterByLocation(myMap, 'yandex');
                await setMapCenterByLocation(myMap, 'browser');
                setCenterForGeoObjects();
            };

            setCenter();
        }
    }, [myMap]);

    const closeCurrentBalloon = () => {
        const close = document.querySelector('ymaps[class$="-balloon__close-button"]') as HTMLElement;
        if (close != null) {
            close.click();
        }
    };

    const addMediaSpace = (e: Baloon) => {
        const c = store.getState().formReducer.mediaSpaces;
        if (setChecked && !c.includes(e.detail)) {
            setChecked([...c, e.detail]);
        }
        closeCurrentBalloon();
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const removeMediaSpace = (e: any) => {
        const c = store.getState().formReducer.mediaSpaces;
        if (setChecked) {
            setChecked(c.filter((x: string) => x != e.detail));
        }
        closeCurrentBalloon();
    };

    useEffect(() => {
        if (!window.hasSubscribed) {
            document.addEventListener('addMediaSpace', addMediaSpace);
            document.addEventListener('removeMediaSpace', removeMediaSpace);
            window.hasSubscribed = true;
        }

        return () => {
            document.removeEventListener('addMediaSpace', addMediaSpace);
            document.removeEventListener('removeMediaSpace', removeMediaSpace);
            window.hasSubscribed = false;
        };
    }, []);

    useEffect(() => {
        if (userLocale) {
            setYMapLocale(userLocale);
        } else {
            const localStorageLocale = localStorage.getItem('locale');
            if (localStorageLocale) {
                setYMapLocale(localStorageLocale);
            }
        }
    }, [userLocale]);

    const setCenterForGeoObjects = () => {
        const ref = currentRef?.current?.ref;
        const map = currentRef?.current?.api;
        if (ref && ref.geoObjects && map) {
            const geoObjectsBound = ref.geoObjects.getBounds();
            if (geoObjectsBound != null && ref.container) {
                const result = map.util.bounds.getCenterAndZoom(geoObjectsBound, ref.container.getSize());
                setMapCenter(result.center);
                setZoom(result.zoom > maximumDefaultZoom ? maximumDefaultZoom : result.zoom - 1);
            }
        }
    };

    const getAddress = async (coordinates: number[]): Promise<void> => {
        // eslint-disable-next-line
        const result = await currentRef.current?.api?.geocode(coordinates);
        if (result) {
            const firstGeoObject = result.geoObjects.get(0);
            const newAddress = firstGeoObject.getAddressLine();
            setAddress(newAddress);

            if (address !== newAddress) {
                onAddressChanged && onAddressChanged(newAddress);
            }
        }
    };

    // eslint-disable-next-line
    const handleClick = async (event: any) => {
        event.preventDefault();

        const coordinates = event.get('coords');
        await getAddress(coordinates);
        if (onMapClick && coordinates.length) {
            onMapClick(coordinates);
        }

        if (setAddresses) {
            const resultLang = await getLanguages();
            const _addresses = new Array<string>(resultLang.data.length);
            const addressesResult = _addresses.fill('');
            setAddresses(addressesResult);
        }
    };

    // eslint-disable-next-line
    const setRef = (instance: any) => {
        setMapRef(instance);
        if (!disabled && !isClickEventSet) {
            if (instance) {
                instance?.events.add('click', handleClick);
                setIsClickEvent(true);
            }
        }
    };

    const getClusterIconContent = (): string => {
        return myMap?.templateLayoutFactory.createClass(
            `<span class="clusterIconContent">{{ properties.geoObjects.length }}</span>`,
        );
    };

    const getPlaceMarkContent = (placemark: MapPlacemark, isHighlighted: boolean): string => {
        if (showDetailedPlacemarkContent) {
            if (isMediaSpaceSubForm) {
                return `
                <div class="balloon__wrapper">
                    <span class="balloon-title">${placemark.name}</span>
                    <div class="balloon-text">${placemark.address || ''}</div>
                    <div class="balloon-image ${
                        placemark.pictureUrl ? '' : 'balloon-image__default'
                    }" style="background: url('${placemark.pictureUrl ?? defaultMediaPlaceImage}')"></div>
                    <div id="balloon-btns" class="balloon-btns">
                        <button
                            ${isHighlighted ? 'disabled' : ''}
                            id="addMediaSpace-${placemark.id}"
                            class="balloon-btns-add"
                            onclick="document.dispatchEvent(new CustomEvent('addMediaSpace', { detail: '${
                                placemark.id
                            }' })); event.preventDefault();">
                            ${t('maps.buttons.add')}
                        </button>
                        <button
                            ${!isHighlighted ? 'disabled' : ''}
                            class="balloon-btns-remove"
                            onclick="document.dispatchEvent(new CustomEvent('removeMediaSpace', { detail: '${
                                placemark.id
                            }' })); event.preventDefault();"
                            id="removeMediaSpace-${placemark.id}">
                            ${t('maps.buttons.del')}
                        </button>
                        </div>
                </div>`;
            } else {
                return `
                <div class="balloon__wrapper">
                    <span class="balloon-title">${placemark.name}</span>
                    <div class="balloon-text">${placemark.address || ''}</div>
                    <div class="balloon-image  ${
                        placemark.pictureUrl ? '' : 'balloon-image__default'
                    }"" style="background: url('${placemark.pictureUrl ?? defaultMediaPlaceImage}')"></div>
                </div>`;
            }
        } else {
            return placemark.name;
        }
    };

    if (!apiKey || !mapCenter.length) return null;

    return (
        <StyledMapContainer>
            <YMaps
                enterprise
                key={locale}
                query={{
                    apikey: apiKey,
                    load: 'util.bounds',
                    lang: locale,
                }}
            >
                <Map
                    state={{ center: mapCenter, zoom: zoom }}
                    defaultState={{ center: mapCenter, zoom: zoom }}
                    instanceRef={setRef}
                    width="100%"
                    height="100%"
                    onLoad={(ympasInstance: YMapsApi) => {
                        setMyMap(ympasInstance);
                    }}
                    modules={[
                        'geocode',
                        'geolocation',
                        'geoObject.addon.balloon',
                        'geoObject.addon.hint',
                        'templateLayoutFactory',
                        'layout.ImageWithContent',
                    ]}
                    name="map"
                >
                    <Clusterer
                        options={{
                            groupByCoordinates: false,
                            clusterIcons: [
                                {
                                    href: defaultClusterMark,
                                    size: [40, 40],
                                    offset: [-20, -50],
                                },
                                {
                                    href: defaultClusterMark,
                                    size: [70, 70],
                                    offset: [-20, -30],
                                },
                            ],
                            clusterIconContentLayout: getClusterIconContent(),
                            clusterNumbers: [10],
                        }}
                    >
                        {placemarks.map((placemark) => (
                            <Placemark
                                key={placemark.id}
                                options={{
                                    iconLayout: 'default#image',
                                    iconImageHref: storeMediaSpaces.includes(placemark.id)
                                        ? pickedPlacemark
                                        : customPlacemark,
                                    groupByCoordinates: true,
                                    iconImageSize: [50, 50],
                                    iconImageOffset: [-18, -50],
                                }}
                                geometry={[placemark.lat, placemark.long]}
                                properties={{
                                    hintContent: placemark.name,
                                    balloonContent: getPlaceMarkContent(
                                        placemark,
                                        storeMediaSpaces.includes(placemark.id),
                                    ),
                                }}
                                address={address}
                                name="placemark"
                            />
                        ))}
                    </Clusterer>
                    <ZoomControl />
                    <FullscreenControl />
                    <GeolocationControl />
                    {hasSearchControl && <SearchControl />}
                </Map>
            </YMaps>
        </StyledMapContainer>
    );
};

export default FlashMap;
