import { FC, useCallback } from 'react';

import { MenuItem, ListItemText } from '@material-ui/core';

import FlashCheckbox from 'components/atoms/FlashCheckbox';

interface FilterOptionProps {
    onClick?: () => void;
    checked?: boolean;
    text: string;
}

const FilterOption: FC<FilterOptionProps> = ({ onClick, checked, text }) => {
    const handleClick = useCallback(() => onClick?.(), [onClick]);

    return (
        <MenuItem onClick={handleClick} className="filter-option">
            <FlashCheckbox checked={checked} />
            <ListItemText primary={text} />
        </MenuItem>
    );
};

export default FilterOption;
