import { PriceSearchStatus } from 'models/price.model';

export const REMOVE_FILTER = `REMOVE_FILTER`;
export const UPDATE_PRICE_FILTER = `UPDATE_PRICE_FILTER`;

export interface Price {
    filterString: string;
    MediaSpacesIds: Array<string>;
    isAllSelected: boolean;
    startDate: Date;
    endDate: Date | null;
    tableCalendar: boolean;
    status?: PriceSearchStatus;
}

export interface FilterState {
    price: Price;
}

interface UpdateFilterAction {
    type: typeof UPDATE_PRICE_FILTER;
    data: Price;
}
interface RemoveFilterAction {
    type: typeof REMOVE_FILTER;
}

export type FilterActionTypes = UpdateFilterAction | RemoveFilterAction | UpdateFilterAction; // | other actions
