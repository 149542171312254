import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { MenuItem } from '@material-ui/core';

import BaseFilterSelect from 'components/atoms/gridFilters/BaseFilterSelect';
import MenuItemChip from 'components/atoms/gridFilters/MenuItemChip';

import { variables } from 'theme/variables';

export interface UserStateSelect {
    onChange: (selectedRole: UserStatesEnum) => void;
    value: UserStatesEnum;
}

export enum UserStatesEnum {
    all = 0,
    blocked = 1,
    unlocked = 2,
}

const UserStateSelect: FC<UserStateSelect> = ({ onChange, value }) => {
    const { t } = useTranslation();

    return (
        <BaseFilterSelect
            defaultValue={UserStatesEnum.unlocked}
            onSelectionChanged={(value: UserStatesEnum) => {
                onChange(value);
            }}
            value={value}
            name="user-select"
        >
            <MenuItem value={UserStatesEnum.all} data-id="all-users">
                {t(`user-states.${UserStatesEnum[UserStatesEnum.all]}`)}
            </MenuItem>
            <MenuItem value={UserStatesEnum.unlocked} data-id="active">
                <MenuItemChip color={variables.stateColours.active} />
                {t(`user-states.${UserStatesEnum[UserStatesEnum.unlocked]}`)}
            </MenuItem>
            <MenuItem value={UserStatesEnum.blocked} data-id="inactive">
                <MenuItemChip color={variables.stateColours.inactive} />
                {t(`user-states.${UserStatesEnum[UserStatesEnum.blocked]}`)}
            </MenuItem>
        </BaseFilterSelect>
    );
};

export default UserStateSelect;
