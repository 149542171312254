import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const RoleAdmin = styled.div(({}) => ({
    h6: {
        display: 'inline-flex',
        justifyContent: 'center',
        color: variables.colours.light,
    },
    paddingTop: '0.625rem',
    width: '11.5rem',
    height: '2.438rem',
    borderRadius: '4px',
    textAlign: 'center',
    background: variables.rolesColours.admin,
    '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
        width: '8.75rem',
        height: '2rem',
        paddingTop: '0.438rem',
    },
}));

export const RoleUser = styled.div(({}) => ({
    h6: {
        display: 'inline-flex',
        justifyContent: 'center',
        color: variables.colours.light,
    },
    paddingTop: '0.625rem',
    width: '11.688rem',
    height: '2.438rem',
    borderRadius: '4px',
    textAlign: 'center',
    background: variables.rolesColours.user,
    '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
        width: '8.75rem',
        height: '2rem',
        paddingTop: '0.438rem',
    },
}));

export const RoleOperator = styled.div(({}) => ({
    h6: {
        display: 'inline-flex',
        justifyContent: 'center',
        color: variables.colours.light,
    },
    paddingTop: '0.625rem',
    width: '11.688rem',
    height: '2.438rem',
    borderRadius: '4px',
    textAlign: 'center',
    background: variables.rolesColours.operator,
    '@media only screen and (min-width: 320px) and (max-width: 1366px)': {
        width: '8.75rem',
        height: '2rem',
        paddingTop: '0.438rem',
    },
}));
