import * as React from 'react';
import { DrawerProps } from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { StyledDrawer } from './styles';
import Toolbar from '../../../../components/atoms/Toolbar';
import { updateMenu } from '../../../../store/menu/actions';
import { store } from '../../../../store';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/rootReducer';

const { dispatch } = store;

export default function SideDrawer(props: DrawerProps): JSX.Element {
    const menu = useSelector((state: RootState) => state.menuReducer.menu);

    const [open, setOpen] = React.useState(!menu.isCollapsed);
    const { children, variant } = props;
    const handleDrawerOpenClose = () => {
        const newValue = !open;
        dispatch(
            updateMenu({
                isCollapsed: !newValue,
            }),
        );
        setOpen(newValue);
    };

    return (
        <StyledDrawer className={open ? 'opened' : 'closed'} variant={variant}>
            <Toolbar>
                <IconButton color="inherit" onClick={handleDrawerOpenClose} name="arrow-button">
                    {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </Toolbar>
            <Divider />
            {children}
        </StyledDrawer>
    );
}
