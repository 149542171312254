import { PagingVmBaseResponse } from './paging/paging.model';
import { DeviceVm } from './device.model';

export type MediaSpaceShortVm = {
    id: string;
    name: string;
    isActive: boolean;
};

export const DefaultMediaSpaceModel = {
    names: [],
    devices: [],
    name: '',
    address: '',
    latitude: undefined,
    longitude: undefined,
    comment: '',
    isActive: true,
    timeZoneId: '',
} as MediaSpaceModel;

export type MediaSpaceNamesModel = {
    name: string;
    comment: string;
    address: string;
    languageId: string;
    languageCode: string;
};

export type MediaSpaceModel = {
    names: MediaSpaceNamesModel[];
    name: string;
    address: string;
    latitude: number | undefined;
    longitude: number | undefined;
    comment: string;
    tariffsValidityStatus?: number;
    tariffsValidityReason?: number;
    devices: Array<DeviceVm>;
    isActive: boolean;
    pictureUrl?: string | null;
    timeZoneId: string | null;
};

export type MediaSpacePage = {
    page: number;
    pageSize: number;
    property?: string;
    direction?: number | null;
    filterString?: string | null;
    filterRule: MediaSpaceFilterRule;
};

export type MediaSpaceVm = MediaSpaceShortVm & {
    names: MediaSpaceNamesModel[];
    address: string;
    latitude: number;
    longitude: number;
    comment: string;
    tariffsValidityStatus?: number;
    tariffsValidityReason?: number;
    devices: DeviceVm[];
    pictureUrl?: string;
    timeZoneId: string | null;
};

export type MediaSpacePageVm = PagingVmBaseResponse<MediaSpaceVm>;

export enum MediaSpaceValidityStatus {
    Invalid,
    Valid,
}

export enum MediaSpaceFilterRule {
    All,
    OnlyInvalid,
}

export enum MediaSpaceDevicesFilterRule {
    NoFilter,
    AllDevicesValid,
}

export enum MediaSpaceTariffsValidationRule {
    NoValidate = 0,
    Validate = 1,
}

export type MediaSpaceImageModel = {
    url: string;
    file?: File;
};

export type MediaSpaceMapVm = {
    id: string;
    name: string;
    address: string;
    names: MediaSpaceNamesModel[];
    latitude: number;
    longitude: number;
    pictureUrl?: string;
};

export type MediaSpaceSelectOption = {
    id: string;
    name: string;
    address: string;
};
