import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { MenuItem } from '@material-ui/core';

import MenuItemChip from 'components/atoms/gridFilters/MenuItemChip';
import BaseFilterSelect from 'components/atoms/gridFilters/BaseFilterSelect';

import { variables } from 'theme/variables';

export interface DeviceStatusSelect {
    onChange: (selectedStatus: DeviceStatusEnum) => void;
    value: DeviceStatusEnum | null;
}

export enum DeviceStatusEnum {
    all = -1,
    offline = 0,
    online = 1,
}

export enum DevicesFilterRuleEnum {
    all,
    onlyInvalid,
}

const DeviceStatusSelect: FC<DeviceStatusSelect> = ({ onChange, value }) => {
    const { t } = useTranslation();

    return (
        <BaseFilterSelect
            value={value}
            defaultValue={value ? value : DeviceStatusEnum.all}
            onSelectionChanged={(value: DeviceStatusEnum) => {
                onChange(value);
            }}
            name="devices-select"
        >
            <MenuItem value={DeviceStatusEnum.all} data-id="all-devices">
                {t(`device-status.${DeviceStatusEnum[DeviceStatusEnum.all]}`)}
            </MenuItem>
            <MenuItem value={DeviceStatusEnum.online} data-id="online">
                <MenuItemChip color={variables.deviceStatus.online} />
                {t(`device-status.${DeviceStatusEnum[DeviceStatusEnum.online]}`)}
            </MenuItem>
            <MenuItem value={DeviceStatusEnum.offline} data-id="offline">
                <MenuItemChip color={variables.deviceStatus.offline} />
                {t(`device-status.${DeviceStatusEnum[DeviceStatusEnum.offline]}`)}
            </MenuItem>
        </BaseFilterSelect>
    );
};

export default DeviceStatusSelect;
