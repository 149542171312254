import { FC, useEffect, useState, useMemo } from 'react';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Link as MuiLink } from '@material-ui/core';

import AuthTemplate from 'components/templates/Auth';
import Login from 'components/organisms/forms/Login';
import AuthProvider, { AuthProviderProps } from 'components/atoms/AuthProvider';

import { getConfig } from 'config';
import allRoutes from 'router';
import i18n from 'i18n/config';

import useWindowDimensions from 'hooks/WindowDimensions';

import { LoginProviders } from 'shared/enums';

import { StyledLogin } from 'components/templates/Login/style';

import { ReactComponent as GoogleIcon } from 'assets/icons/Google.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/FacebookLogin.svg';
import { ReactComponent as VKIcon } from 'assets/icons/Vkontakte.svg';
// import FlashCheckbox from 'components/atoms/FlashCheckbox';
// import { CheckboxStyle } from 'components/atoms/FlashCheckbox/style';
// import { ReactComponent as CustomCheckbox } from '../../../assets/icons/Checkbox.svg';

const LoginTemplate: FC = () => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const [config, setConfig] = useState<Config>({
        backendUrl: '/',
        frontendUrl: '/',
        landingUrl: '/',
        id: '',
        mapsApiKey: '',
        sentryDsn: '',
        sentryEnvironment: '',
        sentryTracesSampleRate: 1.0,
        isSentryEnabled: false,
        defaultLocale: '',
        defaultCurrency: '',
        contactsAddressRu: '',
        contactsAddressEn: '',
        contactsPhone: '',
        contactsEmail: '',
        contactsLongitude: 0,
        contactsLatitude: 0,
        contactsCompanyRu: '',
        contactsCompanyEn: '',
        supportTeamPhone: '',
        whatsAppLink: '',
        googleTagManagerId: '',
        facebookIsAvailable: false,
        fictitiousMode: false,
        detectBrowserLanguage: false,
        adminStartDateRangeInHours: 0,
        userStartDateRangeInDays: 0,
        userEndDateRangeInMonths: 0,
        limitMonthsForStartDate: 0,
        limitMonthsForEndDate: 0,
    });
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const redirectUrl = `${config.frontendUrl}${allRoutes.externalLoginCallback.path}`;

    const authProviders = useMemo<AuthProviderProps[]>(
        () => [
            {
                name: 'google',
                text: i18n.t('login-form.continue-with-google'),
                color: '#000',
                backgroundColor: '#fff',
                icon: GoogleIcon,
                url: `${config.backendUrl}/api/session/external/${LoginProviders.Google}?returnUrl=${redirectUrl}`,
                id: 'SEO-login-from-google-in-signin-page',
                enable: true,
            },
            {
                name: 'facebook',
                text: i18n.t('login-form.continue-with-facebook'),
                color: '#fff',
                backgroundColor: '#4267B2',
                icon: FacebookIcon,
                url: `${config.backendUrl}/api/session/external/${LoginProviders.Facebook}?returnUrl=${redirectUrl}`,
                enable: config.facebookIsAvailable,
            },
            {
                name: 'vk',
                text: i18n.t('login-form.continue-with-vkontakte'),
                color: '#fff',
                backgroundColor: '#4267B2',
                icon: VKIcon,
                url: `${config.backendUrl}/api/session/external/${LoginProviders.Vkontakte}?returnUrl=${redirectUrl}`,
                id: 'SEO-login-from-vkontakte-in-signin-page',
                enable: !config.facebookIsAvailable,
            },
        ],
        [config.backendUrl],
    );

    useEffect(() => {
        if (!isDataLoaded) {
            const loadConfig = async () => {
                const data = await getConfig();
                setIsDataLoaded(true);
                setConfig(data);
            };

            loadConfig();
        }
    }, [config]);

    return (
        <StyledLogin>
            <AuthTemplate>
                <Box>
                    <Typography variant="h1" color="secondary">
                        {t('login-form.header')}
                    </Typography>
                </Box>
                <Box display="flex" marginTop="0.625rem" className="login-subtitle">
                    <Typography variant="h3" color="secondary">
                        {t('login-form.sub-header')}
                    </Typography>
                    <MuiLink
                        component={Link}
                        to={allRoutes.signup.path}
                        className="create-account"
                        data-id="create-account"
                        id="SEO-registration-in-signin-page"
                    >
                        <Typography variant="h3">{t('login-form.create-new-account')}</Typography>
                    </MuiLink>
                </Box>
                <Login />
                <Box
                    display="flex"
                    flexDirection={width > 767 ? 'row' : 'column'}
                    justifyContent="space-between"
                    height="auto"
                    className="social-handler"
                >
                    {authProviders.map((props) => props.enable && <AuthProvider key={props.name} {...props} />)}
                </Box>
            </AuthTemplate>
        </StyledLogin>
    );
};

export default LoginTemplate;
