import { styled } from '@material-ui/core/styles';
import { TextField as MaterialTextField } from '@material-ui/core';
import { variables } from '../../../../theme/variables';

export const FlashTextarea = styled(MaterialTextField)((props: { borderColor?: string; backgroundColor?: string }) => ({
    borderRadius: '4px',
    border: props.borderColor ? `2px solid ${props.borderColor}` : `2px solid ${variables.colours.default}`,
    background: props.backgroundColor ? props.backgroundColor : variables.colours.main,
    marginTop: '1.875rem',
    marginBottom: '1.875rem',
    '& textarea': {
        color: `${variables.colours.dark}!important`,
        paddingLeft: '0.625rem',
    },
    '& label': {
        color: `${variables.colours.light}!important`,
        transform: 'unset',
        paddingTop: '0.313rem',
        paddingLeft: '0.625rem',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.25rem',
        lineHeight: '137.01%',
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            fontSize: '1rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            fontSize: '0.938rem',
        },
    },
    '& .MuiFocused': {
        color: variables.colours.dark,
    },
    '& label + .MuiInput-formControl': {
        background: variables.colours.light,
        marginTop: '2.25rem',
    },
    '::before': {
        borderBottom: 'none',
    },
    '.MuiInput-underline:hover:not(.Mui-disabled)::before': {
        borderBottom: 'none',
    },
    '& label + .MuiInput-underline:after': {
        borderBottom: 'none',
    },
}));
