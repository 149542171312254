import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridOptionsProp, GridRowData } from '@material-ui/data-grid';
import { GridHolder, StyledDataGridDiv } from './style';
import LoadingIndicatorPopup from '../../atoms/LoadingIndicatorPopup';
import LoadingIndicator from '../../atoms/LoadingIndicator';
import useWindowDimensions from '../../../hooks/WindowDimensions';

export type GridProps<T> = GridOptionsProp & {
    rows: Array<T>;
    noDataMessage: JSX.Element;
    columns: GridColDef[];
    isDataLoaded: boolean;
    isLoadingIndicator?: boolean;
};

export default function FlashDataGrid<T>(props: GridProps<T>): JSX.Element {
    const {
        rows,
        columns,
        filterMode,
        onRowClick,
        disableColumnSelector,
        sortingMode,
        sortModel,
        onSortModelChange,
        noDataMessage,
        filterModel,
        isDataLoaded,
        isLoadingIndicator,
    } = props;

    const [render, setRender] = useState(false);

    useEffect(() => {
        setRender(false);
        setTimeout(() => {
            setRender(true);
        });
    }, [rows]);

    const { width } = useWindowDimensions();

    const renderGrid = (): JSX.Element => {
        if (rows.length > 0) {
            return (
                <StyledDataGridDiv maxWidth={width}>
                    {render && (
                        <GridHolder rowsCount={rows.length} maxWidth={width}>
                            <DataGrid
                                rows={rows as GridRowData[]}
                                columns={columns}
                                disableColumnSelector={disableColumnSelector}
                                onRowClick={onRowClick}
                                filterMode={filterMode}
                                sortingMode={sortingMode}
                                sortModel={sortModel}
                                onSortModelChange={onSortModelChange}
                                rowsPerPageOptions={[]}
                                hideFooterPagination={true}
                                filterModel={filterModel}
                                disableColumnMenu={true}
                            />
                        </GridHolder>
                    )}
                </StyledDataGridDiv>
            );
        } else if (!isLoadingIndicator) {
            return <LoadingIndicator isLoaded={!!isLoadingIndicator} />;
        } else return noDataMessage;
    };

    return (
        <>
            {renderGrid()} <LoadingIndicatorPopup isLoaded={isDataLoaded} />
        </>
    );
}
