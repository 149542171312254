import {
    DELETE_MEDIA_SPACES_FORM,
    FormActionTypes,
    FormState,
    NOT_UPLOAD_VIDEO,
    SET_ALL_MEDIA_SPACES,
    SET_MEDIA_SPACES_FORM,
    SET_VIDEO_ID,
    UPLOAD_VIDEO,
} from './types';

const InitialState: FormState = {
    mediaSpaces: [],
    allMediaSpaces: false,
    videoId: '',
    isUploadVideo: false,
};

const formReducer = (state = InitialState, action: FormActionTypes): FormState => {
    switch (action.type) {
        case UPLOAD_VIDEO: {
            return {
                ...state,
                isUploadVideo: true,
            };
        }
        case NOT_UPLOAD_VIDEO: {
            return {
                ...state,
                isUploadVideo: false,
            };
        }
        case SET_MEDIA_SPACES_FORM: {
            return {
                ...state,
                mediaSpaces: action.data,
            };
        }
        case DELETE_MEDIA_SPACES_FORM: {
            return {
                ...state,
                mediaSpaces: [],
            };
        }
        case SET_ALL_MEDIA_SPACES: {
            return {
                ...state,
                allMediaSpaces: action.data,
            };
        }
        case SET_VIDEO_ID: {
            return {
                ...state,
                videoId: action.data,
            };
        }
        default:
            return state;
    }
};

export default formReducer;
