import styled from 'styled-components';

import { IconButton, Typography } from '@material-ui/core';

import { variables } from 'theme/variables';

export const StyledDatePickerNavbar = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});

export const NavbarButton = styled(IconButton)({
    padding: 5,
});

export const NavbarButtonIcon = styled.svg({
    color: variables.colours.primary,
});

export const NavbarMonth = styled(Typography)({});
