import { variables } from '../../../theme/variables';
import styled from 'styled-components';
import Button from '../../../components/atoms/controls/Button';

export const StyledButton = styled(Button)(({}) => ({
    padding: '0 0.5rem',
    height: 'auto',
    width: 'auto',
    '&.notification': {
        padding: 0,
    },
    '&.chat-icon': {
        img: {
            '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                height: '18px',
            },
            '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                height: '18px',
            },
        },
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
        '&.chat-button': {
            marginTop: '1.813rem',
            marginBottom: '1.813rem',
            marginLeft: '1.553rem',
            padding: 0,
            p: {
                fontWeight: 'normal',
                color: variables.colours.main,
                ':first-letter': {
                    textTransform: 'uppercase',
                },
            },
            img: {
                height: '24px',
            },
        },
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        '&.chat-button': {
            marginTop: '1.813rem',
            marginBottom: '1.813rem',
            marginLeft: '1.553rem',
            padding: 0,
            p: {
                fontSize: '0.625rem',
                fontWeight: 'normal',
                color: variables.colours.main,
                ':first-letter': {
                    textTransform: 'uppercase',
                },
            },
            img: {
                height: '24px',
            },
        },
        '&.chat-go-back': {
            span: {
                justifyContent: 'start',
                marginBottom: '0.313rem',
            },
        },
    },
}));
