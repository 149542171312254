import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cost, CostSumStyle, Total } from './style';
import { CostCurrencySum } from '../../../../models/order';

export default function OrderCostSummary(props: { costs: number; costCurrencySum?: CostCurrencySum[] }): JSX.Element {
    const { costs, costCurrencySum = [] } = props;
    const { t } = useTranslation();

    return (
        <>
            {costCurrencySum.length > 0 ? (
                <CostSumStyle>
                    <Total>
                        <Typography variant="body2">{t('order.total')}</Typography>
                    </Total>
                    <div>
                        {costCurrencySum.map((x) => (
                            <Cost key={x.currencyCode}>
                                <Typography variant="body2">{`${x.cost} (${x.currencyCode})`}</Typography>
                            </Cost>
                        ))}
                    </div>
                </CostSumStyle>
            ) : (
                <CostSumStyle>
                    <Total>
                        <Typography variant="body2">{t('order.total')}</Typography>
                    </Total>
                    <Cost>
                        <Typography variant="body2">
                            {costs} {t('order.currency')}
                        </Typography>
                    </Cost>
                </CostSumStyle>
            )}
        </>
    );
}
