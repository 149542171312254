import { FC, useEffect, useState, ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';

import { Select, ListItemText, MenuItem, InputLabel, MenuProps } from '@material-ui/core';

import { getMediaSpaces } from 'api/media-space';
import { MediaSpaceVm } from 'models/media-space.model';

import { StyledSelect } from 'components/atoms/MediaSpacesSelect/style';
import useSelector from '../../../hooks/useAppSelector';

export interface MediaSpacesSelectProps {
    value: string;
    onChange: (value: string) => void;
}

const menuProps: Partial<MenuProps> = {
    style: {
        maxHeight: '30.313rem',
    },
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    getContentAnchorEl: null,
};

const MediaSpacesSelect: FC<MediaSpacesSelectProps> = ({ value, onChange }) => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.userReducer.user);

    const [mediaSpaces, setMediaSpaces] = useState<MediaSpaceVm[]>([]);
    const [isLoadingTriggered, setIsLoadingTriggered] = useState(false);

    useEffect(() => {
        setIsLoadingTriggered(false);
    }, [user.lc]);

    useEffect(() => {
        async function fetchData() {
            const response = await getMediaSpaces();
            setMediaSpaces(response.data);
        }

        if (!isLoadingTriggered) {
            setIsLoadingTriggered(true);
            setTimeout(() => fetchData(), 300);
        }
    }, [isLoadingTriggered]);

    const handleChangeMediaspace = (event: ChangeEvent<{ value: unknown }>) => {
        const selectedMediaSpaceId = event.target.value as string;
        onChange(selectedMediaSpaceId);
    };

    return (
        <StyledSelect>
            <InputLabel>{t('device-profile.select-media-space')}</InputLabel>
            <Select name="mediaspace-select" MenuProps={menuProps} onChange={handleChangeMediaspace} value={value}>
                {mediaSpaces.map((val) => (
                    <MenuItem key={val.id} value={val.id} data-id="mediaspace-item">
                        <ListItemText primary={val.name} />
                    </MenuItem>
                ))}
            </Select>
        </StyledSelect>
    );
};

export default MediaSpacesSelect;
