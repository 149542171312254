import { FC, useEffect, useRef, useState } from 'react';

import Button from 'components/atoms/controls/Button';
import { ReactComponent as EditAvatar } from 'assets/icons/EditAvatar.svg';

import { ProfileImageModel } from 'models/user-model';

import { AvatarImage, AvatarContainer, AvatarInput, AvatarBadge } from 'components/organisms/PhotoUploader/style';

export interface PhotoUploadProps {
    handleBase64image: (value: ProfileImageModel) => void;
    userImgSrc: string | undefined;
    abbreviation: string;
    disabled?: boolean;
}

const PhotoUploader: FC<PhotoUploadProps> = ({ handleBase64image, userImgSrc, abbreviation, disabled }) => {
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

    const inputFile = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        async function fetchData() {
            setImageUrl(userImgSrc);
        }

        fetchData();
    }, [userImgSrc]);

    const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const img = e.target.files[0];
            setImageUrl(URL.createObjectURL(img));
            const profilePhotoModel: ProfileImageModel = {
                file: e.target.files[0],
                url: URL.createObjectURL(img),
            };
            setImageUrl(URL.createObjectURL(img));
            handleBase64image(profilePhotoModel);
        }
    };

    const clickOnRef = () => {
        if (inputFile.current instanceof HTMLInputElement) {
            inputFile.current.click();
        }
    };

    return (
        <div>
            <AvatarBadge
                $disabled={disabled}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                badgeContent={
                    !disabled && (
                        <Button onClick={clickOnRef} name="uploader">
                            <EditAvatar />
                        </Button>
                    )
                }
            >
                <AvatarImage>
                    <AvatarContainer src={imageUrl}>{abbreviation[0]}</AvatarContainer>
                    <AvatarInput disabled={disabled} onChange={onImageChange} ref={inputFile} id="upload-button" />
                </AvatarImage>
            </AvatarBadge>
        </div>
    );
};

export default PhotoUploader;
