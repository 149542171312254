import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, Typography } from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';

import GoBackButton from 'components/atoms/controls/GoBackButton';
import { StyledNavigationLink } from 'components/atoms/controls/GoBackButton/style';
import GoToOrderChatButton from 'components/atoms/controls/GoToOrderChatButton';

import useWindowDimensions from 'hooks/WindowDimensions';
import allRoutes from 'router';

import { OrderVm } from 'models/order';
import { MomentFormats, OrderStatusType, UserRoles } from 'shared/enums';
import { RootState } from 'store/rootReducer';
import { getDateWithLocalTz } from 'hooks/getDateWithLocalTz';

export type OrderHeaderProps = {
    order: OrderVm | undefined;
    isStatusLoading: boolean;
};

export default function OrderHeader(props: OrderHeaderProps): JSX.Element {
    const { order } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.userReducer.user);
    const locations = useLocation().state;
    const { width } = useWindowDimensions();
    async function getOrderUrl() {
        const redirectUrl = order?.paymentLink;
        window.open(redirectUrl);
    }

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let createDate = new Date();
    let updateDate = new Date();
    if (order !== undefined) {
        createDate = new Date(order.createdDate);
        if (order.updatedDate !== undefined) updateDate = new Date(order.updatedDate);
        else updateDate = new Date(createDate);
    }

    const copyValueAndOpenUrl = () => {
        navigator.clipboard.writeText(order?.externalOrderId || '');
        getOrderUrl();
    };

    return (
        <>
            {locations && (locations as string)[0] != '/' ? (
                <StyledNavigationLink
                    startIcon={<ArrowBackIos color="disabled" />}
                    onClick={() => history.push({ pathname: allRoutes.createOrder.path, state: locations })}
                    name="go-back"
                    {...props}
                />
            ) : (
                <GoBackButton />
            )}
            <Box
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                marginTop="0.625rem"
                display="flex"
                flexWrap={width > 767 ? '' : 'wrap'}
            >
                <div>
                    <Typography variant="h4" className="order-number">
                        {t('order-form.order-title')} {order?.number}
                    </Typography>
                    {order?.paymentLink && (user.role === UserRoles.Admin || user.role === UserRoles.Operator) && (
                        <div
                            onClick={() => {
                                copyValueAndOpenUrl();
                            }}
                            className="order-link"
                            data-id="external-order-id"
                        >
                            <Typography variant="body1" className="order-user">
                                {t('order-form.order-external-number')}: {order?.externalOrderId}
                            </Typography>
                        </div>
                    )}
                    {order?.id &&
                        order?.userRole === UserRoles.User &&
                        (user.role === UserRoles.Admin || user.role === UserRoles.Operator) && (
                            <div
                                onClick={() => {
                                    copyValueAndOpenUrl();
                                }}
                                className="order-link"
                                data-id="order-id"
                            >
                                <Typography variant="body1" className="order-user">
                                    {t('order-form.order-number')}: {order?.id}
                                </Typography>
                            </div>
                        )}
                </div>
                {width > 767 ? (
                    <Box width="45%" display="flex" flexDirection="column" alignItems="center">
                        {user.role !== UserRoles.User && (
                            <Box width="100%" textAlign="end">
                                <Typography variant="subtitle1" className="order-user">
                                    {t('order.created-date')}:
                                    {`${moment(getDateWithLocalTz(createDate)).format(
                                        MomentFormats.Long,
                                    )} (${timeZone})`}
                                    <br />
                                    {t('order.updated-date')}:
                                    {`${moment(getDateWithLocalTz(updateDate)).format(
                                        MomentFormats.Long,
                                    )} (${timeZone})`}
                                </Typography>
                            </Box>
                        )}
                        <Box
                            width="100%"
                            justifyContent={width > 767 ? 'flex-end' : 'space-between'}
                            alignItems="center"
                            display="flex"
                        >
                            {((user.role === UserRoles.User && order?.status !== OrderStatusType.Created) ||
                                user.role !== UserRoles.User) &&
                                order !== undefined && (
                                    <GoToOrderChatButton
                                        orderId={order.id}
                                        orderNumber={order.number}
                                        orderStatus={order.status}
                                        chatId={order.currentChatUid}
                                        className="chat-button"
                                        name="chat-button"
                                    />
                                )}
                        </Box>
                    </Box>
                ) : (
                    <Box
                        width={width > 767 ? '45%' : '100%'}
                        justifyContent={width > 767 ? 'flex-end' : 'space-between'}
                        alignItems="center"
                        display="flex"
                    >
                        {user.role !== UserRoles.User && (
                            <Typography variant="subtitle1" className="order-user">
                                {t('order.created-date')}:
                                {`${moment.utc(order?.createdDate).format(MomentFormats.Short)} (${timeZone})`}
                            </Typography>
                        )}
                        {((user.role === UserRoles.User && order?.status !== OrderStatusType.Created) ||
                            user.role !== UserRoles.User) &&
                            order !== undefined && (
                                <GoToOrderChatButton
                                    orderId={order.id}
                                    orderNumber={order.number}
                                    orderStatus={order.status}
                                    chatId={order.currentChatUid}
                                    className="chat-button"
                                    name="chat-button"
                                />
                            )}
                    </Box>
                )}
            </Box>
        </>
    );
}
