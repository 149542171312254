import styled from 'styled-components';

import { Typography } from '@material-ui/core';

import { variables } from 'theme/variables';

export const StyledProgress = styled.div({
    marginTop: -70,
    position: 'relative',
    zIndex: 1,

    '.MuiLinearProgress-colorPrimary': {
        backgroundColor: variables.backgoroundColours.border,
        opacity: '50%',
    },

    '.attention': {
        zIndex: 1,
        width: 18,
        marginTop: -4,
        marginLeft: '0.625rem',
    },
});

export const Label = styled.div({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '0.625rem',
});

export const LabelText = styled(Typography).attrs({
    variant: 'body1',
})({
    color: variables.colours.main,
});

export const AttentionText = styled(Typography).attrs({
    variant: 'body1',
})({
    color: variables.colours.light,
});
