import styled from 'styled-components';

import { ListItemText, Typography } from '@material-ui/core';

import { variables } from 'theme/variables';

export const ScheduleTable = styled.table(({}) => ({
    margin: '1.25rem 0 1.25rem 0',
    borderRadius: '4px',
    border: `2px solid ${variables.colours.default}`,
    boxShadow: '0px 0px 8px 3px rgba(0, 0, 0, 0.05)',
    width: '100%',
    minWidth: '16rem',
    padding: '1.25rem 1.75rem',
    tr: {
        textAlign: 'left',
    },
    td: {
        padding: '0 1.75rem 1.25rem 1.75rem',
    },
    th: {
        padding: '1.25rem 1.75rem',
    },

    '@media only screen and (min-width: 375px) and (max-width: 767px)': {
        margin: '1.25rem 0 1.25rem 0',
        td: {
            padding: '0 1.25rem 0.625rem 1.25rem',
        },
        th: {
            padding: '0.625rem 1.25rem',
        },
    },
    '@media only screen and (min-width: 320px) and (max-width: 374px)': {
        margin: '1.25rem 0 0.313rem 0',
        td: {
            padding: '0 0.625rem 0.313rem 0.625rem',
        },
        th: {
            padding: '0.313rem 0.625rem',
        },
    },
}));

export const TableHolder = styled.div((props: { maxWidth: number }) => ({
    maxWidth: props.maxWidth,
    'overflow-x': 'auto',
    'overflow-y': 'hidden',
    '::-webkit-scrollbar': {
        height: '5px',
    },
    '::-webkit-scrollbar-thumb': {
        background: variables.colours.main,
        borderRadius: '29px',
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: variables.backgoroundColours.primary,
    },
    '::-webkit-scrollbar-track': {
        borderRadius: '0px',
    },
}));

export const ExternalHolder = styled.div((props: { maxWidth: number }) => ({
    'overflow-x': 'hidden',
    width: '100%',
    maxWidth: props.maxWidth,
    marginBottom: '1.25rem',
}));

export const SubheaderText = styled(Typography).attrs({
    variant: 'h6',
})({
    '&.size': {
        textAlign: 'end',
    },
});

export const Subheader = styled.tr.attrs({
    component: 'div',
})({
    [SubheaderText]: {
        color: variables.colours.default,
    },
});

export const FileName = styled(ListItemText)({
    span: {
        fontWeight: 400,
        overflowWrap: 'break-word',
        color: variables.colours.default,
    },
});

export const FileDate = styled(ListItemText)({
    span: {
        fontWeight: 400,
        color: variables.colours.default,
    },
});

export const FileSize = styled(ListItemText)({
    marginRight: 5,
    span: {
        textAlign: 'end',
    },
});

export const DataFooter = styled.div({
    display: 'flex',
    marginTop: '0.625rem',
    marginBottom: '0.625rem',

    '& > *': {
        margin: 0,

        '&:first-child': {
            marginRight: '1.25rem',
            border: `1.5px solid ${variables.colours.default}`,
        },

        '&:last-child': {
            border: `1.5px solid ${variables.colours.default}`,
        },
    },

    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        flexDirection: 'column',

        '.spacer': {
            display: 'none',
            border: 'none',
        },

        '& > *': {
            marginBottom: '0.5rem',

            '&:first-child': {
                marginRight: 0,
            },
        },
    },
});
