import React from 'react';
import moment from 'moment';
import { MomentFormats } from '../../../shared/enums';
import { Typography } from '@material-ui/core';

export type DateRendererProps = {
    date?: string | Date;
    format: MomentFormats;
    className?: string;
};

export default function DateRenderer(props: DateRendererProps): JSX.Element {
    const { date, format, className } = props;

    return (
        <Typography variant="body1" className={className ? className : 'notification-date'}>
            {moment(date).format(format)}
        </Typography>
    );
}
