import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { rootReducer, RootState } from 'store/rootReducer';

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['authReducer', 'menuReducer'],
};

const pReducer = persistReducer(persistConfig, rootReducer);

// eslint-disable-next-line
const composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// eslint-disable-next-line
export const store = createStore<RootState & any, any, any, any>(
    pReducer,
    composeEnhancer(applyMiddleware(thunkMiddleware)),
);
export const persistor = persistStore(store);

export type AppDispatch = typeof store.disaptch;
