import styled from 'styled-components';
import { FormControlTextInputStyle } from '../FiltersHolder/style';

export const MediaSpaceOrderFormControlTextInputStyle = styled(FormControlTextInputStyle)(({}) => ({
    '.MuiFormControl-root': {
        marginLeft: 10,
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            marginLeft: 0,
        },
    },
}));
