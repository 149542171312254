import styled from 'styled-components';

import { Box, Typography, Button } from '@material-ui/core';

import { variables } from '../../../../theme/variables';

export const UserPoint = styled.div(() => ({
    background: variables.rolesColours.user,
    marginTop: '0.25rem',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
}));

export const OperatorPoint = styled.div(() => ({
    background: variables.rolesColours.operator,
    marginTop: '0.25rem',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
}));

export const AdminPoint = styled.div(() => ({
    background: variables.rolesColours.admin,
    marginLeft: '0.5rem',
    marginTop: '0.25rem',
    width: '10px',
    height: '10px',
    borderRadius: '10px',
}));

export const RoleButton = styled(Button)(() => ({
    padding: 0,
}));

export const RoleBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
});

export const UserRoleText = styled(Typography).attrs({
    variant: 'subtitle2',
})({ marginLeft: '0.375rem', marginTop: '0.181rem' });

export const RoleName = styled(Typography).attrs({
    variant: 'subtitle1',
})({});
