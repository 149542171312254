import React, { useCallback, useEffect, Dispatch, SetStateAction } from 'react';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { Avatar, Box, Button, Typography } from '@material-ui/core';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';

import CustomCheckbox from 'components/atoms/Checkbox';
import CheckboxMenu from 'components/atoms/CheckboxMenu';
import GridCellLink from 'components/atoms/GridCellLink';
import RolesChip from 'components/atoms/controls/RolesChip';

import { RootState } from 'store/rootReducer';

import allRoutes from 'router';

import { UserRoles } from 'shared/enums';

import { CheckboxStyle } from 'components/pages/Users/style';

import { ReactComponent as DeleteButton } from 'assets/icons/DeleteButton.svg';
import { variables } from 'theme/variables';
import { getPhotoSrc } from 'api/photo';
import FlashGridHeader from '../../atoms/FlashGridHeader';
import { SortField } from '../../../models/paging/paging.model';
import FlashTooltip from 'components/atoms/FlashTooltip';

export type UserRow = {
    id: string;
    isLockedOut: boolean;
    userName: string;
    role: string;
    email: string;
    phoneNumber: string;
};

type UsersGridProps = {
    users: UserRow[];
    setOpen: Dispatch<SetStateAction<boolean>>;
    setOpenEnable: Dispatch<SetStateAction<boolean>>;
    setOpenDisable: Dispatch<SetStateAction<boolean>>;
    checked: string[];
    setChecked: Dispatch<SetStateAction<string[]>>;
    setDeleteUserId: Dispatch<SetStateAction<string>>;
};

export default function UsersColumnsGrid(props: UsersGridProps): GridColDef[] {
    const { users, setOpen, setOpenEnable, setOpenDisable, checked, setChecked, setDeleteUserId } = props;
    const { t } = useTranslation();
    const history = useHistory();

    const currentUser = useSelector((state: RootState) => state.userReducer.user);

    function renderRoles() {
        const actions = [
            {
                action: () => setOpenEnable(true),
                label: t('users-page.bulk-lockout'),
            },
            {
                action: () => setOpenDisable(true),
                label: t('users-page.bulk-unlock'),
            },
        ];
        if (currentUser.role === UserRoles.Admin) {
            actions.unshift({
                action: async () => {
                    if (checked.length >= 1) {
                        handleClickOpen();
                    } else {
                        toast.error(t('user-form.messages.error-bulk'));
                    }
                },
                label: t('users-page.delete-marked'),
            });
        }
        return <CheckboxMenu actions={actions} setChecked={setChecked} rows={users} />;
    }

    function isDeletedColumns() {
        if (currentUser.role === UserRoles.Admin) {
            return {
                field: 'isDeleted',
                headerName: t('media-space.actions'),
                renderCell: (params: GridCellParams) => renderDeleteButton(params),
                sortable: false,
                disableClickEventBubbling: true,
                align: 'center',
                headerAlign: 'center',
                width: 100,
            } as GridColDef;
        } else {
            return {} as GridColDef;
        }
    }

    function renderDeleteButton(params: GridCellParams) {
        return (
            <>
                <Button
                    size="small"
                    onClick={async () => {
                        setDeleteUserId(params.row.id);
                        deleteUser(params);
                    }}
                    name="delete-button"
                    className={params.row.isLockedOut ? 'lockout-enabled' : '123'}
                >
                    <DeleteButton />
                </Button>
            </>
        );
    }

    async function deleteUser(params: GridCellParams) {
        if (params.row.id !== currentUser.nameid) {
            handleClickOpen();
        } else {
            toast.error(t('user-form.messages.error-delete-admin'));
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (getColumns() && currentUser) {
            if (currentUser.role === UserRoles.Admin && getColumns().length < 6) {
                renderRoles();
                getColumns().push(isDeletedColumns());
            }
        }
    }, [currentUser]);

    const getColumns = useCallback(() => {
        return [
            {
                field: SortField.UserId,
                width: 60,
                sortable: false,
                disableClickEventBubbling: true,
                align: 'left',
                headerAlign: 'left',
                headerName: 'Menu',
                renderHeader: renderRoles,
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <CheckboxStyle>
                            <CustomCheckbox
                                params={params}
                                disabled={currentUser.nameid === params.value}
                                checked={checked}
                                setChecked={setChecked}
                            />
                        </CheckboxStyle>
                    );
                },
            },
            {
                field: SortField.UserName,
                flex: 1,
                minWidth: 200,
                align: 'left',
                headerAlign: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'user.name'} withPadding={true} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.userProfile.path, { id: params.row.id }),
                                state: history.location.pathname,
                            }}
                        >
                            <Box marginRight="0.813rem">
                                <Avatar
                                    style={{ width: '47px', height: '47px' }}
                                    alt={params.row.userName}
                                    src={getPhotoSrc(params.row.photoUrl)}
                                >
                                    <Typography variant="body1" className="user-name">
                                        {params.row.userName[0]}
                                    </Typography>
                                </Avatar>
                            </Box>
                            <FlashTooltip
                                disableHighlighting
                                title={
                                    <Typography
                                        variant="body1"
                                        style={{ color: variables.colours.light, lineHeight: '137.01%' }}
                                    >
                                        {params.row.userName}
                                    </Typography>
                                }
                                placement="top-start"
                                arrow
                            >
                                <Typography variant="body1">{params.row.userName}</Typography>
                            </FlashTooltip>
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.UserRole,
                flex: 0.6,
                align: 'center',
                headerAlign: 'center',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'user.role'} withPadding={true} paddingLeft={35} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.userProfile.path, { id: params.row.id }),
                                state: history.location.pathname,
                            }}
                        >
                            <RolesChip
                                disabled={params.row.isLockedOut}
                                role={params.row.role.toLocaleString() || ''}
                            />
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.UserEmail,
                flex: 1,
                align: 'left',
                headerAlign: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'user.email'} withPadding={true} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.userProfile.path, { id: params.row.id }),
                                state: history.location.pathname,
                            }}
                        >
                            {params.row.isLockedOut ? (
                                <div className="lockout-enabled">{params.row.email}</div>
                            ) : (
                                <>{params.row.email}</>
                            )}
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.UserPhoneNumber,
                flex: 0.6,
                align: 'left',
                headerAlign: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'user.phone-number'} withPadding={true} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.userProfile.path, { id: params.row.id }),
                                state: history.location.pathname,
                            }}
                        >
                            {params.row.isLockedOut ? (
                                <div className="lockout-enabled">{params.row.phoneNumber}</div>
                            ) : (
                                <>{params.row.phoneNumber}</>
                            )}
                        </GridCellLink>
                    );
                },
            },
            isDeletedColumns(),
        ] as GridColDef[];
    }, [users, checked]);

    return getColumns();
}
