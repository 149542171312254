import { PriceSearchStatus } from 'models/price.model';
import { REMOVE_FILTER, UPDATE_PRICE_FILTER, FilterActionTypes, FilterState } from 'store/priceFilter/types';

const InitialState: FilterState = {
    price: {
        filterString: '',
        MediaSpacesIds: [],
        startDate: new Date(),
        endDate: null,
        isAllSelected: true,
        tableCalendar: false,
        status: PriceSearchStatus.ActiveOnly,
    },
};

const userReducer = (state = InitialState, action: FilterActionTypes): FilterState => {
    switch (action.type) {
        case UPDATE_PRICE_FILTER: {
            return {
                ...state,
                price: {
                    ...state.price,
                    filterString: action.data.filterString,
                    MediaSpacesIds: action.data.MediaSpacesIds,
                    startDate: action.data.startDate,
                    endDate: action.data.endDate,
                    isAllSelected: action.data.isAllSelected,
                    tableCalendar: action.data.tableCalendar,
                    status: action.data.status,
                },
            };
        }
        case REMOVE_FILTER: {
            return {
                ...state,
                price: {
                    ...state.price,
                    ...InitialState.price,
                },
            };
        }
        default:
            return state;
    }
};

export default userReducer;
