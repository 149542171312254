import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import translationEn from './en/translation.json';
import translationRu from './ru/translation.json';
import { initReactI18next } from 'react-i18next';
import { Languages } from '../shared/enums';

import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';

import 'moment/locale/ru';
import moment from 'moment';
import { registerLocale } from 'react-datepicker';

const resources = {
    en: {
        translation: translationEn,
    },
    ru: {
        translation: translationRu,
    },
};

export const localStorageLanguageKey = 'locale';

const language = (localStorage.getItem('locale') || 'en') as Languages;

i18n.use(detector).use(initReactI18next).init({
    resources: resources,
    fallbackLng: language, // use en if detected lng is not available
});

const configure = (language: Languages) => {
    let locale: Locale = en;

    switch (language) {
        case Languages.Russian: {
            locale = ru;
            break;
        }
        default: {
            locale = en;
        }
    }
    registerLocale(language, locale);
    moment.locale(language);
};

configure(language);

export const setDefaultLocale = async (): Promise<void> => {
    if (!localStorage.getItem(localStorageLanguageKey) && window?.ENV?.config) {
        localStorage.setItem(localStorageLanguageKey, window.ENV.config.defaultLocale);
        await i18n.changeLanguage(window.ENV.config.defaultLocale);
    }
};

export default i18n;
