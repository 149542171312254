import * as React from 'react';
import { GridColDef, GridSortModel } from '@material-ui/data-grid';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import allRoutes from '../../../router';
import PagedDataGrid from '../../organisms/PagedDataGrid';
import NoDataMessage from '../../atoms/NoDataMessage';
import { StyledPlaylisGrid } from './style';
import FilteredTemplate from '../../templates/FilteredTemplate';
import PlaylistsFilter, {
    DefaultPlaylistsFilterData,
    PlaylistsFilterData,
} from '../../atoms/gridFilters/PlaylistsFilter';
import { playlistsAPI } from '../../../api/playlists';
import { PlaylistGridVm, PlaylistsPagedRequest } from '../../../models/playlist.model';
import { PageSize } from '../../../shared/constants';
import FlashDialog from '../../atoms/dialog/FlashDialog';
import YesNoButtons from '../../atoms/dialog/YesNoButtons';
import TextContent from '../../atoms/dialog/TextContent';
import PlaylistsColumnsGrid from './ColumnsGrid';
import useSelector from '../../../hooks/useAppSelector';
import { getSortingParams } from '../../../utils/sortingHelper';
import { SortDirections, SortField, SortModel } from '../../../models/paging/paging.model';

export default function PlaylistsTemplate(): JSX.Element {
    const defaultSort: SortModel = {
        property: SortField.CreatedDate,
        direction: SortDirections.Desc,
    };

    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const user = useSelector((state) => state.userReducer.user);

    const [isModalToShow, setModalToShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState<string[]>([]);
    const [filter, setFilter] = useState<PlaylistsFilterData>(DefaultPlaylistsFilterData);
    const [playlists, setPlaylists] = useState<Array<PlaylistGridVm>>([]);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState<SortModel>(defaultSort);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);

    const history = useHistory();

    const columns: GridColDef[] = PlaylistsColumnsGrid({
        playlists,
        setModalToShow,
        checked,
        setChecked,
    });

    const deleteSelectedPlaylists = async () => {
        await playlistsAPI.deletePlaylists(checked);
        await loadData();
    };

    const closeDeleteModal = () => {
        setModalToShow(false);
    };

    const getReq = () => {
        const req: PlaylistsPagedRequest = {
            page: page,
            property: sort?.property,
            direction: sort?.direction,
            filterString: filter.filterString,
            pageSize: PageSize,
            startDate: filter.startDate,
            endDate: filter.endDate,
            mediaSpaceIds: filter.selectedMediaSpaces,
        };
        return req;
    };

    const loadData = useCallback(async () => {
        const req = getReq();
        await getPlaylists(req);
    }, [
        page,
        sort.property,
        sort.direction,
        PageSize,
        filter.filterString,
        filter.startDate,
        filter.endDate,
        filter.selectedMediaSpaces,
    ]);

    const getPlaylists = async (req: PlaylistsPagedRequest) => {
        const res = await playlistsAPI.getPaged(req);
        const totalPageNumber = Math.ceil(res.total / res.pageSize);

        setModalToShow(false);
        setPlaylists(res.data);
        setTotalPage(totalPageNumber);
        setTotalItems(res.total);
    };

    useEffect(() => {
        setLoading(true);
        (async () => {
            await loadData();
            setLoading(false);
        })();
    }, [page, user.lc, sort, filter]);

    useEffect(() => {
        if (id) {
            const page = Number(id);
            setPage(page);
        }
    }, [id]);

    const handleSorting = (model: GridSortModel) => {
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
            const sort = getSortingParams(model, defaultSort.property, defaultSort.direction);
            setSort(sort);
        }
    };

    const handleFilterChange = (filter: PlaylistsFilterData) => {
        history.push(history.location.pathname.replace(id, '1'));
        setFilter(filter);
    };

    return (
        <>
            <StyledPlaylisGrid>
                <FilteredTemplate
                    header={t('playlists.header')}
                    createItemPageRoute={allRoutes.createPlaylist}
                    filter={<PlaylistsFilter onFilterChange={handleFilterChange} />}
                    content={
                        <PagedDataGrid
                            sortingMode="server"
                            sortModel={sortModel}
                            onSortModelChange={(model: GridSortModel) => {
                                handleSorting(model);
                            }}
                            isDataLoaded={!loading}
                            isLoadingIndicator={!loading}
                            totalPages={totalPage}
                            totalItems={totalItems}
                            defaultPage={page}
                            noDataMessage={<NoDataMessage />}
                            columns={columns}
                            disableColumnSelector={true}
                            rows={playlists}
                            filterMode="server"
                            customPagination={true}
                        />
                    }
                />
            </StyledPlaylisGrid>
            <FlashDialog
                open={isModalToShow}
                isOpen={isModalToShow}
                onClose={closeDeleteModal}
                controls={<YesNoButtons onConfirm={deleteSelectedPlaylists} onReject={closeDeleteModal} />}
                content={<TextContent text={t('playlists.delete-bulk-confirmation-content')} />}
                title={t('playlists.delete-bulk-dialog-title')}
            />
        </>
    );
}
