import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { MenuItem } from '@material-ui/core';

import MenuItemChip from 'components/atoms/gridFilters/MenuItemChip';
import BaseFilterSelect from 'components/atoms/gridFilters/BaseFilterSelect';

import { UserRoles } from 'shared/enums';

import { variables } from 'theme/variables';

export interface RoleSelectProps {
    onRoleChanged: (selectedRole: string) => void;
    value: string | undefined;
}

export enum FieldValue {
    allRoles = 'allRoles',
}

const RolesSelect: FC<RoleSelectProps> = ({ onRoleChanged, value }) => {
    const { t } = useTranslation();

    return (
        <BaseFilterSelect
            value={value}
            defaultValue={FieldValue.allRoles}
            onSelectionChanged={(value: string) => {
                onRoleChanged(value);
            }}
            name="roles-select"
        >
            <MenuItem value={FieldValue.allRoles} data-id="all-roles">
                {t('roles.all-roles')}
            </MenuItem>
            <MenuItem value={UserRoles.User}>
                <MenuItemChip color={variables.rolesColours.user} data-id="user" />
                {t('roles.user')}
            </MenuItem>
            <MenuItem value={UserRoles.Admin}>
                <MenuItemChip color={variables.rolesColours.admin} data-id="admin" />
                {t('roles.admin')}
            </MenuItem>
            <MenuItem value={UserRoles.Operator}>
                <MenuItemChip color={variables.rolesColours.operator} data-id="operator" />
                {t('roles.operator')}
            </MenuItem>
        </BaseFilterSelect>
    );
};

export default RolesSelect;
