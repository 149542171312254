import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { variables } from '../../../theme/variables';

export const StyledMapContainer = styled(Box)({
    width: '100%',
    minWidth: '280px',
    height: '100%',
    boxShadow: '0 0 7px rgba(0,0,0,0.5)',
    padding: 0,
    borderRadius: '5px',
    '& ymaps': {
        borderRadius: '5px',
        div: {
            paddingTop: '0.625rem',
        },

        '@media only screen and (max-width: 475px)': {
            '[class$="balloon_layout_panel"]': {
                right: '20px',
                bottom: '5px',
                left: '20px',
            },
            '.ymaps-2-1-79-balloon__close+.ymaps-2-1-79-balloon__content': {
                marginRight: 0,
                padding: '10px 12px',
            },
        },

        '.balloon__wrapper': {
            padding: '20px 0 20px 20px',
            width: '95%',

            '@media only screen and (max-width: 475px)': {
                padding: '0',
                width: '100%',
            },
        },

        '.balloon-title': {
            fontFamily: 'MagistralCTT',
            fontWeight: 700,
            fontSize: '25px',
            lineHeight: '17px',
            color: variables.colours.primary,

            '@media only screen and (max-width: 475px)': {
                paddingRight: '30px',
            },
        },
        '.balloon-text': {
            marginBottom: '21px',
            fontFamily: 'Roboto',
            fontSize: '20px',
            lineHeight: '137.01%',
            fontWeight: 400,
        },
        '.balloon-more': {
            display: 'flex',
            justifyContent: 'flex-end',
            textDecoration: 'none',
            textTransform: 'uppercase',
            fontSize: '0.938rem',
            color: variables.colours.primary,
            ':hover': {
                textDecoration: 'underline',
            },
        },

        '.balloon-image': {
            marginBottom: '10px',
            minHeight: '160px',
            height: '100%',
            width: '99%',
            backgroundSize: 'cover !important',
            backgroundPosition: 'center !important',
            backgroundRepeat: 'no-repeat !important',
        },

        '.balloon-image__default': {
            backgroundColor: `${variables.colours.secondary} !important`,
            backgroundSize: 'contain !important',
        },

        '.balloon-btns': {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
            width: '98%',
            button: {
                minWidth: '120px',
                padding: '0.538rem 1.538rem',
                fontSize: '0.875rem',
                boxSizing: 'border-box',
                fontFamily: 'Roboto',
                fontWeight: 500,
                lineHeight: '1.75',
                borderRadius: '0.688rem',
                textTransform: 'uppercase',
                transition:
                    'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                cursor: 'pointer',

                '&:hover': {
                    color: `${variables.colours.primary}`,
                    background: 'transparent',
                },
            },
        },

        '.balloon-btns-add': {
            color: `${variables.colours.light}`,
            border: '1px solid rgba(255, 96, 0, 0.5)',
            backgroundColor: `${variables.colours.primary}`,
            ':disabled': {
                backgroundColor: 'white',
                color: '#DDE0F2 !important',
                border: '1px solid #DDE0F2',
            },
        },

        '.balloon-btns-remove': {
            fontWeight: 400,
            textTransform: 'uppercase',
            color: `${variables.colours.primary}`,
            border: `1px solid ${variables.colours.primary}`,
            backgroundColor: `${variables.colours.light}`,
            ':disabled': {
                backgroundColor: 'white',
                color: '#DDE0F2 !important',
                border: '1px solid #DDE0F2',
            },
        },
    },

    // TODO: we should consider not using class$=""
    '& ymaps[class$="-balloon__content"]': {
        ymaps: {
            height: 'auto !important',
            maxWidth: '385px !important',
            width: '100% !important',
            overflow: 'hidden !important',
            minWidth: '290px',

            '@media only screen and (max-width: 475px)': {
                minWidth: '260px',
            },
        },
    },

    '& ymaps[class$="-default-cluster"]': {
        fontSize: '14px',
        position: 'absolute',
        minWidth: '60px',
        minHeight: '60px',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        'ymaps > span': {
            display: 'block',
            marginBottom: '5px',
        },
    },

    '& ymaps[class$="-balloon__layout"], ymaps[class$="-i-custom-scroll"], ymaps[class$="-balloon_layout_normal"], ymaps[class$="-balloon"]': {
        borderRadius: '15px',
    },

    '.clusterIcon': {
        borderRadius: '50%',
        background: `${variables.colours.secondary}`,
    },

    '.clusterIconContent': {
        color: `${variables.colours.light}`,
    },

    '& ymaps[class$="-image-with-content"]': {
        backgroundSize: 'cover !important',
    },
});
