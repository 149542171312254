import { useTranslation } from 'react-i18next';

import DateRenderer from 'components/atoms/DateRenderer';
import NotificationLink from 'components/atoms/controls/notifications/NotificationLink';

import { MomentFormats } from 'shared/enums';

import { ReactComponent as Chat } from 'assets/icons/Chat.svg';

export type NotificationItemProps = {
    onClick: () => void;
    chatId: string;
    orderNumber: number;
    lastMessageDate: string;
};

export default function ChatMessageNotificationLink(props: NotificationItemProps): JSX.Element {
    const { onClick, chatId, orderNumber, lastMessageDate } = props;

    const { t } = useTranslation();

    return (
        <NotificationLink
            icon={<Chat className="chat-icon" />}
            onClick={onClick}
            chatId={chatId}
            header={t(`order.notification-lable.unread-messege`)}
            body={
                <>
                    <div>
                        {t(`order.notification-lable.order`)}
                        {orderNumber}
                    </div>
                    <DateRenderer format={MomentFormats.LongLocalDate} date={lastMessageDate} />
                </>
            }
        />
    );
}
