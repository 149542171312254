import * as React from 'react';
import CancelButton from 'components/atoms/controls/CancelButton';
import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultMediaSpaceModel, MediaSpaceImageModel, MediaSpaceModel } from '../../../../models/media-space.model';
import FormControlTextInput from '../../../molecules/forms/FormControlTextInput';
import FlashTextarea from '../../../atoms/controls/FlashTextarea';
import { MediaSpaceFormStyle } from './style';
import { Autocomplete } from '@material-ui/lab';
import ResponsiveFlexBox from '../../../atoms/ResponsiveFlexBox';
import { Box, Typography } from '@material-ui/core';
import { generatePath, useHistory } from 'react-router';
import { IBaseFormRef } from '../../../../models/interface/i-form-data';
import SubmitButton from '../../../atoms/controls/SubmitButton';
import FormHistoryBlocker from '../FormHistoryBlocker';
import LoadingIndicator from '../../../atoms/LoadingIndicator';
import allRoutes from '../../../../router';
import { useLocation } from 'react-router-dom';
import useWindowDimensions from '../../../../hooks/WindowDimensions';
import FlashCheckbox from '../../../atoms/FlashCheckbox';
import ImageUploader from 'components/organisms/ImageUploader';
import { FormControlTextInputStyle, StyledTextLength } from 'components/organisms/forms/PriceEditForm/style';
import { getLanguages } from '../../../../api/language';
import { LanguageVm } from '../../../../models/language.model';
import TextField from '../../../atoms/controls/TextField';
import { TimeZoneShortVm } from '../../../../models/timeZone.model';
import { timeZoneAPI } from '../../../../api/timeZone';

export type MediaSpaceFormProps = {
    model: MediaSpaceModel;
    originalData: MediaSpaceModel;
    isCreate: boolean;
    disabled?: boolean;
    hideButtons?: boolean;
    onSubmiting: (isSubmiting: boolean) => void;
    onSubmit: (data: MediaSpaceModel) => void;
    onStateChange: (mediaspace: MediaSpaceModel) => void;
    sendImage: (value: MediaSpaceImageModel) => void;
    isOperator: boolean;
    id?: string;
    ref: React.MutableRefObject<IBaseFormRef | undefined>;
};

const MediaSpaceForm = forwardRef(function BaseForm(props: MediaSpaceFormProps, ref): JSX.Element {
    const nameMaxLength = 100;
    const { t } = useTranslation();
    const history = useHistory();
    const locations = useLocation().state;
    const {
        isCreate,
        disabled,
        hideButtons,
        model,
        onSubmit,
        onStateChange,
        onSubmiting,
        originalData,
        isOperator,
        id,
        sendImage,
    } = props;
    const { width } = useWindowDimensions();
    const [pictureUrl, setPictureUrl] = useState<string | null | undefined>(null);

    const [formData, setFormData] = useState<MediaSpaceModel>(DefaultMediaSpaceModel);
    const [isDisabled, setIsDisabled] = useState(false);
    const [languages, setLanguages] = useState<LanguageVm[]>();
    const [timeZones, setTimeZones] = useState<Array<TimeZoneShortVm>>([]);
    const [selectedTimeZone, setSelectedTimeZone] = useState<TimeZoneShortVm>();
    const [initializedAutoComplete, setInitializedAutoComplete] = useState<boolean>(false);

    const baseRef = useRef<IBaseFormRef>();
    useImperativeHandle(ref, () => ({
        release() {
            baseRef.current?.release();
        },
    }));

    useEffect(() => {
        if (!model.names.length) {
            const names = languages?.map((element) => {
                return {
                    name: '',
                    comment: '',
                    address: '',
                    languageId: element.id,
                    languageCode: element.code,
                };
            });
            model.names = names ?? [];
        }
        setFormData(model);
        setPictureUrl(model.pictureUrl);
    }, [model, originalData]);

    // TODO: Handling controlled or uncontrolled Autocomplete
    useEffect(() => {
        if (timeZones.length > 0 && formData) {
            if (isCreate) {
                setInitializedAutoComplete(true);
            } else {
                if (formData.timeZoneId !== '') {
                    const timeZone = timeZones.find((x) => x.id === formData.timeZoneId);
                    setSelectedTimeZone(timeZone);
                    setInitializedAutoComplete(true);
                }
            }
        }
    }, [timeZones, formData]);

    useEffect(() => {
        async function fetchData() {
            const timeZonesRes = await timeZoneAPI.get();
            setTimeZones(timeZonesRes.data);
            const languagesResponse = await getLanguages();
            setLanguages(languagesResponse.data);
            const names = languagesResponse.data?.map((x) => ({
                name: '',
                comment: '',
                address: '',
                languageId: x.id,
                languageCode: x.code,
            }));
            if (names) {
                setFormData({ ...formData, names: names });
            }
        }
        fetchData();
    }, [location.pathname]);

    const handleSubmit = async () => {
        setIsDisabled(true);
        try {
            baseRef.current?.release();
            await onSubmit(formData);
        } catch {
            baseRef.current?.block();
        }

        setIsDisabled(false);
    };

    const changeMediaSpaceActiveFlag = () => {
        setFormData((prevState) => {
            return {
                ...prevState,
                isActive: !prevState.isActive,
            };
        });
    };

    const renderButtonArea = () => {
        if (!hideButtons) {
            return (
                <Box
                    display="flex"
                    justifyContent={width > 768 ? 'flex-end' : 'center'}
                    flexDirection={width > 374 ? 'row' : 'column'}
                >
                    <SubmitButton
                        onSubmiting={onSubmiting}
                        onSubmit={handleSubmit}
                        color={isDisabled ? 'default' : 'primary'}
                        type="submit"
                        variant="outlined"
                        name="save-change-button"
                    >
                        {isDisabled ? (
                            <LoadingIndicator isLoaded={false} />
                        ) : (
                            <>
                                {isCreate ? (
                                    <Typography variant="h5" className="submit-text">
                                        {t('media-space-form.button-text.create')}
                                    </Typography>
                                ) : (
                                    <Typography variant="h5" className="submit-text">
                                        {t('media-space-form.button-text.save')}
                                    </Typography>
                                )}
                            </>
                        )}
                    </SubmitButton>
                    <CancelButton
                        baseRef={baseRef}
                        originalData={originalData}
                        formData={formData}
                        onClick={() => {
                            if (id) {
                                history.replace({
                                    pathname: generatePath(allRoutes.mediaSpaceProfile.path, { id: id, pageId: 1 }),
                                    state: locations,
                                });
                            } else {
                                history.replace(generatePath(allRoutes.mediaSpaces.path, { id: 1 }));
                            }
                        }}
                        disabled={disabled || isDisabled}
                        name="cancel-button"
                    >
                        {t('media-space-form.button-text.cancel')}
                    </CancelButton>
                </Box>
            );
        } else {
            return null;
        }
    };

    return (
        <MediaSpaceFormStyle>
            {!disabled && <FormHistoryBlocker formData={formData} ref={baseRef} originalData={originalData} />}
            {formData.names.map((item, index) => (
                <div key={index}>
                    <FormControlTextInputStyle
                        disabled={disabled}
                        inputProps={{
                            maxLength: nameMaxLength,
                        }}
                        value={item.name}
                        color="secondary"
                        onChangeValue={(value: string) => {
                            const nameIndex = formData.names.findIndex((x) => x.languageId == item.languageId);
                            const arr = [...formData.names];
                            arr[nameIndex].name = value;
                            onStateChange({ ...formData, names: arr });
                            setFormData({ ...formData, names: arr });
                        }}
                        label={
                            t('media-space-form.name-label') +
                            ` (${languages?.find((x) => x.id === item.languageId)?.name ?? ''})`
                        }
                        placeholder=""
                        name="name"
                    />
                    <StyledTextLength className="title-name-text-length">{`${item.name.length}/${nameMaxLength}`}</StyledTextLength>
                </div>
            ))}
            {initializedAutoComplete && (
                <Autocomplete
                    disabled={disabled}
                    noOptionsText={t('media-space-form.time-zone-no-option')}
                    id="timeZone-select"
                    options={timeZones as TimeZoneShortVm[]}
                    autoHighlight
                    value={selectedTimeZone}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={(event, value) => {
                        const timeZoneId = value ? value.id : null;
                        const data = { ...formData, timeZoneId: timeZoneId };
                        onStateChange(data);
                        setFormData(data);
                    }}
                    getOptionLabel={(option) => option.displayName}
                    renderOption={(option) => <React.Fragment>{option.displayName}</React.Fragment>}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            color="secondary"
                            label={t('media-space-form.time-zone-label')}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            )}
            <FormControlTextInput
                value={formData.address}
                color="secondary"
                disabled={disabled}
                onChangeValue={(value: string) => {
                    const newForm = { ...formData, address: value };
                    onStateChange(newForm);
                    setFormData(newForm);
                }}
                label={t('media-space-form.address-label')}
                name="address"
            ></FormControlTextInput>
            <ResponsiveFlexBox justifyContent="space-between">
                <Box width={width > 467 ? '45%' : '100%'}>
                    <FormControlTextInput
                        value={formData.latitude ? formData.latitude.toString() : ''}
                        color="secondary"
                        disabled={disabled}
                        InputProps={{
                            readOnly: true,
                        }}
                        label={t('media-space-form.latitude-label')}
                        type="number"
                        name="latitude"
                    ></FormControlTextInput>
                </Box>
                <Box width={width > 467 ? '45%' : '100%'}>
                    <FormControlTextInput
                        value={formData.longitude ? formData.longitude.toString() : ''}
                        color="secondary"
                        disabled={disabled}
                        InputProps={{
                            readOnly: true,
                        }}
                        label={t('media-space-form.longitude-label')}
                        type="number"
                        name="longitude"
                    ></FormControlTextInput>
                </Box>
            </ResponsiveFlexBox>
            <FlashCheckbox
                disabled={disabled}
                onChange={changeMediaSpaceActiveFlag}
                checked={formData.isActive}
                label={t('media-space-form.label.is-active')}
            />
            <FlashTextarea
                className="comment"
                value={formData.comment}
                userwidth="100%"
                label={t('media-space-form.comment-label')}
                rows={4}
                disabled={disabled}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    const newForm = { ...formData, comment: value };
                    onStateChange(newForm);
                    setFormData(newForm);
                }}
                name="comment"
            ></FlashTextarea>
            {!isOperator && (
                <ResponsiveFlexBox>
                    <ImageUploader
                        photoImgSrc={pictureUrl}
                        disabled={disabled}
                        handleBase64image={sendImage}
                    ></ImageUploader>
                </ResponsiveFlexBox>
            )}
            {renderButtonArea()}
        </MediaSpaceFormStyle>
    );
});

export default MediaSpaceForm;
