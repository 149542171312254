import styled from 'styled-components';

import { FormControl, Input, Select, Typography } from '@material-ui/core';

export const InputDate = styled.div(({}) => ({
    justifyContent: 'space-between',
    marginTop: '1.25rem',
    '.MuiInputBase-input': {
        paddingBottom: '0.625rem',
    },
}));

export const DayInput = styled(Input).attrs({
    type: 'number',
    inputProps: {
        min: 1,
        max: 31,
    },
})({
    width: '15%',
    marginRight: '5%',
});

export const MonthSelect = styled(Select)({
    width: '37%',
    marginRight: '5%',
    lineHeight: '150.01%',

    '.MuiInputBase-input': {
        paddingBottom: '0.4rem',
    },
});

interface YearInputProps {
    $now: Date;
}

export const YearInput = styled(Input).attrs<YearInputProps>(({ $now }) => ({
    type: 'number',
    inputProps: {
        min: $now.getFullYear() - 150,
        max: $now.getFullYear() - 18,
    },
}))<YearInputProps>({
    width: '37%',
});

export const BirthdayTitle = styled(Typography)({
    marginTop: '2.5rem',
    fontWeight: 500,
});

export const StyledFormControl = styled(FormControl)(({}) => ({
    width: '100%',
}));
