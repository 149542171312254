import React from 'react';
import { DialogContentText } from '@material-ui/core';

export type TextContentProps = {
    text: string;
};

export default function TextContent(props: TextContentProps): JSX.Element {
    const { text: contentText } = props;
    return (
        <div>
            <DialogContentText>{contentText}</DialogContentText>
        </div>
    );
}
