import {
    DELETE_MEDIA_SPACES_FORM,
    DeleteMediaSpacesForm,
    NOT_UPLOAD_VIDEO,
    NotUploadVideo,
    SET_ALL_MEDIA_SPACES,
    SET_MEDIA_SPACES_FORM,
    SET_VIDEO_ID,
    SetAllMediaSpaces,
    SetMediaSpacesForm,
    SetVideoId,
    UPLOAD_VIDEO,
    UploadVideo,
} from './types';

export const setMediasSpacesForm = (data: string[]): SetMediaSpacesForm => ({
    type: SET_MEDIA_SPACES_FORM,
    data,
});

export const deleteMediaSpacesForm = (): DeleteMediaSpacesForm => ({
    type: DELETE_MEDIA_SPACES_FORM,
});

export const setAllMediaSpaces = (data: boolean): SetAllMediaSpaces => ({
    type: SET_ALL_MEDIA_SPACES,
    data,
});

export const setVideoId = (data: string): SetVideoId => ({
    type: SET_VIDEO_ID,
    data,
});

export const uploadVideo = (): UploadVideo => ({
    type: UPLOAD_VIDEO,
});

export const notUploadVideo = (): NotUploadVideo => ({
    type: NOT_UPLOAD_VIDEO,
});
