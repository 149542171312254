import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const Icon = styled.div(({}) => ({
    width: 'auto',
    height: 'auto',
    margin: '0rem 1.25rem 0.313rem 0rem',
    img: {
        zIndex: 2,
    },
    '@media only screen and (min-width: 320px) and (max-width: 424px)': {
        margin: '0rem 0.7rem 0.313rem 0rem',
    },
}));

export const BurgerIconStyle = styled.div(({}) => ({
    width: 'auto',
    height: 'auto',
    '.burger': {
        width: '2.125rem',
        height: '2rem',
        borderRadius: '3px',
        marginBottom: '0.313rem',
        path: {
            fill: variables.colours.light,
        },
        ':last-child': {
            marginBottom: 0,
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '1.875rem',
            height: '1.675rem',
            marginBottom: '0.25rem',
        },
    },
}));
