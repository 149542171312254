import { variables } from '../../../theme/variables';
import styled from 'styled-components';
import { Box } from '@material-ui/core';

export const StyledFooter = styled.div(({}) => ({
    height: variables.constants.footerHeight,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    backgroundColor: variables.backgoroundColours.dark,
    padding: '16px 6.06%',

    '.logo': {
        display: 'flex',
        alignItems: 'flex-end',

        img: {
            display: 'block',
        },
    },

    '.navigation-text': {
        lineHeight: '17px!important',

        '& > *': {
            color: variables.colours.light,
            fontSize: '10px!important',
        },
    },

    '@media only screen and (max-width : 767px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        height: 'auto',
    },
}));

export const FlashInfoCopyright = styled.div(({}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        marginTop: '1.25rem',
        marginLeft: '0.5rem',
    },

    '.copyright': {
        color: variables.colours.light,
        fontSize: '12px!important',
        lineHeight: '15px!important',
        fontWeight: 500,
    },
}));

export const FlashInfo = styled.div(({}) => ({
    textAlign: 'left',

    '.button-handler': {
        margin: '0.625rem 1.563rem 0 0',
        padding: 0,
        '*': {
            height: 21,
        },
    },

    '.app-store': {
        marginBottom: '0.625rem',
        '*': {
            height: 28,
        },
    },

    '.social-button': {
        margin: '0.625rem 1.25rem 0.625rem 0',
        minWidth: 0,
        '*': {
            'margin-bottom': '1px',
        },
    },

    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        marginTop: '1.25rem',
        marginLeft: '0.5rem',
        '.app-store': {
            marginBottom: '0.225rem',
        },
    },
}));

export const StyledBox = styled(Box)(({}) => ({
    margin: '0.625rem 0',
}));

export const FooterHandler = styled.footer(({}) => ({
    position: 'sticky',
    width: '100%',
}));
