import { useContext } from 'react';

import { AuthContext } from 'providers/AuthProvider';

type UseAuth = () => AuthContext;

const useAuth: UseAuth = () => {
    const context = useContext<AuthContext>(AuthContext);

    return context;
};

export default useAuth;
