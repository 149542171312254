import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import FormControlTextInput from '../../molecules/forms/FormControlTextInput';
import { ReactComponent as Search } from '../../../assets/icons/Search.svg';
import { FormControlTextInputStyle } from './style';

export type FilterInputProps = {
    onChange: (filterString: string) => void;
    value?: string;
};

export default function FilterInput(props: FilterInputProps): JSX.Element {
    const { t } = useTranslation();
    const { onChange: setFilter, value: content } = props;
    const debounceEvent = _.debounce((lastValue) => {
        setFilter(lastValue);
    }, 2000);

    return (
        <FormControlTextInputStyle>
            <FormControlTextInput
                color="primary"
                variant="outlined"
                onChangeValue={(value: string) => {
                    debounceEvent(value);
                }}
                defaultValue={content}
                label={t('general.scan')}
                name="search"
            />
            <Search className="search-icon" />
        </FormControlTextInputStyle>
    );
}
