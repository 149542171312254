/* eslint-disable prettier/prettier */

import { FC, useState, Fragment, useEffect, useCallback } from 'react';

import moment from 'moment';

import { useHistory, generatePath, useLocation } from 'react-router-dom';

import MultiStepForm from 'components/organisms/MultiStepForm';
import MediaSubForm from 'components/organisms/MediaSubForm';
import MediaSpaceSubForm from 'components/organisms/MediaSpaceSubForm';
import DateSubForm from 'components/organisms/DateSubForm';
import LoadingIndicatorPopup from 'components/atoms/LoadingIndicatorPopup';

import { calculationOrderPrice, toChangeStatus, getById as getOrderById } from 'api/orders';
import { getContentMetadata, getVideoFileSrc, updateVideo } from 'api/videos';
import { scheduleInitialValues } from 'components/organisms/DateSubForm/constants';
import routes from 'router';

import { useTranslation } from 'react-i18next';

import useSelector from 'hooks/useAppSelector';

import { MediaFormValues } from 'components/organisms/MediaSubForm';
import { MediaSpaceFormValues } from 'components/organisms/MediaSpaceSubForm';
import { DateFormValues } from 'components/organisms/DateSubForm/types';
import { OrderStatusType, UserRoles } from 'shared/enums';
import { FormStep } from 'components/organisms/MultiStepForm/types';
import { VideoVm } from 'models/order';

import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import { ReactComponent as SpaceIcon } from 'assets/icons/Mediaspace.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/CalendarStage.svg';
import { useDispatch } from 'react-redux';
import { setMediasSpacesForm, setVideoId } from '../../../../store/form/actions';

export interface OrderFormValues extends MediaFormValues, MediaSpaceFormValues, DateFormValues {}

const OrderForm: FC = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { state: orderId } = useLocation<string | undefined>();
    const dispatch = useDispatch();

    const formSteps: FormStep[] = [
        {
            icon: VideoIcon,
            title: t('order-form.video-description'),
            component: MediaSubForm,
        },
        {
            icon: SpaceIcon,
            title: t('order-form.media-space-description'),
            component: MediaSpaceSubForm,
        },
        {
            icon: CalendarIcon,
            title: t('order-form.calendar-description'),
            component: DateSubForm,
        },
    ];

    const user = useSelector((state) => state.userReducer.user);

    const [initialValues, setInitialValues] = useState<OrderFormValues>({
        contentUrl: '',
        contentDuration: 10,
        mediaSpaces: [],
        schedules: [scheduleInitialValues],
        videoId: '',
    });
    const [video, setVideo] = useState<VideoVm | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const orderDatePeriodUtc = (scheduleDate: string | undefined, scheduleTime: string | undefined, isEndDate = false) => {
        if (scheduleDate && scheduleTime) {
            const date = new Date(moment(scheduleDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'));
            const hour = scheduleTime?.split(':');
            if (isEndDate && hour !== undefined && hour[0] === '00') {
                date.setDate(date.getDate() + 1);
            } else {
                date.setHours(hour !== undefined ? (+hour[0]) : 0);
            }
            return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
        }

        return undefined;
    }

    const handleSubmit = useCallback(
        async ({ contentDuration, schedules, mediaSpaces, contentUrl, content, videoId }: OrderFormValues) => {
            setIsLoading(true);
            const { data: newOrder } = await calculationOrderPrice({
                duration: contentDuration,
                videoId: videoId,
                schedules: schedules.map((schedule) => ({
                    ...schedule,
                        startDate: moment(schedule.startDate).format('YYYY-MM-DDTHH:mm:ss'),
                        endDate: moment(schedule.endDate).format('YYYY-MM-DDTHH:mm:ss'),
                        utcStartDate: orderDatePeriodUtc(schedule.startDate?.toString(), schedule.startTime?.toString()),
                        utcEndDate: orderDatePeriodUtc(schedule.endDate?.toString(), schedule.endTime?.toString(), true),
                    })),
                mediaSpaceIds: mediaSpaces,
                orderId,
                currencyId: user.preferred_currency_id
            });

            if (user.role == UserRoles.Admin || user.role == UserRoles.Operator) {
                const reqValue = {
                    status: OrderStatusType.Approved,
                    reasonMessage: '',
                };
                await toChangeStatus(newOrder.id, reqValue);
            }

            const newVideo = {
                duration: contentDuration,
                url: contentUrl,
                file: content,
            };

            if (orderId && newVideo.url && video) {
                await updateVideo(video.id, newOrder.id, newVideo.duration);
            }

            setIsLoading(false);

            push(generatePath(routes.orderProfile.path, { id: newOrder.id }));
        },
        [user.role, video, orderId],
    );

    useEffect(() => {
        if (orderId) {
            const getOrder = async () => {
                setIsLoading(true);

                const { data } = await getOrderById(orderId);

                const {
                    mediaSpaces,
                    videos: [video],
                    schedules,
                } = data;
                dispatch(setVideoId(video.id));

                setVideo(video);

                const mediaSpacesIds = mediaSpaces.map((mediaspace) => mediaspace.id);
                dispatch(setMediasSpacesForm(mediaSpacesIds));

                const { data: metaData } = await getContentMetadata(video.id, data.id);

                const videoSrc = getVideoFileSrc(video.id, data.id);

                setInitialValues({
                    mediaSpaces: mediaSpacesIds,
                    contentDuration: metaData.duration,
                    contentType: metaData.contentType,
                    contentUrl: videoSrc,
                    videoId: '',
                    schedules: schedules.map(({ startDate, endDate, startTime, endTime, replays, frequency }) => ({
                        startDate: moment(startDate).toDate(),
                        endDate: moment(endDate).toDate(),
                        startTime: moment(startTime, 'HH:mm:ss').format('HH:mm:ss'),
                        endTime: moment(endTime, 'HH:mm:ss').format('HH:mm:ss'),
                        replays,
                        frequency,
                        isShowAllDay: startTime === endTime && startTime === '00:00:00',
                    })),
                });

                setIsLoading(false);
            };

            setTimeout(() => getOrder(), 300);
        }
    }, [orderId]);

    return (
        <Fragment>
            <MultiStepForm steps={formSteps} initialValues={initialValues} onSubmit={handleSubmit} />
            <LoadingIndicatorPopup isLoaded={!isLoading} />
        </Fragment>
    );
};

export default OrderForm;
