import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { variables } from 'theme/variables';

export const ContainerWithTitleStyled = styled.div((props: { marginBottom: string }) => ({
    width: '100%',
    paddingBottom: '1px',
    marginBottom: props.marginBottom,

    '.map-handler': {
        height: '43.75rem',
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            marginBottom: '1.25rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            height: '25rem',
            marginBottom: '1.25rem',
        },
    },
}));

export const TitleStyled = styled(Typography).attrs((props: { variant: string }) => ({
    variant: props.variant,
}))({
    marginBottom: '10px',
    fontFamily: 'MagistralCTT',
    'font-weight': '700',
    fontSize: '60px',
    lineHeight: '137.01%',
    color: variables.colours.dark,
});

export const TeaserStyled = styled(Typography)({
    marginBottom: '30px',
    'font-weight': '400',
    fontSize: '35px',
    lineHeight: '137.01%',
    letterSpacing: '0.05em',
});
