import styled from 'styled-components';
import NavigationLink from '../../../molecules/NavigationLink';

export const StyledNavigationLink = styled(NavigationLink)(({}) => ({
    padding: 0,
    justifyContent: 'flex-start',
    '.MuiButton-startIcon': {
        marginLeft: 0,
    },
}));
