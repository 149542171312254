import * as React from 'react';
import homePage from '../../../../assets/images/home-page.svg';
import { Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { HomePageImg, HomePageHeadline, HomePageSpan, HomePageText, HomePageButton } from '../style';
import allRoutes from '../../../../router';
import { useDispatch } from 'react-redux';
import { deleteMediaSpacesForm, setAllMediaSpaces } from '../../../../store/form/actions';

export default function HasNoOrdersMessage(): JSX.Element {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const pushHistoryAndClearRedux = () => {
        history.push(allRoutes.createOrder.path);
        dispatch(deleteMediaSpacesForm());
        dispatch(setAllMediaSpaces(false));
    };

    return (
        <>
            <HomePageText className="no-orders-text">
                <HomePageHeadline>
                    <Typography variant="h2">{t('users-page.home-page-headline')}</Typography>
                </HomePageHeadline>
                <HomePageSpan>
                    <Typography variant="h4" className="welcome-text welcome-start">
                        {t('users-page.home-page-sspan-start')}
                    </Typography>
                    <Typography variant="h4" className="text-ads">
                        {t('users-page.advertising-video')}
                    </Typography>
                    <Typography variant="h4" className="welcome-text">
                        {t('users-page.home-page-sspan-end')}
                    </Typography>
                </HomePageSpan>
                <HomePageButton>
                    <Button
                        variant="contained"
                        onClick={pushHistoryAndClearRedux}
                        color="primary"
                        component="label"
                        className="contained-create-button"
                        data-id="create-button"
                    >
                        <Typography variant="h5" className="contained-create-text">
                            {t('media-space-form.button-text.create')}
                        </Typography>
                    </Button>
                </HomePageButton>
            </HomePageText>
            <HomePageImg>
                <img src={homePage}></img>
            </HomePageImg>
        </>
    );
}
