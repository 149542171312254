import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Typography, Link as MuiLink } from '@material-ui/core';

import Logo from 'components/atoms/controls/Logo';

import { StyledFooter, FlashInfo, FooterHandler, FlashInfoCopyright } from 'components/templates/Footer/style';
import allRoutes from '../../../router';
import ResponsiveFlexBox from 'components/atoms/ResponsiveFlexBox';
import { getConfig } from 'config';
import useSelector from 'hooks/useAppSelector';

const Footer: FC = () => {
    const { t } = useTranslation();
    const [company, setCompany] = useState<string | null>(null);
    const userLocale = useSelector((state) => state.userReducer.user.lc);
    const [config, setConfig] = useState<Config | null>(null);

    useEffect(() => {
        getConfig().then((response) => {
            setConfig(response);
        });
    }, []);

    useEffect(() => {
        if (!config) return;

        if (userLocale) {
            if (userLocale === 'ru') {
                setCompany(config.contactsCompanyRu);
            } else if (userLocale === 'en') {
                setCompany(config.contactsCompanyEn);
            }
        } else {
            if (localStorage.getItem('locale')) {
                if (localStorage.getItem('locale') === 'ru') {
                    setCompany(config.contactsCompanyRu);
                } else if (localStorage.getItem('locale') === 'en') {
                    setCompany(config.contactsCompanyEn);
                }
            } else {
                if (config.defaultLocale === 'ru') {
                    setCompany(config.contactsCompanyRu);
                } else if (config.defaultLocale === 'en') {
                    setCompany(config.contactsCompanyEn);
                }
            }
        }
    }, [userLocale, config]);

    return (
        <FooterHandler itemScope itemType="http://schema.org/WPFooter">
            <StyledFooter>
                <Logo className="logo" data-id="logo" />
                <FlashInfoCopyright>
                    <Typography variant="subtitle1" className="copyright">
                        © <span itemProp="copyrightYear">{new Date().getFullYear()}</span> - {company}
                    </Typography>
                </FlashInfoCopyright>
                <FlashInfo>
                    <ResponsiveFlexBox
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        height={'100%'}
                    >
                        <MuiLink
                            className="navigation-text"
                            component={Link}
                            to={allRoutes.contacts.path}
                            data-id="contacts"
                        >
                            <Typography variant="caption">{t('navigation.contacts')}</Typography>
                        </MuiLink>
                        <MuiLink
                            className="navigation-text"
                            component={Link}
                            to={t('navigation.offer-doc-link')}
                            target="_blank"
                            data-id="offer"
                        >
                            <Typography variant="caption">{t('navigation.offer')}</Typography>
                        </MuiLink>
                        <MuiLink
                            className="navigation-text"
                            component={Link}
                            to={t('navigation.GDPR-doc-link')}
                            target="_blank"
                            data-id="personal-data"
                        >
                            <Typography variant="caption">
                                {t('navigation.presonal-data-processing-agreement')}
                            </Typography>
                        </MuiLink>
                        <MuiLink
                            className="navigation-text"
                            component={Link}
                            to={t('navigation.privacy-policy-doc-link')}
                            target="_blank"
                            data-id="privacy-policy"
                        >
                            <Typography variant="caption">{t('navigation.privacy-policy')}</Typography>
                        </MuiLink>
                    </ResponsiveFlexBox>
                </FlashInfo>
            </StyledFooter>
        </FooterHandler>
    );
};

export default Footer;
