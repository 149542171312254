import styled from 'styled-components';

import { Link, LinkProps } from 'react-router-dom';

import { AppBar, ButtonBase, ButtonBaseProps, lighten, Toolbar as MuiToolbar } from '@material-ui/core';

import { variables } from 'theme/variables';

export const StyledAppBar = styled(AppBar)(({}) => ({
    height: variables.constants.appBarHeight,
    zIndex: 500,
}));

export const StyledContainer = styled(AppBar)(({}) => ({
    justifyContent: 'center',
    width: '100%',
    boxShadow: '0 0 0 rgba(0,0,0,0)',
    height: variables.constants.appBarHeight,
    paddingLeft: '11.3%',
    paddingRight: '11.3% !important',
    '.button-handler': {
        padding: 0,
        ':first-child': {
            paddingRight: '1.563rem',
        },
    },
    '.orders-button': {
        marginLeft: '1.563rem',
        color: variables.colours.light,
    },
    '.link-now': {
        marginLeft: '1.563rem',
        color: variables.colours.primary,
    },
    '.create': {
        marginLeft: '5%',
        ':hover': {
            '.create-text': {
                color: variables.colours.primary,
            },
        },
    },
    '.create-text': {
        color: variables.colours.light,
    },
    '.tools': {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',

        '@media only screen and (min-width : 320px) and (max-width : 1024px)': {
            display: 'none',
        },
    },
    '.burger-button': {
        marginLeft: '2.5rem',
        display: 'none',
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
            display: 'block',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            marginLeft: '1.25rem',
            display: 'block',
        },
    },
    '.MuiTypography-colorPrimary': {
        color: variables.colours.primary,
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
        padding: '0 !important',
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        padding: '0 !important',
        '.create': {
            display: 'none',
        },
    },
}));

export const CreateOrderButton = styled(ButtonBase).attrs({
    component: Link,
})<ButtonBaseProps & LinkProps>({
    padding: '10px 20px',
    borderRadius: 8,
    backgroundColor: variables.colours.primary,
    transition: '.2s ease',

    ':hover': {
        backgroundColor: lighten(variables.colours.primary, 0.15),
    },

    '@media only screen and (max-width: 767px)': {
        display: 'none',
    },
});

export const Toolbar = styled(MuiToolbar)({
    [CreateOrderButton]: {
        marginLeft: 50,
    },
});
