import { useCallback, useEffect, useState } from 'react';

import VideoPlayer from 'components/atoms/VideoPlayer';

import { getContentMetadata, getVideoFileSrc } from 'api/videos';

import { OrderVm } from 'models/order';

import {
    ImageView,
    OrderVideo,
    Modal,
    ModalContent,
    PreviewBlock,
} from 'components/atoms/Order/OrderContentPreview/style';

export type OrderContentPreviewProps = {
    order: OrderVm | undefined;
    height?: string | undefined;
};

export default function OrderContentPreview(props: OrderContentPreviewProps): JSX.Element {
    const { order, height } = props;
    const [src, setSrc] = useState<string>();
    const [contentType, setContentType] = useState<string>();
    const [isFullscreen, setIsFullscreen] = useState(false);

    const memoizedCallback = useCallback(() => {
        if (order?.videos != undefined && order?.videos[0]) {
            const loadContent = async () => {
                const metadata = await getContentMetadata(order.videos[0].id, order.id);
                setContentType(metadata.data.contentType);
                const videoSrc = getVideoFileSrc(order.videos[0].id, order.id);
                setSrc(videoSrc);
            };
            loadContent();
        }
    }, [order]);

    useEffect(() => {
        memoizedCallback();
    }, [order]);

    const isVideo = useCallback(
        (mediaType: string | undefined): boolean => {
            return !!mediaType && mediaType.indexOf('video/') == 0;
        },
        [contentType],
    );

    return (
        <PreviewBlock>
            <OrderVideo height={height ?? 'auto'} onClick={() => setIsFullscreen(true)}>
                {isVideo(contentType) ? <VideoPlayer src={src} preview={true} /> : <ImageView src={src} />}
            </OrderVideo>
            <Modal open={isFullscreen} onClose={() => setIsFullscreen(false)}>
                <ModalContent>
                    {isVideo(contentType) ? <VideoPlayer controls autoPlay src={src} /> : <ImageView src={src} />}
                </ModalContent>
            </Modal>
        </PreviewBlock>
    );
}
