import styled from 'styled-components';

import { SpacerProps } from 'components/atoms/Spacer';

const StyledSpacer = styled.div<SpacerProps>(({ width = 'auto', height = 'auto', grow = 1, shrink = 1 }) => ({
    width,
    height,
    flexGrow: grow,
    flexShrink: shrink,
}));

export default StyledSpacer;
