import { FC, useRef, useCallback, useState, useEffect } from 'react';

import * as Yup from 'yup';
import { isEqual, set } from 'lodash';
import { Formik, FormikHelpers } from 'formik';

import { toast } from 'react-toastify';
import { generatePath, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Backdrop, Fade, CircularProgress, Box, Tooltip } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

import FlashContainer from 'components/atoms/FlashContainer';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import MediaSpacesSelect from 'components/atoms/MediaSpacesSelect';
import DeviceStatusChip from 'components/atoms/controls/DeviceStatusChip';
import LoadingIndicatorPopup from 'components/atoms/LoadingIndicatorPopup';
import VolumeMixer from 'components/atoms/VolumeMixer';
import FileList from 'components/molecules/Device/FileList';
import DeviceInfo from 'components/molecules/Device/DeviceInfo';
import ProfileActions from 'components/molecules/ProfileActions';
import OrderTableOfContents from 'components/molecules/forms/OrderTableOfContents';
import FormHistoryBlocker from 'components/organisms/forms/FormHistoryBlocker';

import allRoutes from 'router';

import useSelector from 'hooks/useAppSelector';

import SpaceIcon from 'components/atoms/Icon/SpaceIcon';
import VideoIcon from 'components/atoms/Icon/VideoIcon';
import { ReactComponent as DeviceScreen } from 'assets/icons/DeviceScreen.svg';

import { useNotification } from 'components/organisms/NotificationProvider';

import { UserRoles } from 'shared/enums';
import { PageSize } from 'shared/constants';
import { devicesAPI } from 'api/devices';
import { IBaseFormRef } from 'models/interface/i-form-data';
import { HubEvents } from 'components/organisms/forms/ChatForm';
import {
    DefaultDeviceInput,
    DeviceFile,
    DeviceProfilePage,
    DeviceScreenshotVm,
    UpdateDeviceNameData,
    UpdateDeviceVolumeData,
    DeviceVm,
} from 'models/device.model';
import { DeviceStatusEnum } from 'components/atoms/gridFilters/DeviceStatusSelect';

import {
    StyledTextInput,
    StyledProfile,
    IconButton,
    DeviceName,
    ViewButton,
    ButtonText,
    ButtonHolder,
    SaveButton,
    Header,
    DeviceActions,
    DeviceButtonIcon,
} from 'components/pages/DeviceProfile/style';

import { ImageViewStyled, ModalStyled } from 'components/organisms/forms/Playlist/style';
import { screenshotInfoStyle } from 'components/templates/Devices/screenshotInfoStyle';
import { HeaderTypography } from '../../atoms/HeaderTypography';
import { GridText } from '../../templates/Devices/style';
import { ReactComponent as PaymentAttention } from '../../../assets/icons/PaymentAttention.svg';

export interface DeviceProfileProps {
    event: DeviceVm;
    id: string;
}

const DeviceProfile: FC<DeviceProfileProps> = ({ event, id }) => {
    const { t } = useTranslation();
    const { hub, updateHub } = useNotification();

    const screenTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    const history = useHistory();
    const baseRef = useRef<IBaseFormRef>();
    const user = useSelector((state) => state.userReducer.user);

    const [openModal, setOpenModal] = useState(false);
    const [screenshotSrc, setScreenshotSrc] = useState('');
    const [playlistName, setPlaylistName] = useState('');
    const [orderNumber, setOrderNumber] = useState(0);
    const [fileName, setFileName] = useState('');
    const [playlistFileName, setPlaylistFileName] = useState('');
    const [originalData, setOriginalData] = useState(DefaultDeviceInput);
    const [selectedMediaSpaceId, setSelectedMediaSpaceId] = useState<string>('');
    const [screenshotLoading, setScreenshotLoading] = useState(false);
    const [showScreenshotInfo, setScreenshotInfo] = useState<boolean>(false);

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [files, setFiles] = useState<Array<DeviceFile>>([]);

    const [isDisabled, setIsDisabled] = useState(false);
    const [disabled, setDisabled] = useState(id !== undefined);
    const [nameIsDisabled, setNameIsDisabled] = useState(true);

    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const getScreenshot = async () => {
        setScreenshotLoading(true);

        hub?.invoke('RequestScreenshot', id)
            .then(() => {
                console.log('SignalR Connected.');
            })
            .catch(() => {
                updateHub();
            });

        screenTimeoutRef.current = setTimeout(() => {
            hub?.off(HubEvents.onNewScreenshot);
            setScreenshotLoading(false);
        }, 15 * 1000);
    };

    const onNewScreenshotFromHub = (data: DeviceScreenshotVm) => {
        setScreenshotInfo(true);
        setScreenshotSrc('data:image/jpeg;base64,' + data.screenshotBase64.replace(/\n/g, ''));
        setFileName(data.file?.video?.name || '');
        setOrderNumber(data.file?.video?.order?.number || 0);
        setPlaylistFileName(data.file?.playlistFile?.name);
        setPlaylistName(data.file?.playlistFile?.playlist?.name);
        setOpenModal(true);
        setScreenshotLoading(false);

        if (screenTimeoutRef.current) {
            clearTimeout(screenTimeoutRef.current);
            screenTimeoutRef.current = null;
        }
    };

    const getFilesPage = async () => {
        const request = {
            page: page,
            pageSize: PageSize,
            sortProperty: '',
            sortDirection: 1,
        } as DeviceProfilePage;

        const response = await devicesAPI.getDeviceFiles(id, request);

        const totalPageNumber = Math.ceil(response.total / response.pageSize);
        setTotalPage(totalPageNumber);
        setTotalItems(response.total);
        setFiles(response.data);
        setIsDataLoaded(true);
    };

    const submitVolume = useCallback(async (volumeLevel: number) => {
        setIsDisabled(true);

        const volumeData: UpdateDeviceVolumeData = {
            volumeLevel,
        };

        setOriginalData((prev) => set(prev, 'volumeLevel', volumeLevel));

        try {
            const { data } = await devicesAPI.updateVolume(id, volumeData);
            setOriginalData(data);
            toast.success(t('device-profile.success-update-device'));
            setIsDisabled(false);
        } catch (err) {
            toast.error(t('device-profile.failed-update-device'));
            console.error(err);
        } finally {
            setIsDisabled(false);
        }
    }, []);

    const handleSubmit = async (values: DeviceVm, helpers: FormikHelpers<DeviceVm>) => {
        setIsDisabled(true);

        const model: UpdateDeviceNameData = {
            name: values.name,
            mediaSpaceId: selectedMediaSpaceId,
        };

        try {
            const { data } = await devicesAPI.update(id, model);
            setOriginalData(data);
            toast.success(t('device-profile.success-update-device'));
            setNameIsDisabled(true);
        } catch (err) {
            helpers.setFieldValue('name', originalData.name);
            toast.error(t('device-profile.failed-update-device'));
            console.error(err);
        } finally {
            setIsDisabled(false);
        }
    };

    const editName = () => setNameIsDisabled(!nameIsDisabled);

    const onSubmiting = (isSubmiting: boolean) => setDisabled(isSubmiting || disabled);

    const onChangeMediaspace = (value: string) => setSelectedMediaSpaceId(value);

    const handleClose = () => setOpenModal(false);

    const deleteDevice = async () => {
        await devicesAPI.deleteById(id);
        toast.success(t('devices.success-delete'));
        history.push(generatePath(allRoutes.devices.path, { id: 1 }));
    };

    useEffect(() => {
        hub?.on(HubEvents.onNewScreenshot, onNewScreenshotFromHub);
        return () => {
            hub?.off(HubEvents.onNewScreenshot);
        };
    }, [screenshotLoading]);

    useEffect(() => {
        setOriginalData(event);
        if (event.mediaSpace !== null) {
            setSelectedMediaSpaceId(event.mediaSpace.id);
        }
    }, [event]);

    useEffect(() => {
        setSelectedMediaSpaceId(selectedMediaSpaceId);
    }, [selectedMediaSpaceId]);

    useEffect(() => {
        setTimeout(() => getFilesPage(), 300);
    }, [page]);

    useEffect(() => setDisabled(Number(originalData.status) === DeviceStatusEnum.offline), [originalData.status]);

    useEffect(() => {
        document.addEventListener('click', onClickOuterModal, false);
        return function () {
            document.removeEventListener('click', onClickOuterModal, false);
        };
    }, []);

    // eslint-disable-next-line
    const onClickOuterModal = (event: any) => {
        const modal = document.getElementsByClassName('modal');
        if (modal[0] !== event.target && event.target.nodeName !== 'IMG') {
            setScreenshotInfo(false);
        }
    };

    const normalizedName = playlistFileName
        ? `${playlistName}/${playlistFileName.slice(playlistFileName.lastIndexOf('_') + 1)}`
        : `${orderNumber}/${fileName}`;

    return !isDataLoaded ? (
        <LoadingIndicatorPopup isLoaded={isDataLoaded} />
    ) : (
        <StyledProfile>
            {showScreenshotInfo && (
                <div className="modal" style={screenshotInfoStyle}>
                    {(playlistName || fileName) && <p style={{ margin: '0 auto' }}>{normalizedName}</p>}
                    {!playlistName && !fileName && <p style={{ margin: '0 auto' }}>{t('devices.nothing-plays')}</p>}
                </div>
            )}
            <Formik<DeviceVm>
                initialValues={originalData}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={Yup.object({
                    name: Yup.string()
                        .min(3, t('server-messages.device-name-not-valid'))
                        .max(50, t('server-messages.device-name-not-valid'))
                        .required(t('server-messages.error-save'))
                        .nullable(),
                })}
            >
                {({ values, setFieldValue, submitForm, errors, touched }) => (
                    <FlashContainer>
                        <FormHistoryBlocker formData={values} ref={baseRef} originalData={originalData} />
                        <ModalStyled
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            open={openModal}
                            onClose={handleClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                            data-id="modal"
                        >
                            <Fade in={openModal}>
                                <ImageViewStyled src={screenshotSrc} />
                            </Fade>
                        </ModalStyled>
                        <Header>
                            <ProfileActions
                                deleteContent={t('devices.contentText')}
                                deleteTitle={t('devices.delete-device')}
                                hasDeleteButton={user.role === UserRoles.Admin}
                                onEdit={undefined}
                                onDelete={user.role === UserRoles.Admin ? deleteDevice : undefined}
                            />
                            <HeaderTypography>
                                {t('device-profile.title')}
                                {event.usedMemory > event.availableMemory * 0.8 && (
                                    <Tooltip
                                        title={<GridText className="tooltip">{t('devices.low-memory')}</GridText>}
                                        placement="top-start"
                                        arrow
                                    >
                                        <PaymentAttention />
                                    </Tooltip>
                                )}
                            </HeaderTypography>
                            <DeviceStatusChip status={values.status} />
                        </Header>
                        <DeviceName>
                            <StyledTextInput
                                name="name"
                                value={values.name}
                                disabled={nameIsDisabled}
                                placeholder={t('devices.devicePlaceholder')}
                                onChangeValue={(value: string) => {
                                    setFieldValue('name', value);
                                }}
                                helperText={touched.name && errors.name}
                                error={touched.name && Boolean(errors.name)}
                            />
                            <IconButton onClick={editName} name="edit-button">
                                <CreateIcon />
                            </IconButton>
                        </DeviceName>
                        <DeviceInfo values={values} />
                        <DeviceActions>
                            <VolumeMixer
                                value={values.volumeLevel}
                                max={values.maxVolumeLevel}
                                onSubmit={submitVolume}
                            />
                            <ViewButton
                                disabled={disabled || screenshotLoading}
                                $disabled={isDisabled}
                                onClick={getScreenshot}
                                name="view-button"
                            >
                                <Fade in={screenshotLoading}>
                                    <Box position="absolute" display="flex" alignItems="center">
                                        <CircularProgress size={24} />
                                    </Box>
                                </Fade>
                                <Fade in={!screenshotLoading}>
                                    <Box display="flex" alignItems="center">
                                        <DeviceButtonIcon as={DeviceScreen} />
                                        <ButtonText>{t('device-profile.view')}</ButtonText>
                                    </Box>
                                </Fade>
                            </ViewButton>
                        </DeviceActions>
                        <OrderTableOfContents titles={t('device-profile.media-space')} icon={<SpaceIcon />} />
                        <MediaSpacesSelect onChange={onChangeMediaspace} value={selectedMediaSpaceId} />
                        <OrderTableOfContents titles={t('device-profile.video-description')} icon={<VideoIcon />} />
                        <FileList
                            files={files}
                            page={page}
                            setPage={setPage}
                            totalPage={totalPage}
                            totalItems={totalItems}
                        />
                        {(!isEqual(originalData, values) ||
                            (selectedMediaSpaceId !== '' && selectedMediaSpaceId !== originalData.mediaSpace?.id)) && (
                            <ButtonHolder>
                                <SaveButton
                                    name="save-button"
                                    onSubmit={submitForm}
                                    disabled={isDisabled}
                                    $disabled={isDisabled}
                                    onSubmiting={onSubmiting}
                                >
                                    {isDisabled ? (
                                        <LoadingIndicator isLoaded={false} />
                                    ) : (
                                        <ButtonText>{t('playlist-form.save')}</ButtonText>
                                    )}
                                </SaveButton>
                            </ButtonHolder>
                        )}
                    </FlashContainer>
                )}
            </Formik>
        </StyledProfile>
    );
};

export default DeviceProfile;
