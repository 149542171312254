import { FC, MouseEvent, Dispatch, SetStateAction, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Container, Button, Typography, Link as MuiLink } from '@material-ui/core';
import { Link, generatePath } from 'react-router-dom';

import MenuSectionDesktop from 'components/molecules/menu/MenuSectionDesktop';
import Logo from 'components/atoms/controls/Logo';
import Grow from 'components/atoms/Grow';
import GoToOrdersChatLink from 'components/atoms/controls/GoToOrdersChatLink';
import BurgerIcon from 'components/atoms/Icon/BurgerIcon';

import allRoutes from 'router';

import { UserRoles } from 'shared/enums';

import { StyledAppBar, StyledContainer, CreateOrderButton, Toolbar } from 'components/organisms/menu/AppBar/style';
import { useDispatch } from 'react-redux';
import { deleteMediaSpacesForm, setAllMediaSpaces } from '../../../../store/form/actions';

export interface AppBarProps {
    menuId: string;
    dropDownMenuId: string;
    notificationsCount: number;
    handleNotificationMenuOpen: (event: MouseEvent<HTMLElement>) => void;
    handleProfileMenuOpen: (event: MouseEvent<HTMLElement>) => void;
    photoUrl: string;
    fullName: string;
    role: string;
    setOpenMenu: Dispatch<SetStateAction<boolean>>;
    isAdminOperatorOrder?: boolean;
}

const FlashAppBar: FC<AppBarProps> = ({
    menuId,
    dropDownMenuId,
    notificationsCount,
    handleNotificationMenuOpen,
    handleProfileMenuOpen,
    photoUrl,
    fullName,
    role,
    setOpenMenu,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const ordersPath = useMemo(() => {
        const isAdministration = role === UserRoles.Admin || role === UserRoles.Operator;
        const route = isAdministration ? allRoutes.adminOrders.path : allRoutes.orders.path;
        const path = generatePath(route, { pageId: 1 });

        return path;
    }, [role]);

    const clearRedux = () => {
        dispatch(deleteMediaSpacesForm());
        dispatch(setAllMediaSpaces(false));
    };

    return (
        <StyledAppBar position="fixed">
            <Container>
                <StyledContainer>
                    <Toolbar>
                        <Logo data-id="logo" />
                        <Grow />
                        <Box p={2} className="tools">
                            <MuiLink component={Link} to={ordersPath}>
                                <Typography
                                    variant="body2"
                                    className={
                                        (location.pathname.split('/')[2] === 'admin' && role !== UserRoles.User) ||
                                        (location.pathname.split('/')[1] === 'orders' && role === UserRoles.User)
                                            ? 'link-now'
                                            : 'orders-button'
                                    }
                                >
                                    {t('navigation.orders-history')}
                                </Typography>
                            </MuiLink>
                            <GoToOrdersChatLink data-id="orders-chat" />
                        </Box>
                        <MenuSectionDesktop
                            menuId={menuId}
                            dropDownMenuId={dropDownMenuId}
                            notificationsCount={notificationsCount}
                            handleNotificationMenuOpen={handleNotificationMenuOpen}
                            handleProfileMenuOpen={handleProfileMenuOpen}
                            photoUrl={photoUrl}
                            fullName={fullName}
                        />
                        {role !== UserRoles.Admin && (
                            <CreateOrderButton
                                to={allRoutes.createOrder.path}
                                onClick={clearRedux}
                                id="SEO-create-order-in-header"
                            >
                                <Typography variant="h5" className="create-text">
                                    {t('media-space-form.button-text.create')}
                                </Typography>
                            </CreateOrderButton>
                        )}
                        <Button className="burger-button" onClick={() => setOpenMenu(true)} name="burger">
                            <BurgerIcon />
                        </Button>
                    </Toolbar>
                </StyledContainer>
            </Container>
        </StyledAppBar>
    );
};

export default FlashAppBar;
