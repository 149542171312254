import styled from 'styled-components';
import { variables } from '../../../theme/variables';

export const HomePageImg = styled.div(({}) => ({
    position: 'relative',
    left: '49.19%',
    top: '-74px',
    opacity: 0.3,
    zIndex: -1,
    '@media only screen and (min-width: 1025px) and (max-width: 1366px)': {
        left: '39.19%',
        top: '29.93%',
    },
    '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
        left: '18.19%',
        top: '30.93%',
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        left: '5.19%',
        top: '40.93%',
        img: {
            width: '100%',
        },
    },
}));

export const HomePageHeadline = styled.div(({}) => ({
    marginBottom: '0.938rem',
    '.welcome-title': {
        color: variables.colours.primary,
    },
}));

export const HomePageSpan = styled.div(({}) => ({
    display: 'inline-flex',
    flexWrap: 'wrap',
    marginBottom: '2.5rem',
    ':nth-child(2)': {
        marginBottom: '0',
    },
    '.welcome-text': {
        fontWeight: 400,
    },
    '.welcome-start': {
        paddingRight: '0.625rem',
    },
    '.welcome-order-text': {
        display: 'inline',
        fontWeight: 400,
    },
    '.welcome-company': {
        fontSize: '1.563rem',
        color: variables.colours.primary,
        fontWeight: 400,
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            fontSize: '1.125rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            fontSize: '1rem',
        },
    },
    '.create-text': {
        color: variables.colours.primary,
        textTransform: 'lowercase',
        fontWeight: 400,
    },
    '.text-ads': {
        color: variables.colours.primary,
        fontWeight: 400,
        paddingRight: '0.625rem',
    },
}));

export const HomePageText = styled.div(({}) => ({
    width: '88%',
    position: 'relative',
    margin: '1.725rem 0 0 5%',
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        width: '100%',
        '&.no-orders-text': {
            width: '90%',
        },
    },
}));

export const StyledWelcomePage = styled.div(({}) => ({
    width: '80%',
    margin: '0 0 0 5%',
}));

export const HomePageButton = styled.div(({}) => ({
    '.go-to-button:hover': {
        background: variables.colours.light,
        '.go-to-text': {
            color: variables.colours.primary,
        },
    },
    '.go-to-text': {
        color: variables.colours.light,
    },
    '.contained-create-button': {
        marginTop: '2.5rem',
        marginBottom: '1.25rem',
        ':hover': {
            background: variables.colours.light,
            '.contained-create-text': {
                color: variables.colours.primary,
            },
        },
    },
    '.mobile-create': {
        display: 'none',
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            display: 'inline-flex',
            marginTop: '1rem',
            marginBottom: '1.25rem',
        },
    },
    '.contained-create-text': {
        color: variables.colours.light,
    },
}));
