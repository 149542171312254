import moment from 'moment';
import { DaysOfWeek, Frequency } from '../shared/enums';
import { MediaSpaceShortVm, MediaSpaceVm } from './media-space.model';
import { PagingVmBaseResponse } from './paging/paging.model';

export type PriceNamesInputModel = {
    title: string;
    description?: string;
    languageId?: string;
};

export type PriceCostInputModel = {
    currencyId: string;
    currencyCode: string;
    cost: number;
};

export type PriceInputModel = {
    names: Array<PriceNamesInputModel>;
    description: string;
    cost: number;
    startDate: Date;
    endDate: Date | null;
    endsOn?: Date | null;
    timeStart: string;
    timeEnd: string;
    isForAllMediaSpaces: boolean;
    isAllDay: boolean;
    isDefault: boolean;
    color: number;
    frequency: Frequency;
    daysOfWeek?: Array<DaysOfWeek>;
    mediaSpaceUids: Array<string>;
    priceCost: Array<PriceCostInputModel>;
};

export type FullCalendarModel = {
    title: string;
    description: string;
    cost: number;
    startDate: string;
    endDate: string;
    isForAllMediaSpaces: boolean;
    timeStart: string;
    timeEnd: string;
    color: number;
    frequency: Frequency;
    daysOfWeek?: Array<DaysOfWeek>;
    mediaSpaceUids: Array<string>;
};

export type PricesVm = {
    names: PriceNamesInputModel[];
    id: string;
    title: string;
    description: string;
    cost: number;
    startDate: string;
    endDate: string;
    endsOn?: string | null;
    isForAllMediaSpaces: boolean;
    isAllDay: boolean;
    isDefault: boolean;
    timeStart: string;
    timeEnd: string;
    color: number;
    daysOfWeek?: Array<DaysOfWeek>;
    frequency: Frequency;
    mediaSpaces: Array<MediaSpaceVm>;
    priceCost: Array<CurrencyVm>;
    isDeleted: boolean;
    createdDate: string;
    updatedDate: string;
};

export type CurrencyVm = {
    currencyId: string;
    currencyCode: string;
    cost: number;
    currencyIsDefault: boolean;
};

export const defaultPriceModel: PricesVm = {
    names: [],
    id: '',
    title: '',
    description: '',
    cost: 0.6,
    isAllDay: true,
    isForAllMediaSpaces: true,
    isDefault: false,
    frequency: 0,
    endsOn: '',
    color: 1,
    timeStart: '',
    timeEnd: '',
    startDate: '',
    endDate: '',
    mediaSpaces: [],
    priceCost: [],
    isDeleted: false,
    createdDate: '',
    updatedDate: '',
};

export const DefaultPriceInput: PriceInputModel = {
    names: [],
    description: '',
    cost: 0,
    startDate: new Date(),
    endDate: null,
    endsOn: null,
    isForAllMediaSpaces: false,
    isAllDay: false,
    isDefault: false,
    timeStart: moment('0').format('HH:00'),
    timeEnd: moment('0').format('HH:00'),
    color: 0,
    frequency: Frequency.None,
    mediaSpaceUids: [],
    priceCost: [],
};

export type PricesPagedRequest = {
    page: number;
    pageSize: number;
    property?: string;
    direction?: number | null;
    filterString?: string | null;
    startDate: Date | null;
    endDate?: Date | null;
    mediaSpaceIds: string[];
    status?: PriceSearchStatus;
};

export enum PriceSearchStatus {
    Any,
    InactiveOnly,
    ActiveOnly,
}

export type PagedPriceVmResponse = PagingVmBaseResponse<PriceGridVm>;

export type MediaSpaceNamesModel = {
    title: string;
    description: string;
    languageId: string;
    languageCode: string;
};

export type PriceGridVm = PriceBaseVm & {
    names: Array<MediaSpaceNamesModel>;
    mediaSpaces: Array<MediaSpaceShortVm>;
};

export type PriceBaseVm = PriceModel & {
    id: string;
};

export type PriceModel = {
    name: string;
    startDate: Date;
    endDate?: Date;
    endsOn?: Date;
    isInfinite: boolean;
    isForAllMediaSpaces: boolean;
    mediaSpaceIds: Array<string>;
    priceFiles: Array<PriceFileModel>;
};

export type PriceFileModel = {
    id: string;
    name: string;
    type: string;
    duration: number;
    indexNumber: number;
    width: number;
    height: number;
};
