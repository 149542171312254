import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import allRoutes from 'router';

import { StyledLogo } from 'components/atoms/controls/Logo/style';

import logoImage from 'assets/images/logo.png';
import { getConfig } from 'config';

type LogoProps = {
    className?: string;
};

export default function Logo({ className }: LogoProps): JSX.Element {
    const { t, i18n } = useTranslation();

    const [phone, setPhone] = useState<string | null>(null);
    const [address, setAddress] = useState<string | null>(null);

    useEffect(() => {
        const loadConfig = async () => {
            const data = await getConfig();
            setAddress(i18n.language == 'en' ? data.contactsAddressEn : data.contactsAddressRu);
            setPhone(data.contactsPhone);
        };

        loadConfig().then();
    }, []);

    return (
        <span itemScope itemType="http://schema.org/Organization" className={className}>
            <meta itemProp="name" content={t('general.company-name')} />
            <meta itemProp="address" content={address ? address : t('general.company-city')} />
            <meta itemProp="telephone" content={phone ? phone : t('general.company-phone')} />
            <meta itemProp="image" content={logoImage} />
            <Link itemProp="url" href={allRoutes.home.path} to={allRoutes.home.path}>
                <span itemProp="logo" itemScope itemType="https://schema.org/ImageObject">
                    <StyledLogo
                        className="logo"
                        src={logoImage}
                        itemProp="url image"
                        width="270"
                        height="97"
                        alt="Flash Media"
                        title="Flash Media"
                    />
                </span>
            </Link>
        </span>
    );
}
