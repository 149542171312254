import React, { ChangeEvent, useCallback, useEffect, useState, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { CalculateCostSlider, CalculateCostStyled, CalculateCostButtoneStyled, CalculateCostSelectIcon } from './style';
import MediaDurationInput from 'components/atoms/Order/MediaDurationInput';
import { useTranslation } from 'react-i18next';
import { getDefault } from 'api/price';
import MediaSpacesMultiSelect from 'components/molecules/forms/MediaSpacesMultiSelect';
import FilterOption from 'components/atoms/FilterOption';
import { MediaSpaceMapVm } from 'models/media-space.model';
import { useHistory } from 'react-router-dom';
import { RootState } from 'store/rootReducer';
import { useSelector } from 'react-redux';
import { defaultPriceModel, PricesVm } from 'models/price.model';

type CalculateCostProps = {
    minQuantity?: number;
    maxQuantity?: number;
    mediaSpaces: MediaSpaceMapVm[];
};

export default function CalculateCost({
    minQuantity = 1,
    maxQuantity = 100,
    mediaSpaces,
}: CalculateCostProps): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const user = useSelector((state: RootState) => state.userReducer.user);

    const [tariff, setTariff] = useState<PricesVm>(defaultPriceModel);
    const [isLoadingTriggered, setIsLoadingTriggered] = useState(false);
    const [isLoadingMediaSpaces, setIsLoadingMediaSpaces] = useState(false);
    const [quantity, setQuantity] = useState<number>(30);
    const [duration, setDuration] = useState<number>(10);
    const [currencyCode, setCurrencyCode] = useState<string>('');

    const [selectedMediaSpaces, setSelectedMediaSpaces] = useState<string[]>([]);

    // eslint-disable-next-line @typescript-eslint/ban-types
    const handleSliderChange = useCallback((e: ChangeEvent<{}>, quantityValue: number | number[]) => {
        setQuantity(quantityValue as number);
    }, []);

    const handleChangeDuration = useCallback((duration) => setDuration(duration), [setDuration]);

    const handleRedirectBtn = () => {
        if (!user.role) {
            history.push('/auth/signin');
        } else {
            history.push('/orders/create');
        }
    };
    const countOrderPrice = () => {
        return Number((selectedMediaSpaces.length * duration * quantity * tariff.cost).toFixed(3));
    };

    const onMediaSpacesChange = (selectedMediaSpaces: string[]) => {
        setSelectedMediaSpaces(selectedMediaSpaces);
    };

    const setAllSelected = () => {
        selectedMediaSpaces.length === mediaSpaces.length
            ? setSelectedMediaSpaces([])
            : setSelectedMediaSpaces(mediaSpaces.map((mediaspace) => mediaspace.id));
    };

    const totalPrice = useMemo(() => {
        return countOrderPrice();
    }, [quantity, duration, selectedMediaSpaces, tariff]);

    async function fetchData() {
        const response = await getDefault();
        try {
            let code = '';
            if (user.preferred_currency_id === '') {
                code = response.data.priceCost.find((p) => p.currencyIsDefault)?.currencyCode ?? '';
            } else {
                code =
                    response.data.priceCost.find((p) => p.currencyId === user.preferred_currency_id)?.currencyCode ??
                    '';
            }
            setCurrencyCode(code);

            const priceCost = response.data.priceCost.find((p) => p.currencyCode === code);
            if (priceCost === undefined) {
                response.data.cost = 0;
            } else {
                response.data.cost = priceCost.cost;
            }

            setTariff(response.data);
        } catch (e) {
            console.log('ERROR: Bad response from priceGroup/default');
        }
    }

    useEffect(() => {
        if (!isLoadingTriggered) {
            setIsLoadingTriggered(true);
            setTimeout(() => fetchData(), 300);
        }
    }, [isLoadingTriggered]);

    useMemo(() => {
        fetchData();

        mediaSpaces.forEach((x) => {
            x.name = x.names.find((n) => n.languageCode === user.lc)?.name || '';
            x.address = x.names.find((n) => n.languageCode === user.lc)?.address || '';
        });
    }, [user.lc, user.preferred_currency_id]);

    useEffect(() => {
        if (!isLoadingMediaSpaces && mediaSpaces.length > 0) {
            setIsLoadingMediaSpaces(true);
            setSelectedMediaSpaces(mediaSpaces.map((mediaspace) => mediaspace.id));
        }
    }, [mediaSpaces]);

    return (
        <CalculateCostStyled>
            <div className="top">
                <div className="column column-1">
                    <div className="top-title">
                        <Typography>{t('home-page.calculate-cost.fitrs-column-title')}</Typography>
                    </div>
                    <MediaDurationInput
                        className="duration"
                        value={duration}
                        min={1}
                        max={60}
                        onChange={handleChangeDuration}
                    />
                </div>
                <div className="column column-2">
                    <div className="top-title">
                        <Typography>{t('home-page.calculate-cost.second-column-title')}</Typography>
                    </div>
                    <div className="select">
                        <MediaSpacesMultiSelect
                            iconComponent={CalculateCostSelectIcon}
                            displayField="name"
                            controlsDirection="column"
                            onChange={onMediaSpacesChange}
                            value={selectedMediaSpaces}
                            options={mediaSpaces}
                        />
                    </div>
                    <FilterOption
                        text={t('order-form.button-text.select-all')}
                        checked={selectedMediaSpaces.length === mediaSpaces.length}
                        onClick={setAllSelected}
                    />
                </div>
                <div className="column column-3">
                    <div className="top-title">
                        <Typography className="quantity-title">
                            {t('home-page.calculate-cost.third-column-title')}
                        </Typography>
                        <Typography className="quantity">{quantity}</Typography>
                    </div>
                    <CalculateCostSlider
                        min={minQuantity}
                        max={maxQuantity}
                        value={typeof quantity === 'number' ? quantity : 0}
                        onChange={handleSliderChange}
                    />
                </div>
            </div>
            <div className="middle">
                <Typography className="middle-title">{t('home-page.calculate-cost.content-title')}</Typography>
                <div className="total">
                    <div className="value">{totalPrice}</div>
                    <div className="currency">{currencyCode.toUpperCase()}</div>
                </div>
                <CalculateCostButtoneStyled onClick={handleRedirectBtn} id="SEO-create-order-in-home-page-calculate">
                    <Typography variant="h5" className="button-text">
                        {t('home-page.calculate-cost.content-button')}
                    </Typography>
                </CalculateCostButtoneStyled>
            </div>
        </CalculateCostStyled>
    );
}
