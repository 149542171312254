import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const FormControlTextInputStyle = styled.div(({}) => ({
    display: '-webkit-box',
    marginRight: '5px',
    '.MuiFormControl-root:not(:first-child)': {
        marginLeft: '1.25rem',
    },
    '.MuiFormControl-root': {
        '@media only screen and (min-width : 320px) and (max-width : 769px)': {
            width: '100%',
        },
    },
    '.MuiInputBase-root': {
        background: variables.backgoroundColours.light,
        width: '15.563rem',
        '@media only screen and (min-width : 768px) and (max-width : 1023px)': {
            width: '14.563rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '100%',
        },
        height: '2.688rem',
        boxSizing: 'border-box',
        borderRadius: '4px',
    },
    '.MuiOutlinedInput-input': {
        color: variables.colours.default,
        textAlign: 'center',
        padding: '0.625rem',
    },
    '.MuiSelect-selectMenu': {
        display: 'flex',
    },
}));

export const RoleColor = styled.div((props: { color: string }) => ({
    width: '20px',
    height: '20px',
    marginRight: '0.563rem',
    background: props.color,
    borderRadius: '4px',
}));
