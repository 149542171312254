import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cost, CostSumStyle, Total } from './style';

export default function PlaybackSummary(props: { expectedCount: number; realCount: number }): JSX.Element {
    const { expectedCount, realCount } = props;
    const { t } = useTranslation();

    return (
        <>
            <CostSumStyle>
                <Total>
                    <Typography variant="body2">{t('playbackStatistic.expected-count')}</Typography>
                </Total>
                <Cost>
                    <Typography variant="body2">{expectedCount}</Typography>
                </Cost>
            </CostSumStyle>
            <CostSumStyle>
                <Total>
                    <Typography variant="body2">{t('playbackStatistic.real-count')}</Typography>
                </Total>
                <Cost>
                    <Typography variant="body2">{realCount}</Typography>
                </Cost>
            </CostSumStyle>
        </>
    );
}
