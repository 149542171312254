import React, { useEffect, useState } from 'react';
import { OrderStatusType } from '../../../../shared/enums';
import ResponsiveFlexBox from '../../ResponsiveFlexBox';
import FilterInput from '../../FilterInput';
import DateFilter, { DateFilterData } from '../DateFilter';
import { FormControlTextInputStyle, FilterFlexBox } from '../FiltersHolder/style';
import OrderStatusSelect from '../OrderStatusSelect';
import FlashDialog from '../../dialog/FlashDialog';
import { Box, Button, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FlashDatePicker from '../../FlashDatePicker';
import { getOrderReportSrc } from '../../../../api/orders';
import moment from 'moment';

export type OrderFilterData = DateFilterData & {
    filterString: string;
    status: OrderStatusType;
};

export type OrderFilterProps = {
    onChange: (data: OrderFilterData) => void;
    isAvailableGenerateReportButton?: boolean;
    isAvailableFilterByDate?: boolean;
    isAvailableFilterByString?: boolean;
    isAvailableFilterByStatus?: boolean;
};

export const DefaultFilterData = {
    startDate: null,
    endDate: null,
    filterString: '',
    status: OrderStatusType.All,
} as OrderFilterData;

export default function OrderFilter(props: OrderFilterProps): JSX.Element {
    const { t } = useTranslation();
    const {
        onChange,
        isAvailableGenerateReportButton = true,
        isAvailableFilterByDate = true,
        isAvailableFilterByString = true,
        isAvailableFilterByStatus = true,
    } = props;

    const [startDate, setStartDate] = useState<Date | null>(new Date(new Date().setMonth(new Date().getMonth() - 1)));
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [filter, setFilter] = useState<OrderFilterData>(DefaultFilterData);
    const [openModalMode, setOpenModalMode] = useState<boolean>(false);

    const handleGenerateReport = () => {
        if (startDate !== null && endDate !== null) {
            handleModalMode(false);
            window.open(
                getOrderReportSrc(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'), false),
            );
        }
    };

    const handleModalMode = (open: boolean) => {
        setOpenModalMode(open);
    };

    useEffect(() => {
        onChange && onChange(filter);
    }, [filter]);

    return (
        <ResponsiveFlexBox
            width="100%"
            minHeight="2.813rem"
            justifyContent="space-between"
            className="filter-handler"
            gridRowGap={10}
        >
            <FilterFlexBox>
                {isAvailableFilterByString && (
                    <FormControlTextInputStyle>
                        <FilterInput
                            onChange={(value: string) => {
                                setFilter({ ...filter, filterString: value });
                            }}
                        />
                    </FormControlTextInputStyle>
                )}
                {isAvailableFilterByStatus && (
                    <FormControlTextInputStyle>
                        <OrderStatusSelect
                            onStatusChanged={(value: OrderStatusType) => {
                                setFilter({ ...filter, status: value });
                            }}
                        />
                    </FormControlTextInputStyle>
                )}
            </FilterFlexBox>
            <ResponsiveFlexBox gridGap={10}>
                {isAvailableGenerateReportButton && (
                    <Button variant="outlined" color="primary" onClick={() => handleModalMode(true)}>
                        <Typography variant="h5" color="inherit">
                            {t('orders.create-report')}
                        </Typography>
                    </Button>
                )}
                {isAvailableFilterByDate && (
                    <>
                        <FlashDialog
                            open={openModalMode}
                            isOpen={openModalMode}
                            onClose={() => handleModalMode(false)}
                            title={t('orders.create-report')}
                            content={
                                <ResponsiveFlexBox justifyContent="space-between">
                                    <Box>
                                        <Typography variant="h6">{t('orders.startDate')}</Typography>
                                        <FlashDatePicker
                                            data-id="start-date"
                                            maxDate={endDate}
                                            label={t('orders.startDate')}
                                            selected={startDate}
                                            onChange={(date: Date | [Date, Date] | null) => setStartDate(date as Date)}
                                            icon
                                        />
                                    </Box>
                                    <Box>
                                        <Typography variant="h6">{t('orders.endDate')}</Typography>
                                        <FlashDatePicker
                                            data-id="end-date"
                                            minDate={startDate}
                                            label={t('orders.endDate')}
                                            selected={endDate}
                                            onChange={(date: Date | [Date, Date] | null) => setEndDate(date as Date)}
                                            icon
                                        />
                                    </Box>
                                </ResponsiveFlexBox>
                            }
                            controls={
                                <Button variant="outlined" color="primary" onClick={handleGenerateReport}>
                                    <Typography variant="h5" color="inherit">
                                        {t('orders.generate-report')}
                                    </Typography>
                                </Button>
                            }
                        />
                        <DateFilter
                            onFilterChanged={(data: DateFilterData) => {
                                setFilter({ ...filter, startDate: data.startDate, endDate: data.endDate });
                            }}
                        />
                    </>
                )}
            </ResponsiveFlexBox>
        </ResponsiveFlexBox>
    );
}
