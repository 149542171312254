import styled from 'styled-components';

import { variables } from 'theme/variables';

export const StyledUserList = styled.div(({}) => ({
    '.user-name': {
        color: variables.colours.light,
    },
    '.MuiDataGrid-cell': {
        ':first-child': {
            justifyContent: 'start',
            paddingLeft: '0.719rem',
        },
        ':nth-child(2)': {
            a: {
                display: 'flex',
                justifyContent: 'flex-start',
            },
            p: {
                textAlign: 'left',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        ':nth-child(3)': {
            a: {
                justifyContent: 'center',
            },
            textAlign: 'center',
        },
        ':nth-child(4)': {
            padding: '0 10px',
            a: {
                display: 'block',
                color: variables.colours.dark,
                justifyContent: 'start',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
            '.lockout-enabled': {
                color: variables.colours.default,
            },
            p: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
        ':nth-child(5)': {
            padding: '0 10px',
            a: {
                display: 'block',
                justifyContent: 'start',
                color: variables.colours.dark,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
            '.lockout-enabled': {
                color: variables.colours.default,
            },
        },
        ':nth-child(6)': {
            fill: variables.colours.primary,
            '.lockout-enabled': {
                path: {
                    fill: variables.colours.default,
                },
            },
        },
    },
}));

export const CheckboxStyle = styled.div(({}) => ({
    '.enabled-checkbox': {
        div: {
            border: `1px solid ${variables.colours.main}`,
        },
    },
}));
