import { FC, useState, useEffect, useCallback, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
    StyledVolumeMixer,
    VolumeMixerIcon,
    VolumeSlider,
    Submit,
    SubmitSection,
} from 'components/atoms/VolumeMixer/style';

import { ReactComponent as VolumeIcon } from 'assets/icons/Volume.svg';
import { Typography } from '@material-ui/core';

interface VolumeMixerProps {
    value: number;
    max: number;
    onSubmit: (value: number) => void;
}

const VolumeMixer: FC<VolumeMixerProps> = ({ value, max, onSubmit }) => {
    const { t } = useTranslation();

    const [volume, setVolume] = useState(0);

    const handleSubmit = useCallback(() => {
        onSubmit(volume);
    }, [volume]);

    // eslint-disable-next-line @typescript-eslint/ban-types
    const handleChange = useCallback((e: ChangeEvent<{}>, volumeValue: number | number[]) => {
        setVolume(volumeValue as number);
    }, []);

    useEffect(() => {
        setVolume(value);
    }, [value]);

    const renderVolume = () => Math.floor((volume / max) * 100).toString() + '%';

    return (
        <StyledVolumeMixer>
            <VolumeMixerIcon as={VolumeIcon} />
            <VolumeSlider orientation="horizontal" value={volume} max={max} onChange={handleChange} step={1} />
            <SubmitSection>
                <Submit onClick={handleSubmit} disabled={volume === value}>
                    {t('devices.volume-apply')}
                </Submit>
                <Typography>{renderVolume()}</Typography>
            </SubmitSection>
        </StyledVolumeMixer>
    );
};

export default VolumeMixer;
