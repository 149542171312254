import * as React from 'react';
import { Typography, AccordionSummary, AccordionDetails, AccordionProps } from '@material-ui/core';
import { FlashAccordionStyled, FlashAccordionIcon } from './style';

export type FlashButtonProps = AccordionProps & {
    title: React.ReactNode;
    children: React.ReactNode;
    id: string;
};

export default function FlashAccordion({ title, children, id, ...props }: FlashButtonProps): JSX.Element {
    return (
        <FlashAccordionStyled {...props} itemProp="mainEntity" itemScope itemType="https://schema.org/Question">
            <AccordionSummary
                className="accordion-header"
                expandIcon={<FlashAccordionIcon />}
                aria-controls={id}
                id={id}
            >
                <Typography variant="h4" className="accordion-header-text" itemProp="name">
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                className="accordion-content"
                itemProp="acceptedAnswer"
                itemScope
                itemType="http://schema.org/Answer"
            >
                <Typography className="accordion-content-text" itemProp="text">
                    {children}
                </Typography>
            </AccordionDetails>
        </FlashAccordionStyled>
    );
}
