import { CSSProperties } from 'react';

import styled from 'styled-components';

import { variables } from '../../../../theme/variables';
import { Dialog } from '@material-ui/core';

interface DialogStyleProps {
    width: CSSProperties['width'];
    position: CSSProperties['position'];
}

export const DialogStyle = styled(Dialog)<DialogStyleProps>(({ width, position }) => ({
    '.MuiDialog-paperWidthSm': {
        position: position ? position : 'initial',
        background: variables.backgoroundColours.light,
        boxShadow: '0rem 0rem 0.875rem 0.188rem rgba(0, 0, 0, 0.15)',
        width: 'fit-content',
        maxWidth: width,
        minWidth: 400,
        minHeight: '15rem',
        height: 'auto',
        maxHeight: '70vh',

        '@media only screen and (max-width: 480px)': {
            minWidth: 'calc(100% - 10px)',
        },

        '.MuiDialogTitle-root': {
            background: variables.colours.primary,
            padding: '16px 20px',
            '&.title h2': {
                color: variables.colours.light,
                fontVariant: 'none',
                fontWeight: 'bold',
                marginRight: '3rem',
                display: 'inline-block',
            },

            '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                height: '4.063rem',
                paddingTop: '1.363rem',
            },

            '@media only screen and (min-width : 320px) and (max-width : 767px)': {
                height: 'fit-content',
                paddingTop: '1.263rem',
            },
        },

        '.content': {
            padding: 20,
            p: {
                color: variables.colours.dark,
            },
        },

        '.MuiDialogActions-root': {
            justifyContent: 'stretch',
            padding: '10px 20px',
            paddingBottom: 20,
        },
    },
}));
