import * as React from 'react';
import ConfirmEmailForm from '../../organisms/forms/ConfirmEmail';
import AuthTemplate from '../Auth';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

export default function ConfirmEmailTemplate(): JSX.Element {
    const { t } = useTranslation();

    return (
        <AuthTemplate>
            <Typography variant="h1" color="secondary">
                {t('confirm-email-form.header')}
            </Typography>
            <Typography variant="body1" color="secondary" className="confirm-text">
                {t('confirm-email-form.subheader')}
            </Typography>
            <Typography variant="body1" color="secondary">
                {t('confirm-email-form.subheader-second')}
            </Typography>
            <ConfirmEmailForm></ConfirmEmailForm>
        </AuthTemplate>
    );
}
