import styled from 'styled-components';

import { Box } from '@material-ui/core';

import SubmitButton from 'components/atoms/controls/SubmitButton';

export const UserFormStyle = styled.div({
    position: 'relative',
    display: 'block',
    width: '43.125rem',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 30,

    '.MuiBadge-badge': {
        height: '28px',
        width: '28px',
        borderRadius: '28px',
        '.MuiButton-root': { borderRadius: '28px', minWidth: '28px' },
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        width: '100%',
    },
});

export const StyledBox = styled(Box).attrs((props) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: props.maxWidth > 374 ? 'row' : 'column',
}))({});

export const OptionButton = styled(SubmitButton)({
    '@media only screen and (min-width: 320px) and (max-width: 374px)': {
        width: '8.938rem',
        marginBottom: 0,
        marginLeft: '25%',
    },
});
