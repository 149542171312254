import { GradientFontStyled } from './style';

type GradientFontProps = {
    text: string;
    fontSize: string;
    className?: string;
};

export default function GradientFont({ fontSize, text, className }: GradientFontProps): JSX.Element {
    return (
        <GradientFontStyled className={className} style={{ fontSize }}>
            {text}
        </GradientFontStyled>
    );
}
