export const UPDATE_USER = `UPDATE_USER`;
export const REMOVE_USER = `REMOVE_USER`;
export const UPDATE_LC = `UPDATE_LC`;

export interface User {
    nameid: string;
    role: string;
    given_name: string;
    family_name: string;
    email: string;
    lc: string;
    pic: string;
    hasSignedInOnce: boolean;
    preferred_currency_id: string;
}
export interface UserState {
    user: User;
}
interface UpdateUserAction {
    type: typeof UPDATE_USER;
    data: User;
}
interface RemoveUserAction {
    type: typeof REMOVE_USER;
}

interface UpdateLcAction {
    type: typeof UPDATE_LC;
    data: string;
}

export type UserActionTypes = UpdateUserAction | RemoveUserAction | UpdateLcAction; // | other actions
