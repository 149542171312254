import styled from 'styled-components';

import { Form } from 'formik';
import { variables } from 'theme/variables';

export const StyledMediaSubForm = styled(Form)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '.continue-button': {
        border: `2px solid ${variables.colours.primary}`,
        h5: {
            color: variables.colours.light,
        },
        ':hover': {
            h5: {
                color: variables.colours.primary,
            },
        },
    },
});
