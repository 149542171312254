import React from 'react';
import { ContainerWithTitleStyled, TeaserStyled, TitleStyled } from './style';

type ContainerWithTitleProps = {
    title?: string;
    variant?: string;
    teaser?: string;
    marginBottom?: string;
    children: React.ReactNode;
};

export default function ContainerWithTitle({
    title,
    variant = 'h2',
    teaser,
    marginBottom = '140px',
    children,
}: ContainerWithTitleProps): JSX.Element {
    return (
        <ContainerWithTitleStyled marginBottom={marginBottom}>
            {title ? <TitleStyled variant={variant}>{title}</TitleStyled> : ''}
            {teaser ? <TeaserStyled>{teaser}</TeaserStyled> : ''}
            {children}
        </ContainerWithTitleStyled>
    );
}
