import styled from 'styled-components';

import { Button, Typography } from '@material-ui/core';

import { SubmitButtonProps } from 'components/atoms/controls/SubmitButton';

import { variables } from 'theme/variables';

export const OptionButtonText = styled(Typography).attrs({
    variant: 'h5',
})({});

export const OptionButton = styled(Button).attrs<SubmitButtonProps>(({ disabled }) => ({
    color: disabled ? 'default' : 'primary',
    variant: 'outlined',
    type: 'submit',
}))({
    marginTop: '3.125rem',
    marginBottom: '1.25rem',
    border: `2px solid ${variables.colours.primary}`,

    [OptionButtonText]: {
        color: variables.colours.light,
    },

    ':hover': {
        border: `2px solid ${variables.colours.primary}`,
        [OptionButtonText]: {
            color: variables.colours.primary,
        },
    },
});
