import _ from 'lodash';
import * as React from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { IHistoryState } from '../../../../models/interface/i-form-data';
import FlashDialog from '../../../atoms/dialog/FlashDialog';
import TextContent from '../../../atoms/dialog/TextContent';
import YesNoButtons from '../../../atoms/dialog/YesNoButtons';

export type BaseFormProps = {
    leaveMessage?: string;
    formData: unknown;
    originalData: unknown;
};

type CallbackContainer = {
    unblock: () => void;
};

const FormHistoryBlocker = forwardRef(function BaseForm(props: BaseFormProps, ref): JSX.Element {
    const { leaveMessage, formData, originalData } = props;

    const history = useHistory();
    const { t } = useTranslation();

    const [isFormInitialized, setIsFormInitialized] = useState(false);
    const [isReleased, setIsReleased] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [currentData, setFormData] = useState<unknown>();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [stateToRedirect, setStateToRedirect] = useState<IHistoryState>();
    const [callback, setUnlockCallback] = useState<CallbackContainer>();
    const [dialogBody, setDialogBody] = useState<string>('general.leave-active-form-dialog-message');

    const stateRef = useRef<{ currentData: unknown | undefined; isReleased: boolean; isChanged: boolean }>();
    stateRef.current = { currentData: currentData, isReleased: isReleased, isChanged: isChanged };

    useImperativeHandle(ref, () => ({
        release() {
            setIsReleased(true);
        },
        block() {
            setIsReleased(false);
        },
    }));

    useEffect(() => {
        setFormData(formData);

        if (!isFormInitialized && isChanged === undefined) {
            setTimeout(() => {
                setFormData(!isChanged);
            });
            setIsChanged(false);
            setIsFormInitialized(true);
        } else {
            const originalDataClone = _.clone(originalData);
            const previousDataClone = _.clone(formData);

            const isEqualWithPrevious = _.isEqual(originalDataClone, previousDataClone);

            setIsChanged(!isEqualWithPrevious);
        }
    }, [formData, originalData]);

    useEffect(() => {
        if (leaveMessage) {
            setDialogBody(leaveMessage);
        }
    }, [leaveMessage]);

    const unblockHistory = () => {
        callback && callback.unblock();
    };

    useEffect(() => {
        const unblock = history.block((state: IHistoryState) => {
            setStateToRedirect(state);

            const currentState = stateRef.current;
            const isChanged = currentState?.isChanged;
            const isReleased = currentState?.isReleased;

            const isBlocked = !isReleased && currentState !== undefined && isChanged !== undefined && isChanged;
            setShowConfirmDialog(isBlocked);
            if (isBlocked) {
                return false;
            } else {
                unblockHistory();
            }
        });

        setUnlockCallback({ unblock: unblock });
        return () => {
            unblockHistory();
        };
    }, []);

    const handleConfirm = () => {
        if (stateToRedirect) {
            unblockHistory();
            history.push(stateToRedirect.pathname, stateToRedirect.state);
        }
    };

    return (
        <FlashDialog
            open={showConfirmDialog}
            isOpen={showConfirmDialog}
            onClose={() => setShowConfirmDialog(false)}
            title={t('general.leave-active-form-dialog-title')}
            controls={<YesNoButtons onConfirm={handleConfirm} onReject={() => setShowConfirmDialog(false)} />}
            content={<TextContent text={t(dialogBody)} />}
        />
    );
});

export default FormHistoryBlocker;
