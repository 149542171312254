import { useCallback, useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { Box } from '@material-ui/core';
import { GridColDef, GridRowParams, GridSortModel } from '@material-ui/data-grid';

import useSelector from 'hooks/useAppSelector';

import allRoutes from 'router';

import NoDataMessage from 'components/atoms/NoDataMessage';
import FlashDialog from 'components/atoms/dialog/FlashDialog';
import TextContent from 'components/atoms/dialog/TextContent';
import YesNoButtons from 'components/atoms/dialog/YesNoButtons';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import LoadingIndicatorPopup from 'components/atoms/LoadingIndicatorPopup';
import MediaSpacesFilter from 'components/atoms/gridFilters/MediaSpacesFilter';
import PagedDataGrid from 'components/organisms/PagedDataGrid';
import FlashMap, { MapPlacemark } from 'components/organisms/FlashMap';
import FilteredTemplate from 'components/templates/FilteredTemplate';
import MediaSpacesColumnsGrid from 'components/pages/MediaSpaces/ColumnsGrid';

import { deleteById, mediaSpacesAPI } from 'api/media-space';
import { PageSize } from 'shared/constants';
import { UserRoles, ValidationRules } from 'shared/enums';
import { MediaSpacePage, MediaSpaceVm, MediaSpaceFilterRule, MediaSpaceValidityStatus } from 'models/media-space.model';

import { StyledList } from 'components/pages/MediaSpaces/style';
import { getDateWithLocalTz } from 'hooks/getDateWithLocalTz';
import { getCurrentDate } from 'hooks/getCurrentDate';
import { getSortingParams } from '../../../utils/sortingHelper';
import { SortDirections, SortField, SortModel } from '../../../models/paging/paging.model';

export default function MediaSpaces(): JSX.Element {
    const defaultSort: SortModel = {
        property: SortField.CreatedDate,
        direction: SortDirections.Desc,
    };

    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();

    const history = useHistory();
    const user = useSelector((state) => state.userReducer.user);

    const [mediaSpaces, setMediaSpaces] = useState<MediaSpaceVm[]>([]);
    const [mediaSpaceMapPlacemark, setMediaSpaceMapPlacemark] = useState<MapPlacemark[]>([]);
    const [loading, setLoading] = useState(false);
    const [tableMap, setTableMap] = useState(true);
    const [page, setPage] = useState<number>(1);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [valFilter, setFilter] = useState<string>('');
    const [open, setOpen] = useState(false);
    const [mediaSpaceId, setMediaSpaceId] = useState<string>('');
    const [warningsOnly, setWarningsOnly] = useState<boolean>(false);
    const [invalid, setInvalid] = useState(false);
    const [sort, setSort] = useState<SortModel>(defaultSort);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);

    const columns: GridColDef[] = MediaSpacesColumnsGrid({
        mediaSpaces,
        setMediaSpaceId,
        setOpen,
    });

    const onDeleteFunction = async () => {
        await deleteById(mediaSpaceId);
        toast.success(t('media-space-form.messages.success-delete'));
        await loadData();
        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getReq = () => {
        const req: MediaSpacePage = {
            page: page,
            property: sort?.property,
            direction: sort?.direction,
            filterString: valFilter,
            pageSize: PageSize,
            filterRule: warningsOnly ? MediaSpaceFilterRule.OnlyInvalid : MediaSpaceFilterRule.All,
        };
        return req;
    };

    const loadData = useCallback(async () => {
        if (tableMap) {
            const req = getReq();
            await getMediaSpaces(req);
        } else {
            await getMediaSpacesMap();
        }
    }, [page, sort.property, sort.direction, PageSize, valFilter, warningsOnly, tableMap]);

    const getMediaSpaces = async (req: MediaSpacePage) => {
        const res = await mediaSpacesAPI.getValidityPage(MediaSpaceValidityStatus.Valid, req);
        const totalPageNumber = Math.ceil(res.total / res.pageSize);
        const mediaSpaces: MediaSpaceVm[] = res.data.map((element) => ({
            names: element.names,
            id: element.id,
            name: element.name,
            address: element.address,
            longitude: element.longitude,
            latitude: element.latitude,
            comment: element.comment,
            tariffsValidityStatus: element.tariffsValidityStatus,
            tariffsValidityReason: element.tariffsValidityReason,
            devices: element.devices,
            isActive: element.isActive,
            timeZoneId: element.timeZoneId,
        }));

        setMediaSpaces(mediaSpaces);
        setTotalPage(totalPageNumber);
        setTotalItems(res.total);
        setMediaSpaceMapPlacemark([]);
    };

    const getMediaSpacesMap = async () => {
        const res = await mediaSpacesAPI.getMediaSpacesMap(
            warningsOnly ? MediaSpaceFilterRule.OnlyInvalid : MediaSpaceFilterRule.All,
        );
        const mediaSpacesMap: MapPlacemark[] = res.data.map((element) => ({
            id: element.id,
            isHighlighted: false,
            lat: element.latitude,
            long: element.longitude,
            name: element.name,
            address: element.address,
        }));

        setMediaSpaceMapPlacemark(mediaSpacesMap);
        setMediaSpaces([]);
    };

    useEffect(() => {
        setLoading(true);
        (async () => {
            await loadData();
            setLoading(false);
        })();
    }, [page, user.lc, sort, valFilter, warningsOnly, tableMap]);

    useEffect(() => {
        if (id) {
            const page = Number(id);
            setPage(page);
        }
    }, [id]);

    const handleSorting = (model: GridSortModel) => {
        if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
            setSortModel(model);
            const sort = getSortingParams(model, defaultSort.property, defaultSort.direction);
            setSort(sort);
        }
    };

    useEffect(() => {
        const isInvalid = mediaSpaces.filter((item) => {
            const timeWhenDeviceIsOnline = 10;
            if (item.tariffsValidityStatus === ValidationRules.Invalid) return true;
            if (!item.devices.length) return true;
            if (
                item.devices.some(
                    (device) => getDateWithLocalTz(device.lastConnectionDate) < getCurrentDate(timeWhenDeviceIsOnline),
                )
            )
                return true;
        });
        setInvalid(Boolean(isInvalid.length));
    }, [mediaSpaces.length]);

    const getGridContent = (): JSX.Element => {
        {
            return tableMap ? (
                <StyledList>
                    <PagedDataGrid
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={(model: GridSortModel) => {
                            handleSorting(model);
                        }}
                        isDataLoaded={!loading}
                        isLoadingIndicator={!loading}
                        columns={columns}
                        noDataMessage={<NoDataMessage />}
                        rows={mediaSpaces}
                        filterMode="server"
                        totalPages={totalPage}
                        totalItems={totalItems}
                        defaultPage={page}
                    />
                    <FlashDialog
                        open={open}
                        title={t('media-space-form.delete-media-space')}
                        isOpen={open}
                        onClose={handleClose}
                        content={<TextContent text={t('media-space-form.contentText')} />}
                        controls={<YesNoButtons onConfirm={onDeleteFunction} onReject={handleClose} />}
                    />
                </StyledList>
            ) : (
                <>
                    {!loading ? (
                        <Box width="100%" className="map-handler">
                            <FlashMap
                                hasSearchControl={true}
                                showDetailedPlacemarkContent={true}
                                placemarks={mediaSpaceMapPlacemark}
                            />
                        </Box>
                    ) : (
                        <LoadingIndicatorPopup isLoaded={!loading} />
                    )}
                </>
            );
        }
    };

    return (
        <>
            <FilteredTemplate
                header={t('media-spaces.header')}
                isMediaspaces={invalid}
                createItemPageRoute={user.role === UserRoles.Admin ? allRoutes.createMediaSpace : undefined}
                filter={
                    <MediaSpacesFilter
                        warningsOnly={warningsOnly}
                        setWarningsOnly={(value) => {
                            history.push(history.location.pathname.replace(id, '1'));
                            setWarningsOnly(value);
                        }}
                        onFilterChange={(value) => {
                            history.push(history.location.pathname.replace(id, '1'));
                            setFilter(value);
                        }}
                        setTableMap={setTableMap}
                        tableMap={tableMap}
                    />
                }
                content={getGridContent()}
            />
            {<LoadingIndicator isLoaded={!loading} />}
        </>
    );
}
