import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const UserProfile = styled.div(({}) => ({
    '.user-profile': {
        paddingLeft: 0,
    },
    paddingBottom: '0.75rem',
}));

export const UserDeleted = styled.div(({}) => ({
    '.deleted-user': {
        color: variables.backgoroundColours.border,
    },
    marginLeft: '3.125rem',
}));
