import * as React from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { createChat, isUserInChat, restoreUserInChat } from '../../../../api/chat';
import { FlashButtonProps } from '../Button';
import { ReactComponent as ChatIcon } from '../../../../assets/icons/Chat.svg';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import NavigationLink from '../../../molecules/NavigationLink';
import allRoutes from '../../../../router';
import { OrderStatusType } from 'shared/enums';

export type SwitchToChatButtonProps = FlashButtonProps & {
    orderId?: string;
    orderNumber?: number;
    orderStatus?: OrderStatusType;
    chatId: string;
};

export default function GoToOrderChatButton(props: SwitchToChatButtonProps): JSX.Element {
    const { orderId, orderNumber, orderStatus, chatId, ...buttonProps } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const [isUserPresentInChat, setIsUserPresentInChat] = useState(false);

    const isValidOrder = !!orderId;
    const hasNoChat = (isValidOrder && chatId == '00000000-0000-0000-0000-000000000000') || chatId == null;
    const hasChat = !hasNoChat;

    const switchToChat = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (isValidOrder) {
            let newChatId = chatId || '';

            if (hasNoChat) {
                const result = await createChat(orderId || '');
                newChatId = result.data;
                await restoreUserInChat(newChatId);
            } else if (!isUserPresentInChat) {
                await restoreUserInChat(newChatId);
            }

            history.push({
                pathname: generatePath(allRoutes.messagesPage.path, { id: newChatId }),
                state: { orderNumber: orderNumber, orderUid: orderId },
            });
        }
    };

    useEffect(() => {
        if (hasChat) {
            const checkIfUserInChat = async () => {
                const response = await isUserInChat(chatId || '');
                setIsUserPresentInChat(response.data);
            };
            checkIfUserInChat();
        }
    }, [orderId]);

    return (
        <>
            {isValidOrder && orderStatus !== OrderStatusType.Created && (
                <NavigationLink color={buttonProps.color || 'primary'} {...buttonProps} onClick={switchToChat}>
                    {buttonProps.children || (
                        <>
                            <ChatIcon width="40px" height="40px" />
                            <Typography variant="subtitle1">{t('order.chat-buttom')}</Typography>
                        </>
                    )}
                </NavigationLink>
            )}
        </>
    );
}
