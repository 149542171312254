import styled from 'styled-components';

export const StatisticsStyled = styled.div((props: { marginBottom: string }) => ({
    width: '100%',
    marginBottom: props.marginBottom,

    '.container': {
        display: 'flex',
        'flex-wrap': 'wrap',
        justifyContent: 'center',
        rowGap: '60px',
        columnGap: '2%',
    },
}));
