import { FC, useEffect, useState, MouseEvent } from 'react';

import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from 'store/rootReducer';
import { deleteNotifications } from 'api/chat';

import FlashAppBar from 'components/organisms/menu/AppBar';
import FlashMenu from 'components/organisms/menu/FlashMenu';
import SideMenuMobile from 'components/organisms/menu/SideMenuMobile';
import NotificationsArea from 'components/organisms/menu/NotificationsArea';
import { useNotification } from 'components/organisms/NotificationProvider';
import { refresh } from '../../../../api/auth';
import { updateAccess, updateRefresh } from '../../../../store/auth/actions';
import { Box, Button, MenuItem, Typography } from '@material-ui/core';
import allRoutes from 'router';
import Logo from 'components/atoms/controls/Logo';
import FlashCommonGlobal from 'components/atoms/FlashCommonGlobal';
import { RegistrationButtonStyled, SignInButtonStyled, StyledUnregisteredUserHedaer } from './style';
import FormChangeLanguage from '../../forms/FormChangeLanguage';
import { ReactComponent as Lang } from 'assets/icons/lang.svg';
import { ReactComponent as Currencies } from 'assets/icons/CurrencyWhite.svg';
import FormChangeCurrency from '../../forms/FormChangeCurrency';
import { currenciesAPI } from '../../../../api/currency';
import BurgerIcon from 'components/atoms/Icon/BurgerIcon';
import { getLanguages } from '../../../../api/language';

const menuId = 'primary-search-account-menu';
const dropDownMenuId = 'primary-drop-down-notification-menu';

const PrimarySearchAppBar: FC = () => {
    const { t } = useTranslation();
    const { notifications } = useNotification();
    const dispatch = useDispatch();

    const hasUser = useSelector((state: RootState) => {
        return state.authReducer.auth.accessToken !== '';
    });

    const [hasSignedInOnce, sethasSignedInOnce] = useState(false);

    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [anchorNotificateEl, setAnchorNotificateEl] = useState<null | HTMLElement>(null);

    const [thereAreCurrencies, setThereAreCurrencies] = useState<boolean>(false);
    const [thereAreLanguages, setThereAreLanguages] = useState<boolean>(false);

    const handleProfileMenuOpen = (event: MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleNotificationMenuOpen = (event: MouseEvent<HTMLElement>): void => {
        setAnchorNotificateEl(event.currentTarget);
    };

    const handleMenuClose = (): void => {
        setAnchorEl(null);
    };

    const handleMenuNotificateClose = () => {
        setAnchorNotificateEl(null);
    };

    const onResetNotificationClick = async () => {
        await deleteNotifications();
    };

    const currentUser = useSelector((state: RootState) => state.userReducer.user);
    const currentAuth = useSelector((state: RootState) => state.authReducer.auth);

    useEffect(() => {
        const callbackUseEffect = async () => {
            if (!currentUser.hasSignedInOnce && !hasSignedInOnce) {
                sethasSignedInOnce(true);
                toast.success(t('login-form.user-registered-successfully'));
                if (currentAuth.accessToken && currentAuth.refreshToken) {
                    const response = await refresh({
                        accessToken: currentAuth.accessToken,
                        refreshToken: currentAuth.refreshToken,
                    });
                    const refreshData = { refreshToken: response.data.refreshToken, refreshTokenTTL: 0 };
                    const accessDate = { accessToken: response.data.accessToken, accessTokenTTL: 0 };
                    dispatch(updateRefresh(refreshData));
                    dispatch(updateAccess(accessDate));
                }
            }
        };
        callbackUseEffect();
    }, [currentUser]);

    useEffect(() => {
        async function fetchData() {
            const response = await currenciesAPI.getCurrencies();
            const languageResponse = await getLanguages();
            setThereAreCurrencies(response.data.length > 1);
            setThereAreLanguages(languageResponse.data.length > 1);
        }
        fetchData();
    }, []);

    return (
        <>
            {hasUser && (
                <>
                    <FlashAppBar
                        menuId={menuId}
                        dropDownMenuId={dropDownMenuId}
                        notificationsCount={notifications.length}
                        handleNotificationMenuOpen={handleNotificationMenuOpen}
                        handleProfileMenuOpen={handleProfileMenuOpen}
                        photoUrl={currentUser.pic}
                        fullName={currentUser.given_name + ' ' + currentUser.family_name}
                        role={currentUser.role}
                        setOpenMenu={setOpenMenu}
                    />
                    <SideMenuMobile openMenu={openMenu} setOpenMenu={setOpenMenu} />
                    <FlashMenu id={menuId} anchorEl={anchorEl} handleMenuClose={handleMenuClose} />
                    <NotificationsArea
                        id={dropDownMenuId}
                        notifications={notifications}
                        anchorEl={anchorNotificateEl}
                        onResetNotificationClick={onResetNotificationClick}
                        handleMenuClose={handleMenuNotificateClose}
                    />
                </>
            )}
            {!hasUser && (
                <StyledUnregisteredUserHedaer>
                    <FlashCommonGlobal>
                        <Box className="content">
                            <Box className="logo">
                                <Logo data-id="logo" />
                            </Box>
                            <Box className="buttons">
                                <Box className="desktop">
                                    <Box display="flex" justifyContent="center">
                                        {thereAreLanguages && (
                                            <FormChangeLanguage
                                                title={t('navigation.language-title')}
                                                button={
                                                    <MenuItem data-id="change-language">
                                                        <Lang />
                                                        &nbsp;
                                                        <Typography variant="body1" color="secondary">
                                                            {localStorage.getItem('locale')?.toUpperCase()}
                                                        </Typography>
                                                    </MenuItem>
                                                }
                                            />
                                        )}
                                        {thereAreCurrencies && (
                                            <FormChangeCurrency
                                                title={t('navigation.currency-title')}
                                                onClick={() => null}
                                                button={
                                                    <MenuItem data-id="change-language">
                                                        <Currencies color="secondary" />
                                                        &nbsp;
                                                        <Typography variant="body1" color="secondary">
                                                            {localStorage.getItem('currencyCode')?.toUpperCase()}
                                                        </Typography>
                                                    </MenuItem>
                                                }
                                            />
                                        )}
                                    </Box>
                                    <SignInButtonStyled to={allRoutes.signin.path} id="SEO-login-in-header">
                                        <Typography variant="h5" className="button-text">
                                            {t('navigation.login')}
                                        </Typography>
                                    </SignInButtonStyled>
                                    <RegistrationButtonStyled
                                        to={allRoutes.signup.path}
                                        id="SEO-registration-in-header"
                                    >
                                        <Typography variant="h5" className="button-text">
                                            {t('home-page.intro.button')}
                                        </Typography>
                                    </RegistrationButtonStyled>
                                </Box>

                                <Box className="mobile">
                                    <Button onClick={() => setOpenMenu(true)} name="burger">
                                        <BurgerIcon />
                                    </Button>
                                    <SideMenuMobile openMenu={openMenu} setOpenMenu={setOpenMenu} />
                                </Box>
                            </Box>
                        </Box>
                    </FlashCommonGlobal>
                </StyledUnregisteredUserHedaer>
            )}
        </>
    );
};

export default PrimarySearchAppBar;
