export const UPDATE_MENU = `UPDATE_MENU`;

export interface MenuState {
    menu: {
        isCollapsed?: boolean;
    };
}

export interface MenuData {
    isCollapsed: boolean;
}

export interface UpdateMenuAction {
    type: typeof UPDATE_MENU;
    data: MenuData;
}

export type MenuActionTypes = UpdateMenuAction;
