import React, { FC, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import FilterInput from 'components/atoms/FilterInput';
import MediaSpacesMultiselect from 'components/atoms/gridFilters/MediaSpacesMultiselect';
import DeviceStatusSelect, { DeviceStatusEnum } from 'components/atoms/gridFilters/DeviceStatusSelect';

import useSelector from 'hooks/useAppSelector';

import { updateDeviceFilter } from 'store/deviceFilter/actions';

import { FilterFlexBox } from 'components/atoms/gridFilters/FiltersHolder/style';
import { StyledFilter, StyledFormControl } from 'components/atoms/gridFilters/DevicesFilter/style';
import FilterOption from '../../FilterOption';
import { useTranslation } from 'react-i18next';

export interface DevicesFilterProps {
    onFilterChange: (filter: DevicesFilterData) => void;
    checkboxValue: boolean;
    setCheckbox: () => void;
}

export interface DevicesFilterData {
    filterString: string;
    selectedMediaSpaces: string[];
    status: DeviceStatusEnum | null;
}

const DevicesFilter: FC<DevicesFilterProps> = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const filters = useSelector((state) => state.deviceFilterReducer.device);

    const [filter, setFilter] = useState<DevicesFilterData>(filters);

    useEffect(() => {
        props.onFilterChange && props.onFilterChange(filter);
    }, [filter]);

    const onFilterStringChange = (filterString: string) => {
        setFilter({ ...filter, filterString: filterString });
    };

    const onMediaSpacesChange = (selectedMediaSpaces: string[]) => {
        setFilter({ ...filter, selectedMediaSpaces: selectedMediaSpaces });
    };

    const onStatusChange = (status: DeviceStatusEnum) => {
        setFilter({ ...filter, status: status });
    };

    useEffect(() => {
        dispatch(updateDeviceFilter(filter));
    }, [dispatch, filter]);

    return (
        <StyledFilter className="filter-handler">
            <FilterFlexBox>
                <StyledFormControl>
                    <FilterInput onChange={onFilterStringChange} value={filter.filterString} />
                </StyledFormControl>
                <StyledFormControl>
                    <DeviceStatusSelect onChange={onStatusChange} value={filter.status} />
                </StyledFormControl>
                <StyledFormControl>
                    <MediaSpacesMultiselect
                        displayField="name"
                        value={filter.selectedMediaSpaces}
                        onSelectionChanged={onMediaSpacesChange}
                    />
                </StyledFormControl>
                <StyledFormControl className="last-filter">
                    <FilterOption
                        onClick={() => {
                            props.setCheckbox();
                        }}
                        checked={props.checkboxValue}
                        text={t('mediaspaces.warning-only')}
                    />
                </StyledFormControl>
            </FilterFlexBox>
        </StyledFilter>
    );
};

export default DevicesFilter;
