import { FC } from 'react';

import Status from 'components/atoms/Order/Status';

import { PaymentStatusMap } from 'shared/constants';

import { PaymentStatus as PaymentStatusEnum } from 'shared/enums';

interface PaymentStatusProps {
    status?: PaymentStatusEnum;
    loading?: boolean;
}

const PaymentStatus: FC<PaymentStatusProps> = ({ status, loading }) => {
    const { color, message } = PaymentStatusMap[status ?? PaymentStatusEnum.NonPaid];

    return <Status color={color} message={message} loading={loading} />;
};

export default PaymentStatus;
