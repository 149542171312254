import { Typography } from '@material-ui/core';
import styled from 'styled-components';

import { variables } from 'theme/variables';

export const StyledList = styled.div(({}) => ({
    '.MuiDataGrid-cell': {
        ':first-child': {
            a: {
                justifyContent: 'start',
            },
            p: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },

        ':nth-child(2)': {
            a: {
                justifyContent: 'start',
            },
            p: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },

        ':nth-child(3)': {
            fill: variables.colours.primary,
        },

        '.mediaspace-name': {
            position: 'relative',
            width: '11rem',
            '@media only screen and (min-width: 320px) and (max-width: 1439px)': {
                width: '8rem',
            },
        },

        '.payment-attention': {
            position: 'absolute',
            right: '-2rem',
            top: '-0.188rem',

            '@media only screen and (min-width : 1440px)': {
                right: '10%',
            },
        },
    },
}));

export const RedTypography = styled(Typography).attrs({
    variant: 'body1',
})({
    color: '#e2574c',
});
