import { ReactComponent as Mediaspace } from 'assets/icons/Mediaspace.svg';
import { Icon } from 'components/atoms/Icon/style';

export default function SpaceIcon(): JSX.Element {
    return (
        <Icon>
            <Mediaspace />
        </Icon>
    );
}
