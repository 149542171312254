import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import * as yup from 'yup';
import { pick } from 'lodash';
import { toast } from 'react-toastify';

import { Formik, FormikProps, useFormikContext } from 'formik';
import { Button, Typography } from '@material-ui/core';

import MediaInput from 'components/atoms/MediaInput';
import MediaPreview from 'components/atoms/MediaPreview';

import i18n from 'i18n/config';

import { FormStepComponent } from 'components/organisms/MultiStepForm/types';
import { OrderFormValues } from 'components/organisms/forms/Order';

import { StyledMediaSubForm } from 'components/organisms/MediaSubForm/style';
import { uploadDetached as uploadDetachedVideo } from 'api/videos';
import LoadingIndicator from 'components/atoms/LoadingIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { setVideoId } from '../../../store/form/actions';

export interface MediaFormValues {
    contentDuration: number;
    contentUrl: string;
    contentType?: string;
    content?: File;
    videoId: string;
}

export interface uploadVideoValues {
    content: File;
    contentDuration: number;
}

const MAX_VIDEO_DURATION = 60;
const SUPPORTED_MEDIA_TYPES = 'video/mp4,image/jpeg,image/png';
const QUICKTIME_MEDIA_TYPE = 'video/quicktime';

const isSupportedMediaType = (mediaType: string | undefined): boolean => {
    return !!mediaType && SUPPORTED_MEDIA_TYPES.split(',').includes(mediaType);
};

const validationSchema = yup.object().shape({
    content: yup.mixed().test({
        name: 'is-valid',
        message: i18n.t('order-form.messages.invalid-video'),
        test: function (value) {
            if (!value && this.parent.contentUrl) return true;
            return value?.type !== QUICKTIME_MEDIA_TYPE
                ? isSupportedMediaType(value?.type)
                : this.createError({ message: i18n.t('order-form.messages.invalid-video-quicktime') });
        },
    }),
    contentUrl: yup.string().test({
        name: 'is-content-url-provided-at-least',
        message: i18n.t('order-form.messages.invalid-video'),
        test: function (value) {
            return value || this.parent.content;
        },
    }),
    contentDuration: yup
        .number()
        .min(1, i18n.t('order-form.messages.invalid-video'))
        .max(MAX_VIDEO_DURATION, i18n.t('order-form.messages.invalid-video')),
});

const MediaSubForm: FormStepComponent = ({ onSubmit }) => {
    const { t } = useTranslation();

    const [isLoaded, setIsloaded] = useState<boolean>(true);
    const dispatch = useDispatch();

    const storeVideoId = useSelector((state: RootState) => state.formReducer.videoId);
    const isUploadVideo = useSelector((state: RootState) => state.formReducer.isUploadVideo);

    const { values: outerValues } = useFormikContext<OrderFormValues>();
    const uploadVideo = async (values: uploadVideoValues) => {
        setIsloaded(false);
        const response = await uploadDetachedVideo(values.content, values.contentDuration);
        dispatch(setVideoId(response.data));
        return response.data;
    };

    const handleSubmit = useCallback(
        (values) => {
            validationSchema
                .validate(values)
                .then(() => {
                    return isUploadVideo ? uploadVideo(values) : storeVideoId;
                })
                .then((response: string) => {
                    values = {
                        ...values,
                        videoId: response,
                    };
                    onSubmit(values);
                })
                .catch(({ errors }) => {
                    setIsloaded(true);
                    if (errors) {
                        errors.forEach((error: string) => toast.error(error));
                    } else {
                        setIsloaded(true);
                        toast.error('Error to upload video');
                    }
                });
        },
        [onSubmit, isUploadVideo],
    );

    const initialValues = useMemo(
        () => pick(outerValues, 'contentUrl', 'contentDuration', 'contentType', 'content', 'videoId'),
        [outerValues],
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnMount enableReinitialize>
            {({ submitForm, values }: FormikProps<MediaFormValues>) => (
                <StyledMediaSubForm>
                    {values.content || values.contentUrl ? (
                        <MediaPreview />
                    ) : (
                        <MediaInput
                            accept={SUPPORTED_MEDIA_TYPES}
                            heading={t('order-form.upload-video-text')}
                            hint={t('order-form.recommendation-video')}
                        />
                    )}
                    {(values.content || values.contentUrl) && (
                        <>
                            <LoadingIndicator isLoaded={isLoaded} />
                            {isLoaded && (
                                <Button
                                    variant="outlined"
                                    className="continue-button"
                                    color="primary"
                                    component="label"
                                    onClick={() => submitForm()}
                                    data-id="continue"
                                    id="SEO-createorder-in-orders-page-step-2"
                                >
                                    <Typography variant="h5">{t('order-form.save-and-continue')}</Typography>
                                </Button>
                            )}
                        </>
                    )}
                </StyledMediaSubForm>
            )}
        </Formik>
    );
};

export default MediaSubForm;
