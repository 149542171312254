import {
    AuthActionTypes,
    AuthState,
    DELETE_NEXT_PATH,
    REMOVE_TOKENS,
    SET_NEXT_PATH,
    UPDATE_ACCESS,
    UPDATE_REFRESH,
} from 'store/auth/types';

const InitialState: AuthState = {
    auth: {
        accessToken: '',
        accessTokenTTL: 0,
        refreshToken: '',
        refreshTokenTTL: 0,
    },
    nextPath: '/',
};

const authReducer = (state = InitialState, action: AuthActionTypes): AuthState => {
    switch (action.type) {
        case UPDATE_ACCESS: {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    accessToken: action.accessData.accessToken,
                    accessTokenTTL: action.accessData.accessTokenTTL,
                },
            };
        }
        case UPDATE_REFRESH: {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    refreshToken: action.refreshData.refreshToken,
                    refreshTokenTTL: action.refreshData.refreshTokenTTL,
                },
            };
        }
        case REMOVE_TOKENS: {
            return {
                ...state,
                auth: {
                    ...state.auth,
                    ...InitialState.auth,
                },
            };
        }
        case SET_NEXT_PATH: {
            return {
                ...state,
                nextPath: action.path,
            };
        }
        case DELETE_NEXT_PATH: {
            return {
                ...state,
                nextPath: '/',
            };
        }
        default:
            return state;
    }
};

export default authReducer;
