import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import StyledItemsCount from './style';

interface ItemsCountProps {
    value: number;
}

const ItemsCount: FC<ItemsCountProps> = ({ value }) => {
    const { t } = useTranslation();

    return <StyledItemsCount>{t('general.items', { count: value })}</StyledItemsCount>;
};

export default ItemsCount;
