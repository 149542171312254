import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NoDataMessage from '../../atoms/NoDataMessage';
import PagedDataGrid from '../../organisms/PagedDataGrid';
import { getById as getOrderById, getPagedPlaybackStatisticById } from '../../../api/orders';
import { PageSize } from '../../../shared/constants';
import { OrderVm, PlaybackStatisticVm } from '../../../models/order';
import { GridColDef } from '@material-ui/data-grid';
import PlaybackStatisticColumnsGrid from './ColumnsGrid';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { variables } from '../../../theme/variables';
import PlaybackSummary from '../../atoms/PlaybackStatistic/PlaybackSummary';
import { AxiosResponse } from 'axios';
import { StyledPlaybackStatistic } from './style';
import GoBackButton from 'components/atoms/controls/GoBackButton';

export default function PlaybackStatisticPage(): JSX.Element {
    const { t } = useTranslation();

    const { orderId } = useParams<{ orderId: string }>();
    const { pageId } = useParams<{ pageId: string }>();
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isLoadingIndicator, setIsLoadingIndicator] = useState(true);

    const [order, setOrder] = useState<OrderVm>();
    const [playbackStatistic, setPlaybackStatistic] = useState<Array<PlaybackStatisticVm>>([]);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [page, setPage] = useState(1);

    const [expectedCount, setExpectedCount] = useState<number>(0);
    const [realCount, setrealCount] = useState<number>(0);

    const columns: GridColDef[] = PlaybackStatisticColumnsGrid({
        rows: playbackStatistic,
    });

    const getOrder = async () => {
        setIsDataLoaded(false);
        const order: AxiosResponse<OrderVm> = await getOrderById(orderId);
        setOrder(order.data);
        setIsDataLoaded(true);
    };

    const getPage = async () => {
        setIsLoadingIndicator(false);
        setIsDataLoaded(false);
        const response = await getPagedPlaybackStatisticById(orderId, page, PageSize);
        const totalPageNumber = Math.ceil(response.total / response.pageSize);
        setTotalPage(totalPageNumber);
        setExpectedCount(response.expectedCount);
        setrealCount(response.realCount);
        setPlaybackStatistic(response.data);
        setIsDataLoaded(true);
        setIsLoadingIndicator(true);
    };

    useEffect(() => {
        setTimeout(() => getPage(), 300);
    }, [page]);

    useEffect(() => {
        if (pageId) {
            const page = Number(pageId);
            setTimeout(() => setPage(page), 300);
        }
    }, [pageId]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getOrder().then();
        if (isDataLoaded) {
            page === 1 ? getPage() : setPage(1);
        }
    }, []);

    return (
        <StyledPlaybackStatistic>
            <GoBackButton />
            <Box width="100%" justifyContent="space-between" display="flex" alignItems="center" mt="0.625rem">
                <Typography variant="h4" className="order-number">
                    {t('order-form.order-title')} {order?.number}
                </Typography>
            </Box>
            <Box
                width="100%"
                justifyContent="space-between"
                display="flex"
                alignItems="center"
                mt="0.3rem"
                mb="0.625rem"
            >
                <Typography variant="h1" style={{ color: variables.colours.primary }}>
                    {t('playbackStatistic.title')}
                </Typography>
            </Box>
            <PagedDataGrid
                isDataLoaded={isDataLoaded}
                isLoadingIndicator={isLoadingIndicator}
                totalPages={totalPage}
                defaultPage={page}
                noDataMessage={<NoDataMessage />}
                columns={columns}
                disableColumnSelector={true}
                disableExtendRowFullWidth={true}
                rows={playbackStatistic}
                filterMode="server"
                customPagination={true}
                summaryElement={<PlaybackSummary expectedCount={expectedCount} realCount={realCount} />}
            />
        </StyledPlaybackStatistic>
    );
}
