import { FC, Fragment, ComponentPropsWithRef, useState, useCallback } from 'react';

import LightBox from 'react-image-lightbox';

import { StyledFullScreenImage } from 'components/atoms/FullScreenImage/style';

interface FullScreenImageProps extends ComponentPropsWithRef<'img'> {
    src: string;
    height?: string | undefined;
}

const FullScreenImage: FC<FullScreenImageProps> = (props) => {
    const [fullScreen, setFullScreen] = useState(false);

    const enableFullscreen = useCallback(() => setFullScreen(true), []);
    const disableFullscreen = useCallback(() => setFullScreen(false), []);

    return (
        <Fragment>
            <StyledFullScreenImage height={props.height ?? 'auto'} {...props} onClick={enableFullscreen} />
            {fullScreen && (
                <LightBox
                    imagePadding={60}
                    enableZoom={false}
                    reactModalStyle={{ zIndex: 1300 }}
                    mainSrc={props.src}
                    onCloseRequest={disableFullscreen}
                />
            )}
        </Fragment>
    );
};

export default FullScreenImage;
