import { Drawer } from '@material-ui/core';
import { variables } from '../../../../theme/variables';
import styled from 'styled-components';

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
    width: variables.constants.drawerWidth,
    zIndex: 3,
    whiteSpace: 'nowrap',
    '.MuiPaper-root': {
        marginTop: variables.constants.appBarHeight,
        color: variables.colours.main,
        width: variables.constants.drawerWidth,
        height: `calc(100vh - ${variables.constants.appBarHeight + variables.constants.footerHeight}px)`,
        overflow: 'hidden auto',
        scrollbarWidth: 'thin',
        scrollbarColor: `${variables.colours.primary} transparent`,
        scrollbarFaceColor: variables.colours.primary,
        scrollbarShadowColor: 'transparent',

        '&::-ms-expand': {
            display: 'none',
        },

        '&::-webkit-scrollbar': {
            width: '6px',
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: variables.colours.primary,
            borderRadius: '30px',
        },

        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
    },
    '.MuiButton-label': {
        color: variables.colours.main,
    },
    '&.opened': {
        width: variables.constants.drawerWidth + 1,
    },
    '&.closed': {
        '.MuiPaper-root': {
            width: theme.spacing(6.8) + 18,
        },
        width: theme.spacing(7) + 18,
    },
    '@media only screen and (min-width : 320px) and (max-width : 1024px)': {
        display: 'none',
    },
}));
