import styled from 'styled-components';

import { variables } from 'theme/variables';

export const StyledLogin = styled.div(({}) => ({
    '.create-account': {
        marginLeft: '0.625rem',
        h3: {
            color: variables.colours.primary,
            marginBottom: '0.125rem',
        },
    },
    a: {
        ':hover': {
            textDecoration: 'none',
        },
    },
    '.login-subtitle': {
        h3: {
            '@media only screen and (min-width : 375px) and (max-width: 767px)': {
                fontSize: '0.8rem',
            },
            '@media only screen and (min-width : 320px) and (max-width: 374px)': {
                fontSize: '0.68rem',
            },
        },
    },
    '.social-handler': {
        margin: '1rem 0 0',
        p: {
            fontWeight: 500,
            marginLeft: '2px',
        },
    },
    '.registration-checkbox-title span': {
        color: variables.colours.light,
    },
}));
