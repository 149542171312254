import styled from 'styled-components';
import { variables } from 'theme/variables';

export const FAQStyled = styled.div({
    width: '100%',
    paddingBottom: '120px',

    '.accordions': {
        marginBottom: '60px',
    },

    '.tip': {
        'font-weight': '500',
        lineHeight: '137.01%',
        fontSize: '35px',
        letterSpacing: '0.05em',
        textAlign: 'center',

        a: {
            color: variables.colours.dark,
            textDecoration: 'underline',
            textDecorationColor: variables.colours.primary,

            ':hover': {
                textDecoration: 'none',
            },
        },
    },
});
