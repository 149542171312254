import React from 'react';
import { Typography } from '@material-ui/core';
import Button from '../../controls/Button';
import { useTranslation } from 'react-i18next';
import { StyledButtons } from './style';

export type YesNoButtonsProps = {
    onReject?: () => void;
    onConfirm?: () => void;
};

export default function YesNoButtons(props: YesNoButtonsProps): JSX.Element {
    const { t } = useTranslation();
    const { onConfirm, onReject } = props;

    const handleConfirmation = () => {
        onConfirm && onConfirm();
    };

    const handleReject = () => onReject && onReject();

    return (
        <StyledButtons>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleConfirmation}
                className="option-button"
                name="yes"
            >
                <Typography variant="h5" className="yes-text">
                    {t('selection.yes')}
                </Typography>
            </Button>
            <Button variant="outlined" onClick={handleReject} className="option-button" name="no">
                <Typography variant="h5" className="no-text">
                    {t('selection.no')}
                </Typography>
            </Button>
        </StyledButtons>
    );
}
