import { variables } from '../../../theme/variables';
import styled from 'styled-components';

export const StyledPage = styled.div({
    display: 'flex',
    '&.login': {
        minHeight: '100vh',
        backgroundColor: variables.backgoroundColours.dark,
        '&.protection': {
            backgroundColor: variables.backgoroundColours.light,
        },
        main: {
            marginTop: '0px',
        },
        '@media only screen and (min-width: 1024px) and (max-width: 1439px)': {
            '.MuiContainer-root': {
                padding: 0,
            },
        },
        '@media only screen and (min-width: 768px) and (max-width: 1023px)': {
            '.MuiContainer-root': {
                padding: '2.65rem',
            },
        },
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
            '.MuiContainer-root': {
                padding: 0,
            },
        },
    },

    '&.auth': {
        backgroundColor: variables.backgoroundColours.dark,
    },

    '&.home, &.auth': {
        main: {
            '@media only screen and (min-width: 320px) and (max-width: 767px)': {
                marginTop: '115px',
            },
        },
    },
    '.cookie-container': {
        display: 'flex',
        background: '#F4F4F4',
        minHeight: '122px',
        alignItems: 'center',
        justifyContent: 'space-around',
        bottom: 0,
        flexWrap: 'wrap',
        left: 0,
        position: 'fixed',
        width: '100%',
        zIndex: 999,
        gap: 10,
        padding: '10px',
    },
    '.cookie-content': {},
    '.cookie-accept-btn': {
        width: '157px',
        height: '43px',
        border: `2px solid ${variables.colours.primary}`,
        background: variables.colours.primary,
        borderRadius: '7px',
        color: 'white',
        cursor: 'pointer',
        fontWeight: 400,
        fontSize: '17px',
        lineHeight: '137.01%',
        '&:hover': {
            background: 'none',
            color: variables.colours.primary,
        },
    },
    '.privacy-policy': {
        color: variables.colours.primary,
        textDecoration: 'none',
    },
});

export const WhatsAppButtonContainer = styled.div(({}) => ({
    position: 'fixed',
    bottom: '12px',
    right: '12px',
    zIndex: 10000,
}));
