import moment from 'moment';
import * as yup from 'yup';
import { intersection, without, uniq, get } from 'lodash';

import i18n from 'i18n/config';
import { generateRange } from 'shared/utils';

import { OrderReplays } from 'shared/enums';
import { Require } from 'shared/types';
import { OrderScheduleModel } from 'models/order';
import { DateFormValues } from 'components/organisms/DateSubForm/types';

type ValidatedOrderScheduleModel = Require<OrderScheduleModel, 'startTime' | 'endTime'>;

export const SCHEDULES_LIMIT = 4;

export type OrderReplay = {
    label: string;
    value: OrderReplays;
};

export const orderReplaysOptions = (): OrderReplay[] => {
    return [
        { label: i18n.t('enums.order-replays.one'), value: OrderReplays.One },
        { label: i18n.t('enums.order-replays.five'), value: OrderReplays.Five },
        { label: i18n.t('enums.order-replays.ten'), value: OrderReplays.Ten },
        { label: i18n.t('enums.order-replays.fifteen'), value: OrderReplays.Fifteen },
        { label: i18n.t('enums.order-replays.twenty'), value: OrderReplays.Twenty },
    ];
};

export const scheduleInitialValues: OrderScheduleModel = {
    startTime: moment().startOf('hour').add(2, 'hour').format('HH:mm:ss'),
    endTime: moment().startOf('hour').add(3, 'hours').format('HH:mm:ss'),
    replays: OrderReplays.One,
    isShowAllDay: false,
    startDate: undefined,
    endDate: undefined,
    excludeDates: [],
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validationSchema = (): any => {
    const result = yup.object().shape({
        schedules: yup
            .array<DateFormValues>()
            .of(
                yup
                    .object()
                    .shape({
                        startTime: yup.string().required(i18n.t('order-form.start-time')),
                        endTime: yup.string().required(i18n.t('order-form.end-time')),
                        replays: yup.mixed().oneOf(Object.values(OrderReplays), i18n.t('order-form.replays')),
                        startDate: yup.string().required(i18n.t('order-form.order-date')),
                        endDate: yup.string().required(i18n.t('order-form.order-date')),
                    })
                    .test(
                        'time fields are not equal to each other',
                        'Time fields are equal to each other',
                        // eslint-disable-next-line
                        ({ startTime, endTime }: Require<any, 'startTime' | 'endTime'>) => {
                            if (startTime === endTime && startTime === '00:00:00') return true;
                            return startTime !== endTime;
                        },
                    ),
            )
            // eslint-disable-next-line
            .test('do schedules overlap', i18n.t('order-form.messages.error-schedules-overlap'), (schedules: any) => {
                const doSchedulesOverlap =
                    schedules?.reduce(
                        (doOverlap: boolean, current: ValidatedOrderScheduleModel, index: number): boolean => {
                            if (doOverlap) return true;

                            const others = schedules?.filter(
                                // eslint-disable-next-line
                                (schedule: any, scheduleIndex: number) => scheduleIndex !== index,
                            );

                            const overlapsWithOthers = others?.reduce(
                                (doOverlap: boolean, other: ValidatedOrderScheduleModel): boolean => {
                                    if (doOverlap) return true;

                                    const startInRange =
                                        current.startTime >= other.startTime && current.startTime < other.endTime;
                                    const endInRange =
                                        current.endTime > other.startTime && current.endTime <= other.endTime;
                                    const someIsAllDay = current.isShowAllDay || other.isShowAllDay;

                                    if (startInRange || endInRange || someIsAllDay) {
                                        const currentRange = generateRange(
                                            current.startDate,
                                            current.endDate,
                                            true,
                                        ) as string[];
                                        const otherRange = generateRange(
                                            other.startDate,
                                            other.endDate,
                                            true,
                                        ) as string[];

                                        const intersectedPart = intersection(currentRange, otherRange);

                                        const currentExclude = get(current, 'excludeDates', []);
                                        const otherExclude = get(other, 'excludeDates', []);

                                        const withoutExcluded = without(
                                            intersectedPart,
                                            ...uniq([...currentExclude, ...otherExclude]),
                                        );

                                        return withoutExcluded.length > 0;
                                    }

                                    return false;
                                },
                                false,
                            );

                            return overlapsWithOthers;
                        },
                        false,
                    ) ?? false;

                return !doSchedulesOverlap;
            }),
    });
    return result;
};
