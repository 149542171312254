import React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, RouteProps, generatePath, useLocation } from 'react-router-dom';
import allRoutes, { AccessMode, FlashRouterProps } from '../../../../router';
import { UserRoles } from '../../../../shared/enums';
import { RootState } from '../../../../store/rootReducer';
import { User } from '../../../../store/user/types';
import LoadingIndicatorPopup from '../../../atoms/LoadingIndicatorPopup';
import { logOut } from '../../../../api/auth';
import { deleteNextPath, removeTokens, setNextPath } from '../../../../store/auth/actions';
import { removeUser } from '../../../../store/user/actions';
import MainContainer from 'components/molecules/containers/MainContainer';

export type SecurityRouteProps = RouteProps & {
    route: FlashRouterProps;
};

const FlashMediaRoute: React.FC<SecurityRouteProps> = (props: SecurityRouteProps) => {
    const { route, ...rest } = props;
    const dispatch = useDispatch();
    const locale = useLocation();
    const [isDataLoaded, setIsDataLoaded] = React.useState(false);
    const auth = useSelector((state: RootState) => state.authReducer.auth);
    const nextPath = useSelector((state: RootState) => state.authReducer.nextPath);
    const user = useSelector((state: RootState) => {
        if (state.authReducer.auth.accessToken !== '') {
            return state.userReducer.user;
        }
        return state.authReducer.auth.accessToken !== '';
    });
    const logout = async () => {
        try {
            dispatch(removeTokens());
            dispatch(removeUser());
            dispatch(deleteNextPath());
            await logOut({
                accessToken: auth.accessToken || '',
                refreshToken: auth.refreshToken || '',
            });
        } finally {
            return;
        }
    };

    useMemo(() => {
        if ((user as User).nameid && auth.accessToken) {
            setIsDataLoaded(true);
        } else if (!user) setIsDataLoaded(true);
    }, [user]);

    if (isDataLoaded) {
        switch (route.access) {
            case AccessMode.Anonymous:
                if (user) {
                    if (route.path !== allRoutes.completePasswordRestore.path) {
                        return <Redirect to={nextPath} />;
                    } else {
                        logout();
                    }
                }
                break;
            case AccessMode.Authorized:
                if (!user) {
                    dispatch(setNextPath(locale.pathname));
                    return <Redirect to={allRoutes.home.path} />;
                }

                if (route.availableForRolesOnly.length) {
                    if (
                        route.availableForRolesOnly.indexOf(UserRoles.User) === -1 &&
                        (user as User).role === UserRoles.User
                    ) {
                        return <Redirect to={allRoutes.home.path} />;
                    } else if (
                        (route.availableForRolesOnly.indexOf(UserRoles.Admin) === -1 &&
                            (user as User).role === UserRoles.Admin) ||
                        (route.availableForRolesOnly.indexOf(UserRoles.Operator) === -1 &&
                            (user as User).role === UserRoles.Operator)
                    ) {
                        return <Redirect to={generatePath(allRoutes.orders.path, { pageId: 1 })} />;
                    }
                }
                break;
            case AccessMode.Both:
                // No special logic
                break;
        }
        return (
            <MainContainer isFullWidth={route.isFullWidth}>
                <Route {...rest} />
            </MainContainer>
        );
    } else return <>{!isDataLoaded && <LoadingIndicatorPopup isLoaded={isDataLoaded} />}</>;
};

export default FlashMediaRoute;
