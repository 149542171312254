import { AxiosResponse } from 'axios';
import { ChatMessageVm } from '../models/chat-message-model';
import axios from '../api/axios';

export const send = (): Promise<AxiosResponse<Array<ChatMessageVm>>> => {
    return axios.get(`SaveMessage`);
};

export const getMessages = (chatId: string, page: number): Promise<AxiosResponse<Array<ChatMessageVm>>> => {
    return axios.get(`chat/${chatId}/history?page=${page}`);
};

export default {
    send,
    historyMessage: getMessages,
};
