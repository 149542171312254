import { FC, useCallback } from 'react';

import { isNumber } from 'lodash';

import { useTranslation } from 'react-i18next';

import { ReactComponent as ChevronUpIcon } from 'assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';

import {
    StyledMediaDurationInput,
    MediaDurationControl,
    MediaDurationLabel,
    MediaDurationButton,
    MediaDurationIcon,
} from 'components/atoms/Order/MediaDurationInput/style';

interface MediaDurationInputProps {
    value: number;
    onChange: (value: number) => void;
    min?: number;
    max?: number;
    className?: string;
}

const MediaDurationInput: FC<MediaDurationInputProps> = ({ min, max, value, className, onChange }) => {
    const { t } = useTranslation();

    const handleIncrement = useCallback(() => {
        if (isNumber(max) && value + 1 > max) return onChange(isNumber(min) ? min : max);
        return onChange(value + 1);
    }, [value, min, max, onChange]);

    const handleDecrement = useCallback(() => {
        if (isNumber(min) && value - 1 < min) return onChange(isNumber(max) ? max : min);
        return onChange(value - 1);
    }, [value, min, max, onChange]);

    return (
        <StyledMediaDurationInput className={className}>
            <MediaDurationLabel>{`0${value}`.slice(-2)}</MediaDurationLabel>
            <MediaDurationControl>
                <MediaDurationButton onClick={handleIncrement}>
                    <MediaDurationIcon as={ChevronUpIcon} />
                </MediaDurationButton>
                <MediaDurationButton onClick={handleDecrement}>
                    <MediaDurationIcon as={ChevronDownIcon} />
                </MediaDurationButton>
            </MediaDurationControl>
            <MediaDurationLabel>{t('order-form.second')}</MediaDurationLabel>
        </StyledMediaDurationInput>
    );
};

export default MediaDurationInput;
