import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const Chat = styled.div(() => ({
    height: 'auto',
    width: '85%',
    borderRadius: '4px',
    '.dialogBox': {
        overflow: 'auto',
        '&.MuiBox-root': {
            maxHeight: '27.688rem',
            height: '27.688rem',
        },
        justifyContent: 'flex-end',
        '::-webkit-scrollbar': {
            width: '5px',
        },
        '::-webkit-scrollbar-thumb': {
            background: variables.colours.main,
            borderRadius: '29px',
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: variables.backgoroundColours.primary,
        },
        '::-webkit-scrollbar-track': {
            borderRadius: '0px',
        },
    },
    '.chat-title': {
        fontSize: '2.5rem',
        letterSpacing: '0.2rem',
        color: variables.colours.light,
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            fontSize: '2rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            fontSize: '2rem',
        },
    },
    '@media only screen and (min-width: 320px) and (max-width: 767px)': {
        width: '100%',
    },
}));

export const MessagesStile = styled.div({
    marginTop: '0.625rem',
    '.MuiPaper-root': {
        '&.message-end': {
            marginTop: '0.625rem',
            padding: '1.25rem',
            color: variables.backgoroundColours.light,
            justifyContent: 'flex-end',
            backgroundColor: variables.backgoroundColours.primary,
            borderRadius: '21px 21px 0px 21px',
            marginBottom: '0.313rem',
            maxWidth: '31.25rem',
            '@media only screen and (min-width: 320px) and (max-width: 630px)': {
                maxWidth: '12rem',
            },
        },
        '&.message-start': {
            padding: '1.25rem',
            color: variables.backgoroundColours.light,
            backgroundColor: variables.colours.main,
            justifyContent: 'flex-end',
            borderRadius: '21px 21px 21px 0px',
            marginBottom: '0.313rem',
            maxWidth: '31.25rem',
            '@media only screen and (min-width: 320px) and (max-width: 630px)': {
                maxWidth: '12rem',
            },
        },
    },
    '.chat-message': {
        color: variables.colours.light,
        overflowWrap: 'break-word',
    },
    '.system': {
        '.MuiPaper-root': {
            backgroundColor: variables.colours.info,
        },
    },
    '.time-message': {
        color: variables.colours.default,
    },
    '.end-time-message': {
        color: variables.colours.default,
        textAlign: 'right',
    },
});

export const MessageLength = styled.div({
    fontWeight: 300,
    fontSize: 14,
    width: '100%',
    textAlign: 'center',
});

export const OrderButton = styled.div(({}) => ({
    background: `linear-gradient(40.39deg, #FBFBFB -47.91%, rgba(255, 255, 255, 0) 52.5%), linear-gradient(95.34deg, #FF3D00 39.45%, rgba(255, 255, 255, 0.58) 125.52%), ${variables.colours.primary}`,
    textAlign: 'center',
    height: '5.313rem',
    width: '100%',
    borderRadius: '0 4px 0 0',
    display: 'flex',
    '.buttonText': {
        margin: '0 auto',
    },
}));

export const NewMessages = styled.div(({}) => ({
    height: '6.313rem',
    boxShadow: '-2px 0px 13px rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box',
    display: 'flex',
}));
