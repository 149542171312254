import React from 'react';
import { FlashRouterProps } from '../../../router';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { ButtonProps } from '@material-ui/core';
import { StyledButton } from './style';
import { generatePath, useHistory } from 'react-router-dom';

export type NavigationLinkProps = ButtonProps & {
    route?: FlashRouterProps;
    // eslint-disable-next-line
    routerState?: any;
};

export default function NavigationLink(props: NavigationLinkProps): JSX.Element | null {
    const { route, routerState, children, ...buttonProps } = props;
    const user = useSelector((state: RootState) => state.userReducer.user);
    const history = useHistory();

    if (route) {
        const isShown = route.availableForRolesOnly.length === 0 || route.availableForRolesOnly.indexOf(user.role) >= 0;
        if (isShown) {
            return (
                <StyledButton
                    onClick={() => {
                        // TODO: VERY BAD CODE SINGLE RESPONSIBILITY
                        if (route.path === '/chat/:id' && !history.location.pathname.includes('chat')) {
                            localStorage.setItem('locationBack', history.location.pathname);
                        }
                        if (routerState) {
                            history.push(generatePath(route.path, routerState));
                        } else {
                            history.push(route.path);
                        }
                    }}
                    color="primary"
                    {...buttonProps}
                >
                    {children}
                </StyledButton>
            );
        } else {
            return null;
        }
    } else {
        return (
            <StyledButton color="primary" {...buttonProps}>
                {children}
            </StyledButton>
        );
    }
}
