import { ReactComponent as Video } from 'assets/icons/video.svg';
import { Icon } from 'components/atoms/Icon/style';

export default function VideoIcon(): JSX.Element {
    return (
        <Icon>
            <Video />
        </Icon>
    );
}
