import { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import DeviceProfile from 'components/pages/DeviceProfile';

import { devicesAPI } from 'api/devices';
import { DefaultDeviceInput, DeviceVm } from 'models/device.model';

export default function DeviceEditPage(): JSX.Element {
    const { id } = useParams<{ id: string }>();

    const [event, setEvent] = useState<DeviceVm>(DefaultDeviceInput);

    useEffect(() => {
        const loadEvent = async () => {
            const response = await devicesAPI.getById(id);
            const event = response.data;
            const inputModel: DeviceVm = {
                name: event.name,
                lastConnectionDate: new Date(event.lastConnectionDate),
                createdDate: new Date(event.createdDate),
                status: event.status,
                macAddress: event.macAddress,
                ipAddress: event.ipAddress,
                osBuild: event.osBuild,
                operationSystemName: event.operationSystemName,
                mediaSpace: event.mediaSpace,
                availableMemory: event.availableMemory,
                usedMemory: event.usedMemory,
                applicationVersion: event.applicationVersion,
                volumeLevel: event.volumeLevel,
                maxVolumeLevel: event.maxVolumeLevel,
                cpuTemp: event.cpuTemp,
                storageType: event.storageType,
            };
            setEvent(inputModel);
        };
        setTimeout(() => loadEvent(), 300);
    }, [id]);

    return <DeviceProfile event={event} id={id} />;
}
