import styled from 'styled-components';

import { Box, List, Modal, ButtonBase, Typography } from '@material-ui/core';

import { variables } from 'theme/variables';

export const PlaylistListStyled = styled(List)({
    margin: '1.25rem 0 1.25rem 3.7rem',
    minWidth: '25rem',
    maxHeight: '15.438rem',
    position: 'relative',
    overflow: 'auto',
    paddingTop: 0,
    border: `2px solid ${variables.colours.default}`,
    borderRadius: '4px',
    boxShadow: '0px 0px 8px 3px rgba(0, 0, 0, 0.05)',
    h6: {
        color: variables.colours.default,
    },
    '.MuiListItemText-root': {
        span: {
            fontWeight: 400,
            overflowWrap: 'break-word',
        },
    },
    '.delete-button': {
        fill: variables.colours.default,
        marginLeft: '0.625rem',
        ':hover': {
            opacity: '0.5',
        },
    },
    '.list-handler': {
        display: 'inline-flex',
        h6: {
            ':last-child': {
                marginLeft: '3.75rem',
                marginRight: '1.875rem',
                '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
                    marginLeft: '3.675rem',
                    marginRight: '2rem',
                },
                '@media only screen and (min-width: 376px) and (max-width: 767px)': {
                    marginLeft: '2.4rem',
                    marginRight: '0.225rem',
                },
                '@media only screen and (min-width: 320px) and (max-width: 375px)': {
                    marginLeft: '2.4rem',
                    marginRight: '0.225rem',
                },
            },
            '.MuiListItem-gutters': {
                '@media only screen and (min-width: 320px) and (max-width: 767px)': {
                    paddingLeft: '0.313rem',
                    paddingRight: '0.313rem',
                },
            },
        },
    },
    '@media only screen and (min-width: 375px) and (max-width: 767px)': {
        margin: '1.25rem 0 1.25rem 0',
    },
    '@media only screen and (min-width: 320px) and (max-width: 374px)': {
        margin: '1.25rem 0 0.313rem 0',
    },
});

export const Calendar = styled.div(({}) => ({
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    '.react-datepicker-wrapper': { width: '100%' },
}));

export const Duration = styled.div(({}) => ({
    width: '6.375rem!important',
    '.MuiInputBase-input': {
        color: 'black',
        minWidth: '3.563rem',
    },
}));

export const UploadInput = styled.input(({}) => ({
    display: 'none',
}));

export const PlaylistItemStyled = styled.div(({}) => ({
    marginBottom: '2.5rem',
    '&.view-handler': {
        marginBottom: 0,
    },
    '.view-title': {
        marginTop: '0.313rem',
        marginLeft: '3.75rem',
        color: variables.colours.default,
    },
    '.list-header': {
        fontWeight: 'normal',
        display: 'flex',
        justifyContent: 'space-between',
        paddingTop: '8px',
        backgroundColor: variables.backgoroundColours.light,
        color: variables.colours.default,

        h6: {
            ':first-child': {
                marginLeft: 25,
            },

            ':last-child': {
                marginRight: 90,
            },
        },
    },
    '.file-name': {
        cursor: 'pointer',
    },
    'li.Mui-disabled': {
        paddingRight: '2.938rem',
    },
    '.MuiFormControlLabel-labelPlacementStart': {
        marginLeft: 0,
    },
}));

export const OptionButtonHolder = styled(Box)({
    '.MuiButtonBase-root': {
        border: `2px solid ${variables.colours.primary}`,
        h5: {
            color: variables.colours.primary,
        },
        '.save-text': {
            color: variables.colours.light,
        },
        ':hover': {
            border: `2px solid ${variables.colours.primary}`,
            '.save-text': {
                color: variables.colours.primary,
            },
        },
        ':last-child': {
            marginLeft: '3.313rem',
            '@media only screen and (min-width: 375px) and (max-width: 424px)': {
                marginLeft: '1.313rem',
            },
            '@media only screen and (min-width: 320px) and (max-width: 374px)': {
                marginLeft: 0,
                marginTop: '0.938rem!important',
            },
        },
    },
});

export const Quality = styled.div(({}) => ({
    marginRight: '3.75rem',
    '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
        marginRight: '2.75rem',
    },
    '@media only screen and (min-width: 376px) and (max-width: 767px)': {
        marginRight: '1.75rem',
    },
    '@media only screen and (min-width: 320px) and (max-width: 375px)': {
        marginRight: '0.75rem',
    },
}));

export const ModalStyled = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

export const VideoViewStyled = styled.video(({}) => ({
    borderRadius: '10px',
    height: '50%',
    width: '80%',
    backgroundColor: 'black',
    ':focus-visible': {
        outline: 'none',
    },
}));

export const ImageViewStyled = styled.img(({}) => ({
    marginBottom: '1.25rem',
    borderRadius: '10px',
    height: 'auto',
    maxHeight: '50%',
    maxWidth: '80%',
    ':focus-visible': {
        outline: 'none',
    },
}));

export const TableHolder = styled.div((props: { maxWidth: number }) => ({
    maxWidth: props.maxWidth - 50,
    'overflow-x': 'auto',
    'overflow-y': 'hidden',
    '::-webkit-scrollbar': {
        height: '5px',
    },
    '::-webkit-scrollbar-thumb': {
        background: variables.colours.main,
        borderRadius: '29px',
    },
    '::-webkit-scrollbar-thumb:hover': {
        background: variables.backgoroundColours.primary,
    },
    '::-webkit-scrollbar-track': {
        borderRadius: '0px',
    },
}));

export const ExternalHolder = styled.div((props: { maxWidth: number }) => ({
    'overflow-x': 'hidden',
    width: '100%',
    maxWidth: props.maxWidth - 50,
}));

export const LoaderWrapper = styled.div({
    display: 'inline-flex',
    alignItems: 'center',
    height: '100%',
    width: 40,
});

export const ActionButton = styled(ButtonBase)({
    height: 30,
    width: 30,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    backgroundColor: '#f9f9f9',
    marginLeft: 15,
});

export const ActionButtonIcon = styled.svg({
    height: 18,
    width: 18,
    color: '#aaa',
});

export const ListNumber = styled(Typography)({
    marginRight: 10,
    width: 15,
    color: '#aaa',
});
