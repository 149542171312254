import styled from 'styled-components';
import FormControlTextInput from '../../../molecules/forms/FormControlTextInput';
import { variables } from '../../../../theme/variables';

export const PriceEditFormStyle = styled.div(({}) => ({
    marginLeft: '1.25rem',
    marginTop: '-1.875rem',
    '@media only screen and (min-width: 320px) and (max-width: 1024px)': {
        marginTop: 0,
    },
    '@media only screen and (min-width: 320px) and (max-width: 425px)': {
        marginLeft: 0,
    },
    '.react-datepicker__input-container': {
        width: '91%',
        marginBottom: '1.875rem',
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
            width: '100%',
        },
    },
    '.button-actions': {
        '.button-container': {
            display: 'flex',
            flexWrap: 'wrap',
            gap: '12px 0',
        },
    },
    '.header': {
        color: variables.colours.primary,
        marginBottom: '0.625rem',
        paddingBottom: '0.313rem',
        borderBottom: `1.5px solid ${variables.colours.default}`,
    },
    '.MuiInputBase-input': {
        paddingBottom: '0.625rem',
    },
    '.MuiButton-outlinedSecondary': {
        border: `2px solid ${variables.colours.primary}`,
        marginLeft: '3.313rem',
        '@media only screen and (min-width: 375px) and (max-width: 424px)': {
            marginLeft: '1.313rem',
        },
        '@media only screen and (min-width: 320px) and (max-width: 374px)': {
            marginLeft: 0,
            marginTop: '0.938rem!important',
        },
    },
    '.option-button': {
        marginTop: '3.125rem',
        marginBottom: 0,
        border: `2px solid ${variables.colours.primary}`,
        h5: {
            color: variables.colours.primary,
        },
        '.save-text': {
            color: variables.colours.light,
        },
        ':hover': {
            border: `2px solid ${variables.colours.primary}`,
            '.save-text': {
                color: variables.colours.primary,
            },
        },
    },
    '.date-handler': {
        width: '50%',
        justifyContent: 'space-between',
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
            width: '100%',
            '.react-datepicker-wrapper': {
                display: 'flex',
            },
        },
    },
    '.dropdown-handler': {
        width: '50%',
        marginRight: '3.8%',
        marginBottom: '1.375rem',
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
            width: '100%',
            marginRight: 0,
        },
    },
    '.time-handler': {
        width: '50%',
        marginBottom: '1rem',
        justifyContent: 'space-between',
    },
    '.price-icon': {
        marginTop: '1.875rem',
        path: {
            fill: variables.colours.primary,
        },
        '&.cost-icon': {
            marginRight: '4.5rem',
        },
    },
    '.mediaspace-icon': {
        marginRight: '1.563rem',
        path: {
            fill: variables.colours.primary,
        },
    },
    '.time-dash': {
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
            marginTop: '1.25rem',
        },
    },
    '.checkbox-time + span': {
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
            marginTop: '1.25rem',
        },
    },
    '.checkbox-time': {
        '@media only screen and (min-width: 320px) and (max-width: 767px)': {
            marginTop: '1.25rem',
        },
    },
    '.list-container': {
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            '.grid-container': {
                display: 'flex',
                width: '100%',
                margin: '16px auto',
            },
            '.list': {
                width: '100%',
                paddingRight: 0,
                paddingLeft: 0,
            },
        },
    },
}));

export const FormControlTextInputStyle = styled(FormControlTextInput)({
    width: '100%',
    marginBottom: '1.25rem',
    '.MuiInputBase-input': {
        fontSize: '1.875rem',
        paddingBottom: '0.625rem',
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            fontSize: '1.563rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            fontSize: '1.25rem',
        },
    },
});

export const StyledTypography = styled.div(({}) => ({
    paddingBottom: '0.625rem',
}));

export const CostList = styled.div(({}) => ({
    display: 'flex',
    flexDirection: 'column',
}));

export const CostItem = styled.div(({}) => ({
    display: 'flex',
}));

export const StyledMessage = styled.div(({}) => ({
    marginLeft: '7.5rem',
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        marginLeft: '1.3rem',
    },
}));

export const StyledTitleBlock = styled.div(({}) => ({
    display: 'flex',
    flexDirection: 'column',
}));

export const StyledTextLength = styled.div(({}) => ({
    fontWeight: 400,
    background: '#fff',
    height: 23,
    marginLeft: 'auto',
    right: 0,
}));
