import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { Typography } from '@material-ui/core';

import useWindowDimensions from 'hooks/WindowDimensions';

import { OrderVm } from 'models/order';
import { MomentFormats, OrderStatusType } from 'shared/enums';

import { ScheduleTable, ExternalHolder, TableHolder } from 'components/molecules/Order/OrderInfoTable/style';
import { OrderStatusMap } from 'shared/constants';
import { getDateWithLocalTz } from 'hooks/getDateWithLocalTz';

export type OrderInfoTableProps = {
    order: OrderVm | undefined;
};

export default function OrderHistoryTable(props: OrderInfoTableProps): JSX.Element {
    const { order } = props;
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    return (
        <ExternalHolder maxWidth={width}>
            <TableHolder maxWidth={width}>
                <ScheduleTable>
                    <thead>
                        <tr>
                            <th className="width">
                                <Typography variant="body1" className="table-title">
                                    {t('order.status-column')}
                                </Typography>
                            </th>
                            <th className="width">
                                <Typography variant="body1" className="table-title">
                                    {t('order.status-date')}
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {order?.orderHistory.map((val) => (
                            <tr key={val.id}>
                                <td>{t(OrderStatusMap[val.status ?? OrderStatusType.All].message)}</td>
                                <td>{moment(getDateWithLocalTz(val.createdDate)).format(MomentFormats.Long)}</td>
                            </tr>
                        ))}
                    </tbody>
                </ScheduleTable>
            </TableHolder>
        </ExternalHolder>
    );
}
