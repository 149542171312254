import styled from 'styled-components';

import 'react-image-lightbox/style.css';

export const StyledFullScreenImage = styled.img({
    cursor: 'pointer',
    borderRadius: 8,
    maxWidth: '90%',
    objectFit: 'contain',
});
