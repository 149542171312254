import { FC, SetStateAction } from 'react';

import { FormHelperText, IconButton, Input, InputAdornment, InputLabel, InputProps } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { FormPasswordInput } from 'components/atoms/PasswordInput/style';

export type PasswordInputProps = InputProps & {
    label: string;
    value: string;
    isEdit?: boolean;
    classNameIcon?: string;
    showPassword: boolean;
    helperText?: string | boolean | undefined;
    setShowPassword: (value: SetStateAction<boolean>) => void;
    iconColor?: 'inherit' | 'primary' | 'secondary' | 'default';
    color?: 'primary' | 'secondary';
};

const Password: FC<PasswordInputProps> = ({
    name,
    label,
    value,
    showPassword,
    setShowPassword,
    color,
    iconColor,
    isEdit = true,
    classNameIcon,
    helperText,
    ...inputProps
}) => {
    return (
        <FormPasswordInput error={helperText ? true : false}>
            <InputLabel color={color}>{label}</InputLabel>
            <Input
                name={name || 'password'}
                {...inputProps}
                value={value}
                type={showPassword ? 'text' : 'password'}
                color={color}
                endAdornment={
                    isEdit ? (
                        <InputAdornment position="end">
                            <IconButton
                                className={classNameIcon}
                                color={iconColor}
                                onClick={() => setShowPassword(!showPassword)}
                                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                            >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    ) : (
                        <></>
                    )
                }
            ></Input>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormPasswordInput>
    );
};

export default Password;
