import styled from 'styled-components';
import { variables } from '../../../../theme/variables';

export const LinkStyle = styled.div(({}) => ({
    textAlign: 'center',
    '.list-icon': {
        path: {
            fill: variables.backgoroundColours.border,
        },
        circle: {
            stroke: variables.backgoroundColours.border,
        },
        '&.list-active': {
            path: {
                fill: variables.colours.primary,
            },
            circle: {
                stroke: variables.colours.primary,
            },
        },
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            width: '30px',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '30px',
        },
    },
    '.map-icon': {
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            width: '15px',
            marginBottom: '0.625rem',
            marginLeft: '0.063rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '15px',
            marginBottom: '0.625rem',
            marginLeft: '0.063rem',
        },
    },
    '.map-button-false': {
        border: `2px solid ${variables.backgoroundColours.border}`,
        boxSizing: 'border-box',
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        padding: '0.413rem',
        paddingLeft: '0.5rem',
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            width: '30px',
            height: '30px',
            padding: 0,
            paddingTop: '0.113rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '30px',
            height: '30px',
            padding: 0,
            paddingTop: '0.113rem',
        },
    },
    '.map-button-true': {
        border: `2px solid ${variables.colours.primary}`,
        boxSizing: 'border-box',
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        padding: '0.413rem',
        paddingLeft: '0.5rem',
        '.map-icon': {
            path: {
                fill: variables.colours.primary,
            },
        },
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            width: '30px',
            height: '30px',
            padding: 0,
            paddingTop: '0.113rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '30px',
            height: '30px',
            padding: 0,
            paddingTop: '0.113rem',
        },
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        margin: '0 auto',
    },
}));

export const MediaSpacesAmount = styled.div(({}) => ({
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '137.01%',
    color: '#232323',
}));

export const LeftSideContainer = styled.div(({}) => ({
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
}));

export const FormControlTextInputStyle = styled.div(({ theme }) => ({
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    flex: 1,

    '.MuiFormControl-root:not(:first-child)': {
        marginLeft: '1.25rem',
    },
    '.MuiFormControl-root': {
        '@media only screen and (min-width : 320px) and (max-width : 769px)': {
            width: '100%',
        },
    },
    '.MuiInputBase-root': {
        background: variables.backgoroundColours.light,

        '@media only screen and (min-width : 768px) and (max-width : 1023px)': {
            width: '100%',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '100%',
        },
        height: '2.688rem',
        boxSizing: 'border-box',
        borderRadius: '4px',
    },
    '.MuiOutlinedInput-input': {
        color: variables.colours.default,
        textAlign: 'center',
        padding: '0.625rem',
    },
    '.MuiSelect-selectMenu': {
        display: 'flex',
    },
}));
