import { useTranslation } from 'react-i18next';

import NotificationLink from 'components/atoms/controls/notifications/NotificationLink';

import { ReactComponent as OrderNotification } from 'assets/icons/OrderNotification.svg';

export type NotificationItemProps = {
    orderNumber: number;
    onClick: () => void;
    chatId: string;
};

export default function OrderApprovedNotificationLink(props: NotificationItemProps): JSX.Element {
    const { orderNumber, onClick, chatId } = props;

    const { t } = useTranslation();

    return (
        <NotificationLink
            icon={<OrderNotification />}
            onClick={onClick}
            chatId={chatId}
            header={t('order.status-lable.approved')}
            body={
                <>
                    {t('order.order')}
                    {orderNumber}
                    {t('order.status-lable.approved-coment')}
                </>
            }
        />
    );
}
