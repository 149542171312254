import { FC, useEffect, useState, CSSProperties } from 'react';

import { DialogProps } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { DialogStyle } from 'components/atoms/dialog/FlashDialog/style';

import 'react-datepicker/dist/react-datepicker.css';

export type FlashDialogProps = DialogProps & {
    title?: string;
    isOpen: boolean;
    content?: JSX.Element;
    controls?: JSX.Element;
    onClose?: () => void;
    width?: CSSProperties['width'];
    position?: CSSProperties['position'];
    isRequired?: boolean;
};

const FlashDialog: FC<FlashDialogProps> = ({
    title,
    isOpen,
    controls,
    content,
    onClose,
    width,
    position,
    isRequired,
}) => {
    const [isOpened, setIsOpened] = useState<boolean>(isOpen);

    const handleClose = () => {
        setIsOpened(false);
        onClose && onClose();
    };

    useEffect(() => setIsOpened(isOpen), [isOpen]);

    return (
        <DialogStyle
            open={isOpened}
            width={width}
            position={position}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            disableBackdropClick={isRequired}
            disableEscapeKeyDown={isRequired}
        >
            <DialogTitle id="form-dialog-title" className="title">
                {title}
            </DialogTitle>
            <DialogContent className="content">{content}</DialogContent>
            <DialogActions>{controls}</DialogActions>
        </DialogStyle>
    );
};

export default FlashDialog;
