import styled from 'styled-components';

import { ButtonBase, Typography } from '@material-ui/core';

import { ReactComponent as CreateVideoIcon } from 'assets/icons/CreateVideo.svg';

import { variables } from 'theme/variables';

export const StyledMediaInput = styled(ButtonBase)({
    height: 390,
    padding: 40,
    margin: 5,
    boxShadow: `0 0 0 5px ${variables.colours.primary}`,
    borderRadius: 6,
    display: 'flex',
    flexDirection: 'column',

    '.MuiTouchRipple-child': {
        backgroundColor: variables.colours.primary,
    },

    '&:hover, &:focus': {
        '.media-input-heading': {
            color: variables.colours.primary,
        },
    },
});

export const MediaInputIcon = styled(CreateVideoIcon)({
    marginBottom: 20,
});

export const MediaInputHeading = styled(Typography).attrs({ variant: 'h3', component: 'h3' })({
    transition: '.2s ease',
    marginBottom: 10,
    fontSize: 18,
});

export const MediaInputHint = styled(Typography).attrs({ variant: 'subtitle1' })({
    color: variables.colours.default,
    fontSize: 12,
});
