import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';
import { NotifMenuStyled, StyledCheckboxMenu } from './style';
import { MenuItem } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { StatusId } from '../../../models/interface/i-has-id';
import { OrderStatusType } from '../../../shared/enums';

export type CustomAction = {
    action: () => void;
    label: string;
};

export type CheckboxMenuProps = {
    rows: StatusId[];
    exceptions?: string[];
    setChecked: (value: React.SetStateAction<string[]>) => void;
    actions: Array<CustomAction>;
};

export default function CheckboxMenu(props: CheckboxMenuProps): JSX.Element {
    const { actions, rows, setChecked, exceptions } = props;
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const isMenuOpen = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <StyledCheckboxMenu>
            <IconButton
                aria-label="show more"
                aria-haspopup="true"
                onClick={handleClick}
                color="primary"
                name="more-button"
            >
                <MoreIcon className="more-icon" />
            </IconButton>
            <NotifMenuStyled
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={isMenuOpen}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        rows.filter((x) => exceptions?.find((ex) => ex === x.id) === undefined).map((row) => {
                            if (row.status !== undefined) {
                                if (row.status === OrderStatusType.onModeration) {
                                    setChecked((prev) => {
                                        return [...prev, row.id];
                                    });
                                }
                            } else {
                                setChecked((prev) => {
                                    return [...prev, row.id];
                                });
                            }
                        });
                        handleClose();
                    }}
                    data-id="all"
                >
                    {t('orders.checkbox.choose-all')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        setChecked([]);
                        handleClose();
                    }}
                    data-id="remove"
                >
                    {t('orders.checkbox.remove-marks')}
                </MenuItem>
                {actions.map((action: CustomAction, i: number) => (
                    <MenuItem
                        key={i}
                        onClick={() => {
                            action.action();
                            handleClose();
                        }}
                        data-id="action"
                    >
                        {action.label}
                    </MenuItem>
                ))}
            </NotifMenuStyled>
        </StyledCheckboxMenu>
    );
}
