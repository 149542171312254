import styled from 'styled-components';
import { variables } from '../../../theme/variables';
import { Pagination } from '@material-ui/lab';

export const PaginationStyle = styled(Pagination)(({}) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    background: variables.backgoroundColours.light,
    border: `1.5px solid ${variables.backgoroundColours.border}`,
    boxSizing: 'border-box',
    borderRadius: '4px',
    '.MuiPaginationItem-rounded': {
        fontFamily: 'Roboto',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        color: variables.colours.default,
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            fontSize: '0.938rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            fontSize: '0.938rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 375px)': {
            margin: 0,
            padding: 0,
            minWidth: '1.563rem',
        },
    },
    '.MuiPaginationItem-page.Mui-selected': {
        color: variables.colours.light,
    },
    '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
        marginBottom: '0.5rem',
    },
    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        justifyContent: 'center',
    },
}));
