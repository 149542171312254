import React from 'react';
import { ContainerWithGradientStyled } from './style';

type ContainerWithGradientProps = {
    children: React.ReactNode;
    background?: string;
};

export default function ContainerWithGradient({
    children,
    background = 'linear-gradient(to bottom, #fff 0%, #f3dad1 25%, #e0cfd4 50%, #c2d1f5 75%, #e5edfd 100%)',
}: ContainerWithGradientProps): JSX.Element {
    return <ContainerWithGradientStyled background={background}>{children}</ContainerWithGradientStyled>;
}
