import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { Typography, ButtonBase, ButtonBaseProps, lighten } from '@material-ui/core';
import { variables } from 'theme/variables';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const IntroStyled = styled.div((props: { marginBottom: string }): any => ({
    position: 'relative',
    'z-index': '1',
    width: '100%',
    minHeight: '853px',
    padding: '95px 0 0',
    marginBottom: props.marginBottom,
    overflow: 'hidden',

    '> img': {
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        zIndex: '-1',
        width: '100%',
        height: '100%',

        '@media only screen and (max-width: 767px)': {
            width: '1792px',
            left: '50%',
            transform: 'translateX(-50%)',
        },
    },

    '.content': {
        display: 'flex',
        alignItems: 'stretch',

        '.left': {
            width: '100%',
            maxWidth: '620px',

            '.button-text': {
                color: variables.colours.light,
            },
        },

        '.right': {
            position: 'relative',
            flex: '1',

            '> img': {
                position: 'absolute',
                top: '40px',
                left: '0',
                zIndex: '-1',
                width: '794px',
                height: '444px',
            },
        },
    },
}));

export const IntroTitleStyled = styled(Typography).attrs({
    variant: 'h1',
})({
    marginBottom: '40px',
    fontFamily: 'MagistralCTT',
    'font-weight': '700',
    fontSize: '60px',
    lineHeight: '117%',
    letterSpacing: '0.05em',
    color: variables.colours.light,

    '.orange': {
        color: variables.colours.primary,
    },
});

export const IntroButtonStyled = styled(ButtonBase).attrs({
    component: Link,
})<ButtonBaseProps & LinkProps>({
    padding: '10px 90px',
    borderRadius: 8,
    backgroundColor: variables.buttonColours.primary,
    transition: '.2s ease',
    color: variables.colours.light,

    ':hover': {
        backgroundColor: lighten(variables.colours.primary, 0.15),
    },

    '.button-text': {
        color: variables.colours.light,
    },
});
