import styled from 'styled-components';

import { variables } from 'theme/variables';

import { StepIndicatorProps } from 'components/atoms/StepIndicator';

const INDICATOR_BASE_HEIGHT = 350;
const INDICATOR_HEIGHT = INDICATOR_BASE_HEIGHT * 0.3;
const INDICATOR_OFFSET = 8;

export const StyledStepIndicator = styled.div<StepIndicatorProps>(({ value, max }) => {
    const step = Math.max(0, value - 1);
    const basePart = (INDICATOR_BASE_HEIGHT - INDICATOR_OFFSET * 2) / max;
    const difference = INDICATOR_HEIGHT - basePart;

    const indicatorPosition = step * basePart - (value - 1 ? 1 : 0) * difference + INDICATOR_OFFSET;

    return {
        display: 'flex',
        position: 'relative',
        height: 350,
        marginRight: 40,

        ':before': {
            content: '""',
            display: 'inline-block',
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: 4,
            backgroundColor: variables.backgoroundColours.border,
            borderRadius: 5,
        },

        ':after': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: indicatorPosition,
            display: 'inline-block',
            background: 'linear-gradient(180deg, #FF6000 0%, rgba(241, 67, 54, 0.27) 119.17%)',
            height: INDICATOR_HEIGHT,
            width: 4,
            borderRadius: 5,
            marginRight: 20,
            transition: '.25s ease-in-out',
        },

        '@media only screen and (max-width : 768px)': {
            width: '100%',
            marginRight: 0,
            marginBottom: 20,
            marginTop: 40,
            height: 'fit-content',

            ':before': {
                content: '""',
                display: 'inline-block',
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: 4,
                backgroundColor: variables.backgoroundColours.border,
                borderRadius: 5,
            },

            ':after': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: `calc(${value - 1} * (100% / ${max}))`,
                display: 'inline-block',
                background: 'linear-gradient(180deg, #FF6000 0%, rgba(241, 67, 54, 0.27) 119.17%)',
                width: `calc(100% / ${max})`,
                height: 4,
                borderRadius: 5,
                marginRight: 20,
                transition: '.25s ease-in-out',
            },
        },
    };
});

export const StepIndicatorValue = styled.span({
    fontFamily: 'MagistralCTT',
    background: '-webkit-linear-gradient(#FF6000 0%, rgba(241, 67, 54, 0.27) 48.61%)',
    WebkitBackgroundClip: 'text',
    lineHeight: 1,
    WebkitTextFillColor: 'transparent',
    fontSize: 35,
    marginTop: 15,
    marginLeft: 20,
    letterSpacing: 2,
    userSelect: 'none',

    '@media only screen and (max-width : 768px)': {
        marginTop: -50,
    },
});
