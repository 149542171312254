import React, { useEffect, useState } from 'react';
import ResponsiveFlexBox from '../../ResponsiveFlexBox';
import { FormControlTextInputStyle } from './style';
import FilterInput from '../../FilterInput';
import MediaSpacesMultiselect from '../MediaSpacesMultiselect';
import DateFilter, { DateFilterData } from '../DateFilter';
import { FilterFlexBox } from '../FiltersHolder/style';

export type PlaylistsFilterProps = {
    onFilterChange: (filter: PlaylistsFilterData) => void;
};

export type PlaylistsFilterData = DateFilterData & {
    filterString: string;
    selectedMediaSpaces: string[];
};

export const DefaultPlaylistsFilterData = {
    filterString: '',
    selectedMediaSpaces: [],
    startDate: null,
    endDate: null,
} as PlaylistsFilterData;

export default function PlaylistsFilter(props: PlaylistsFilterProps): JSX.Element {
    const { onFilterChange } = props;

    const [filter, setFilter] = useState<PlaylistsFilterData>(DefaultPlaylistsFilterData);

    useEffect(() => {
        onFilterChange && onFilterChange(filter);
    }, [filter]);

    const onFilterStringChange = (filterString: string) => {
        setFilter({ ...filter, filterString: filterString });
    };

    const onMediaSpacesChange = (selectedMediaSpaces: string[]) => {
        setFilter({ ...filter, selectedMediaSpaces: selectedMediaSpaces });
    };

    return (
        <ResponsiveFlexBox width="100%" minHeight="2.813rem" justifyContent="space-between" className="filter-handler">
            <FilterFlexBox>
                <FormControlTextInputStyle>
                    <FilterInput onChange={onFilterStringChange} />
                </FormControlTextInputStyle>
                <FormControlTextInputStyle>
                    <MediaSpacesMultiselect displayField="name" onSelectionChanged={onMediaSpacesChange} />
                </FormControlTextInputStyle>
            </FilterFlexBox>
            <DateFilter
                onFilterChanged={(data: DateFilterData) => {
                    setFilter({ ...filter, startDate: data.startDate, endDate: data.endDate });
                }}
            />
        </ResponsiveFlexBox>
    );
}
