import styled from 'styled-components';

import { Box, Typography } from '@material-ui/core';

import Button from 'components/atoms/controls/Button';

import { variables } from 'theme/variables';

export const StyledBox = styled(Box).attrs({
    textAlign: 'right',
})({});

export const DisableButtonText = styled(Typography).attrs({
    variant: 'h6',
})({});

export const DisableButton = styled(Button)({
    paddingRight: 0,
    marginTop: '0.938rem',

    [DisableButtonText]: {
        color: variables.colours.primary,
    },
});
