import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { generatePath, Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Link as MuiLink } from '@material-ui/core';

import Button from 'components/atoms/controls/Button';
import Password from 'components/atoms/PasswordInput';
import FormControlTextInput from 'components/molecules/forms/FormControlTextInput';

import useAuth from 'hooks/useAuth';

import { logIn } from 'api/auth';
import { variables } from 'theme/variables';
import allRoutes from 'router';

import { LoginModel } from 'models/login';

import { LoginStyle } from 'components/organisms/forms/Login/style';
import { getConfig } from '../../../../config';

class IError {
    response?: {
        data?: {
            userMessage?: string;
        };
    };
}

export default function Login(): JSX.Element {
    const { t } = useTranslation();

    const { authorize } = useAuth();
    const history = useHistory();

    const [showReSendConfirmEmail, setShowReSendConfirmEmail] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [formData, setFormData] = useState<LoginModel>({
        userName: '',
        password: '',
        preferredLocalization: '',
    });

    const [config, setConfig] = useState<Config>({
        backendUrl: '/',
        frontendUrl: '/',
        landingUrl: '/',
        id: '',
        mapsApiKey: '',
        sentryDsn: '',
        sentryEnvironment: '',
        sentryTracesSampleRate: 1.0,
        isSentryEnabled: false,
        defaultLocale: '',
        defaultCurrency: '',
        contactsAddressEn: '',
        contactsAddressRu: '',
        contactsPhone: '',
        contactsEmail: '',
        contactsLongitude: 0,
        contactsLatitude: 0,
        contactsCompanyRu: '',
        contactsCompanyEn: '',
        supportTeamPhone: '',
        whatsAppLink: '',
        googleTagManagerId: '',
        facebookIsAvailable: false,
        fictitiousMode: false,
        detectBrowserLanguage: false,
        adminStartDateRangeInHours: 0,
        userStartDateRangeInDays: 0,
        userEndDateRangeInMonths: 0,
        limitMonthsForStartDate: 0,
        limitMonthsForEndDate: 0,
    });
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const login = async () => {
        if (formData.password !== '' && formData.userName !== '') {
            formData.preferredLocalization = localStorage.getItem('locale') ?? '';
            try {
                const {
                    data: { accessToken, refreshToken },
                } = await logIn(formData);
                authorize({ accessToken, refreshToken });
                localStorage.removeItem('currencyCode');
            } catch (error: unknown) {
                if (error instanceof IError) {
                    const isEmailNotConfirmed =
                        error.response?.data?.userMessage?.toString() === 'server-messages.email-not-confirmed';
                    setShowReSendConfirmEmail(isEmailNotConfirmed);
                    if (!isEmailNotConfirmed) {
                        toast.error(t('login-form.messages.error-incorent-login-or-password'));
                    }
                }
            }
        } else {
            toast.error(t('login-form.messages.error-empty-field'));
        }
    };

    const fictitiousLogin = async () => {
        if (formData.userName !== '') {
            formData.preferredLocalization = localStorage.getItem('locale') ?? '';
            try {
                const {
                    data: { accessToken, refreshToken },
                } = await logIn(formData);
                authorize({ accessToken, refreshToken });
                localStorage.removeItem('currencyCode');
                history.push(generatePath(allRoutes.orders.path, { pageId: 1 }));
            } catch (error: unknown) {
                if (error instanceof IError) {
                    const isEmailNotConfirmed =
                        error.response?.data?.userMessage?.toString() === 'server-messages.email-not-confirmed';
                    setShowReSendConfirmEmail(isEmailNotConfirmed);
                    if (!isEmailNotConfirmed) {
                        toast.error(t('login-form.messages.error-incorrect-login'));
                    }
                }
            }
        } else {
            toast.error(t('login-form.messages.error-empty-field-login'));
        }
    };

    const loginClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (!config.fictitiousMode) {
            await login();
        } else {
            await fictitiousLogin();
        }
    };

    useEffect(() => {
        const listener = async (event: { code: string }) => {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                await login();
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [formData]);

    useEffect(() => {
        if (!isDataLoaded) {
            const loadConfig = async () => {
                const data = await getConfig();
                setIsDataLoaded(true);
                setConfig(data);
            };

            loadConfig();
        }
    }, [config]);

    return (
        <LoginStyle>
            <FormControlTextInput
                name="username"
                value={formData.userName}
                onChangeValue={(value: string) => {
                    setFormData({ ...formData, userName: value });
                }}
                label={t('login-form.username-label')}
            />
            {!config.fictitiousMode && (
                <>
                    <Password
                        name="password"
                        label={t('login-form.password-label')}
                        value={formData.password}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                        iconColor="primary"
                        onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                            setFormData({ ...formData, password: value.target.value });
                        }}
                    />
                    <MuiLink
                        component={Link}
                        to={allRoutes.restorePassword.path}
                        className="button-handler forget-password-button"
                        data-id="forget-password"
                    >
                        <Box>
                            <Typography variant="body1" className="forget-password">
                                {t('navigation.forget-password')}
                            </Typography>
                        </Box>
                    </MuiLink>
                    {showReSendConfirmEmail && (
                        <MuiLink
                            component={Link}
                            to={allRoutes.confirmEmail.path}
                            className="confirmation-button-handler"
                            data-id="resend-confirmation"
                        >
                            <Typography variant="body1" color="secondary">
                                {t('navigation.resend-confirmation')}
                            </Typography>
                        </MuiLink>
                    )}
                </>
            )}
            <Box display="flex" justifyContent="flex-end" marginBottom="2.313rem" marginTop="1rem">
                <Button
                    onClick={loginClick}
                    color="primary"
                    variant="outlined"
                    name="login"
                    id="SEO-login-in-signin-page"
                >
                    <Typography variant="h5" className="continue">
                        {t('login-form.submit')}
                    </Typography>
                </Button>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
                <Box flexGrow="1" borderBottom={`0.188rem solid ${variables.colours.primary}`} />
                <Box padding="0 1.938rem">
                    <Typography variant="h5" className="or">
                        {t('general.or')}
                    </Typography>
                </Box>
                <Box flexGrow="1" borderBottom={`0.188rem solid ${variables.colours.primary}`} />
            </Box>
        </LoginStyle>
    );
}
