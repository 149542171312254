import styled from 'styled-components';

import { Avatar, Badge } from '@material-ui/core';

interface AvatarBadgeProps {
    $disabled: boolean | undefined;
}

export const AvatarBadge = styled(Badge).attrs<AvatarBadgeProps>(({ $disabled }) => ({
    overlap: 'circle',
    color: $disabled ? undefined : 'primary',
}))<AvatarBadgeProps>({});

export const AvatarImage = styled.div(({}) => ({
    borderRadius: '50%',

    img: {
        width: '168px',
        height: '168px',
        objectFit: 'cover',
        borderRadius: '50%',
    },
}));

export const AvatarContainer = styled(Avatar).attrs({
    sizes: '150',
    variant: 'circular',
})({
    height: '10.5rem',
    width: '10.5rem',

    '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
        width: '5.688rem',
        height: '5.688rem',
    },

    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        width: '5.688rem',
        height: '5.688rem',
    },
});

export const AvatarInput = styled.input.attrs({
    type: 'file',
    accept: 'image/png, image/jpeg',
})({
    display: 'none',
});
