import { REMOVE_USER, UPDATE_USER, UPDATE_LC, UserActionTypes, UserState } from 'store/user/types';

const InitialState: UserState = {
    user: {
        nameid: '',
        role: '',
        given_name: '',
        family_name: '',
        email: '',
        pic: '',
        hasSignedInOnce: true,
        lc: '',
        preferred_currency_id: '',
    },
};

const userReducer = (state = InitialState, action: UserActionTypes): UserState => {
    switch (action.type) {
        case UPDATE_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    nameid: action.data.nameid,
                    role: action.data.role,
                    given_name: action.data.given_name,
                    family_name: action.data.family_name,
                    email: action.data.email,
                    pic: action.data.pic,
                    hasSignedInOnce: action.data.hasSignedInOnce,
                    lc: action.data.lc,
                    preferred_currency_id: action.data.preferred_currency_id,
                },
            };
        }
        case REMOVE_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...InitialState.user,
                },
            };
        }
        case UPDATE_LC: {
            return {
                ...state,
                user: {
                    ...state.user,
                    lc: action.data,
                },
            };
        }
        default:
            return state;
    }
};

export default userReducer;
