/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC, useState, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { Formik, useFormikContext } from 'formik';
import { Typography } from '@material-ui/core';

import {
  StyledMultiStepForm,
  MultiStepContent,
  MultiStepHeader,
  MultiStepIcon,
  MultiStepTitle,
  MultiStepBackIcon,
  MultiStepBackButton,
} from 'components/organisms/MultiStepForm/style';
import StepIndicator from 'components/atoms/StepIndicator';

import { FormStep } from 'components/organisms/MultiStepForm/types';

import { ReactComponent as BackIcon } from 'assets/icons/Back.svg';

interface MultiStepFormContainerProps {
  initialValues: any;
  steps: FormStep[];
  onSubmit: (values: any) => void;
}

interface MultiStepFormProps {
  steps: FormStep[];
}

const MultiStepForm: FC<MultiStepFormProps> = ({ steps }) => {
  const { t } = useTranslation();

  const { values: outerValues, setValues: setOuterValues, submitForm } = useFormikContext<any>();

  const [currentStep, setCurrentStep] = useState<number>(0);
  const { component: StepComponent, title, icon: Icon } = steps[currentStep] || {};

  const nextStep = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [steps, currentStep, outerValues, submitForm]);

  const prevStep = useCallback(() => {
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  }, [setCurrentStep, currentStep]);

  const handleStepSubmit = useCallback(
    (values) => {
      const newValues = { ...outerValues, ...values };

      setOuterValues(newValues);

      if (currentStep === steps.length - 1) return submitForm();

      return nextStep();
    },
    [nextStep, setOuterValues, outerValues],
  );

  return (
    <StyledMultiStepForm>
      {currentStep > 0 && (
        <MultiStepBackButton onClick={prevStep}>
          <MultiStepBackIcon as={BackIcon} />
          <Typography component="span" color="inherit">
            {`${t('order-form.button-text.come-back')} ${currentStep}`}
          </Typography>
        </MultiStepBackButton>
      )}
      <StepIndicator value={currentStep + 1} max={steps.length} />
      <MultiStepContent>
        <MultiStepHeader>
          <MultiStepIcon as={Icon} />
          <MultiStepTitle>{title}</MultiStepTitle>
        </MultiStepHeader>
        <StepComponent currentStep={currentStep} onSubmit={handleStepSubmit} />
      </MultiStepContent>
    </StyledMultiStepForm>
  );
};

const MultiStepFormContainer: FC<MultiStepFormContainerProps> = ({ onSubmit, ...props }) => {
  const handleSubmit = useCallback((values) => onSubmit(values), [onSubmit]);

  return (
    <Formik enableReinitialize initialValues={props.initialValues} onSubmit={handleSubmit}>
      <MultiStepForm {...props} />
    </Formik>
  );
};

export default MultiStepFormContainer;
