import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { deleteChat, get } from '../../../api/chat';
import { ChatVm } from '../../../models/chat';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { StyledChatLinkContainer, DelChat, ChatsStyle, BadgeChat } from './style';
import Button from '../../atoms/controls/Button';
import { useHistory } from 'react-router-dom';
import FlashDialog from '../../atoms/dialog/FlashDialog';
import { MomentFormats } from '../../../shared/enums';
import moment from 'moment';
import { ChatMessageVm } from '../../../models/chat-message-model';
import YesNoButtons from '../../atoms/dialog/YesNoButtons';
import TextContent from '../../atoms/dialog/TextContent';
import LoadingIndicator from '../../atoms/LoadingIndicator';
import ChatForm from '../../organisms/forms/ChatForm';
import useWindowDimensions from '../../../hooks/WindowDimensions';
import { ReactComponent as ChatRemove } from '../../../assets/icons/ChatRemove.svg';
import { getDateWithLocalTz } from '../../../hooks/getDateWithLocalTz';

export type UserChatsProps = {
    lastMessage?: ChatMessageVm;
    isFilter: string;
    onNewMessage: (message: ChatMessageVm) => void;
    setMobileChat: React.Dispatch<React.SetStateAction<boolean>>;
    mobileChat: boolean;
};

export default function UserChats(props: UserChatsProps): JSX.Element {
    const { lastMessage, isFilter, onNewMessage, setMobileChat, mobileChat } = props;

    const [chats, setChats] = useState(Array<ChatVm>());
    const [filteredChats, setFilteredChats] = useState(Array<ChatVm>());
    const [isTriggeredLoad, setIsTriggeredLoad] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const [currentChat, setCurrentChat] = useState<ChatVm>();
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const [isModalToShow, setModalToShow] = useState<boolean>(false);
    const { width } = useWindowDimensions();

    useEffect(() => {
        // 1. Make a shallow copy of the items
        const chatsAvailable = [...chats];
        const chatToUpdate = chats.filter((x) => x.id === lastMessage?.chatId)[0];
        const index = chatsAvailable.indexOf(chatToUpdate);

        if (chatToUpdate && lastMessage) {
            // 2. Make a shallow copy of the item you want to mutate
            const item = { ...chatToUpdate };
            // 3. Replace the property you're intested in
            item.lastMessage = lastMessage.textMessage;
            item.lastMessageDate = lastMessage.createdDate;
            item.hasUnreadMessages = !lastMessage.isViewed;
            // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
            chatsAvailable[index] = item;
            // 5. Set the state to our new copy
            setChats(chatsAvailable);
        } else {
            setTimeout(() => getData(), 300);
        }
    }, [lastMessage]);

    const scrollToActiveChat = (id: string) => {
        if (id !== ':id') {
            const objControl = document.getElementById(id);
            if (objControl != null) {
                objControl.scrollIntoView();
            }
        }
    };

    useEffect(() => {
        if (id === ':id' && !currentChat && !mobileChat) {
            setCurrentChat(chats[0]);
        }
        if (!isTriggeredLoad) {
            setTimeout(() => getData(), 300);
        }
        scrollToActiveChat(id);
    }, [chats]);

    useMemo(() => {
        if (currentChat) {
            history.replace({
                pathname: `/chat/${currentChat?.id}`,
                state: { orderNumber: currentChat?.orderNumber, orderUid: currentChat?.orderUid },
            });
        }
        setIsTriggeredLoad(true);
    }, [currentChat]);

    const onDelConfirmClick = () => {
        setModalToShow(true);
    };

    const callDeleteChat = async () => {
        closeDeleteModal();
        setIsTriggeredLoad(true);
        await deleteChat(id);
        getData();
    };
    const closeDeleteModal = () => {
        setModalToShow(false);
    };

    useEffect(() => {
        if (id !== ':id' && chats.length > 0) {
            const chat = chats.filter((x) => x.id === id)[0];
            if (chat) {
                setCurrentChat(chat);
            } else {
                setCurrentChat(chats[0]);
            }
        }
    }, [id]);

    const getData = async () => {
        setIsLoaded(false);
        const chatsResponse = await get();
        chatsResponse.data.forEach((chat) => {
            chat.lastMessageDate = getDateWithLocalTz(new Date(chat.lastMessageDate)).toString();
        });
        setChats(chatsResponse.data);
        setIsLoaded(true);
    };

    const handleFilter = async () => {
        setFilteredChats(
            isFilter.length ? chats.filter((f) => f.orderNumber.toString().indexOf(isFilter) >= 0) : chats,
        );
    };

    useEffect(() => {
        handleFilter();
    }, [isFilter, chats]);

    return (
        <>
            {mobileChat && id !== ':id' ? (
                <ChatForm onNewMessage={onNewMessage} />
            ) : (
                <ChatsStyle>
                    <LoadingIndicator isLoaded={isLoaded} />
                    {filteredChats.map((chat) => (
                        <StyledChatLinkContainer key={chat.id} className={id === chat.id ? 'current' : ''}>
                            <Button
                                fullWidth
                                onClick={() => {
                                    setCurrentChat(chat);
                                    setMobileChat(width < 768);
                                }}
                                name="set-chat"
                                id={chat.id}
                            >
                                <div>
                                    <Typography
                                        variant="subtitle2"
                                        className={chat.hasUnreadMessages ? 'unread-message-date' : 'last-message-date'}
                                    >
                                        {chat.lastMessageDate
                                            ? moment(chat.lastMessageDate).format(MomentFormats.Long)
                                            : ''}
                                    </Typography>
                                </div>
                                <Typography
                                    variant="body1"
                                    className={chat.hasUnreadMessages ? 'unread-order-title' : 'order-title'}
                                >
                                    <Box display="flex" alignItems="center">
                                        <span className="chat-order-header">
                                            {t('general.order')}
                                            {chat.orderNumber}
                                        </span>
                                        {chat.hasUnreadMessages && (
                                            <BadgeChat color="primary">
                                                <img height="29px" width="24.29px" />
                                            </BadgeChat>
                                        )}
                                    </Box>
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    className={chat.hasUnreadMessages ? 'unread-message' : 'message'}
                                >
                                    {chat.lastMessage}
                                </Typography>
                            </Button>
                            <Box className="delete-chat-button">
                                {id === chat.id && (
                                    <DelChat>
                                        <Button onClick={onDelConfirmClick} name="delete-chat">
                                            <ChatRemove />
                                        </Button>
                                    </DelChat>
                                )}
                            </Box>
                        </StyledChatLinkContainer>
                    ))}
                    <FlashDialog
                        open={isModalToShow}
                        isOpen={isModalToShow}
                        onClose={closeDeleteModal}
                        controls={<YesNoButtons onConfirm={callDeleteChat} onReject={closeDeleteModal} />}
                        content={<TextContent text={t('chat-form.dialogMessageToDeleteChat')} />}
                        title={t('chat-form.deleteAllMessages')}
                    />
                </ChatsStyle>
            )}
        </>
    );
}
