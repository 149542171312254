import { FC, Fragment, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import MediaDurationInput from 'components/atoms/Order/MediaDurationInput';
import FullScreenImage from 'components/atoms/FullScreenImage';

import { MediaFormValues } from 'components/organisms/MediaSubForm';
import VideoPlayer from 'components/atoms/VideoPlayer';

import {
    RemoveButton,
    TimeLabel,
    ImageControls,
    RemoveButtonIcon,
    RemoveButtonText,
} from 'components/atoms/MediaPreview/style';

const MediaPreview: FC = () => {
    const { t } = useTranslation();

    const { values, setFieldValue, setValues } = useFormikContext<MediaFormValues>();

    const isVideo = !!values.contentType && values.contentType.includes('video/');

    const removeContent = useCallback(() => {
        setValues({
            ...values,
            content: undefined,
            contentType: undefined,
            contentUrl: '',
            contentDuration: 0,
        });
    }, [setValues, values]);

    const handleDuration = useCallback((value) => setFieldValue('contentDuration', value), [setFieldValue]);

    return (
        <Fragment>
            {isVideo ? (
                <VideoPlayer
                    controls
                    src={values.contentUrl}
                    onLoadedMetadata={({ currentTarget: { duration } }) =>
                        setFieldValue('contentDuration', Math.floor(duration))
                    }
                />
            ) : (
                <FullScreenImage height="390px" src={values.contentUrl} alt={t('order-form.image-preview')} />
            )}
            <RemoveButton onClick={removeContent}>
                <RemoveButtonText>{t('media-space-form.button-text.delete')}</RemoveButtonText>
                <RemoveButtonIcon as={HighlightOffIcon} />
            </RemoveButton>
            {!isVideo && (
                <ImageControls>
                    <TimeLabel>{t('order-form.time-img-description')}</TimeLabel>
                    <MediaDurationInput value={values.contentDuration} min={1} max={60} onChange={handleDuration} />
                </ImageControls>
            )}
        </Fragment>
    );
};

export default MediaPreview;
