const envType = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development';

switch (envType) {
    case 'production':
        break;
    case 'stage':
        break;
}

export async function getConfig(): Promise<Config> {
    if (!window.ENV || !window.ENV.config) {
        const response = await fetch('/config.json');
        const config = await response.json();
        window.ENV = {
            config: {
                backendUrl: config['BASE_API_URL'].toString(),
                frontendUrl: config['FRONTEND_URL'].toString(),
                landingUrl: config['LANDING_URL'].toString(),
                id: config['GUID'].toString(),
                mapsApiKey: config['MAPS_API_KEY'].toString(),
                isSentryEnabled: config['SENTRY_ENABLED'].toString() == 'true',
                sentryDsn: config['SENTRY_DSN'].toString(),
                sentryEnvironment: config['SENTRY_ENVIRONMENT'].toString(),
                sentryTracesSampleRate: +config['SENTRY_TRACES_SAMPLE_RATE'].toString(),
                defaultLocale: config['DEFAULT_LOCALE'].toString(),
                defaultCurrency: config['DEFAULT_CURRENCY'].toString(),
                contactsAddressRu: config['CONTACTS_ADDRESS_RU'].toString(),
                contactsAddressEn: config['CONTACTS_ADDRESS_EN'].toString(),
                contactsPhone: config['CONTACTS_PHONE'].toString(),
                contactsEmail: config['CONTACTS_EMAIL'].toString(),
                contactsLongitude: config['CONTACTS_LONGITUDE'].toString(),
                contactsLatitude: config['CONTACTS_LATITUDE'].toString(),
                contactsCompanyRu: config['CONTACTS_COMPANY_RU'].toString(),
                contactsCompanyEn: config['CONTACTS_COMPANY_EN'].toString(),
                supportTeamPhone: config['SUPPORT_TEAM_PHONE'].toString(),
                whatsAppLink: config['WHATSAPP_LINK'].toString(),
                googleTagManagerId: config['GOOGLE_TAG_MANAGER_ID'].toString(),
                facebookIsAvailable: config['FACEBOOK_IS_AVAILABLE'].toString() == 'true',
                fictitiousMode: config['FICTITIOUS_MODE'].toString() == 'true',
                detectBrowserLanguage: config['DETECT_BROWSER_LANGUAGE'].toString() == 'true',
                adminStartDateRangeInHours: config['ADMIN_START_DATE_RANGE_IN_HOURS'],
                userStartDateRangeInDays: config['USER_START_DATE_RANGE_IN_DAYS'],
                userEndDateRangeInMonths: config['USER_END_DATE_RANGE_IN_MONTHS'],
                limitMonthsForStartDate: config['LIMIT_MONTHS_FOR_START_DATE'],
                limitMonthsForEndDate: config['LIMIT_MONTHS_FOR_END_DATE'],
            },
        };
    }

    return window.ENV.config;
}
