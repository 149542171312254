import styled from 'styled-components';

import { IconButton as Icon, Button, Typography, Box } from '@material-ui/core';

import SubmitButton from 'components/atoms/controls/SubmitButton';
import FormControlTextInput from 'components/molecules/forms/FormControlTextInput';

import { variables } from 'theme/variables';

export const StyledProfile = styled.div(({}) => ({
    marginLeft: '1.25rem',
    marginTop: '-1.875rem',

    '@media only screen and (min-width: 320px) and (max-width: 1024px)': {
        marginTop: 0,
    },

    '@media only screen and (min-width: 320px) and (max-width: 425px)': {
        marginLeft: 0,
    },
}));

export const Header = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.875rem',

    svg: {
        fontSize: '1.875rem!important',
    },

    '.MuiChip-root': {
        marginTop: '0.625rem',
        alignSelf: 'flex-end',
    },
});

export const StyledBox = styled(Box)({
    textAlign: 'end',
    marginBottom: '1.25rem',

    '@media only screen and (min-width: 320px) and (max-width: 767px)': {
        textAlign: 'center',
        marginBottom: '1.875rem',
    },
});

export const StyledTextInput = styled(FormControlTextInput).attrs({
    color: 'secondary',
})({
    width: '100%',
    marginBottom: '1.25rem',

    '.MuiInputBase-input': {
        paddingBottom: '0.625rem',
    },
});

export const DeviceName = styled.div(({}) => ({
    position: 'relative',
}));

export const IconButton = styled(Icon).attrs({
    color: 'default',
})({
    position: 'absolute',
    top: 3,
    right: 0,
});

export const ButtonText = styled(Typography).attrs({
    variant: 'h5',
})({
    color: variables.colours.light,
});

interface ButtonProps {
    $disabled: boolean;
}

export const ViewButton = styled(Button).attrs<ButtonProps>(({ $disabled }) => ({
    type: 'submit',
    variant: 'outlined',
    color: $disabled ? 'default' : 'primary',
}))<ButtonProps>(() => ({
    border: `2px solid ${variables.colours.primary}`,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    ':hover': {
        border: `2px solid ${variables.colours.primary}`,
        [ButtonText]: {
            color: variables.colours.primary,
        },
        path: {
            fill: variables.colours.primary,
        },
    },

    '&.Mui-disabled': {
        backgroundColor: variables.backgoroundColours.border,
        border: `2px solid ${variables.backgoroundColours.border}`,
    },
}));

export const SaveButton = styled(SubmitButton).attrs<ButtonProps>(({ $disabled }) => ({
    type: 'submit',
    variant: 'outlined',
    color: $disabled ? 'default' : 'primary',
}))<ButtonProps>({
    border: `2px solid ${variables.colours.primary}`,

    ':hover': {
        border: `2px solid ${variables.colours.primary}`,
        [ButtonText]: {
            color: variables.colours.primary,
        },
    },
});

export const ButtonHolder = styled.div({
    display: 'flex',
    justifyContent: 'center',
});

export const StyledDataGridFooter = styled.div({
    display: 'flex',
    marginTop: '0.625rem',
    marginBottom: '0.625rem',

    '& > *': {
        margin: 0,

        '&:first-child': {
            marginRight: '1.25rem',
            border: `1.5px solid ${variables.colours.default}`,
        },

        '&:last-child': {
            border: `1.5px solid ${variables.colours.default}`,
        },
    },

    '@media only screen and (min-width : 320px) and (max-width : 767px)': {
        flexDirection: 'column',

        '.spacer': {
            display: 'none',
            border: 'none',
        },

        '& > *': {
            marginBottom: '0.5rem',

            '&:first-child': {
                marginRight: 0,
            },
        },
    },
});

export const DeviceActions = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(4),
}));

export const DeviceButtonIcon = styled.svg({
    marginRight: '1.25rem',
    path: {
        fill: variables.colours.light,
    },
});
