import * as React from 'react';
import { GridColDef, GridSortModel } from '@material-ui/data-grid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getPage } from '../../../api/orders';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import allRoutes from '../../../router';
import PagedDataGrid from '../../organisms/PagedDataGrid';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/rootReducer';
import { MomentFormats, UserRoles } from '../../../shared/enums';
import HasNoOrdersMessage from '../../molecules/Home/HasNoOrdersMessage';
import { OrderStatusType } from '../../../shared/enums';
import OrderFilter, { DefaultFilterData, OrderFilterData } from '../../atoms/gridFilters/OrderFilter';
import PagedDataGridUser from '../../organisms/PagedDataGridUser';
import { PageSize } from '../../../shared/constants';
import NoDataMessage from '../../atoms/NoDataMessage';
import OrderCostSummary from '../../atoms/Order/OrderCostSummary';
import { StyledOrderList } from './style';
import { Avatar, Tooltip, Typography } from '@material-ui/core';
import { getPhotoSrc } from '../../../api/photo';
import { Box } from '@material-ui/core';
import FilteredTemplate from '../../templates/FilteredTemplate';
import { CostCurrencySum, OrderPage, OrderVm } from '../../../models/order';
import moment from 'moment';
import { variables } from '../../../theme/variables';
import LoadingIndicator from '../../atoms/LoadingIndicator';
import { NameField, OrderRow, SortDirections, SortDirectionsInteger, SortField } from './types';
import OrdersColumnsGrid from './ColumnsGrid';
import { useCallback } from 'react';

export default function OrdersPage(): JSX.Element {
    const { t } = useTranslation();

    const user = useSelector((state: RootState) => state.userReducer.user);
    const auth = useSelector((state: RootState) => state.authReducer.auth);

    const [rows, setRows] = useState<OrderRow[]>([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isLoadingIndicator, setIsLoadingIndicator] = useState(true);
    const [isTriggeredLoad, setIsTriggeredLoad] = useState(false);
    const [isGridLoaded, setIsGridLoaded] = useState(true);

    const [totalItem, setTotalItem] = useState<number>(0);
    const [checked, setChecked] = useState<string[]>([]);
    const [orders, setOrders] = useState<OrderVm[]>([]);

    const [valField, setField] = useState<string | undefined>(NameField.Number);
    const [valSort, setSort] = useState<number | null | undefined>(SortDirectionsInteger.Desc);
    const [filter, setFilter] = useState<OrderFilterData>(DefaultFilterData);
    const [totalPage, setTotalPage] = useState<number>(1);
    const [page, setPage] = useState(1);
    const [costSum, setCostSum] = useState(0);
    const [costCurrencySum, setCostCurrencySum] = useState<CostCurrencySum[]>([]);
    const [isAdminOperatorOrder, setIsAdminOperatorOrder] = useState<boolean | undefined>(undefined);

    const { pageId } = useParams<{ pageId: string }>();
    const location = useLocation();

    useEffect(() => {
        const match = matchPath(location.pathname, {
            path: allRoutes.adminOrders.path,
            exact: true,
            strict: false,
        });

        setIsAdminOperatorOrder(match?.isExact || false);
    }, [location.pathname]);

    const loadOrdersWithRequest = async (reqVal: OrderPage) => {
        setRows([]);
        setOrders([]);
        setIsLoadingIndicator(false);

        const pageContent = await getPage(reqVal);
        const totalPageNumber = Math.ceil(pageContent.total / pageContent.pageSize);
        setTotalPage(totalPageNumber);
        setTotalItem(pageContent.total);
        setCostSum(pageContent.costSum);
        setCostCurrencySum(pageContent.costCurrencySum);
        if (user.role !== UserRoles.User) {
            const rows: OrderRow[] = [];
            pageContent.data.forEach((element) => {
                const endDate =
                    element.schedules.length > 0 &&
                    element.schedules.reduce((prev, current) => (prev.endDate > current.endDate ? prev : current))
                        .endDate;
                const startDate =
                    element.schedules.length > 0 &&
                    element.schedules.reduce((prev, current) => (prev.startDate < current.startDate ? prev : current))
                        .startDate;
                rows.push({
                    id: element.id,
                    number: element.number,
                    createdDate: new Date(Date.parse(element.createdDate)).toLocaleString('ru', {
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                    }),
                    userId: element.userId,
                    userName: (
                        <>
                            <Box marginRight="0.813rem">
                                <Avatar
                                    style={{ width: '47px', height: '47px' }}
                                    alt={element.userFullName && element.userFullName}
                                    src={getPhotoSrc(element.photoUrl)}
                                >
                                    <Typography variant="body1" className="user-name">
                                        {element.userFullName && element.userFullName[0]}
                                    </Typography>
                                </Avatar>
                            </Box>
                            <Tooltip
                                title={
                                    <Typography
                                        variant="body1"
                                        style={{ color: variables.colours.light, lineHeight: '137.01%' }}
                                    >
                                        {element.userFullName}
                                    </Typography>
                                }
                                placement="top-start"
                                arrow
                            >
                                <Typography variant="body1">{element.userFullName}</Typography>
                            </Tooltip>
                        </>
                    ),
                    dateReply: (
                        <Box width="100%">
                            {startDate == endDate ? (
                                <Typography variant="body1">
                                    {startDate && moment(startDate).format(MomentFormats.Short)}
                                </Typography>
                            ) : (
                                <Typography variant="body1">
                                    {startDate && moment(startDate).format(MomentFormats.Short)}-
                                    {element.schedules.length > 1 && <>...</>}
                                    <br />
                                    {endDate && moment(endDate).format(MomentFormats.Short)}
                                </Typography>
                            )}
                        </Box>
                    ),
                    status: element.status,
                    cost:
                        element.schedules.reduce((sum, current) => sum + current.cost, 0) + ' ' + element.currencyCode,
                });
            });
            setRows(rows);
        } else setOrders(pageContent.data);
        setIsDataLoaded(true);
        setIsLoadingIndicator(true);
    };

    const loadOrders = useCallback(
        async (createdDate?: string | undefined, userName?: string | undefined, orderNumber?: number | undefined) => {
            const reqVal = {
                userId: user.role === UserRoles.User ? user.nameid : undefined,
                page: page,
                Number: orderNumber,
                UserName: userName,
                CreatedDate: createdDate,
                SortProperty: valField,
                SortDirection: valSort,
                filterString: filter.filterString,
                Status: filter.status === OrderStatusType.All ? undefined : filter.status,
                StartedAt: filter.startDate ? filter.startDate : undefined,
                FinishedAt: filter.endDate ? filter.endDate : undefined,
                PageSize: PageSize,
                IsAdminOperator: isAdminOperatorOrder,
            };
            setTimeout(() => loadOrdersWithRequest(reqVal), 300);
        },
        [
            page,
            filter.filterString,
            filter.status,
            filter.startDate,
            filter.endDate,
            user.role,
            valField,
            valSort,
            PageSize,
            isAdminOperatorOrder,
        ],
    );

    const columns: GridColDef[] = OrdersColumnsGrid({
        rows,
        checked,
        setChecked,
        setIsGridLoaded,
        loadOrders,
    });

    useEffect(() => {
        orders.forEach((o) => {
            o.mediaSpaces.forEach((x) => {
                x.name = x.names.find((n) => n.languageCode === user.lc)?.name || '';
                x.address = x.names.find((n) => n.languageCode === user.lc)?.address || '';
            });
        });
        setOrders(orders);
    }, [user.lc]);

    useEffect(() => {
        if (!isTriggeredLoad && auth.accessToken && user.nameid && isAdminOperatorOrder !== undefined) {
            setIsTriggeredLoad(true);
            const getData = async () => {
                await loadOrders();
            };
            setTimeout(() => getData(), 100);
        }
    }, [isTriggeredLoad, auth, user, isAdminOperatorOrder]);

    useEffect(() => {
        if (isDataLoaded && isAdminOperatorOrder !== undefined) {
            page === 1 ? loadOrders() : setPage(1);
        }
    }, [isAdminOperatorOrder]);

    useEffect(() => {
        if (isDataLoaded) {
            page === 1 ? loadOrders() : setPage(1);
        }
    }, [filter]);

    useEffect(() => {
        if (pageId) {
            //fix url address for default user's order page, redirect to page 1
            if (pageId === ':id') {
                setPage(1);
            } else {
                const page = Number(pageId);
                setPage(page);
            }
        } else {
            setPage(1);
        }
    }, [pageId]);

    useEffect(() => {
        if (isDataLoaded) loadOrders();
    }, [page]);

    const handleFilterChange = (filter: OrderFilterData) => {
        setFilter(filter);
    };

    const getSorting = async (model: GridSortModel) => {
        if (model.length == 0) {
            model.push({ sort: SortDirections.Asc, field: NameField.Number });
        }

        const sortModel = model[0];
        let field = sortModel.field;
        const sort = sortModel.sort;

        let sortValue = SortDirectionsInteger.Asc;
        if (sort != SortDirections.Asc) {
            setSort(SortDirectionsInteger.Desc);
            sortValue = SortDirectionsInteger.Desc;
        }

        if (field == NameField.UserName) {
            field = SortField.UserName;
        } else if (field == NameField.Number) {
            field = SortField.Number;
        } else if (field == NameField.CreatedDate) {
            field = SortField.CreatedDate;
        }
        setField(field);
        const reqValue = {
            userId: undefined,
            page: page,
            UserName: undefined,
            Number: undefined,
            CreatedDate: undefined,
            SortProperty: field,
            SortDirection: sortValue,
            StartedAt: filter.startDate ? filter.startDate : undefined,
            FinishedAt: filter.endDate ? filter.endDate : undefined,
            PageSize: PageSize,
        };
        setTimeout(() => loadOrdersWithRequest(reqValue), 300);
    };

    return (
        <>
            {user.role === UserRoles.User ? (
                <>
                    {totalItem > 0 && (
                        <PagedDataGridUser
                            totalItems={totalPage}
                            defaultPage={page}
                            orders={orders}
                            summaryElement={<OrderCostSummary costs={costSum} costCurrencySum={costCurrencySum} />}
                        />
                    )}
                </>
            ) : (
                <StyledOrderList>
                    <FilteredTemplate
                        header={t('orders.header')}
                        createItemPageRoute={allRoutes.createOrder}
                        filter={<OrderFilter onChange={handleFilterChange} />}
                        content={
                            <>
                                {isGridLoaded ? (
                                    <PagedDataGrid
                                        isDataLoaded={isDataLoaded}
                                        isLoadingIndicator={isLoadingIndicator}
                                        totalPages={totalPage}
                                        totalItems={totalItem}
                                        noDataMessage={<NoDataMessage />}
                                        defaultPage={page}
                                        columns={columns}
                                        rows={rows}
                                        rowsPerPageOptions={[]}
                                        filterMode="server"
                                        onSortModelChange={(model: GridSortModel) => {
                                            getSorting(model);
                                        }}
                                        summaryElement={
                                            <OrderCostSummary costs={costSum} costCurrencySum={costCurrencySum} />
                                        }
                                    />
                                ) : (
                                    <LoadingIndicator isLoaded={isGridLoaded} />
                                )}
                            </>
                        }
                    />
                </StyledOrderList>
            )}
            {isDataLoaded && totalItem === 0 && user.role === UserRoles.User && <HasNoOrdersMessage />}
        </>
    );
}
