import { combineReducers } from 'redux';

import userReducer from 'store/user';
import authReducer from 'store/auth';
import themeReducer from 'store/theme';
import menuReducer from 'store/menu';
import priceFilterReducer from 'store/priceFilter';
import deviceFilterReducer from 'store/deviceFilter';
import formReducer from 'store/form';

export const rootReducer = combineReducers({
    userReducer,
    authReducer,
    themeReducer,
    menuReducer,
    priceFilterReducer,
    deviceFilterReducer,
    formReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
