import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import ProtectionTemplate from '../../templates/ProtectionTemplate';
import { useTranslation } from 'react-i18next';

export default function PrivacyPage(): JSX.Element {
    const { t } = useTranslation();
    return (
        <ProtectionTemplate>
            <Typography variant="h1">{t('general.privacy-policy-title')}</Typography>
            <Box marginTop="0.813rem">
                <Typography variant="body1">{t('general.privacy-policy-text')}</Typography>
            </Box>
        </ProtectionTemplate>
    );
}
