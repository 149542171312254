export const UPDATE_ACCESS = `UPDATE_ACCESS`;
export const UPDATE_REFRESH = `UPDATE_REFRESH`;
export const REMOVE_TOKENS = `REMOVE_TOKENS`;
export const SET_NEXT_PATH = 'SET_NEXT_PATH';
export const DELETE_NEXT_PATH = 'DELETE_NEXT_PATH';

export interface Access {
    accessToken: string;
    accessTokenTTL: number;
}

export interface Refresh {
    refreshToken: string;
    refreshTokenTTL: number;
}

export interface AuthState {
    auth: {
        accessToken?: string;
        accessTokenTTL: number;
        refreshToken?: string;
        refreshTokenTTL?: number;
    };
    nextPath: string;
}

export interface UpdateAccessAction {
    type: typeof UPDATE_ACCESS;
    accessData: Access;
}

export interface UpdateRefreshAction {
    type: typeof UPDATE_REFRESH;
    refreshData: Refresh;
}

export interface RemoveTokensAction {
    type: typeof REMOVE_TOKENS;
}

export interface SetNextPath {
    type: typeof SET_NEXT_PATH;
    path: string;
}

export interface DeleteNextPath {
    type: typeof DELETE_NEXT_PATH;
}

export type AuthActionTypes =
    | UpdateAccessAction
    | UpdateRefreshAction
    | RemoveTokensAction
    | SetNextPath
    | DeleteNextPath;
