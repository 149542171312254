import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { Typography } from '@material-ui/core';

import { StyledInfo, Title, Characteristic } from 'components/molecules/Device/DeviceInfo/style';

import { DeviceVm, StorageType } from 'models/device.model';
import { MomentFormats } from 'shared/enums';
import { getDateWithLocalTz } from '../../../../hooks/getDateWithLocalTz';

export interface DeviceInfoProps {
    values: DeviceVm;
}

const DeviceInfo: FC<DeviceInfoProps> = ({ values }) => {
    const { t } = useTranslation();

    return (
        <StyledInfo>
            <Characteristic>
                <Title>{t('device-profile.registration-date')}</Title>
                <Typography>{moment(getDateWithLocalTz(values.createdDate)).format(MomentFormats.Long)}</Typography>
            </Characteristic>
            <Characteristic>
                <Title>{t('device-profile.last-connection')}</Title>
                <Typography>
                    {moment(getDateWithLocalTz(values.lastConnectionDate)).format(MomentFormats.Long)}
                </Typography>
            </Characteristic>
            <Characteristic>
                <Title>{t('device-profile.mac-address')}</Title>
                <Typography>{values.macAddress}</Typography>
            </Characteristic>
            <Characteristic>
                <Title>{t('device-profile.ip-address')}</Title>
                <Typography>{values.ipAddress}</Typography>
            </Characteristic>
            <Characteristic>
                <Title>{t('device-profile.device-type')}</Title>
                <Typography>{values.osBuild}</Typography>
            </Characteristic>
            <Characteristic>
                <Title>{t('device-profile.operating-system')}</Title>
                <Typography>{values.operationSystemName}</Typography>
            </Characteristic>
            <Characteristic>
                <Title>{t('device-profile.app-version')}</Title>
                <Typography>{values.applicationVersion}</Typography>
            </Characteristic>
            <Characteristic>
                <Title>{t('device-profile.memory')}</Title>
                <Typography>
                    {values.availableMemory < 1024 &&
                        values.usedMemory +
                            t('device-profile.megabyte') +
                            values.availableMemory +
                            ' / ' +
                            t('device-profile.megabyte')}
                    {values.availableMemory >= 1024 &&
                        (values.usedMemory / 1024).toFixed(2) +
                            t('device-profile.gigabyte') +
                            ' / ' +
                            (values.availableMemory / 1024).toFixed(2) +
                            t('device-profile.gigabyte')}
                </Typography>
            </Characteristic>
            <Characteristic>
                <Title>{t('device-profile.storage-type.title')}</Title>
                <Typography>
                    {values.storageType === StorageType.External
                        ? t('device-profile.storage-type.external')
                        : t('device-profile.storage-type.iternal')}
                </Typography>
            </Characteristic>
            <Characteristic>
                <Title>{t('device-profile.device-temperature')}</Title>
                <Typography>{values.cpuTemp.toFixed(1)}</Typography>
            </Characteristic>
        </StyledInfo>
    );
};

export default DeviceInfo;
