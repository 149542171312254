import styled from 'styled-components';
import { variables } from '../../../theme/variables';

type TransferListStyle = {
    disabled: boolean;
};

export const TransferListStyle = styled.div<TransferListStyle>(({ disabled }) => ({
    background: variables.backgoroundColours.light,
    marginTop: '0.625rem',
    width: '100%',
    '.list': {
        width: '35%',
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            width: '88%',
        },
    },
    '.MuiPaper-rounded': {
        border: `1px solid ${disabled ? variables.colours.default : variables.colours.primary}`,
        boxSizing: 'border-box',
        borderRadius: '4px',
        width: '100%',
        height: '32.813rem',
        overflow: 'auto',
        minWidth: '13rem',
        '.MuiTypography-displayBlock': {
            color: variables.colours.default,
            fontWeight: 400,
            textTransform: 'uppercase',
        },
        '::-webkit-scrollbar': {
            height: '5px',
            width: '5px',
            padding: '10px',
        },
        '::-webkit-scrollbar-thumb': {
            background: variables.colours.main,
            borderRadius: '29px',
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: variables.backgoroundColours.primary,
        },
        '::-webkit-scrollbar-track': {
            borderRadius: '0px',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            height: '20.813rem',
        },
    },
    '.MuiButton-root': {
        border: 0,
        lineHeight: '137.01%',
        color: variables.colours.primary,
        p: {
            textAlign: 'right',
            textDecoration: 'line-through',
            width: '3.125rem',
        },
    },
    '.MuiButton-root:disabled': {
        color: variables.backgoroundColours.border,
    },
    '.arrow': {
        fontSize: '2.188rem',
        '@media only screen and (min-width: 768px) and (max-width: 1366px)': {
            fontSize: '1.875rem',
        },
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            fontSize: '1.563rem',
            transform: 'rotate(90deg)',
        },
    },
    '.grid-container': {
        '@media only screen and (min-width : 320px) and (max-width : 767px)': {
            display: 'grid',
            '.MuiGrid-container': {
                flexDirection: 'row',
                padding: '1rem 0 1rem 0',
            },
        },
    },
    '.MuiListItem-gutters': {
        '@media only screen and (min-width : 320px) and (max-width : 374px)': {
            paddingRight: 0,
        },
    },
    '.all-mediaspaces': {
        '@media only screen and (min-width : 320px) and (max-width : 374px)': {
            paddingRight: 0,
            label: {
                marginRight: 0,
                marginLeft: -20,
            },
        },
    },
}));
