import { FC, useMemo, ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import NotificationLink from 'components/atoms/controls/notifications/NotificationLink';

import useSelector from 'hooks/useAppSelector';

import { UserRoles } from 'shared/enums';

import { ReactComponent as OrderNotificationIcon } from 'assets/icons/OrderNotification.svg';

export type NotificationItemProps = {
    orderNumber: number;
    orderUserId?: string;
    onClick: () => void;
    orderId?: string;
    chatId: string;
};

const OrderCreateNotificationLink: FC<NotificationItemProps> = ({
    orderNumber,
    orderUserId,
    onClick,
    orderId,
    chatId,
}) => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.userReducer.user);

    const isUserView = useMemo(() => {
        return user.role === UserRoles.User || orderUserId === user.nameid;
    }, [user, orderUserId]);

    const header: ReactNode = isUserView ? t('order.status-lable.created') : t('order.status-lable.created-from-admin');

    const body: ReactNode[] = isUserView
        ? [t('order.order'), orderNumber, t('order.status-lable.created-coment')]
        : [t('order.order'), orderNumber];

    return (
        <NotificationLink
            orderId={orderId}
            chatId={chatId}
            icon={<OrderNotificationIcon />}
            onClick={onClick}
            header={header}
            body={body}
        />
    );
};

export default OrderCreateNotificationLink;
