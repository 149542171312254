import React, { Dispatch, SetStateAction, useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import moment from 'moment';

import { Typography } from '@material-ui/core';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import { GridCellParams, GridColDef } from '@material-ui/data-grid';

import CustomCheckbox from 'components/atoms/Checkbox';
import DateRenderer from 'components/atoms/DateRenderer';
import CheckboxMenu from 'components/atoms/CheckboxMenu';
import GridCellLink from 'components/atoms/GridCellLink';

import allRoutes from 'router';
import { MomentFormats } from 'shared/enums';
import { PriceGridVm } from 'models/price.model';
import { MediaSpaceShortVm } from 'models/media-space.model';

import { variables } from 'theme/variables';
import FlashGridHeader from '../../atoms/FlashGridHeader';
import { SortField } from '../../../models/paging/paging.model';
import FlashTooltip from 'components/atoms/FlashTooltip';

export type PriceColumnsGridProps = {
    setModalToShow: (value: SetStateAction<boolean>) => void;
    setChecked: Dispatch<SetStateAction<string[]>>;
    prices: PriceGridVm[];
    checked: string[];
};

export default function PriceColumnsGrid(props: PriceColumnsGridProps): GridColDef[] {
    const { setModalToShow, setChecked, prices, checked } = props;
    const { t } = useTranslation();
    const today = moment().format();
    const history = useHistory();

    function dataStyle(endsOn: Date | undefined, endDate: Date | undefined) {
        const priceDate = endsOn !== null ? moment(endsOn).format() : moment(endDate).format();
        if (priceDate) {
            if (priceDate < today) return 'disableColor';
        }
        return '';
    }

    const getColumns = useCallback(() => {
        return [
            {
                field: SortField.PriceId,
                width: 60,
                sortable: false,
                disableClickEventBubbling: true,
                align: 'left',
                headerAlign: 'left',
                headerName: 'Menu',
                renderHeader: function renderHeader() {
                    return (
                        <CheckboxMenu
                            actions={[
                                {
                                    action: () => {
                                        setModalToShow(true);
                                    },
                                    label: t('price-form.delete-marked'),
                                },
                            ]}
                            setChecked={setChecked}
                            rows={prices}
                        />
                    );
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return <CustomCheckbox params={params} checked={checked} setChecked={setChecked} />;
                },
            },
            {
                field: SortField.PriceName,
                flex: 0.6,
                headerAlign: 'left',
                align: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'price.name'} withPadding={false} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.priceProfile.path, { id: params.row.id }),
                                state: history.location.pathname,
                            }}
                        >
                            <Typography variant="body1" className={dataStyle(params.row.endsOn, params.row.endDate)}>
                                {params.row.name}
                            </Typography>
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.PriceStartDate,
                width: 198,
                align: 'left',
                headerAlign: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'price.startDate'} withPadding={false} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.priceProfile.path, { id: params.row.id }),
                                state: history.location.pathname,
                            }}
                        >
                            <DateRenderer
                                className={dataStyle(params.row.endsOn, params.row.endDate)}
                                date={params.row.startDate}
                                format={MomentFormats.Short}
                            />
                        </GridCellLink>
                    );
                },
            },
            {
                field: SortField.PriceEndDate,
                width: 202,
                headerAlign: 'left',
                align: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'price.endDate'} withPadding={false} />;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    const endDate = params.row.endsOn || params.row.endDate;
                    if (endDate) {
                        return (
                            <GridCellLink
                                to={{
                                    pathname: generatePath(allRoutes.priceProfile.path, { id: params.row.id }),
                                    state: history.location.pathname,
                                }}
                            >
                                <DateRenderer
                                    className={dataStyle(params.row.endsOn, params.row.endDate)}
                                    date={endDate}
                                    format={MomentFormats.Short}
                                />
                            </GridCellLink>
                        );
                    } else {
                        return (
                            <GridCellLink
                                to={{
                                    pathname: generatePath(allRoutes.priceProfile.path, { id: params.row.id }),
                                    state: history.location.pathname,
                                }}
                            >
                                <AllInclusiveIcon className="infinity" color="disabled" />
                            </GridCellLink>
                        );
                    }
                },
            },
            {
                field: SortField.PriceMediaSpaces,
                flex: 1.2,
                align: 'left',
                headerAlign: 'left',
                renderHeader: function renderHeader() {
                    return <FlashGridHeader header={'price.mediaSpaces'} withPadding={false} />;
                },
                valueGetter: (params: GridCellParams) => {
                    return params.row.isForAllMediaSpaces ? Number.MAX_VALUE : params.row.mediaSpaces.length;
                },
                renderCell: function renderCell(params: GridCellParams) {
                    const mediaSpaces = params.row.mediaSpaces;
                    return (
                        <GridCellLink
                            to={{
                                pathname: generatePath(allRoutes.priceProfile.path, { id: params.row.id }),
                                state: history.location.pathname,
                            }}
                        >
                            {params.row.isForAllMediaSpaces ? (
                                <div className={dataStyle(params.row.endsOn, params.row.endDate)}>
                                    {t('price.all-mediaSpaces')}
                                </div>
                            ) : (
                                <>
                                    {mediaSpaces.length > 0 ? (
                                        <div className={dataStyle(params.row.endsOn, params.row.endDate)}>
                                            {mediaSpaces[0].name}
                                            {mediaSpaces.length > 1 ? ` +${mediaSpaces.length - 1}` : ''}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {mediaSpaces.length > 1 && (
                                        <FlashTooltip
                                            disableHighlighting
                                            title={
                                                <Typography variant="body1" style={{ color: variables.colours.light }}>
                                                    {mediaSpaces.map((mediaSpace: MediaSpaceShortVm) => (
                                                        <span
                                                            className={dataStyle(params.row.endsOn, params.row.endDate)}
                                                            key={mediaSpace.id}
                                                        >
                                                            {mediaSpace.name}
                                                        </span>
                                                    ))}
                                                </Typography>
                                            }
                                            placement="top-start"
                                            arrow
                                        >
                                            <div className={dataStyle(params.row.endsOn, params.row.endDate)}>
                                                +{mediaSpaces.length - 1}
                                            </div>
                                        </FlashTooltip>
                                    )}
                                </>
                            )}
                        </GridCellLink>
                    );
                },
            },
        ] as GridColDef[];
    }, [prices, checked]);

    return getColumns();
}
