import { FC } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import ProfileHeader from 'components/molecules/ProfileHeader';
import ProfileActions from 'components/molecules/ProfileActions';

import allRoutes from 'router';

import { deleteById } from 'api/media-space';
import { MediaSpaceModel } from 'models/media-space.model';

export interface MediaSpaceTopProfileNavigationProps {
    isCreate: boolean;
    mediaSpace: MediaSpaceModel;
    id: string;
    onEdit?: { pathname: string; state: unknown };
    hasDeleteAction?: boolean;
    valid?: boolean;
    reason?: number;
    isNotDevices?: boolean;
    hasUnavailableDevices?: boolean;
}

const MediaSpaceTopProfileNavigation: FC<MediaSpaceTopProfileNavigationProps> = ({
    id,
    isCreate,
    mediaSpace,
    onEdit,
    hasDeleteAction,
    valid,
    reason,
    isNotDevices,
    hasUnavailableDevices,
}) => {
    const { t } = useTranslation();
    const history = useHistory();

    async function onDelete() {
        if (id) {
            await deleteById(id);
            toast.success(t('media-space-form.messages.success-delete'));
            history.push(generatePath(allRoutes.mediaSpaces.path, { id: 1 }));
        }
    }

    const onDeleteFunction = hasDeleteAction && !isCreate ? onDelete : undefined;

    const renderHeader = (): JSX.Element | null => {
        if (isCreate || mediaSpace.name) {
            let subheader = '';
            let header = '';

            if (isCreate) {
                header = t('media-space-form.header-create');
                subheader = t('media-space-form.subheader-create');
            } else {
                header = mediaSpace.name;
                subheader = t('media-space-form.subheader-update');
            }

            return (
                <ProfileHeader
                    header={header}
                    subheader={subheader}
                    valid={valid}
                    reason={reason}
                    isCreate={isCreate}
                    isNotDevices={isNotDevices}
                    hasUnavailableDevices={hasUnavailableDevices}
                />
            );
        } else {
            return null;
        }
    };

    return (
        <>
            <ProfileActions
                deleteTitle={t('media-space-form.delete-media-space')}
                deleteContent={t('media-space-form.contentText')}
                onDelete={onDeleteFunction}
                hasDeleteButton={hasDeleteAction}
                onEdit={onEdit}
            />
            {renderHeader()}
        </>
    );
};

export default MediaSpaceTopProfileNavigation;
